import React, { useEffect, useState } from "react";
import FormElement from "../../SmallComponents/FormElement";
import update from "immutability-helper";
import { useHistory } from "react-router";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import Select from "react-select";
import fetchData from "../../../serverCall/fetchData";
import { validateMandatory } from "../../CommonFunctions/ValidateFields";
import CreateEditModal from "../../SmallComponents/CreateEditModal";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "white" : "gray",
        padding: "5px 20px",
    }),
    control: (_, state) => ({
        minWidth: "10rem",
        margin: "auto",
        height: "2rem",
        padding: "0 10px",
        borderRadius: "5px",
        color: "#666",
        fontsize: "12px",
        border: state.selectProps.error ? "1px solid red" : "1px solid rgb(221,221,221)",
        boxSizing: "border-box",
        display: "flex",

        backgroundColor: "white",
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";
        const color = "#666";
        const fontSize = "16px";

        return { ...provided, opacity, transition, color, fontSize };
    },
    indicatorSeparator: () => {
        return null;
    },
};

const EditItemTax = ({ setSection, addTokenToState, isLogged, itemInfo }) => {
    const taxParams = ["itemMainCategory", "subCategoryId", "brandId", "taxApplicableDate"];

    const [itemTax, setItemTax] = useState({
        itemMainCategory: {
            inputType: "options",
            value: "",
            hintText: "Item Main Category",
            mandatory: true,
            colSpan: 6,
            options: [],
            error: false,
            errorMessage: "Please select item main category",
            disabled: true,
        },
        subCategoryId: {
            inputType: "options",
            value: "",
            hintText: "Item Sub Category",
            mandatory: true,
            colSpan: 6,
            options: [],
            error: false,
            errorMessage: "Select a sub category",
            disabled: true,
        },
        brandId: {
            inputType: "options",
            value: "",
            hintText: "Brand Name",
            mandatory: true,
            colSpan: 6,
            options: [],
            error: false,
            errorMessage: "Please select brand name",
            disabled: true,
        },
        taxApplicableDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Tax Applicable Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please pick a date",
            mindate: true,
        },
    });

    const [taxList, setTaxList] = useState([]);
    const [costList, setCostList] = useState([]);

    const taxOptions = [
        { value: "Taxable", label: "Taxable" },
        { value: "Nil-Tax", label: "Nil-Tax" },
        { value: "Exempt", label: "Exempt" },
    ];

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        var itemTaxInfo = await getItems();
        var categoryList = await getCategory();
        var subCategoryList = await getSubcategory(itemTaxInfo[0].categoryId);
        var brandList = await getBrandNames(itemTaxInfo[0].subCategoryId);

        var taxParamsCopy = Object.assign(itemTax);
        taxParamsCopy = update(taxParamsCopy, {
            itemMainCategory: { options: { $set: categoryList }, value: { $set: itemTaxInfo[0].categoryId } },
            subCategoryId: { options: { $set: subCategoryList }, value: { $set: itemTaxInfo[0].subCategoryId } },
            brandId: { options: { $set: brandList }, value: { $set: itemTaxInfo[0].brandId } },
            taxApplicableDate: { value: { $set: itemTaxInfo[0].taxApplicableDate } },
        });

        setItemTax(taxParamsCopy);
    }

    const history = useHistory();
    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        }
        return true;
    }

    async function getCategory() {
        var categoryData = await fetchData({
            requestingPage: "getCategory",
            method: "get",
            url: "fetch/item-category-list",
            headers: { token: isLogged.accessToken,module:"Item Tax" },
        });

        var categoryList = [];

        if (categoryData.msg === "success") {
            categoryData.asset.forEach((categ) => {
                var catObj = {};
                catObj.optionId = categ.categoryId;
                catObj.optionName = categ.categoryName;
                categoryList.push(catObj);
            });
            return categoryList;
        }
    }

    async function getSubcategory(id) {
        if (id !== "") {
            var subCategoryData = await fetchData({
                requestingPage: "getCategory",
                method: "get",
                url: `fetch/item-subcategory-list/categoryid=${id}`,
                headers: { token: isLogged.accessToken },
            });


            var subCategoryList = [];

            if (subCategoryData.msg === "success") {
                subCategoryData.asset.forEach((subCateg) => {
                    var catObj = {};
                    catObj.optionId = subCateg.subCategoryId;
                    catObj.optionName = subCateg.subCategoryName;
                    subCategoryList.push(catObj);
                });

                return subCategoryList;
            }
        }
    }

    async function getBrandNames(id) {
        if (id !== "") {
            var brandData = await fetchData({
                requestingPage: "getCategory",
                method: "get",
                url: `fetch/item-brand-list/subcategoryid=${id}`,
                headers: { token: isLogged.accessToken,module:"Item Tax" },
            });

            var brandList = [];

            if (brandData.msg === "success") {
                brandData.asset.forEach((brand) => {
                    var brandObj = {};
                    brandObj.optionId = brand.brandId;
                    brandObj.optionName = brand.brandName;
                    brandList.push(brandObj);
                });
                return brandList;
            }
        }
    }

    async function getItems() {
        var itemsData = await fetchData({
            requestingPage: "getItemTaxInfo",
            method: "post",
            url: "fetch/item-tax-pre-edit",
            headers: { token: isLogged.accessToken,module:"Item Tax" },
            data: itemInfo.editItemTaxObj,
        });

        if (itemsData.msg === "success") {
            var costTaxList = [];
            itemsData.asset.forEach((item) => {
                var costTax = {
                    taxType: item.taxType,
                    taxTypeError: false,
                    taxTypeErrorMessage: "Select a tax type for item",

                    taxPercentage: item.taxPercentage,
                    taxPercentageError: false,
                    taxPercentageErrorMessage: "Provide valid tax percentage of item",
                    IGST: parseFloat(item.IGST),
                    CGST: parseFloat(item.CGST),
                    SGST: parseFloat(item.SGST),
                    itemId: item.itemId,
                    itemName: item.itemName,
                    taxApplicableDate: item.taxApplicableDate,
                    toSend: false,
                };
                costTaxList.push(costTax);
            });

            setCostList(costTaxList);
            setTaxList(itemsData.asset);
            return itemsData.asset;
        }
    }

    const updateItemParameter = ({ paramName, key, value }) => {
        var itemParams = Object.assign(itemTax);
        itemParams = update(itemParams, { [paramName]: { [key]: { $set: value } } });

        switch (paramName) {
            case "itemMainCategory":
                itemParams = update(itemParams, { subCategoryId: { value: { $set: "" } }, brandId: { value: { $set: "" } } });
                setTaxList([]);
                break;

            case "subCategoryId":
                itemParams = update(itemParams, { brandId: { value: { $set: "" } } });
                setTaxList([]);
                break;

            default:
                break;
        }
        setItemTax(itemParams);
    };

    const updateCostList = (param, index, value) => {
        var updatedList = costList.slice();
        if (param === "taxType" && value === undefined) {
            updatedList = update(updatedList, { [index]: { [param]: { $set: "" },
                                                            "IGST": {$set:  0}, 
                                                            "CGST": {$set:  0}, 
                                                            "SGST": {$set: 0} ,
                                                            "taxPercentage": {$set: 0}  
                                                        } 
                                                    });
        } else {
            updatedList = update(updatedList, { [index]: { [param]: { $set: value } } });
        }

        // if (param === "taxType") {
        //     if (value !== 0) {
        //         updatedList = update(updatedList, { [index]: { taxPercentage: { $set: "0" } } });
        //     }
        // }

        if(param==="CGST" || param === "SGST")
        {   value = (/^\d*\.?\d*$/).test(value) > -1 ? value : "";
            updatedList=update(updatedList, { [index]: { [param] : { $set: value } } });
            updatedList = update(updatedList, { [index]: { taxPercentage: {$set: (parseFloat(updatedList[index]["CGST"])+parseFloat(updatedList[index]["SGST"])) } } })
        }else if(param === "IGST"){
            updatedList = update(updatedList, { [index]: { taxPercentage: {$set: value } } })
        }

        setCostList(updatedList);
    };

    const [taxError, setTaxError] = useState([]);

    const checkErrors = () => {
        var errorList = [];
        var paramsCopy = Object.assign(itemTax);
        ["itemMainCategory", "subCategoryId", "brandId", "taxApplicableDate"].forEach((field) => {
            if (paramsCopy[field].mandatory) {
                paramsCopy = update(paramsCopy, { [field]: { error: { $set: !validateMandatory(paramsCopy[field].value.toString()) } } });
            }

            if (paramsCopy[field].error) {
                errorList.push(paramsCopy[field].errorMessage);
            }
        });

        var costListCopy = costList.slice();

        setItemTax(paramsCopy);

        costList.forEach((cost, i) => {
            costListCopy[i].taxTypeError = false;
            costListCopy[i].taxPercentageError = false;
            costListCopy[i].toSend = false;

            var proceed = true;

            if (isNaN(cost.taxPercentage)) {
                costListCopy[i].taxPercentageError = true;
                proceed = false;
                errorList.push("Provide Valid Percentage");
            }

            if (parseFloat(cost.taxPercentage) < 0) {
                costListCopy[i].taxPercentageError = true;
                proceed = false;
            }

            if (proceed) {
                // 1 --------- scenario 1
                if (parseFloat(cost.taxPercentage) === 0 && cost.taxType === "") {
                    //  Do Nothing
                }

                // 2 --------- scenario 2
                if (cost.taxType === "Taxable" && parseFloat(cost.taxPercentage) > 0) {
                    // Can send this record to server
                    costListCopy[i].toSend = true;
                }

                // 3 --------- scenario 3
                if (cost.taxType === "Taxable" && parseFloat(cost.taxPercentage) === 0) {
                    costListCopy[i].taxPercentageError = true;
                }

                // 4 --------- scenario 3
                if ((cost.taxType === "Nil-Tax" || cost.taxType === "Exempt") && parseFloat(cost.taxPercentage) === 0) {
                    costListCopy[i].toSend = true;
                }
            }

            setCostList(costListCopy);

            if (cost.taxTypeError) errorList.push(`${cost.taxTypeErrorMessage} ${cost.itemName}`);
            if (cost.taxPercentageError) errorList.push(`${cost.taxPercentageErrorMessage} ${cost.itemName}`);
            setTaxError(errorList);
        });

        if (errorList.length === 0) {
            var shortlistedArray = [];
            costListCopy.forEach((item, i) => {
                if (item.toSend) {
                    item.taxApplicableDate = itemTax.taxApplicableDate.value;
                    item.taxId = taxList[i].taxId;
                    item.IGST=parseFloat(item.IGST);
                    item.CGST=parseFloat(item.CGST);
                    item.SGST=parseFloat(item.SGST);
                    delete item.itemName;
                    delete item.taxPercentageError;
                    delete item.taxPercentageErrorMessage;
                    delete item.taxTypeError;
                    delete item.taxTypeErrorMessage;
                    delete item.toSend;
                    shortlistedArray.push(item);
                }
            });
            if (shortlistedArray.length > 0) {

                var arrayToServer = [];
                shortlistedArray.forEach((newTaxObj, j) => {
                    var compareToObj = taxList.filter((compTo) => compTo.taxId === newTaxObj.taxId)[0];
                    if (
                        newTaxObj.taxApplicableDate !== compareToObj.taxApplicableDate ||
                        parseFloat(newTaxObj.taxPercentage) !== parseFloat(compareToObj.taxPercentage) ||
                        newTaxObj.taxType !== compareToObj.taxType
                    ) {
                        arrayToServer.push(newTaxObj);
                    } else {
                        console.log("---- Both objects are same");
                    }
                });
                if (arrayToServer.length > 0) {
                prepareDataToSubmit(arrayToServer);
            }
                else {
                    setDataSubmitted(true);
                    setIsOpen(true);
                    setModalText("No change has been detected.");
                }
            }
        }
    };

    const prepareDataToSubmit = async (data) => {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "createItemTax",
            method: "put",
            url: "edit/item-tax",
            headers: { token: isLogged.accessToken,module:"Item Tax" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Successfully edited Item Tax!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setTaxError([result.desc]);
        }
        setDataSubmitted(true);
    };

    const renderFormElements = ({ elementList }) => {
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={itemTax[element].inputType}
                    value={itemTax[element].value}
                    setInput={(value) => {
                        updateItemParameter({ paramName: element, key: "value", value: value });
                    }}
                    hintText={itemTax[element].hintText}
                    mandatory={itemTax[element].mandatory}
                    colSpan={itemTax[element].colSpan}
                    options={itemTax[element].inputType === "options" ? itemTax[element].options : null}
                    error={itemTax[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? itemTax[element].rowSpan : null}
                    placeholder={itemTax[element].placeholder}
                    title={itemTax[element].title}
                    mindate={true}
                    disabled={itemTax[element].disabled}
                />
            );
        });
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    const submitOkClick = () => {
        setIsOpen(false);
        if (taxError.length === 0) {
            setSection("itemTaxList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const renderErrorMessage = () => {
        if (taxError.length > 0) return taxError[0];
        else return null;
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "1100px",
                        margin: "0 auto 4rem",
                        padding: "3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div className="createItemPurchaseGrid">{renderFormElements({ elementList: taxParams })}</div>

                    <br />

                    <table className="createItemPurchaseTable">
                        <thead>
                            <tr className="createVendorContactsTableHeader">
                                <td>Item Code</td>
                                <td>Item Name</td>
                                <td>Weight From (gms)</td>
                                <td>Weight To (gms)</td>
                                <td>Net Weight (gms)</td>
                                <td>Gross Weight (gms)</td>
                                <td>Tax Type</td>
                                <td>IGST</td>
                                <td>CGST</td>
                                <td>SGST</td>
                                <td>Tax Percentage</td>
                            </tr>
                        </thead>

                        {taxList.length > 0 ? (
                            <tbody>
                                {taxList.map((row, j) => (
                                    <tr className="createVendorContactsTableRows" key={j}>
                                        {["itemCode", "itemName", "from","to","netWeight","grossWeight","taxType","IGST","CGST","SGST", "taxPercentage"].map((key, i) => {
                                            switch (key) {
                                                case "taxType":
                                                    return (
                                                        <td>
                                                            <Select
                                                                options={taxOptions}
                                                                styles={customStyles}
                                                                isSearchable={true}
                                                                value={taxOptions[taxOptions.findIndex((opt) => opt.value === costList[j].taxType)]}
                                                                onChange={(value) => {
                                                                    updateCostList("taxType", j, value?.value);
                                                                }}
                                                                isClearable={true}
                                                                error={costList[j].taxTypeError}
                                                            />
                                                        </td>
                                                    );

                                                case "taxPercentage":
                                                    return (
                                                        <td key={i} style={{ maxWidth: "8px" }}>
                                                            <input
                                                                className={
                                                                    costList[j]?.taxPercentageError ? "createItemTaxInputError" : "createItemTaxInput"
                                                                }
                                                                type="text"
                                                                value={costList[j]?.taxPercentage}
                                                                //onChange={(e) => updateCostList("taxPercentage", j, e.target.value)}
                                                                disabled={true}
                                                            />
                                                        </td>
                                                    );
                                                     case "IGST":
                                                    return(
                                                        <td key={i} style={{maxWidth: "80px"}}>
                                                        <input type="text" className="createItemTaxInput"
                                                        value={costList[j]?.IGST}
                                                        onChange={(e)=>updateCostList("IGST",j,e.target.value)}
                                                        disabled={costList[j]?.taxType!=="Taxable" || ( costList[j]?.CGST || costList[j]?.SGST) ? true: false }/>
                                                        </td>
                                                    );
                                                case "CGST":
                                                    return(
                                                        <td key={i} style={{maxWidth: "80px"}}>
                                                        <input type="text" className="createItemTaxInput"
                                                        value={costList[j]?.CGST}
                                                        onChange={(e)=>updateCostList("CGST",j,e.target.value)}
                                                        disabled={costList[j]?.taxType!=="Taxable" || costList[j]?.IGST ? true: false }/>
                                                        </td>
                                                    );

                                                case "SGST":
                                                    return(
                                                        <td key={i} style={{maxWidth: "80px"}}>
                                                        <input type="text" className="createItemTaxInput"
                                                        value={costList[j]?.SGST}
                                                        onChange={(e)=>updateCostList("SGST",j,e.target.value)}
                                                        disabled={costList[j]?.taxType!=="Taxable" || costList[j]?.IGST ? true: false }/>
                                                        </td>
                                                    );
                                                default:
                                                    return <td key={i}>{[row[key]? row[key] : " - "]}</td>;
                                            }
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="createVendorContactsTableRows">
                                    {["itemCode", "itemName", "taxType", "taxPercentage"].map((item) => {
                                        return <td key={item}>&nbsp;</td>;
                                    })}
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                    <button className="submitButton" onClick={checkErrors}>
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        itemInfo: state.itemInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditItemTax);
