import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import FetchNewToken from "../../serverCall/FetchNewToken";
import InputField from "../SmallComponents/InputField";

const CreateDcReturn = ({isLogged,setSection,storeInfo,addTokenToState})=>{
    const lineItemsParamsList = ["itemName","itemCode","from","to","netWeight","grossWeight","uom","quantity","currentDc","previousReturnDc","dcReturnQty"];
    const [dcReturnParams,setDcReturnParams]=useState({
        dcOrderNo:{
            inputType: "options",
            value: "",
            options: [],
            hintText: "Dc Order Id",
            mandatory: true,
            error: false,
            colSpan: 4,
            errorMessage: "Please Select Dc No To Create Dc Return",
        },
        userDcReturnNo:{
            inputType: "text",
            value:"",
            hintText:"DC Return No",
            mandatory: true,
            error: false,
            colSpan: 4,
            errorMessage: "Please Enter Dc Return No"
        },
        dcReturnDate:{
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Return Dc date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please select Returned Dc Date",
            mindate: true,
        }
    });

    const [lineItems,setLineItems]=useState([]);
    const [dcReturnError,setDcReturnError] = useState([]);
    const [dcDetail,setDcDetail] = useState({});
    const [dcReturnRemarks,setDcReturnRemarks] = useState("");
    const [dcApprovedList,setDcApprovedList]=useState([]);
    const history = useHistory();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Creating DC Return. Please wait...");
    useEffect(()=> getInformation(),[]);

    async function getInformation(){
        await checkToken();
        var dcOptions = await getDcApprovedList();
        var paramsCopy = Object.assign(dcReturnParams);
        paramsCopy = update(paramsCopy,{dcOrderNo: {options: {$set: dcOptions} } });
        setDcReturnParams(paramsCopy);
    }

    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    };
    async function getDcApprovedList(){
        var dcAList = await fetchData({
            requestingPage: "dcListPage",
            method: "post",
            url: "dclist-fetch/dc-approved-list",
            headers: {token: isLogged.accessToken, module: "DC List"}
        })
        if(dcAList.msg==="success"){
            var dcApprovedOptions=[];
            dcAList.asset=dcAList.asset.filter((dcObj)=>dcObj["lineItems"].some((row)=>row["previousReturnDc"]<row["currentDc"]));
            dcAList.asset.forEach((dataItem,i)=>{
                dcApprovedOptions.push({optionId: dataItem.dcId, optionName: dataItem.dcNo});
            })
            setDcApprovedList(dcAList.asset);
            return dcApprovedOptions;
        }
    }

    useEffect(()=>{
        if(dcReturnParams.dcOrderNo.value!==""){
            var dcDetail = dcApprovedList.filter((dcObj)=>dcObj.dcId === dcReturnParams.dcOrderNo.value)[0];
            setDcDetail(dcDetail);
            dcDetail.lineItems.forEach((item)=>{item.dcReturnQty = 0;item.createQty=0;});
            setLineItems(dcDetail.lineItems);
        } else{
            setDcDetail({});
            setLineItems([]);
        }
    },[dcReturnParams]);
    const updateDcReturnParameter = ({section,param,key,value})=>{
        if(section === "general"){
            var paramsCopy = Object.assign(dcReturnParams);
            paramsCopy = update(paramsCopy,{ [param]: {[key]: {$set: value} } });
            setDcReturnParams(paramsCopy);
        }
    }

    const checkErrors = ()=>{
        var errorList=[];
        var dcReturnObj = Object.assign(dcReturnParams);
        Object.keys(dcReturnObj).forEach((item)=>{
            if(dcReturnObj[item].mandatory){
                dcReturnObj = update(dcReturnObj,{[item]: {error: {$set: !validateMandatory(dcReturnObj[item].value.toString())} } })
            }
            if(dcReturnObj[item].error){
                errorList.push(dcReturnObj[item].errorMessage);
            }
        });
        var lineItemsCopy = lineItems.slice();
         var LineCheckCondition1=lineItemsCopy.some((row)=>row["dcReturnQty"]<0);
         if(LineCheckCondition1)
         errorList.push("Provide Valid Dc Return Quantity");
         var LineCheckCondition2=lineItemsCopy.some((row)=>row["currentDc"]<row["previousReturnDc"]);
         if(LineCheckCondition2)
         errorList.push("The Current Return Dc Quantity of an Item cannot be Greater Than Total Return Dc Quantity");
         if(errorList.length===0 && lineItemsCopy.length > 0){
          var data2Server = {
              dcOrderId: dcReturnObj.dcOrderNo.value,
              userDcReturnNo: dcReturnObj.userDcReturnNo.value,
              dcReturnDate: dcReturnObj.dcReturnDate.value, 
          }
          var lineItemsToSend=[]; 
          lineItemsCopy.forEach((row)=>{
              lineItemsToSend.push({
                  dcLineId: row.lineId,
                  previousReturnDc: row.previousReturnDc,
                  currentReturnDc: row.dcReturnQty,
                        })
                    });
        data2Server.lineItems=lineItemsToSend;
        submitData(data2Server);
         } else{
             setDcReturnError(errorList);
             setDcReturnParams(dcReturnObj);
             setLineItems(lineItemsCopy);
         }
        
    };

    const submitData=async(data2Server)=>{
        setIsOpen(true);
        setModalText("Creating DC Return. Please Wait...!");
        var result = await fetchData({
            requestingPage:"createDc",
            url: "dclist-create/create-dc-return",
            method: "post",
            headers: {token: isLogged.accessToken, module: "DC List"},
            data: data2Server,
        });
        setDataSubmitted(true);
        if(result.msg==="success"){
            setModalText("DC Return Created Successfully");
        }else{
            setDataSubmitted(true);
            setDcReturnError([result.desc]);
            setModalText(result.desc);
            console.log("Error in Submitting data",result);}
    }

    const renderFormElements=({elementList,param,section})=>{
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value !== null ? param[element].value : ""}
                    setInput={(value) => {
                        updateDcReturnParameter({ section, param: element, key: "value", value: value });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                />
            );
        });
    }
const updateCreateQty=(rowIndex,value)=>{
var paramsCopy = lineItems.slice();
paramsCopy = update(paramsCopy, {[rowIndex]:{["createQty"]:{$set: parseInt(value) || 0} } });
setLineItems(paramsCopy);
}
const updateLineItems=(action,rowIndex,value)=>{
    let lineCopy = lineItems.slice();
       if(action === "increment"){
               lineCopy=update(lineCopy,{[rowIndex]:{["previousReturnDc"]: {$set: lineCopy[rowIndex]["previousReturnDc"]+value}  , 
                                                    ["dcReturnQty"]: {$set: lineCopy[rowIndex]["dcReturnQty"]+value}  }   
                                                    })
       } else if(action === "decrement"){
        lineCopy=update(lineCopy,{[rowIndex]:{["previousReturnDc"]: {$set: lineCopy[rowIndex]["previousReturnDc"]-value}  , 
                                  ["dcReturnQty"]: {$set: lineCopy[rowIndex]["dcReturnQty"]-value}  }   
        })
       }
       setLineItems(lineCopy);
}
const renderErrorMessage = () => {
    if (dcReturnError.length > 0) return dcReturnError[0];
  };

  const submitOkClick = () => {
    setIsOpen(false);
    if (dcReturnError.length === 0) {
      setSection("dcList");
    }
  };
    const RenderVendorDetail = () => {
        return (
            <React.Fragment>
                <div className="detailTitle">Vendor Detail</div>
                <div className="detailText">
                    {<><b>{`Vendor Id: `}</b>{`${dcDetail.vendorId}- ${dcDetail.vendorName}`}</>}
                    <br />
                    {dcDetail.address !== null ? <><b>{`Address: `}</b> {dcDetail.vendorAddress}</> : null },
                    <br /> {dcDetail.vendorCity}, {dcDetail.vendorState || ""}, {dcDetail.vendorPinCode !== null ? ` - ${dcDetail.vendorPinCode}` : null}
                    <br /> <b>{dcDetail.vendorMobile !== null || dcDetail.vendorPhone !== null ? `\nPhone: ` : null}</b>
                    {dcDetail.vendorMobile !== null ? dcDetail.vendorMobile : null}
                    {dcDetail.vendorPhone !== null ? `, ${dcDetail.vendorPhone} ` : null}
                    {dcDetail.vendorEmail !== null ? <br /> : null}
                    {dcDetail.vendorEmail !== null ? <><b>{`Email Id:`}</b>{dcDetail.vendorEmail}</> : null}
                    {dcDetail.vendorGstNo !== null ? <br /> : null}
                    {dcDetail.vendorGstNo !== null ? <><b>{`GST No: `}</b>{dcDetail.vendorGstNo}</> : null}
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
        <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
        <div className="formArea">
            <div
              style={{
                width: "1000px",
                margin: "0 auto 2rem",
                padding: "2rem",
                border: "1px solid lightgray",
                borderRadius: "5px",
                backgroundColor: "white",
              }}>
                <div style={{ display: "flex" }}>
                    <div className="createPurchaseOrderGrid" style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
                        {renderFormElements({elementList: Object.keys(dcReturnParams),param: dcReturnParams,section: "general"})}
                    </div>
                    <div style={{ width: "300px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{lineItems.length > 0 ? <RenderVendorDetail /> : null}</div>
                            </div>
                    </div>
                </div>
                {dcDetail.orderDate !== undefined ? (
                        <div className="createSalesInvoiceGrid">
                            <div
                            style={{
                                gridColumn: "auto / span 3",
                                display: "flex",
                                fontSize: "14px",
                                alignItems: "center",
                                color: "#333333",
                            }}>
                            DC No:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {dcDetail.userDcNo !== undefined ? dcDetail.userDcNo : "-"}
                            </span>
                        </div>
                        <div
                            style={{
                                gridColumn: "auto / span 4",
                                display: "flex",
                                fontSize: "14px",
                                alignItems: "center",
                                color: "#333333",
                            }}>
                            DC Order Date:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {dcDetail.orderDate !== undefined ? dcDetail.orderDate : "-"}
                            </span>
                        </div>
                        <div
                            style={{
                                gridColumn: "auto / span 5",
                                display: "flex",
                                fontSize: "14px",
                                alignItems: "center",
                                color: "#333333",
                            }}
                        >
                           DC Exp Delivery Date:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {dcDetail.expectDate !== undefined ? dcDetail.expectDate : "-"}
                            </span>
                        </div>
                        <div
                            style={{
                                gridColumn: "auto / span 4",
                                display: "flex",
                                fontSize: "14px",
                                alignItems: "center",
                                color: "#333333",
                            }}
                        >
                           Process:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {dcDetail.processName !== undefined ? dcDetail.processName : "-"}
                            </span>
                        </div>
                        <div
                            style={{
                                gridColumn: "auto / span 4",
                                display: "flex",
                                fontSize: "14px",
                                alignItems: "center",
                                color: "#333333",
                            }}
                        >
                           Production Order:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {dcDetail.prodNo !== undefined ? dcDetail.prodNo : "-"}
                            </span>
                        </div>
                    </div>
                    ) : null}
             {lineItems.length > 0 ? (
                <table className="createItemPurchaseTable" style={{ margin: "2rem auto" }}>
                  <thead>
                    <tr className="createVendorContactsTableHeader">
                      <td>Item Name</td>
                      <td>Item Code</td>
                      { isLogged.showHideWeight ? <> <td>Weight From(gms)</td>
                      <td>Weight To(gms)</td>
                      <td>Net Weight(gms)</td>
                      <td>Gross Weight(gms)</td> </> : null}
                      <td>UOM</td>
                      <td>Production Qty</td>
                      <td>Current Dc Qty</td>
                      <td>Total Return Dc Quantity</td>
                      <td>Current Return Dc Quantity</td>
                      <td>Create Return Qty</td>
                    </tr>
                  </thead>
                  <tbody>
                    {lineItems.map((row,i)=>(
                       <tr className="createVendorContactsTableRows" key={i}>
                            {lineItemsParamsList.map((col,j)=>{
                                let weightRelatedColumn =  ["from","to","netWeight","grossWeight"].includes(col);
                                if(["from","to","netWeight","grossWeight"].includes(col))
                                    {   
                                    return isLogged.showHideWeight ?  <td key={j}>{row[col] !==null ? row[col] : "-"}</td> :  null
                                    }
                                    else {
                                        return <td key={j} className={col==="itemName" ? "stickyFirstColumn" : ''}>{row[col] !==null ? row[col] : "-"}</td>            
                                       }
                                    })
                                  }
                                <td style={{display:"flex",maxWidth: "10px",justifyContent:"center"}}>
                                    <button onClick={(e)=>{updateLineItems("increment",i,lineItems[i].createQty)}}>+</button>
                                        <InputField type="number" value={lineItems[i].createQty} align="center" boxWidth="90px"
                                              setName={(value) => updateCreateQty(i,parseInt(value) || 0)}/>
                                    <button onClick={(e)=>{updateLineItems("decrement",i,lineItems[i].createQty)}}>-</button>
                                </td>
                        </tr>
                    ))}
                   </tbody>
                 </table>
                ) : null}
                <div className="poNetTotalSurchargeDiv">
                    <div className="dcReturnRemarks" style={{width:'300px'}}>
                        <div style={{ marginTop: "10px" }}>Remarks</div>
                        <textarea className="multilineInput" rows="6" cols="30" name="text" placeholder="Enter Remarks here"
                        style={{ padding: "10px",resize: "none", marginTop: "10px",height: "114px",fontFamily: "sans-serif",}}
                        value={dcReturnRemarks} onChange={(e) => {setDcReturnRemarks(e.target.value);}} />
                    </div>
                </div>
            </div>
        </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
          <button className="submitButton"
            onClick={() => {setDcReturnError([]);checkErrors();}}>
            Submit
          </button>
        </div>
      </div>
        </React.Fragment>
    );
}


const mapStateToProps = (state) => {
    return {
      isLogged: state.isLogged,
      storeInfo: state.storeInfo
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) =>
        dispatch(addToken(accessToken, employeeId)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(CreateDcReturn);