import React, { useEffect, useState,useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from "./Dropdown";
import FormHint from "./FormHint";
import InputField from "./InputField";
import calendarIcon from "../../assets/calendar.svg";
import closeIcon from "../../assets/close.svg";
import SearchableDropDown from "./SearchableDropDown";
import uuid from "react-uuid";
import MultiSelectDropdown from "./MultiSelectDropdown";
import { useWindowSize } from "../CommonFunctions/utils";
const FormElement = ({
    inputType,
    value,
    setInput,
    hintText,
    mandatory,
    colSpan,
    rowSpan,
    options,
    error,
    disabled,
    placeholder,
    title,
    mindate,
    maxdate,
    hideHint,
    removeBorder,
    multiple,
    onEnterKey,
    isCreatableMulti,
    hasSelectAll,
    isClearable,
    boxHeight,
    boxWidth,
    styles,
    filterOptions
}) => {
    const [startDate, setStartDate] = useState();
    const [windowWidth,windowHeight]=useWindowSize();
    const refs=useRef();
    if (inputType === "upload") {
        var inputId = uuid();
    }

    useEffect(() => {
        if (inputType === "dateFromEditPage" || inputType === "dateTimePicker") {
            if (value !== null && value !== "") {
                setStartDate(new Date(value));
            } else {
                setStartDate(null);
            }
        }
        // eslint-disable-next-line
    }, [value]);

    const selectInput = () => {
        switch (inputType) {
            case "text":
            case "password":
            case "number":
            case "float":
                return (
                    <InputField
                        value={value}
                        setName={setInput}
                        type={inputType}
                        error={error}
                        disabled={disabled}
                        placeholder={placeholder}
                        title={title}
                        boxWidth
                        boxHeight
                        removeBorder={removeBorder}
                        onEnterKey={onEnterKey}
                    />
                );

            case "options":
                return <SearchableDropDown options={options} selected={value} setInput={setInput} error={error} disabled={disabled} isClearable={isClearable} filterOptions={filterOptions} />;

            case "multiSelect":
                return <MultiSelectDropdown options={options} selected={value} setInput={setInput} error={error} isCreatableMulti={isCreatableMulti} disabled={disabled} hasSelectAll={hasSelectAll} filterOptions={filterOptions}/>;

            case "date":
            case "dateFromEditPage":
                const ExampleCustomInput = React.forwardRef(({ value, onClick},innerRef ) => (
                        
                        <div className="commonDatepicker" onClick={onClick} ref={innerRef}>
                            <div style={{ flex: "1" }}>{value}</div>
                        </div>
                ),);
                return (
                    <div style={{display:"flex"}}>
                        <style>
                            {`
                            .react-datepicker__input-container input{
                                border: none;
                                height: inherit;
                                border: lightgray;
                            }
                            `}
                        </style>
                    <DatePicker
                        wrapperClassName={disabled ? "datePickerWrapperDisabled" : error ? "datePickerWrapperCustom datePickerWrapperCustomError" : "datePickerWrapperCustom"}
                        selected={startDate}
                        dateFormat="dd-MMM-yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        // isClearable
                        minDate={mindate ? new Date() : undefined}
                        maxDate={maxdate ? new Date() : undefined}
                        placeholderText="Pick Date"
                        withPortal={windowWidth < 650 || windowHeight< 600}
                        shouldCloseOnSelect={true}
                        onChange={(date) => {
                            console.log(date);

                            if (date) {
                                var year = date.getFullYear();
                                var month = date.getMonth() + 1;
                                var dateValue = date.getDate();
                                
                                if (month.toString().length === 1) month = `0${month}`;
                                if (dateValue.toString().length === 1) dateValue = `0${dateValue}`;

                                var formattedDate = `${year}-${month}-${dateValue}`;
                                setInput(formattedDate);
                            } else {
                                setInput("");
                            }
                            setStartDate(date);
                            refs.current.setOpen(false);
                            refs.current.setBlur(true);
                        }}
                        //customInput={<ExampleCustomInput innerRef={refs}/>}
                        ref={refs}
                        disabled={disabled}
                    />
                    <img style={{ width: "1rem"}} onClick={()=>refs.current.setFocus(true)} 
                    src={calendarIcon}  alt="Calendar" />
                    </div>
                );
            case 'dateTimePicker':
                const ExampleCustomDateTimeInput = React.forwardRef(({ value, onClick},innerRef ) => (
                        
                    <div className="commonDatepicker" onClick={onClick} ref={innerRef}>
                        <div style={{ flex: "1" }} tabIndex={"0"} onFocus={onClick}>{value}</div>
                        <img style={{ width: "1rem", paddingRight: "10px" }} src={calendarIcon} alt="Calendar" />
                    </div>
            ),);
                return(<div style={{display:"flex"}}>
                    <style>
                        {`
                        .react-datepicker__wrapper {
                            border: 3px solid #cec9c996;
                            }
                        .react-datepicker__input-container input{
                            border: none;
                            height: inherit;
                            border: lightgray;
                        }
                        `}
                    </style>
                    
                    <DatePicker
                        wrapperClassName={disabled ? "datePickerWrapperDisabled" : error ? "datePickerWrapperCustom datePickerWrapperCustomError" : "datePickerWrapperCustom"}
                        selected={startDate}
                        dateFormat="dd-MMM-yyyy p"
                        // peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeFormat="p"
                        timeIntervals={15}
                        // isClearable
                        minDate={mindate ? new Date() : undefined}
                        maxDate={maxdate ? new Date() : undefined}
                        disabledKeyboardNavigation={false}
                        placeholderText="Pick Date"
                        withPortal={windowWidth < 650 || windowHeight< 600}
                        shouldCloseOnSelect={true}
                        onChange={(date) => {
                            console.log(date);

                            if (date) {
                               var formattedDate = new Date(date).toISOString();
                                setInput(formattedDate);
                            } else {
                                setInput("");
                            }
                            setStartDate(date);
                            refs.current.setOpen(false)
                            refs.current.setBlur(true)
                        }}
                        //customInput={<ExampleCustomDateTimeInput innerRef={refs}/>}
                        disabled={disabled}
                        ref={refs}
                    />
                    <img style={{ width: "1rem", paddingRight: "10px" }} onClick={()=>refs.current.setFocus(true)}
                        src={calendarIcon} alt="Calendar" />
                    </div>
                );
            case "upload":
                return (
                    <React.Fragment>
                        <label className={error ? "inputLabelError" : "inputLabel"} htmlFor={inputId}>
                            {hintText}
                        </label>

                        <input
                            type="file"
                            accept="image/*"
                            name="photo"
                            onChange={(event) => setInput(event.target.files[0])}
                            id={inputId}
                            style={{ opacity: 0, position: "absolute", top: "10px", left: "10px", zIndex: "-1" }}
                        />
                    </React.Fragment>
                );

            case "uploadReady":
                return (
                    <div className="inputDone">
                        <span
                            style={{
                                flex: 1,
                                display: "flex",
                                alignItems: "center",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: "168px",
                                textOverflow: "ellipsis",
                            }}
                        >
                            ✅ {value}
                        </span>
                        <span
                            style={{
                                padding: "0 10px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                "&:hover": { backgroundColor: "gray" },
                                maxWidth: "25rem",
                                textOverflow: "ellipsis",
                                boxSizing: "border-box",
                            }}
                            onClick={() => setInput("deleteId")}
                            title="Remove and Re-upload ID"
                        >
                            ❌
                        </span>
                    </div>
                );

            case "addButton":
                return (
                    <div style={styles} className={disabled ? "commonInput formAddButtonDisabled" : "commonInput formAddButton"} onClick={setInput} title={title}>
                        {value}
                    </div>
                );

            default:
                return <span>NO INPUT SPECIFIED</span>;
        }
    };

    return (
        <div className="formElement" style={{ gridColumn: `auto / span ${colSpan}`, gridRow: `auto / span ${rowSpan}`,height: boxHeight ? boxHeight : null,width:boxWidth ? boxWidth : null,...styles }}>
            {hideHint === undefined  && inputType!=='addButton'? <FormHint hintText={hintText} mandatory={mandatory} /> : null}
            {selectInput()}
        </div>
    );
};

export const renderFormElements = ({ elementList, param, section,setInput }) => {
    return elementList.map((element) => {
        return (
          <FormElement
            key={element}
            inputType={param[element].inputType}
            value={param[element].value !== null ? param[element].value : ""}
            setInput={(value,wholeObject) => setInput({section,paramName: element,
                                          key: "value",value,wholeObject})
                    }
            hintText={param[element].hintText}
            mandatory={param[element].mandatory}
            colSpan={param[element].colSpan}
            options={
              param[element].inputType === "options" || param[element].inputType === "multiSelect" 
                ? param[element].options
                : null
            }
            error={param[element].error}
            filterOptions={param[element].filterOptions || null}
            rowSpan={
              element === "vendorLogo" || element === "otherBusinessDetails"
                ? param[element].rowSpan
                : null
            }
            placeholder={param[element].placeholder}
            title={param[element].title}
            disabled={param[element].disabled}
            mindate={param[element].mindate}
          />
        );
    });
  };

export default React.memo(FormElement);
