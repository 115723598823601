import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { editQcId } from "../../redux/Store/StoreInfoActions";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const QualityDetail = ({
    // props
    setSection,
    approval,
    setEditAndApprove,

    // state
    isLogged,
    storeInfo,

    // dispatch
    addTokenToState,
    editQcId,
}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving Quality Checking. Please wait...");

    const [diagramImage, setDiagramImage] = useState("");
    const [diagramModalOpen, setDiagramModalOpen] = useState(false);

    const [qcDetail, setQcDetail] = useState({});
    const [qcDocument, setQcDocument] = useState("");

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getQcDetail();
    }

    const history = useHistory();

    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getQcDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "store-fetch/qc-detail/" + storeInfo.viewQcId,
            headers: { token: isLogged.accessToken, module: "Quality Checking" },
        });
        if (data.msg === "success") {

            data.asset[0].qcLineItems.forEach((item) => {

                var qualityList = data.asset[0].qcSamples.filter((qual) => qual.qcLineItemId === item.qcLineItemId);
                item.qualityItems = qualityList;
            });

            setQcDetail(data.asset[0]);
        } else {
            console.log(data);
        }
    }

    useEffect(() => {
        if (qcDetail.qcDocument !== null) {
            getQcDocument();
        }
        // eslint-disable-next-line
    }, [qcDetail]);

    async function getQcDocument() {

        if (qcDetail?.qcDocument !== null && qcDetail.qcDocument !== undefined) {
            var data = await fetchData({
                requestingPage: "itemImage",
                method: "get",
                url: "image/qc-document/" + qcDetail.qcDocument,
                headers: { token: isLogged.accessToken, module: "Quality Checking" },
            });

            if (data !== "") {
                setQcDocument(data);
            }
        }
    }

    const submitOkClick = () => {
        setIsOpen(false);

        setSection("qualityList");

        setModalText("Approving SO. Please wait...");
    };

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable" style={{ borderSpacing: "0 1px" }}>
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td>Item Code</td>
                        <td>Item</td>
                        <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td>
                        <td>HSN No</td>
                        <td>UOM</td>
                        <td>Received Quantity</td>
                        <td>Checking Quantity</td>
                        <td>Accepted Quantity</td>
                        <td>Rejected Quantity</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={qcDetail.qcLineItems} tab="contact" />{" "}
                </tbody>
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        var lineItemParams = [
            "itemCode",
            "itemName",
            "from", "to", "netWeight", "grossWeight",
            "hsnCode",
            "uom",
            "receivedQuantity",
            "checkingQuantity",
            "acceptedQuantity",
            "rejectedQuantity",
        ];

        return rows.map((row, j) => {
            return (
                <React.Fragment>
                    <tr className="vendorDetailContactsTableRows" key={j}>
                        {lineItemParams.map((key, i) => {
                            return <td key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                        })}
                    </tr>
                    {row.qualityItems.length > 0 ? (
                        <tr>
                            <td colSpan="8" className="vendorListInnerTableWrapper" style={{ padding: "0 3rem 1rem", backgroundColor: "#eee" }}>
                                <table className="vendorListInnerTable" style={{ backgroundColor: "rgb(200,200,200)" }}>
                                    <thead>
                                        <tr className="vendorListInnerTableHeader" style={{ backgroundColor: "rgb(249,249,249)" }}>
                                            <td className="vendorListInnerTableHeading">Unit</td>
                                            <td className="vendorListInnerTableHeading">Description</td>
                                            <td className="vendorListInnerTableHeading">Quality</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {row.qualityItems.map((qual, i) => {
                                            return (
                                                <tr
                                                    className="vendorListInnerTable"
                                                    key={qual.inwardLineItemId}
                                                    style={{ backgroundColor: "rgb(249,249,249)" }}
                                                >
                                                    <td className="vendorListInnertableInfo">Unit {i + 1}</td>
                                                    <td className="vendorListInnertableInfo">{qual.description}</td>
                                                    <td className="vendorListInnertableInfo">{qual.quality}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    ) : null}
                </React.Fragment>
            );
        });
    };

    const renderSummaryInfo = () => {
        if (qcDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        {qcDetail.checkingNo}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={
                                qcDetail.inwardStatus === "Declined"
                                    ? { color: "red" }
                                    : qcDetail.inwardStatus === "Approved"
                                        ? { color: "#14c76a" }
                                        : { color: "#333333" }
                            }
                        >
                            ( {qcDetail.checkingStatus} )
                        </span>
                    </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">Quality Checking Date</span>
                        <span className="vendorSummaryValue">: {qcDetail.checkingDate}</span>

                        <span className="vendorSummaryCategory">Order Type</span>
                        <span className="vendorSummaryValue">: {qcDetail.orderType}</span>

                        <span className="vendorSummaryCategory">Order No</span>
                        <span className="vendorSummaryValue">: {qcDetail.orderNo}</span>

                        <span className="vendorSummaryCategory">Inward No</span>
                        <span className="vendorSummaryValue">: {qcDetail.inwardNo}</span>

                        <span className="vendorSummaryCategory">Batch No</span>
                        <span className="vendorSummaryValue">: {qcDetail.batchNo !== null ? qcDetail.batchNo : "-"}</span>
                    </div>
                </React.Fragment>
            );
        }
    };

    const [errorMessage, setErrorMessage] = useState([]);

    async function approveQc() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `store-edit/qc-approve/${qcDetail.qcId}`,
            headers: { token: isLogged.accessToken, module: "Quality Checking" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Quality Checking Approved");
        } else {
            setModalText(data.desc);
            setErrorMessage(data.desc);
        }
    }

    const renderErrorMessage = () => {
        if (errorMessage !== "") {
            return errorMessage;
        }
    };

    const ApproveButtons = () => {
        return (
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="saveButton"
                        onClick={() => {
                            setEditAndApprove(true);
                            editQcId(storeInfo.viewQcId);
                            setSection("qcEdit");
                        }}
                    >
                        Edit
                    </button>

                    <button
                        className="submitButton"
                        onClick={() => {
                            approveQc();
                        }}
                    >
                        Approve
                    </button>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <Modal
                isOpen={diagramModalOpen}
                onRequestClose={() => setDiagramModalOpen(false)}
                contentLabel="Example Modal"
                style={{
                    overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
                    content: {
                        textAlign: "center",
                        width: "70%",
                        height: "70%",
                        position: "fixed",
                        top: "15%",
                        left: "15%",
                        padding: "20px",
                        borderRadius: "5px",
                        boxShadow: "0 0 5px gray",
                    },
                }}
            >
                <img className="modalImage" src={`data:image/jpeg;base64,${diagramImage}`} alt="Item Diagram" />
            </Modal>

            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="poSummaryText">{renderSummaryInfo()}</div>
                        {qcDetail.orderType !=='Plant Transfer'  && <div className="poSummaryText2">
                            <span className="vendorSummaryRole" style={{ textTransform: "uppercase" }}>
                                {qcDetail.companyName}
                            </span>

                            <span className="poSummaryValue">
                                {`ID: ${qcDetail.companyId}`}
                            </span>
                            <span className="poSummaryValue">
                                {qcDetail.companyPinCode !== null
                                    ? ` ${qcDetail.companyAddress} - ${qcDetail.companyPinCode}`
                                    : qcDetail.companyAddress}
                            </span>
                            <span className="poSummaryValue">
                                {qcDetail.companyMobile !== null || qcDetail.companyPhone !== null ? `\nPhone: ` : null}
                                {qcDetail.companyMobile !== null ? qcDetail.companyMobile : null}
                                {qcDetail.companyPhone !== null ? `, ${qcDetail.companyPhone} ` : null}
                            </span>
                            <span className="poSummaryValue">{qcDetail.companyEmail !== null ? `Email Id: ${qcDetail.companyEmail}` : null}</span>

                            <span className="poSummaryValue">{qcDetail.companyGstNo !== null ? `GST No: ${qcDetail.companyGstNo}` : null}</span>
                        </div>}
                    </div>

                    <div className="vendorDetailTabArea">
                        {qcDetail.qcLineItems !== undefined && qcDetail.qcLineItems?.length > 0 ? <RenderTable /> : null}
                    </div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                No Items:
                                <span> {qcDetail.qcLineItems?.length}</span>
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                Quality Checking Instruction: {qcDetail.instruction !== null ? qcDetail.instruction : "NA"}
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                Quality Checking Document:{" "}
                                {qcDetail.qcDocument !== null ? (
                                    <span
                                        style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
                                        onClick={() => {
                                            setDiagramModalOpen(true);
                                            setDiagramImage(qcDocument);
                                        }}
                                    >
                                        Open link
                                    </span>
                                ) : (
                                    "NA"
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {approval ? <ApproveButtons /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editQcId: (qcId) => dispatch(editQcId(qcId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityDetail);
