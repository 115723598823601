import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import TopBanner from "../SmallComponents/TopBanner";
import screenControlIcon from "../../assets/screen_access.svg";
import PageTitle from "../SmallComponents/PageTitle";
import ScreenControlList from "./ScreenControlList";
import CreateScreenControl from "./CreateScreenControl";
import EditScreenControl from "./EditScreenControl";
import ViewScreenControl from "./ViewScreenControl";
import { connect } from "react-redux";

const ScreenControl = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("screenControlList");

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "screenControlList" }];

    var createScreenControlButton = {
        buttonName: "Create Screen Control",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createScreenControl",
    };

    const renderSection = (section) => {
        console.log(section);
        switch (section) {
            case "screenControlList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={screenControlIcon} pageTitle="Screen control List" 
                                    buttonList={isLogged.access["Screen Control"] === "Create" || isLogged.access["Screen Control"]==="Edit" ? 
                                    [createScreenControlButton] : null} />
                        <ScreenControlList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createScreenControl":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={screenControlIcon} pageTitle="Create Screen control" buttonList={goBackButton} />
                        <CreateScreenControl setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editScreenControl":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={screenControlIcon} pageTitle="Edit Screen control" buttonList={goBackButton} />
                        <EditScreenControl setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "viewScreenControl":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={screenControlIcon} pageTitle="View Screen control" buttonList={goBackButton} />
                        <ViewScreenControl setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Screen Control" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null) (ScreenControl);
