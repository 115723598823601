import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";


const CustomerLedgerList = ({ isLogged, addTokenToState,storeInfo,setCurrentSection }) => {

  const [ledgerData,setLedgerData]=useState({});
  const [totalCrDrAmount, setTotalCrDrAmount] = useState({});
  const [isLoading,setLoading] = useState(true);
  useEffect(() => {
    getInformation();
    // eslint-disable-next-line
  }, []);
  async function getInformation() {
    await checkToken();
    await getLedgerDetails(storeInfo.customerLedger)
  }
  const history = useHistory();
  const [noRecords, setNoRecords] = useState(false);

  async function checkToken() {
    console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }
  async function getLedgerDetails(data){
    var response = await fetchData({
      requestingPage: "cusReceiptList",
      method: "post",
      url: "fetch/customers-ledger",
      data,
      headers: {token: isLogged.accessToken , module: "Sales Order"}
    });
    var totalAmount = {
      'totalCr': 0,
      'totalDr': 0
    };

    if (response.msg === "success") {
      if(response.asset?.voucherLineItems?.length) {
        setLedgerData(response.asset);
        response.asset?.voucherLineItems.map((item) => {
          if (item.voucher_type === 'Cr') {
            totalAmount.totalCr += Number(item.voucher_amount);
          } else {
            totalAmount.totalDr += Number(item.voucher_amount);
          }
        });
        setTotalCrDrAmount(totalAmount);
      }
      else{
        setNoRecords(true);
      }
      setLoading(false);
    } else {
      setCurrentSection("customerLedger")
      console.log(response);
    }
  }


  return (
    <React.Fragment>
      <div className="formArea" style={{marginTop:"0",padding:"0"}}>
        <div
          style={{
            width: "1000px",
            height: "500px",
            margin: "0 2rem 4rem",
            padding: "1rem",
            border: "1px solid inherit",
            borderRadius: "5px",
            backgroundColor: "inherit",
          }}>
            {isLoading ?  <div
                        className="loader"
                        style={{ margin: "1rem auto" }}></div> : null}
          {noRecords ?  <div className="noRecordsYet">No items available</div> :
          <div className="detailsPageWrapper"  style={{overflowX:"unset",flexDirection:"row",width:"110%"}}>
            <div className="detailsPageContainer" style={{minHeight: "1400px"}}>
              
              {/*customer & seller details*/}
              <table className="invoiceBill" style={{width:"100%",border:"none"}}>
                  <tbody>
                    <tr key="customerDetails">
                      <td style={{width:"300px",border:"none"}}>&nbsp;</td>
                      <td  style={{textAlign:"center",border:"none",borderBottom:"0.25px solid black"}}><RenderCustomer customer={ledgerData.customer}/></td>
                      <td style={{width:"300px",border:"none"}}>&nbsp;</td>
                    </tr>
                    <tr key="plantDetails">
                      <td colSpan={3} style={{textAlign:"center",border:"none"}}><RenderPlantDetails plant={ledgerData.plant}/></td>
                    </tr>
                    <tr key="dateRange">
                      <td colSpan={3} style={{textAlign:"center",fontSize: "11px",border:"none"}}>{ledgerData?.fromDate?.split('T')[0] + ' to ' + ledgerData?.toDate?.split('T')[0]}</td>
                    </tr>
                  </tbody>
              </table>
              {/* voucher line items*/}
              <table className="innerTable" style={{width:"100%",border:"none"}}>
                  <thead>
                    <tr className="invoiceDetailContactsTableHeader" key="headers" style={{color:"#000"}}>
                      {["Date","Particulars","Invoice/Receipt No","Debit","Credit"].map((item)=>
                      <td key={Math.random()} style={{borderLeft:"none",borderRight:"none",textAlign:"center"}}>
                        {item}
                        </td>)
                      }
                      </tr>
                  </thead>
                  <tbody>
                    {ledgerData.voucherLineItems?.map((row, i)=>(
                      <tr className="invoiceDetailContactsTableRows" key={i}>
                          <td style={{border:"none",textAlign:"center"}}>{row.voucher_date+" "+row.voucher_type}</td>
                          <td style={{border:"none",textAlign:"center"}}>{row.voucher_name}</td>
                          <td style={{border:"none",textAlign:"center"}}>{row.voucher_no}</td>
                          <td style={{border:"none",textAlign:"center"}}>{row.voucher_type ==='Cr' ? row.voucher_amount : <>&nbsp;</>}</td>
                          <td style={{border:"none",textAlign:"center"}}>{row.voucher_type === 'Dr' ? row.voucher_amount : <>&nbsp;</>}</td>
                      </tr>
                    ))}
                    
                   <tr className="invoiceDetailContactsTableRows" key="total">
                      <td colSpan={3} style={{border:"none"}}></td>
                      <td style={{borderLeft:"none",borderRight:"none",textAlign:"center", fontWeight:600}}>
                        {totalCrDrAmount.totalCr}
                      </td>
                      {/* <td colSpan={2} style={{border:"none"}}></td> */}
                      <td style={{borderLeft:"none",borderRight:"none",textAlign:"center", fontWeight:600}}>
                        {totalCrDrAmount.totalDr}
                      </td>
                   </tr>
                  </tbody>
              </table>
            </div>
          </div>}
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    storeInfo: state.storeInfo
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export const RenderPlantDetails=({plant={}})=>{

  return(
      <div className="vendorSummary" style={{maxWidth:"max-content", margin: "0",display:"inline-flex",verticalAlign:"top"}}>  
      <div className="poSummaryText" style={{maxWidth:"max-content"}}>
          <span className="vendorSummaryCategory" style={{color: "#000", textTransform: "capitalize"}}>Seller</span>
          <span className="vendorSummaryValue" style={{fontSize: "12px"}}>{`Company: ${plant.parentPlantName || plant.plantName}`}</span>
          <span className="vendorSummaryValue" style={{fontSize: "12px"}}>{`Plant: ${plant.plantName}`}</span>
          <span className="vendorSummaryValue" style={{fontSize: "12px"}}>{`${plant.city}-${plant.zipCode}, ${plant.state}`}</span>
          <span className="vendorSummaryValue" style={{fontSize: "12px"}}>{`GSTIN/UIN: ${plant.gstNo}`}</span>
      </div>
  </div>
  )
};

export const RenderCustomer=({customer={}})=>{

  return(
      <div className="vendorSummary" style={{maxWidth:"max-content", margin: "0",display:"inline-flex",verticalAlign:"top"}}>
      <div className="poSummaryText">
          <span className="vendorSummaryCategory"  style={{color: "#000", textTransform: "capitalize"}}>
              {` ${customer.CompanyName?.toUpperCase()}`}
          </span>
          <span className="vendorSummaryValue">
              {`ID: ${customer.CustomerId}`}
          </span>
          <span className="vendorSummaryValue">
          {customer.Address?.split(',').join(`,\n\n`)}, <br />
              {customer.ZipCode !== null
                  ? `${customer.City}  - ${customer.ZipCode}`
                  : customer.City},
                  {customer.State}
          </span>
          <span className="vendorSummaryValue">
              {customer.CustomerMobile !== null || customer.CustomerPhone !== null ? `\nPhone: ` : null}
              {customer.CustomerMobile !== null ? customer.CustomerMobile : null}
              {customer.customerPhone !== null ? `, ${customer.CustomerPhone} ` : null}
          </span>
          <span className="vendorSummaryValue">
              {customer.CustomerEmail !== null ? `Email Id: ${customer.CustomerEmail}` : null}
          </span>

          <span className="vendorSummaryValue">
              {customer.GSTNo !== null ? `GSTIN/UIN: ${customer.GSTNo}` : null}
          </span>
          </div> 
      </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLedgerList);
