import React from "react"
import { connect } from "react-redux";
import { numToWords, getIndianCurrency } from '../CommonFunctions/utils';
const ShortForm = ({ lineItemChunk, invoiceDetail, isLogged, deliveryAddressDetail, igst }) => {
  const mainItemsTaxRow = igst ? ["IGSTAmount", "Less RoundedOff"] : ["CGSTAmount", "SGSTAmount", "Less RoundedOff"]
  const RenderInvoiceDetailTable = () => {

    return (
      <>
        <table className="invoiceBill" style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <td><RenderConsigneeAddress context={"Bill"} addressDetail={invoiceDetail} /></td>
              </td>
              <td rowSpan={2} style={{ border: "none", padding: "24px", height: "100%",width: "17rem" }}>
                {renderSummaryInfo()}
              </td>
            </tr>
          </tbody>
        </table>
        {invoiceDetail.invoiceStatus === "Declined" ? (
          <div style={{ marginTop: "1rem" }}>
            <span style={{ color: "red", fontWeight: "bold" }}>Reason : </span> {invoiceDetail.declineReason}
          </div>
        ) : null}
      </>
    )
  }

  const RenderConsigneeAddress = ({ context, addressDetail = {} }) => {
    return (
      <div className="vendorSummary" style={{ maxWidth: "max-content", margin: "0", display: "inline-flex", verticalAlign: "top" }}>
        <div className="poSummaryText">
          <span className="vendorSummaryCategory" style={{ color: "#000", textTransform: "capitalize" }}>
            {`${context} to: ${addressDetail.customerName?.toUpperCase()}`}
          </span>
          <span className="vendorSummaryValue">
            {`ID: ${addressDetail.customerId}`}
          </span>
          <span className="vendorSummaryValue">
            {addressDetail.customerAddress?.split(',').join(`,\n\n`)}, <br />
            {addressDetail.customerPinCode !== null
              ? `${addressDetail.customerCity}  - ${addressDetail.customerPinCode}`
              : addressDetail.customerCity},
            {addressDetail.customerState}
          </span>
          <span className="vendorSummaryValue">
            {addressDetail.customerMobile !== null || addressDetail.customerPhone !== null ? `\nPhone: ` : null}
            {addressDetail.customerMobile !== null ? addressDetail.customerMobile : null}
            {addressDetail.customerPhone !== null ? `, ${addressDetail.customerPhone} ` : null}
          </span>
          <span className="vendorSummaryValue">
            {addressDetail.customerEmail !== null ? `Email Id: ${addressDetail.customerEmail}` : null}
          </span>

          <span className="vendorSummaryValue">
            {addressDetail.customerGstNo !== null ? `GSTIN/UIN: ${addressDetail.customerGstNo}` : null}
          </span>
        </div>
      </div>
    )
  }

  const renderSummaryInfo = () => {
    if (invoiceDetail !== null) {
      return (
        <React.Fragment>
                  <span className="vendorSummaryCategory">No      :</span>
                  <span className="vendorSummaryValue">{invoiceDetail.invoiceNo}</span><br></br>
                  <span className="vendorSummaryCategory">Date      :</span>
                  <span className="vendorSummaryValue">{invoiceDetail.soDate}</span><br></br>
                  <span className="vendorSummaryCategory">Vehicle No   :</span>
                  <span className="vendorSummaryValue">{}</span><br></br>
        </React.Fragment>
      );
    }
  };

  const RenderSeller = () => {

    return (
      <div className="vendorSummary" style={{ maxWidth: "max-content", margin: "0", display: "inline-flex", verticalAlign: "top" }}>
        <div className="poSummaryText" style={{ maxWidth: "max-content" }}>
          <span className="vendorSummaryCategory" style={{ color: "#000", textTransform: "capitalize" }}>Seller</span>
          <span className="poSummaryValue">{`Company: ${invoiceDetail.parentPlantName || invoiceDetail.plantName}`}</span>
          <span className="poSummaryValue">{`Plant: ${invoiceDetail.plantName}`}</span>
          <span className="poSummaryValue">{`${invoiceDetail.plantCity}-${invoiceDetail.plantZipCode}, ${invoiceDetail.plantState}`}</span>
          <span className="poSummaryValue">{`GSTIN/UIN: ${invoiceDetail.plantGstNo}`}</span>
        </div>
      </div>
    )
  };

  const amountInWords = (amount) => {
    if (amount) {
      let result;
      amount = amount.toString().split(".");
      result =
        numToWords(parseInt(amount[0])) +
        (amount[1] > 0
          ? " and " + numToWords(parseInt(amount[1])) + " Paise Only"
          : " Only");
      return result;
    }
  };

  const RenderTaxRow = (colName) => {

    return (
      <tr className="invoiceDetailContactsTableRows" key={colName}>
        <td style={{ borderBottom: "none", borderTop: "none" }}>&nbsp;</td>
        {lineItemParams.map((key, i) => {
          switch (key) {
            case "sectionName":
              return <td key={i} style={{ borderBottom: "none", borderTop: "none", textAlign: "right" }}><b><i>{colName}</i></b></td>

            case "amount":
              if (colName == "Less RoundedOff") {
                let roundedOff = invoiceDetail.totalAmount?.split('.')?.[1];
                roundedOff = roundedOff || '00'
                roundedOff = roundedOff?.toString()?.length > 1 ? roundedOff : roundedOff + '0'
                return <td key={i} style={{ borderBottom: "none", borderTop: "none", textAlign: "right" }}>{'₹ 00.' + (roundedOff)}</td>
              } else if (colName == 'Less Discount') {
                return <td key={i} style={{ borderBottom: "none", borderTop: "none", textAlign: "right" }}>{invoiceDetail['finalDiscount']}</td>
              }
              else
                return <td key={i} style={{ borderBottom: "none", borderTop: "none", textAlign: "right" }}>{getIndianCurrency(invoiceDetail[colName])}</td>

            default:
              return <td key={i} style={{ borderBottom: "none", borderTop: "none" }}>&nbsp;</td>;
          }
        })}
      </tr>
    )
  }


  const RenderTable = ({ currentPageLineItems, pageNumber }) => {
    return (
      <>
        <table className="innerTable" style={{ width: "100%", height: "90%" }}>
          <thead>
            <tr className="invoiceDetailContactsTableHeader" style={{ color: "#000" }}>
              <td>S.No.</td>
              <td>Description of Goods</td>
              {isLogged.showHideWeight ? (
                <>
                  <td>Weight From(gms)</td>
                  <td>Weight To(gms)</td>
                  <td>Net Weight(gms)</td>
                  <td>Gross Weight(gms)</td>
                </>
              ) : null}
              <td>HSN/SAC No</td>
              <td>UOM</td>
              <td rowSpan={"2"}>{invoiceDetail.bundleName || "Bundle" }</td>
              <td>Quantity</td>
              <td>Rate</td>
              <td>Amount</td>
            </tr>
          </thead>
          <tbody>
            <RenderTableRows currentPageLineItems={currentPageLineItems} pageNumber={pageNumber} tab="contact" />

            {pageNumber === lineItemChunk.length ?
              <>

                {mainItemsTaxRow.map((tax) => RenderTaxRow(tax))}
                <RenderEmptyRows columns={lineItemParams} emptyRows={6} />
                <tr>
                  <td colSpan={lineItemParams.length}>
                    <b>Total Amount</b>
                  </td>
                  <td className="align-right">
                    <div className="poNetTotalAmount align-right" style={{ color: "#000" }}><b>{getIndianCurrency(Math.round(invoiceDetail["totalAmount"]))}</b></div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={lineItemParams.length + 1}>
                    <div className="medium-font">Amount Chargeable(in Words):&emsp; <b>INR&ensp; </b>{amountInWords(invoiceDetail.totalAmount?.split('.')[0])}</div>
                  </td>
                </tr>
              </>
              : <tr className="invoiceDetailContactsTableRows">
                <td style={{ textAlign: "right" }} colSpan={lineItemParams.length + 1}>continued to Page Number {pageNumber + 1}</td>
              </tr>}
              {
                <tr colSpan="6" rowSpan={2}>
                  <td colSpan="3">
                    <div className="vendorSummary" style={{
                          maxWidth: "max-content",
                          margin: "0px",
                          display: "inline-flex",
                          verticalAlign: "top"
                    }}>
                      <div className="poSummaryText">

                  <b>Invoice Issued Under</b>
                  <span className="poSummaryValue">
                    Section 31 CGST ACT,2017
                  </span>
                  <span className="poSummaryValue">Section 31 TNGST ACT,2017</span>
                  <span className="poSummaryValue">Rule H6 of CGST Rules 2017</span>
                  <span className="poSummaryValue">Rule H6 of TNGST Rules 2017</span>
                  <span className="poSummaryValue">Section 20 of IGST ACT,2017</span>
                      </div>
                      </div>
                  </td>
                  <td colSpan={6} style={{padding:"0px"}}>
                    <InnerTable/>
                  </td>          
                </tr>
              }
          </tbody>
        </table>

        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
          <div>
            <span className="small-font vendorSummaryRole" style={{ color: "#000" }}><b><u>Terms and Conditions</u></b></span>
            <div className="medium-font poSummaryValue" style={{ maxWidth: "400px", wordBreak: "break-word" }}>
              <ol style={{paddingLeft: "20px"}}>
              <li>
              No CLAIM ARE ENTERTAINED UNLESS BROUGHT TO OUR NOTICE IN WRITING WITHIN TWO DAYS ON RECEIPT OF GOODS
              </li>
              <li>
              INTEREST @ 24% WILL BE CHARGED ON OVERDUE PAYMENT-PAYMENT WITH IN 30 DAYS
              </li>
              <li>
              WE ARE NOT RESPONSIBLE FOR BREAKAGE or DAMAGE IN TRANSIT
              </li>
              <li>
                TRANSIT INSURANCE IF ANY IS TO BE TAKEN BY THE BUYER
              </li>
              </ol>
              </div>
          </div>
        </div>
        {pageNumber === lineItemChunk.length ?
          <>
            <div style={{ width: "100%", position: "absolute", bottom: "15px" }}>
              <span>
            <div style={{textAlign: "end",paddingRight: "70px"}}>{invoiceDetail.orgName}</div></span>
              <span style={{ width: "50%" }}>
                <div className="medium-font poSummaryValue" style={{ color: "#000", textAlign: "right", marginTop: "5px", marginRight: "5px" }}><b>Authorized Signatory</b></div>
              </span>
            </div>
          </> : null}
      </>
    );
  };

  const InnerTable =()=>{
    const totalValue = Number(invoiceDetail.totalAmount) +( Number(invoiceDetail["otherCharges"]) + Number(invoiceDetail["transportCharges"]));
    return(<>
    <table className="innerTable" style={{width:"100%",height: "100%"}}>
      <tbody>
        <tr>
         <td>Other Charges</td>
         <td>{getIndianCurrency(invoiceDetail["otherCharges"]|| 0)}</td>
        </tr>
        <tr>
          <td>Transport Charges</td>
          <td>{getIndianCurrency(invoiceDetail?.transportCharges || 0)}</td>
        </tr>
        {
          ["Total Value",<>&nbsp;</>,<>&nbsp;</>,<>&nbsp;</>].map((item,i)=>{
            return(
              <tr key ={i}>
                <td style={{width: "70%"}}><b>{item}</b></td>
             <td >{ item === "Total Value" ? totalValue :null}</td>
            </tr>
            )
          })

        }
        <tr>
          <td><b>Grand Total</b></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    </>)
  }

  const lineItemParams = 
  // isLogged.showHideWeight ? ["sectionName", "from", "to", "netWeight", "grossWeight", "hsnCode",
    // "uom","quantity","bundle", "unitPrice", "amount"] :
    ["sectionName", "hsnCode","uom","quantity","bundle", "unitPrice", "amount"];

  const RenderTableRows = ({ currentPageLineItems, pageNumber }) => {

    if (currentPageLineItems?.length > 0) {
      return (<>
        {currentPageLineItems.map((row, j) => {
          return (
            <tr className="invoiceDetailContactsTableRows" key={j}>
              <td style={{ borderBottom: "none", borderTop: "none" }}>{((pageNumber - 1) * 16) + j + 1}</td>
              {lineItemParams.map((key, i) => {
                if (key === 'amount' || key === "unitPrice") {
                  return <td style={{ borderBottom: "none", borderTop: "none" }} className="align-right" key={i}>{row[key] !== null ? getIndianCurrency(row[key]) : "-"}</td>;
                } else {
                  return <td style={{textAlign:key==="sectionName"?"left":"right", borderBottom: "none", borderTop: "none" }} key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                }
              })}
            </tr>
          );
        })}
        <tr className="invoiceDetailContactsTableRows">
          {lineItemParams.map((key, i) => {
            return <td key={i} style={{ borderBottom: "none", borderTop: "none" }}>&nbsp;</td>;
          })}
          <td style={{ textAlign: "right", borderBottom: "none" }}>{getIndianCurrency(currentPageLineItems?.reduce((prev, curr) => prev + curr["amount"], 0))}</td>
        </tr>
        <RenderEmptyRows columns={lineItemParams} emptyRows={21 - currentPageLineItems.length} />
      </>);
    } else return (<></>)
  };

  const RenderEmptyRows = ({ columns, emptyRows }) => {
    return (
      Array.from({ length: emptyRows }).map((empty, ind) => (
        <tr className="invoiceDetailContactsTableRows" key={ind}>
          <td style={{ borderBottom: "none", borderTop: "none" }}>&nbsp;</td>
          {columns.map((key, i) => {
            return <td key={i} style={{ borderBottom: "none", borderTop: "none" }}>&nbsp;</td>;
          })}
        </tr>)
      )
    )
  }


  return (
    <>
      { //Invoice Pages
        lineItemChunk.length > 0 ?
          lineItemChunk.map((currentPage, i) => {
            return (
              <div className="detailsPageContainer" key={i}>
                <div className="invoicePageTitle">Tax Invoice</div>
                <div style={{ border: "0.25px solid black", minHeight: "1100px", position: "relative" }}>
                  <RenderInvoiceDetailTable />
                  <div style={{padding: "10px 10px"}}>
                  <RenderConsigneeAddress context={"Ship"} addressDetail={deliveryAddressDetail} />
                  <div style={{float: "right",
                                width: "18rem",
                             }}>
                  <span className="vendorSummaryCategory">DC No    :</span>
                  <span className="vendorSummaryValue">{}</span><br></br>
                  <span className="vendorSummaryCategory">DC Date      :</span>
                  <span className="vendorSummaryValue">{}</span><br></br>
                  <span className="vendorSummaryCategory">Order No   :</span>
                  <span className="vendorSummaryValue">{invoiceDetail.soNo}</span><br></br>
                  <span className="vendorSummaryCategory">Order Date   :</span>
                  <span className="vendorSummaryValue">{invoiceDetail.soDate}</span><br></br>
                  
                  </div>
                  </div>
                  <RenderTable currentPageLineItems={currentPage} pageNumber={i + 1} />
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <span style={{ fontSize: "9px" }}>SUBJECT TO {invoiceDetail.plantCity?.toUpperCase()} JURISDICTION</span>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <span style={{ fontSize: "9px" }}>This is a Computer Generated Invoice</span>
                </div>
              </div>
            )
          }) : ''
      }
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    // poInfo: state.poInfo,
  };
};

export default connect(mapStateToProps)(ShortForm);