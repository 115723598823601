import React, { useState, useEffect, Fragment, useCallback } from 'react'
import { connect } from 'react-redux'
import FormElement, { renderFormElements } from 'components/SmallComponents/FormElement';
import CreateEditModal from "../../SmallComponents/CreateEditModal";
import update from "immutability-helper";
import fetchData from 'serverCall/fetchData';
import { validateMandatory } from 'components/CommonFunctions/ValidateFields';
import { useHistory } from "react-router";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import deleteIcon from "assets/delete.svg";
import * as XLSX from "xlsx";

const CreatePackingSlip = ({ isLogged, addTokenToState, setSection ,screenName:screenName}) => {
  //elementlist for loading slip component 
  const loadingSlipElementList = ["plantId","storageId","customerId","packingSlipDate","soNo","vehicleNo"]
  const [packingSlipParams, setPackingSlipParams] = useState({
    plantId: {
      inputType: "options",
      value: "",
      hintText: "Plant Name",
      mandatory: true,
      error: false,
      errorMessage: "Please Select a Plant Id",
      colSpan: 4,
      options: [],
    },
    storageId: {
      inputType: "options",
      value: "",
      hintText: "Storage Name",
      error: false,
      errorMessage: "Please Select a Storage Name",
      mandatory: true,
      colSpan: 4,
      options: [],
    },
    customerId: {
      inputType: "options",
      value: "",
      hintText: "Customer Name",
      error: false,
      errorMessage: 'Please Select a Customer Name',
      colSpan: 4,
      options: [],
      mandatory: true,
    },
    packingSlipDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Date",
      colSpan: 4,
      mandatory: true,
      error: false,
      errorMessage: "Please Select a Date",
    },
    invoiceNo: {
      inputType: "options",
      value: "",
      options: [],
      error: false,
      errorMessage: "Please Select Invoice Number",
      colSpan: 4,
      mandatory: true,
      hintText: "Invoice No",
    },
    soNo: {
      // inputType:"text",
      inputType: screenName === "Packing"?"text":"options",
      value: "",
      options: [],
      error: false,
      errorMessage: "Please Select Sale Order Number",
      colSpan: 4,
      mandatory: true,
      hintText: "So No",
      disabled: screenName === "Packing"?true:false,
    },

    vehicleNo
    : {
      inputType: "text",
      value: "",
      hintText: "Vehicle No",
      error: false,
      errorMessage: "Please Insert a Vehicle No",
      colSpan: 4,
      mandatory: false
    },
    // loadingSlipId :{
    //   inputType: "multiSelect",
    //   value: [],
    //   hintText: "LoadingSlip Id",
    //   error: false,
    //   errorMessage: "Please Select a loadingSlip Id",
    //   mandatory: false,
    //   colSpan: 4,
    //   options: [],
    // }
  });

  const [lineItemsParams, setBundleListParams] = useState({
    sectionNo: {
      inputType: "options",
      options: [],
      value: "",
      hintText: "Section No",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please Enter Section No",
    },
    bundleNo: {
      inputType: "options",
      options: [],
      value: "",
      hintText: "Bundle No",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please Enter Bundle No",
    }
  });
  const history = useHistory();
  const [bundleList, setBundleList] = useState([]);
  const [updateBundleList, setUpdateBundleList] = useState([])
  const [errors, setErrors] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [invoiceAppList, setInvoiceAppList] = useState([]);
  const [selectedBundleList, setSelectedBundleList] = useState([])
  const [totalweight, setTotalWeight] = useState(0)
  const [totalquantity, setTotalQuantity] = useState(0)
  const [totalBc, setTotalBc] = useState(0)
  const [bulkFile, setBulkFile] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [toServerData, setToServerData] = useState([]);

  useEffect(() => { customerDet() }, []);

  async function customerDet() {
    await checkToken();
    await getCustomers();
    await getSalesInvoiceApproved();
  }

  async function getOrderList() {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "post",
      url: `so-fetch/so-approved-list-mini`,
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
    });
    if (data.msg === "success") {
      var orderList = [];
      data.asset.forEach((item) => {
        var orderObj = { optionId: item.soNo, optionName: item.soNo };
        orderList.push(orderObj);
      });
      return orderList;
    }
  }

  async function getItems() {
    let itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data:{tab:"Active",type:"Sales"},
      headers: { token: isLogged.accessToken, module: "Items" },
    });

    let itemList = [];
    if (itemData.msg === "success") {

      itemData.asset.forEach((item) => {
        let itemObj = {};
        itemObj.optionId = item.item.itemCode;
        itemObj.optionName = item.item.itemCode
        // itemObj.optionName = item.item.itemCode
        // itemObj.optionName =
        //   "  " + item.item.itemCode + " - " + item.item.itemName;
        itemObj.itemId = item.item.itemId;
        itemObj.mrp = item.item.mrp;
        itemObj.categoryId = item.item.categoryId;
        itemObj.sectionNo = item.item.itemCode;
        itemObj.sectionName = item.item.itemName;

        itemList.push(itemObj);
      });
      return itemList
    }
  }

  const getInformation = useCallback(async () => {
    if (packingSlipParams.plantId.options.length === 0) {
      const plantConfig = {
        requestingPage: "plantList",
        method: "get",
        url: "fetch/plants",
        headers: { token: isLogged.accessToken, module: "Plant & Store" },
      };
      const bundleConfig = {
        requestingPage: "sectionMaster",
        method: "post",
        url: "fetch/bundle-line-item",
        headers: { token: isLogged.accessToken, module: "Packing Slip" },
      }
      let [plants = [], sections = []] = await Promise.all([
        fetchData(plantConfig).then((res) => res.asset),
        fetchData(bundleConfig).then((res) => res),
      ])
     let soList = await getOrderList()
      let paramsCopy = Object.assign(packingSlipParams);
      paramsCopy = update(paramsCopy, {
        plantId: { options: { $set: plants } },
        soNo: { options: { $set: soList } },
      });
      setPackingSlipParams(paramsCopy);
      let lineItemsCopy = Object.assign(lineItemsParams);

      // section drop down list with value as bundle values 
      const { sectionList, bundleList } = sections.asset;
      let sectionsList = await getItems();
      lineItemsCopy = update(lineItemsCopy, { sectionNo: { options: { $set: sectionsList } } });
      setBundleListParams(lineItemsCopy);
      setSectionList(sectionsList)
      // Adding optionId and optionName to the bundelList
      const updateBundleList = bundleList.map((bundle) =>({
        ...bundle,
        optionId: bundle.bundleId,
        optionName: bundle.bundleId.toString().padStart(6,'0')
      }))
      // set updated bundle line of items to the state
      setBundleList(updateBundleList)
    }
  }, [isLogged, packingSlipParams])

  async function checkToken() {
    //console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      //console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      //console.log("Token not changed");
    }
  };

  async function getCustomers() {
    let paramsCopy = Object.assign(packingSlipParams);
    let data = await fetchData({
      requestingPage: "customerList",
      method: "get",
      url: "fetch/customers",
      headers: { token: isLogged.accessToken, module: "Customer Receipt" },
    });

    // let loadigSlipId = await getLoadingSlipId()

    if (data.msg === "success") {
      paramsCopy = update(paramsCopy, { ['customerId']: { ['options']: { $set: data.asset } },
                                      //  ['loadingSlipId']:{['options']:{$set:loadigSlipId.asset}}
                                       });
      setCustomerList(data.asset);
    } else {
      //console.log(data);
    }
    setPackingSlipParams(paramsCopy);
  };

  // async function getLoadingSlipId(){
  //   let data = await fetchData({
  //     requestingPage: "customerList",
  //     method: "post",
  //     url: "store-fetch/loading-slip",
  //     headers: { token: isLogged.accessToken, module: "Packing Slip" },
  //   });
  //   return data;
  // }

  async function getSalesInvoiceApproved() {
    let invoices = await fetchData({
      requestingPage: "customerList",
      method: "post",
      url: "so-fetch/sales-invoice-approved-list",
      headers: { token: isLogged.accessToken, module: "Packing Slip" },
    });

    if (invoices.msg === "success") {
      let invoiceList = [];
      invoices.asset.forEach(({ invoiceNo, soNo, totalAmount, customerId,optionName }, i) => { 
        invoiceList.push({ invoiceNo, soNo, totalAmount: parseInt(totalAmount), customerId,optionId:optionName,optionName }); 
      });
      setInvoiceAppList(invoiceList);
    }
  }

  useEffect(() => {
    getInformation();
  }, [getInformation]);
  

  const updatePackingParams = (props) => {
    let { section, paramName, key, value, wholeObject } = props;

    switch (section) {
      case "general":
        let packingParamsCopy = Object.assign(packingSlipParams);
        packingParamsCopy = update(packingParamsCopy, { [paramName]: { [key]: { $set: value } } });
        if (paramName === "plantId") {
          if (value) {
            packingParamsCopy = update(packingParamsCopy, { storageId: { options: { $set: wholeObject.storage } } })
          } else {
            packingParamsCopy = update(packingParamsCopy, { storageId: { options: { $set: [] } } })
          }
        }
        if (paramName === "customerId"){
          if(value){
            let invoiceOptions = invoiceAppList.filter((invoice) => invoice.customerId === packingParamsCopy.customerId.value);
            packingParamsCopy = update(packingParamsCopy, { invoiceNo: { options: { $set: invoiceOptions } } });
          }
          else {
            packingParamsCopy = update(packingParamsCopy, {
              invoiceNo: { value: { $set: "" }, options: { $set: [] } },
              soNo: { value: { $set: "" } }
            });
          }
        }
        if (paramName === "invoiceNo") {
          if (value) {
            packingParamsCopy = update(packingParamsCopy, { soNo: { value: { $set: wholeObject.soNo } } })
          }
          else {
            packingParamsCopy = update(packingParamsCopy, { soNo: { value: { $set: "" } } })
          }
        }
        setPackingSlipParams(packingParamsCopy);
        break;
      case "items":
        // update the params like sectionNo in the drop down
        let lineItemsParamsCopy = Object.assign(lineItemsParams);
        lineItemsParamsCopy = update(lineItemsParamsCopy, { [paramName]: { [key]: { $set: value } } });
        if (paramName === "sectionNo") {
          if (value) {
            let bundleListCopy =  bundleList
            // filtering bundleId related to sectionId
             bundleListCopy  = bundleListCopy.filter(bundleItem => bundleItem.sectionId === value ) 
             // check whether the bundleId already selected in SelectedBundleList
             bundleListCopy = bundleListCopy.filter(item1 => !selectedBundleList.some(item2 => (item2.sectionId === item1.sectionId && item2.bundleId === item1.bundleId)))                              
             setUpdateBundleList(bundleListCopy)
            lineItemsParamsCopy = update(lineItemsParamsCopy, { bundleNo: { options: { $set: bundleListCopy } } })
          }
          else {
            lineItemsParamsCopy = update(lineItemsParamsCopy, { bundleNo: { options: { $set: [] } } })
          }
        }
        setBundleListParams(lineItemsParamsCopy);
        break;
      default:
        return;
    }
  }

  const editSubSectionItem = (action, index) => {

    if (action === "delete") {

      // delete the selected element index from selected bundleList
      let selectedBundleListCopy = [...selectedBundleList];
      selectedBundleListCopy.splice(index, 1)
      setSelectedBundleList(selectedBundleListCopy);
    };
  }
  const addBundleToLine = () => {
    let lineCopy = updateBundleList.slice();
    let errorList = []
    let   psCopy = Object.assign(lineItemsParams);
    
    if(selectedBundleList.length < 0) {
    if (!lineItemsParams.sectionNo.value && !lineItemsParams.bundleNo.value) {
      psCopy.sectionNo.error = true;
      psCopy.bundleNo.error = true;
      errorList.push("Provide Valid Packing Slip Details");
    }
  }
    if (errorList.length === 0) {   // if there is no error

      const selectedBundleID = lineItemsParams.bundleNo.value;
      let bundleListCopy = lineCopy;
      // select all the bundles which is match with section id , to show in table
      bundleListCopy = bundleListCopy.filter(bundleItem => bundleItem.bundleId === selectedBundleID )
      let updatedBundle = [...selectedBundleList, ...bundleListCopy]
      const sortedBundleList = updatedBundle.slice().sort((a, b) => a.bundleId - b.bundleId);
      let trimmedList = psCopy.bundleNo.options.filter((item) => item.optionId !== psCopy.bundleNo.value);
      // reset section field
      psCopy = update(psCopy, { sectionNo: { value: { $set: "" }},bundleNo: { value: { $set: "" }, options: { $set: trimmedList } } });
      setBundleListParams(psCopy);
      setUpdateBundleList(trimmedList)
      setSelectedBundleList(sortedBundleList);
    } else {
      setErrors(errorList);
    }
  }

  useEffect(()=>{
    if(toServerData.length || selectedBundleList.length){
      let list = toServerData.length?toServerData:selectedBundleList
      const totals = list.reduce((accumulator, item) => {
        accumulator.totalWeight += item.weight;
        accumulator.totalQuantity += item.quantity;
        return accumulator;
      }, { totalWeight: 0, totalQuantity: 0});
        let totalBc = list.length
        setTotalBc(totalBc)
        setTotalQuantity(totals.totalQuantity);
        setTotalWeight(totals.totalWeight)
    }
  },[selectedBundleList.length,toServerData.length]);

  const RenderTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr className="createVendorContactsTableHeader">
            <td>Section No</td>
            <td>Section Name</td>
            <td>Bundle No</td>
            <td>Quantity</td>
            <td>Cutting Length</td>
            <td>Weight Range</td>
            <td className='itemIndiTabActions'>Actions</td>
          </tr>
        </thead>
        <tbody>
          {selectedBundleList.length > 0 ?
            selectedBundleList.map((item, index) => (
              <tr key={index} className="createVendorContactsTableRows">
                <td>{item.sectionNo}</td>
                <td>{item.sectionName}</td>
                <td>{(""+item.bundleId).padStart(6,'0')}</td>
                <td>{item.quantity}</td>
                <td>{item.cuttingLength}</td>
                <td>{item.weight}</td>
                <td className='itemIndiTabActions'>
                  <img
                    alt="Delete Record"
                    className="createVendorContactsAction"
                    src={deleteIcon}
                    onClick={() => {
                      let reset = window.confirm("Delete record?");
                      if (reset) {
                        editSubSectionItem("delete", index);
                      }
                    }}
                  />
                </td>
              </tr>
            ))
            :
            <tr className='createVendorContactsTableRows'>
              {Array.from({ length: 7 }).map((key, index) => <td key={index}>&nbsp;</td>)}
            </tr>
          }
        </tbody>
      </table>
    )
  }
  const renderSubsection = () => {
    return (
      <Fragment>
        <div className="purchaseOrderIndividualItemsArea" style={{ gridAutoRows: "60px" }}>
          {renderFormElements({
            section: "items", elementList: ["sectionNo","bundleNo"],
            param: lineItemsParams, setInput: updatePackingParams, test: "bundle"
          })}
          <FormElement
            inputType="addButton"
            value="+ Add"
            colSpan={4}
            setInput={() => {
              setErrors();
              addBundleToLine();
            }}
          />
        </div>
        <RenderTable />
      </Fragment>
    )
  };

  const  checkErrors = () => {
    let errorList = [];
    let psParamCopy = Object.assign(packingSlipParams);
    let keys = Object.keys(psParamCopy)
    keys.map((item) => {
      if (psParamCopy[item].mandatory)
        psParamCopy[item].error = !validateMandatory(psParamCopy[item].value.toString());
      if (psParamCopy[item].error) {
        errorList.push(psParamCopy[item].errorMessage);
      }
    });


    let lineItemsCopy = selectedBundleList.slice();

    if (!toServerData && selectedBundleList.length < 1)
      errorList.push("Select Section No");
    if (selectedBundleList.length > 0 || toServerData.length) {
      let dataToServer = {
        plantId: psParamCopy.plantId.value,
        storageId: psParamCopy.storageId.value,
        customerId: psParamCopy.customerId.value,
        psDate: psParamCopy.packingSlipDate.value || null,
        soNo: psParamCopy.soNo.value || null,
        invoiceNo: psParamCopy.invoiceNo.value || null,
        vehicleNo: psParamCopy.vehicleNo.value || null,
        totalBundleCount : totalBc,
        totalQuantity: totalquantity,
        totalWeight: totalweight,
        // loadingSlipId :psParamCopy.loadingSlipId.value.map(item=>item.value)
      };
      dataToServer.lineItems = toServerData.length?toServerData:lineItemsCopy;
      submitData(dataToServer);
    } else {
      setPackingSlipParams(psParamCopy);
      setBundleList(lineItemsCopy);
      setErrors(errorList);
    }

  }

  const submitOkClick = () => {
    setIsOpen(false);
    if (errors.length === 0) {

      setSection("packingSlipList");
    }
  };

  async function submitData(dataToServer) {
     let url = `store-create/`
    url =  url+"packing-slip"
    let result = await fetchData({
      requestingPage: "createPsItem",
      url,
      method: "post",
      headers: { token: isLogged.accessToken, module: "Packing Slip" },
      data: dataToServer
    });
    if (result.msg === "success") {
      setDataSubmitted(true);
      setIsOpen(true);
      setModalText(`${ "Packing Slip"} Created SuccessFully`);
    } else {
      setDataSubmitted(true);
      setModalText(result.desc);
      setErrors([result.desc]);
    }
  }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState();

  const parseData = (data) => {
    var errorList = [];
    var serverList = [];
      data.forEach((row)=>{
        var rowError = false;
        if (row.sectionNo !== undefined) {
          var categInFile = sectionList.find((option) => option.optionName.toString() === row.sectionNo.toString());
            if (categInFile === undefined) {
                row.section_No_Error = "New Value";
                rowError = true;
            }else{
              row.sectionId = categInFile.optionId;
            }
        } else {
            row.section_No_Error = "Blank";
            rowError = true;
        }
        if (row.bundleNo !== undefined) {
           let bundleListCopy =  bundleList
            // filtering bundleId related to sectionId
            bundleListCopy  = bundleListCopy.filter(bundleItem => bundleItem.sectionNo.toString() === row.sectionNo.toString() ) 
            var itemTypeInFile = bundleListCopy.find((option) => option.optionId === row.bundleNo);
      console.log(itemTypeInFile,"itemTypeInFile")
                  if (itemTypeInFile === undefined) {
                      row.bundle_No_Error = "New Value";
                      rowError = true;
                  } else {
                      row.bundleId = itemTypeInFile.optionId;
                      row.bundleLineId = itemTypeInFile.bundleLineId;
                      row.quantity = itemTypeInFile.quantity;
                      row.weight = itemTypeInFile.weight;

                  }
              } else {
                  row.bundle_No_Error = "Blank";
                  rowError = true;
                 }

                 if (rowError) {
                          errorList.push(row);
                      } else {
                          serverList.push(row);
                      }
      })

     errorList.sort((a, b) => (a.Line_Number > b.Line_Number ? 1 : -1));

    setExcelData(data);
    setErrorData(errorList);
    setToServerData(serverList);
    setIsOpen(false);
};

  const readExcel = (file) => {
    setIsOpen(true);
    setModalText("Reading excel file. Please wait...");
    setDataSubmitted(false);
    const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
            const bufferArray = e.target.result;

            const workBook = XLSX.read(bufferArray, { type: "buffer" });

            const workSheetName = workBook.SheetNames[0];
            const workSheet = workBook.Sheets[workSheetName];
            const data = XLSX.utils.sheet_to_json(workSheet);

            resolve({ data });
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });

    promise.then(({ data }) => {

        parseData(data);
    });
};

  const renderErrorMessage = () => {
    if (errors?.length > 0) return errors[0];
  }
  return (
    <Fragment>
      <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
      <div className='formArea'>
        <div
          style={{
            width: "1000px",
            margin: "0 auto 2rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex" }}>
            <div
              className="createPurchaseOrderGrid"
              style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
              {renderFormElements({
                section: "general", elementList: screenName === "Packing" ? Object.keys(packingSlipParams):loadingSlipElementList,
                param: packingSlipParams, setInput: updatePackingParams
              })}

            </div>
          </div>
          {/* <div className='purchaseOrderSubSectionArea'>
            {renderSubsection()}
          </div> */}
          <div className="poSpacer">
          &nbsp;
          </div>
          <div style={{width: "max-content"}}>
          {bulkFile !== "" ? (
                                    <div className="formElement" style={{ gridColumn: `auto / span ${4}` }}>
                                        <div className="inputDone">
                                            <span
                                                style={{
                                                    flex: 1,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "25rem",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                ✅ {bulkFile.name}
                                            </span>
                                            <span
                                                style={{
                                                    paddingRight: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    "&:hover": { backgroundColor: "gray" },
                                                    maxWidth: "25rem",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    boxSizing: "border-box",
                                                }}
                                                onClick={() => {
                                                    setBulkFile("");

                                                    setErrorData([]);
                                                    setExcelData([]);
                                                }}
                                                title="Remove and Re-upload LR Copy"
                                            >
                                                ❌
                                            </span>
                                        </div>
                                    </div>
                                ) :
                                <>
          <label className={"inputLabel"} htmlFor="upload_file" style={{padding:"11px"}}>
                    Upload Bulk Bundles
          </label>
                <input
                    type="file"
                    name="items"
                    accept=".xls, .xlsx"
                    onChange={(event) => {
                        setBulkFile(event.target.files[0]);
                        readExcel(event.target.files[0]);
                    }}
                    id="upload_file"
            /></>}

            </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }} >
            <div className="poNetTotal">
              <div className="poTotalTitle">
                <span>
                  <b>Total Bundle Count</b>
                </span>
                <span className="poNetTotalAmount">{totalBc}</span>
              </div>
              <div className="poTotalTitle">
                <span>
                  <b>Total Quantity</b>
                </span>
                <span className="poNetTotalAmount">{totalquantity}</span>
              </div>
              <div className="poTotalTitle">
                <span>
                  <b>Total Weight</b>
                </span>
                <span className="poNetTotalAmount">{totalweight}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='formSubmitArea'>
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">
            {renderErrorMessage()}
          </p>
          <button className='submitButton' onClick={() => {
            setErrors([]);
            checkErrors();
          }}>
            Submit
          </button>
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePackingSlip);