import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import FormElement from "../../SmallComponents/FormElement";
import fetchData from "../../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../../CommonFunctions/ValidateFields";
import { addVendorInvoiceParams } from "redux/PurchaseOrder/PoInfoActions";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { useHistory } from "react-router-dom";

const VenderInvoiceParams = ({ isLogged, addTokenToState, setCurrentSection, addVendorInvoiceParams }) => {

  const [vendorInvoiceParams, setVendorInvoiceParams] = useState({
    vendorId: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Vendor Name",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Vendor",
      mandatory: true
    },
    plantId: {
      inputType: "options",
      hintText: "Plant Name",
      value: "",
      options: isLogged.plantOptions,
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Plant Name",
      mandatory: true
    },
    fromDate: {
      inputType: "dateFromEditPage",
      value: new Date(),
      hintText: "From Date",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select a From Date Range",
      mandatory: true
    },
    toDate: {
      inputType: "dateFromEditPage",
      value: new Date(),
      hintText: "To Date",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select a To Date Range",
      mandatory: true
    }
  });
  
  const [vendorInvoiceError, setvendorInvoiceError] = useState([]);
  const history =useHistory();

  //getting all vendors
  async function getVendors() {
    let InvoiceCopy = Object.assign(vendorInvoiceParams);
    let vendors = await fetchData({
      requestingPage: "vendorList",
      method: "get",
      url: "fetch/vendor-names",
      headers: { token: isLogged.accessToken, module: "DC List" },
    });
    let vendorOptions = [];
    if (vendors.msg === "success") {
      vendors.asset.forEach((vendor) => {
        let a = { optionName: vendor.VendorName, optionId: vendor.vendorId };
        vendorOptions.push(a);
      });
    }

    //updating vendor options 
    InvoiceCopy = update(InvoiceCopy, {
      ["vendorId"]: { ["options"]: { $set: vendorOptions } },
    });
    setVendorInvoiceParams(InvoiceCopy);
  };

  async function checkToken() {
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  //handling inputs while onchange
  const updateParameter = (element, key, value) => {
    let InvoiceCopy = Object.assign(vendorInvoiceParams);
    InvoiceCopy = update(InvoiceCopy, {
      [element]: { [key]: { $set: value } },
    });
    setVendorInvoiceParams(InvoiceCopy);
  };

  useEffect(() => {
    checkToken()
    getVendors();
  }, [])


  const checkVendorInvoiceError = () => {
    var errorList = [];
    var paramsCopy = Object.assign(vendorInvoiceParams);
    let queryParams = {}
    Object.keys(paramsCopy).forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } })
        queryParams[item] = paramsCopy[item].value
      }
      if (paramsCopy[item].error)
        errorList.push(paramsCopy[item].errorMessage);
    })
    if (errorList.length == 0) {
      addVendorInvoiceParams(queryParams)
      setCurrentSection('vendorInvoiceList');
    } else {
        setvendorInvoiceError(errorList)
    }
  };

  const renderFormElements = ({ elementList, param }) => {
    return (
      <>
        {elementList.map((element, ind) => {
          return (
            <span style={{ flex: "50%", maxWidth: ind === 0 ? "500px" : "250px" }} key={element}>
              <FormElement
                inputType={param[element].inputType}
                value={param[element].value}
                setInput={(value) => updateParameter(element, "value", value)}
                hintText={param[element].hintText}
                mandatory={param[element].mandatory}
                colSpan={param[element].colSpan}
                options={
                  param[element].inputType === "options"
                    ? param[element].options
                    : null
                }
                error={param[element].error}
                key={ind}
              />
            </span>
          );
        })}
        <FormElement
          inputType="addButton"
          value="Submit"
          colSpan={5}
          boxWidth="80px"
          setInput={() => {
            setvendorInvoiceError([]);
            checkVendorInvoiceError();
          }}
        />
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="formArea" style={{ marginTop: "0", padding: "0" }}>
        <div
          style={{
            width: "1000px",
            height: "500px",
            margin: "0 2rem 4rem",
            padding: "1rem",
            border: "1px solid inherit",
            borderRadius: "5px",
            backgroundColor: "inherit",
          }}>
          <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>
            {renderFormElements({
              elementList: ["vendorId", "plantId", "fromDate", "toDate"],
              param: vendorInvoiceParams,
            })}
          </div>
          <p className="formSubmitErrorTextArea" style={{ textAlign: "center", fontSize: "16px" }}>
            {vendorInvoiceError[0]}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};


const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
      addVendorInvoiceParams: (value) => dispatch(addVendorInvoiceParams(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VenderInvoiceParams);

