import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import viewIcon from "../../assets/view.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import { editVendor, viewVendor } from "../../redux/Vendor/VendorInfoActions";
import {addProductionPlanningId} from "../../redux/PurchaseOrder/PoInfoActions"
import Pagination from "../CommonFunctions/pagination";
import FormElement from "../SmallComponents/FormElement";
import SortUpGreen from '../../assets/SortUpGreen.svg';
import SortDownGreen from '../../assets/SortDownGreen.svg';

const ProductionPlanningList = ({
  setSection,

  // state
  isLogged,

  // dispatch
  addTokenToState,
  viewAddProductionPlanningId,
  editAddProductionPlanningId,
  viewVendorId,
  editVendorId,
}) => {
  useEffect(() => {
    getInformation();
    // eslint-disable-next-line
  }, []);

  async function getPrDetails() {
    var data = await fetchData({
      requestingPage: "soList",
      method: "post",
      url: "po-fetch/production-planning-list",
      headers: { token: isLogged.accessToken, module: "Production Planning" },
    });
    if (data.msg === "success") {
      var data2 = [];

      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });
      setVendorList(data2);
      if (data.asset.length > 0) setNoRecords(false);
      else setNoRecords(true);
    } else {
      console.log(data);
    }
    setLoading(false);
  }

  async function getInformation() {
    await checkToken();
    // await getVendors();
    await getPrDetails();
  }

  const history = useHistory();
  const [vendorList, setVendorList] = useState([]);
  const [noRecords, setNoRecords] = useState(false);
  const [loading, setLoading] = useState(true);
  const [PageSize, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const vendorListCurrentPage = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return vendorList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, vendorList, PageSize]);
  const [order, setOrder] = useState({ col: "vendorId", state: true, data: "" });

  async function checkToken() {
    console.log("Checking token");
    //   console.log(isLogged.accessToken);
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  const editVendorCollapse = (index) => {

    var vendorListCopy = vendorList.slice();
    vendorListCopy.forEach((vendor, j) => {
      if (j === index) {
        vendorListCopy[j].collapse = !vendor.collapse;
      } else {
        vendorListCopy[j].collapse = true;
      }
    });
    setVendorList(vendorListCopy);
  };


  const RenderSubTable = ({ data }) => {
    return (
      <td colSpan="7" className="vendorListInnerTableWrapper">
        <table className="vendorListInnerTable">
          <thead>
            <tr className="vendorListInnerTableHeader">
              <td className="vendorListInnerTableHeading">Section No</td>
              <td className="vendorListInnerTableHeading">Section Name</td>
              <td className="vendorListInnerTableHeading">Cutting Length</td>
              <td className="vendorListInnerTableHeading">Weight From</td>
              <td className="vendorListInnerTableHeading">Weight To</td>
              <td className="vendorListInnerTableHeading">Quantity</td>
            </tr>
          </thead>
          <tbody>
            {data.map((row, i) => {
              return (
                <tr className="vendorListInnerTable" key={i}>
                  <td className="vendorListInnertableInfo">{row.itemCode}</td>
                  <td className="vendorListInnertableInfo">{row.itemName}</td>
                  <td className="vendorListInnertableInfo">{row.cuttingLength}</td>
                  <td className="vendorListInnertableInfo">{row.from}</td>
                  <td className="vendorListInnertableInfo">{row.to}</td>
                  <td className="vendorListInnertableInfo">{row.quantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    );
  };

  const renderVendorList = () => {
    return vendorListCurrentPage.map((pp, i) => {
      //          if(((vendorFilter!=="") && vendor.vendor.companyName.toLowerCase().includes(vendorFilter.toLowerCase())  ) || vendorFilter==="")    
      return (
        <React.Fragment key={i}>
          <tr
            className={pp.collapse ? "vendorList" : "vendorListExpanded"}
            onClick={() => editVendorCollapse(i)}
          >
            <td className="vendorListInfo">{pp.ppNO}</td>
            {/* <td className="vendorListInfo">{pp.partyOrder}</td> */}
            <td className="vendorListInfoActions">
              <img
                alt="View Record"
                className="vendorActionImages"
                src={viewIcon}
                title="View Vendor Detail"
                onClick={(e) => {
                  e.stopPropagation();
                  viewAddProductionPlanningId(pp.ppId);
                  setSection("productionPlanningDetail");
                }}
              />

              <img
                alt="Edit Record"
                className="vendorActionImages"
                src={isLogged.access.Vendor === "Edit" ? editIcon : editIconDisabled}
                title={isLogged.access.Vendor === "Edit" ? "Edit Vendor" : "No Edit Access"}
                onClick={(e) => {
                  if (isLogged.access.Vendor === "Edit") {
                    e.stopPropagation();
                    editAddProductionPlanningId(pp.ppId);
                    setSection("editProductionPlanning");
                  }
                }}
                style={isLogged.access.Vendor === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
              />

              <img
                alt="Toggle Expand"
                className="vendorActionImages"
                title="Toggle Expand"
                src={pp.collapse ? downIcon : upIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  editVendorCollapse(i);
                }}
              />
            </td>
          </tr>

          <tr>{!pp.collapse ? <RenderSubTable data={pp.lineItems} /> : null}</tr>
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <div style={{ display: "inline-flex", width: "500px", }} className="purchaseOrderGrid">
        <span style={{ flexGrow: 0, width: "80px", marginLeft: "25px", marginRight: "10px" }}>
          <FormElement
            setInput={(value) => {
              setRowsPerPage(value);
            }}
            inputType={"number"}
            value={PageSize}
            hintText={"Rows Per Page"}
            colSpan={0.5}
            rowSpan={1}
            mandatory={false}
            error={false}
            errorMessage=""
            disabled={false}
          />
        </span>
      </div>
      <div className="vendorListArea">
        <table className="vendorListTable">
          <thead>
            <tr className="vendorListHeader">
              <td className="vendorListHeading">Production Planning No
                <img
                  className="sortArrow"
                  src={
                    order.col === "vendorId"
                      ? order.state
                        ? SortUpGreen : SortDownGreen
                      : null}
                      alt="sortArrow"
                /></td>
              {/* <td className="vendorListHeading" onClick={(e) => {
                setOrder({
                  col: "companyName",
                  state: !order.state,
                });
                handleSort({
                  data: vendorListCurrentPage,
                  col: "companyName",
                  state: order.state,
                });
              }}>Party Order
                <img
                  className="sortArrow"
                  src={
                    order.col === "companyName"
                      ? order.state
                        ? SortUpGreen : SortDownGreen
                      : null}
                /></td> */}
              <td className="vendorListHeadingActions">Actions</td>
            </tr>
          </thead>
          <tbody>{vendorList.length > 0 ? renderVendorList() : null}</tbody>
        </table>
        {noRecords ? (
          <div className="noRecordsYet">No records found</div>
        ) : loading ? (
          <div className="loader" style={{ margin: "1rem auto" }}></div>
        ) : null}
      </div>
      {vendorList.length > 0 ? <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={vendorList.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      /> : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    viewAddProductionPlanningId: (vendorId) => dispatch(addProductionPlanningId(vendorId)),
    editAddProductionPlanningId: (vendorId) => dispatch(addProductionPlanningId(vendorId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductionPlanningList);
