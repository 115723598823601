import React,{useState,useEffect} from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import logoNotAvailable from "../../assets/no-stopping.svg";

const PlantDetail = ({isLogged,setSection,addTokenToState,itemInfo}) => {

    const [plantDetail,setPlantDetail] = useState({});
    const [plantProfile,setPlantProfile]=useState();
    useEffect(()=>getInformation(),[])

    async function getInformation(){
        await checkToken();
        var data = await getPlantDetail();
        if(!data){
            setSection("plantList");
        }else{
            await getPlantProfile({plantId:data.plantId,plantProfile: data.plantProfile})
        setPlantDetail(data)
               }
            console.log(data);
    }

    const history = useHistory();

  async function checkToken() {
    console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }


    async function getPlantDetail(){
        var data = await fetchData({
            requestingPage: "plantDetail",
            method: "get",
            url: "fetch/plant/" + itemInfo.editPlantId,
            headers: { token: isLogged.accessToken, module: "Plant & Store" },
        });
        if (data.msg === "success") return data.asset[0];
        else return null;
    }
    async function getPlantProfile({plantId,plantProfile}) {
        if (plantId && plantProfile) {
            var data = await fetchData({
                requestingPage: "plantImage",
                method: "get",
                url: "image/plant-profile-photo/" + plantId,
                headers: { token: isLogged.accessToken, module: "Plant & Store" },
            });
            if (data.msg !== "failure") setPlantProfile(data);
        }
    }
    const PlantInfo=()=>{

        return (<React.Fragment>
            <span className='vendorSummaryRole'>{plantDetail.plantName}</span>
            <span className='vendorSummaryGrid'>
                <span className='vendorSummaryCategory'>Plant Code</span>
                <span className='vendorSummaryValue'>{plantDetail.plantCode}</span>
                <span className='vendorSummaryCategory'>Plant GST Type</span>
                <span className='vendorSummaryValue'>{plantDetail.gstRegisterType}</span>
                <span className='vendorSummaryCategory'>Plant GST No</span>
                <span className='vendorSummaryValue'>{plantDetail.gstNo}</span>
                {
                    plantDetail.parentPlantName ? 
                    <>
                    <span className='vendorSummaryCategory'>Parent Plant Name</span>
                    <span className='vendorSummaryValue'>{plantDetail.parentPlantName}</span>
                    </> : null}
                    <span className='vendorSummaryCategory'>Plant Email</span>
                    <span className='vendorSummaryValue'>{plantDetail.plantEmail}</span>
                    <span className='vendorSummaryCategory'>Contact No</span>
                    <span className='vendorSummaryValue'>{plantDetail.contactNo}</span>
       
                {/* <span className='vendorSummaryCategory'>Plant City</span>
                <span className='vendorSummaryValue'>{plantDetail.city}</span>
                <span className='vendorSummaryCategory'>State</span>
                <span className='vendorSummaryValue'>{plantDetail.state}</span>
                <span className='vendorSummaryCategory'>Country</span>
                <span className='vendorSummaryValue'>{plantDetail.country}-{plantDetail.zipCode}</span> */}
            </span>
        </React.Fragment>)
    }
    const tablist = [
        { id: "storeDetails", name: "Store Details" },
        { id: "bankDetails", name: "Bank Details" },
      ];
    const [selectedTab, setSelectedTab] = useState("storeDetails");
     
    const SubSectionTabList=({tablist})=>{
        return tablist.map((tab) => {
            return (
              <div
                key={tab.id}
                className={
                  selectedTab === tab.id
                    ? "createVendorIndividualTabSelected"
                    : "createVendorIndividualTab"
                }
                onClick={() => setSelectedTab(tab.id)}>
                {tab.name}
              </div>
            );
          });
    };
    const RenderTableRows = ({ rows, tab, fields }) => {

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {fields.map((key, i) => {
                        return <td key={i}>{row[key]}</td>;
                    })}
                </tr>
            );
        });
    };

    const SubSection=({tab})=>{

        switch(tab){
            case "storeDetails":
                return(
                    <table className="vendorDetailContactsTable">
                    <thead>
                        <tr className="vendorDetailContactsTableHeader">
                            <td>Store Code</td>
                            <td>Store Name</td>
                            <td>Address</td>
                            <td>Status</td>
                            <td>Storage Type</td>
                        </tr>
                    </thead>
                    <tbody>
                        <RenderTableRows
                            rows={plantDetail.storageDetail || []}
                            tab="storageActions"
                            fields={["storageCode", "storageName", "address", "status","storeType"]}
                        />
                    </tbody>
                </table>
                );
             case "bankDetails":
                return (
                    <table className="vendorDetailContactsTable">
                        <thead>
                            <tr className="vendorDetailContactsTableHeader">
                                <td>Account Holder Name</td>
                                <td>Account Number</td>
                                <td>Bank Name</td>
                                <td>IFSC Code</td>
                                <td>Branch</td>
                            </tr>
                        </thead>
                         <tbody>
                                <RenderTableRows
                                    rows={plantDetail.bankDetail}
                                    tab="bankActions"
                                    fields={["accountHolderName", "accountNumber", "bankName", "ifscCode", "branch"]}
                                />
                         </tbody>
                    </table>
                );
            default:
                return <div>No Table rendered</div>;
        }
    }
    const renderImageStatus = () => {
        return (
          <div>
            <img
              className="vendorImage"
              src={
                plantProfile !== null && plantProfile !== undefined && plantProfile !== ""
                  ? `data:image/png;base64,${plantProfile}`
                  : logoNotAvailable
              }
              alt="Vendor Logo"
            />
            <div className="vendorCurrentStatus">
            </div>
          </div>
        );
      };
  return (
        <div className='detailsPageWrapper'>
         <div className='detailsPageContainer'>
            <div className='vendorSummary'>
                {renderImageStatus()}
                <div className='vendorSummaryText'>
                    <PlantInfo />
                </div>
                <div style={{flex: "1"}}/>
                <div className='vendorSummaryText'>
                    <div className='vendorSummaryAddress'>
                        Address
                    </div>
                    <div className="vendorSummaryAddressValue">
                        {plantDetail.address}
                     </div>
                    <div className="vendorSummaryAddressValue">
                        {plantDetail.state},
                        {plantDetail.city}
                    </div>
                    <div className="vendorSummaryAddressValue">
                        {plantDetail.country}
                        {plantDetail.zipCode !== null
                        ? ` - ${plantDetail.zipCode}`
                        : null}
                    </div>
                </div>
            </div>

            <div className='vendorDetailTabArea'>
                <div className='vendorDetailTabs'>
                 <SubSectionTabList tablist={tablist}/>
                </div>
                <SubSection tab={selectedTab}/>
            </div>
         </div>
        </div>
  )
}

const mapStateToProps = (state) => {
    return {
      isLogged: state.isLogged,
      itemInfo :state.itemInfo
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) =>
        dispatch(addToken(accessToken, employeeId)),
    };
  };
  
export default connect(mapStateToProps,mapDispatchToProps)(PlantDetail);