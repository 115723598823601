import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import FormHint from "../SmallComponents/FormHint";
import InputField from "../SmallComponents/InputField";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import resetIcon from "../../assets/return.svg";

const EditInvoice = ({ isLogged, poInfo, setSection, editApprove }) => {
  const [soDetail, setSoDetail] = useState({});
  const [showCustomerDetail, setShowCustomerDetail] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [tcsPercentage, setTcsPercentage] = useState(null);
  const [lineItemsList, setLineItemsList] = useState([]);
  const [customerDetail,setCustomerDetail] = useState([]);
  const [invoiceLineItemId,setInvoiceLineItemId] = useState(null)
  

  const [invoiceDetail, setInvoiceDetail] = useState({});

  const [otherCharges, setOtherCharges] = useState("");
  const [transportCharges, settransportCharges] = useState("");
  const [transportChargesError, setTransportChargesError] = useState(false);
  const [otherChargesError, setOtherChargesError] = useState(false);

  const [netTotal, setNetTotal] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [instruction, setInstruction] = useState("");

  const [lrDocument, setLrDocument] = useState("");
  const [outwardDocument, setOutwardDocument] = useState("");

  const salesOrderParamList = [
    ["orderType", "premiumNo","deliveryAddressId"],
    ["invoiceNo","invoiceDate", "officeName","packaging"],
    ["orderType","customerId","deliveryAddressId"]
  ];
  const [salesOrderParams, setSalesOrderParams] = useState({
    customerId: {
      inputType: "options",
      value: "",
      hintText: "Customer Name",
      mandatory: true,
      colSpan: 12,
      error: false,
      options: [],
      errorMessage: "Please select a Customer",
    },
    orderType: {
      inputType: "options",
      value: "Sales",
      hintText: "Order Type",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [
        { optionId: "Sales", optionName: "Sales" },
        {optionId:"Direct Invoice",optionName:"Direct Invoice"}
        // { optionId: "Sales Return", optionName: "Sales Return" },
      ],
      errorMessage: "Please select order type",
      disabled: true,
    },
    orderNo: {
      inputType: "options",
      value: "",
      hintText: "Order No",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please select order no",
      disabled: true,
    },
    premiumNo: {
      inputType: "options",
      value: "",
      hintText: "Premium List No",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please select order no",
      disabled: false,
    },
    invoiceNo: {
      inputType: "text",
      value: "",
      hintText: "Invoice No",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please Enter invoice No",
      disabled:true
    },
    invoiceDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Invoice Date",
      mandatory: true,
      colSpan: 6,
      error: false,
      mindate: true,
      errorMessage: "Please Select invoice date",
    },

    officeName: {
      inputType: "options",
      value: "",
      hintText: "Office Name",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Select Office Name",
    },
    deliveryAddressId:{
      inputType: "options",
      value: "",
      hintText: "Delivery Address",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      options: []
    },
    packaging :{
      inputType: "text",
      value: "Bundle",
      hintText: "Packaging",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Select A Packaging Type",
    }
  });

  const [lineItemParams, setLineItemParams] = useState({

    itemCategory: {
      inputType: "options",
      value: "",
      hintText: "Item Category",
      mandatory: false,
      colSpan: 8,
      options: [],
      error: false,
      errorMessage: "Select an Item",
      callServer: true,
    },
    sectionNo: {
      inputType: "options",
      value: "",
      hintText: "Section No",
      mandatory: true,
      options: [],
      colSpan: 6,
      error: false,
      errorMessage: "",
      // disabled: true,
    },
    sectionName: {
      inputType: "text",
      value: "",
      hintText: "Section Name",
      mandatory: false,
      colSpan: 7,
      error: false,
      errorMessage: "",
      disabled: true,
    },
    // cuttingLength: {
    //   inputType: "options",
    //   value: "",
    //   options: [],
    //   hintText: "Cutting Length",
    //   mandatory: true,
    //   colSpan: 6,
    //   error: false,
    //   errorMessage: "Select Cutting Length above to autofill this field",
    //   disabled: false,
    // },
    
    quantity: {
      inputType: "number",
      value: "",
      hintText: "Quantity",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Enter a valid quantity",
    },
    unitPrice: {
      inputType: "float",
      value: "",
      hintText: "Unit Price",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Enter a valid price",
    },
    tax: {
      inputType: "text",
      value: "",
      hintText: "Tax (%)",
      mandatory: true,
      colSpan: 2,
      error: false,
      errorMessage: "Tax not declared for this Item",
      disabled: false,
    },
    taxAmount: {
      inputType: "text",
      value: "",
      hintText: "Tax Amount",
      mandatory: true,
      colSpan: 4,
      error: false,
      errorMessage: "Tax not declared for this Item",
      disabled: true,
    },
    netPrice: {
      inputType: "text",
      value: "",
      hintText: "Net Price",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      disabled: true,
    }  });
  const [finalDiscount,setFinalDiscount] = useState(0);
  const [finalDiscountError,setFinalDiscountError] = useState("");
  const [showDiscount,setShowDiscount] = useState(false);
  const [lineItemErrors, setLineItemErrors] = useState([]);
  const [itemList, setItemList] = useState();
  useEffect(() => {
    getInformation();
  }, []);

  async function getCustomerList() {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "get",
      url: "fetch/customer-list",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
    if (data.msg === "success") {
      var optionArray = [];
      data.asset.forEach((dataItem) => {
        var a = {
          optionName: dataItem.customer,
          optionId: dataItem.customerId,
        };
        optionArray.push(a);
      });

      return optionArray;
    } else {
      console.log(data);
    }
  }

  async function getInformation() {
    setModalText("Getting Invoice Detail");
    // setIsOpen(true);
    setDataSubmitted(false);

    var plantList = await getPlants();
    // var orderList = await getOrderList();
    var invoiceDet = await getInvoiceDetail();
    var soDetail = await getSoDetail();
    var getOtherCharges = await getOtherChargesList()
    var customerList = await getCustomerList();

    setSoDetail(soDetail);
    setShowCustomerDetail(true);
    
    setTcsPercentage(parseFloat(invoiceDet?.tcsPercentage));
if(soDetail?.lineItems?.length){
  var lineItemsCopy = [];
  soDetail?.lineItems.forEach((item) => {

    var invoiceLineItemCreated = invoiceDet.lineItems.filter(
      (line) => line.soLineItemId === item.soLineItemId
    )[0];


    var newObj = Object.assign(item);
    newObj.quantitySo = item.quantity;
    newObj.unitPriceSo = item.unitPrice;
    if (item.discount === null) {
      newObj.discountSo = 0;
      newObj.discount = 0;
    } else {
      newObj.discountSo = item.discount;
    }

    newObj.quantity = "";
    newObj.netPrice = 0;
    newObj.invoiceLineItemId = null;

    if (
      (invoiceLineItemCreated !== null) &
      (invoiceLineItemCreated !== undefined)
    ) {
      newObj.quantity = invoiceLineItemCreated.quantity;
      newObj.netPrice = invoiceLineItemCreated.netPrice;
      newObj.discount = invoiceLineItemCreated.discount;
      newObj.unitPrice = invoiceLineItemCreated.unitPrice;
      newObj.invoiceLineItemId = invoiceLineItemCreated.invoiceLineItemId;
     newObj.taxAmount = invoiceLineItemCreated.taxAmount
     newObj.bundle = invoiceLineItemCreated.bundle
    }

    newObj.quantityError = false;
    newObj.quantityErrorMessage = "Please Enter a Valid Quantity";

    newObj.unitPriceError = false;
    newObj.unitPriceErrorMessage = "Please Enter a Valid Unit Price";

    newObj.discountError = false;
    newObj.discountErrorMessage = "Please Enter a Valid Discount value";

    newObj.toSend = false;

    lineItemsCopy.push(newObj);
  });
  setLineItems(lineItemsCopy);
}else{

  setLineItemsList(invoiceDet.lineItems)
}


    setNetTotal(invoiceDet.netTotal);
    setOtherCharges(invoiceDet.otherCharges);
    settransportCharges(invoiceDet.transportCharges);
    setTotalAmount(invoiceDet.totalAmount);
    setInstruction(invoiceDet.instruction);
    if(invoiceDet.finalDiscount)
    {setShowDiscount(true);
      setFinalDiscount(invoiceDet.finalDiscount);}
    setInvoiceDetail(invoiceDet);

    var paramsCopy = JSON.parse(JSON.stringify(salesOrderParams));

    paramsCopy = update(paramsCopy, {
      invoiceDate: { value: { $set: invoiceDet.invoiceDate } },
      invoiceNo: { value: { $set: invoiceDet.invoiceNo } },
      orderType: { value: { $set: invoiceDet.orderType } },
      // orderNo: {
      //   options: { $set: orderList },
      //   value: { $set: invoiceDet.soId },
      // },
      officeName: {
        options: { $set: plantList },
        value: { $set: invoiceDet.plantId },
      },
      deliveryAddressId: {
        value: {$set: invoiceDet.deliveryAddressId},
        options: {$set: invoiceDet.deliveryAddressOptions}
      },
      packaging :{
        value : {$set :invoiceDet.bundleName || "Bundle" }
      },
      premiumNo: {value:{$set:invoiceDet.premiumNO},
                 options: { $set: getOtherCharges} 
                },
        customerId: {value:{$set:invoiceDet.customerId},
                  options: { $set: customerList } 
                },
    });

    console.log(paramsCopy,paramsCopy)

    setSalesOrderParams(paramsCopy);

    if (invoiceDet.lrDocument !== null) {
      setLrDocument({ name: invoiceDet.lrDocument });
    }
    if (invoiceDet.outwardDocument !== null) {
      setOutwardDocument({ name: invoiceDet.outwardDocument });
    }

    setDataSubmitted(true);
    setModalText("Editing Invoice. Please wait");
    setIsOpen(false);
  }

  async function getItemUom() {
    var itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data: { tab: "Active", type: "Sales" },
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    var itemList = [];
    if (itemData.msg === "success") {

      itemData.asset.forEach((item) => {
        var itemObj = {};
        // itemObj.optionId = item.item.sectionNo;
        // itemObj.optionName = item.item.sectionNo;
        itemObj.optionId = item.item.itemCode;
        itemObj.optionName = item.item.itemCode;
        itemObj.itemCode = item.item.itemCode;
        itemObj.itemName = item.item.itemName;
        itemObj.itemId = item.item.itemId;
        itemObj.categoryId = item.item.categoryId;
        itemObj.categoryName = item.item.categoryName;
        itemObj.sectionNo = item.item.itemCode;
        itemObj.sectionName = item.item.itemName;
        itemList.push(itemObj);

      });

      return [itemList];
    }
  }

  async function getCategory() {
    var categoryData = await fetchData({
      requestingPage: "getCategory",
      method: "get",
      url: "fetch/categories",
      headers: { token: isLogged.accessToken, module: "Dropdowns" },
    });

    var categoryList = [];
    var subCategoryList = {};

    if (categoryData.msg === "success") {
      categoryData.asset.forEach((categ) => {
        var catObj = {};
        catObj.optionId = categ.category.categoryId;
        catObj.optionName = categ.category.categoryName;
        categoryList.push(catObj);

        subCategoryList[categ.category.categoryId] = [];

        categ.subCategory.forEach((subCateg) => {
          var subCatObj = {};
          subCatObj.optionId = subCateg.subCategoryId;
          subCatObj.optionName = subCateg.subCategoryName;

          subCategoryList[categ.category.categoryId].push(subCatObj);
        });
      });
      return [categoryList, subCategoryList];
    }
  }

  const directInvoiceInfo=async()=>{

    // var uomList = await getUoms()
    // let cuttingLength = await getCuttingLength();
    var [itemList] = await getItemUom();
    var [categoryList] = await getCategory();

    var lineItemParamsCopy = Object.assign(lineItemParams);
    const trimSectionList = itemList.filter(item1 =>
      !lineItemsList.some(item2 => (item2.sectionNo === item1.sectionNo)))
  
    lineItemParamsCopy = update(lineItemParamsCopy, {
      // uomId: { options: { $set: uomList } },
      sectionNo: { options: { $set: trimSectionList } },
      itemCategory: { options: { $set: categoryList } },
      // cuttingLength: { options: { $set: cuttingLength } }
    });
    setLineItemParams(lineItemParamsCopy);
    setItemList(itemList)
  }

const getCustomer=async(id)=> {
  if(id){
    var data = await fetchData({
      requestingPage: "customerList",
      method: "get",
      url: `fetch/customer/${id}`,
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
    });
    if (data.msg === "success") {
      // setCustomerDetail(data.asset.customerDetail[0]);
      let paramCopy = Object.assign(salesOrderParams);
      // console.log(data.deliveryDetail,"data.deliveryDetail")
      paramCopy = update(paramCopy, {
        deliveryAddressId: {options: {$set: data.asset.deliveryDetail} }
      });
      setCustomerDetail(data.asset.customerDetail[0])
      setSalesOrderParams(paramCopy);
      return data.asset.deliveryDetail;
    }
  }
  }

  useEffect(() => {
    getCustomer(salesOrderParams.customerId.value);
  }, [salesOrderParams.customerId.value]);

  async function getOtherChargesList(){
    var data = await fetchData({
      requestingPage: "OtherChargesList",
      method: "get",
      url: "so-fetch/other-charges-list",
      headers: { token: isLogged.accessToken, module: "Other Charges" },
      data: {}
    });
    if (data.msg === "success") {
      return data.asset
    }else{return []}
    // setLoading(false);
  }


  // async function getOrderList() {
  //   var data = await fetchData({
  //     requestingPage: "customerList",
  //     method: "post",
  //     url: `so-fetch/so-approved-list-mini`,
  //     headers: { token: isLogged.accessToken, module: "Sales Invoice" },
  //   });
  //   if (data.msg === "success") {
  //     var orderList = [];
  //     data.asset.forEach((item) => {
  //       var orderObj = { optionId: item.soId, optionName: item.soNo };
  //       orderList.push(orderObj);
  //     });
  //     return orderList;
  //   }
  // }

  async function getSoDetail() {
    if(poInfo.soInvoiceCreateId){

      var data = await fetchData({
        requestingPage: "customerList",
        method: "get",
        url: `so-fetch/sales-invoice-pre-create/${poInfo.soInvoiceCreateId}`,
        headers: { token: isLogged.accessToken, module: "Sales Invoice" },
      });
      if (data.msg === "success") {
        return data.asset[0];
      }
    }
    else{
      return []
    }
  }

  useEffect(()=>{
    if(invoiceDetail.orderType!=="Sales"){

      directInvoiceInfo()
    }
    // let paramCopy = Object.assign(salesOrderParams);
    // if(invoiceDetail.orderType==="Sales"){
    //   paramCopy = update(paramCopy, {
    //     deliveryAddressId: {options: {$set:[]},value: {$set: ""} },
    //     customerId: {value: {$set: ""} },
        
    //   });
    // }else{
    //   paramCopy = update(paramCopy, {
    //     deliveryAddressId: {options: {$set:[]},value: {$set: ""} },
    //     premiumNo: {value: {$set: ""} },
        
    //   });
    // }
    // setSalesOrderParams(paramCopy);
  },[salesOrderParams.orderType.value])

  async function getInvoiceDetail() {
    var data = await fetchData({
      requestingPage: "vendorDetail",
      method: "get",
      url: "so-fetch/sales-invoice-detail/" + poInfo.soEditInvoiceId,
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
    });
    if (data.msg === "success") {
      return data.asset[0];
    } else {
      // console.log(data);
    }
  }

  async function getPlants() {
    var plantsData = await fetchData({
      requestingPage: "plantList",
      method: "get",
      url: "fetch/plants",
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
    });

    var plantList = [];

    if (plantsData.msg === "success") {
      plantsData.asset.forEach((plant) => {
        var plantObj = {};
        plantObj.optionId = plant.plant.plantId;
        plantObj.optionName = plant.plant.plantName;
        plantObj.address =
          plant.plant.plantName +
          ", " +
          plant.plant.city +
          ", " +
          plant.plant.state +
          (plant.plant.zipCode !== null
            ? ` - ${plant.plant.zipCode}, `
            : ", ") +
          plant.plant.country;
        plantList.push(plantObj);
      });

      return plantList;

      // var paramCopy = Object.assign(salesOrderParams);
      // paramCopy = update(paramCopy, { officeName: { options: { $set: plantList } } });
      // setSalesOrderParams(paramCopy);
    }
  }

  useEffect(() => {
    

    var total =
      parseFloat(netTotal) +
      parseFloat(otherCharges||0) +
      parseFloat(transportCharges || 0) - (showDiscount? parseFloat(finalDiscount) : 0);
    setTotalAmount(total);
  }, [otherCharges, transportCharges,showDiscount,finalDiscount]);

  const RenderCustomerDetail = () => {
    return (
      <React.Fragment>
        <div className="detailTitle">{soDetail?.customerName||customerDetail.companyName}</div>
        <div className="detailText">
          {`Customer ID: ${soDetail?.customerId|| customerDetail.customerId}`},
          <br /> {soDetail?.customerAddress || customerDetail?.address},
          <br /> {soDetail?.customerCity ||customerDetail?.city}
          {soDetail?.customerPinCode !== null || customerDetail?.zipCode !==null
            ? ` - ${soDetail?.customerPinCode ||customerDetail?.zipCode}`
            : null}
          {soDetail?.customerMobile !== null ||
          soDetail?.customerPhone !== null ? (
            <br />
          ) : null}
          {soDetail?.customerMobile !== null||customerDetail?.mobile !== null || soDetail?.customerPhone !== null ||customerDetail?.phone !== null
            ? `Phone: `
            : null}
          {soDetail?.customerMobile !== null ? soDetail?.customerMobile : customerDetail?.mobile !== null?customerDetail?.mobile:null}
          {soDetail?.customerPhone !== null||customerDetail?.phone !== null
            ? `, ${soDetail?.customerPhone ||customerDetail?.phone} `
            : null}
          {soDetail?.customerEmail !== null||customerDetail?.email !== null ? <br /> : null}
          {soDetail?.customerEmail !== null||customerDetail?.email !== null
            ? `Email Id: ${soDetail?.customerEmail||customerDetail?.email}`
            : null}
          {soDetail?.customerGstNo !== null|| customerDetail?.gstNo !== null? <br /> : null}
          {soDetail?.customerGstNo !== null||customerDetail?.gstNo !== null
            ? `GST No:  ${soDetail?.customerGstNo||customerDetail?.gstNo}`
            : null}
        </div>
      </React.Fragment>
    );
  };

  const submitOkClick = () => {
    setIsOpen(false);
    if (errorList.length === 0) {
      setSection("salesInvoiceList");
    }
    setModalText("Uploading form. Please wait...");
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState(
    "Submitting Sales Order. Please wait.."
  );

  const updateInvoiceParameter = ({ paramName, section, key, value }) => {
    if (section === "general") {
      // console.log(paramName,"paramName",value,"value",salesOrderParams,"salesOrderParams")
      var salesParamsCopy = Object.assign(salesOrderParams);
      salesParamsCopy = update(salesParamsCopy, {
        [paramName]: { [key]: { $set: value } },
      });

      setSalesOrderParams(salesParamsCopy);
    }else {
      var lineParamsCopy = Object.assign(lineItemParams);
      lineParamsCopy = update(lineParamsCopy, {
        [paramName]: { [key]: { $set: value } },
      });
      if (paramName === "itemCategory") {
        if (value) {
          const filteredItems = itemList.filter(item => { return item.categoryId === value })

          const trimSectionList = filteredItems.filter(item1 =>
            !lineItemsList.some(item2 => (item2.sectionNo === item1.sectionNo)))

          lineParamsCopy = update(lineParamsCopy, {
            sectionNo: { options: { $set: trimSectionList } },
          });
        } else {

          const trimSectionList = itemList.filter(item1 =>
            !lineItemsList.some(item2 => (item2.sectionNo === item1.sectionNo)))

          lineParamsCopy = update(lineParamsCopy, {
            sectionNo: { options: { $set: trimSectionList } },
          });

        }
      }
      if (paramName === "sectionNo") {
        if (value) {
          const filteredSections = lineParamsCopy['sectionNo']?.options.filter(item => { return item.sectionNo === value })
          lineParamsCopy = update(lineParamsCopy, {
            sectionName: { value: { $set: filteredSections[0]?.itemName } },
          });
        } else {

          lineParamsCopy = update(lineParamsCopy, {
            sectionName: { value: { $set: "" } },
          });
        }
      }
      if(paramName === "tax"){
        if (value) {
          let taxAmountValue = lineParamsCopy.unitPrice.value * (lineParamsCopy.tax.value/100);
          lineParamsCopy = update(lineParamsCopy, {
            taxAmount: { value: { $set: taxAmountValue} },
          });
        } else {

          lineParamsCopy = update(lineParamsCopy, {
            taxAmount: { value: { $set: "" } },
          });
        }
      }
      if(paramName === "unitPrice" ||paramName === "tax"){
        if (value) {
          let netPriceValue = Number(parseFloat(lineItemParams.quantity.value * (paramName === "unitPrice"?value:lineItemParams.unitPrice.value)+
            (lineItemParams.quantity.value *
              (lineItemParams.taxAmount.value||0))).toFixed(2))

              lineParamsCopy = update(lineParamsCopy, {
                netPrice: { value: { $set: netPriceValue } },
              });
        } else {

          lineParamsCopy = update(lineParamsCopy, {
            netPrice: { value: { $set: 0 } },
          });
        }
      }
      setLineItemParams(lineParamsCopy);
    }

  };
  const checkLineItemError = () => {
    var errorList = [];

    var lineItemParamsCopy = Object.assign(lineItemParams);
    [

      "quantity",
      "tax",
      "taxAmount",
      "unitPrice"
    ].forEach((element) => {
      if (lineItemParamsCopy[element].value !== null && lineItemParamsCopy[element].mandatory === true) {
        lineItemParamsCopy = update(lineItemParamsCopy, {
          [element]: {
            error: {
              $set: !validateMandatory(
                lineItemParamsCopy[element].value.toString()
              ),
            },
          },
        });
      } else {
      }

      if (
        element === "quantity" ||
        element === "unitPrice" 
      ) {
        if (lineItemParamsCopy[element].value !== "") {
          if (
            isNaN(lineItemParamsCopy[element].value) ||
            parseFloat(lineItemParamsCopy[element].value) < 0
          ) {
            lineItemParamsCopy = update(lineItemParamsCopy, {
              [element]: { error: { $set: true } },
            });
          } else {
            lineItemParamsCopy = update(lineItemParamsCopy, {
              [element]: { error: { $set: false } },
            });
          }
        }
      }

      if (lineItemParamsCopy[element].error) {
        errorList.push(lineItemParamsCopy[element].errorMessage);
      }

      setLineItemErrors(errorList);
      setLineItemParams(lineItemParamsCopy);
    });

    if (errorList.length === 0) {
      let category = itemList.filter(item => item.categoryId === lineItemParamsCopy.itemCategory.value)
      let itemId = itemList.filter(item => item.itemCode === lineItemParamsCopy.sectionNo.value)[0].itemId
      var newLineItem = {
        itemCategory: category[0]?.categoryId,
        sectionNo: lineItemParamsCopy.sectionNo.value,
        sectionName: lineItemParamsCopy.sectionName.value,
        itemId: itemId,
        invoiceLineItemId:invoiceLineItemId?invoiceLineItemId:null,
        // cuttingLengthName: cuttingLength.filter(
        //   (item) => item.optionId === lineItemParamsCopy.cuttingLength.value
        // )[0]?.optionName,
        // cuttingLength: lineItemParamsCopy.cuttingLength.value || null,
        //uomId: lineItemParamsCopy.uomId.value,
        //uom: itemUomList.filter(
          //(item) => item.optionId === lineItemParamsCopy.uomId.value
        //)[0].optionName,
        //from: lineItemParamsCopy.from.value,
        //to: lineItemParamsCopy.to.value,
        quantity: Number(parseFloat(lineItemParamsCopy.quantity.value).toFixed(2)),
        // cost: lineItemParamsCopy.cost.value,
        //discount:
          //lineItemParamsCopy.discount.value !== ""
            //? parseFloat(lineItemParamsCopy.discount.value)
            //: null,
        unitPrice: parseFloat(lineItemParamsCopy.unitPrice.value),
        tax: lineItemParamsCopy?.tax?.value ||0,
        taxAmount: lineItemParamsCopy?.taxAmount?.value.toFixed(2) ||0,

        netPrice:lineItemParamsCopy?.netPrice.value
        
      };


      var lineItemsListCopy = lineItemsList;
      lineItemsListCopy.push(newLineItem);

      setLineItemsList(lineItemsListCopy);

      const trimSectionList = itemList.filter(item1 =>
        !lineItemsListCopy.some(item2 => (item2.sectionNo === item1.sectionNo)));
        [
          "itemCategory",
          "sectionNo",
          "sectionName",
          "quantity",
          "unitPrice",
          "tax",
          "taxAmount",
          "netPrice"
        ].forEach((element) => {
        if (element === "sectionNo") {
          lineItemParamsCopy.sectionNo.value = "";
          lineItemParamsCopy.sectionNo.options = trimSectionList;
        } else {
          lineItemParamsCopy[element].value = "";
        }
      });
    
      setLineItemParams(lineItemParamsCopy);
      setInvoiceLineItemId(null);
    }
  };

  const renderSubSection = () => {
    let lineItemsParamsList = Object.keys(lineItemParams)
    return (
      <React.Fragment>
        <div className="purchaseOrderIndividualItemsArea" style={{ gridAutoRows: "60px" }}>
          {renderFormElements({
            elementList: lineItemsParamsList,
            param: lineItemParams,
            section: "lineItem",
          })}
          <FormElement
            inputType="addButton"
            value="+ Add"
            colSpan={3}
            setInput={() => {
              checkLineItemError();
            }}
          />
          <div
            style={{
              marginTop: "26px",
              gridColumn: "span 1",
              color: "#666",
              background: "rgb(230,230,230)",
              cursor: "pointer",
            }}>
            <img
              style={{ padding: "10px", width: "1rem", height: "1rem" }}
              src={resetIcon}
              alt="Reset"
              onClick={() => {
                var yes = window.confirm("Clear Input data?");

                if (yes) {
                  var lineItemParamEmpty = Object.assign(lineItemParams);
                  lineItemsParamsList.forEach((item) => {
                    lineItemParamEmpty[item].value = "";
                    lineItemParamEmpty[item].error = false;
                  });
                  setLineItemParams(lineItemParamEmpty);
                  setLineItemErrors([]);
                }
              }}
            />
          </div>
        </div>

        <RenderTable />
      </React.Fragment>
    );
  };

  const RenderTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr className="createVendorContactsTableHeader">
            <td>Section No</td>
            <td>Section Name</td>
            <td>{salesOrderParams.packaging.value || "Bundle"}</td>
            <td>Quantity</td>
            <td>Unit Price</td>
            <td>Tax</td>
            <td>Tax Amount</td>
            <td>Net Price</td>
            <td>Actions</td>
          </tr>
        </thead>
        {lineItemsList.length > 0 ? (
          <tbody>
            <RenderTableRows rows={lineItemsList} tab="contactActions" />
          </tbody>
        ) : (
          <tbody>
            <tr className="createVendorContactsTableRows">
              {Array.from({ length: 9 }).map((item, i) => {
                return <td key={i}>&nbsp;</td>;
              })}
              {/* <td key={1}>&nbsp;</td> */}
            </tr>
          </tbody>
        )}
      </table>
    );
  };

  const updateBundle=(key,index,value)=>{
    let updatedList = lineItemsList.slice();
    updatedList[index][key] = value;
    setLineItemsList(updatedList);
  }

  const RenderTableRows = ({ rows, tab }) => {
    let RenderTableCols = [
        "sectionNo",
        "sectionName",
        "bundle",
        "quantity",
        "unitPrice",
        "tax",
        "taxAmount",
        "netPrice"
      ];
    return rows.map((row, j) => {
      return (
        <tr className="createVendorContactsTableRows" key={j}>
          {RenderTableCols.map((key, i) => {
            if(key ==="bundle"){
              return(
                <td key={i} style={{ maxWidth: "8px" }}>
                <input
                  className={"createPurchaseCostInput"
                  }
                  style={{ minWidth: "3rem" }}
                  type="number"
                  value={row[key] || ""}
                  min={0}
                  onChange={(e) =>
                    updateBundle(key, j, e.target.value)
                  }
                />
              </td>
              );
            }
            if(key ==="taxAmount"){
              return <td key={i}>{row[key] ? row[key] : row.quantity*(row.tax/100)||" - "}</td>;
            }else{

              return <td key={i}>{row[key] ? row[key] : " - "}</td>;
            }
          })}
          <td>
            <img
              alt="Edit Record"
              className="createVendorContactsAction"
              src={editIcon}
              onClick={() => {
                editSubSectionItem(j, "edit");
              }}
            />
            <img
              alt="Delete Record"
              className="createVendorContactsAction"
              src={deleteIcon}
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) {
                  editSubSectionItem(j, "delete");
                }
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const editSubSectionItem = (index, action) => {
    if (action === "edit") {
      var lineItemAllClear = true;

      [
        // "itemCategory",
        "sectionNo",
        "sectionName",
        // "cuttingLength",
        "quantity",
        "unitPrice",
        "tax",
        "taxAmount",
        "netPrice",
      ].forEach((item) => {
        if (lineItemParams[item].value !== "") {
          lineItemAllClear = false;
        }
      });
      if (!lineItemAllClear) {
        var yes = window.confirm(
          "Unsaved data found. Are you sure you want to overwrite it?"
        );
      }
      if (lineItemAllClear || yes) {
        var lineItemCopy = Object.assign(lineItemParams);
        var lineItemsListCopy = lineItemsList.slice();

        var editObj = lineItemsListCopy.splice(index, 1)[0];
        var lineItemsFromServer = [
          "sectionNo",
          "sectionName",
          // "cuttingLength",
          "quantity",
          "unitPrice",
          "tax",
          "taxAmount",
          "netPrice",
        ];
        setInvoiceLineItemId(editObj.invoiceLineItemId)
        lineItemsFromServer.forEach((itemKey) => {
          if (itemKey === "item" || itemKey === "uom") {
          } else if (itemKey === "sectionNo") {
            lineItemCopy[itemKey].options = itemList;
            lineItemCopy[itemKey].value = editObj[itemKey];

          }

          else {
            lineItemCopy[itemKey].value = editObj[itemKey];
          }
          let taxAmountValue = editObj.unitPrice* (editObj.tax/100);
          lineItemCopy["taxAmount"].value = taxAmountValue
          
        });
        setLineItemParams(lineItemCopy);
        setLineItemsList(lineItemsListCopy);
      }
    }
    if (action === "delete") {
      var lineItemsListCopy = lineItemsList.slice();
      lineItemsListCopy.splice(index, 1);


      var lineItemCopy = Object.assign(lineItemParams);

      const trimSectionList = itemList.filter(item1 =>
        !lineItemsListCopy.some(item2 => (item2.sectionNo === item1.sectionNo)))

      lineItemCopy["sectionNo"].options = trimSectionList;

      setLineItemParams(lineItemCopy);
      setLineItemsList(lineItemsListCopy);
    }
  };

  useEffect(()=>{
        if(lineItemsList.length){
          var newNetTotal = 0;
          lineItemsList.forEach((item) => {
      if (!isNaN(item.netPrice)) {
        newNetTotal = parseFloat(newNetTotal) + parseFloat(item.netPrice);
      } else {
        newNetTotal = 0;
      }
    });

    newNetTotal = newNetTotal.toFixed(2);

    var newTotalAmount =
      parseFloat(newNetTotal) +
      parseFloat(transportCharges) +
      parseFloat(otherCharges) - (showDiscount ? parseFloat(finalDiscount) : 0);

      setNetTotal(newNetTotal);
      setTotalAmount(newTotalAmount);
        }
  },[lineItemsList.length])

  const updateLineItems = (param, index, value) => {
    
    var updatedList = lineItems.slice();

    updatedList = update(updatedList, {
      [index]: { [param]: { $set: value } },
    });

    var netRate = 0;
    var taxAmount = 0;
    if (
      !isNaN(updatedList[index].quantity) &&
      !isNaN(updatedList[index].unitPrice) &&
      !isNaN(updatedList[index].discount) &&
      !isNaN(updatedList[index].soOtherCharges)
    ) {
     
      let  netRateWithoutTax=  parseFloat(updatedList[index].quantity) *
      (parseFloat(updatedList[index].unitPrice + (parseFloat(updatedList[index].soOtherCharges || 0))) -
        (parseFloat(updatedList[index].discount) / 100) *
          parseFloat(updatedList[index].unitPrice));
          taxAmount = (parseFloat(updatedList[index].tax)/100) * netRateWithoutTax;
netRate = netRateWithoutTax  + taxAmount;
    }

    if (!isNaN(netRate)) {
      updatedList = update(updatedList, {
        [index]: { netPrice: { $set: netRate },taxAmount: {$set: taxAmount} },
      });
    } else {
      updatedList = update(updatedList, { [index]: { netPrice: { $set: 0 },taxAmount: {$set: 0} } });
    }

    var newNetTotal = 0;
    updatedList.forEach((item) => {
      if (!isNaN(item.netPrice)) {
        newNetTotal = parseFloat(newNetTotal) + parseFloat(item.netPrice);
      } else {
        newNetTotal = 0;
      }
    });

    newNetTotal = newNetTotal.toFixed(2);

    var newTotalAmount =
      parseFloat(newNetTotal) +
      parseFloat(transportCharges) +
      parseFloat(otherCharges)- (showDiscount ? parseFloat(finalDiscount) || 0 : 0);

    setLineItems(updatedList);
    setNetTotal(newNetTotal);
    setTotalAmount(newTotalAmount);
  };

  const [errorList, setErrorList] = useState([]);
  const checkErrors = (submitType) => {
    var errList = [];
    var paramsCopy = Object.assign(salesOrderParams);
    if(salesOrderParams.orderType.value ==="Sales"){

      salesOrderParamList[0].forEach((item) => {
        if (paramsCopy[item].mandatory) {
          console.log(paramsCopy[item],"paramsCopy[item]")
          paramsCopy = update(paramsCopy, {
            [item]: {
              error: {
                $set: !validateMandatory(paramsCopy[item].value.toString()),
              },
            },
          });
        }
  
        if (paramsCopy[item].error) {
          errList.push(paramsCopy[item].errorMessage);
        }
      });
      var proceed = false;
      var lineItemsCopy = lineItems.slice();
      lineItemsCopy.forEach((item, i) => {
        if (item.quantity < 0 || item.quantity > item.quantitySo) {
          lineItemsCopy[i].quantityError = true;
          errList.push(lineItemsCopy[i].quantityErrorMessage);
        } else {
          lineItemsCopy[i].quantityError = false;
          item.quantity = parseFloat(item.quantity);
        }
  
        if (item.discount < 0 || item.discount > 100) {
          lineItemsCopy[i].discountError = true;
          errList.push(lineItemsCopy[i].discountErrorMessage);
        } else {
          lineItemsCopy[i].discountError = false;
        }
  
        if (item.unitPrice < 0) {
          lineItemsCopy[i].unitPriceError = true;
          errList.push(lineItemsCopy[i].unitPriceErrorMessage);
        } else {
          lineItemsCopy[i].unitPriceError = false;
        }
  
        if (item.netPrice > 0) {
          lineItemsCopy[i].toSend = true;
          proceed = true;
        } else {
          console.log(item.itemName, "Don't send to Server");
        }
      });
    }else{
      salesOrderParamList[2].forEach((item) => {
        if (paramsCopy[item].mandatory) {
          paramsCopy = update(paramsCopy, {
            [item]: {
              error: {
                $set: !validateMandatory(paramsCopy[item].value.toString()),
              },
            },
          });
        }
  
        if (paramsCopy[item].error) {
          errList.push(paramsCopy[item].errorMessage);
        }
      });
    }

    salesOrderParamList[1].forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (paramsCopy[item].error) {
        errList.push(paramsCopy[item].errorMessage);
      }
    });

    if (isNaN(otherCharges) || parseInt(otherCharges) < 0) {
      errList.push("Enter a valid number for Other Charges");
      setOtherChargesError(true);
    } else {
      setOtherChargesError(false);
    }

    if (isNaN(transportCharges) || parseInt(transportCharges) < 0) {
      errList.push("Enter a valid number for Transport Charges");
      setTransportChargesError(true);
    } else {
      setTransportChargesError(false);
    }
    if (isNaN(finalDiscount) || parseInt(finalDiscount) < 0) {
      errList.push("Enter a valid number for Discount");
      setFinalDiscountError(true);
    } else {
      setFinalDiscountError(false);
    }
    var proceed = false;
    var lineItemsCopy = lineItems.slice();
    lineItemsCopy.forEach((item, i) => {
      if (item.quantity < 0 || item.quantity > item.quantitySo) {
        lineItemsCopy[i].quantityError = true;
        errList.push(lineItemsCopy[i].quantityErrorMessage);
      } else {
        lineItemsCopy[i].quantityError = false;
        item.quantity = parseFloat(item.quantity);
      }

      if (item.discount < 0 || item.discount > 100) {
        lineItemsCopy[i].discountError = true;
        errList.push(lineItemsCopy[i].discountErrorMessage);
      } else {
        lineItemsCopy[i].discountError = false;
      }

      if (item.unitPrice < 0) {
        lineItemsCopy[i].unitPriceError = true;
        errList.push(lineItemsCopy[i].unitPriceErrorMessage);
      } else {
        lineItemsCopy[i].unitPriceError = false;
      }

      if (item.netPrice > 0) {
        lineItemsCopy[i].toSend = true;
        proceed = true;
      } else {
      }
    });

    setLineItems(lineItemsCopy);
    setSalesOrderParams(paramsCopy);
    setErrorList(errList);

    if (lineItemsList.length || errList.length === 0) {
      if (lineItemsList.length || proceed) {
        setModalText(
          submitType === "Draft"
            ? "Saving Invoice..."
            : "Editing Invoice Detail..."
        );
        prepareData(salesOrderParams.orderType.value==="Sales"?lineItemsCopy:lineItemsList, paramsCopy, submitType);
        // prepareData(lineItemsCopy, paramsCopy, submitType);
      } else {
        setDataSubmitted(true);
        setModalText("No Data to send to server");
        setIsOpen(true);
      }
    }
  };

  const prepareData = (lineItemsCopy, paramsCopy, submitType) => {
    var data2Server = new FormData();
    data2Server.append("invoiceId", invoiceDetail.invoiceId);
    data2Server.append("premiumId", paramsCopy.premiumNo.value);
    data2Server.append("soId", soDetail?.soId);
    data2Server.append("invoiceDate", paramsCopy.invoiceDate.value);
    data2Server.append("invoiceNo", paramsCopy.invoiceNo.value);
    data2Server.append("plantId", paramsCopy.officeName.value);
    data2Server.append('deliveryAddressId',paramsCopy.deliveryAddressId.value || "");
    data2Server.append("netTotal", netTotal);
    data2Server.append("customerId",paramsCopy.customerId.value);
    data2Server.append("transportCharges", transportCharges || 0);
    data2Server.append("otherCharges", otherCharges || 0);
    data2Server.append("totalAmount", totalAmount);
    data2Server.append("instruction", instruction);
    data2Server.append("invoiceStatus", submitType);
    data2Server.append("bundleName", paramsCopy.packaging.value);
    data2Server.append("orderType", paramsCopy.orderType.value);

    if(showDiscount && parseFloat(finalDiscount) >=0 )
    data2Server.append('finalDiscount',finalDiscount);
    
    if (lrDocument !== "" && lrDocument?.size > 0) {
      data2Server.append("lrDocument", lrDocument);
    }
    if (outwardDocument !== "" && outwardDocument?.size > 0) {
      data2Server.append("outwardDocument", outwardDocument);
    }

    var lineItemsToServer = [];

    var lineItemsCopy2 = JSON.parse(JSON.stringify(lineItemsCopy));
    if(salesOrderParams.orderType.value==="Sales"){
    lineItemsCopy2.forEach((item) => {
      if (item.toSend) {
        delete item.discountSo;
        delete item.discountError;
        delete item.discountErrorMessage;

        delete item.quantitySo;
        delete item.quantityError;
        delete item.quantityErrorMessage;

        delete item.unitPriceSo;
        delete item.unitPriceError;
        delete item.unitPriceErrorMessage;

        delete item.hsnCode;
        delete item.itemName;
        delete item.soId;
        delete item.toSend;
        delete item.uom;

        lineItemsToServer.push(item);
        
      }
    });}else{
      lineItemsToServer=lineItemsCopy;
    }

    data2Server.append("invoiceLineItems", JSON.stringify(lineItemsToServer));

    
    submitData(data2Server);
  };

  async function submitData(data) {
    setIsOpen(true);
    setDataSubmitted(false);
    var result = await fetchData({
      requestingPage: "createCustomer",
      method: "put",
      url: "so-edit/sales-invoice",
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
      data: data,
    });

    if (result.msg === "success") {
      if (editApprove) {
        setModalText("Edit Successful. Approving Invoice...");
        approveInvoice();
      } else {
        setModalText(
          invoiceDetail.invoiceStatus === "Draft"
            ? "Invoice Saved"
            : "Invoice Edited Successfully!"
        );
        setDataSubmitted(true);
      }
    } else {
      setModalText(`Upload failed: ${result.desc}`);
      setErrorList([result.desc]);
      setDataSubmitted(true);
    }
  }

  async function approveInvoice() {
    setIsOpen(true);
    setDataSubmitted(false);
    var data = await fetchData({
      requestingPage: "approvePo",
      method: "put",
      url: `so-edit/sales-invoice-approve/${invoiceDetail.invoiceId}`,
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
    });

    setDataSubmitted(true);
    if (data.msg === "success") {
      setModalText("Approved Invoice");
    } else {
      // console.log(data);
      setModalText(data.desc);
      setErrorList([data.desc]);
    }
  }

  const renderErrorMessage = () => {
    if (errorList.length > 0) {
      return errorList[0];
    } else return null;
  };

  const renderFormElements = ({ elementList, param, section }) => {
    return elementList.map((element) => {
      return (
        <FormElement
          key={element}
          inputType={param[element].inputType}
          value={param[element].value}
          setInput={(value) => {
            updateInvoiceParameter({
              section,
              paramName: element,
              key: "value",
              value: value,
            });
          }}
          hintText={param[element].hintText}
          mandatory={param[element].mandatory}
          colSpan={param[element].colSpan}
          options={
            param[element].inputType === "options"
              ? param[element].options
              : null
          }
          error={param[element].error}
          rowSpan={
            element === "vendorLogo" || element === "otherBusinessDetails"
              ? param[element].rowSpan
              : null
          }
          placeholder={param[element].placeholder}
          title={param[element].title}
          disabled={param[element].disabled}
          mindate={param[element].mindate}
        />
      );
    });
  };
  const lineItemsTableCols = isLogged.showHideWeight
    ? [
        "itemName",
        "itemCode",
        "from",
        "to",
        "netWeight",
        "grossWeight",
        "hsnCode",
        "uom",
       "bundle",
        "quantitySo",
        "quantity",
        "unitPriceSo",
        "unitPrice",
        // "soOtherCharges",
        "tax",
        "taxAmount",
        "discountSo",
        "discount",
        "netPrice",
      ]
    : [
        "itemName",
        "itemCode",
        "hsnCode",
        "uom",
        "bundle",
        "quantitySo",
        "quantity",
        "unitPriceSo",
        "unitPrice",
        // "soOtherCharges",
        "tax",
        "taxAmount",
        "discountSo",
        "discount",
        "netPrice",
      ];
  return (
    <React.Fragment>
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
      <div className="formArea">
        <div
          style={{
            width: "fit-content",
            margin: "0 auto @rem",
            // padding: "3rem 3rem 1rem 3rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex" }}>
            <div className="createSalesInvoiceGrid">
              {renderFormElements({
                elementList: invoiceDetail.orderType==="Direct Invoice"?salesOrderParamList[2]:salesOrderParamList[0],
                param: salesOrderParams,
                section: "general",
              })}
            </div>
          </div>
          <div className="createSalesInvoiceInfo">
            <div className="salesInvoiceOurAddress">
              <div className="vendorSummaryGrid">
              {salesOrderParams?.premiumNo.value !== "" && salesOrderParams?.premiumNo.value !==null? 
               <React.Fragment>
                <span className="vendorSummaryCategory">Order No</span>
                <span className="vendorSummaryValue">: {soDetail?.soNo}</span>

                <span className="vendorSummaryCategory">Order Date</span>
                <span className="vendorSummaryValue">: {soDetail?.soDate}</span>

                <span className="vendorSummaryCategory">Transporter Name</span>
                <span className="vendorSummaryValue">
                  :{" "}
                  {soDetail?.transporterName !== null
                    ? soDetail?.transporterName
                    : "NA"}
                </span>
                 </React.Fragment>
                 : null}
              </div>
            </div>
            <div className="salesInvoiceCompanyAddress">
              {showCustomerDetail ? <RenderCustomerDetail /> : null}
            </div>
          </div>
          <div >
            <div className="createSalesInvoiceGrid"  style={{gridTemplateRows: "repeat(2, 4rem)"}}>
              {renderFormElements({
                elementList: salesOrderParamList[1],
                param: salesOrderParams,
                section: "general",
              })}
            </div>
            <div className="purchaseOrderSubSectionArea" 
            style={{display:invoiceDetail.orderType==="Direct Invoice"?"block":"none"}}
            >
            {renderSubSection()}
          </div>
          </div>
          <div style={{ marginBottom: "2rem" }}>
            {salesOrderParams.officeName.value !== "" ? (
              <span style={{ color: "#ce9547", fontWeight: "bold" }}>
                {" "}
                Office Address:{" "}
              </span>
            ) : null}
            {salesOrderParams.officeName.value !== ""
              ? salesOrderParams.officeName.options.filter(
                  (opt) => opt.optionId === salesOrderParams.officeName.value
                )[0].address
              : null}
          </div>
          {lineItems?.length > 0 ? (
            <table className="createItemPurchaseTable">
              <thead>
                <tr className="createVendorContactsTableHeader">
                  <td className="stickyFirstColumn">Item </td>
                  <td>Item Code</td>
                  {isLogged.showHideWeight ? (
                    <>
                      {" "}
                      <td>Weight From(gms)</td>
                      <td>Weight To(gms)</td>
                      <td>Net Weight(gms)</td>
                      <td>Gross Weight(gms)</td>{" "}
                    </>
                  ) : null}
                  <td>HSN No</td>
                  <td>UOM</td>
                  <td>{salesOrderParams.packaging.value || "Bundle"}</td>
                  <td>SO Quantity</td>
                  <td>Quantity</td>
                  <td>SO Unit Price</td>
                  <td>Unit Price</td>
                  {/* <td>Premium Charges</td> */}
                  <td>Tax (%)</td>
                  <td>Tax Amount</td>
                  <td>SO Discount (%)</td>
                  <td>Discount (%)</td>
                  <td>Net Rate</td>
                </tr>
              </thead>
              <tbody>
                {lineItems?.map((row, j) => (
                  <tr className="createVendorContactsTableRows" key={j}>
                    {
                    lineItemsTableCols.map((key, i) => {
                      switch (key) {
                        case "quantity":
                          return (
                            <td key={i} style={{ maxWidth: "0px" }}>
                              <input
                              style={{textAlign:"center",maxWidth:"8px"}}
                                className={
                                  lineItems[j].quantityError
                                    ? "createPurchaseCostInputError"
                                    : "createPurchaseCostInput"
                                }
                                type="text"
                                value={row[key]}
                                onChange={(e) =>
                                  updateLineItems(key, j, e.target.value)
                                }
                              />
                            </td>
                          );

                        case "unitPrice":
                          return (
                            <td key={i} style={{ maxWidth: "80px" }}>
                              <input
                                className={
                                  lineItems[j].unitPriceError
                                    ? "createPurchaseCostInputError"
                                    : "createPurchaseCostInput"
                                }
                                type="text"
                                value={row[key]}
                                onChange={(e) =>
                                  updateLineItems(key, j, e.target.value)
                                }
                              />
                            </td>
                          );
                        case "discount":
                          return (
                            <td key={i} style={{ maxWidth: "8px" }}>
                              <input
                                className={
                                  lineItems[j].discountError
                                    ? "createPurchaseCostInputError"
                                    : "createPurchaseCostInput"
                                }
                                type="text"
                                value={row[key]}
                                onChange={(e) =>
                                  updateLineItems(key, j, e.target.value)
                                }
                              />
                            </td>
                          );
                        
                          case "bundle":
                            return(
                              <td key={i} style={{ maxWidth: "8px" }}>
                              <input
                                className={
                                  lineItems[j].discountError
                                    ? "createPurchaseCostInputError"
                                    : "createPurchaseCostInput"
                                }
                                style={{ minWidth: "3rem" }}
                                type="number"
                                value={row[key] || ""}
                                min={0}
                                onChange={(e) =>
                                  updateLineItems(key, j, e.target.value)
                                }
                              />
                            </td>
                            );

                        case "netPrice":
                          return (
                            <td key={i}>{row[key] === 0 ? "-" : row[key]}</td>
                          );
                        case "itemName":
                          return(
                            <td key={i} className="stickyFirstColumn">{row[key]}</td>
                          )
                        default:
                          return (
                            <td key={i}>
                              {row[key] !== null ? row[key] : "-"}
                            </td>
                          );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
          <div className="poNetTotalSurchargeDiv">
            <div className="purchaseInstruction">
              <div>
                No Items: <span>{lineItems?.length}</span>
              </div>

              <div style={{ marginTop: "10px" }}>Invoice Instruction</div>
              <textarea
                className="createInvoiceMultilineInput"
                value={instruction}
                rows="3"
                cols="30"
                name="text"
                placeholder="Enter instructions"
                style={{
                  padding: "10px",
                  resize: "none",
                  marginTop: "10px",
                  height: "114px",
                  fontFamily: "sans-serif",
                }}
                onChange={(e) => {
                  setInstruction(e.target.value);
                }}
              />
              <div className="createSalesInvoiceGrid2">
                {lrDocument !== "" ? (
                  <div
                    className="formElement"
                    style={{ gridColumn: `auto / span ${9}` }}>
                    <FormHint hintText="LR Document" mandatory={false} />
                    <div className="inputDone">
                      <span
                        style={{
                          flex: 1,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          // maxWidth: "168px",
                          textOverflow: "ellipsis",
                        }}>
                        ✅ {lrDocument.name}
                      </span>
                      <span
                        style={{
                          paddingRight: "10px",
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "gray" },
                        }}
                        onClick={() => setLrDocument("")}
                        title="Remove and Re-upload LR Copy">
                        ❌
                      </span>
                    </div>
                  </div>
                ) : (
                  <FormElement
                    colSpan={9}
                    inputType="upload"
                    hintText="LR Document"
                    setInput={(file) => {
                      setLrDocument(file);
                    }}
                  />
                )}

                {outwardDocument !== "" ? (
                  <div
                    className="formElement"
                    style={{ gridColumn: `auto / span ${9}` }}>
                    <FormHint hintText="Outward Document" mandatory={false} />
                    <div className="inputDone">
                      <span
                        style={{
                          flex: 1,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          // maxWidth: "168px",
                          textOverflow: "ellipsis",
                        }}>
                        ✅ {outwardDocument.name}
                      </span>
                      <span
                        style={{
                          paddingRight: "10px",
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "gray" },
                        }}
                        onClick={() => setOutwardDocument("")}
                        title="Remove and Re-upload Outward Document">
                        ❌
                      </span>
                    </div>
                  </div>
                ) : (
                  <FormElement
                    colSpan={9}
                    inputType="upload"
                    hintText="Outward Document"
                    setInput={(file) => {
                      setOutwardDocument(file);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="poSpacer">&nbsp;</div>

            <div className="poNetTotal">
              <div className="poTotalTitle">
                <span>
                  <b>Net Total</b>
                </span>
                <span className="poNetTotalAmount">{netTotal}</span>
              </div>

              <div className="poFormElement">
                <div className="formElement">
                  <FormHint hintText="Other Charges" mandatory={false} />
                  <InputField
                    value={otherCharges}
                    setName={(value) => setOtherCharges(value)}
                    align="end"
                    error={otherChargesError}
                  />
                </div>
                <div className="formElement">
                  <FormHint hintText="Transport Charges" mandatory={false} />

                  <InputField
                    value={transportCharges}
                    setName={(value) => settransportCharges(value)}
                    align="end"
                    error={transportChargesError}
                  />
                </div>
                {<div>
                   <div
                style={{
                  gridColumn: `auto / span 6`,
                  margin: "auto 0px",
                  color: "#666666",
                  cursor: "pointer",
                }}
                onClick={() => setShowDiscount(!showDiscount)}>
                <input
                  type="checkbox"
                  name="discount"
                  checked={showDiscount}
                  readOnly
                  style={{ cursor: "pointer" }}
                />
                <label
                  htmlFor="discount"
                  style={
                    showDiscount
                      ? {
                          fontWeight: "600",
                          color: "#1f43e5",
                          padding: "0 10px",
                          cursor: "pointer",
                          fontSize: "17px",
                        }
                      : {
                          padding: "0 10px",
                          cursor: "pointer",
                          fontSize: "17px",
                        }
                  }>
                  Add Discount
                </label>
              </div>
              {showDiscount ? <div className="formElement">
                <FormHint hintText="Discount" mandatory={false} />

                <InputField
                  type="float"
                  value={finalDiscount}
                  setName={(value) => setFinalDiscount(parseFloat(value) >=0 ? parseFloat(value) : 0)}
                  align="end"
                  error={finalDiscountError}
                />
                </div> : null}

                </div>}
                <div className="formElement" style={{marginTop: "20px"}}>
                <FormHint hintText="TCS(%)" mandatory={false} />
                <InputField
                    type="float"
                    value={tcsPercentage}
                    setName={(value) => setTcsPercentage(value)}
                    align="end"
                    error={""}
                  />
                </div>
              </div>

              <div className="poTotalTitle" style={{ margin: "3rem 0 0 0" }}>
                <span>
                  <b>Total Amount</b>
                </span>
                <div className="poNetTotalAmount">{totalAmount}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

          {invoiceDetail.invoiceStatus === "Draft" ? (
            <button
              className="saveButton"
              onClick={() => {
                setErrorList([]);
                checkErrors("Draft");
              }}>
              Save Draft
            </button>
          ) : null}
          <button
            className="submitButton"
            onClick={() => {
              setErrorList([]);
              checkErrors("Approval Pending");
            }}>
            {editApprove ? "Edit & Approve" : " Submit for Approval"}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditInvoice);
