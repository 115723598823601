import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import CreateEditModal from "components/SmallComponents/CreateEditModal";
import { editBundleCreation } from 'redux/Item/ItemInfoActions';
import { addToken } from "redux/UserAccount/IsLoggedActions";
import FormElement from 'components/SmallComponents/FormElement';
import update from "immutability-helper";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import FetchNewToken from "serverCall/FetchNewToken";
import { useHistory } from "react-router-dom";
import fetchData from "serverCall/fetchData";

const CreateAgingReport = ({isLogged, addTokenToState, setSection}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading data. Please wait...");
    const [errors, setErrors] = useState([]);
    const history = useHistory();
    const [sectionList, setSectionList] = useState([]);
    const [lineItems, setLineItems] = useState([]);
    const [partyOrderList,setPartyOrderList] = useState([])
    const [agingParams, setAgingParams] = useState({
        sectionNo: {
          inputType: "options",
          value: "",
          hintText: "Sections ",
          mandatory: true,
          colSpan: 6,
          error: false,
          options: [],
          errorMessage: "Please select Section",
        },
        sectionName: {
          inputType: "text",
          value: "",
          disabled: true,
          hintText: "Section Name",
          mandatory: false,
          colSpan: 6,
          error: false,
        },
        quantity: {
          inputType: "float",
          value: "",
          hintText: "Total Quantity (Pcs)",
          mandatory: false,
          colSpan: 6,
          error: false,
          errorMessage: "Please enter a valid Quantity",
        },
        weight: {
          inputType: "float",
          value: "",
          hintText: "Weight Per Pieces",
          mandatory: false,
          colSpan: 6,
          error: false,
          errorMessage: "Please enter a valid weight",
        },
        cuttingLengthId: {
          inputType: "options",
          value: "",
          hintText: "Cutting Length",
          mandatory: true,
          colSpan: 6,
          error: false,
          options: [],
          errorMessage: "Please select Cutting Length",
        },
        refereeNo: {
          inputType: "number",
          value: "",
          hintText: "Referee No",
          mandatory: false,
          colSpan: 6,
          error: false,
          errorMessage: "",
        },
        pieces: {
          inputType: "number",
          value: "",
          hintText: "No. of Pieces",
          mandatory: false,
          colSpan: 6,
          error: false,
          errorMessage: "",
        },
        partyOrder: {
          inputType: "options",
          value: "",
          hintText: "Party Order",
          mandatory: false,
          colSpan: 6,
          error: false,
          options: [],
          errorMessage: "Please select Party Order",
        },
        hardness: {
          inputType: "number",
          value: "",
          hintText: "Hardness",
          mandatory: false,
          colSpan: 6,
          error: false,
          errorMessage: "",
        },
        damagePieces: {
          inputType: "number",
          value: "",
          hintText: "Damage Pieces",
          mandatory: false,
          colSpan: 6,
          error: false,
          errorMessage: "",
        },
        operationNo: {
          inputType: "text",
          value: "",
          hintText: "Operation No",
          mandatory: false,
          colSpan: 6,
          error: false,
          errorMessage: "Please enter a valid Operation No",
        },
        date: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please choose a date",
          },
          pressMachineNo: {
            inputType: "number",
            value: "",
            hintText: "Press Machine No",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
          },
        agingMachineNo: {
          inputType: "text",
          value: "",
          hintText: "Aging Machine No",
          mandatory: true,
          colSpan: 6,
          error: false,
          options: [],
          errorMessage: "Please Enter Aging Machine No",
        },
        sectionId: {
          value: ""
        }
      });

      async function getCuttingLengthList() {
        let cuttingLengthList = await fetchData({
          requestingPage: "uomList",
          method: "post", url: "fetch/cutting-length",
          headers: { token: isLogged.accessToken, module: "Cutting Length" }
        });
    
        if (cuttingLengthList.msg === "success") {
          return cuttingLengthList.asset
        } else {
          console.log(cuttingLengthList);
          return [];
        }
      }

      const checkToken = useCallback(async () => {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
          history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          addTokenToState(isLogged.employeeId, token2);
        } else {
          //console.log("Token not changed");
        }
      }, [isLogged, addTokenToState, history]);

      async function getItems() {
        let itemData = await fetchData({
          requestingPage: "itemList",
          method: "post",
          url: "fetch/items",
          data:{tab:"Active",type:"Sales"},
          headers: { token: isLogged.accessToken, module: "Items" },
        });
    
        let itemList = [];
        if (itemData.msg === "success") {
    
          itemData.asset.forEach((item) => {
            let itemObj = {};
            itemObj.optionId = item.item.itemId;
            itemObj.optionName = item.item.itemCode
            itemObj.sectionName = item.item.itemName
            itemObj.itemId = item.item.itemId;
            itemObj.mrp = item.item.mrp;
            itemObj.categoryId = item.item.categoryId;
            itemObj.sectionNo = item.item.sectionNo;    
            itemList.push(itemObj);
          });
          return itemList
        }
      }

      async function getInformation(){
          await checkToken()
          let sectionList = await getItems();
          let cuttingLengthList = await getCuttingLengthList();
          // let soIdList = await getSalesOrderApproved();
          let soList = await getSoApproved();
          setPartyOrderList(soList)

          setSectionList(sectionList);

          const paramsCopy = update(agingParams, {
            sectionNo: { options: { $set: sectionList } },
            cuttingLengthId: { options: { $set: cuttingLengthList } },
            // partyOrder: {options: { $set: soIdList}}
          });
          setAgingParams(paramsCopy)
      }

      async function getSoApproved() {
        var data = await fetchData({
          requestingPage: "soList",
          method: "post",
          url: "so-fetch/so-approved-list",
          headers: { token: isLogged.accessToken, module: "Sales Order" },
        });
        if (data.msg === "success") {
          return data.asset
          // var data2 = [];
    
          // data.asset.forEach((element, i) => {
          //   var newElement = Object.assign(element);
          //   newElement.collapse = true;
          //   data2.push(newElement);
          // });
        
        } else {
          console.log(data);
        }
      }

      useEffect(() => { getInformation() }, []);

      const submitOkClick = () => {
        setIsOpen(false);
        if (errors.length === 0) {
          setSection("agingReportList");
        }
      };

      const updateParameters = ({ param, key, value, wholeObject }) => {
        let bundleParamsCopy = Object.assign(agingParams);
        bundleParamsCopy = update(bundleParamsCopy, { [param]: { [key]: { $set: value } } });
        if (param === "sectionNo") {
          let partyOrderListCopy = partyOrderList.slice();
           partyOrderListCopy.filter((so,i)=>{
            return partyOrderListCopy[i].lineItemsList = so.lineItems.map(item=>item.itemId)
          })
          setPartyOrderList(partyOrderListCopy);

          let filteredList = partyOrderListCopy.filter((so)=>so.lineItemsList.includes(value))
          bundleParamsCopy = update(bundleParamsCopy, {
            sectionName: { value: { $set: wholeObject?.sectionName || "" } },
            sectionId: { value: { $set: wholeObject?.sectionId || "" } },
            partyOrder: { options: { $set: filteredList } }
          });
        }
        setAgingParams(bundleParamsCopy);
      }

      const renderFormElements = ({ elementList, param }) => {
        return elementList.map((element) => {
          return (
            <FormElement
              key={element}
              inputType={param[element].inputType}
              value={param[element].value !== null ? param[element].value : ""}
              setInput={(value, wholeObject) => {
                updateParameters({ param: element, key: "value", value: value, wholeObject });
              }}
              hintText={param[element].hintText}
              mandatory={param[element].mandatory}
              colSpan={param[element].colSpan}
              options={param[element].inputType === "options" ? param[element].options : null}
              error={param[element].error}
              rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
              placeholder={param[element].placeholder}
              title={param[element].title}
              disabled={param[element].disabled}
              mindate={param[element].mindate}
            />
          );
        });
      }

      const RenderTable = () => {
        return (
          <table className="createVendorContactsTable">
            <thead>
              <tr className="createVendorContactsTableHeader">
                <td>s.No</td>
                <td>Section No</td>
                <td>Referee No</td>
                <td>Section Name</td>
                <td>C/L</td>
                <td>Weight Per Piece</td>
                <td>No.Pieces</td>
                <td>Total Qty</td>
                <td>Party Order</td>
                <td>Hardness</td>
                <td>Damage Pieces</td>
                <td>Actions</td>

              </tr>
            </thead>
            {lineItems.length > 0 ?
              (<tbody>
                <RenderTableRows data={lineItems} />
              </tbody>) :
              (<tbody>
                <tr className="createVendorContactsTableRows">
                  {Array.from({length:12}).map((item, i) => {
                    return <td key={i}>&nbsp;</td>;
                  })}
                </tr>
              </tbody>)}
          </table>
        );
      };

      const RenderTableRows =(props) => {
        const { data } = props;
          return data.map((row, j) => {
              return (
                <tr className="createVendorContactsTableRows" key={j}>
                  <td>{j+1}</td>
                {["sectionNo","refereeNo", "sectionName","cuttingLengthId", "weight",
                  "pieces","quantity","partyOrderNo","hardness","damagePieces"].map((key, i) => {
                  return <td key={i}>{row[key] ? row[key] : " - "}</td>;
                })}
                <td>
                        <img
                            alt="Edit Record"
                            className="createVendorContactsAction"
                            src={editIcon}
                            onClick={() => {
                                // editSubSectionItem(j, "edit");
                            }}
                        />
                        <img
                            alt="Delete Record"
                            className="createVendorContactsAction"
                            src={deleteIcon}
                            onClick={() => {
                                var reset = window.confirm("Delete record?");
                                if (reset) {
                                    // editSubSectionItem(j, "delete");
                                }
                            }}
                        />
                    </td>
                </tr>
              );
          });
      };

      const addItemToLine = () => {
        let linecopy = lineItems.slice();
        let errorList = [];
        ["sectionNo", "sectionName", "cuttingLengthId",].forEach((item) => {
          if (agingParams[item].value === "" || agingParams[item].value === null) {
            agingParams[item].error = true;
            errorList.push("Provide Valid  Details");
          }
        })
        if (errorList.length === 0) {
          let partyOrder = partyOrderList.filter(so=>so.soId = agingParams.partyOrder.value)
          linecopy.push({
            itemId: agingParams.sectionNo.value,
            sectionNo: agingParams.sectionNo.options.filter((row)=>row.optionId===agingParams.sectionNo.value)[0].optionName.split(" - ")[0],
            sectionName: agingParams.sectionName.value,
            quantity: agingParams.quantity.value || null,
            weight: agingParams.weight.value||null,
            cuttingLengthId: agingParams.cuttingLengthId.value,
            cuttingLength: agingParams.cuttingLengthId.options.filter((opt)=>opt.optionId === agingParams.cuttingLengthId.value)[0].optionName,
            refereeNo:agingParams.refereeNo.value,
            pieces:agingParams.pieces.value|| null,
            hardness:agingParams.hardness.value || null,
            damagePieces:agingParams.damagePieces.value || null,
            partyOrder:agingParams.partyOrder.value||null,
            partyOrderNo:partyOrder.length?partyOrder[0]?.soNo:null

          });
    
          let trimmedList = agingParams.sectionNo.options.filter((item) => item.optionId !== agingParams.sectionNo.value);
          const bundleParamsCopy = update(agingParams, {
            sectionNo: { value: { $set: "" }, options: { $set: trimmedList } },
            sectionName: { value: { $set: "" } },
            weight: { value: { $set: "" } },
            cuttingLengthId: { value: { $set: "" } },
            quantity: { value: { $set: "" } },
          })
          setAgingParams(bundleParamsCopy);
          setLineItems(linecopy);
        } else {
          setErrors(errorList);
        }
      }

      const renderErrorMessage = () => {
        if (errors.length > 0)
          return errors[0]
      };

      const checkErrors=() => {
        let errorList = [];
        let paramsCopy= Object.assign(agingParams);
    
        ["sectionNo", "sectionName", "quantity", "weight", "cuttingLengthId"].map((item,i)=>{
          if(paramsCopy[item].value!=="")
              errorList.push("Unsaved  Record Found!");
      })
    
        let lineItemsCopy = lineItems.slice();
    
        if(lineItems.length<1)
            errorList.push("Select Section No");
    
        if(errorList.length===0 && lineItems.length> 0){
          let bundleLineItems = lineItems.map((line)=>(
            {...line, 
            })
          );

          let dataToServer={
            lineItems:bundleLineItems,
            agingMachineNo: agingParams.agingMachineNo.value,
            operationNo: agingParams.operationNo.value,
            pressMachineNo:agingParams.pressMachineNo.value,
            date :agingParams.date.value

          }
          submitData(dataToServer);
        } else {
          setLineItems(lineItemsCopy);
          setErrors(errorList);
        }
      }

      async function submitData(dataToServer){
        // console.log(dataToServer,"dataToServer")
        setIsOpen(true)
        setModalText("Creating Aging Report... Please Wait...")
    
        let response = await fetchData({
          requestingPage: "sectionMaster",
          method: "post",
          url: "so-create/aging-report",
          data: dataToServer,
          headers: { token: isLogged.accessToken, module: "Aging Report" }
        });
    
        if (response.msg === "success") {
          setDataSubmitted(true);
          setModalText("Data Submitted");
          setIsOpen(true);
        } else {
          setModalText(response.desc)
          console.log("Server Errr");
        }
      }
      const renderSubsection=()=>{

        return(
          <Fragment>
            <div className="purchaseOrderIndividualItemsArea" style={{ gridAutoRows: "60px" }}>
              {renderFormElements({
                elementList: ["sectionNo", "sectionName","refereeNo","cuttingLengthId", "weight",
                               "pieces","quantity","partyOrder","hardness","damagePieces"], 
                param: agingParams
              })}
              <FormElement
                inputType="addButton"
                value="+ Add"
                colSpan={4}
                setInput={() => {
                  setErrors([]);
                  addItemToLine();
                }}
              />
            </div>
            <RenderTable />
          </Fragment>
        )
      }
      return (
        <Fragment>
          <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
          <div className="formArea">
            <div
              style={{
                width: "1000px",
                margin: "0 auto 2rem",
                // padding: "3rem 3rem 1rem 3rem",
                padding: "2rem",
                border: "1px solid lightgray",
                borderRadius: "5px",
                backgroundColor: "white",
              }}>
              <div style={{ display: "flex" }}>
                <div
                  className="createPurchaseOrderGrid"
                  style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
                  {renderFormElements({ elementList: ["agingMachineNo", "operationNo","date","pressMachineNo"],
                                        param: agingParams, })}
                </div>
              </div>
              <div className='purchaseOrderSubSectionArea'>
                {renderSubsection()}
              </div>
            </div>
          </div>
          <div className="formSubmitArea">
            <div className="formSubmitInnerArea">
              <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
              <button className="submitButton" onClick={() => { setErrors([]); checkErrors(); }}>
                Submit
              </button>
            </div>
          </div>
        </Fragment>
      )
}

const mapStateToProps = (state) => ({
    isLogged: state.isLogged
  })
  
  const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
      editBundleCreation: (value) => dispatch(editBundleCreation(value))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(CreateAgingReport);