import React,{ Fragment,useEffect,useState } from "react"
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import { addEmployeeId,addEmployeeEfficiencyItemId } from "redux/PurchaseOrder/PoInfoActions";
import { connect } from "react-redux";
import fetchData from "../../serverCall/fetchData";

const EmployeeEfficiencyReport=({setSection,updateEmployeeId,updateEmployeeEfficiencyItemId,isLogged})=>{

    const [EmployeeReportParams, setEmployeeReportParams] = useState({
        employeeName: {
            inputType: "options",
            hintText: "Employee Name",
            colSpan: 4,
            error: false,
            errorMessage: "Please Select a Operator name",
            options:[],
            value: "",
            mandatory: true,
          },
          itemName: {
            inputType: "options",
            hintText: "Item Name",
            options:[{optionId:1,optionName:"role-check emp first item"}],
            colSpan: 4,
            error: false,
            errorMessage: "",
            value: "",
            mandatory: false,
          }
      })
      const [emplyeeReportError,setEmplyeeReportError]=  useState("");

      async function getEmployees() {
        var data = await fetchData({
            requestingPage: "employeeList",
            method: "POST",
            url: "fetch/employees",
            headers: { token: isLogged.accessToken,module:"Employee" },
        });
        if (data.msg === "success") {
          return data.asset;
        } else {
           console.log(data);
            return [];
        }
     }

     async function getItemDetail (){
      var data = await fetchData({
        requestingPage: "employeeList",
        method: "GET",
        url: "fetch/item-list",
        headers: { token: isLogged.accessToken,module:"Employee" },
    });
    if (data.msg === "success") {
      return data.asset;
    } else {
       console.log(data);
        return [];
    }

     }


    const fetchRequest =async()=>{
        let employeeList = await getEmployees(); 
        let itemList = await getItemDetail()
        let EmployeeReportParamsCopy = Object.assign(EmployeeReportParams)
        EmployeeReportParamsCopy= update(EmployeeReportParamsCopy, {employeeName: {options: {$set: employeeList} },
                                                                    itemName:{options:{$set:itemList}} })
        setEmployeeReportParams(EmployeeReportParamsCopy)
    }

    useEffect(()=>{
      fetchRequest()
    },[])

    const renderFormElements = ({ elementList, param }) => {
      
        return (
          <Fragment>
            {elementList.map((element, ind) => {
              return (
                <span style={{ flex: "50%", maxWidth: ind === 0 ? "500px" : "250px" }} key={element}>
                  <FormElement
                    inputType={param[element].inputType}
                    value={param[element].value}
                    setInput={(value) => updateParameter(element, "value", value)}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={
                      param[element]?.inputType === "options"
                        ? param[element]?.options
                        : null
                    }
                    error={param[element].error}
                    key={ind}
                    isClearable={param[element].isClearable}
                  />
                </span>
              );
            })}
          </Fragment>
        )
      }

      const updateParameter = (element, key, value) => {
        let paramsCopy = structuredClone(EmployeeReportParams);
        paramsCopy = update(paramsCopy, { [element]: { [key]: { $set: value } } });
        setEmployeeReportParams(paramsCopy);
      }

     
      const submitData =()=>{
       if(EmployeeReportParams?.employeeName?.value){
        setSection("employeeReportList");
        updateEmployeeId(EmployeeReportParams?.employeeName?.value);
        updateEmployeeEfficiencyItemId(EmployeeReportParams?.itemName?.value)
       }else{
       setEmplyeeReportError(EmployeeReportParams?.employeeName.errorMessage);
       }
      }
    
      
    return(
        <Fragment> 
          <div className="formArea" style={{ marginTop: "0", padding: "0" }}>
            <div
              style={{
                width: "1000px",
                height: "500px",
                margin: "0 2rem 4rem",
                padding: "1rem",
                border: "1px solid inherit",
                borderRadius: "5px",
                backgroundColor: "inherit",
              }}>
              <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>
                {renderFormElements({ elementList: ["employeeName","itemName"], param: EmployeeReportParams })}
                <FormElement
                  inputType="addButton"
                  value="Submit"
                  colSpan={5}
                  boxWidth="80px"
                  setInput={() => {
                    submitData()
                  }}
              />
              </div>
            
              <p className="formSubmitErrorTextArea" style={{textAlign:"center",fontSize:"16px"}}>
                {emplyeeReportError}
              </p>
            </div>
          </div>    
        </Fragment>
    )
}

const mapStateToProps=(state)=>{
  return{
    isLogged: state.isLogged,
  }
  }

const mapDispatchToProps=(dispatch)=>{
  return{
    updateEmployeeId: (value)=>dispatch(addEmployeeId(value)),
    updateEmployeeEfficiencyItemId :(value)=>dispatch(addEmployeeEfficiencyItemId(value))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(EmployeeEfficiencyReport)