import React, { Fragment, useEffect,useState } from "react";
import { connect } from "react-redux";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import fetchData from 'serverCall/fetchData';

const LoadingSlipDetail = ({setSection, addTokenToState, isLogged,storeInfo,setDownloadReportParams,screenName}) => {

    const [packingSlipDetail,setPackingSlipDetail] = useState([]);
    async function getPackingSlipDetail(){
        let url ="store-fetch/"
        url = url+"loading-slip-detail/"+storeInfo.loadingSlipId

        var psDetail = await fetchData({
          requestingPage: "customerList",
          method: "post",
          url: url,
          headers: { token: isLogged.accessToken, module:"Loading Slip" },
        });
        setPackingSlipDetail(psDetail.asset);
        setDownloadReportParams({
            dealerName: `Packing Slip Detail ${psDetail.asset.companyName}`,
            orderNo: psDetail.asset.psDate
        })
        return psDetail.asset
      }
      useEffect(()=>{
        getPackingSlipDetail();
      },[])

    const renderSummaryInfo=()=>{

        return(
            <table className='innerTable' style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        <td >
                            <span className="vendorSummaryValue">Date : </span><br></br>
                            <span className="vendorSummaryCategory">
                                {packingSlipDetail?.psDate}
                            </span>
                        </td>
                    </tr>
                    {/* <tr>
                        <td >
                            <span className="vendorSummaryValue" style={{ textTransform: "capitalize" }}>
                                Invoice No:</span><br></br>
                            <span className="vendorSummaryCategory">
                                {packingSlipDetail.invoiceNo}
                             
                                &nbsp;
                            </span>
                        </td>
                    </tr> */}
                    {/* {screenName==="Packing"?
                    
                    <tr>
                        <td>
                            <span className="vendorSummaryValue"> Sale order No</span><br />&nbsp;
                            <span className="vendorSummaryCategory">
                                {packingSlipDetail.soNo}
                            </span>
                        </td>
                    </tr>:null
                } */}
                    <tr>
                        <td><span className="vendorSummaryValue">Vehicle No</span><br />&nbsp;

                            <span className="vendorSummaryCategory">
                                {packingSlipDetail.vehicleNo}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ border: "none" }}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td style={{ border: "none" }}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td style={{ border: "none" }}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td style={{ border: "none" }}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td style={{ border: "none" }}>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        )
    }

   const RenderMainSection=()=>{

    return(
        <table  className="invoiceBill" style={{width:"100%"}}>
            <tbody>
                <tr>
                    <td>
                        <div className="vendorSummary" style={{ maxWidth: "max-content", margin: "0", display: "inline-flex", verticalAlign: "top" }}>
                            <div className="poSummaryText" style={{ maxWidth: "max-content" }}>
                                <span className="vendorSummaryCategory" style={{ color: "#000", textTransform: "capitalize" }}>Seller</span>
                                <span className="poSummaryValue">{`Company: ${packingSlipDetail.parentPlantName ||packingSlipDetail.plantName}`}</span>
                                <span className="poSummaryValue">{`Plant: ${ packingSlipDetail.plantName}`}</span>
                                <span className="poSummaryValue">{` Address: ${packingSlipDetail.plantState} ${packingSlipDetail.plantCity} ${packingSlipDetail.plantZipCode}`}</span>
                                <span className="poSummaryValue">{`GSTIN/UIN: ${packingSlipDetail.plantGstNo}`}</span>
                            </div>
                        </div>
                    </td>
                    <td rowSpan={2} style={{border:"none",padding:"0px",height:"100%"}}>
                    {renderSummaryInfo()}
                    </td>
                </tr>
                <tr>
                {/* {screenName==="Packing"?
                    <td>
                        <div className="vendorSummary" style={{ maxWidth: "max-content", margin: "0", display: "inline-flex", verticalAlign: "top" }}>
                            <div className="poSummaryText" style={{ maxWidth: "max-content" }}>
                                <span className="vendorSummaryCategory" style={{ color: "#000", textTransform: "capitalize" }}>Customer</span>
                                <span className="poSummaryValue">{`Company: ${packingSlipDetail.companyName}`}</span>
                                <span className="poSummaryValue">{`Customer Id: ${packingSlipDetail.customerId}`}</span>
                                <span className="poSummaryValue">{`Phone No: ${packingSlipDetail.customerPhone || packingSlipDetail.customerMobile}`}</span>
                                <span className="poSummaryValue">{`Address: ${packingSlipDetail.customerAddress},  ${packingSlipDetail.customerCity} ${packingSlipDetail.customerState}`}</span>
                                {packingSlipDetail.cusomerEmail && <span>Email: {packingSlipDetail.customerEmail}</span>}
                                <span className="poSummaryValue">{`GSTIN/UIN: ${packingSlipDetail.customerGstNo}`}</span>
                            </div>
                        </div>
                    </td>
                    :null} */}
                </tr>
              
            </tbody>
        </table>
    )
   }

   
   const RenderTable=({singlePageLineItems,pageNumber})=>{
       if(singlePageLineItems){

       const totalBundleCount = Object.values(singlePageLineItems)?.reduce((acc, val) => acc + val.length, 0);
       const totalWeight = Object.values(singlePageLineItems)?.reduce((acc, val) => acc.concat(val), []).reduce((total, { netWeight }) => total + netWeight, 0)
       return(
           <>
        <table className="innerTable" style={{width:"100%",height:"90%"}}>
            <thead>
                <tr className="invoiceDetailContactsTableHeader" style={{color: "#000"}}>
                    <td>S. No.</td>
                    <td>Bundle No</td>
                    <td>Section No</td>
                    <td>Section Name</td>
                    <td>C/L</td>
                    <td>Pcs</td>
                    <td>Net Wt.</td>
                    <td>Wt. Range</td>
                    <td>Remarks</td>
                </tr>
            </thead>
            <tbody>
                {
                    Object.entries(singlePageLineItems)?.map(([sectionKey,sectionLine],index)=>(
                    <React.Fragment key={index}>
                     {sectionLine.map((line,sNoIndex)=>(
                         <tr className="invoiceDetailContactsTableRows" key={sNoIndex}>
                             <td>{ line.serialNumber}</td> {/*S. No */}
                             <td>{(line.bundleId+'').padStart(6,0)}</td>
                             <td>{line.sectionNo}</td>
                             <td>{line.sectionName}</td>
                             <td>{line.cuttingLength}</td>
                             <td>{line.quantity}</td>
                             <td>{line.netWeight}</td>
                             <td>{""}</td>
                             <td>{line.remarks}</td>
                         </tr>
                     ))}
                     {/*Bundle Footer */}
                        <tr className="invoiceDetailContactsTableRows">
                            <td>&nbsp;</td>
                            <td>{sectionLine.length}</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>{sectionLine.reduce((acc,line)=>acc+line.quantity,0).toFixed(2)}</td> {/**pcs */}
                            <td>{sectionLine.reduce((acc,line)=>acc+line.netWeight,0).toFixed(2)}</td>{/**net wt */}
                            <td>{ /*wt range */
                            ( (sectionLine.reduce((acc,line)=>acc+line.netWeight,0).toFixed(2))
                            / (sectionLine.reduce((acc,line)=>acc+line.quantity,0).toFixed(2)) ).toFixed(3) 
                            } </td>
                        </tr>
                    </React.Fragment>
                    ))
                }
            </tbody>
        </table>
        <div style={{display:"flex",justifyContent:"space-between",fontSize:"14px"}}>
               <span>{`Total Bundle Count: ${totalBundleCount} `}</span>   
              {packingSlipDetail.detailPageLineItems[pageNumber+1] ? <span>Continued to Page No {pageNumber+1}</span> : null}
               <span >{`Total Weight: ${totalWeight}`}</span>
            </div>
            </>
       )
            } else{
                return null;
            }
   }
    return(
        <Fragment>
            <div className="detailsPageWrapper">
                {
                    packingSlipDetail.detailPageLineItems?.length > 0 ?
                    packingSlipDetail.detailPageLineItems.map((currPage,pageIndex)=>(
                        <Fragment key={pageIndex}>
                        <div className="detailsPageContainer">
                            <div className="invoicePageTitle">{"Loading Slip"}</div>
                            <div style={{ border: "0.25px solid black", minHeight: "1100px", position: "relative" }}>
                                {/*main content */
                                pageIndex ===0 && <RenderMainSection />
                                }
                                <RenderTable singlePageLineItems={currPage} pageNumber={pageIndex+1}/>
                            </div>
                        </div>
                        </Fragment>
                    )) 
                    :null
                }
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo:state.storeInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
  };

  export default connect(mapStateToProps, mapDispatchToProps)(LoadingSlipDetail)