import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import FetchNewToken from "serverCall/FetchNewToken";
import fetchData from "serverCall/fetchData";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import editIcon from "assets/edit.svg";
import editIconDisabled from "assets/edit_disabled.svg";
import {editCuttingLengthId} from "redux/Item/ItemInfoActions";


const CuttingLengthList = ({
  setSection,
  // state
  isLogged,

  // dispatch
  editCuttingLengthId,
  addTokenToState, }) => {
  const [loading, setLoading] = useState(true);
  const [cuttingLengthList, setCuttingLengthList] = useState([]);
  const [noRecords, setNoRecords] = useState(false);

  const history = useHistory();
  const checkToken = useCallback(async () => {
    console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }, [isLogged, history, addTokenToState]);

  const getCuttingLengthList = useCallback(async () => {
    setLoading(false);
    var data = await fetchData({
      requestingPage: "sectionMaster",
      method: "post",
      url: "fetch/cutting-length",
      headers: { token: isLogged.accessToken, module: "Cutting Length" },
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        setNoRecords(true);
      } else {
        setCuttingLengthList(data.asset);
      }
    } else {
      console.log(data);
    }
  }, [isLogged])

  const getInformation = useCallback(
    async () => {
      await checkToken();
      await getCuttingLengthList();
    },
    [checkToken, getCuttingLengthList]);

  useEffect(() => {
    getInformation();
  }, [getInformation]);

  const renderCuttingLengthList = () => {
    return (
      cuttingLengthList.map((row, index) => {
        return (
          <tr className='uomListData' key={index}>
            <td className="uomSymbolData">{row.cuttingLength}</td>
            <td className='uomSymbolData'>{row.symbol}</td>
            <td className="uomActionData">
              <img
                className="uomEditImage"
                alt="Edit Record"
                src={isLogged.access["Cutting Length"] === "Edit" ? editIcon : editIconDisabled}
                onClick={() => {
                  if (isLogged.access["Cutting Length"] === "Edit") {
                    editCuttingLengthId(row.cuttingLengthId);
                    setSection("editCuttingLength");
                  }
                }}
                title={isLogged.access["Cutting Length"] === "Edit" ? "Edit Record" : "No Edit Access"}
                style={isLogged.access["Cutting Length"] === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
              />
            </td>
          </tr>
        )
      })
    )
  }
  return (
    <div className="uomListArea">
      <table className="uomListTable">
        <thead>
          <tr className=" uomListHeader">
            <td className="uomSymbolHeader">
              <span>Cutting Length</span>
            </td>
            <td className="uomSymbolHeader">
              <span>UOM</span>
            </td>
            <td className="uomActionHeader">
              <span>Action</span>
            </td>
          </tr>
        </thead>
        <tbody>
          {cuttingLengthList.length > 0 ? renderCuttingLengthList() : null}
        </tbody>
      </table>
      {noRecords ? (
        <div className="noRecordsYet">No records found</div>
      ) : loading ? (
        <div className="loader" style={{ margin: "1rem auto" }}></div>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({ isLogged: state.isLogged })

const mapDispatchToProps = (dispatch)=>{
  return {
    addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    editCuttingLengthId: (value)=>dispatch(editCuttingLengthId(value))
};
}

export default connect(mapStateToProps, mapDispatchToProps)(CuttingLengthList)