import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import { useHistory } from "react-router-dom";
import FormElement from "../SmallComponents/FormElement";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";

const LocationList = ({ isLogged, addTokenToState }) => {
  const [locationParams, setLocationParams] = useState({
    country: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Available Countries",
      colSpan: 5,
      error: false,
      errorMessage: "Select A country",
    },
    state: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Available State/Region in Selected Country",
      colSpan: 10,
      error: false,
      errorMessage: "Select A State/Province/Region",
    },
  });

  const [cityList, setCityList] = useState([]);
  useEffect(async () => {
    await checkToken();
    let countriesFromDB = await getDim({ field: "country" }, "country");
    let locationCopy = Object.assign(locationParams);
    locationCopy = update(locationCopy, {
      country: { options: { $set: countriesFromDB } },
    });
    setLocationParams(locationCopy);
  }, []);

  const history = useHistory();

  async function checkToken() {
    console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getDim(dataSent, dimension) {
    var data = await fetchData({
      requestingPage: "getDim",
      method: "post",
      url: "dim",
      headers: { token: isLogged.accessToken, module: "Employee" },
      data: dataSent,
    });
    // console.log(data);
    if (data.msg !== "failure") {
      var optionArray = [];

      switch (dimension) {
        case "country":
          data.forEach((dataItem) => {
            // console.log("Country: ");
            var a = {
              optionName: dataItem.country.toUpperCase(),
              optionId: dataItem.country_id,
            };
            optionArray.push(a);
          });
          break;

        case "state":
          data.forEach((dataItem) => {
            var a = {
              optionName: dataItem.state.toUpperCase(),
              optionId: dataItem.state_id,
            };
            optionArray.push(a);
          });
          break;

        case "cityId":
          data.forEach((dataItem) => {
            // console.log(dataItem);
            var a = {
              optionName: dataItem.city.toUpperCase(),
              optionId: dataItem.city_id,
            };
            optionArray.push(a);
          });
          break;

        default:
          break;
      }

      return optionArray;
    } else {
      console.log(data);
      return [];
    }
  }

  useEffect(async () => {
    let locationCopy = Object.assign(locationParams);
    if (locationParams.country.value) {
      let statesFromDB = await getDim(
        {
          field: "state",
          filterBy: "country_id",
          filterValue: locationParams.country.value,
        },
        "state"
      );
      locationCopy = update(locationCopy, {
        state: { options: { $set: statesFromDB } },
      });
    } else {
      locationCopy = update(locationCopy, {
        state: { options: { $set: [] }, value: { $set: "" } },
      });
    }
    setLocationParams(locationCopy);
  }, [locationParams.country.value]);

  useEffect(async () => {
    let citiesFromDB = [];
    if (locationParams.state.value) {
      citiesFromDB = await getDim(
        {
          field: "city",
          filterBy: "state_id",
          filterValue: locationParams.state.value,
        },
        "cityId"
      );
      setCityList(citiesFromDB);
    } else {
      setCityList(citiesFromDB);
    }
  }, [locationParams.state.value]);

  const updateParameter = (element, key, value) => {
    let locationCopy = Object.assign(locationParams);
    locationCopy = update(locationCopy, {
      [element]: { [key]: { $set: value } },
    });
    setLocationParams(locationCopy);
  };

  const renderFormElements = ({ elementList, param }) => {
    return (
      <div style={{ maxWidth: "250px" }}>
        {elementList.map((element, ind) => {
          return (
            <FormElement
              inputType={param[element].inputType}
              value={param[element].value}
              setInput={(value) => updateParameter(element, "value", value)}
              hintText={param[element].hintText}
              mandatory={param[element].mandatory}
              colSpan={param[element].colSpan}
              options={
                param[element].inputType === "options"
                  ? param[element].options
                  : null
              }
              error={param[element].error}
              key={ind}
            />
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="formArea">
        <div
          style={{
            width: "1000px",
            height: "500px",
            margin: "0 auto 4rem",
            padding: "1rem",
            border: "1px solid inherit",
            borderRadius: "5px",
            backgroundColor: "inherit",
          }}>
          <div className="createItemPurchaseGrid">
            {renderFormElements({
              elementList: ["country", "state"],
              param: locationParams,
            })}
            <ul style={{ gridRow: "2" }} className="locationListPage">
              <span className="vendorListInnerTableHeading">
                Available Cities in Selected State
              </span>
              {cityList.length > 0
                ? cityList.map((obj, ind) => (
                    <li key={ind}>{obj.optionName}</li>
                  ))
                : ""}
            </ul>
            <table className="location-list-table">
              <thead>
                <tr>
                  <td colSpan={3}>Available</td>
                </tr>
                <tr>
                  <td>Countries</td>
                  <td>States</td>
                  <td>Cities</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{locationParams.country.options.length}</td>
                  <td>{locationParams.state.options.length}</td>
                  <td>{cityList.length}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationList);
