import axios from "axios";
import { serverEndpoint } from "./environmentVariables";

const fetchData = ({
    // props
    requestingPage,
    method,
    url,
    data,
    headers,
}) => {
    return new Promise((resolve, reject) => {
        switch (requestingPage) {
            case "login":
                axios({ method: method, url: serverEndpoint + url, data: data })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        console.log("Error:", err, err.response.data);
                        resolve(err.response.data);
                    });

                break;

            case "roleList":
            case "employeeDetail":
            case "vendorList":
            case "vendorDetail":
            case "customerList":
            case "customerDetail":
            case "uomList":
            case "plantList":
            case "plantDetail":
            case "itemCategoryList":
            case "itemBrandList":
            case "itemTypeList":
            case "itemProcessList":
            case "getCategory":
            case "getBrand":
            case "getItemType":
            case "getProcess":
            case "getBom":
            case "itemDetail":
            case "getParents":
            case "taxList":
            case "itemPriceTypeList":
            case "screenControl":
            case "approvePo":
            case "approveMrs":
            case "idnPage":
            case "approveDc":
            case "dcDetail":
            case "cusReceiptDetail":
            case "approveCusReceipt":
            case "venPaymentList":
            case "venPaymentDetail":
                axios({ method: method, url: serverEndpoint + url, headers: headers })
                    .then((res) => {
                      
                        resolve(res.data);
                    })
                    .catch((err) => {
                        if (err && err.response) resolve(err.response.data);
                    });
                break;

            case "employeeImage":
            case "vendorImage":
            case "CustomerImage":
            case "itemImage":
            case "diagramImage":
            case "plantImage":
                axios({ method: method, url: serverEndpoint + url, headers: headers, responseType: "arraybuffer" })
                    .then((response) => {
                     

                        resolve(Buffer.from(response.data, "binary").toString("base64"));
                    })
                    .catch((err) => {
                        resolve("");
                    });
                break;

            case "createRole":
            case "editRole":
            case "getDim":
            case "createEmployee":
            case "editEmployee":
            case "createVendor":
            case "createCustomer":
            case "createUom":
            case "editUom":
            case "createPlant":
            case "editPlant":
            case "createItemCategory":
            case "editItemCategory":
            case "createItemBrand":
            case "editItemBrand":
            case "createItemType":
            case "editItemType":
            case "createItemProcess":
            case "editItemProcess":
            case "createItem":
            case "createItemPurchaseCost":
            case "createItemTax":
            case "getItemTaxInfo":
            case "getItemPurchaseCostPre":
            case "itemTaxPreCreate":
            case "createItemPrice":
            case "purchaseCostList":
            case "salesPriceList":
            case "getSalesPrice":
            case "createScreenControl":
            case "poItemDetail":
            case "createPoItem":
            case "declinePo":
            case "createIdn":
            case "createDc":
            case "declineDc":
            case "editDc":
            case "location":
            case "dashboard":
            case "soList":
            case "invoiceList":
            case "cusReceiptList":
            case "productionList":
            case "MRSList":
            case "dcListPage":
            case "itemList":
            case "opdList":
            case "inwardList":
            case "outwardList":
            case "qcList":
            case 'stockTransferList':
            case "BillOfMaterial":
            case "workOrder":
            case "employeeList":
            case "importReport":
            case "stockReport":
            case "productionReport":
            case "sectionMaster":
            case "premiumCharges":
            case "BundleCreation":
            case "createPsItem":
            case "PaymentSlipList":  
            case "OtherChargesList":    
                axios({ method: method, url: serverEndpoint + url, headers: headers, 
                    maxBodyLength: Infinity,
                    maxContentLength: Infinity,
                    data: data })
                    .then((res) => {
                        
                        resolve(res.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
                break;
            case "report":
                axios({ method: method, url: serverEndpoint + url, headers: headers })
                    .then((res) => resolve(res.data))
                    .catch((err) => {
                        resolve(err.response.data);
                    });
                break;
            case "getFile":
                axios({ method: method, url: serverEndpoint + url, headers: headers })
                    .then((res) => {
                        return res.data.asset.blob();
                    })
                    .then((blob) => {
                        const url = window.URL.createObjectURL(new Blob([blob]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "ItemsTemplate.xlsx");

                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // Clean up and remove the link
                        link.parentNode.removeChild(link);
                    });
                break;

            case 'gstValidation':
                axios({method: 'get', url: serverEndpoint+url,headers})
                .then((res)=>resolve(res.data))
                .catch((err)=>resolve(err.response.data))
                break;

            default:
                console.log("=== No Fetch Case matched ===");
                break;
        }
    });
};

export async function getUom(isLogged) {
    var uomData = await fetchData({
      requestingPage: "getBom",
      method: "get",
      url: "fetch/item-uom-list",
      headers: { token: isLogged.accessToken, module: "Production Order" },
    });
  
    var itemList = [];
    var itemUomListFromServer = {};
  
    if (uomData.msg === "success") {
      uomData.asset.forEach((itemUom) => {
        var uomObj = {};
        uomObj.optionId = itemUom.item.itemId;
        uomObj.optionName = itemUom.item.itemCode+" - "+itemUom.item.itemName;
        uomObj.categoryId = itemUom.item.categoryId;
        itemList.push(uomObj);
         
        itemUomListFromServer[itemUom.item.itemId] = [];
        itemUom.uom.forEach((uom) => {
          var itemUomObj = {};
          itemUomObj.optionId = uom.uomId;
          itemUomObj.optionName = uom.uom;
          itemUomObj.from = itemUom.item.from;
          itemUomObj.to = itemUom.item.to;
          itemUomObj.netWeight = itemUom.item.netWeight;
          itemUomObj.grossWeight = itemUom.item.grossWeight;
          itemUomListFromServer[itemUom.item.itemId].push(itemUomObj);
        });
      });
      return [itemList, itemUomListFromServer];
    }
    return [itemList, itemUomListFromServer];
  };

export default fetchData;
