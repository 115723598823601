import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";

import Modal from "react-modal";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import logoNotAvailable from "../../assets/no-stopping.svg";
import { poEditInvoiceId, poInvoiceCreateId } from "../../redux/PurchaseOrder/PoInfoActions";

const PurchaseInvoiceDetail = ({
    // props
    setSection,
    approval,
    setEditAndApprove,

    // state
    isLogged,
    poInfo,

    // dispatch
    addTokenToState,
    soInvoiceCreate,
    poEditInvoice,
}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving Invoice. Please wait...");

    const [diagramImage, setDiagramImage] = useState("");
    const [diagramModalOpen, setDiagramModalOpen] = useState(false);

    const [invoiceDetail, setInvoiceDetail] = useState({});
    const [lineItemsList, setLineItemsList] = useState([]);

    const [vendorLogo, setVendorLogo] = useState("");
    const [invoiceDocument, setInvoiceDocument] = useState("");

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getInvoiceDetail();
    }

    const history = useHistory();

    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getInvoiceDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "po-fetch/invoice/" + poInfo.poViewInvoiceId,
            headers: { token: isLogged.accessToken, module: "Purchase Invoice" },
        });
        if (data.msg === "success") {
            setInvoiceDetail(data.asset[0]);
            setLineItemsList(data.asset[0].lineItems);
        } else {
            console.log(data);
        }
    }

    useEffect(() => {
        
        getVendorLogo();
        // eslint-disable-next-line
    }, [invoiceDetail]);

    async function getVendorLogo() {
        var data = await fetchData({
            requestingPage: "vendorImage",
            method: "get",
            url: "image/vendor-logo/" + invoiceDetail.vendorId,
            headers: { token: isLogged.accessToken, module: "Purchase Invoice" },
        });
        setVendorLogo(data);
    }

    useEffect(() => {
        // if (soDetail.customerLogoAvailable) {
        getInvoiceDocument();
        // }
        // eslint-disable-next-line
    }, [invoiceDetail]);

    async function getInvoiceDocument() {

        if (invoiceDetail?.invoice_document !== null) {
            var data = await fetchData({
                requestingPage: "itemImage",
                method: "get",

                url: "image/purchase-invoice-document/" + invoiceDetail.invoice_document,
                headers: { token: isLogged.accessToken, module: "Purchase Invoice" },
            });
            if (data !== "") {
                setInvoiceDocument(data);
            }
        }
    }

    const submitOkClick = () => {
        setIsOpen(false);

        setSection("purchaseInvoiceList");
        // }
        setModalText("Approving Invoice. Please wait...");
    };

    const renderSummaryInfo = () => {
        if (invoiceDetail !== {}) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        {invoiceDetail.invoiceNo}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={
                                invoiceDetail.invoiceStatus === "Declined"
                                    ? { color: "red" }
                                    : invoiceDetail.invoiceStatus === "Approved"
                                        ? { color: "#14c76a" }
                                        : { color: "#333333" }
                            }
                        >
                            ( {invoiceDetail.invoiceStatus} )
                        </span>
                    </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">Order Type</span>
                        <span className="vendorSummaryValue">: Purchase Order</span>

                        <span className="vendorSummaryCategory">Order No</span>
                        <span className="vendorSummaryValue">: {invoiceDetail.poNo}</span>

                        <span className="vendorSummaryCategory">Order Date</span>
                        <span className="vendorSummaryValue">: {invoiceDetail.poDate}</span>

                        <span className="vendorSummaryCategory">Invoice Date</span>
                        <span className="vendorSummaryValue">: {invoiceDetail.invoiceDate}</span>

                        <span className="vendorSummaryCategory">E-Way Bill No</span>
                        <span className="vendorSummaryValue">: {invoiceDetail.eWayBillNo}</span>

                        <span className="vendorSummaryCategory">Quality Checking</span>
                        <span className="vendorSummaryValue">
                            :{" "}
                            {invoiceDetail?.qcNo?.map((qc, i) => {
                                return i === invoiceDetail?.qcNo.length - 1 ? <span>{qc}</span> : <span>{qc}, </span>;
                            })}
                        </span>
                    </div>
                </React.Fragment>
            );
        }
    };

    const renderImageStatus = () => {
        return (
            <div>
                <span style={{ flex: "1" }}>&nbsp;</span>

                <img
                    className="poVendorImage"
                    src={
                        vendorLogo !== null && vendorLogo !== undefined && vendorLogo !== ""
                            ? `data:image/png;base64,${vendorLogo}`
                            : logoNotAvailable
                    }
                    alt="Vendor Logo"
                />
            </div>
        );
    };

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td>Item</td>
                        <td>Item Code</td>
                        <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td>
                        <td>UOM</td>
                        <td>PO Qty</td>
                        <td>Accepted Qty</td>
                        <td>Invoice Qty</td>
                        <td>Unit Price</td>
                        <td>Tax (%)</td>
                        <td>Net Price</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={lineItemsList} tab="contact" />
                </tbody>
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        var lineItemParams = ["itemName", "itemCode", "from", "to", "netWeight", "grossWeight", "uom", "poQuantity", "acceptedQuantity", "invoiceQuantity", "unitPrice", "tax", "netPrice"];

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i}>{row[key] !== null || row[key] !== undefined ? row[key] : "-"}</td>;
                    })}
                </tr>
            );
        });
    };

    const [errorMessage, setErrorMessage] = useState([]);

    const renderErrorMessage = () => {
        if (errorMessage.length > 0) {
            return errorMessage[0];
        }
    };

    async function approveInvoice() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `po-edit/invoice-approve/${invoiceDetail.invoiceId}`,
            headers: { token: isLogged.accessToken, module: "Purchase Invoice" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Approved Invoice");
        } else {
            setModalText(data.desc);
            setErrorMessage(data.desc);
        }
    }

    const ApproveButtons = () => {
        return (
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="saveButton"
                        onClick={() => {
                            setEditAndApprove(true);
                            setSection("editPurchaseInvoice");

                            poEditInvoice(invoiceDetail.invoiceId);
                            poInvoiceCreateId(invoiceDetail.poId);
                        }}
                    >
                        Edit
                    </button>
                    <button
                        className="submitButton"
                        onClick={() => {
                            approveInvoice();
                        }}
                    >
                        Approve
                    </button>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <Modal
                isOpen={diagramModalOpen}
                onRequestClose={() => setDiagramModalOpen(false)}
                contentLabel="Example Modal"
                style={{
                    overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
                    content: {
                        textAlign: "center",
                        width: "70%",
                        height: "70%",
                        position: "fixed",
                        top: "15%",
                        left: "15%",
                        padding: "20px",
                        borderRadius: "5px",
                        boxShadow: "0 0 5px gray",
                    },
                }}
            >
                <img className="modalImage" src={`data:image/jpeg;base64,${diagramImage}`} alt="Item Diagram" />
            </Modal>

            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="poSummaryText">{renderSummaryInfo()}</div>
                        <div className="poSummaryText2">
                            <span className="vendorSummaryRole" style={{ textTransform: "uppercase" }}>
                                {invoiceDetail.vendorName}
                            </span>

                            <span className="poSummaryValue">
                                {`Vendor ID: ${invoiceDetail.vendorId}`}
                            </span>
                            <span className="poSummaryValue">
                                {invoiceDetail.vendorPinCode !== null
                                    ? ` ${invoiceDetail.vendorAddress} - ${invoiceDetail.vendorPinCode}`
                                    : invoiceDetail.vendorAddress}
                            </span>
                            <span className="poSummaryValue">
                                {invoiceDetail.vendorMobile !== null || invoiceDetail.vendorPhone !== null ? `\nPhone: ` : null}
                                {invoiceDetail.vendorMobile !== null ? invoiceDetail.vendorMobile : null}
                                {invoiceDetail.vendorPhone !== null ? `, ${invoiceDetail.vendorPhone} ` : null}
                            </span>
                            <span className="poSummaryValue">
                                {invoiceDetail.vendorEmail !== null ? `Email Id: ${invoiceDetail.vendorEmail}` : null}
                            </span>

                            <span className="poSummaryValue">
                                {invoiceDetail.vendorGstNo !== null ? `GST No: ${invoiceDetail.vendorGstNo}` : null}
                            </span>
                        </div>
                        {renderImageStatus()}
                    </div>

                    {invoiceDetail.invoiceStatus === "Declined" ? (
                        <div style={{ marginTop: "2rem" }}>
                            <span style={{ color: "red", fontWeight: "bold" }}>Reason : </span> {invoiceDetail.declineReason}
                        </div>
                    ) : null}

                    <div className="vendorDetailTabArea">
                        <RenderTable />
                    </div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                No Items: <span>{lineItemsList.length}</span>
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                Invoice Instruction: {invoiceDetail.instruction !== null ? invoiceDetail.instruction : "-"}
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                Invoice Document:{" "}
                                {invoiceDetail?.invoice_document !== null ? (
                                    <span
                                        style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
                                        onClick={() => {
                                            setDiagramModalOpen(true);
                                            setDiagramImage(invoiceDocument);
                                        }}
                                    >
                                        Open link
                                    </span>
                                ) : (
                                    "NA"
                                )}
                            </div>
                        </div>
                        <div className="poSpacer">&nbsp;</div>
                        <div className="poNetTotal">
                            <div className="poTotalTitle">
                                <span>
                                    <b>Net Total</b>
                                </span>
                                <span className="poNetTotalAmount">{invoiceDetail.netTotal}</span>
                            </div>
                            <div className="SoDetailChargesDiv">
                                <div className="poText">
                                    <span>Other Charges</span>
                                    <span>{invoiceDetail.otherCharges}</span>
                                </div>

                                <div className="poText">
                                    <span>Transport Charges </span>
                                    <span>{invoiceDetail.transportCharges}</span>
                                </div>
                            </div>

                            <div className="poTotalTitle" style={{ margin: "1rem 0 0 0" }}>
                                <span>
                                    <b>Total Amount</b>
                                </span>
                                <div className="poNetTotalAmount">{invoiceDetail.totalAmount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {approval ? <ApproveButtons /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),

        poInvoiceCreate: (invoiceCreateId) => dispatch(poInvoiceCreateId(invoiceCreateId)),
        poEditInvoice: (editInvoiceId) => dispatch(poEditInvoiceId(editInvoiceId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoiceDetail);
