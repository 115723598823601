import React,{ Fragment, useEffect,useState } from "react"
import viewIcon from "assets/view.svg";
import { connect } from "react-redux";
import fetchData from "serverCall/fetchData";
import editIcon from "assets/edit.svg";
import editIconDisabled from "assets/edit_disabled.svg";
import { addToSalesLeadId } from "redux/Store/StoreInfoActions";

const SalesLeadList =({isLogged,setCurrentSection,editSalesLeadId})=>{

    const [leadList,setDealList] = useState([])
    const access = isLogged.access["Sales Lead"];


    const salesLeadDetail =async()=>{

        let data = await fetchData({
          requestingPage:"employeeDetail",
          method:"get",
          url:"so-fetch/sales-lead-list",
          headers:{token:isLogged.accessToken}
        })
    
        if(data.msg === "success"){
            setDealList(data.asset)
        }
      }

      useEffect(()=>{
        salesLeadDetail();
      },[])

    const RenderOtherChargesList=()=>{
        return(
            <Fragment>
              <table className="vendorListTable">
              <thead>
              <tr className="vendorListHeader" style={{zIndex:1}}>
            <td
              className="vendorListHeading stickyFirstColumn"
              >
              Lead Name
            </td>
            <td
              className="vendorListHeading">
              Email
            </td>
            <td className="vendorListHeading">
              Phone No
            </td>    
            <td className="vendorListHeading">
              Company Name
            </td>   
            <td className="vendorListHeading">
              Designation
            </td>  
            <td className="vendorListHeadingActions">Actions</td>
          </tr>
              </thead>
              <tbody>{leadList.length > 0 ?
              renderDealList()
              :null}</tbody>
          </table>
            </Fragment>
        )
    }

    const renderDealList =()=>{
        return leadList.map((item,i)=>{
          return (
             <Fragment key={i}>
              <tr className="vendorList">
            <td className="vendorListInfo">{`${item.firstName} ${item.lastName}`}</td>
            <td className="vendorListInfo">{item.email|| "--"}</td>
            <td className="vendorListInfo">{item.phoneNo}</td>
            <td className="vendorListInfo">{item.companyName}</td>
            <td className="vendorListInfo">{item.designation||"--"}</td>
            <td className="vendorListInfoActions">
                     <img
                      alt="Edit Record"
                      className="vendorActionImages"
                      src={
                        access === "Edit" || access === "App/Dec"
                          ? editIcon
                          : editIconDisabled
                      }
                      title={
                        access === "Edit" || access === "App/Dec"
                          ? "Edit Item"
                          : "Access Denied"
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        if (access === "Edit" || access === "App/Dec") {
                          editSalesLeadId(item.leadId);
                          setCurrentSection("editSalesLead");
                        }
                      }}
                      style={
                        access === "Edit" || access === "App/Dec"
                          ? { cursor: "pointer" }
                          : { cursor: "not-allowed" }
                      }
                    />
               </td>
            </tr>
             </Fragment>
          )
        })
      }
    
return (
    <Fragment>
          <div className="vendorListArea" style={{maxHeight:"450px"}}>
        <React.Fragment>
          {RenderOtherChargesList()}
          {leadList.length ? null 
             : <div className="noRecordsYet">No records found</div>}
        </React.Fragment>
        </div>
    </Fragment>
)
}

const mapStateToProps = (state) => {
    return {
      isLogged: state.isLogged,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      editSalesLeadId: (value) => dispatch(addToSalesLeadId(value)),
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(SalesLeadList) 