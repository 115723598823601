import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import DeclineModal from "../SmallComponents/DeclineModal";
import Modal from "react-modal";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import fetchData from "../../serverCall/fetchData";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { connect } from "react-redux";
import logoNotAvailable from "../../assets/no-stopping.svg";
import { editInwardId } from "../../redux/Store/StoreInfoActions";

const InwardDetail = ({ setSection, addTokenToState, isLogged, storeInfo, approval, setEditAndApprove, editInward }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving PO. Please wait...");

    const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);
    const [declineModalText, setDeclineModalText] = useState("Provide a reason for declining Invoice");
    const [declineReason, setDeclineReason] = useState("");
    const [declineReasonError, setDeclineReasonError] = useState(false);

    const [diagramImage, setDiagramImage] = useState("");
    const [diagramModalOpen, setDiagramModalOpen] = useState(false);

    const [inwardDetail, setInwardDetail] = useState({});

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getInwardDetail();
    }

    const history = useHistory();

    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getInwardDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "store-fetch/inward-detail/" + storeInfo.viewInwardId,
            headers: { token: isLogged.accessToken, module: "Inward" },
        });
        if (data.msg === "success") {
            setInwardDetail(data.asset[0]);
            // setLineItemsList(data.asset[0].lineItems);
        } else {
            console.log(data);
        }
    }

    const [inwardDocument, setInwardDocument] = useState("");

    useEffect(() => {
        if (inwardDetail.inwardDocument) {
            getInwardDocument();
        }
        // }
        // eslint-disable-next-line
    }, [inwardDetail]);

    async function getInwardDocument() {

        if (inwardDetail?.inwardDocument) {
            var data = await fetchData({
                requestingPage: "itemImage",
                method: "get",
                url: "image/inward-document/" + inwardDetail.inwardDocument,
                headers: { token: isLogged.accessToken },
            });

            if (data !== "") {
                setInwardDocument(data);
            }
        }
    }

    const [customerLogo, setCustomerLogo] = useState("");

    useEffect(() => {
        if(inwardDetail.companyId)
        getVendorLogo();
        // eslint-disable-next-line
    }, [inwardDetail]);

    async function getVendorLogo() {
        var urlDomain = inwardDetail?.orderType === "Purchase Order" ? "vendor-logo/" : "customer-logo/";
        var data = await fetchData({
            requestingPage: "vendorImage",
            method: "get",
            url: "image/" + urlDomain + inwardDetail.companyId,
            headers: { token: isLogged.accessToken, module: "Inward" },
        });
        setCustomerLogo(data);
    }

    const renderImageStatus = () => {
        return (
            <div>
                <span style={{ flex: "1" }}>&nbsp;</span>

                <img
                    className="poVendorImage"
                    src={
                        customerLogo !== null && customerLogo !== undefined && customerLogo !== ""
                            ? `data:image/png;base64,${customerLogo}`
                            : logoNotAvailable
                    }
                    alt="Vendor Logo"
                />
            </div>
        );
    };

    const renderSummaryInfo = () => {
        if (inwardDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        {inwardDetail.inwardNo}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={
                                inwardDetail.inwardStatus === "Declined"
                                    ? { color: "red" }
                                    : inwardDetail.inwardStatus === "Approved"
                                        ? { color: "#14c76a" }
                                        : { color: "#333333" }
                            }
                        >
                            ( {inwardDetail.inwardStatus} )
                        </span>
                    </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">Order Type</span>
                        <span className="vendorSummaryValue">: {inwardDetail.orderType}</span>

                        <span className="vendorSummaryCategory">Order No</span>
                        <span className="vendorSummaryValue">: {inwardDetail.orderNo}</span>

                        <span className="vendorSummaryCategory">Order Date</span>
                        <span className="vendorSummaryValue">: {inwardDetail.orderDate}</span>

                        <span className="vendorSummaryCategory">Inward Date</span>
                        <span className="vendorSummaryValue">: {inwardDetail.inwardDate}</span>

                        <span className="vendorSummaryCategory">Batch No</span>
                        <span className="vendorSummaryValue">: {inwardDetail.batchNo !== null ? inwardDetail.batchNo : "-"}</span>
                    </div>
                </React.Fragment>
            );
        }
    };

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td>Item</td>
                        <td>Item Code</td>
                        <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td>
                        <td>HSN No</td>
                        <td>UOM</td>
                        <td>Quantity</td>
                        <td>Received Quantity</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={inwardDetail.lineItems} tab="contact" />
                </tbody>
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        var lineItemParams = ["itemName", "itemCode", "from", "to", "netWeight", "grossWeight", "hsnCode", "uom", "orderQuantity", "receivedQuantity"];

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                    })}
                </tr>
            );
        });
    };

    const submitOkClick = () => {
        setIsOpen(false);

        setSection("inwardList");

        setModalText("Approving SO. Please wait...");
    };

    const declineOkClick = () => {
        if (declineReason.trim().length > 0) {
            setDeclineReasonError(false);
            setDeclineModalIsOpen(false);

            setIsOpen(true);
            setModalText("Declining SO. Please Wait...");
            // declineInvoice();
        } else {
            setDeclineReasonError(true);
        }
    };

    const [errorMessage, setErrorMessage] = useState("");

    async function approveInward() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `store-edit/inward-approve/${storeInfo.viewInwardId}`,
            headers: { token: isLogged.accessToken, module: "Inward" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Inward Approved");
        } else {
            console.log(data);
            setModalText(data.desc);
            setErrorMessage(data.desc);
        }
    }

    const renderErrorMessage = () => {
        if (errorMessage !== "") {
            return errorMessage;
        }
    };

    const ApproveButtons = () => {
        return (
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="saveButton"
                        onClick={() => {
                            setEditAndApprove(true);
                            editInward(storeInfo.viewInwardId);
                            setSection("editInward");
                        }}
                    >
                        Edit
                    </button>

                    <button
                        className="submitButton"
                        onClick={() => {
                            approveInward();
                        }}
                    >
                        Approve
                    </button>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <DeclineModal
                openDeclineModal={declineModalIsOpen}
                declineOkClick={declineOkClick}
                declineModalText={declineModalText}
                declineReason={declineReason}
                error={declineReasonError}
                setDeclineReason={setDeclineReason}
                cancelDecline={() => setDeclineModalIsOpen(false)}
            />
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <Modal
                isOpen={diagramModalOpen}
                onRequestClose={() => setDiagramModalOpen(false)}
                contentLabel="Example Modal"
                style={{
                    overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
                    content: {
                        textAlign: "center",
                        width: "70%",
                        height: "70%",
                        position: "fixed",
                        top: "15%",
                        left: "15%",
                        padding: "20px",
                        borderRadius: "5px",
                        boxShadow: "0 0 5px gray",
                    },
                }}
            >
                <img className="modalImage" src={`data:image/jpeg;base64,${diagramImage}`} alt="Item Diagram" />
            </Modal>

            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="poSummaryText">{renderSummaryInfo()}</div>
                        <div className="poSummaryText2">
                            <span className="vendorSummaryRole" style={{ textTransform: "uppercase" }}>
                                {inwardDetail.companyName}
                            </span>

                            <span className="poSummaryValue">
                                {`ID: ${inwardDetail.companyId}`}
                            </span>
                            <span className="poSummaryValue">
                                {inwardDetail.companyPinCode !== null
                                    ? ` ${inwardDetail.companyAddress}, ${inwardDetail.companyCity} - ${inwardDetail.companyPinCode}`
                                    : `${inwardDetail.companyAddress} , ${inwardDetail.companyCity}`}
                            </span>
                            <span className="poSummaryValue">
                                {inwardDetail.companyMobile !== null || inwardDetail.companyPhone !== null ? `\nPhone: ` : null}
                                {inwardDetail.companyMobile !== null ? inwardDetail.companyMobile : null}
                                {inwardDetail.companyPhone !== null ? `, ${inwardDetail.companyPhone} ` : null}
                            </span>
                            <span className="poSummaryValue">
                                {inwardDetail.companyEmail !== null ? `Email Id: ${inwardDetail.companyEmail}` : null}
                            </span>

                            <span className="poSummaryValue">
                                {inwardDetail.companyGstNo !== null ? `GST No: ${inwardDetail.companyGstNo}` : null}
                            </span>
                        </div>
                        {renderImageStatus()}
                    </div>

                    {inwardDetail.inwardStatus === "Declined" ? (
                        <div style={{ marginTop: "2rem" }}>
                            <span style={{ color: "red", fontWeight: "bold" }}>Reason : </span> {inwardDetail.declineReason}
                        </div>
                    ) : null}

                    <div className="vendorDetailTabArea">
                        {inwardDetail.lineItems !== undefined && inwardDetail.lineItems?.length > 0 ? <RenderTable /> : null}
                    </div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                No Items:
                                <span> {inwardDetail.lineItems?.length}</span>
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                Inward Instruction: {inwardDetail.inwardInstruction !== null ? inwardDetail.inwardInstruction : "NA"}
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                Inward Document:{" "}
                                {inwardDetail.inwardDocument !== null ? (
                                    <span
                                        style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
                                        onClick={() => {
                                            setDiagramModalOpen(true);
                                            setDiagramImage(inwardDocument);
                                        }}
                                    >
                                        Open link
                                    </span>
                                ) : (
                                    "NA"
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {approval ? <ApproveButtons /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),

        editInward: (inwardId) => dispatch(editInwardId(inwardId)),

        // soInvoiceCreate: (invoiceCreateId) => dispatch(soInvoiceCreateId(invoiceCreateId)),
        // soEditInvoice: (editInvoiceId) => dispatch(soEditInvoiceId(editInvoiceId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InwardDetail);
