import React, { useEffect, useState,useRef } from "react";
import resetIcon from "../../assets/return.svg";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import FormElement from "../SmallComponents/FormElement";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

// const customStyles = {
//     option: (provided, state) => ({
//         ...provided,
//         color: state.isSelected ? "white" : "#666",
//         padding: "5px 20px",
//     }),
//     control: (_, state) => ({
//         width: "100%",
//         height: "2.5rem",
//         // borderRadius: "5px",
//         color: "#666",
//         fontsize: "16px",
//         border: state.selectProps.error ? "1px solid red" : "1px solid rgb(200,200,200)",
//         boxSizing: "border-box",
//         flex: 1,
//         display: "flex",
//         backgroundColor: state.selectProps.disabled ? "rgb(240,240,240)" : "white",
//     }),
//     singleValue: (provided, state) => {
//         const opacity = state.isDisabled ? 0.5 : 1;
//         const transition = "opacity 300ms";
//         const color = "#666";
//         const fontSize = "16px";

//         return { ...provided, opacity, transition, color, fontSize };
//     },
//     indicatorSeparator: () => {
//         return null;
//     },
// };
// async function usePrevious(value) {
//     const ref = useRef();
//     useEffect(() => {
//       ref.current = value;
//     },[value]);
//     return ref.current;
//   }
const CreateScreenControl = ({ isLogged, addTokenToState, setSection }) => {
    const [masterList,setMasterList]=useState([]);
    
    const [masterObj, setMasterObj] = useState({
        "Sales Dashboard":{checked: false,access: null},
        "Quote Dashboard":{checked: false,access: null},

        "Stock Report":{checked : false, access :null},
        "Item Report":{checked:false,access:null},
        "Movement Analysis":{checked : false, access :null},
        "Import Report":{checked:false,access:null},
        "Production Report":{checked:false,access:null},
        "Production Log Report":{checked :false,access: null},
        "Employee Efficiency Report":{checked :false,access:null},
        "Aging Report":{checked :false,access:null},
        "Sales Report":{checked:false, access: null},
        "Customer Ledger":{checked:false, access: null},
        "Outstanding statement":{checked:false, access: null},
        "Purchase Report" :{checked : false, access :null},
       "Vendor Ledger" :{checked : false, access :null},
       "Vendor Invoice" :{checked : false, access :null}, 
        
        "Role": { checked: false, access: null },
        "Role Control":{checked: false, access: null},
        "Screen Control":{checked :false,access: null},
        "UOM": { checked: false, access: null },
        "Plant & Store": { checked: false, access: null },
        "Item Menu":{checked: false, access: null},
        "Item Category": { checked: false, access: null },
        "Item Brand": { checked: false, access: null },
        "Item Type": { checked: false, access: null },
        "Item Process": { checked: false, access: null },
        "Item Purchase Cost": { checked: false, access: null },
        "Item Price Type": { checked: false, access: null },
        "Item Tax": { checked: false, access: null },
        "Items": { checked: false, access: null },
        "Item Sales Price": { checked: false, access: null },
        "Item Currency": {checked: false, access: null},
        "Cutting Length": {checked :false,access: null},
        "Section Master":{checked:false,access:null},
        "Die Master":{checked:false,access:null},
        "Premium Charges":{checked:false,access:null},
        "Location":{checked:false,access: null},
        "Employee": { checked: false, access: null },
        "Vendor": { checked: false, access: null },
        "Customer": { checked: false, access: null },
        "Transporter":{checked: false, access: null},
        "Clearing Agent": {checked: false, access: null},
        "Purchase Order": {checked: false, access: null},
        "Purchase Invoice": {checked : false, access :null},
        "Vendor Payments": {checked: false,access: null},
        "Inventory": {checked: false, access: null},
        "OPD": {checked : false, access :null},
        "Inward": {checked : false, access :null},
        "Outward": {checked : false, access :null},
        "Quality Checking": {checked : false, access :null},
        "Stock Transfer":{checked : false, access :null},
        "Pending SO":{checked : false, access :null},
        "Pending MRS":{checked : false, access :null},
        "IDN":{checked : false, access :null},
        "Packaging":{checked:false,access:null},
        "Bundle Creation":{checked:false,access:null},
        "Packing Slip":{checked:false,access:null},
        "Import PO":{checked : false, access :null},
        "Purchase Import":{checked : false, access :null},
        "Sales Order":{checked : false, access :null},
        "Sales Invoice":{checked : false, access :null},
        "Other Charges":{checked : false, access :null},
        "Customer Receipt":{checked:false,access:null},
        "Production Inward":{checked:false,access:null},
        "Production Planning":{checked : false, access :null},
        "Production Order":{checked : false, access :null},
        "Work Order":{checked: false,access: null},
        "Process Log": {checked: false,access: null},
        "Material Requisition":{checked : false, access :null},
        "DC List": {checked: false, access: null},
        "Bill Of Material":{checked: false,access: null},
        "Sub Contracting":{checked : false, access :null},
        "Loading Slip" :{checked : false, access : null},
        "Sales Opportunities" :{checked : false, access : null},
        "Sales Quote" :{checked : false, access : null},
        "Sales Lead":{checked : false, access : null}



    });
    const [includeEmp,setIncludeEmp] =useState(false);
    const [screenControlParams,setScreenControlParams]=useState({
        plantId:{
            inputType: "options",
            value: "",
            hintText:"Plant Name",
            options:[],
            mandatory: true,
            colSpan: 3,
            placeholder: "Select Plant",
            error: false,
            errorMessage: "Please Select Plant Name",
            disabled: false,
        },
        roleId:{
            inputType:"options",
            value: "",
            hintText:"Role Name",
            options:[],
            mandatory:true,
            colSpan:3,
            placeholder: "Select Role",
            error: false,
            errorMessage:"Please Select Role Name",
            disabled: false,
        },
        empNo:{
            inputType:"options",
            value:"",
            hintText:"Employee No",
            options:[],
            mandatory: includeEmp,
            colSpan:3,
            placeholder: "Select Employee",
            error: false,
            errorMessage:"Please Select Employee No",
            disabled: false,
        } 
    });
    const [cloneParams,setCloneParams]=useState(JSON.parse(JSON.stringify(screenControlParams)));
    cloneParams.empNo.mandatory=true;

    const updateMasterObj = (value, obj) => {
        var newObj = Object.assign(masterObj);
        var updatedObj = update(newObj, { [obj]: { access: { $set: value }, checked: { $set: true } } });
     
        setMasterObj(updatedObj);
    };

    const resetSelection = (e, obj) => {
        var newObj = Object.assign(masterObj);
        var updatedObj = update(newObj, { [obj]: { access: { $set: null }, checked: { $set: false } } });
    
        setMasterObj(updatedObj);
    };

    const [rawRoles,SetRawRoles] =useState([]);
    const [cloneRoles,setCloneRoles]=useState([]);
    const [screenControlError, setScreenControlError] = useState([]);
    const [cloneCheck,setCloneCheck] = useState(false);
    const [editable,setEditable] = useState(false)
    
    useEffect(() => {
        // get roles that don't have any screen controls for creating new
        getInformation();
            // let listArray =Object.keys(isLogged.access)
            // listArray.splice(listArray.indexOf("plantAccess"),1);
            // setMasterList(listArray);
            let listArray =[];
        Object.keys(isLogged.access).forEach((key)=>{
            if(isLogged.access[key]!=null && key!=="plantAccess")
            listArray.push(key);})
        // console.log("listArray",listArray);
        setMasterList(listArray);
    }, []);

    async function getInformation() {
        await checkToken();
        await getPlants();
        await getScreenControlRolesPreCreate();
    }

    useEffect(()=>{
        if(cloneCheck === true)
        {getCloneRolesPreCreate();}
        else if(cloneCheck === false && masterList.length>0)
        {
            updateParams({section:"clone",paramName:"plantId",key:"options",value:[]});
            updateParams({section:"clone",paramName:"roleId",key:"options",value:[]});
            updateParams({section:"clone",paramName:"empNo",key:"options",value:[]});
            updateParams({section:"clone",paramName:"plantId",key:"value",value:""});
            updateParams({section:"clone",paramName:"roleId",key:"value",value:""});
            updateParams({section:"clone",paramName:"empNo",key:"value",value:""});
            let masterObjCopy= Object.assign(masterObj);
            masterList.forEach((menu)=>{
                masterObjCopy = update(masterObjCopy,{[menu]: { checked:{ $set: false},access:{$set: null} } });   
            });
            setMasterObj(masterObjCopy);
        }
    },[cloneCheck]);

    const history = useHistory();
    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
        return true;
    }
    const [plantOptions,setPlantOptions]=useState([]);
    const [plantAccess,setPlantAccess]=useState([]);
    async function getPlants(){
        var plant=await fetchData({
        requestingPage: "screenControl",
        method: "get",
        url: "fetch/plant",
        headers:{token: isLogged.accessToken,module: "Screen Control"}
        });
        if(plant.msg==="success"){
            var optionArray=[];
            plant.asset.forEach((dataItem)=>{
                var a = { optionName: dataItem.plant_name, optionId: dataItem.plant_id };
                optionArray.push(a);
            });

            setPlantOptions(optionArray)
        } else{
            console.log(plant);
        }
    }

    async function getScreenControlRolesPreCreate() {
        var data = await fetchData({
            requestingPage: "screenControl",
            method: "get",
            url: "fetch/screen-control-role-list-pre-create",
            headers: { token: isLogged.accessToken,module: "Screen Control" },
        });
        if (data.msg === "success") {
            SetRawRoles(data.asset);
            var optionArray1=[];
            
            data.asset.forEach((dataItem)=>{
                var tempPlantObj1={optionId:dataItem.plantId,optionName:dataItem.plantName};
                var canGroup1 = !(optionArray1.some((obj1)=>obj1.optionId === tempPlantObj1.optionId));
                if(canGroup1){
                    optionArray1.push(tempPlantObj1);
                    }    
                });
                    optionArray1=optionArray1.sort((curr,next)=>{return parseFloat(curr.optionId)-parseFloat(next.optionId)});
                    updateParams({section:"general",paramName:"plantId",key:"options",value:optionArray1});
        }
    }

    async function getCloneRolesPreCreate(){
        var data2=await fetchData({
            requestingPage: "screenControl",
            method: "get",
            url: "fetch/screen-control-role-list-editable",
            headers: {token: isLogged.accessToken,module: "Screen Control" },
        });
        if(data2.msg==="success"){
            setCloneRoles(data2.asset);
             var optionArray2 = [];
             data2.asset.forEach((dataItem)=>{
            var tempPlantObj2={optionId:dataItem.plantId,optionName:dataItem.plantName};
            var canGroup2 = !(optionArray2.some((obj2)=>obj2.optionId === tempPlantObj2.optionId));
            if(canGroup2){
                optionArray2.push(tempPlantObj2);
                }   
            });
                optionArray2=optionArray2.sort((curr,next)=>{return parseFloat(curr.optionId)-parseFloat(next.optionId)});
                updateParams({section:"clone",paramName:"plantId",key:"options",value:optionArray2});
        }
    }
  
// const {scPlantVal,scRoleVal} = {scPlantVal:screenControlParams["plantId"]["value"],
//                                 scRoleVal: screenControlParams["roleId"]["value"]}
//  const prevVal=usePrevious({scPlantVal,scRoleVal});

//For General Role Name options
    useEffect(()=>{
            if(screenControlParams.plantId.value!==""){
                var tempRoleOptions=[];
                 rawRoles.forEach((v)=>{
                   if(v.plantId === screenControlParams.plantId.value)
                   { 
                     var tempRoleObj={optionId: v.roleId,optionName:v.roleName};
                    
                    var canGroup= !(tempRoleOptions.some((sub)=>sub.optionId === tempRoleObj.optionId));
                    if(canGroup)
                     tempRoleOptions.push(tempRoleObj);
                     
                   } 
                 });
                 updateParams({section: "general",paramName:"roleId",key:"options",value:tempRoleOptions}); }
                },[screenControlParams.plantId.value]);

//For General Employee No Options
useEffect(()=>{
             if(screenControlParams.roleId.value!==""){
                var tempEmpOptions=[];
                rawRoles.forEach((v)=>{
                 if(v.roleId === screenControlParams.roleId.value && v.plantId === screenControlParams.plantId.value){
                     var tempEmpObj={optionId: v.empNo, optionName: v.empNo};
                    tempEmpOptions.push(tempEmpObj);
                 }
                });
               updateParams({section:"general",paramName: "empNo",key:"options",value:tempEmpOptions});}
               else if(screenControlParams.roleId.value=="")
               {
                   updateParams({section:"general",paramName:"empNo",key:"options",value:[]});
               }
    },[screenControlParams.roleId.value]);

//For Clone Role Name Options
useEffect(()=>{
    if(cloneParams.plantId.value!==""){
        var tempRoleOptions=[];
        cloneRoles.forEach((v)=>{
           
            if(v.plantId == cloneParams.plantId.value){
                var tempRoleObj ={optionId: v.roleId, optionName: v.roleName};
                var canGroup = !(tempRoleOptions.some((sub)=>sub.optionId === tempRoleObj.optionId));
               
                if(canGroup)
                tempRoleOptions.push(tempRoleObj);
            }
          });
          updateParams({section: "clone",paramName:"roleId",key:"options",value:tempRoleOptions});}
},[cloneParams.plantId.value]);

//For Clone Employee No Options
useEffect(()=>{
    var tempEmpOptions=[];
    if(cloneParams.roleId.value!==""){
    cloneRoles.forEach((v)=>{
        if(v.roleId === cloneParams.roleId.value  && v.plantId === cloneParams.plantId.value){
            var tempEmpObj={optionId: v.empNo,optionName:v.empNo};
            tempEmpOptions.push(tempEmpObj);
            }
        });
        updateParams({section:"clone",paramName: "empNo",key:"options",value:tempEmpOptions});}
        else if(cloneParams.roleId.value=""){
            updateParams({section:"clone",paramName:"empNo",key:"options",value:[]});
        }
},[cloneParams.roleId.value]);

//Invoking selected Employees  Permissions
useEffect(()=>{
    var masterObjCopy=Object.assign(masterObj);
if(cloneParams.empNo.value!="" && cloneCheck){
    var yes = window.confirm(`Do You want To copy Screen Control Permissions of ${cloneParams.empNo.value}`);
    if(yes){
    (async function getSelectedEmpPermissions(){
       var empDetail = await fetchData({
        requestingPage: "screenControl",
        method: "get",
        url: `fetch/screen-control-role-pre-edit/${cloneParams.plantId.value}/${cloneParams.roleId.value}/${cloneParams.empNo.value}`,
        headers: {token: isLogged.accessToken,module: "Screen Control" },});
    
    if(empDetail.msg="success"){
        var subMenuArray=empDetail.asset[0].subMenus;
        var accessArray=empDetail.asset[0].access;
        subMenuArray.forEach((menu,index)=>{
            if(accessArray[index]!=="null")
            masterObjCopy=update(masterObjCopy,{[menu]:{checked:{$set:true},access:{$set:accessArray[index]} } });
            else
            masterObjCopy=update(masterObjCopy,{[menu]:{checked:{$set:true},access:{$set: null} } });
        });
        setMasterObj(masterObjCopy);
        window.alert("Permissions Of Selected Employee No. is Copied!");
       } })(); }
   } else if(cloneParams.empNo.value === "" && !cloneCheck){
       masterList.forEach((menu,index)=>{
        masterObjCopy=update(masterObjCopy,{[menu]:{checked:{$set:false},access:{$set:null} } });
       });
       setMasterObj(masterObjCopy);
       }
},[cloneParams.empNo.value,cloneCheck]);

    const checkErrors = () => {
        var errorList=[]; 
        var generalParams =Object.assign(screenControlParams);
        ["plantId","roleId","empNo"].forEach((item)=>{
            if(generalParams[item].mandatory){
                generalParams =update(generalParams,{[item]: {error: {$set: !validateMandatory(generalParams[item].value.toString()) } }});
            }
            if(generalParams[item].error){
                errorList.push(generalParams[item].errorMessage);
            }
        })
        var permissionChecked =(masterList.every((subMenu)=>masterObj[subMenu]["access"]===null));
        if(permissionChecked) {errorList.push("Grant Permission For Atleast One Module")} else{console.log("Go Ahead")} 
        if (errorList.length == 0) {
            var arrayToServer = [];
                    var menusAndAccess =Object.entries(masterObj).filter(([key,value])=>value.access!==null);
                    var subMenuArray = JSON.stringify(menusAndAccess.map((keyAndValue)=>keyAndValue[0])); //Getting Keys i.e. Menu Names
                    var accessArray = JSON.stringify(menusAndAccess.map((keyAndValue)=>keyAndValue[1]["access"])); // Getting Values i.e. Access of menus
                    var plantAccessArray = plantAccess.length>0 ? plantAccess.map((plant)=>plant.value) : [];
                    if(includeEmp){ 
                        var newScreenControl={
                            empNo: generalParams.empNo.value,
                            plantId: generalParams.plantId.value,
                            roleId: generalParams.roleId.value,
                            subMenus: subMenuArray,
                            access:accessArray,
                            plantAccess: plantAccessArray,
                            editableForApprove :editable
                        }
                        arrayToServer.push(newScreenControl);
                    } else{ //For all employees of selected role ,(if employee No is not selected)
                    rawRoles.forEach((v)=>{
                        if(v.plantId === generalParams.plantId.value && v.roleId === generalParams.roleId.value )
                         {
                            var newScreenControl = {
                                    empNo: v.empNo,
                                    plantId: generalParams.plantId.value,
                                    roleId: generalParams.roleId.value,                                                            
                                    subMenus: subMenuArray,
                                    access: accessArray,
                                    plantAccess: plantAccessArray,
                                    editableForApprove :editable
                                    };
                        arrayToServer.push(newScreenControl);}
                    });}

            if (arrayToServer.length > 0) {
                setIsOpen(true);
                submitData(arrayToServer);
            } else {
                setScreenControlError([errorList[0]]);
            }
        } else {
            setScreenControlError([errorList[0]]);
        }
    };

    const submitData = async (data) => {
        setDataSubmitted(false);
        setIsOpen(true);
        var result = await fetchData({
            requestingPage: "createScreenControl",
            method: "post",
            url: "create/screen-control",
            headers: { token: isLogged.accessToken,module: "Screen Control" },
            data: data,
        });

        if (result.msg === "success") {
            setDataSubmitted(true);
            setModalText("Successfully created Screen Control!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setScreenControlError([result.desc]);
        }
    };

    const submitOkClick = () => {
        setIsOpen(false);
        console.log("error",screenControlError.length,screenControlError);
        if (screenControlError.length === 0) {
            setSection("screenControlList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    const renderErrorMessage = () => {
        if (screenControlError.length > 0) return screenControlError[0];
    };

    const appDecModules= ["Items","Purchase Order","Purchase Import","Purchase Invoice","Vendor Payments","OPD","Inward","Outward","Quality Checking","Stock Transfer","Sales Order","Sales Invoice","Other Charges","Customer Receipt","Production Order","Material Requisition","DC List", "Sub Contracting"];
    const updateParams=({section,paramName,key,value})=>{
        if(section === "general"){
            var generalParams = Object.assign(screenControlParams);
            if(paramName==="plantId" && generalParams.plantId.value!==""){
                generalParams = update(generalParams,{[paramName]: {[key]:{$set : value} } });
                generalParams =update(generalParams,{roleId: {value : {$set: ""}},
                                                    empNo: {value: {$set: ""}} });
            }else{
            generalParams = update(generalParams,{[paramName]: {[key]:{$set : value} } });}
            setScreenControlParams(generalParams);
        } else if(section === "clone")
        {
            var copyParams = Object.assign(cloneParams);
            if(paramName==="plantId" && copyParams.plantId.value !==""){
                copyParams =update(copyParams,{[paramName]: {[key]: {$set:value} } });
                copyParams =update(copyParams,{roleId: {value : {$set: ""}},
                                                empNo: {value: {$set: ""}} });
            }else{
            copyParams =update(copyParams,{[paramName]: { [key]:{$set: value} } });}
            setCloneParams(copyParams);
        }
    }
    useEffect(()=>{
        var copyScreen=Object.assign(screenControlParams);
        if(includeEmp){
            copyScreen = update(copyScreen,{empNo: { mandatory: {$set: true} } })
        }else{
            copyScreen = update(copyScreen,{empNo: {mandatory: {$set: false},value: {$set: ""} } });
        } setScreenControlParams(copyScreen);
    },[includeEmp]);
 const selectAll=["View","Create","Edit","App/Dec"];
 const [selectAllObj,setSelectAllObj]=useState({selectedState :{ checked: false, access: null } });
useEffect(()=>{
    var copyMasterObj=Object.assign(masterObj);
if(selectAllObj.selectedState.checked===true){
   
    masterList.forEach((menu)=>{
        if(selectAllObj.selectedState.access === "App/Dec" && !appDecModules.includes(menu))
        copyMasterObj=update(copyMasterObj,{[menu]:{checked: {$set: true},access: {$set: "Edit"}}});
        else
        copyMasterObj=update(copyMasterObj,{[menu]:{checked: {$set: true},access:{$set: selectAllObj.selectedState.access} } });
    });
} else if(selectAllObj.selectedState.checked=== false){
    masterList.forEach((menu)=>{
        copyMasterObj=update(copyMasterObj,{[menu]:{checked: {$set: false},access:{$set: null} } });
    });
   
}
setMasterObj(copyMasterObj);
},[selectAllObj]);

const itemMenus=["Item Category","Item Brand","Item Type","Item Process","Item Purchase Cost","Item Price Type","Item Tax","Items","Item Sales Price","Item Currency","Cutting Length","Section Master","Die Master", "Premium Charges"];
const storeStockMenus=["Item Report","Movement Analysis"];
const packagingMenus=["Bundle Creation","Loading Slip","Packing Slip"];
const salesReportMenus=["Customer Ledger","Outstanding statement"];
// const productionReportSubMenus=["Production Log Report","Employee Efficiency Report"];
const purchaseOrderMenus =["Vendor Ledger","Vendor Invoice"]

 const renderFormElements = ({ elementList, params, section }) =>{
       return  elementList.map((element)=>{ 
            if(!(element==="empNo" && !includeEmp === true) && section ==="general" || section === 'clone' && cloneCheck=== true)
            return( 
                    <FormElement style={{width:"60px !important"}}
                        key={element}
                        inputType={params[element].inputType}
                        value={params[element].value !== null ? params[element].value : ""}
                        setInput={(value)=>{updateParams({section,paramName: element, key:"value", value}); }}
                        hintText={params[element].hintText}
                        mandatory={params[element].mandatory}
                        colSpan={params[element].colSpan}
                        options={
                                params[element].inputType === "options"
                                ? params[element].options
                                : null
                                }
                        error={params[element].error}
                        rowSpan={
                                element === "vendorLogo" || element === "otherBusinessDetails"
                                ? params[element].rowSpan
                                : 0
                                }
                        placeholder={params[element].placeholder}
                        title={params[element].title}
                        disabled={params[element].disabled}
                        mindate={params[element].mindate} /> 
                );
        });
       
    };
    const NotifySubMenu=({MenuName})=>{
        switch (MenuName) {
            case "Item Menu":
                return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Item Menu To Enable Sub Menus in Item</td>
            case "Stock Report":
                return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Stock Report To Enable Sub Menus in Stock Report</td>
            case "Packaging":
                return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Packaging To Enable Sub Menus in Packaging</td>
            case "Sales Report":
                return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Sales Report To Enable Sub Menus in Sales Report</td>
            // case "Production Report":
            //     return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Production Report To Enable Sub Menus in Production Report</td>
            case "Purchase Report":
                return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Purchase Report To Enable Sub Menus in Purchase Report</td>
            // case "Sales Opportunities":
            //     return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Sales Opportunities To Enable Sub Menus in Sales Opportunities</td>
            default:
                return null;
        }
    };

    const menuDisabledCheck=(menuName)=>{
        if(itemMenus.includes(menuName)){
            return  masterObj["Item Menu"]?.["checked"]===false ? true  : false
        }else if(storeStockMenus.includes(menuName)){
            return masterObj["Stock Report"]?.["checked"]===false ? true : false
        }else if(packagingMenus.includes(menuName)){
            return masterObj["Packaging"]?.["checked"]===false ? true  : false
        } else if(salesReportMenus.includes(menuName)){
            return masterObj["Sales Report"]?.["checked"]===false ? true : false
        }
        // else if(productionReportSubMenus.includes(menuName)){
        //     return masterObj["Production Report"]?.["checked"]===false ? true : false
        // }
        else if(purchaseOrderMenus.includes(menuName)){
            return masterObj["Purchase Report"]?.["checked"]===false ? true : false
        }
        // else if(SalesOpportunitiesMenus.includes(menuName)){
        //     return masterObj["Purchase Report"]?.["checked"]===false ? true : false
        // }
        else{
            return false;
        }
    }
    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div className="screenControl">
                    <table className="screenControlTable">
                        <thead>
                            <tr>
                                <td>
                                  <label key="includeEmp" className="plantsSubTitle" style={{fontSize: "14px"}}>
                                    <input type="checkbox"   onClick={(e)=> {setIncludeEmp(!includeEmp);}}/>
                                    <span id="includeEmp">INCLUDE Employee No.</span>
                                  </label>
                                </td> 
                                <td>
                                  <label key="cloneCheck" className="plantsSubTitle" style={{fontSize: "14px"}}>
                                    <input type="checkbox"  onClick={(e)=>setCloneCheck(!cloneCheck)}/>
                                    <span id="cloneCheck">Wish To Clone Permissions From? </span>
                                  </label>  
                                </td>
                                <td>
                                    <label key="plantAccess" className="plantsSubTitle" style={{fontSize: "14px",width:"250px"}}>
                                        <span id="plantAccess">Plant Access</span>
                                    </label>
                                </td>
                            </tr>   
                        </thead>
                        <tbody>
                            <tr>
                                <td>    
                            <div className="screenControlOptionsWrapper">           
                                {renderFormElements({elementList:Object.keys(screenControlParams),params:screenControlParams,section:"general"})}
                                   
                            </div>
                                </td>
                                <td>
                            <div className="screenControlOptionsWrapper">
                                {renderFormElements({elementList:Object.keys(cloneParams),params:cloneParams,section:"clone"})}
                            </div>
                                </td>
                                <td style={{width: "250px"}}>
                                    <FormElement 
                                        setInput={(value)=>{setPlantAccess(value);}}
                                        inputType={"multiSelect"}
                                        value={plantAccess}
                                        hintText={"Plant Names"}
                                        options={plantOptions}
                                        colSpan={3}
                                        rowSpan={2}
                                        mandatory={false}
                                        error={false}
                                        errorMessage=""
                                        disabled={false}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                               <label key="editableForApprove" className="plantsSubTitle" style={{fontSize: "14px"}}>
                                    <input type="checkbox"  onClick={(e)=>setEditable(!editable)}/>
                                    <span id="editableForApprove">Editable For Approved Items </span>
                                </label> 

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                    {/* <h3 className="screenControlModuleName">Master Configure</h3> */}
                    <table className="screenControlTable" >
                        <thead>
                            <tr>
                                <th className="screenControlTableHead">Module</th>
                                <th className="screenControlTableHead">View</th>
                                <th className="screenControlTableHead">+Create</th>
                                <th className="screenControlTableHead">+Edit</th>
                                <th className="screenControlTableHead">Approve/Decline</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="screenControlModuleName" style={{fontWeight:"600"}}>Select All</td>
                                {
                                    selectAll.map((selection)=>{
                                        return(
                                            <td className="screenControlTableData">
                                                <input
                                                type="radio"
                                                name={"selectAll"}
                                                value={selection}
                                                checked={selectAllObj.selectedState.access === selection ? true : false}
                                                onChange={(e) => {setSelectAllObj({selectedState:{checked : true,access: selection}}) }}
                                            />
                                            </td>
                                        )
                                    })
                                }
                                <td className="screenControlTableData">
                                            <div className="screenControlTableReset">
                                                <span
                                                    style={{ padding: "0.25rem" }}
                                                    src={resetIcon}
                                                    alt="Reset"
                                                    onClick={(e) => setSelectAllObj({selectedState :{ checked: false, access: null } })}
                                                >
                                                    X
                                                </span>
                                            </div>
                                        </td>
                            </tr>
                            {masterList && masterList.length> 0 && masterList.map((obj) => {
                                return (
                                    <tr>
                                        <td className="screenControlTableText">{obj}</td>
                                        <td className="screenControlTableData">
                                            <input
                                                type="radio"
                                                name={obj}
                                                value="View"
                                                checked={masterObj[obj]?.access === "View" ? true : false}
                                                onChange={(e) => {updateMasterObj(e.target.value, obj);}}
                                                disabled = {menuDisabledCheck(obj)}
                                            />
                                        </td>
                                        <td className="screenControlTableData">
                                            <input
                                                type="radio"
                                                name={obj}
                                                value="Create"
                                                checked={masterObj[obj]?.access === "Create" ? true : false}
                                                onChange={(e) => {updateMasterObj(e.target.value, obj);}}
                                                disabled = {menuDisabledCheck(obj)}
                                            />
                                        </td>
                                        <td className="screenControlTableData">
                                            <input
                                                type="radio"
                                                name={obj}
                                                value="Edit"
                                                checked={masterObj[obj]?.access === "Edit" ? true : false}
                                                onChange={(e) => {updateMasterObj(e.target.value, obj)}}
                                                disabled = {menuDisabledCheck(obj)}
                                            />
                                        </td>
                                        {appDecModules.includes(obj) ?
                                        <td className="screenControlTableData">
                                            <input
                                                type="radio"
                                                name={obj}
                                                value="App/Dec"
                                                checked={masterObj[obj]?.access === "App/Dec" ? true : false}
                                                onChange={(e) =>{updateMasterObj(e.target.value, obj)}}
                                                disabled = {menuDisabledCheck(obj)}
                                            />
                                        </td> : <td>&nbsp;</td>}
                                        <td className="screenControlTableData">
                                            <div className="screenControlTableReset">
                                                <span
                                                    style={{ padding: "0.25rem" }}
                                                    src={resetIcon}
                                                    alt="Reset"
                                                    onClick={(e) => resetSelection(e.target.value, obj)}
                                                >
                                                    X
                                                </span>
                                            </div>
                                        </td> 
                                        {/* {obj === "Item Menu" ? <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Item Menu To Enable Sub Menus in Item</td> : null} */}
                                        <NotifySubMenu MenuName={obj}/>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                    <button
                        className="submitButton"
                        onClick={() => {
                            setScreenControlError([]);
                            checkErrors();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateScreenControl);
