import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import fetchData from "serverCall/fetchData";
import FetchNewToken from "serverCall/FetchNewToken";
import CreateEditModal from "components/SmallComponents/CreateEditModal";
import SearchableDropDown from "components/SmallComponents/SearchableDropDown";

const CreateCuttingLength = ({
    // props
    setSection,

    // state
    // roleInfo,
    isLogged,

    // dispatch
    // editUom,
    addTokenToState,
  }) => {
    const initialCuttingLength = {cuttingLength:"",uomId:""};
  const [cuttingLengthList, setCuttingLengthList] = useState([]);
  const [newCuttingLength, setNewCuttingLength] = useState({...initialCuttingLength});
  const [uomOptionsList,setUomOptionsList] = useState([]);
    const history = useHistory();
  const checkToken=useCallback(async() =>{
      console.log("Checking token");
      //   console.log(isLogged.accessToken);
      const token2 = await FetchNewToken(isLogged.accessToken);
      if (token2 === "expired") {
          history.push("/");
      } else if (token2 !== isLogged.accessToken) {
          console.log("New Token");
          addTokenToState(isLogged.employeeId, token2);
      } else {
          console.log("Token not changed");
      }
  },[isLogged,addTokenToState,history]);

  const getCuttingLengthList=useCallback(async()=> {
      var data = await fetchData({
          requestingPage: "sectionMaster",
          method: "post",
          url: "fetch/cutting-length",
          headers: { token: isLogged.accessToken,module:"Cutting Length" },
      });
      if (data.msg === "success") {
          setCuttingLengthList(data.asset);
      } else {
          console.log(data);
      }
      var uomList = await fetchData({ requestingPage: "uomList", 
                                    method: "get", url: "fetch/uom", 
                                    headers: { token: isLogged.accessToken,module: "UOM" } 
                                });

        if(uomList.msg==="success"){
            setUomOptionsList(uomList.asset);
        }else{
            console.log(uomList);
        }

  },[isLogged]);

  const getInformation=useCallback(async()=> {
      await checkToken();
      await getCuttingLengthList();
    },[checkToken,getCuttingLengthList]);

    useEffect(() => {
        getInformation();
    }, [getInformation]);

    const submitCuttingLength=async()=>{
        setDataSubmitted(false);
        setIsOpen(true);
        let submitted = await fetchData({
            requestingPage: "sectionMaster",
            method: "POST",
            data: newCuttingLength,
            url: "create/cutting-length",
            headers:{token :isLogged.accessToken,module: "Cutting Length"}
        });

        if(submitted.msg==="success"){
            setModalText("Cutting Length Created Successfully");
            setNewCuttingLength(initialCuttingLength);
            getCuttingLengthList();
        }else{
            setModalText(submitted.desc);
        }
        setDataSubmitted(true);
    }
    const renderCuttingLengthList = (list) => {
      return(
        list.map((row,index)=>{
          return(
            <tr className='uomListData' key={index}>
              <td className="uomSymbolData">{row.cuttingLength}</td>
              <td className='uomSymbolData'>{row.symbol}</td>
            </tr>
          )
        })
      )
    };

    const renderCreateCuttingLength = () => {
        return (
            <tr className="uomListData">
                <td className="uomSymbolData2">
                    <input
                        className="createUom"
                        value={newCuttingLength.cuttingLength}
                        onChange={(e) => setNewCuttingLength({ ...newCuttingLength, cuttingLength: e.target.value })}
                        autoFocus
                    />
                </td>
                <td className="uomSymbolData3" style={{minWidth:"10rem"}}>
                <div style={{flex:"1 0.5 65%"}}>
                    <SearchableDropDown 
                        options={uomOptionsList}
                        selected={newCuttingLength.uomId}
                        multiple={false}
                        isClearable={true}
                        setInput={(value)=>setNewCuttingLength({...newCuttingLength,uomId:value})}
                    />
                    </div>
                    <button
                        style={{width:"7rem"}}
                        className="cancelRoleButton"
                        onClick={() => {
                            setNewCuttingLength({...initialCuttingLength});
                        }}
                    >
                        Cancel
                    </button>
                    <button
                    style={{width:"7rem"}}
                        id="SubmitCreate"
                        className="createRoleButton"
                        onClick={() => {
                            if(newCuttingLength.cuttingLength && newCuttingLength.uomId)
                            submitCuttingLength();
                        }
                      }
                    >
                        Add
                    </button>
                </td>
            </tr>
        );
    };

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Uploading data. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading data. Please wait...");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="uomListArea">
                <table className="uomListTable">
                    <thead>
                    <tr className=" uomListHeader">
                        <td className="uomSymbolHeader">
                            <span>Cutting Length</span>
                        </td>
                        <td className="uomSymbolHeader">
                            <span>UOM</span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {renderCreateCuttingLength()}
                    {renderCuttingLengthList(cuttingLengthList)}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCuttingLength);
