import React,{ Fragment,useState,useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";

const VendorLedgerList =({poInfo,isLogged,addTokenToState})=>{

    const [ledgerData,setLedgerData]=useState({});
    const [totalCrDrAmount, setTotalCrDrAmount] = useState({});
    const [isLoading,setLoading] = useState(true);
    const [noRecords, setNoRecords] = useState(false);

     const history = useHistory()
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
      }, []);

      async function getInformation() {
        await checkToken();
        await getLedgerDetails(poInfo.vendorLedgerParams)
      }

      //checking token
      async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
          history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          addTokenToState(isLogged.employeeId, token2);
        } else {
          console.log("Token not changed");
        }
      }

      //vendor ledger details
      async function getLedgerDetails(data){
        var response = await fetchData({
          requestingPage: "cusReceiptList",
          method: "post",
          url: "po-fetch/vendor-ledger",
          data,
          headers: {token: isLogged.accessToken , module: "Vendor Ledger"}
        });
        var totalAmount = {
          'totalCr': 0,
          'totalDr': 0
        };
    
        if (response.msg === "success") {
          if(response.asset?.voucherLineItems?.length) {
            setLedgerData(response.asset);
            response.asset?.voucherLineItems.map((item) => {
              if (item.voucher_type === 'Cr') {
                totalAmount.totalCr += Number(item.voucher_amount);
              } else {
                totalAmount.totalDr += Number(item.voucher_amount);
              }
            });
            setTotalCrDrAmount(totalAmount);
          }
          else{
            setNoRecords(true);
          }
          setLoading(false);
        } else {
        //   setCurrentSection("customerLedger")
          console.log(response);
        }
      }

return(
    <Fragment>
        <div className="formArea" style={{marginTop:"0",padding:"0"}}>
        <div
          style={{
            width: "1000px",
            height: "500px",
            margin: "0 2rem 4rem",
            padding: "1rem",
            border: "1px solid inherit",
            borderRadius: "5px",
            backgroundColor: "inherit",
          }}>
            {isLoading ?  <div
                        className="loader"
                        style={{ margin: "1rem auto" }}></div> : null}
          {noRecords ?  <div className="noRecordsYet">No items available</div> :
          <div className="detailsPageWrapper"  style={{overflowX:"unset",flexDirection:"row",width:"110%"}}>
            <div className="detailsPageContainer" style={{minHeight: "1400px"}}>
              
              {/*vendor & buyer details*/}
              <table className="invoiceBill" style={{width:"100%",border:"none"}}>
                  <tbody>
                    <tr key="customerDetails">
                      <td style={{width:"300px",border:"none"}}>&nbsp;</td>
                      <td  style={{textAlign:"center",border:"none",borderBottom:"0.25px solid black"}}><RenderVendor vendor={ledgerData.vendor}/></td>
                      <td style={{width:"300px",border:"none"}}>&nbsp;</td>
                    </tr>
                    <tr key="plantDetails">
                      <td colSpan={3} style={{textAlign:"center",border:"none"}}><RenderPlantDetails plant={ledgerData.plant}/></td>
                    </tr>
                    <tr key="dateRange">
                      <td colSpan={3} style={{textAlign:"center",fontSize: "11px",border:"none"}}>{ledgerData?.fromDate?.split('T')[0] + ' to ' + ledgerData?.toDate?.split('T')[0]}</td>
                    </tr>
                  </tbody>
              </table>
              {/* voucher line items*/}
              <table className="innerTable" style={{width:"100%",border:"none"}}>
                  <thead>
                    <tr className="invoiceDetailContactsTableHeader" key="headers" style={{color:"#000"}}>
                      {["Date","Particulars","Invoice/Receipt No","Credit","Debit"].map((item)=>
                      <td key={Math.random()} style={{borderLeft:"none",borderRight:"none",textAlign:"center"}}>
                        {item}
                        </td>)
                      }
                      </tr>
                  </thead>
                  <tbody>
                    {ledgerData.voucherLineItems?.map((row, i)=>(
                      <tr className="invoiceDetailContactsTableRows" key={i}>
                          <td style={{border:"none",textAlign:"center"}}>{row.voucher_date+" "+row.voucher_type}</td>
                          <td style={{border:"none",textAlign:"center"}}>{row.voucher_name}</td>
                          <td style={{border:"none",textAlign:"center"}}>{row.voucher_no}</td>
                          <td style={{border:"none",textAlign:"center"}}>{row.voucher_type ==='Cr' ? row.voucher_amount : <>&nbsp;</>}</td>
                          <td style={{border:"none",textAlign:"center"}}>{row.voucher_type === 'Dr' ? row.voucher_amount : <>&nbsp;</>}</td>
                      </tr>
                    ))}
                    
                   <tr className="invoiceDetailContactsTableRows" key="total">
                      <td colSpan={3} style={{border:"none"}}></td>
                      <td style={{borderLeft:"none",borderRight:"none",textAlign:"center", fontWeight:600}}>
                        {totalCrDrAmount.totalCr}
                      </td>
                      <td style={{borderLeft:"none",borderRight:"none",textAlign:"center", fontWeight:600}}>
                        {totalCrDrAmount.totalDr}
                      </td>
                   </tr>
                  </tbody>
              </table>
            </div>
          </div>}
        </div>
      </div>
    </Fragment>
)
}

export const RenderVendor=({vendor={}})=>{

    return(
        <div className="vendorSummary" style={{maxWidth:"max-content", margin: "0",display:"inline-flex",verticalAlign:"top"}}>
        <div className="poSummaryText">
            <span className="vendorSummaryCategory"  style={{color: "#000", textTransform: "capitalize"}}>
                {` ${vendor.CompanyName?.toUpperCase()}`}
            </span>
            <span className="vendorSummaryValue">
                {`ID: ${vendor.VendorId}`}
            </span>
            <span className="vendorSummaryValue">
            {vendor.Address?.split(',').join(`,\n\n`)}, <br />
                {vendor.ZipCode !== null
                    ? `${vendor.City}  - ${vendor.ZipCode}`
                    : vendor.City},
                    {vendor.State}
            </span>
            <span className="vendorSummaryValue">
                {vendor.VendorMobile !== null || vendor.VendoPhone !== null ? `\nPhone: ` : null}
                {vendor.VendorMobile !== null ? vendor.VendorMobile : null}
                {vendor.VendorPhone !== null ? `, ${vendor.VendorPhone} ` : null}
            </span>
            <span className="vendorSummaryValue">
                {vendor.VendorEmail !== null ? `Email Id: ${vendor.VendorEmail}` : null}
            </span>
  
            <span className="vendorSummaryValue">
                {vendor.GSTNo !== null ? `GSTIN/UIN: ${vendor.GSTNo}` : null}
            </span>
            </div> 
        </div>
    )
  }

  export const RenderPlantDetails=({plant={}})=>{

    return(
        <div className="vendorSummary" style={{maxWidth:"max-content", margin: "0",display:"inline-flex",verticalAlign:"top"}}>  
        <div className="poSummaryText" style={{maxWidth:"max-content"}}>
            <span className="vendorSummaryCategory" style={{color: "#000", textTransform: "capitalize"}}>Buyer</span>
            <span className="vendorSummaryValue" style={{fontSize: "12px"}}>{`Company: ${plant.parentPlantName || plant.plantName}`}</span>
            <span className="vendorSummaryValue" style={{fontSize: "12px"}}>{`Plant: ${plant.plantName}`}</span>
            <span className="vendorSummaryValue" style={{fontSize: "12px"}}>{`${plant.city}-${plant.zipCode}, ${plant.state}`}</span>
            <span className="vendorSummaryValue" style={{fontSize: "12px"}}>{`GSTIN/UIN: ${plant.gstNo}`}</span>
        </div>
    </div>
    )
  };

const mapStateToProps = (state) => {
    return { 
        poInfo: state.poInfo,
        isLogged: state.isLogged,
     };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) =>
        dispatch(addToken(accessToken, employeeId)),
    };
  };


export default connect(mapStateToProps,mapDispatchToProps)(VendorLedgerList);