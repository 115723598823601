import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import viewIcon from "../../assets/view.svg";
import SortUpGreen from "../../assets/SortUpGreen.svg";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import {
  addProductionReportId,
  setSoTab,
} from "../../redux/PurchaseOrder/PoInfoActions";
import Pagination from "../CommonFunctions/pagination";
import { handleSort } from "../CommonFunctions/utils";


const ProductionReportList = ({
  // props
  setSection,
  // setApproval,
  setTab,
  // setEditAndApprove,

  // state
  isLogged,
  poInfo,

  // dispatch
  addTokenToState,
  viewProductionReportId,
  editProductionReportId,
  setSalesOrderTab,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [soList, setSoList] = useState([]);
  const [soApprovedList, setSoApprovedList] = useState([]);
  const [soDeclinedList, setSoDeclinedList] = useState([]);
  const [soReturnUnapprovedList, setSoReturnUnapprovedList] = useState([]);
  const [soReturnApprovedList, setSoReturnApprovedList] = useState([]);
  const [soClosedList, setSoClosedList] = useState([]);

  const [noUnapprovedRecords, setNoUnapprovedRecords] = useState(false);
  const [loading, setLoading] = useState(true);
  const [plantId, setPlantId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [PageSize, setRowsPerPage] = useState(10);

  const soUNApprovedCurrentPage = useMemo(() => {
    if (poInfo.soTab === "soList") {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      return soList.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, soList, PageSize, poInfo.soTab]);

  useEffect(() => {
    getInformation();

    // eslint-disable-next-line
  }, []);

  async function getInformation() {
    await checkToken();
    await getCustomers();
    await getPrDetails();
  }

  const history = useHistory();
  async function checkToken() {
    console.log("Checking token");
    //   console.log(isLogged.accessToken);
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getPrDetails() {
    var data = await fetchData({
      requestingPage: "soList",
      method: "post",
      url: "po-fetch/production-report-list",
      headers: { token: isLogged.accessToken, module: "Production Report" },
      data: { plantId, customerId }
    });
    if (data.msg === "success") {
      var data2 = [];

      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });
      setSoList(data2);
      if (data.asset.length > 0) setNoUnapprovedRecords(false);
      else setNoUnapprovedRecords(true);
    } else {
      console.log(data);
    }
    setLoading(false);
  }

  async function getCustomers() {
    var data = await fetchData({
      requestingPage: "getParents",
      method: "get",
      url: "fetch/customer-list",
      headers: { token: isLogged.accessToken, module: "Customer" },
    });
    if (data.msg === "success") {
      var options = [];
      data.asset.forEach((parent) => {
        var newParent = { optionId: parent.customerId, optionName: parent.customer };
        options.push(newParent);
      });
      setCustomerOptions(options);
    }
  }
  const [order, setOrder] = useState({ col: "soNo", state: true, data: "" });

  const renderSubSection = () => {
    return (
      <React.Fragment>
        <table className="vendorListTable">
          <thead>
            <tr className="vendorListHeader" style={{ zIndex: 1 }}>
              <td
                className="vendorListHeading"
                onClick={(e) => {
                  setOrder({
                    col: "plantName",
                    state: !order.state,
                  });
                  handleSort({
                    data: soUNApprovedCurrentPage,
                    col: "plantName",
                    state: order.state,
                  });
                }}>
                Plant
                <img alt="sort"
                  className="sortArrow"
                  src={
                    order.col === "plantName"
                      ? order.state
                        ? SortUpGreen
                        : SortDownGreen
                      : null
                  }
                />
              </td>
              <td
                className="vendorListHeading"
                onClick={(e) => {
                  setOrder({ col: "soDate", state: !order.state });
                  handleSort({
                    data: soUNApprovedCurrentPage,
                    col: "soDate",
                    state: order.state,
                  });
                }}>
                Date
                <img alt="sort"
                  className="sortArrow"
                  src={
                    order.col === "soDate"
                      ? order.state
                        ? SortUpGreen
                        : SortDownGreen
                      : null
                  }
                />
              </td>
              <td
                className="vendorListHeading"
                onClick={(e) => {
                  setOrder({
                    col: "companyName",
                    state: !order.state,
                  });
                  handleSort({
                    data: soUNApprovedCurrentPage,
                    col: "companyName",
                    state: order.state,
                  });
                }}>
                Shift No
                <img alt="sort"
                  className="sortArrow"
                  src={
                    order.col === "companyName"
                      ? order.state
                        ? SortUpGreen
                        : SortDownGreen
                      : null
                  }
                />
              </td>
              <td
                className="vendorListHeading"
                onClick={(e) => {
                  setOrder({
                    col: "expectedDeliveryDate",
                    state: !order.state,
                  });
                  handleSort({
                    data: soUNApprovedCurrentPage,
                    col: "expectedDeliveryDate",
                    state: order.state,
                  });
                }}>
                Machine No
                <img alt="sort"
                  className="sortArrow"
                  src={
                    order.col === "expectedDeliveryDate"
                      ? order.state
                        ? SortUpGreen
                        : SortDownGreen
                      : null
                  }
                />
              </td>
              <td className="vendorListHeadingActions">Actions</td>
            </tr>
          </thead>
          <tbody>
            {soList.length > 0 ? renderSoUnapprovedList() : null}
          </tbody>
        </table>
        {noUnapprovedRecords ? (
          <div className="noRecordsYet">No records found</div>
        ) : loading ? (
          <div className="loader" style={{ margin: "1rem auto" }}></div>
        ) : null}
      </React.Fragment>
    );
  };
  const access = isLogged.access["Production Report"];
  const editItemCollapse = (list, index) => {
    var listCopy = [];
    let clickedIndex = ((currentPage - 1) * PageSize) + index;
    listCopy = soList.slice();

    listCopy.forEach((item, j) => {
      if (j === clickedIndex) {
        listCopy[j].collapse = !item.collapse;
      } else {
        listCopy[j].collapse = true;
      }
    });

    setSoList(listCopy);
  };


  const renderSoUnapprovedList = () => {
    return soUNApprovedCurrentPage?.map((item, i) => {
      return (
        <React.Fragment key={i}>
          <tr
            className={item.collapse ? "vendorList" : "vendorListExpanded"}
            key={item.soId}
            onClick={() => editItemCollapse("soList", i)}>
            <td className="vendorListInfo stickyFirstColumn">{item.plantName}</td>
            <td className="vendorListInfo">{item.date}</td>
            <td className="vendorListInfo">{item.shiftNo}</td>
            <td className="vendorListInfo">{item.machineNo}</td>

            <td className="vendorListInfoActions">

              <img
                alt="View Record"
                className="vendorActionImages"
                src={viewIcon}
                title="View PR Detail"
                onClick={(e) => {
                  e.stopPropagation();
                  viewProductionReportId(item.prId);
                  setSection("productionReportDetail");
                  // setApproval(false);
                }}
              />
              <img
                alt="Edit Record"
                className="vendorActionImages"
                src={
                  access === "Edit" || access === "App/Dec"
                    ? editIcon
                    : editIconDisabled
                }
                title={
                  access === "Edit" || access === "App/Dec"
                    ? "Edit Item"
                    : "Access Denied"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (access === "Edit" || access === "App/Dec") {
                    console.log(item.prId, "item.prId")
                    editProductionReportId(item.prId);
                    setSection("editProductionReport");
                    // setEditAndApprove(false);
                  }
                }}
                style={
                  access === "Edit" || access === "App/Dec"
                    ? { cursor: "pointer" }
                    : { cursor: "not-allowed" }
                }
              />
              <img
                alt="Toggle Expand"
                className="vendorActionImages"
                title="Toggle Expand"
                src={item.collapse ? downIcon : upIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  editItemCollapse("soList", i);
                }}
              />
            </td>
          </tr>

          <tr>
            {!item.collapse ? (
              <RenderSubTable tab="unapprovedSo" data={item.lineItems} />
            ) : null}
          </tr>
        </React.Fragment>
      );
    });
  };


  const RenderSubTable = ({ tab, data, reason }) => {
    return (
      <td
        colSpan={
          tab === "approvedSo" || tab === "closedSo"
            ? "11"
            : tab === "soReturnList" || tab === "soReturnApprovedList"
              ? "10"
              : "9"
        }
        className="vendorListInnerTableWrapper">
        {tab === "declinedSo" ? (
          <div
            style={{
              boxSizing: "border-box",
              width: "100%",
              padding: "10px",
              backgroundColor: "#eee",
            }}>
            <span style={{ color: "red", fontWeight: "bold" }}>
              Reason:&nbsp;&nbsp;&nbsp;
            </span>
            {reason}
          </div>
        ) : null}

        {tab === "soReturnApprovedList" ? (
          <div
            style={{
              boxSizing: "border-box",
              width: "100%",
              padding: "10px",
              backgroundColor: "#eee",
            }}>
            <span style={{ color: "red", fontWeight: "bold" }}>
              Notes:&nbsp;&nbsp;&nbsp;
            </span>
            {reason !== null ? reason : "-"}
          </div>
        ) : null}

        <table className="vendorListInnerTable">
          <thead>
            <tr className="vendorListInnerTableHeader">
              <td className="vendorListInnerTableHeading stickyFirstColumn">section No</td>
              <td className="vendorListInnerTableHeading">Section Name</td>
              <td className="vendorListInnerTableHeading">Die No</td>
              <td className="vendorListInnerTableHeading">Die Name</td>
              {/* <td className="vendorListInnerTableHeading">HSN Code</td> */}
              <td className="vendorListInnerTableHeading">Cutting Length</td>
              <td className="vendorListInnerTableHeading">Casting No</td>
              <td className="vendorListInnerTableHeading">Trolley No</td>
              <td className="vendorListInnerTableHeading">Weight Range</td>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => {
              return (
                <tr className="vendorListInnerTable" key={row.PrLineItemId}>
                  <td className="vendorListInnertableInfo stickyFirstColumn">{row.sectionNo}</td>
                  <td className="vendorListInnertableInfo">{row.sectionName}</td>
                  <td className="vendorListInnertableInfo">{row.dieNo}</td>
                  <td className="vendorListInnertableInfo">{row.dieName}</td>
                  <td className="vendorListInnertableInfo">{row.cuttingLength}</td>
                  <td className="vendorListInnertableInfo">{row.castingNo}</td>
                  <td className="vendorListInnertableInfo">{row.trolleyNo}</td>
                  <td className="vendorListInnertableInfo">{row.weightRange}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    );
  };

  const renderPagination = () => {
    return (
      soList.length > 0 ? <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={soList.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      /> : null
    );

  }

  return (
    <React.Fragment>
      <div className="vendorListArea">{renderSubSection(poInfo.soTab)}</div>
      {renderPagination()}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
    viewProductionReportId: (viewId) => dispatch(addProductionReportId(viewId)),
    editProductionReportId: (viewId) => dispatch(addProductionReportId(viewId)),

    setSalesOrderTab: (tab) => dispatch(setSoTab(tab)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductionReportList);
