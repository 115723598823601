import React,{useEffect, useState} from "react";
import fetchData from "serverCall/fetchData";
import { connect } from "react-redux";

const ViewDoc =({inwardDetail,isLogged})=>{

    const [diagramImage, setDiagramImage] = useState("");

    useEffect(()=>{
        if (inwardDetail.inwardDocument !==null) {
        getInwardDocument()
        }
        // eslint-disable-next-line
    },[inwardDetail])

async function getInwardDocument() {

        if (inwardDetail?.inwardDocument) {
            var data = await fetchData({
                requestingPage: "itemImage",
                method: "get",
                url: "image/inward-document/" + inwardDetail.inwardDocument,
                headers: { token: isLogged.accessToken },
            });

            if (data !== "") {
                setDiagramImage(data);
            }
        }
    }
return (
    <>
    {
        diagramImage?
        <div className="detailsPageContainer" style={{width:"auto"}}>

            <img className="modalImage" src={`data:image/jpeg;base64,${diagramImage}`} alt="Item Diagram" />
        </div>
        :<center> <p className="noRecordsYet">No Bill Found 
        </p></center>

    }
    </>
)
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};


export default connect(mapStateToProps)(ViewDoc);