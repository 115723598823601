import React, {Fragment,useEffect,useState} from 'react'
import { addToken } from '../../../redux/UserAccount/IsLoggedActions';
import {connect} from 'react-redux';
import fetchData from '../../../serverCall/fetchData';
import imageNotAvailable from "../../../assets/imageNotAvailable.svg";
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';


const StockReportList = ({stockReportParams,isLogged, itemId}) => {

  const [isLoading,setIsLoading] = useState(true);
  const [noRecords,setNoRecords]=useState(false);
  const [stockReportList,setStockReportList] = useState([]);
  const [itemData, setItemData] = useState(null);
  const [itemImage, setItemImage] = useState();

  async function getItemDetail() {
    var data = await fetchData({
        requestingPage: "itemDetail",
        method: "get",
        url: "fetch/item/" + stockReportParams.itemId,
        headers: { token: isLogged.accessToken,module:"Items" },
    });
    if (data.msg === "success") {

        setItemData(data.asset.item[0]);
        // setStorageOpeningStock(data.asset.openingStock);
    }
  }

  async function getItemImage() {
      var data = await fetchData({
        requestingPage: "stockReport",
        method: "get",
        url: "image/item-image/" + stockReportParams.itemId,
        headers: { token: isLogged.accessToken, module: "Items" },
      });
      setItemImage(data);
  }
  async function getStockList() {
    await getItemImage();
    await getItemDetail();

    var response = await fetchData({
      requestingPage: "stockReport",
      method: "post",
      url: "store-fetch/stock-report",
      headers: { token: isLogged.accessToken, module: "Item Report" },
      data: {itemId: stockReportParams.itemId,
             plantName: stockReportParams.plantName,
             storageName: stockReportParams.storageName,
             fromDate: stockReportParams.fromDate,
             toDate: stockReportParams.toDate ,
             plantId: stockReportParams.plantId,
             storageId: stockReportParams.storageId
            }
    });
    setIsLoading(false);
    if (response.msg === 'success') {
      if (response.asset.length) {
        setStockReportList(response?.asset?.filter((itm => itm.itemId === stockReportParams.itemId)));
      } else {
        setNoRecords(true);
      }
    } else {
      setNoRecords(true);
    }
  }

  useEffect(()=>{
    getStockList();
  },[]);

  const RenderSubTable=({data})=>{

    return(
      <td colSpan={4}>
        <table className='vendorListInnerTable'>
            <thead className='vendorListInnerTableHeader'>
              <td className="vendorListInnerTableHeading">Item No</td>
              <td className="vendorListInnerTableHeading">Invoice No</td>
              <td className="vendorListInnerTableHeading">Port Arrival Date</td>
            </thead>
            <tbody>
              <tr className="vendorListInnerTable">
                <td className="vendorListInnerTableInfo">{data.importNo}</td>
                <td className="vendorListInnerTableInfo">{data.invoiceNo}</td>
                <td className="vendorListInnerTableInfo">{data.portArrivalDate}</td>
              </tr>
            </tbody>
        </table>
      </td>
    )
  }
  const editItemCollapse = (index) => {
    var listCopy = [];
        listCopy = stockReportList.slice();
    listCopy.forEach((item, j) => {
      if (j === index) {
        listCopy[j].collapse = !item.collapse;
      } else {
        listCopy[j].collapse = true;
      }
    });
    setStockReportList(listCopy)
  };
  const renderStockReport=()=>{

    return(
      <Fragment>
        {stockReportList.map((Imp,index)=>(
          <Fragment key={index}>
        <tr className='vendorList' key={index} onClick={()=>editItemCollapse(index)}>
          <td className="vendorListInfo">{Imp.plantName}</td>
          <td className="vendorListInfo">{Imp.storageName}</td>
          <td className="vendorListInfo">{Imp.quantity}</td>
          <td className="vendorListInfo">{parseFloat(Imp.rate).toFixed(2)}</td>
          <td className="vendorListInfo">{parseFloat(Imp.total).toFixed(2)}</td>
          {/* <td className="vendorListInfo">{Imp.plannedqty}</td>
          <td className="vendorListInfo">{Imp.poQuantity}</td>
          <td className="vendorListInfo">{Imp.poQuantity}</td>
          <td className="vendorListInfo">{Imp.soQuantity}</td>
          <td className="vendorListInfo">{Imp.projectQty}</td> */}
        </tr>
          {/* {
            !Imp.collapse ? (
              <RenderSubTable data={Imp.invoices}/>
            ) : null
          } */}
        </Fragment>
        ))
        }
        <tr className='vendorList' key='total'>
          <td className="vendorListInfo"></td>
          <td className="vendorListInfo"></td>
          <td className="vendorListInfo"></td>
          <td className="vendorListHeading">Total</td>
          <td className="vendorListInfo">
            <b>
              {(stockReportList.reduce((accumulator, object) => {
                return parseFloat(Number(accumulator) + Number(object.total));
              }, 0)).toLocaleString('en-IN', {
                style: 'currency',
                currency: 'INR'
             })}
            </b>
          </td>
        </tr> 
      </Fragment>
    )
  }
  
  const renderSummaryInfo = () => {
    if (itemData !== null) {
        return (
            <React.Fragment>
                <div className="vendorSummaryGrid">
                    <span className="vendorSummaryCategory">Item Main Category</span>
                    <span className="vendorSummaryValue">: {itemData.category}</span>

                    <span className="vendorSummaryCategory">Item Sub Category</span>
                    <span className="vendorSummaryValue">: {itemData.subCategory}</span>

                    <span className="vendorSummaryCategory">Brand Name</span>
                    <span className="vendorSummaryValue">: {itemData.brand}</span>

                    <span className="vendorSummaryCategory">Item Code</span>
                    <span className="vendorSummaryValue">: {itemData.itemCode}</span>

                    <span className="vendorSummaryCategory">Item Name</span>
                    <span className="vendorSummaryValue">: {itemData.item}</span>

                    <span className="vendorSummaryCategory">MRP</span>
                    <span className="vendorSummaryValue">: {itemData.mrp}</span>

                    <span className="vendorSummaryCategory">Default UOM</span>
                    <span className="vendorSummaryValue">: {itemData.defaultUom}</span>

                    <span className="vendorSummaryCategory">Dimension (L*B*H)</span>
                    <span className="vendorSummaryValue">
                        :{" "}
                        {itemData.itemLength !== undefined &&
                        itemData.itemLength !== null &&
                        itemData.itemLength > 0 &&
                        itemData.itemWidth !== undefined &&
                        itemData.itemWidth !== null &&
                        itemData.itemWidth > 0 &&
                        itemData.itemHeight !== undefined &&
                        itemData.itemHeight !== null &&
                        itemData.itemHeight > 0
                            ? `${itemData.itemLength} mm * ${itemData.itemWidth} mm * ${itemData.itemHeight} mm`
                            : " NA"}
                    </span>

                    <span className="vendorSummaryCategory">Weight (Net Weight & Gross Weight)</span>
                    <span className="vendorSummaryValue">
                        :{" "}
                        {itemData.netWeight !== undefined &&
                        itemData.netWeight !== null &&
                        itemData.netWeight > 0 &&
                        itemData.grossWeight !== undefined &&
                        itemData.grossWeight !== null &&
                        itemData.grossWeight > 0
                            ? `${itemData.netWeight} gms & ${itemData.grossWeight} gms`
                            : " NA"}
                    </span>
                    <span className="vendorSummaryCategory">Weight Range(From - To)</span>
                    <span className="vendorSummaryValue"> :{" "}{itemData.from && itemData.to ? `${itemData.from} gms - ${itemData.to} gms` : ' NA'} </span>

                    <span className="vendorSummaryCategory">Description</span>
                    <span className="vendorSummaryValue" style={{ whiteSpace: "break-spaces" }}>
                        :{" "}
                        {itemData.description !== undefined && itemData.description !== null && itemData.description.length > 0
                            ? itemData.description
                            : " NA"}
                    </span>
                </div>
            </React.Fragment>
        );
    }
  }
  const renderAddInfo = () => {
    if (itemData != null) {
        return (
            <React.Fragment>
                <div className="vendorSummaryGrid">
                    <div className="vendorSummaryCategory">Model Name </div>
                    <div className="vendorSummaryValue">
                        :{" "}
                        {itemData.modelName !== undefined && itemData.modelName !== null && itemData.modelName.length > 0
                            ? itemData.modelName
                            : " NA"}
                    </div>

                    <div className="vendorSummaryCategory">HSN Code </div>
                    <div className="vendorSummaryValue">
                        {" "}
                        : {itemData.hsnCode !== undefined && itemData.hsnCode !== null && itemData.hsnCode.length > 0 ? itemData.hsnCode : " NA"}
                    </div>

                    <div className="vendorSummaryCategory">Tax</div>
                    <div className="vendorSummaryValue">
                        :{" "}
                        {itemData.taxPercentage !== undefined && itemData.taxPercentage !== null && itemData.taxPercentage.length > 0
                            ? `${itemData.taxPercentage}%`
                            : " NA"}{" "}
                    </div>

                    <div className="vendorSummaryCategory">Tax Date</div>
                    <div className="vendorSummaryValue">
                        :{" "}
                        {itemData.taxApplicableDate !== undefined && itemData.taxApplicableDate !== null && itemData.taxApplicableDate.length > 0
                            ? new Date(itemData.taxApplicableDate).toDateString()
                            : " NA"}{" "}
                    </div>

                    <div className="vendorSummaryCategory">Min Stock Level</div>
                    <div className="vendorSummaryValue">
                        :{" "}
                        {itemData.minStockLevel !== undefined && itemData.minStockLevel !== null && itemData.minStockLevel > 0
                            ? itemData.minStockLevel
                            : " NA"}{" "}
                    </div>

                    <div className="vendorSummaryCategory">Max Stock Level</div>
                    <div className="vendorSummaryValue">
                        :{" "}
                        {itemData.maxStockLevel !== undefined && itemData.maxStockLevel !== null && itemData.maxStockLevel > 0
                            ? itemData.maxStockLevel
                            : " NA"}{" "}
                    </div>
                    <div className="vendorSummaryCategory">Item Active Status</div>
                    <div>
                        : {" "} {itemData.activeStatus ? itemData.activeStatus : ""}
                    </div>
                </div>
            </React.Fragment>
        );
    }
  };

  const renderChart=(type)=>{
    var chartData = [];
    var data;
    switch(type) {
      case 'pie':
        data = stockReportList.map((Imp) => {
          return {'id': Imp.storageName, 'label': Imp.plantName,
           'value': Imp.quantity }
          }
        );
        return renderPieChart(data);
      case 'bar':
        data = stockReportList.map((Imp) => {
          return {'Storage': Imp.storageName, 'Plant': Imp.plantName,
           'Quantity': Imp.quantity, 'Blocked Qty': Imp.soQuantity,
           'Scrap Qty': Imp.scrapQuantity, 'Total': Imp.total }
          }
        );
        return renderBarChart(data);
      case 'line':
        data = {
          id: 'Item Stock',
          data: stockReportList.map((Imp)=>( { x:Imp.storageName, y:Imp.quantity }))
        };
        chartData.push(data);
        // break;
        return renderLineChart(chartData);
      default:
        return null;
    }
  }

  const renderLineChart=(data)=>{

    return(
      <Fragment>
      <div
        className="dashboardCard"
        style={{
          height: "390px",
          gridColumn: `1/span 3`,
          alignSelf: 'start',
          gridRow: `3 / span 2`,
          padding: "2rem",
        }}>
        <div className="dashboardGraphBody">
        <ResponsiveLine
                data={data}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                curve="linear"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Stores',
                  legendOffset: 36,
                  legendPosition: 'middle',
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Quantity',
                  legendOffset: -40,
                  legendPosition: 'middle',
                }}
                isInteractive={true}
                colors={{ scheme: 'category10' }}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                  {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
        </div>
      </div>
      </Fragment>
    )
  }

  
  const renderBarChart=(data)=>{

    return(
      <Fragment>
      <div
        className="dashboardCard"
        style={{
          height: "390px",
          gridColumn: `1/span 3`,
          alignSelf: 'start',
          gridRow: `3 / span 2`,
          padding: "2rem",
        }}>
        <div className="dashboardGraphBody">
        <ResponsiveBar
          data={data}
          keys={['Quantity', 'Blocked Qty', 'Scrap Quantity']}
          indexBy="Storage"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={{ scheme: 'nivo' }}
          groupMode='grouped'
          borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
          }}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Storage',
            legendPosition: 'middle',
            legendOffset: 32
          }}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Quantity',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          animate={true}          
          legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
          ]}
          motionStiffness={90}
          motionDamping={15}
          motionConfig="wobbly"
        />
        </div>
      </div>
      </Fragment>
    )
  }

const renderPieChart=(data)=>{

    return(
      <Fragment>
      <div
        className="dashboardCard"
        style={{
          height: "390px",
          gridColumn: `1/span 3`,
          alignSelf: 'start',
          gridRow: `3 / span 2`,
          padding: "2rem",
        }}>
        <div className="dashboardGraphBody">
        <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
          />
        </div>
      </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className="formArea" style={{marginTop:"0",padding:"0"}}>
        <div
          style={{
            width: "1000px",
            height: "500px",
            margin: "0 2rem 4rem",
            padding: "1rem",
            border: "1px solid inherit",
            borderRadius: "5px",
            backgroundColor: "inherit",
          }}>
             {isLoading ?  <div
                        className="loader"
                        style={{ margin: "1rem auto" }}></div> : null}
          {noRecords ?  <div className="noRecordsYet">No items available</div> :
          <div className="detailsPageWrapper"  style={{overflowX:"unset",flexDirection:"row",width:"110%"}}>
            <div className="detailsPageContainer" style={{minHeight: "1400px"}}>
              
            <div className="vendorSummary">
              <div className="vendorSummaryText">{renderSummaryInfo()}</div>
              <div style={{ flex: "1" }} />
              <div className="vendorSummaryText">{renderAddInfo()}</div>
              <div>
                  <img
                      alt="Item"
                      className="vendorImage"
                      src={itemImage !== null && itemImage !== undefined && itemImage !== "" ? `data:image/png;base64,${itemImage}` : imageNotAvailable}
                  />
              </div>
            </div>
            &nbsp;
            <table className="vendorListTable">
              <thead>
              <tr className="vendorListHeader"  style={{zIndex:1}}>
                <td className='vendorListHeading'>Plant Name</td>
                <td className='vendorListHeading'>Storage Name</td>
                {/* <td className='vendorListHeading'>Actual Qty</td>
                <td className='vendorListHeading'>Planned Qty</td>
                <td className='vendorListHeading'>Requested Qty</td>
                <td className='vendorListHeading'>Ordered Qty</td>
                <td className='vendorListHeading'>Reserved Qty</td>
                <td className='vendorListHeading'>Project Qty</td> */}
                <td className='vendorListHeading'>Available Qty</td>
                <td className='vendorListHeading' title='Average of last 10 transactions'>Avg Unit Price</td>
                <td className='vendorListHeading'>value</td>
              </tr>
              </thead>
              <tbody>
                {stockReportList.length > 0 ? renderStockReport() : null}
              </tbody>
            </table>
            <div className='dashboardGrid'>
                {stockReportList.length > 0 && stockReportParams.enableChart ? renderChart(stockReportParams.chartType) : null}
                </div>
            </div>
          </div>}
          </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
    stockReportParams: state.poInfo.stockReportParams
  };
};

const mapDispatchToProps = (dispatch) => {

  return {
    addTokenToState: (accessToken, employeeId) =>dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(StockReportList);