import { Fragment,useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import productionReportIcon from "../../assets/production-report.svg"
import CreateAgingReport from "./CreateAgingReport"
import AgingReportList from "./AgingReportList"
import EditAgingReport from "./EditAgingReport"
import AgingReportDetail from "./AgingReportDetail"
import { exportPDF } from "../CommonFunctions/ExportPDF";
import { connect } from "react-redux";

const AgingReport = ({isLogged}) => {
    const [currentSection,setCurrentSection] = useState("agingReportList");

    var goBackButton = (props) =>
    ({
      buttonName: "Back",
      className: "goBackButton",
      ...props
    });

    var createButton = 
    { buttonName: "Create Aging Report", className: "employeeButton", setSection: setCurrentSection, sectionName: "createAgingReport" };

    var downloadPRButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "agingReportDetail") }, sectionName: "agingReportList" };
    const renderSection=(currentSection)=>{
        switch(currentSection){
            case "createAgingReport":
                return(
                    <Fragment>
                    <PageTitle imgSrc={productionReportIcon} pageTitle={"Aging Reports"}
                        buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"agingReportList"})]}/>
                     <CreateAgingReport setSection={setCurrentSection}/>
              </Fragment>
                );

            case "agingReportList":
                return(
                    <Fragment>
                        <PageTitle imgSrc={productionReportIcon} pageTitle={"Aging Report List"}
                            buttonList={[createButton]}
                            />
                            <AgingReportList setSection={setCurrentSection}/>
                    </Fragment>
                )

            case "editAgingReport":
                return(
                    <Fragment>
                        <PageTitle imgSrc={productionReportIcon} pageTitle={"Aging Report List"}
                            buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"agingReportList"})]}
                            />
                            <EditAgingReport setSection={setCurrentSection}/>
                    </Fragment>
                )
            case "agingReportDetail":
                return(
                    <Fragment>
                        <PageTitle imgSrc={productionReportIcon} pageTitle={"Aging Report Detail"}
                            buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"agingReportList"}),downloadPRButton]}
                            />
                            <AgingReportDetail setSection={setCurrentSection}/>
                    </Fragment>
                )

            default:
                return <>Page Not Found</>;
        }
    }

  return (
    <Fragment>
        <div className="completePage">
            <MenuList selectedMenu={"Reports"} selectedSubMenu={"Aging Report"}/>
            <div className="detailsContainer">
                <TopBanner />
                {renderSection(currentSection)}
            </div>
        </div>
    </Fragment>
  )
}
const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default  connect(mapStateToProps, null)(AgingReport)