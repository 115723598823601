import React,{useState,useEffect} from "react";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";

const ProductionPlanningDetail=({poInfo,isLogged})=>{
    const [reportDetail,setReportDetail] = useState([]);
    const [lineItems,setLinItems]= useState([]);

    const RenderTable = ({currentPageLineItems,pageNumber}) => {
        return (
                <>
                <table className="innerTable" style={{width:"100%",height:"90%"}}>
                    <thead>
                        <tr>
                            <td colSpan={17}>
                            Production planning No : 
                            <span  className="vendorSummaryRole"
                             style={{ textTransform: "capitalize"}}>
                                {reportDetail.ppNO}
                            </span>
                            </td>
                        </tr>
                    <tr className="invoiceDetailContactsTableHeader" style={{color: "#000"}}>
                    <td className="stickyFirstColumn">Sl No</td>
                    <td>Po No</td>
                    <td>Section No</td>
                  <td>Section Name</td>
                  <td>Cutting Length</td>
                  <td>Weight From</td>
                  <td>Weight To</td>
                  <td>Quantity</td> 
                </tr>
                    </thead>
                    <tbody>
                        <RenderTableRows  currentPageLineItems={currentPageLineItems} pageNumber={pageNumber}  tab="contact" />
                    </tbody>
                </table>
                </>
            );
       };

    async function getPrDetails() {
        var data = await fetchData({
          requestingPage: "soList",
          method: "post",
          url: "po-fetch/production-planning-detail/" + poInfo.productionPlanningId ,
          headers: { token: isLogged.accessToken, module: "Production Planning" },
        });
        if (data.msg === "success") {
          return data.asset[0]
        } else {
          console.log(data);
        }
      }

    const getInfo=async()=>
    {
        let detail = await getPrDetails();
        setReportDetail(detail)
        let lineChunk =[];
        for(let i=0;i< detail?.lineItems.length; i+=16) // 16 items in each page
                lineChunk.push(detail?.lineItems.slice(i,i+16));
                setLinItems(lineChunk);       
    };

    useEffect(()=>{
        getInfo();
    },[])

       
    const RenderTableRows = ({ currentPageLineItems,pageNumber }) => {
        if(currentPageLineItems?.length>0){
        return (<>
            {currentPageLineItems.map((row, j) => {
                return (
                    <tr className="invoiceDetailContactsTableRows" key={j}>
                        <td  style={{borderBottom: "none", borderTop: "none"}}>{((pageNumber-1)*16)+j+1}</td>
                        {[
                            "soNo",
                            "itemCode",
                            "itemName",
                            "cuttingLength",
                            "from",
                            "to",
                            "quantity",
                        ].map((key, i) => {           
                        return <td  style={{borderBottom: "none", borderTop: "none"}} key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                        })}
                    </tr>
                );
            })}
        </>);
    } else return(<></>)
       };

return(
    <>
     <div className="detailsPageWrapper">
        { //Invoice Pages
        lineItems?.length > 0 ?
        lineItems?.map((currentPage,i)=>{                   
                    return(
                    <div className="detailsPageContainer" key={i}>
                        <div className="invoicePageTitle">PRODUCTION PLANNING REPORT</div>
                        <div style={{border:"0.25px solid black",minHeight:"1100px",position:"relative"}}>
                            <RenderTable currentPageLineItems={currentPage} pageNumber={i+1}/>
                        </div>
                            <div style={{width: "100%", textAlign: "center"}}>
                            </div>
                            <div style={{width: "100%", textAlign: "center"}}>
                                <span style={{fontSize: "9px"}}>This is a Computer Generated Invoice</span>
                            </div>
                    </div>
                    )
                    }) : null
        }
     </div>  
    </>
)

}
const mapStateToProps = (state) => {
    return {
      isLogged: state.isLogged,
      poInfo: state.poInfo,
    };
  };
export default connect(mapStateToProps)(ProductionPlanningDetail);