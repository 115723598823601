import React,{useState,useEffect, Fragment,useCallback} from 'react'
import { connect } from 'react-redux'
import FormElement, { renderFormElements } from 'components/SmallComponents/FormElement';
import update from "immutability-helper";
import fetchData from 'serverCall/fetchData';
import { validateMandatory } from 'components/CommonFunctions/ValidateFields';
import deleteIcon from "assets/delete.svg";
import CreateEditModal from "../../SmallComponents/CreateEditModal";
import * as XLSX from "xlsx";

const CreatePackingSlip = ({isLogged,storeInfo, setSection,screenName}) => {
  const [selectedBundleList, setSelectedBundleList] = useState([])
  const [totalweight, setTotalWeight] = useState(0)
  const [totalquantity, setTotalQuantity] = useState(0)
  const [totalBc, setTotalBc] = useState(0)
  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState();
  const [errors, setErrors] = useState([]);

  const [bulkFile, setBulkFile] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [toServerData, setToServerData] = useState([]);
  
  const [packingSlipParams,setPackingSlipParams] = useState({
    plantId:{
      inputType: "options",
      value: "",
      hintText: "Plant Name",
      mandatory: true,
      error: false,
      errorMessage :"Please Select a Plant Id",
      colSpan: 4,
      options: [],
    },
    storageId:{
      inputType: "options",
      value: "",
      hintText: "Storage Name",
      error: false,
      errorMessage: "Please Select a Storage Name",
      mandatory: true,
      colSpan: 4,
      options: [],
    },
    customerId: {
      inputType: "options",
      value: "",
      hintText: "Customer Name",
      error : false,
      errorMessage: 'Please Select a Customer Name',
      colSpan: 4,
      options: [],
      mandatory: true,
    },
    packingSlipDate:{
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Packing Slip Date",
      colSpan: 4,
      mandatory: true,
      error: false,
      errorMessage: "Please Select a Date",
    },
    invoiceNo: {
      inputType: "options",
      value: "",
      options: [],
      error: false,
      errorMessage: "Please Select Invoice Number",
      colSpan: 4,
      mandatory: true,
      hintText: "Invoice No",
    },
    soNo: {
      inputType: "text",
      value: "",
      options: [],
      error: false,
      errorMessage: "Please Select Sale Order Number",
      colSpan: 4,
      mandatory: true,
      hintText: "So No",
      disabled: true,
    },
    vehicleNo: {
      inputType: "text",
      value: "",
      hintText: "Vehicle No",
      error :false,
      errorMessage: "Please Insert a Vehicle No",
      colSpan: 4,
      mandatory :false
    },
  });

  const [lineItemsParams, setBundleListParams] = useState({
    sectionNo: {
      inputType: "options",
      options: [],
      value: "",
      hintText: "Section No",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please Enter Section No",
    },
    bundleNo: {
      inputType: "options",
      options: [],
      value: "",
      hintText: "Bundle No",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please Enter Bundle No",
    }
  });

  const [bundleList, setBundleList] = useState([]);
  const [packingSlipErrors,setPackingSlipErrors] = useState([]);
  const [invoiceAppList, setInvoiceAppList] = useState([]);
  const [updateBundleList, setUpdateBundleList] = useState([])
  
  async function getPackingSlipDetail(){
    let url = "store-fetch/"
    url = screenName === "Packing" ? url +"packing-slip-detail/"+storeInfo.packingSlipId :url +"loading-slip-detail/"+storeInfo.loadingSlipId

    let psDetail = await fetchData({
      requestingPage: "customerList",
      method: "post",
      url,
      headers: { token: isLogged.accessToken, module:  screenName === "Packing" ?"Packing Slip":"Loading Slip" },
    });
    return psDetail.asset
  }
  const getInformation=useCallback(async() => {
  let packingSlipDetail = await getPackingSlipDetail();
  let invoiceList =  await getSalesInvoiceApproved();
  let invoiceOptions = invoiceList.filter((invoice) => invoice.customerId === packingSlipDetail.customerId);

  if(packingSlipParams.plantId.options.length === 0){
      const plantConfig={
        requestingPage: "plantList",
          method: "get",
          url: "fetch/plants",
          headers: { token: isLogged.accessToken,module:"Plant & Store" },
      };
      const customerConfig={
        requestingPage: "customerList",
        method: "get",
        url: "fetch/customers",
        headers: { token: isLogged.accessToken,module:"Customer" },
      };
      const bundleConfig = {
        requestingPage: "sectionMaster",
        method: "post",
        url: "fetch/bundle-line-item",
        headers: { token: isLogged.accessToken, module: "Packing Slip" },
      }
      let [plants =[],customers=[],sections=[]] = await Promise.all([
          fetchData(plantConfig).then((res)=>res.asset),
          fetchData(customerConfig).then((res)=>res.asset),
          fetchData(bundleConfig).then((res) => res)
        ])
        let paramsCopy = Object.assign(packingSlipParams);
        
        //get storage options corresponding to the plant id from db
        let initialStorageOptions = plants.filter((obj)=>obj.plant.plantId===packingSlipDetail.plantId)[0].storage
         
        paramsCopy = update(paramsCopy,{plantId: {options: {$set: plants},value: {$set: packingSlipDetail.plantId} },
                                        customerId:{options:{$set: customers},value: {$set: packingSlipDetail.customerId} },
                                      storageId:{options: {$set: initialStorageOptions},value:{$set: packingSlipDetail.storageId} } ,
                                      packingSlipDate:{value:{$set: new Date(packingSlipDetail.psDate)} },
                                      vehicleNo:{value:{$set: packingSlipDetail.vehicleNo}},
                                      invoiceNo: {options: {$set: invoiceOptions},value:{$set: packingSlipDetail.invoiceNo} },
                                      soNo: {value: {$set: packingSlipDetail.soNo}}
                                      });

        setPackingSlipParams(paramsCopy);
        
        let lineItemsCopy = Object.assign(lineItemsParams);

        const { sectionList, bundleList } = sections.asset;
        // const selectedSectionId = packingSlipDetail.packingSlipLineItems[0].sectionId
        let bundleListCopy = screenName === "Packing" ? packingSlipDetail.packingSlipLineItems :packingSlipDetail.loadingSlipLineItems
      lineItemsCopy = update(lineItemsCopy, { sectionNo: { options: { $set: sectionList } } });
      const updateBundleList = bundleList.map((bundle) =>({
        ...bundle,
        optionId: bundle.bundleId,
        optionName: bundle.bundleId.toString().padStart(6,'0')
      }))
      setSectionList(sectionList)
      setBundleListParams(lineItemsCopy);
      setBundleList(updateBundleList);
      setSelectedBundleList(bundleListCopy);
                                  
      //update total counts
      
        let totals = bundleListCopy.reduce((accumulator, currentItem) => {
          accumulator.totalWeight += currentItem.weight;
          accumulator.totalQuantity += currentItem.quantity;
          return accumulator;
        }, { totalWeight: 0, totalQuantity: 0 });
        let totalBc = bundleListCopy.length
        setTotalBc(totalBc)
        setTotalQuantity(totals.totalQuantity);
        setTotalWeight(totals.totalWeight)

    }

    },[isLogged,lineItemsParams])
  

  useEffect(()=>{
    getInformation();
  },[]);
  async function getSalesInvoiceApproved() {
    let invoices = await fetchData({
      requestingPage: "customerList",
      method: "post",
      url: "so-fetch/sales-invoice-approved-list",
      headers: { token: isLogged.accessToken, module: "Packing Slip" },
    });

    if (invoices.msg === "success") {
      let invoiceList = [];
      invoices.asset.forEach(({ invoiceNo, soNo, totalAmount, customerId,optionName }, i) => { 
        invoiceList.push({ invoiceNo, soNo, totalAmount: parseInt(totalAmount), customerId,optionId:optionName,optionName }); 
      });
      setInvoiceAppList(invoiceList);
      return invoiceList;
    }
  };

  const editSubSectionItem = (action, index) => {

  if (action === "delete") {
  // delete the selected element index from selected bundleList
  const selectedBundleListCopy = selectedBundleList.filter((_, i) => i !== index);
  setSelectedBundleList(selectedBundleListCopy);
};

  }

  const addBundleToLine = () => {
    let lineCopy = updateBundleList.slice();
    let errorList = []
    let   psCopy = Object.assign(lineItemsParams);
    
    if(selectedBundleList.length < 0) {
    if (!lineItemsParams.sectionNo.value && !lineItemsParams.bundleNo.value) {
      psCopy.sectionNo.error = true;
      psCopy.bundleNo.error = true;
      errorList.push("Provide Valid Packing Slip Details");
    }
  }
    if (errorList.length === 0) {   // if there is no error

      const selectedBundleID = lineItemsParams.bundleNo.value;
      let bundleListCopy = lineCopy;
      // select all the bundles which is match with section id , to show in table
      bundleListCopy = bundleListCopy.filter(bundleItem => bundleItem.bundleId === selectedBundleID )
      let updatedBundle = [...selectedBundleList, ...bundleListCopy]
      const sortedBundleList = updatedBundle.slice().sort((a, b) => a.bundleId - b.bundleId);
      let trimmedList = psCopy.bundleNo.options.filter((item) => item.optionId !== psCopy.bundleNo.value);
      // reset section field
      psCopy = update(psCopy, { bundleNo: { value: { $set: "" }, options: { $set: trimmedList } } });
      setBundleListParams(psCopy);
      setUpdateBundleList(trimmedList)
      setSelectedBundleList(sortedBundleList);
    } else {
      setErrors(errorList);
    }
  }
  
  const updatePackingParams = (props)=>{
let {section,paramName,key,value,wholeObject} = props;
    switch(section){
      case "general":
        let packingParamsCopy = Object.assign(packingSlipParams);
        packingParamsCopy = update(packingParamsCopy,{[paramName]: {[key]: {$set: value} } });
        if(paramName==="plantId"){
          if(value){
            packingParamsCopy = update(packingParamsCopy,{storageId: {options: {$set: wholeObject.storage} } })
          }else{
            packingParamsCopy = update(packingParamsCopy,{storageId: {options: {$set: []} } })
          }
        }
        if (paramName === "customerId"){
          if(value){
            let invoiceOptions = invoiceAppList.filter((invoice) => invoice.customerId === packingParamsCopy.customerId.value);
            packingParamsCopy = update(packingParamsCopy, { invoiceNo: { options: { $set: invoiceOptions } } });
          }
          else {
            packingParamsCopy = update(packingParamsCopy, {
              invoiceNo: { value: { $set: "" }, options: { $set: [] } },
              soNo: { value: { $set: "" } }
            });
          }
        }
        if (paramName === "invoiceNo") {
          if (value) {
            packingParamsCopy = update(packingParamsCopy, { soNo: { value: { $set: wholeObject.soNo } } })
          }
          else {
            packingParamsCopy = update(packingParamsCopy, { storageId: { value: { $set: "" } } })
          }
        }
        setPackingSlipParams(packingParamsCopy);
        break;

      case "items":
        let lineItemsParamsCopy = Object.assign(lineItemsParams);
        lineItemsParamsCopy = update(lineItemsParamsCopy, { [paramName]: { [key]: { $set: value } } });
        if (paramName === "sectionNo") {
          if (value) {
            let bundleListCopy =  bundleList
             bundleListCopy  = bundleListCopy.filter(bundleItem => bundleItem.sectionId === value ) 
             bundleListCopy = bundleListCopy.filter(item1 => !selectedBundleList.some(item2 => (item2.sectionId === item1.sectionId && item2.bundleId === item1.bundleId)))
                // console.log(listCopy )                                  
             setUpdateBundleList(bundleListCopy)
            lineItemsParamsCopy = update(lineItemsParamsCopy, { bundleNo: { options: { $set: bundleListCopy } } })
          }
          else {
            lineItemsParamsCopy = update(lineItemsParamsCopy, { bundleNo: { options: { $set: [] } } })
          }
        }lineItemsParamsCopy = update(lineItemsParamsCopy, { sectionNo: { value: { $set: value } } });
        setBundleListParams(lineItemsParamsCopy);
        break;

      default:
        return;
    }

  }

  useEffect(()=>{
    if(toServerData.length || selectedBundleList.length){
      let list = toServerData.length?toServerData:selectedBundleList
      const totals = list.reduce((accumulator, item) => {
        accumulator.totalWeight += item.weight;
        accumulator.totalQuantity += item.quantity;
        return accumulator;
      }, { totalWeight: 0, totalQuantity: 0});
        let totalBc = list.length
        setTotalBc(totalBc)
        setTotalQuantity(totals.totalQuantity);
        setTotalWeight(totals.totalWeight)
    }
  },[selectedBundleList.length,toServerData.length]);

  const RenderTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr className="createVendorContactsTableHeader">
            <td>Section No</td>
            <td>Section Name</td>
            <td>Bundle No</td>
            <td>Quantity</td>
            <td>Cutting Length</td>
            <td>Weight Range</td>
            <td className='itemIndiTabActions'>Actions</td>
          </tr>
        </thead>
        <tbody>
          {selectedBundleList.length > 0 ?
            selectedBundleList.map((item, index) => (
              <tr key={index} className="createVendorContactsTableRows">
                <td>{item.sectionNo}</td>
                <td>{item.sectionName}</td>
                <td>{(""+item.bundleId).padStart(6,'0')}</td>
                <td>{item.quantity}</td>
                <td>{item.cuttingLength}</td>
                <td>{item.weight}</td>
                <td className='itemIndiTabActions'>
                  <img
                    alt="Delete Record"
                    className="createVendorContactsAction"
                    src={deleteIcon}
                    onClick={() => {
                      let reset = window.confirm("Delete record?");
                      if (reset) {
                        editSubSectionItem("delete", index);
                      }
                    }}
                  />
                </td>
              </tr>
            ))
            :
            <tr className='createVendorContactsTableRows'>
              {Array.from({ length: 6 }).map((key, index) => <td key={index}>&nbsp;</td>)}
            </tr>
          }
        </tbody>
      </table>
    )
  }


  const renderSubsection = () => {
    return (
      <Fragment>
        {/* <div className="purchaseOrderIndividualItemsArea" style={{ gridAutoRows: "60px" }}>
          {renderFormElements({
            section: "items", elementList: ["sectionNo","bundleNo"],
            param: lineItemsParams, setInput: updatePackingParams, test: "bundle"
          })}
          <FormElement
            inputType="addButton"
            value="+ Add"
            colSpan={4}
            setInput={() => {
              setPackingSlipErrors([]);
              addBundleToLine();
            }}
          />
        </div> */}
        <RenderTable />
      </Fragment>
    )
  };

  const checkErrors = () => {
    let errorList = [];
    let psParamCopy = Object.assign(packingSlipParams);
    Object.keys(psParamCopy).map((item) => {
      if (psParamCopy[item].mandatory)
        psParamCopy[item].error = !validateMandatory(psParamCopy[item].value.toString());
      if (psParamCopy[item].error) {
        errorList.push(psParamCopy[item].errorMessage);
      }
    });

    let lineItemsCopy = selectedBundleList.slice();


    if (!toServerData && selectedBundleList.length < 1)
      errorList.push("Select Section No");

    if (selectedBundleList.length > 0) {
      let dataToServer = {
        plantId: psParamCopy.plantId.value,
        storageId: psParamCopy.storageId.value,
        customerId: psParamCopy.customerId.value,
        psId:lineItemsCopy[0].psId,
        psDate: psParamCopy.packingSlipDate.value || null,
        soNo: psParamCopy.soNo.value || null,
        invoiceNo: psParamCopy.invoiceNo.value || null,
        vehicleNo: psParamCopy.vehicleNo.value || null,
        lsId:lineItemsCopy[0].lsId,
      };
      dataToServer.lineItems = toServerData.length?toServerData:lineItemsCopy;
      submitData(dataToServer);
    } else {
      setPackingSlipParams(psParamCopy);
      setBundleList(lineItemsCopy);
      setErrors(errorList);
    }

  }

  async function submitData(dataToServer) {
    let url = "store-edit/"
  url = url+"packing-slip/"+storeInfo.packingSlipId
    let result = await fetchData({
      requestingPage: "createPsItem",
      url: url,
      method: "put",
      headers: { token: isLogged.accessToken, module:"Packing Slip" },
      data: dataToServer
    });
    if (result.msg === "success") {
      setDataSubmitted(true);
      setIsOpen(true);
      setModalText(`${"Packing Slip"} Edited SuccessFully`);
    } else {
      setDataSubmitted(true);
      setModalText(result.desc);
      setErrors([result.desc]);
    }
  }

  const submitOkClick = () => {
    setIsOpen(false);
    if (errors.length === 0) {
      setSection("packingSlipList");
    }
  };
  const parseData = (data) => {
    var errorList = [];
    var serverList = [];
      data.forEach((row)=>{
        var rowError = false;
        if (row.sectionNo !== undefined) {
          var categInFile = sectionList.find((option) => option.optionName.toString() === row.sectionNo.toString());
            if (categInFile === undefined) {
                row.section_No_Error = "New Value";
                rowError = true;
            }else{
              row.sectionId = categInFile.optionId;
            }
        } else {
            row.section_No_Error = "Blank";
            rowError = true;
        }
        if (row.bundleNo !== undefined) {
           let bundleListCopy =  bundleList
            // filtering bundleId related to sectionId
            bundleListCopy  = bundleListCopy.filter(bundleItem => bundleItem.sectionNo.toString() === row.sectionNo.toString() ) 
            var itemTypeInFile = bundleListCopy.find((option) => option.optionId === row.bundleNo);
      
                  if (itemTypeInFile === undefined) {
                      row.bundle_No_Error = "New Value";
                      rowError = true;
                  } else {
                      row.bundleId = itemTypeInFile.optionId;
                      row.bundleLineId = itemTypeInFile.bundleLineId;
                      row.quantity = itemTypeInFile.quantity;
                      row.weight = itemTypeInFile.weight;

                  }
              } else {
                  row.bundle_No_Error = "Blank";
                  rowError = true;
                 }

                 if (rowError) {
                          errorList.push(row);
                      } else {
                          serverList.push(row);
                      }
      })

     errorList.sort((a, b) => (a.Line_Number > b.Line_Number ? 1 : -1));

    setExcelData(data);
    setErrorData(errorList);
    setToServerData(serverList);

    setIsOpen(false);
};

// useEffect(()=>{
//   if(toServerData.length){
//     let filteredServerDate = [] ;
//     let data =selectedBundleList.filter((item)=>{ 
//       let filteredList = toServerData.map(value=>
//         {
//           if(value.sectionNo.toString() !== item.sectionNo.toString() && value.bundleId !== item.bundleId){
//             console.log(value,"value")
//           }
//         })
//       console.log(filteredList,"filteredList")
//       return filteredList
//     })
//     console.log(data,"data")
//   }
// },[toServerData])

  const readExcel = (file) => {
    setIsOpen(true);
    setModalText("Reading excel file. Please wait...");
    setDataSubmitted(false);
    const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
            const bufferArray = e.target.result;

            const workBook = XLSX.read(bufferArray, { type: "buffer" });

            const workSheetName = workBook.SheetNames[0];
            const workSheet = workBook.Sheets[workSheetName];
            const data = XLSX.utils.sheet_to_json(workSheet);

            resolve({ data });
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });

    promise.then(({ data }) => {

        parseData(data);
    });
};


  const renderErrorMessage=()=>{
    if(packingSlipErrors.length > 0) return packingSlipErrors[0];
  }
  return (
      <Fragment>
        <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
        <div className='formArea'>
          <div
            style={{
              width: "1000px",
              margin: "0 auto 2rem",
              // padding: "3rem 3rem 1rem 3rem",
              padding: "2rem",
              border: "1px solid lightgray",
              borderRadius: "5px",
              backgroundColor: "white",
            }}>
            <div style={{ display: "flex" }}>
              <div
                className="createPurchaseOrderGrid"
                style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
                    {renderFormElements({section:"general",elementList: Object.keys(packingSlipParams),
                                        param: packingSlipParams,setInput: updatePackingParams})}

                </div>
            </div>
            <div className='purchaseOrderSubSectionArea'>
                  {renderSubsection()}
            </div>

            <div className="poSpacer">
          &nbsp;
          </div>
          <div style={{width: "max-content"}}>
          { bulkFile !== "" ? (
                                    <div className="formElement" style={{ gridColumn: `auto / span ${4}` }}>
                                        <div className="inputDone">
                                            <span
                                                style={{
                                                    flex: 1,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "25rem",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                ✅ {bulkFile.name}
                                            </span>
                                            <span
                                                style={{
                                                    paddingRight: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    "&:hover": { backgroundColor: "gray" },
                                                    maxWidth: "25rem",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    boxSizing: "border-box",
                                                }}
                                                onClick={() => {
                                                    setBulkFile("");

                                                    setErrorData([]);
                                                    setExcelData([]);
                                                }}
                                                title="Remove and Re-upload LR Copy"
                                            >
                                                ❌
                                            </span>
                                        </div>
                                    </div>
                                ) :
                                <>
          <label className={"inputLabel"} htmlFor="upload_file" style={{padding:"11px"}}>
                    Upload Bulk Bundles
          </label>
                <input
                    type="file"
                    name="items"
                    accept=".xls, .xlsx"
                    onChange={(event) => {
                        setBulkFile(event.target.files[0]);
                        readExcel(event.target.files[0]);
                    }}
                    id="upload_file"
            /></>}

            </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }} >
            <div className="poNetTotal">
              <div className="poTotalTitle">
                <span>
                  <b>Total Bundle Count</b>
                </span>
                <span className="poNetTotalAmount">{totalBc}</span>
              </div>
              <div className="poTotalTitle">
                <span>
                  <b>Total Quantity</b>
                </span>
                <span className="poNetTotalAmount">{totalquantity}</span>
              </div>
              <div className="poTotalTitle">
                <span>
                  <b>Total Weight</b>
                </span>
                <span className="poNetTotalAmount">{totalweight}</span>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div className='formSubmitArea'>
            <div className="formSubmitInnerArea">
              <p className="formSubmitErrorTextArea">
                {renderErrorMessage()}
              </p>
              <button className='submitButton' onClick={()=>{
                setPackingSlipErrors([]);
                checkErrors();
                }}>
                Submit
              </button>
            </div>
        </div>
      </Fragment>
  )
}

const mapStateToProps = (state) => ({isLogged:state.isLogged,storeInfo: state.storeInfo})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePackingSlip);