import React,{Fragment,useState} from 'react';
import MenuList from 'components/Menu/MenuList';
import TopBanner from 'components/SmallComponents/TopBanner';
import PageTitle from 'components/SmallComponents/PageTitle';
import itemsIcon from "assets/item_black.svg";
import { connect } from 'react-redux';
import SalesItemsList from './SalesItemsList';
import { useHistory } from "react-router-dom";
import CreateSalesItem from './CreateSalesItem';
import EditSalesItem from "./EditSalesItem";
import SalesItemDetail from './SalesItemDetail';

const SalesItems=({isLogged})=>{

  const [currentSection,setCurrentSection] = useState("salesItemList");
  const [isApproval,setIsApproval] = useState(false);

  const history = useHistory();

    const pushBack = (section) => {
        history.push(section);
    };
  var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "salesItemList" }];

    var goBackToItemButton = {
        buttonName: "Back",
        className: "goBackButton",
        setSection: pushBack,
        sectionName: "/itemMenu",
    };

    var createItemButton = {
        buttonName: "Create Section Master",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createSalesItem",
    };


  const renderSection=(section)=>{

    switch(section){
      case "salesItemList":
        return(
          <Fragment>
             <PageTitle
                  imgSrc={itemsIcon}
                  pageTitle="Section Master List"
                  buttonList={
                      isLogged.access["Sales Items"] === "Create" || isLogged.access["Sales Items"] === "Edit" || isLogged.access["Sales Items"] === "App/Dec"
                          ? [createItemButton, goBackToItemButton]
                          : [goBackToItemButton]
                  }
              />
              <SalesItemsList setSection={(value) => setCurrentSection(value)} setIsApproval={setIsApproval}/>
          </Fragment>
        );

      case "createSalesItem":
        return(
          <Fragment>
            <PageTitle 
              imgSrc={itemsIcon}
              pageTitle={"Create Section Master"}
              buttonList={goBackButton}
            />
            <CreateSalesItem setSection={(value) => setCurrentSection(value)}/>
          </Fragment>
        );

        case "EditSalesItem":
          return(
            <Fragment>
              <PageTitle
              imgSrc={itemsIcon}
              pageTitle={"Edit Section Master"}
              buttonList={goBackButton}
              />
              <EditSalesItem setSection={(value) => setCurrentSection(value)}/>
            </Fragment>
          )

          case "SalesItemDetail":
            return(
              <Fragment>
                <PageTitle
                imgSrc={itemsIcon}
                pageTitle={"Section Master Detail"}
                buttonList={goBackButton}
                />
              <SalesItemDetail setSection={(value) => setCurrentSection(value)} isApproval={isApproval}/>
              </Fragment>
            )

        default: 
          return(
            <>Page Not Found</>
          )
    }
  }


  return (
    <Fragment>
      <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
    </Fragment>
  );
}

const mapStateToProps=(state)=>{

  return{
    isLogged: state.isLogged
  }
}

export default connect(mapStateToProps,null)(SalesItems);