import React,{useEffect,useState} from "react";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";

const ProductionReportDetail =({isLogged,poInfo})=>{

    const [reportDetail,setReportDetail] = useState([]);
    const [lineItems,setLinItems]= useState([]);
    const RenderTable = ({currentPageLineItems,pageNumber}) => {
        return (
                <>
                <table className="innerTable" style={{width:"100%",height:"90%"}}>
                    <thead>
                        <tr>
                            <td colSpan={17}>
                                <div style={{display: "grid",gridTemplateColumns: "auto auto",padding:"13px",justifyContent: "space-between"}}>
                                    <span>SHIFT :{reportDetail.shiftNo}</span>
                                    {/* <span>
                                     PRODUCTION REPORT
                                    </span> */}
                                    <span>Date :{reportDetail.date}</span>
                                </div>
                            </td>
                        </tr>
                    <tr className="invoiceDetailContactsTableHeader" style={{color: "#000"}}>
                    <td rowSpan={2}>Sr.No</td>
                    <td rowSpan={2}>Die No</td>
                    <td rowSpan={2}>CVT</td>
                    <td colSpan={3} style={{ textAlign: "center" }}>Time</td>
                    <td rowSpan={2}>CNo</td>
                    <td colSpan={2} style={{ textAlign: "center" }}>Input</td>
                    <td colSpan={3} style={{ textAlign: "center" }}>Output</td>
                    <td rowSpan={2}>Trolley No</td>
                    <td rowSpan={2}>WT.Range(Kgs)</td>
                    <td colSpan={2} style={{ textAlign: "center" }}>Efficiency</td>
                    <td rowSpan={2}>Remarks</td>
                </tr>
                <tr className="invoiceDetailContactsTableHeader" style={{color: "#000"}}>
                    <td>On</td> <td>Off</td><td>Total</td>
                    <td>Billet Size</td> <td>Kgs</td> <td>C/L</td> <td>Pieces</td> <td>Kgs</td>
                    <td>%</td> <td>KG/HR</td>
                </tr>
                    </thead>
                    <tbody>
                        <RenderTableRows  currentPageLineItems={currentPageLineItems} pageNumber={pageNumber}  tab="contact" />
                    </tbody>
                </table>
                </>
            );
       };

    const RenderTableRows = ({ currentPageLineItems,pageNumber }) => {
        if(currentPageLineItems?.length>0){
        return (<>
            {currentPageLineItems.map((row, j) => {
                return (
                    <tr className="invoiceDetailContactsTableRows" key={j}>
                        <td  style={{borderBottom: "none", borderTop: "none"}}>{((pageNumber-1)*16)+j+1}</td>
                        {["dieNo",
                            "cvt",
                            "onTime",
                            "offTime",
                            "total",
                            "castingNo",
                            "billetSize",
                            "inputKgs",
                            "cuttingLength",
                            "pieces",
                            "outputKgs",
                            "trolleyNo",
                            "weightRange",
                            "efficiencyPercentage",
                            "efficiencyKgs",
                         "remark"].map((key, i) => {           
                        return <td  style={{borderBottom: "none", borderTop: "none"}} key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                        })}
                    </tr>
                );
            })}
        </>);
    } else return(<></>)
       };

    const getPrDetail = async()=>{
        var data = await fetchData({
            requestingPage: "premiumCharges",
            method: "post",
            url: "po-fetch/production-report/"+ poInfo.productionReportId,
            headers: { token: isLogged.accessToken, module: "Production Report" },
          });
          if (data.msg === "success") {
            return data.asset[0]
          }
      }

    const getInfo=async()=>
    {
        let detail = await getPrDetail();
        setReportDetail(detail)
        let lineChunk =[];
        for(let i=0;i< detail?.lineItems.length; i+=16) // 16 items in each page
                lineChunk.push(detail?.lineItems.slice(i,i+16));
                setLinItems(lineChunk);       
    }
    useEffect(()=>{
        getInfo();
    },[])

return(
    <>
      <div className="detailsPageWrapper">
        { //Invoice Pages
        lineItems?.length > 0 ?
        lineItems?.map((currentPage,i)=>{                   
                    return(
                    <div className="detailsPageContainer" key={i}>
                        <div className="invoicePageTitle">PRODUCTION REPORT</div>
                        <div style={{border:"0.25px solid black",minHeight:"1100px",position:"relative"}}>
                            <RenderTable currentPageLineItems={currentPage} pageNumber={i+1}/>
                        </div>
                            <div style={{width: "100%", textAlign: "center"}}>
                            </div>
                            <div style={{width: "100%", textAlign: "center"}}>
                                <span style={{fontSize: "9px"}}>This is a Computer Generated Invoice</span>
                            </div>
                    </div>
                    )
                    }) : null
        }
        </div>
    </>
)
}
const mapStateToProps = (state) => {
    return {
      isLogged: state.isLogged,
      poInfo: state.poInfo,
    };
  };
  
export default connect(mapStateToProps)(ProductionReportDetail);