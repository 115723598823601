import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import fetchData from "serverCall/fetchData";
import FetchNewToken from "serverCall/FetchNewToken";
import Pagination from '../../CommonFunctions/pagination';
import downIcon from "assets/down.svg";
import upIcon from "assets/up.svg";
import editIcon from "assets/edit.svg";
import editIconDisabled from "assets/edit_disabled.svg";
import viewIcon from "assets/view.svg";
import { editItemId, viewItemId } from "redux/Item/ItemInfoActions";
import FormElement from "../../SmallComponents/FormElement";
import SortUpGreen from "assets/SortUpGreen.svg";
import SortDownGreen from "assets/SortDownGreen.svg";
import approveIcon from "assets/approve2.svg";
import approveIconDisabled from "../../../assets/approve.svg"
// import { handleSort } from "../../CommonFunctions/utils";
const ItemList = ({
    // props
    setSection,
  setIsApproval,
    // state
    isLogged,

    // dispatch
    addTokenToState,
    editItem,
    viewItem,
  }) => {
    const history = useHistory();
    const [categoryOptions,setCategoryOptions] = useState([]);
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getBrandNamesList();
        await getItems();
        let categoryOptions = await getItemCategory();
        setCategoryOptions(categoryOptions);
    }
    const [brandNamesList,setBrandNamesList]=useState([]);
    const [brandId,setBrandId]=useState("");
    const [itemList, setItemList] = useState([]);
    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentPage,setCurrentPage] = useState(1);
    const [PageSize,setRowsPerPage] = useState(10);
    const [itemsTab,setItemsTab] = useState('Active') ;
    const [categoryId,setCategoryId] =useState('');
    const [subCategoryId,setSubCategoryId] = useState('');
    const [subCategoryOptions,setSubCategoryOptions] = useState([]);
    const tablist = [
      { id: "Approval Pending",name: "Unapproved Items"},
      { id: "Active", name: "Active Items" },
      { id: "Inactive", name: "InActive Items" },
  ];
  let finalList = itemList.slice();
  const [itemName,setItemName] = useState("");
    const itemListCurrentPage = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        if(itemName.length > 0 && finalList.length > 0)
        finalList = finalList.filter((row)=>row.item.itemName.toLowerCase().includes(itemName.toLowerCase()));
        return finalList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,itemList,PageSize,itemName]);

    async function checkToken() {
        console.log("Checking token");
        //   console.log(isLogged.accessToken);
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }
    useEffect(()=>{
     getItems();
    },[brandId,itemsTab,categoryId,subCategoryId])

    async function getItems() {
      let tab  =  itemsTab==="Active"||itemsTab==="Inactive"?itemsTab:''
        var data = await fetchData({
            requestingPage: "itemList",
            method: "post",
            url: "fetch/items",
            headers: { token: isLogged.accessToken,module:"Items" },
            data: {brandId,tab,categoryId,subCategoryId,type:"Purchase"}
        });
        if (data.msg === "success") {
          
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setItemList(data2);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    const editItemCollapse = (index) => {
        var itemListCopy = itemList.slice();
        itemListCopy.forEach((item, j) => {
            if (j === index) {
                itemListCopy[j].collapse = !item.collapse;
            } else {
                itemListCopy[j].collapse = true;
            }
        });
        setItemList(itemListCopy);
    };
    async function getItemCategory() {
      var data = await fetchData({
          requestingPage: "itemCategoryList",
          method: "get",
          url: "fetch/categories",
          headers: { token: isLogged.accessToken,module:"Item Category" },
      });
      if (data.msg === "success") {
          return data.asset;
      } else {
          console.log(data);
          return [];
      }
  }

    async function getBrandNamesList() {
        var data = await fetchData({ requestingPage: "itemBrandList", method: "get", url: "fetch/brands", 
                      headers: { token: isLogged.accessToken,module:"Item Brand" } });
        if (data.msg === "success") {
            if (data.asset.length > 0) 
            {setNoRecords(false);
            let options= data.asset.map((obj)=>({optionId: obj.brandId,optionName: obj.brandName}));
            setBrandNamesList(options);
            }
            else setNoRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }
    const renderItemList = () => {
        return itemListCurrentPage.map((item, i) => {
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.item.itemCode}
                        onClick={() => editItemCollapse(i)}
                    >
                        <td className="vendorListInfo">{item.item.itemCode}</td>
                        <td className="vendorListInfo">{item.item.itemName}</td>
        {isLogged.showHideWeight ?   
                        <>
                        <td className="vendorListInfo">{item.item.from ? item.item.from : " - "}</td>
                        <td className="vendorListInfo">{item.item.to ? item.item.to : " - "}</td>
                        <td className="vendorListInfo">{item.item.netWeight? item.item.netWeight : " - "}</td>
                        <td className="vendorListInfo">{item.item.grossWeight? item.item.grossWeight : " - "}</td>
                        </> : null}
                        <td className="vendorListInfo">{item.item.hsnCode}</td>
                        <td className="vendorListInfo">{item.item.brandName}</td>
                        <td className="vendorListInfo">{item.item.mrp}</td>
                        <td className="vendorListInfo" style={{ whiteSpace: "break-spaces" }}>
                            {item.item.description}
                        </td>
                        <td className="vendorListInfoActions">
                         { itemsTab === "Approval Pending" && 
                         <img 
                            alt="Approve Record"
                            src={
                              item.item.status === "Draft"
                                ? approveIconDisabled
                                : approveIcon
                            }
                          
                            title= {item.soStatus === "Draft"?
                            "Can't approve Draft":isLogged.access.Items === "App/Dec" ?  "Approve Item" : "Access Denied"}
                            className="vendorActionImages"
                            style={ item.item.status !== "Draft" && isLogged.access.Items === "App/Dec" ? {cursor: 'pointer'} : {cursor: 'not-allowed'}}
                            onClick={(e)=>{
                              e.stopPropagation();
                              if (item.item.status !== "Draft"){
                              viewItem(item.item.itemId);
                              setSection("itemDetail");
                              setIsApproval(true);
                              }
                            }}
                          /> }
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View Item Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewItem(item.item.itemId);
                                    setSection("itemDetail");
                                }}
                            />
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={isLogged.access.Items === "Edit" || isLogged.access.Items === "App/Dec" ? editIcon : editIconDisabled}
                                title={isLogged.access.Items === "Edit" || isLogged.access.Items === "App/Dec" ? "Edit Item" : "No Edit Access"}
                                onClick={(e) => {
                                    if (isLogged.access.Items === "Edit" || isLogged.access.Items === "App/Dec") {
                                        e.stopPropagation();
                                        editItem(item.item.itemId);
                                        setSection("editItem");
                                    }
                                }}
                                style={isLogged.access.Items === "Edit" || isLogged.access.Items === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse(i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr key={i}>{!item.collapse ? <RenderSubTable data={item.uom} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const RenderSubTable = ({ data }) => {
        return (
            <td colSpan="12" className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Count</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.uomId}>
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.uomCount}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };
    const sortBy = (data, col, order) => {
        if (order === false) {
          const sortAlphaNum = (a, b) =>
            a["item"][col]?.localeCompare(b["item"][col]||"", "en", { numeric: true });
          data.sort(sortAlphaNum);
        } else {
          const sortAlphaNum = (a, b) =>
            b["item"][col]?.localeCompare(a["item"][col] || "", "en", { numeric: true });
          data.sort(sortAlphaNum);
        }
      };
      const sortByDate = (data, col, order) => {
        if (order === true) {
          const sortAlphaNum = (a, b) => new Date(a["item"][col]) - new Date(b["item"][col]);
          data.sort(sortAlphaNum);
        } else {
          const sortAlphaNum = (a, b) => new Date(b["item"][col]) - new Date(a["item"][col]);
          data.sort(sortAlphaNum);
        }
      };
      const [order, setOrder] = useState({ col: "itemCode", state: true, data: "" });
      const handleSort = ({ col, state, data }) => {
        if (col.includes("Date")) {
          sortByDate(data, col, state);
        } else {
          sortBy(data, col, state);
        }
      };  
    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={itemsTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => setItemsTab(tab.id)}
                >
                    {tab.name}
                </div>
            );
        });
    };
      return (
        <React.Fragment>
        <div key={1} className="poListTabs">
          <RenderTabList key={1} tablist={tablist} />
        </div>
            <div style={{ display:"inline-flex",width: "700px", marginLeft: "25px" }} className="purchaseOrderGrid">
            <span style={{flexGrow:0, width:"80px"}}>
        <FormElement
          setInput={(value) => {
            setCurrentPage(1)
            setRowsPerPage(value);
          }}
          inputType={"number"}
          value={PageSize}
          hintText={"Rows Per Page"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span style={{flexGrow:1}}>
        <FormElement
          setInput={(value) => {
            setCurrentPage(1)
            setBrandId(value);
          }}
          inputType={"options"}
          value={brandId}
          hintText={"Brand Name"}
          colSpan={0.5}
          options={brandNamesList}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span style={{flexGrow:1}}>
        <FormElement
          setInput={(value,wholeObject) => {
            setCurrentPage(1);
            setCategoryId(value);
            setSubCategoryId("");
            setSubCategoryOptions(wholeObject?.['subCategory'] || [])
          }}
          inputType={"options"}
          value={categoryId}
          hintText={"Category Name"}
          colSpan={0.5}
          options={categoryOptions}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span style={{flexGrow:1}}>
        <FormElement
          setInput={(value) => {
            setCurrentPage(1);
            setSubCategoryId(value);
          }}
          inputType={"options"}
          value={subCategoryId}
          hintText={"Sub Category Name"}
          colSpan={0.5}
          options={subCategoryOptions}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span style={{flexGrow: 0,width:"150px"}}>
        <FormElement
          setInput={(value) => {
            setCurrentPage(1);
            setItemName(value);
          }}
          inputType={"text"}
          value={itemName}
          hintText={"Item Name"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        </div>
            <div className="vendorListArea">
                <table className="vendorListTable">
                    <thead>
                        <tr className="vendorListHeader">
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "itemCode",
                        state: !order.state,
                      });
                      handleSort({
                        data: itemListCurrentPage,
                        col: "itemCode",
                        state: !order.state,
                      });
                    }}>Item Code
                    <img
                          className="sortArrow"
                          src={
                               order.col === "itemCode"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                        setOrder({
                            col: "itemName",
                            state: !order.state,
                        });
                        handleSort({
                            data: itemListCurrentPage,
                            col: "itemName",
                            state: order.state,
                        });
                        }}>Item Name
                                <img
                                    className="sortArrow"
                                    src={
                                        order.col === "itemName"
                                        ? order.state
                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                    /></td>   
                { isLogged.showHideWeight?
                            <>           
                            <td className="vendorListHeading"onClick={(e) => {
                      setOrder({
                        col: "from",
                        state: !order.state,
                      });
                      handleSort({
                        data: itemListCurrentPage,
                        col: "from",
                        state: order.state,
                      });
                    }}>Weight From (gms) 
                    <img
                          className="sortArrow"
                          src={
                               order.col === "from"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "to",
                        state: !order.state,
                      });
                      handleSort({
                        data: itemListCurrentPage,
                        col: "to",
                        state: order.state,
                      });
                    }}>Weight To (gms)
                    <img
                          className="sortArrow"
                          src={
                               order.col === "to"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "netWeight",
                        state: !order.state,
                      });
                      handleSort({
                        data: itemListCurrentPage,
                        col: "netWeight",
                        state: order.state,
                      });
                    }}>Net Weight (gms)
                    <img
                          className="sortArrow"
                          src={
                               order.col === "netWeight"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "grossWeight",
                        state: !order.state,
                      });
                      handleSort({
                        data: itemListCurrentPage,
                        col: "grossWeight",
                        state: order.state,
                      });
                    }}>Gross Weight (gms)
                    <img
                          className="sortArrow"
                          src={
                               order.col === "grossWeight"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td> 
                            </>
                            :null}
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "hsnCode",
                        state: !order.state,
                      });
                      handleSort({
                        data: itemListCurrentPage,
                        col: "hsnCode",
                        state: order.state,
                      });
                    }}>HSN Code
                    <img
                          className="sortArrow"
                          src={
                               order.col === "hsnCode"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "brandName",
                        state: !order.state,
                      });
                      handleSort({
                        data: itemListCurrentPage,
                        col: "brandName",
                        state: order.state,
                      });
                    }}>Brand Name
                    <img
                          className="sortArrow"
                          src={
                               order.col === "brandName"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "mrp",
                        state: !order.state,
                      });
                      handleSort({
                        data: itemListCurrentPage,
                        col: "mrp",
                        state: order.state,
                      });
                    }}>MRP
                    <img
                          className="sortArrow"
                          src={
                               order.col === "mrp"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading">Description</td>
                            <td className="vendorListHeadingActions">Actions</td>
                        </tr>
                    </thead>
                    <tbody>{itemList.length > 0 ? renderItemList() : null}</tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
            {finalList.length>0 ? <Pagination 
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={finalList.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editItem: (itemId) => dispatch(editItemId(itemId)),
        viewItem: (itemId) => dispatch(viewItemId(itemId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
