import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import fetchData from "../../../serverCall/fetchData";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import editIcon from "../../../assets/edit.svg";
import editIconDisabled from "../../../assets/edit_disabled.svg";
import {editPremiumCharges} from "redux/Item/ItemInfoActions";


export const PremiumChargesList = ({
  setSection,
  // state
  isLogged,

  // dispatch
  editPremiumCharges,
  addTokenToState, }) => {
  const [loading, setLoading] = useState(true);
  const [premiumChargesList, setPremiumChargesList] = useState([]);
  const [noRecords, setNoRecords] = useState(false);

  const history = useHistory();
  const checkToken = useCallback(async () => {
    console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }, [isLogged, history, addTokenToState]);

  const getSectionList = useCallback(async () => {
    setLoading(false);
    var data = await fetchData({
      requestingPage: "premiumCharges",
      method: "get",
      url: "fetch/premium-charges",
      headers: { token: isLogged.accessToken, module: "Premium Charges" },
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        setNoRecords(true);
      } else {
        setPremiumChargesList(data.asset);
      }
    } else {
      console.log(data);
    }
  }, [isLogged])

  const getInformation = useCallback(
    async () => {
      await checkToken();
      await getSectionList();
    },
    [checkToken, getSectionList]);

  useEffect(() => {
    getInformation();
  }, [getInformation]);

  const renderPremiumChargesList = () => {
    return (
      premiumChargesList.map((row, index) => {
        return (
          <tr className='uomListData' key={index}>
            <td className="uomSymbolData">{row.premiumNo}</td>
            <td className='uomSymbolData'>{row.premiumName}</td>
            <td className='uomSymbolData'>{row.rate}</td>
            <td className="uomActionData">
              <img
                className="uomEditImage"
                alt="Edit Record"
                src={isLogged.access["Premium Charges"] === "Edit" ? editIcon : editIconDisabled}
                onClick={() => {
                  if (isLogged.access["Premium Charges"] === "Edit") {
                    editPremiumCharges(row.premiumId);
                    setSection("editPremiumCharges");
                  }
                }}
                title={isLogged.access["Premium Charges"] === "Edit" ? "Edit Record" : "No Edit Access"}
                style={isLogged.access["Premium Charges"] === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
              />
            </td>
          </tr>
        )
      })
    )
  }
  return (
    <div className="uomListArea">
      <table className="uomListTable">
        <thead>
          <tr className=" uomListHeader">
            <td className="uomSymbolHeader">
              <span>Premium No</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Premium Name</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Rate</span>
            </td>
            <td className="uomActionHeader">
              <span>Action</span>
            </td>
          </tr>
        </thead>
        <tbody>
          {premiumChargesList.length > 0 ? renderPremiumChargesList() : null}
        </tbody>
      </table>
      {noRecords ? (
        <div className="noRecordsYet">No records found</div>
      ) : loading ? (
        <div className="loader" style={{ margin: "1rem auto" }}></div>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({ isLogged: state.isLogged })

const mapDispatchToProps = (dispatch)=>{
  return {
    addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    editPremiumCharges: (value)=>dispatch(editPremiumCharges(value))
};
}

export default connect(mapStateToProps, mapDispatchToProps)(PremiumChargesList)