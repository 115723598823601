import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import fetchData from "../../../serverCall/fetchData";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import editIcon from "../../../assets/edit.svg";
import editIconDisabled from "../../../assets/edit_disabled.svg";
import {editDieMasterId} from "redux/Item/ItemInfoActions";
import FormElement from 'components/SmallComponents/FormElement';


export const DieList = ({
  setSection,
  // state
  isLogged,

  // dispatch
  editDieMasterId,
  addTokenToState, }) => {
  const [loading, setLoading] = useState(true);
  const [DieList, setDieList] = useState([]);
  const [noRecords, setNoRecords] = useState(false);
  const [currentPage,setCurrentPage] = useState(1);
  const [PageSize,setRowsPerPage] = useState(10);
  let finalList = DieList.slice();

  const history = useHistory();
  const checkToken = useCallback(async () => {
    console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }, [isLogged, history, addTokenToState]);

  const getSectionList = useCallback(async () => {
    setLoading(false);
    var data = await fetchData({
      requestingPage: "premiumCharges",
      method: "post",
      url: "fetch/die-master",
      data:{isBroken:""},
      headers: { token: isLogged.accessToken, module: "Die Master" },
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        setNoRecords(true);
      } else {
        setDieList(data.asset);
      }
    } else {
      console.log(data);
    }
  }, [isLogged])

  // const itemListCurrentPage = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   if(finalList.length > 0)
  //   finalList = finalList.filter((row)=>row.item.dieName.toLowerCase().includes(dieName.toLowerCase()));
  //   return finalList.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage,DieList,PageSize]);

  const getInformation = useCallback(
    async () => {
      await checkToken();
      await getSectionList();
    },
    [checkToken, getSectionList]);

  useEffect(() => {
    getInformation();
  }, [getInformation]);

  const renderDieList = () => {
    return (
      DieList.map((row, index) => {
        return (
          <tr className='uomListData' key={index}>
            <td className="uomSymbolData">{row.dieNo}</td>
            <td className='uomSymbolData'>{row.dieName}</td>
            <td className='uomSymbolData'>{row.recievedDate}</td>
            <td className='uomSymbolData'>{row.size}</td>
            <td className='uomSymbolData'>{row.cvt}</td>
            <td className='uomSymbolData'>{row.pressMachineNo}</td>
            <td className='uomSymbolData'style={{color:row.isBroken?"red":"black"}}>{row.isBroken?"Broken":row.rectifiedDate?"Rectified":"-"}</td>
            <td className='uomSymbolData'>{row.brokenDate}</td>
            <td className='uomSymbolData'>{row?.rectifiedDate}</td>
            <td className="uomActionData">
              <img
                className="uomEditImage"
                alt="Edit Record"
                src={isLogged.access["Die Master"] === "Edit" ? editIcon : editIconDisabled}
                onClick={() => {
                  if (isLogged.access["Die Master"] === "Edit") {
                    editDieMasterId(row.dieId);
                    setSection("editDie");
                  }
                }}
                title={isLogged.access["Die Master"] === "Edit" ? "Edit Record" : "No Edit Access"}
                style={isLogged.access["Die Master"] === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
              />
            </td>
          </tr>
        )
      })
    )
  }
  return (
    <div >
      <div style={{ display:"inline-flex", marginLeft: "25px" }} className="purchaseOrderGrid">
            <span style={{flexGrow:0, width:"80px"}}>
        <FormElement
          setInput={(value) => {
            setCurrentPage(1)
            setRowsPerPage(value);
          }}
          inputType={"number"}
          value={PageSize}
          hintText={"Rows Per Page"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span style={{flexGrow:1}}>
        </span>
        </div>
      <table className="uomListTable">
        <thead>
          <tr className=" uomListHeader">
            <td className="uomSymbolHeader">
              <span>Die No</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Die Name</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Recieved Date</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Size</span>
            </td>
            <td className="uomSymbolHeader">
              <span>CVT</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Press Machine No</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Die Status</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Broken Date</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Rectified Date</span>
            </td>
            <td className="uomActionHeader">
              <span>Action</span>
            </td>
          </tr>
        </thead>
        <tbody>
          {DieList.length > 0 ? renderDieList() : null}
        </tbody>
      </table>
      {noRecords ? (
        <div className="noRecordsYet">No records found</div>
      ) : loading ? (
        <div className="loader" style={{ margin: "1rem auto" }}></div>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({ isLogged: state.isLogged })

const mapDispatchToProps = (dispatch)=>{
  return {
    addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    
    editDieMasterId: (value)=>dispatch(editDieMasterId(value))
};
}

export default connect(mapStateToProps, mapDispatchToProps)(DieList)