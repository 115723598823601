import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import upIcon from "../../assets/up.svg";
import downIcon from "../../assets/down.svg";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { editRoleControl } from "../../redux/Role/RoleInfoActions";

const RoleControlList = ({
    setSection,
    // state
    isLogged,

    // dispatch
    addTokenToState,
    editRoleControlId,
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);

    async function getInformation() {
        await checkToken();
        await getRoleControls();
    }

    const history = useHistory();

    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getRoleControls() {
        var data = await fetchData({
            requestingPage: "roleList",
            method: "get",
            url: "fetch/role-control-role",
            headers: { token: isLogged.accessToken,module : "Role Control" },
        });
        if (data.msg === "success") {
            // Parse reeceived data and save to roleControlList in previous format

            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);

            var bossIds = [];
            data.asset.forEach((item) => {
                bossIds.push(item.boss_role_id);
            });
            var uniqueBossIds = [...new Set(bossIds)];

            var roleControlListTemplate = [];
            uniqueBossIds.forEach((bossId, i) => {
                var roleControlObject = { roleId: bossId, controls: [], collapse: true };
                data.asset.forEach((element) => {
                    if (element.boss_role_id === bossId) {
                        roleControlObject.roleName = element.boss_role_name;
                        roleControlObject.controls.push({
                            reporteeRoleId: element.reportee_role_id,
                            reporteeRoleName: element.reportee_role_name,
                        });
                    }
                });
                roleControlListTemplate.push(roleControlObject);
            });


            setRoleControlList(roleControlListTemplate);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    const [roleControlList, setRoleControlList] = useState([]);

    const editRoleCollapse = (i) => {
        var roleControlListCopy = roleControlList.slice();
        roleControlListCopy.forEach((role, j) => {
            if (j === i) {
                roleControlListCopy[j].collapse = !role.collapse;
            } else {
                roleControlListCopy[j].collapse = true;
            }
            setRoleControlList(roleControlListCopy);
        });
    };

    const editRoleControl = (role) => {
        role.role_id = role.roleId;
        role.role = role.roleName;
        editRoleControlId(role);
        setSection("editRoleControl");
    };

    const renderRoleControls = () => {
        return roleControlList.map((roleControl, i) => {
            return (
                <React.Fragment>
                    <tr
                        className="controlRoleList"
                        onClick={(e) => {
                            editRoleCollapse(i);
                        }}
                        key={i}
                    >
                        {/* <td className="roleList"> */}
                        <td className="roleNameBold">
                            <span>{roleControl.roleName}</span>
                        </td>
                        <td className="roleAction">
                            <img
                                src={isLogged.access["Role Control"] === "Edit" ? editIcon : editIconDisabled}
                                className="roleEditImage"
                                alt="Edit Role"
                                onClick={(e) => {                                        
                                    e.stopPropagation();
                                    if (isLogged.access["Role Control"] === "Edit") {
                                        editRoleControl(roleControl);
                                    }
                                }}
                                title={isLogged.access["Role Control"] === "Edit" ? "Edit Role Control" : "No Edit Access"}
                                style={isLogged.access["Role Control"] === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />
                            <img
                                src={roleControl.collapse ? downIcon : upIcon}
                                className="roleEditImage"
                                alt="Collapse Role"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editRoleCollapse(i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr style={{ backgroundColor: "white" }}>
                        <td colSpan="2">
                            {roleControl.collapse ? null : (
                                <table className="roleListInnerTable">
                                    <tr className=" controlRoleListHeader">
                                        <td className="controlRoleNameTitle">
                                            <span>Control Role Name</span>
                                        </td>
                                    </tr>
                                    {roleControl.controls.map((control,i) => {
                                        return (
                                            <tr className="controlList" key={i}>
                                                <td className="controlRoleName">
                                                    <span>{control.reporteeRoleName}</span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </table>
                            )}
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    };

    return (
        <div className="rolePagesContainer">
            <div className="roleListArea">
                <table className="roleListTable">
                    <thead>
                    <tr className=" roleListHeader">
                        <td className="roleNameHeader">
                            <span>Role Name</span>
                        </td>
                        <td className="roleActionHeader">
                            <span>Action</span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {roleControlList.length > 0 ? renderRoleControls() : null}
                    </tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editRoleControlId: (id) => dispatch(editRoleControl(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleControlList);
