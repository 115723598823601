import React, { useState } from "react";
import PageTitle from "../../SmallComponents/PageTitle";
import itemsIcon from "../../../assets/item_black.svg";
import MenuList from "../../Menu/MenuList";
import TopBanner from "../../SmallComponents/TopBanner";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux"
import CreateDie from "./CreateDie"
import DieList from "./DieList"
import EditDie from "./EditDieMaster"
import UploadDies from "./UploadDies"


const DieMaster = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("dieList");
    const [isApproval,setIsApproval] = useState(false);

    var history = useHistory();

    const pushBack = (section) => {
        history.push(section);
    };

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "dieList" }];

    var goBackToItemButton = {
        buttonName: "Back",
        className: "goBackButton",
        setSection: pushBack,
        sectionName: "/itemMenu",
    };

    var createItemButton = {
        buttonName: "Create Die",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createDie",
    };

    var uploadDieButton = {
        buttonName: "Upload Die",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "uploadDies",
    };


    const renderSection = (section) => {
        switch (section) {
            case "dieList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={itemsIcon}
                            pageTitle="Die List"
                            buttonList={
                                isLogged.access['Die Master'] === "Create" || isLogged.access['Die Master'] === "Edit" || isLogged.access['Die Master'] === "App/Dec"
                                    ? [uploadDieButton,createItemButton, goBackToItemButton]
                                    : [goBackToItemButton]
                            }
                        />
                        <DieList setSection={(value) => setCurrentSection(value)} setIsApproval={setIsApproval} />
                    </React.Fragment>
                );

            case "createDie":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={itemsIcon} pageTitle="Create Die" buttonList={goBackButton} />
                        <CreateDie setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "itemDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={itemsIcon} pageTitle="Die Detail" buttonList={goBackButton} />
                        {/* <ItemDetail setSection={(value) => setCurrentSection(value)} isApproval={isApproval}/> */}
                    </React.Fragment>
                );

            case "editDie":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={itemsIcon} pageTitle="Edit Die" buttonList={goBackButton} />
                        <EditDie setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

                case "uploadDies":
                    return (
                        <React.Fragment>
                            <PageTitle imgSrc={itemsIcon} pageTitle="Upload Dies" buttonList={goBackButton} />
                            <UploadDies setSection={(value) => setCurrentSection(value)} />
                        </React.Fragment>
                    );

            default:
                return <h1>Page not yet created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(DieMaster);
