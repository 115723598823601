import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import resetIcon from "../../assets/return.svg";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import fetchData from "../../serverCall/fetchData";
import {
  validateMandatory
} from "../CommonFunctions/ValidateFields";

const CreateProductionReport = ({ setSection, isLogged, addTokenToState }) => {
  const prParamList = [
    [

      "plantId",
      "date",
      "shiftNo",
      "shift",
      "machineNo"

    ]
  ];

  const [salesOrderParams, setSalesOrderParams] = useState({

    plantId: {
      inputType: "options",
      value: "",
      hintText: "Plant",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please pick a Plant",
    },
    date: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Date",
      mandatory: true,
      colSpan: 5,
      error: false,
      errorMessage: "Please pick a Order date",
      // mindate: true,
    },
    shiftNo: {
      inputType: "number",
      value: "",
      hintText: "Shift Number",
      mandatory: false,
      placeholder: "1,2,3",
      colSpan: 5,
      error: false,
      errorMessage: "",
    },
    shift: {
      inputType: "options",
      value: "",
      options: [{ optionId: "Day Shift", optionName: "Day Shift" },
      { optionId: "Night Shift", optionName: "Night Shift" }],
      hintText: "Shift",
      mandatory: false,
      colSpan: 5,
      error: false,
      errorMessage: "",
    },
    machineNo: {
      inputType: "text",
      value: "",
      hintText: "Machine Number",
      mandatory: false,
      colSpan: 5,
      error: false,
      errorMessage: "",
    },

  });

  let lineItemsParamsList = [
    // "sectionNo",
    "dieId",
    "dieName",
    "cvt",
    "cuttingLength",
    // "castingNo",
    // "trolleyNo",
    "weightRange",
    "remark"

  ]

  const [lineItemParams, setLineItemParams] = useState({

    // sectionNo: {
    //   inputType: "options",
    //   value: "",
    //   hintText: "Sections ",
    //   mandatory: true,
    //   colSpan: 6,
    //   error: false,
    //   options: [],
    //   errorMessage: "Please select Section",
    // },
    dieId: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Die Number",
      mandatory: true,
      colSpan: 5,
      error: false,
      errorMessage: "",
      disabled: false,
    },
    dieName: {
      inputType: "text",
      value: "",
      hintText: "Die Name",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      disabled: true,
    },
    cvt: {
      inputType: "number",
      value: "",
      hintText: "CVT",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      disabled: true,
    },
    cuttingLength: {
      inputType: "options",
      value: "",
      hintText: "Cutting Length",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please select Cutting Length",
    },
    // onTime:{
    //   inputType: 'dateTimePicker',
    //   value: "",
    //   hintText: "On Time",
    //   error: false,
    //   errorMessage: "Please Select a start session",
    //   colSpan: 5,
    //   mandatory: true
    // },
    // offTime:{
    //   inputType: 'dateTimePicker',
    //   value: "",
    //   hintText: "Off Time",
    //   error: false,
    //   errorMessage: "Please Select a start session",
    //   colSpan: 5,
    //   mandatory: true
    // },
    weightRange: {
      inputType: "number",
      value: "",
      hintText: "Weight Range",
      mandatory: false,
      colSpan: 5,
      error: false,
      errorMessage: "",
      disabled: false,
    },
    remark: {
      inputType: "text",
      value: "",
      hintText: "Remarks",
      mandatory: false,
      colSpan: 5,
      error: false,
      errorMessage: "",
      disabled: false,
    },

  });

  const [lineItemsList, setLineItemsList] = useState([]);
  const [brokenDie, setBrokenDie] = useState([]);
  const [dieList,setDieList]= useState([])

  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    getInformation();
  }, []);

  async function getItems() {
    let itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data: { tab: "Active", type: "Sales" },
      headers: { token: isLogged.accessToken, module: "Items" },
    });

    let itemList = [];
    if (itemData.msg === "success") {

      itemData.asset.forEach((item) => {
        let itemObj = {};
        itemObj.optionId = item.item.itemCode;
        itemObj.optionName = item.item.itemCode
        itemObj.sectionName = item.item.itemName
        // itemObj.optionName =
        //   "  " + item.item.itemCode + " - " + item.item.itemName;
        itemObj.itemId = item.item.itemId;
        itemObj.mrp = item.item.mrp;
        itemObj.categoryId = item.item.categoryId;
        // itemObj.sectionNo = item.item.sectionNo;
        // itemObj.sectionName = item.item.sectionName;

        itemList.push(itemObj);
      });
      return itemList
    }
  }

  async function getDieList() {
    var data = await fetchData({
      requestingPage: "premiumCharges",
      method: "post",
      url: "fetch/die-master",
      data:{isBroken:false},
      headers: { token: isLogged.accessToken, module: "Die Master" },
    });
    if (data.msg === "success") {
      return data.asset
    }

  }

  const getInformation = async () => {
    await checkToken();
    var [plantList, storageLocListFormatted] = await getPlants();
    let sectionList = await getItems();
    let dieList = await getDieList();
    let cuttingLengthList = await getCuttingLengthList();

    var salesParamsCopy = Object.assign(salesOrderParams);
    salesParamsCopy = update(salesParamsCopy, {
      plantId: { options: { $set: plantList } },
    });
    setSalesOrderParams(salesParamsCopy);

    var lineItemParamsCopy = Object.assign(lineItemParams);
    lineItemParamsCopy = update(lineItemParamsCopy, {
      // sectionNo: { options: { $set: sectionList } },
      dieId: { options: { $set: dieList } },
      cuttingLength: { options: { $set: cuttingLengthList } }

    });
    setLineItemParams(lineItemParamsCopy);
    setDieList(dieList)
  };

  const history = useHistory();

  async function checkToken() {
    console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

 




  async function getPlants() {
    var plantsData = await fetchData({
      requestingPage: "plantList",
      method: "get",
      url: "fetch/plants",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    var plantList = [];
    var storageLocList = {};

    if (plantsData.msg === "success") {
      plantsData.asset?.forEach((plant) => {
        var plantObj = {};
        plantObj.optionId = plant.plant.plantId;
        plantObj.optionName = plant.plant.plantName;
        plantObj.address =
          plant.plant.plantName +
          ", " +
          plant.plant.city +
          ", " +
          plant.plant.state +
          (plant.plant.zipCode !== null
            ? ` - ${plant.plant.zipCode}, `
            : ", ") +
          plant.plant.country;
        plantList.push(plantObj);

        storageLocList[plant.plant.plantId] = [];
        plant.storage.forEach((store) => {
          var storeObj = {};
          storeObj.optionId = store.storageId;
          storeObj.optionName = store.storageName;
          storeObj.address =
            store.address +
            ", " +
            plant.plant.city +
            ", " +
            plant.plant.state +
            (plant.plant.zipCode !== null
              ? ` - ${plant.plant.zipCode}, `
              : ", ") +
            plant.plant.country;
          storeObj.gstNo = plant.plant.gstNo;
          storageLocList[plant.plant.plantId].push(storeObj);
        });
      });

    }
    return [plantList, storageLocList];
  }



  const updatePOParameter = ({ paramName, section, key, value }) => {

    if (section === "general") {
      var salesParams = Object.assign(salesOrderParams);
      salesParams = update(salesParams, {
        [paramName]: { [key]: { $set: value } },
      });

      setSalesOrderParams(salesParams);
    } else {
      var lineParamsCopy = Object.assign(lineItemParams);
      lineParamsCopy = update(lineParamsCopy, {
        [paramName]: { [key]: { $set: value } },
      });
      if (paramName === "dieId") {
        if (value) {
          const filteredSections = lineParamsCopy['dieId']?.options.filter(item => { return item.dieId === value });
          // const filteredDieList = lineParamsCopy['dieId']?.options.filter(item => { return item.dieId !== value });
          lineParamsCopy = update(lineParamsCopy, {
            dieName: { value: { $set: filteredSections[0]?.dieName }},
            cvt: { value: { $set: filteredSections[0]?.cvt }},
            // dieId:{ options: { $set: filteredDieList }}
          });
        } else {
          lineParamsCopy = update(lineParamsCopy, {
            sectionName: { value: { $set: "" } },
          });
        }
      }
      setLineItemParams(lineParamsCopy);
    }
  };


  const [lineItemErrors, setLineItemErrors] = useState([]);
  const checkLineItemError = () => {
    var errorList = [];

    var lineItemParamsCopy = Object.assign(lineItemParams);
    lineItemsParamsList.forEach((element) => {
      if (lineItemParamsCopy[element].value !== null && lineItemParamsCopy[element].mandatory === true) {
        lineItemParamsCopy = update(lineItemParamsCopy, {
          [element]: {
            error: {
              $set: !validateMandatory(
                lineItemParamsCopy[element].value.toString()
              ),
            },
          },
        });
      }

      if (lineItemParamsCopy[element].error) {
        errorList.push(lineItemParamsCopy[element].errorMessage);
      }

      setLineItemErrors(errorList);
      setLineItemParams(lineItemParamsCopy);
    });


    if (errorList.length === 0) {
      let cuttingLengthName = lineItemParams.cuttingLength.options.filter(item => item.optionId === lineItemParamsCopy?.cuttingLength?.value)
      var newLineItem = {}
      let dieName = lineItemParams.dieId.options.filter(item => item.optionId === lineItemParamsCopy?.dieId?.value)[0]
      lineItemsParamsList.map(item => {
        return newLineItem[item] = lineItemParamsCopy[item].value || null
      })
      newLineItem["cuttingLengthName"] = cuttingLengthName[0]?.optionName;
      newLineItem["prLineItemId"] = null ;
      setBrokenDie( (prev)=> ( { ...prev,...{[lineItemsList.length]:false}}));
      newLineItem["dieNo"] = dieName.dieNo;
      newLineItem["brokenDate"] = dieName.brokenDate;
      newLineItem["rectifiedDate"] = dieName.rectifiedDate;



      var lineItemsListCopy = lineItemsList;
      lineItemsListCopy.push(newLineItem);

      setLineItemsList(lineItemsListCopy);
      const filteredDieList = lineItemParamsCopy['dieId']?.options.filter(item => { return item.dieId !== lineItemParamsCopy.dieId.value });
      lineItemsParamsList.forEach((element) => {
      if(element==="dieId"){
        lineItemParamsCopy[element].options = filteredDieList;
      }
      lineItemParamsCopy[element].value = "";
      });
      
      setLineItemParams(lineItemParamsCopy);
      

    }
  };

  const [soErrors, setSoErrors] = useState([]);
  const checkErrors = (submitMode) => {

    var errorList = [];
    var paramsCopy = Object.assign(salesOrderParams);


    prParamList[0].forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    if (errorList.length === 0) {
      var lineItemAllClear = true;
      lineItemsParamsList.forEach((item) => {
        if (lineItemParams[item].value !== "") {
          lineItemAllClear = false;
          setLineItemErrors(["Unsaved Item information found"]);
        }
      });

      if (lineItemAllClear) {
        if (lineItemsList.length > 0) {
          setDataSubmitted(false);
          setIsOpen(true);
          prepareDataToSumbit(submitMode);
        } else {
          setLineItemErrors(["Add atleast one die information"]);
        }
      }
    }

    setSalesOrderParams(paramsCopy);
    setSoErrors(errorList);
  };

  const prepareDataToSumbit = (submitMode) => {
    var serverObj = {};

    prParamList[0].forEach((item) => {
      if (salesOrderParams[item].value !== "") {
        serverObj[item] = salesOrderParams[item].value;
      } else {
        serverObj[item] = null;
      }
    });
    serverObj["lineItems"] = lineItemsList;
    submitData(serverObj);
  };

  const submitData = async (serverObj) => {
    var result = await fetchData({
      requestingPage: "createPoItem",
      url: "po-create/production-report",
      method: "post",
      headers: { token: isLogged.accessToken, module: "Production Report" },
      data: serverObj,
    });
    if (result.msg === "success") {
      setRedirect(true);
      setDataSubmitted(true);
      setModalText("Production Order Submitted")
    } else {
      console.log(result);
    }
  };

  async function getCuttingLengthList() {
    let cuttingLengthList = await fetchData({
      requestingPage: "uomList",
      method: "post", url: "fetch/cutting-length",
      headers: { token: isLogged.accessToken, module: "Cutting Length" }
    });

    if (cuttingLengthList.msg === "success") {
      return cuttingLengthList.asset
    } else {
      // console.log(cuttingLengthList);
      return [];
    }
  }
  const getFormateDate =()=>{
    let currentDate = new Date();

    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let dateValue = currentDate.getDate();

    if (month.toString().length === 1) month = `0${month}`;
    if (dateValue.toString().length === 1) dateValue = `0${dateValue}`;

    let formattedDate = `${year}-${month}-${dateValue}`;
    return formattedDate
}

  const handleBrokenDie=(value,index)=>{
    var lineItemCopy = Object.assign(lineItemsList);
      lineItemCopy[index].brokenDate = value? getFormateDate():lineItemCopy[index].brokenDate?lineItemCopy[index].brokenDate:null;     
    lineItemCopy[index].rectifiedDate = !value && lineItemCopy[index].brokenDate ? getFormateDate():null;
    lineItemCopy[index].brokenDie = value;
    setLineItemsList(lineItemCopy);
  }


  const editSubSectionItem = (index, action) => {
    if (action === "edit") {
      var lineItemAllClear = true;

      lineItemsParamsList.forEach((item) => {
        if (lineItemParams[item].value !== "") {
          lineItemAllClear = false;
        }
      });
      if (!lineItemAllClear) {
        var yes = window.confirm(
          "Unsaved data found. Are you sure you want to overwrite it?"
        );
      }
      if (lineItemAllClear || yes) {
        var lineItemCopy = Object.assign(lineItemParams);
        var lineItemsListCopy = lineItemsList.slice();

        var editObj = lineItemsListCopy.splice(index, 1)[0];
        var lineItemsFromServer = [
          // "sectionNo",
          "dieId",
          "dieName",
          "cvt",
          // "castingNo",
          // "trolleyNo",
          "remark",
          "weightRange",
          "cuttingLength"
        ];

        lineItemsFromServer.forEach((itemKey) => {
           if(itemKey==="dieId"){
            lineItemCopy[itemKey].options =dieList
           }
          lineItemCopy[itemKey].value = editObj[itemKey];
        });
        setLineItemParams(lineItemCopy);

        setLineItemsList(lineItemsListCopy);
      }
    }
    if (action === "delete") {
      var lineItemsListCopy = lineItemsList.slice();
      lineItemCopy.dieId.options =dieList;
      lineItemsListCopy.splice(index, 1);
      setLineItemsList(lineItemsListCopy);
    }
  };

  const renderFormElements = ({ elementList, param, section }) => {
    return elementList.map((element) => {
      return (
        <FormElement
          key={element}
          inputType={param[element].inputType}
          value={param[element].value !== null
            ? param[element].value
            : ""
          }
          filterOptions={param[element].filterOptions}
          setInput={(value) => {
            updatePOParameter({
              section,
              paramName: element,
              key: "value",
              value: value,
            });
          }}
          hintText={param[element].hintText}
          mandatory={param[element].mandatory}
          colSpan={param[element].colSpan}
          options={
            param[element].inputType === "options"
              ? param[element].options
              : null
          }
          error={param[element].error}
          rowSpan={
            element === "vendorLogo" || element === "otherBusinessDetails"
              ? param[element].rowSpan
              : null
          }
          placeholder={param[element].placeholder}
          title={param[element].title}
          disabled={param[element].disabled}
          mindate={param[element].mindate}
        />
      );
    });
  };

  const updateLineItems = (key, index, value) => {
    let lineItemCopy = lineItemsList.slice();
    lineItemCopy[index][key] = value
    setLineItemsList(lineItemCopy);
  }

  const RenderTable = () => {
    const lineItemsTableCols =
      [

        // "sectionNo",
        "dieNo",
        "cvt",
        "onTime",
        "offTime",
        "total",
        "castingNo",
        "billetSize",
        "inputKgs",
        "cuttingLength",
        "pieces",
        "outputKgs",
        "trolleyNo",
        "weightRange",
        "efficiencyPercentage",
        "efficiencyKgs",
        "remark",
        "brokenDie",
        "actions"

      ];
    return (

      <table className="createItemPurchaseTable">
        <thead>
          <tr className="createVendorContactsTableHeader">
            {/* <td className="stickyFirstColumn" rowSpan={2}> Section No</td> */}
            <td rowSpan={2}>Sr.No</td>
            <td rowSpan={2}>Die No</td>
            <td rowSpan={2}>CVT</td>
            <td colSpan={3} style={{ textAlign: "center" }}>Time</td>
            <td rowSpan={2}>CNo</td>
            <td colSpan={2} style={{ textAlign: "center" }}>Input</td>
            <td colSpan={3} style={{ textAlign: "center" }}>Output</td>
            <td rowSpan={2}>Trolley No</td>
            <td rowSpan={2}>WT.Range(Kgs)</td>
            <td colSpan={2} style={{ textAlign: "center" }}>Efficiency</td>
            <td rowSpan={2}>Remarks</td>
            <td rowSpan={2}>Broken Die</td>
            <td rowSpan={2}>Actions</td>
          </tr>
          <tr className="createVendorContactsTableHeader">
            <td>On</td> <td>Off</td><td>Total</td>
            <td>Billet Size</td> <td>Kgs</td> <td>C/L</td> <td>Pieces</td> <td>Kgs</td>
            <td>%</td> <td>KG/HR</td>
          </tr>

        </thead>
        <tbody>
          {lineItemsList.map((row, j) => (
            <tr className="createVendorContactsTableRows" key={j}>
              <td>{j+1}</td>
              {lineItemsTableCols.map((key, i) => {
                switch (key) {
                  case "onTime":
                  case "offTime":
                  case "billetSize":
                  case "inputKgs":
                  case "pieces":
                  case "outputKgs":
                  case "efficiencyPercentage":
                  case "efficiencyKgs":
                  case "castingNo":
                  case "trolleyNo":
                    return (
                      <td key={i} style={{ maxWidth: "80px" }}>
                        <input
                          className={
                            lineItemsList[j].unitPriceError
                              ? "createPurchaseCostInputError"
                              : "createPurchaseCostInput"
                          }
                          type="text"
                          value={row[key]}
                          onChange={(e) =>
                            updateLineItems(key, j, e.target.value)
                          }
                        />
                      </td>

                    );
                  case "brokenDie":
                    return(
                      <td key={i} style={{ maxWidth: "80px" }}>
                        <input
                          className={
                            lineItemsList[j].unitPriceError
                              ? "createPurchaseCostInputError"
                              : "createPurchaseCostInput"
                          }
                          type="checkbox"
                          // value={row[key]}
                          checked = {brokenDie[j]}
                          onChange={(e) =>{
                            
                            setBrokenDie((prev)=>({...prev,...{[j]:e.target.checked}}));
                            handleBrokenDie(e.target.checked,j)
                          }
                          }
                        />
                      </td>
                    )
                    // case "sectionNo":
                    // return (
                    //   <td key={i} className="stickyFirstColumn">{row[key]}</td>
                    // )
                  case "total":
                    return (
                      <td key={i}>{
                        row["onTime"] && row["offTime"] ?
                          Number(row["onTime"]) - Number(row["offTime"])
                          : 0
                      }</td>
                    )
                  case "cuttingLength":
                    return (
                      <td key={i} >{row["cuttingLengthName"]}</td>
                    )
                  case "actions":
                    return (
                      <td key={i}>
                        <img
                          alt="Edit Record"
                          className="createVendorContactsAction"
                          src={editIcon}
                          onClick={() => {
                            editSubSectionItem(j, "edit");
                          }}
                        />
                        <img
                          alt="Delete Record"
                          className="createVendorContactsAction"
                          src={deleteIcon}
                          onClick={() => {
                            var reset = window.confirm("Delete record?");
                            if (reset) {
                              editSubSectionItem(j, "delete");
                            }
                          }}
                        />
                      </td>
                    )
                  default:
                    return (
                      <td key={i}>
                        {row[key] !== null ? row[key] : "-"}
                      </td>
                    );

                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
      //   ) : null}
    );
  };


  const renderSubSection = () => {
    let lineItemsParamsList = Object.keys(lineItemParams)
    return (
      <React.Fragment>
        <div className="purchaseOrderIndividualItemsArea" style={{ gridAutoRows: "60px" }}>
          {renderFormElements({
            elementList: lineItemsParamsList,
            param: lineItemParams,
            section: "lineItem",
          })}
          <FormElement
            inputType="addButton"
            value="+ Add"
            colSpan={3}
            setInput={() => {
              checkLineItemError();
            }}
          />
          <div
            style={{
              marginTop: "26px",
              gridColumn: "span 1",
              color: "#666",
              background: "rgb(230,230,230)",
              cursor: "pointer",
            }}>
            <img
              style={{ padding: "10px", width: "1rem", height: "1rem" }}
              src={resetIcon}
              alt="Reset"
              onClick={() => {
                var yes = window.confirm("Clear Input data?");

                if (yes) {
                  var lineItemParamEmpty = Object.assign(lineItemParams);
                  lineItemsParamsList.forEach((item) => {
                    lineItemParamEmpty[item].value = "";
                    lineItemParamEmpty[item].error = false;
                  });
                  setLineItemParams(lineItemParamEmpty);
                  setLineItemErrors([]);
                }
              }}
            />
          </div>
        </div>

        <RenderTable />
      </React.Fragment>
    );
  };

  const submitOkClick = () => {
    setIsOpen(false);
    if (soErrors.length === 0 && redirect) {
      setSection("productionReportList");
    }
    setModalText("Uploading form. Please wait...");
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState(
    "Submitting Production Report. Please wait.."
  );

  const renderErrorMessage = () => {
    if (soErrors.length > 0) return soErrors[0];
    else if (lineItemErrors.length > 0) return lineItemErrors[0];
  };

  return (

    <React.Fragment>
      <style>
        {
          `table,tr,td,th{
                border-collapse:collapse;
                border:1px solid white;
              }
              `
        }
      </style>
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
      <div className="formArea">
        <div
          style={{
            // width: "1100px",
            width: "fit-content",
            margin: "0 auto 4rem",
            // padding: "3rem 3rem 1rem 3rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex" }}>
            <div
              style={{ gridTemplateRows: "repeat(2, 4rem)" }}
              className={
                "createSalesOrderGrid"
              }>
              {renderFormElements({
                elementList: prParamList[0],
                param: salesOrderParams,
                section: "general",
              })}

            </div>
          </div>
          <div className="purchaseOrderSubSectionArea">
            {renderSubSection()}
          </div>
          <div className="poNetTotalSurchargeDiv">
            <div className="purchaseInstruction">
              <div>
                No Items:&nbsp;
                <span>{lineItemsList.length}</span>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

          <button
            className="cancelButton"
            onClick={() => {
              var reset = window.confirm("Do you want to reset all fields?");
              if (reset) {
                var salesOrderParamsCopy = Object.assign(salesOrderParams);


                prParamList[0].forEach((element) => {
                  salesOrderParamsCopy[element].value = "";
                  salesOrderParamsCopy[element].error = false;
                });
                setSalesOrderParams(salesOrderParamsCopy);

                var lineItemParamsCopy = Object.assign(lineItemParams);
                lineItemsParamsList.forEach((element) => {
                  lineItemParamsCopy[element].value = "";
                  lineItemParamsCopy[element].error = false;
                });
                setLineItemParams(lineItemParamsCopy);

                setLineItemsList([]);
                setSoErrors([]);
                setLineItemErrors([]);
              }
            }}>
            Reset Fields
          </button>
          <button
            className="submitButton"
            onClick={() => {
              setSoErrors([]);
              setLineItemErrors([]);
              checkErrors("Approval Pending");
            }}>
            Submit
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductionReport);
