import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";

import editIcon from "../../../assets/edit.svg";
import downIcon from "../../../assets/down.svg";
import upIcon from "../../../assets/up.svg";
import editIconDisabled from "../../../assets/edit_disabled.svg";
import { editItemSalesPriceObj } from "../../../redux/Item/ItemInfoActions";

const ItemSalesPriceList = ({ setSection, isLogged, addTokenToState, editSaleObj }) => {
    const history = useHistory();
    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);

    const [salesPriceList, setSalesPriceList] = useState([]);

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getSalesPriceList();
    }

    async function checkToken() {
        console.log("Checking token");
        //   console.log(isLogged.accessToken);
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getSalesPriceList() {
        var data = await fetchData({
            requestingPage: "salesPriceList",
            method: "post",
            url: "fetch/item-sales-price-list",
            headers: { token: isLogged.accessToken,module:"Item Sales Price" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setSalesPriceList(data2);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    const editSalesListCollapse = (index) => {
        var salesPriceListCopy = salesPriceList.slice();
        salesPriceListCopy.forEach((purchase, j) => {
            if (j === index) {
                salesPriceListCopy[j].collapse = !purchase.collapse;
            } else {
                salesPriceListCopy[j].collapse = true;
            }
        });
        setSalesPriceList(salesPriceListCopy);
    };

    const RenderSubTable = ({ data }) => {
        return (
            <td colSpan="8" className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Item Code</td>
                            <td className="vendorListInnerTableHeading">Item Name</td>
                            <td className="vendorListInnerTableHeading">Weight From (gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To (gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight (gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight (gms)</td>
                            <td className="vendorListInnerTableHeading">MRP</td>
                            <td className="vendorListInnerTableHeading">Purchase Cost</td>
                            <td className="vendorListInnerTableHeading">Tax Type</td>
                            <td className="vendorListInnerTableHeading">Tax Percentage (%)</td>
                            <td className="vendorListInnerTableHeading">Net Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.itemCode}>
                                    <td className="vendorListInnertableInfo">{row.itemCode}</td>
                                    <td className="vendorListInnertableInfo">{row.itemName}</td>
                                    <td className="vendorListInnertableInfo">{row.from? row.from : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.to? row.to : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight? row.netWeight: " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight? row.grossWeight: " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.mrp}</td>
                                    <td className="vendorListInnertableInfo">{isNaN(parseFloat(row.cost)) ? "-" : parseFloat(row.cost)}</td>
                                    <td className="vendorListInnertableInfo">{row.taxType !== null ? row.taxType : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.taxPercentage !== null ? row.taxPercentage : "-"}</td>
                                    <td className="vendorListInnertableInfo">
                                        {isNaN(parseFloat(row.price) * ((parseFloat(row.taxPercentage) + 100) / 100))
                                            ? "-"
                                            : (parseFloat(row.price) * ((parseFloat(row.taxPercentage) + 100) / 100)).toFixed(2)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    const renderSalesPriceList = () => {
        return salesPriceList.map((salesPrice, i) => {
            var oldDate = new Date(salesPrice.priceApplicableDate);
            var now = new Date();

            var editable = oldDate.setHours(0, 0, 0, 0) >= now.setHours(0, 0, 0, 0);
            return (
                <React.Fragment>
                    <tr
                        className={salesPrice.collapse ? "vendorList" : "vendorListExpanded"}
                        key={salesPrice.i}
                        onClick={() => editSalesListCollapse(i)}
                    >
                        <td className="vendorListInfo">{salesPrice.priceApplicableDate}</td>
                        <td className="vendorListInfo">
                            {salesPrice.items[0].updatedTime
                                ? new Date(salesPrice.items[0].updatedTime).toISOString().slice(0, 10)
                                : new Date(salesPrice.items[0].createTime).toISOString().slice(0, 10)}
                        </td>
                        <td className="vendorListInfo">
                            {salesPrice.items[0].updatedBy ? salesPrice.items[0].updatedBy : salesPrice.items[0].createdBy}
                        </td>
                        <td className="vendorListInfo">{salesPrice.categoryName}</td>
                        <td className="vendorListInfo">{salesPrice.items[0].subCategoryName}</td>
                        <td className="vendorListAddress">{salesPrice.brandName}</td>
                        <td className="vendorListAddress">{salesPrice.priceType}</td>
                        <td className="vendorListInfoActions">
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={editable ? (isLogged.access["Item Sales Price"] === "Edit" ? editIcon : editIconDisabled) : editIconDisabled}
                                title={
                                    editable
                                        ? isLogged.access["Item Sales Price"] === "Edit"
                                            ? "Edit purchase cost"
                                            : "No Edit Access"
                                        : "Can't edit older records"
                                }
                                style={
                                    editable
                                        ? isLogged.access["Item Sales Price"] === "Edit"
                                            ? { cursor: "pointer" }
                                            : { cursor: "not-allowed" }
                                        : { cursor: "not-allowed" }
                                }
                                onClick={(e) => {
                                    if (editable) {
                                        if (isLogged.access["Item Sales Price"] === "Edit") {
                                            e.stopPropagation();
                                            // editVendorId(salesPrice.vendorId);
                                            setSection("editSalesPrice");
                                            editSaleObj({
                                                priceApplicableDate: salesPrice.priceApplicableDate,
                                                subCategoryId: salesPrice.subCategoryId,
                                                brandId: salesPrice.brandId,
                                                priceTypeId: salesPrice.priceTypeId,
                                            });
                                        }
                                    }
                                }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={salesPrice.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editSalesListCollapse(i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!salesPrice.collapse ? <RenderSubTable data={salesPrice.items} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    return (
        <React.Fragment>
            <div className="vendorListArea">
                <table className="vendorListTable">
                    <thead>
                        <tr className="vendorListHeader" key={"salePriceTitle"}>
                            <td className="vendorListHeading">Price Applicable Date</td>
                            <td className="vendorListHeading">Created / Edited Date</td>
                            <td className="vendorListHeading">Created / Edited By</td>
                            <td className="vendorListHeading">Category</td>
                            <td className="vendorListHeading">Sub Category</td>
                            <td className="vendorListHeading">Brand Name</td>
                            <td className="vendorListHeading">Price Type</td>
                            <td className="vendorListHeadingActions">Actions</td>
                        </tr>
                    </thead>
                    <tbody>{salesPriceList.length > 0 ? renderSalesPriceList() : null}</tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editSaleObj: (salePriceObj) => dispatch(editItemSalesPriceObj(salePriceObj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemSalesPriceList);
