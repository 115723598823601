import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import fetchData from "serverCall/fetchData";
import FetchNewToken from "serverCall/FetchNewToken";
import CreateEditModal from "components/SmallComponents/CreateEditModal";

const CreateSectionMaster = ({
    // props
    setSection,

    // state
    // roleInfo,
    isLogged,

    // dispatch
    // editUom,
    addTokenToState,
  }) => {
    const initialSectionMaster = {sectionNo:"",sectionName:"",dimension:"",uomId:"",premiumId:"",optionId:""};
  const [sectionMasterList, setSectionMasterList] = useState([]);
  const [premiumChargesList, setPremiumChargesList] = useState([]);
  const [newSectionMaster, setNewSectionMaster] = useState({...initialSectionMaster});
    const history = useHistory();
  const checkToken=useCallback(async() =>{
      console.log("Checking token");
      //   console.log(isLogged.accessToken);
      const token2 = await FetchNewToken(isLogged.accessToken);
      if (token2 === "expired") {
          history.push("/");
      } else if (token2 !== isLogged.accessToken) {
          console.log("New Token");
          addTokenToState(isLogged.employeeId, token2);
      } else {
          console.log("Token not changed");
      }
  },[isLogged,addTokenToState,history]);

  const getSectionMaster=useCallback(async()=> {
      var data = await fetchData({
          requestingPage: "sectionMaster",
          method: "post",
          url: "fetch/section-master",
          headers: { token: isLogged.accessToken,module:"Section Master" },
      });
      if (data.msg === "success") {
          setSectionMasterList(data.asset);
      } else {
          console.log(data);
      }

  },[isLogged]);

  const getPremiumCharges=useCallback(async()=> {
      var data = await fetchData({
          requestingPage: "premiumCharges",
          method: "get",
          url: "fetch/premium-charges",
          headers: { token: isLogged.accessToken,module:"Section Master" },
      });
      if (data.msg === "success") {
          setPremiumChargesList(data.asset);
      } else {
          console.log(data);
      }

  },[isLogged]);

  const getInformation=useCallback(async()=> {
      await checkToken();
      await getSectionMaster();
      await getPremiumCharges();
    },[checkToken,getSectionMaster,getPremiumCharges]);

    useEffect(() => {
        getInformation();
    }, [getInformation]);

    const submitSectionMaster=async()=>{
        setDataSubmitted(false);
        let submitted = await fetchData({
            requestingPage: "sectionMaster",
            method: "POST",
            data: newSectionMaster,
            url: "create/section-master",
            headers:{token :isLogged.accessToken,module: "Section Master"}
        });

        if(submitted.msg==="success"){
            setModalText("Section Master Created Successfully");
            setNewSectionMaster(initialSectionMaster);
            getSectionMaster();
        }else{
            setModalText(submitted.desc);
        }
        setDataSubmitted(true);
    }
    const renderSectionMasterList = (list) => {
      return(
        list.map((row,index)=>{
          return(
            <tr className='uomListData' key={index}>
              <td className="uomSymbolData">{row.sectionNo}</td>
              <td className='uomSymbolData'>{row.sectionName}</td>
              <td className='uomSymbolData'>{row.premiumId}</td>
            </tr>
          )
        })
      )
    };

    const renderOptions = (options) => {
        return (
            <React.Fragment>
                {options.map((option) => {
                    return (
                        <option
                            key={option.optionId}
                            // style={{ height: "3rem", margin: "10px", padding: "10px 30px" }}
                            value={option.optionId}
                            selected={option.optionId === newSectionMaster.premiumId ? true : false}
                        >
                            {option.optionName}
                        </option>
                    );
                })}
            </React.Fragment>
        );
    };

    const renderCreateSectionMaster = () => {
        return (
            <tr className="uomListData">
                <td className="uomSymbolData2">
                    <input
                        className="createUom"
                        value={newSectionMaster.sectionNo}
                        onChange={(e) => setNewSectionMaster({ ...newSectionMaster, sectionNo: e.target.value })}
                        autoFocus
                    />
                </td>
                <td className="uomSymbolData2">
                    <input
                        className="createUom"
                        value={newSectionMaster.sectionName}
                        onChange={(e) => setNewSectionMaster({ ...newSectionMaster, sectionName: e.target.value })}
                    />
                </td>
                <td className="uomSymbolData3" style={{minWidth:"10rem"}}>
                <div style={{flex:"1 0.5 65%"}}>
                    <select
                        defaultValue={""}
                            onChange={(e) => {
                                setNewSectionMaster({ ...newSectionMaster, premiumId: e.target.value })
                            }}
                        >
                            <option value="" disabled>Choose a rate..</option>
                            {renderOptions(premiumChargesList)}
                    </select>
                    </div>
                    <button
                        style={{width:"7rem"}}
                        className="cancelRoleButton"
                        onClick={() => {
                            setNewSectionMaster({...initialSectionMaster});
                        }}
                    >
                        Cancel
                    </button>
                    <button
                    style={{width:"7rem"}}
                        id="SubmitCreate"
                        className="createRoleButton"
                        onClick={() => {
                            if(newSectionMaster.sectionNo && newSectionMaster.sectionName)
                            submitSectionMaster();
                        }
                      }
                    >
                        Add
                    </button>
                </td>
                {/* <td className="uomSymbolData3" style={{minWidth:"10rem"}}>
                    <div style={{flex:"1 0.5 65%"}}>
                    <SearchableDropDown 
                        options={uomOptionsList}
                        selected={newSectionMaster.uomId}
                        multiple={false}
                        isClearable={true}
                        setInput={(value)=>setNewSectionMaster({...newSectionMaster,uomId:value})}
                    />
                    </div>
               </td> */}
               {/* <td className="uomSymbolData2" >
                    
                </td> */}
            </tr>
        );
    };

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Uploading data. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading data. Please wait...");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="uomListArea">
                <table className="uomListTable">
                    <thead>
                    <tr className=" uomListHeader">
                        <td className="uomSymbolHeader">
                            <span>Section No</span>
                        </td>
                        <td className="uomSymbolHeader">
                            <span>Section Name</span>
                        </td>
                        <td className="uomSymbolHeader">
                            <span>Premium Id</span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {renderCreateSectionMaster()}
                    {renderSectionMasterList(sectionMasterList)}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSectionMaster);
