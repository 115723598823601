import React, { useState, useEffect } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import dashboard from "../../assets/dashboard.svg";
import "../styles/Dashboard.css";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { useHistory } from "react-router-dom";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import {
  dateFormatting,
  addDaysToDate,
  getIndianCurrency,
} from "../CommonFunctions/utils";

const SalesDashboard = ({ isLogged, addTokenToState }) => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState("Sales Order");
  const [barData, setBarData] = useState([]);
  const [pieChartData1, setPieChartData1] = useState([]);
  const [pieChartData2, setPieChartData2] = useState([]);
  const [cardData1, setCardData1] = useState([]);
  const [itemTrendList,setItemTrendList]=useState([]);
  const [queryParams, setQueryParams] = useState({
    showChartBy: {
      inputType: "options",
      value: "MM-YY",
      mandatory: true,
      options: [
        { optionId: "DD-MM-YY", optionName: "Show By Days" },
        { optionId: "MM-YY", optionName: "Show By Months" },
        { optionId: "YYYY", optionName: "Show By Years" },
      ],
      error: false,
      hintText: "Show Chart By",
      isClearable: false,
    },
    fromDate: {
      inputType: "dateFromEditPage",
      value: "04-01-" + new Date().getFullYear(),
      hintText: "From Date",
      mandatory: true,
      error: false,
      mindate: true,
      maxdate: true,
    },
    toDate: {
      inputType: "dateFromEditPage",
      value: new Date(),
      hintText: "To Date",
      mandatory: true,
      error: false,
      maxdate: true,
    },
    plantId: {
      inputType: "options",
      value: "",
      options: isLogged.plantOptions,
      hintText: "Plant Name",
      mandatory: false,
      error: false,
    },
    customerId: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Customer Name",
      mandatory: false,
      error: false,
    },
    items:{
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "Items",
      mandatory: false,
      error: false,
    }
  });

  const tablist = [
    { id: "Sales Order", name: "Sales Orders" },
    { id: "Sales Invoice", name: "Sales Invoices" },
    { id: "Sales Return", name: "Sales Returns" },
  ];
  useEffect(() => {
    getInformation();
  }, []);

  async function getInformation() {
    await checkToken();
    let [itemList] = await getItems();
    let queryParamsCopy = Object.assign(queryParams);
    queryParamsCopy = update(queryParamsCopy, {
      items: { options: { $set: itemList } },
    });
    let [options] = await getCustomers();
    queryParamsCopy = update(queryParamsCopy, {
      customerId: { options: { $set: options } },
    });
    setQueryParams(queryParamsCopy);
  }

  async function getItems() {
    let itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data:{tab:"Active"},
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    let itemList = [];
    if (itemData.msg === "success") {

      itemData.asset.forEach((item) => {
        let itemObj = {};
        itemObj.optionId = item.item.itemId;
        itemObj.optionName =
          "  " + item.item.itemCode + " - " + item.item.itemName;
        itemObj.itemCode = item.item.itemCode;
        itemList.push(itemObj);
      });

      return [itemList];
    }
  }

  async function checkToken() {
    console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getCustomers() {
    let data = await fetchData({
      requestingPage: "getParents",
      method: "get",
      url: "fetch/customer-list",
      headers: { token: isLogged.accessToken, module: "Customer" },
    });
    if (data.msg === "success") {
      let options = [];
      data.asset.forEach((parent) => {
        let newParent = {
          optionId: parent.customerId,
          optionName: parent.customer,
        };
        options.push(newParent);
      });
      return [options]
    }
  }

  async function getSoData(qryParams) {
    let data1 = await fetchData({
      requestingPage: "dashboard",
      method: "post",
      url: "so-fetch/sales-order-dashboard",
      headers: { token: isLogged.accessToken, module: "Sales Dashboard" },
      data: qryParams,
    });
    if (data1.msg === "success") {
      setBarData(data1.asset.barData);
      setCardData1([
        {
          type: "numbers",
          title: "Sale Order Count",
          value: getIndianCurrency(data1.asset.totalApprovedSoCount, false),
        },
        {
          type: "numbers",
          title: "Total Sale Order Amount",
          value: getIndianCurrency(data1.asset.totalSalesAmount).split('.')[0],
        },
      ]);
      setItemTrendList(data1.asset.itemTrendList);
      data1.asset.pieChartData1.map((obj) => {
        switch (obj["id"]) {
          case "Not Sent":
            obj["color"] = "rgb(178,38,58,0.75)";
            break;
          case "Sent":
            obj["color"] = "rgb(127,201,127)";
            break;
          case "Partially Sent":
            obj["color"] = "rgb(255,255,51)";
            break;
          default:
            obj["color"] = "yellow";
        }
        return obj;
      });
      setPieChartData1(data1.asset.pieChartData1);
      data1.asset.pieChartData2.map((obj) => {
        switch (obj["id"]) {
          case "Invoiced":
            obj["color"] = "rgb(127,201,127)";
            break;
          case "Not Invoiced":
            obj["color"] = "rgb(178,38,58,0.75)";
            break;
          default:
            obj["color"] = "yellow";
        }
        return obj;
      });
      setPieChartData2(data1.asset.pieChartData2);
    } else {
      console.log(data1);
    }
  }

  async function getSoReturnData(qryParams) {
    let data2 = await fetchData({
      requestingPage: "dashboard",
      method: "post",
      url: "so-fetch/sales-return-dashboard",
      headers: { token: isLogged.accessToken, module: "Sales Dashboard" },
      data: qryParams,
    });
    if (data2.msg === "success") {
      setBarData(data2.asset.barData);
      setCardData1([
        {
          type: "numbers",
          title: "Sales Return Count",
          value: getIndianCurrency(
            data2.asset.totalApprovedSalesReturnCount,
            false
          ),
        },
        {
          type: "numbers",
          title: "Sales Return Total Amount",
          value: getIndianCurrency(data2.asset.totalSalesReturnAmount).split('.')[0],
        },
      ]);
    } else {
      console.log(data2);
    }
  }
  async function getSoInvoiceData(qryParams) {
    let data3 = await fetchData({
      requestingPage: "dashboard",
      method: "post",
      url: "so-fetch/sales-invoice-dashboard",
      headers: { token: isLogged.accessToken, module: "Sales Dashboard" },
      data: qryParams,
    });
    if (data3.msg === "success") {
      setBarData(data3.asset.barData);
      setCardData1([
        {
          type: "numbers",
          title: "Sales Invoice Count",
          value: getIndianCurrency(
            data3.asset.totalApprovedSalesInvoiceCount,
            false
          ),
        },
        {
          type: "numbers",
          title: "Sales Invoice Total Amount",
          value: getIndianCurrency(data3.asset.totalSalesInvoiceAmount).split('.')[0],
        },
      ]);
    } else {
      console.log(data3);
    }
  }
  useEffect(() => {
    let saleCopy = Object.assign(queryParams);
    let fromDate = dateFormatting(saleCopy.fromDate.value),
      // converting date and adding 1 date for between operator in backend
      toDate = dateFormatting(addDaysToDate(saleCopy.toDate.value, 1));

    let qryParams = {
      dates: [fromDate, toDate],
      plantId: saleCopy.plantId.value,
      customerId: saleCopy.customerId.value,
      showChartBy: saleCopy.showChartBy.value,
      itemIds: saleCopy.items.value.map((it)=>it?.value) // since it is multi select extract only values
    };
    if (fromDate.length === 10 && toDate.length === 10) {
      if (selectedTab === "Sales Order") {
        getSoData(qryParams);
      } else if (selectedTab === "Sales Return") {
        getSoReturnData(qryParams);
      } else if (selectedTab === "Sales Invoice") {
        getSoInvoiceData(qryParams);
      }
    }
  }, [queryParams, selectedTab]);
  const renderItemTrendList = () => {
    return (
      <div
        className="dashboardCard itemTrendList">
        <div className="dashboardCardHeader">
          <span className="dashboardCardTitle">Items Sale Count</span>
        </div>
        <div className="dashboardAlertBody">
          {itemTrendList.length > 0 ? (
            itemTrendList.map((stock, ind) => {
              return (
                <div className="alertStockLine" key={ind}>
                  <span style={{ flex: "1 0" }}>{stock.itemName}</span>
                  <span>{stock.saleCount}</span>
                </div>
              );
            })
          ) : (
            <div className="noRecordsYet">No Item Has been Sold</div>
          )}
        </div>
      </div>
    );
};

  const renderCards = (data) => {
    return data?.map((item,ind) => {
      return (
        <div
          className="dashboardCard"
          key={item.title}
          style={{ height: "120px", width: "250px" }}>
          <div className="dashboardCardHeader">
            <span className="dashboardCardTitle">{item.title}</span>
          </div>
          <div className="dashboardCardBody">
            <div className="dashSingle">
              <div>{item.value}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  const updateSaleOrderParameters = ({ param, paramName, key, value }) => {
    let paramsCopy = Object.assign(param);
    paramsCopy = update(paramsCopy, {
      [paramName]: { [key]: { $set: value } },
    });
    setQueryParams(paramsCopy);
  };

  const renderFormElements = ({ elementList, params }) => {
    return (
      <div className="dashboardCard dashboardParams">
        {elementList.map((item,ind) => {
          return (
            <div key={ind}>
              <FormElement
                key={item}
                inputType={params[item].inputType}
                value={params[item].value}
                setInput={(value) => {
                  updateSaleOrderParameters({
                    param: params,
                    paramName: item,
                    key: "value",
                    value: value,
                  });
                }}
                hintText={params[item].hintText}
                mandatory={params[item].mandatory}
                colSpan={params[item].colSpan}
                options={
                  params[item].inputType === "options" ||
                  params[item].inputType === "multiSelect"
                    ? params[item].options
                    : null
                }
                error={params[item].error}
                isClearable={params[item].isClearable}
                maxdate={params[item].maxdate}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const RenderTabList = () => {
    return tablist.map((tab) => {
      return (
        <div
          key={tab.id}
          className={
            selectedTab === tab.id
              ? "createVendorIndividualTabSelected"
              : "createVendorIndividualTab"
          }
          onClick={(e) => setSelectedTab(tab.id)}>
          {tab.name}
        </div>
      );
    });
  };

  const theme = {
    axis: {
      domain: {
        line: {
          stroke: "rgb(11,87,180)", //rgb(87 118 232) //    color:rgb(88, 80, 245)
          strokeWidth: 2,
        },
      },
    },
  };
  const renderBarGraph = (data, indexBy, key, title) => {
    return (
      <div
        className="dashboardCard"
        style={{
          height: "390px",
          width: "32.8rem",
          gridColumn: `1/span 3`,
          alignSelf: 'start',
          gridRow: `3 / span 2`,
          padding: "2rem",
        }}>
        <div className="dashboardGraphBody">
          <ResponsiveBar
            data={data}
            keys={key}
            indexBy={indexBy}
            margin={{ top: 10, right: 0, bottom: 50, left: 65 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            tooltip={({ value, indexValue }) => {
              return (
                <div className="dashBoardToolTip">
                  {indexValue}
                  <br />
                  {getIndianCurrency(value)}
                  <br />
                </div>
              );
            }}
            theme={theme}
            colors={["rgb(11,87,180)"]}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 1,
              tickPadding: 5,
              tickRotation: -90,
              legend: "",
              legendPosition: "middle",
              legendOffset: 52,
            }}
            axisLeft={{
              tickSize: 1,
              tickPadding: 4,
              tickRotation: -1,
              legend: "Sales Amount",
              legendPosition: "middle",
              legendOffset: -60,
              format: (value) => `   ₹${Number(value) / 100000} L`,
            }}
            labelSkipWidth={12}
            labelSkipHeight={0}
            label={(d) => null}
            layers={["axes", "bars", "markers", "legends"]}
          />
        </div>
      </div>
    );
  };

  const renderPieChart = (pieData, arcColors, title, columnIndex) => {
    return (
      <div
        className="dashboardCard"
        style={{
          height: "390px",
          alignSelf: 'self-start',
          gridColumn: ` ${columnIndex} / span 1`,
          gridRow: `auto / span 1`,
        }}>
        <div className="dashboardCardHeader">
          <span className="dashboardCardTitle">{title}</span>
        </div>
        <div className="dashboardGraphBody">
          <ResponsivePie
            data={pieData}
            margin={{ top: 10, right: 80, bottom: 100, left: 80 }}
            innerRadius={0.5}
            padAngle={1}
            cornerRadius={6}
            activeOuterRadiusOffset={15}
            arcLinkLabelsSkipAngle={5}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={5}
            colors={arcColors}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 18,
                itemWidth: 80,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    );
  };

  const renderSubSection = () => {
    switch (selectedTab) {
      case "Sales Order":
        return (
          <>
            <div className="dashboardGrid">
            {renderFormElements({
              elementList: Object.keys(queryParams),
              params: queryParams,
            })}
              <div className="cardsContainer">{renderCards(cardData1)}</div>
              {renderItemTrendList()}
              {renderBarGraph(
                barData,
                "soCreateDate",
                ["totalAmount"],
                "Sales Order"
              )}
              {renderPieChart(
                pieChartData1,
                { datum: "data.color" },
                "Outward Status",
                "1"
              )}
              {renderPieChart(
                pieChartData2,
                { datum: "data.color" },
                "Invoice Status",
                "2"
              )}
            </div>
          </>
        );
      case "Sales Return":
        return (
          <>
            <div className="dashboardGrid">
            {renderFormElements({
              elementList: Object.keys(queryParams),
              params: queryParams,
            })}
              <div className="cardsContainer">
                {renderCards(cardData1)}
                
                </div>
              {renderBarGraph(
                barData,
                "soReturnCreateDate",
                ["totalAmount"],
                "Sales Order Return"
              )}
            </div>
          </>
        );
      case "Sales Invoice":
        return (
          <>
            <div className="dashboardGrid">
            {renderFormElements({
              elementList: Object.keys(queryParams),
              params: queryParams,
            })}
              <div className="cardsContainer">
                {renderCards(cardData1)}
                
                </div>
              {renderBarGraph(
                barData,
                "soInvoiceCreateDate",
                ["totalAmount"],
                "Sales Invoice"
              )}
            </div>
          </>
        );
      default:
        return <span>Page Yet To Be Built</span>;
    }
  };
  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Dashboard" selectedSubMenu="Sales Dashboard" />
        <div className="detailsContainer">
          <TopBanner />
          <PageTitle imgSrc={dashboard} pageTitle="Sales Dashboard" />
          <span
            className="hintText"
            style={{ color: "GrayText", fontSize: "13px",marginLeft:"40px",marginBottom:"0px" }}>
            *Dashboard data is mainly based on Approved Orders
          </span>
          <div className="poListTabs">
            <RenderTabList />
          </div>
          {renderSubSection()}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { isLogged: state.isLogged };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SalesDashboard);
