import React,{useState,useEffect,useMemo} from "react";
import { connect } from "react-redux";
import downIcon from "../../assets/down.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import { handleSort } from "../CommonFunctions/utils";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import SortUpGreen from "../../assets/SortUpGreen.svg";
import Pagination from "../CommonFunctions/pagination";
import { editOrViewProcessLogId } from "../../redux/PurchaseOrder/PoInfoActions";
const ProcessLogList=({setSection,
    setApproval,
    // state
    isLogged,
    // dispatcher
    addTokenToState,
    editOrViewProcessLogId
  })=>{
    const [processLogList,setProcessLogList] =useState([]);
    const [currentPage,setCurrentPage]=useState(1);
    const [PageSize,setRowsPerPage]=useState(10);
    const [loading, setLoading] = useState(true);
    const [noRecords,setNoRecords] = useState(false);
    const [plantId,setPlantId] = useState("");
    
    const processLogCurrentPage = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return processLogList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,processLogList,PageSize]);
      
      
      useEffect(() => {
        getInformation();
      }, []);

      async function getInformation() {
        await checkToken();
        await getProcessLogList();
      }
    
      const history = useHistory();
      
      async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
          history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          console.log("New Token");
          addTokenToState(isLogged.employeeId, token2);
        } else {
          console.log("Token not changed");
        }
      }
      async function getProcessLogList(){
        let data = await fetchData({
          url: 'production-fetch/process-log-list',
          requestingPage: "workOrder",
          method: "post",
          headers: {token: isLogged.accessToken,module: "Process Log"}
        })

        if(data.msg=='success'){
          setLoading(false)
          setProcessLogList(data.asset)
        }else{
          console.log(data)
          setProcessLogList([]);
        }
      }
    const CommonColumns=({data})=>{
      return(
        <React.Fragment>
        <td className="vendorListHeading stickyFirstColumn">Process Log Id</td>
        <td className="vendorListHeading">Work Order Id</td>
        {/* <td className="vendorListHeading">Assigned Quantity</td>
        <td className="vendorListHeading">Produced Quantity</td> */}
        <td className="vendorListHeading">Status</td>
        </React.Fragment>
      )
    }
    const access= isLogged.access['Process Log']
      const renderProcessLogList=()=>{
        return(
        processLogCurrentPage.map((row)=>
           { const statusColor = row.processLogStatus == 'In Progress' ? '#ff9800' : '#43a047'
            return  (<tr className="vendorList" key={Math.random()}>
            <td className="vendorListInfo">{row.processLogNo}</td>
            <td  className="vendorListInfo">{row.workOrderNo}</td>
            {/* <td  className="vendorListInfo">{row.assignedQuantity}</td>
            <td  className="vendorListInfo">{row.producedQuantity}</td> */}
            <td className="vendorListInfo" style={{color: statusColor,fontWeight:500}}>{row.processLogStatus?.toUpperCase()}</td>
            <td  className="vendorListInfoActions"> 
            <img
                  alt="Edit Record"
                  className="vendorActionImages"
                  src={
                    access === "Edit" || access === "App/Dec"
                      ? editIcon
                      : editIconDisabled
                  }
                  title={
                    access === "Edit" || access === "App/Dec"
                      ? "Edit Process Log"
                      : "Access Denied"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (access === "Edit" || access === "App/Dec") {
                      setSection("editProcessLog");
                      editOrViewProcessLogId(row.processLogId);
                    }
                  }}
                  style={
                    access === "Edit" || access === "App/Dec"
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed" }
                  }
                />
          </td>
            </tr>)}
          )
        )
      }
    return(
        <React.Fragment>
             <div className="vendorListArea">
                <table className="vendorListTable">
                  <thead>
                    <tr className="vendorListHeader"  style={{zIndex:1}}>
                    <CommonColumns data={processLogCurrentPage}/>
                    <td className="vendorListHeadingActions">Actions</td>
                    </tr>
                  </thead>
                  <tbody>
                    {processLogCurrentPage.length > 0 ? renderProcessLogList() : null}
                  </tbody>
                </table>
              </div>
              {processLogList.length == 0 ? (
              <div className="noRecordsYet">No records found</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
                <Pagination 
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={processLogList.length}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
        </React.Fragment>
    )
}

const mapStateToProps=(state)=>{
    return{
        isLogged: state.isLogged,
        poInfo: state.poInfo
    }
}

const mapDispatchToProps=(dispatch)=>{

  return  {
    editOrViewProcessLogId: (value)=>(dispatch(editOrViewProcessLogId(value)))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProcessLogList);