import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import viewIcon from "../../assets/view.svg";
import { viewIdnPendingId } from "../../redux/Store/StoreInfoActions";
import createProdIcon from "../../assets/sheets.svg";
import { pendingIdn } from "../../redux/Store/StoreInfoActions";
import FormElement from "../SmallComponents/FormElement";
import SortUpGreen from "../../assets/SortUpGreen.svg";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import { handleSort } from "../CommonFunctions/utils";
import Pagination from '../CommonFunctions/pagination';

const IdnPendingList =({ setSection,isLogged,storeInfo,addTokenToState,viewIdnPendingId,pendingIdn}) => {


    const [loading, setLoading] = useState(true);
    const [noApprovedMrsRecords, setNoApprovedMrsRecords] = useState(false);
    const [MrsApprovedList, setMrsApprovedList] = useState([]);
    const [plantId,setPlantId] = useState('');
    const [currentPage,setCurrentPage]=useState(1);
    const [PageSize,setRowsPerPage]=useState(10);
    const MrsApprovedCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return MrsApprovedList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,MrsApprovedList,PageSize]);
    useEffect(() => {
        getInformation();
    }, [plantId]);

    async function getInformation() {
        await checkToken();
        await getMrsApprovedList();

    }

    const history = useHistory();
    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }
   

    async function getMrsApprovedList() {
        var data2 = [];
if(isLogged.access["Pending MRS"]){
        var data = await fetchData({
            requestingPage: "MRSList",
            method: "post",
            url: "mrs-for-idn/request-list",
            headers: { token: isLogged.accessToken ,module:"Pending MRS"},
            data:{plantId}
        });
        if (data.msg === "success") {
            if (data.asset.length === 0) {
                setNoApprovedMrsRecords(true);
            }

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });

            setMrsApprovedList(data2);
        }} else {setMrsApprovedList(data2);}
        setLoading(false);
    }
    const editItemCollapse = ( index) => {
        var listCopy = MrsApprovedList.slice();

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });
        setMrsApprovedList(listCopy);
    };

    const access = isLogged.access["IDN"];
    const renderApprovedMrsList = () => {
        return MrsApprovedCurrentPage.map((item, i) => {
            var idncolor="";
            switch(item.idnStatus){
                case "Pending":
                    idncolor="red";
                    break;
                case "Completed":
                    idncolor="#14c76a";
                    break;
                default:
                    idncolor="black";
            }
            const isCreatable= !item.idnDate; 
            return (
                <React.Fragment>
                    <tr className="vendorList"
                        key={item.requestNo}
                        onClick={(e) => {
                            e.stopPropagation();
                            editItemCollapse( i);
                        }}>

                        <td className="vendorListInfo stickyFirstColumn">{item.requestNo}</td>
                        <td className="vendorListInfo">{item.orderDate}</td>
                        <td className="vendorListInfo">{item.expectDate}</td>
                        <td className="vendorListInfo" style={{color: idncolor}}>{item.idnStatus}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        <td className="vendorListInfo">{item.idnDate}</td>
                        <td className="vendorListInfo">{item.editDate}</td>
                        <td className="vendorListInfoActions">
                            <img
                                alt="Create IDN"
                                className="vendorActionImages"
                                src={createProdIcon}
                                onClick={(e) => {
                                                 if(isCreatable){
                                                        e.stopPropagation();
                                                        pendingIdn(item);
                                                         history.push("/idn");
                                                        }
                                                }}
                                style={access === "Create" || access=== "Edit" ? (isCreatable ? { cursor: "pointer" }: { cursor: "not-allowed" }) : {cursor: "not-allowed"}}
                                title={access === "Create" || access === "Edit"? (isCreatable ? "Create IDN Qty/Status " : "Already Created") : "No Access For IDN" }
                            />
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View MRS Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // setApproval(false);
                                    viewIdnPendingId(item.requestId);
                                    setSection("IdnPendingDetail");
                                }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse(i);
                                }}
                            />
                        </td>
                    </tr>
                    <tr> {!item.collapse ? <RenderSubTable  tab="approvedMrs" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };
   
    const RenderSubTable = ({ data }) => {
        return (
            <td colSpan={"11"} className="vendorListInnerTableWrapper" >
                <table className="vendorListInnerTable">
                    <thead >
                        <tr className="vendorListInnerTableHeader" >
                            <td className="vendorListInnerTableHeading stickyFirstColumn">Item</td>
                            <td className="vendorListInnerTableHeading">Item Code</td>
                            {isLogged.showHideWeight ? 
                            <><td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td> </> : null}
                            <td className="vendorListInnerTableHeading">HSN Code</td>
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Requested Qty</td>
                            <td className="vendorListInnerTableHeading">IDN Qty</td> 
                        </tr>
                    </thead>
                    <tbody >
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.requestLineItemId} >
                                    <td className="vendorListInnertableInfo stickyFirstColumn">{row.itemName}</td>
                                    <td className="vendorListInnertableInfo">{row.itemCode}</td>
                                    {isLogged.showHideWeight ? <>                              <td className="vendorListInnertableInfo">{row.from ? row.from : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.to ? row.to : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight ? row.grossWeight : " - "}</td> </> : null}
                                    <td className="vendorListInnertableInfo">{row.hsnCode  ? row.hsnCode : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.quantity}</td>
                                     <td className="vendorListInnertableInfo">{row.idnQuantity}</td> 
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };
    const [order, setOrder] = useState({ col: "requestNo", state: true, data: "" });
    return (
        <React.Fragment key={1}>
                <div style={{ display: "inline-flex", marginLeft: "25px" }} className="listPageParamsGrid">
                    <span className="listPageParams">
                        <FormElement setInput={(value) => { setPlantId(value); }}
                            inputType={"options"}
                            value={plantId}
                            hintText={"Plant Name"}
                            options={isLogged.plantOptions}
                            colSpan={1}
                            rowSpan={1}
                            mandatory={false}
                            error={false}
                            errorMessage=""
                            disabled={false} />
                    </span>
                    <span style={{ flexGrow: 0, width: "80px" }}>
                        <FormElement
                            setInput={(value) => {
                                setRowsPerPage(value);
                            }}
                            inputType={"number"}
                            value={PageSize}
                            hintText={"Rows Per Page"}
                            colSpan={0.5}
                            rowSpan={1}
                            mandatory={false}
                            error={false}
                            errorMessage=""
                            disabled={false}
                        />
                    </span>
                </div>
                <div  className="vendorListArea"> 
                { <React.Fragment>
                        <table className="vendorListTable" >
                            <thead >
                            <tr className="vendorListHeader">
                                <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                                    setOrder({
                                        col: "requestNo",
                                        state: !order.state,
                                    });
                                    handleSort({
                                        data: MrsApprovedCurrentPage,
                                        col: "requestNo",
                                        state: order.state,
                                    });
                                }}
                                >Request No<img
                                        className="sortArrow"
                                        src={
                                            order.col === "requestNo"
                                                ? order.state
                                                    ? SortUpGreen : SortDownGreen
                                                : null}
                                    /></td>
                                <td className="vendorListHeading" onClick={(e) => {
                                    setOrder({
                                        col: "orderDate",
                                        state: !order.state,
                                    });
                                    handleSort({
                                        data: MrsApprovedCurrentPage,
                                        col: "orderDate",
                                        state: order.state,
                                    });
                                }}
                                >MRS Request Date<img
                                        className="sortArrow"
                                        src={
                                            order.col === "orderDate"
                                                ? order.state
                                                    ? SortUpGreen : SortDownGreen
                                                : null}
                                    /></td>
                                <td className="vendorListHeading" onClick={(e) => {
                                    setOrder({
                                        col: "expectDate",
                                        state: !order.state,
                                    });
                                    handleSort({
                                        data: MrsApprovedCurrentPage,
                                        col: "expectDate",
                                        state: order.state,
                                    });
                                }}
                                >Expected Date<img
                                        className="sortArrow"
                                        src={
                                            order.col === "expectDate"
                                                ? order.state
                                                    ? SortUpGreen : SortDownGreen
                                                : null}
                                    /></td>
                                <td className="vendorListHeading" onClick={(e) => {
                                    setOrder({
                                        col: "idnStatus",
                                        state: !order.state,
                                    });
                                    handleSort({
                                        data: MrsApprovedCurrentPage,
                                        col: "idnStatus",
                                        state: order.state,
                                    });
                                }}
                                >IDN Status<img
                                        className="sortArrow"
                                        src={
                                            order.col === "idnStatus"
                                                ? order.state
                                                    ? SortUpGreen : SortDownGreen
                                                : null}
                                    /></td>
                                <td className="vendorListHeading" onClick={(e) => {
                                    setOrder({
                                        col: "createdBy",
                                        state: !order.state,
                                    });
                                    handleSort({
                                        data: MrsApprovedCurrentPage,
                                        col: "createdBy",
                                        state: order.state,
                                    });
                                }}
                                >Requested by<img
                                        className="sortArrow"
                                        src={
                                            order.col === "createdBy"
                                                ? order.state
                                                    ? SortUpGreen : SortDownGreen
                                                : null}
                                    /></td>
                                <td className="vendorListHeading" onClick={(e) => {
                                    setOrder({
                                        col: "idnDate",
                                        state: !order.state,
                                    });
                                    handleSort({
                                        data: MrsApprovedCurrentPage,
                                        col: "idnDate",
                                        state: order.state,
                                    });
                                }}
                                >IDN Date<img
                                        className="sortArrow"
                                        src={
                                            order.col === "idnDate"
                                                ? order.state
                                                    ? SortUpGreen : SortDownGreen
                                                : null}
                                    /></td>
                                <td className="vendorListHeading" onClick={(e) => {
                                    setOrder({
                                        col: "editDate",
                                        state: !order.state,
                                    });
                                    handleSort({
                                        data: MrsApprovedCurrentPage,
                                        col: "editDate",
                                        state: order.state,
                                    });
                                }}
                                >IDN Edited Date<img
                                        className="sortArrow"
                                        src={
                                            order.col === "editDate"
                                                ? order.state
                                                    ? SortUpGreen : SortDownGreen
                                                : null}
                                    /></td>
                                <td className="vendorListHeadingActions">Actions</td>
                            </tr>
                            </thead>
                            <tbody>
                                {MrsApprovedList.length > 0 ? renderApprovedMrsList() : null}
                            </tbody>
                        </table> </React.Fragment> }
                        {noApprovedMrsRecords ? (
                            <div className="noRecordsYet">No items available</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                        <Pagination 
                         className="pagination-bar"
                         currentPage={currentPage}
                         totalCount={MrsApprovedList.length}
                         pageSize={PageSize}
                         onPageChange={page => setCurrentPage(page)}
                        />
            </div>          
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        viewIdnPendingId: (IdnPendingId) => dispatch(viewIdnPendingId(IdnPendingId)),
        pendingIdn: (mrsForIdn) => dispatch(pendingIdn(mrsForIdn))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdnPendingList);