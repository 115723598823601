import React, { useState } from "react";
import { connect } from "react-redux";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import inventoryIcon from "../../assets/inventory.svg";
import MenuList from "../Menu/MenuList";
import PriceDetail from "./PriceDetail";
import Inventory3 from "./Inventory3";
import {exportData, exportToCSV} from "../CommonFunctions/ExportExcel";

const Store = ({isLogged}) => {
    const [currentSection, setCurrentSection] = useState("inventory");
    const [inventoryJSON,setInventoryJSON] = useState([]);

    async function generateReport () 
    { 
        await exportData(isLogged, "priceDetails"); 
    }
    var priceDetail = { buttonName: "Price Detail", className: "employeeButton", setSection: setCurrentSection, sectionName: "priceDetail" };
    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "inventory" };
    var priceReportButton = {buttonName: "Export" ,className: "exportButton", setSection: async ()=> { await exportData(isLogged, "priceDetails")} , sectionName: "inventory"}
    var IReportButton = {buttonName: "Export" ,className: "exportButton", 
    setSection: async()=>{await exportToCSV(inventoryJSON,`Inventory Detail -  ${new Date().toDateString()}`)}    
    , sectionName: "priceDetail"}
    const renderSection = (section) => {
        switch (section) {
            case "inventory":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={inventoryIcon} pageTitle="Inventory" buttonList={[priceDetail, IReportButton]} />
                        <Inventory3 setInventoryJSON={setInventoryJSON}/>
                        {/* <Inventory /> */}
                    </React.Fragment>
                );

            case "priceDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={inventoryIcon} pageTitle="Inventory Item Price Detail" buttonList={[ goBackButton, priceReportButton ]} />
                        <PriceDetail />
                    </React.Fragment>
                );
            
            default:
                return(<>Page Not Found</>)
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Store" selectedSubMenu="Inventory" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                    
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(Store);
