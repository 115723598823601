import { renderFormElements } from "components/SmallComponents/FormElement";
import InputField from "components/SmallComponents/InputField";
import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import { connect } from "react-redux";
import fetchData from 'serverCall/fetchData';
import CreateEditModal from "components/SmallComponents/CreateEditModal";


const EditOtherCharges = ({ otherChargesId, isLogged, setSection }) => {
    const [otherChargesParams, setOtherChargesParams] = useState({
        packingSlipId: {
            inputType: "text",
            value: "",
            hintText: "Packing Slip",
            colSpan: 4,
            error: false,
            errorMessage: "Please select a Packing Slip No",
            mandatory: true,
            disabled: true
        },
        ocDate: {
            inputType: "dateFromEditPage",
            value: "",
            colSpan: 4,
            error: false,
            errorMessage: "Please select Other Charges Date",
            hintText: "Other Charges Date",
            mandatory: true,
        }
    });

    const [otherChargesLineItem, setOtherChargesLineItem] = useState([])
    const [filteredBundleLineItems, setFilteredBundleLineItems] = useState([])
    const [totalDetails, setTotalDetails] = useState({ totalQuantity: 0, totalWeight: 0, netAmount: 0 })
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading data. Please wait...");
    const [errors, setErrors] = useState([]);
    const [roundedOff, setRoundedOff] = useState(0)

    const updateOtherChargeParams = ({ value, paramName, key }) => {
        let otherChargeParamsCopy = Object.assign(otherChargesParams);
        otherChargeParamsCopy = update(otherChargeParamsCopy, { [paramName]: { [key]: { $set: value } } });
        setOtherChargesParams(otherChargeParamsCopy);
    }

    const getRoundedOffValue = (value) => {
        if (!isNaN(value)) {
            setRoundedOff(value)
        } else {
            setRoundedOff(0)
        }
    }

    const updateLineItems = (value, index) => {
        let lineCopy = filteredBundleLineItems.slice();
        lineCopy[index].rate = value;
        lineCopy[index].totalAmount = value * lineCopy[index].totalQuantity

        let netAmount = 
         lineCopy.reduce((acc, val) => acc += val.totalAmount || 0, 0) + 
         otherChargesLineItem.reduce((acc,val)=>acc+= val.quantity * (val.premiumCharge||0),0)+roundedOff ;
        setFilteredBundleLineItems(lineCopy);
        setTotalDetails((prev) => ({ ...prev, netAmount }));

    }

    const calculatingTotalQuantityAndWeight = (forBundle) => {
        const filteredValue = forBundle.reduce((acc, val) => {
            if (acc[val.bundleId]) {
                acc[val.bundleId] = {
                    ...acc[val.bundleId],
                    totalQuantity: val.quantity + acc[val.bundleId].totalQuantity,
                    totalWeight: val.weight + acc[val.bundleId].totalWeight
                }
            } else {
                acc[val.bundleId] = {
                    ...val,
                    totalQuantity: val.quantity,
                    totalWeight: val.weight
                }
            }
            return acc
        }, {})

        const result = Object.values(filteredValue)
        setFilteredBundleLineItems(result)
        const totals = result.reduce((accumulator, item) => {
            accumulator.totalWeight += item.totalWeight;
            accumulator.totalQuantity += item.totalQuantity;
            return accumulator;
        }, { totalWeight: 0, totalQuantity: 0 })
        let netAmount = result.reduce((acc, val) => acc += val.totalAmount || 0, 0);
        setTotalDetails({ ...totals, netAmount })
    }
    //getting data of specific  otherCharges 
    const fetchingOtherCharge = async () => {
        var psDetail = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: "so-fetch/other-charges/" + otherChargesId,
            headers: { token: isLogged.accessToken, module: "Other Charges" },
        });
        return psDetail.asset;
    }

    useEffect(() => {
        let otherChargeParamsCopy = Object.assign(otherChargesParams);
        fetchingOtherCharge().then((data) => {
            let { otherChargeDetail = {}, packingSlipLineItems = [] } = data;
            packingSlipLineItems.forEach((item) => {
                item.totalAmount = item.rate * item.quantity;
            })

            calculatingTotalQuantityAndWeight(packingSlipLineItems)
            otherChargeParamsCopy = update(otherChargeParamsCopy, {
                packingSlipId: { value: { $set: otherChargeDetail.packingSlipNo || "" } },
                ocDate: { value: { $set: otherChargeDetail.otherChargesDate || "" } }

            });
             setRoundedOff( otherChargeDetail.roundedOff)
            setOtherChargesParams(otherChargeParamsCopy);
            setOtherChargesLineItem(packingSlipLineItems)
        })
    }, [])

    const renderBundles = () => {
        return (

            <table className="createVendorContactsTable" style={{ padding: "20px" }}>
                <thead>
                    <tr className="createVendorContactsTableHeader">
                        <td>Bundle No</td>
                        <td>Total Quantity</td>
                        <td>Total Weight</td>
                        <td>Rate</td>
                        <td>Total Amount</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredBundleLineItems.map((line, index) => (
                            <tr className="createVendorContactsTableRows" key={index} style={{ height: "50px" }}>
                                <td>{line.bundleId.toString().padStart(6, "0")}</td>
                                <td>{line.totalQuantity}</td>
                                <td>{line.totalWeight}</td>
                                <td>
                                    <InputField
                                        type="float"
                                        boxHeight={"30px"}
                                        boxWidth={"80px"}
                                        value={line.rate || ""}
                                        setName={(value) => updateLineItems(value, index)}
                                    />
                                </td>
                                <td>{line.totalAmount || 0}</td>
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>
        );
    }

    const renderSections = () => {
        return (
            <table className="createVendorContactsTable" style={{ padding: "20px" }}>
                <thead>
                    <tr className="createVendorContactsTableHeader">
                        <td>Section No</td>
                        <td>Section Name</td>
                        <td>Weight</td>
                        <td>Quantity</td>
                        <td>Premium Charge</td>
                        <td>Amount</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        otherChargesLineItem.map((line, index) => (
                            <tr className="createVendorContactsTableRows" key={index} style={{ height: "50px" }}>
                                <td>{line.sectionNo}</td>
                                <td>{line.sectionName}</td>
                                <td>{line.weight}</td>
                                <td>{line.quantity}</td>
                                <td>{line.premiumCharge}</td>
                                <td>{line.quantity * (line.premiumCharge||0)}</td>
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>
        )
    }

    const submitData = async () => {
        setIsOpen(true);
        setModalText("Uploading Content...Please Wait");
        setDataSubmitted(false);
        let data = {
            otherChargesId: otherChargesId,
            ocDate: otherChargesParams.ocDate.value,
            lineItems: filteredBundleLineItems,
            roundedOff
        }



        let response = await fetchData({
            method: "PUT",
            url: `so-edit/other-charges`,
            data,
            headers: { token: isLogged.accessToken, module: "Other Charges" },
            requestingPage: "OtherChargesList",
        })
        setDataSubmitted(true);
        if (response.msg === "success") {
            setModalText("Succesfully Edited")
        } else {
            setModalText(response.desc);
            setErrors([response.desc]);
        }

    }


    const submitOkClick = () => {
        setIsOpen(false);
        if (errors.length === 0) {
            setSection("otherChargesList");
        }
    }

    const renderErrorMessage = () => {
        if (errors.length > 0) {
            return errors[0];
        }
    }

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "1000px",
                        margin: "0 auto 2rem",
                        // padding: "3rem 3rem 1rem 3rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}>
                    <div style={{ display: "flex" }}>
                        <div
                            className="createPurchaseOrderGrid"
                            style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
                            {renderFormElements({
                                elementList: ["packingSlipId", "ocDate"], param: otherChargesParams,
                                setInput: updateOtherChargeParams
                            })}
                        </div>
                    </div>
                    <div className='purchaseOrderSubSectionArea'>
                        {renderBundles()}
                    </div>
                    <div className='purchaseOrderSubSectionArea'>
                        {renderSections()}
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end" }} >
                        <div className="poNetTotal">
                            <div className="poTotalTitle">
                                <span>
                                    <b>Rounded Off</b>
                                </span>
                                <span className="poNetTotalAmount">
                                    <InputField
                                        type="roundoff"
                                        value={roundedOff}
                                        setName={(value) =>
                                            getRoundedOffValue(value)
                                        }
                                        align="end"
                                        error={""}
                                    />
                                </span>
                            </div>
                            <div className="poTotalTitle">
                                <span>
                                    <b>Total Quantity</b>
                                </span>
                                <span className="poNetTotalAmount">{totalDetails.totalQuantity}</span>
                            </div>
                            <div className="poTotalTitle">
                                <span>
                                    <b>Total Weight</b>
                                </span>
                                <span className="poNetTotalAmount">{totalDetails.totalWeight}</span>
                            </div>
                            <div className="poTotalTitle">
                                <span>
                                    <b>Total Amount</b>
                                </span>
                                <span className="poNetTotalAmount">{totalDetails.netAmount}</span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                    <button className="submitButton"
                        onClick={submitData}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    otherChargesId: state.storeInfo.otherChargesId,
    isLogged: state.isLogged,
})

export default connect(mapStateToProps)(EditOtherCharges)