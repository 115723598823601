import { Fragment,useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import productionReportIcon from "../../assets/production-report.svg"
import CreateProductionReport from "./CreateProductionReport"
import ProductionReportList from "./ProductionReportListPage"
import EditProductionReport from "./EditProductionReport"
import ProductionReportDetail from "./ProductionReportDetail"
import { exportPDF } from "../CommonFunctions/ExportPDF";
import { connect } from "react-redux";

const ProductionReport = ({isLogged}) => {
    const [currentSection,setCurrentSection] = useState("productionReportList");
    var goBackButton = (props) =>
    ({
      buttonName: "Back",
      className: "goBackButton",
      ...props
    });

    var createButton = 
    { buttonName: "Create Production Report", className: "employeeButton", setSection: setCurrentSection, sectionName: "createProductionReport",style:{width: "max-content"} };

    var downloadPRButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "productionReportDetail") }, sectionName: "productionReportList" };
    const renderSection=(currentSection)=>{
        switch(currentSection){
            case "createProductionReport":
                return(
                    <Fragment>
                    <PageTitle imgSrc={productionReportIcon} pageTitle={"Create Production Report"}
                        buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"productionReportList"})]}/>
                     <CreateProductionReport setSection={setCurrentSection}/>
              </Fragment>
                );

            case "productionReportList":
                return(
                    <Fragment>
                        <PageTitle imgSrc={productionReportIcon} pageTitle={"Production Report List"}
                            buttonList={[createButton]}
                            />
                            <ProductionReportList setSection={setCurrentSection} />
                    </Fragment>
                )
            case "editProductionReport":
                return(
                    <Fragment>
                        <PageTitle imgSrc={productionReportIcon} pageTitle={"Edit Production Report"}
                           buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"productionReportList"})]}
                            />
                            <EditProductionReport setSection={setCurrentSection} />
                    </Fragment>
                )

            case "productionReportDetail":
                return(
                    <Fragment>
                        <PageTitle imgSrc={productionReportIcon} pageTitle={"Production Report Detail"}
                           buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"productionReportList"}),downloadPRButton]}
                            />
                            <ProductionReportDetail setSection={setCurrentSection} />
                    </Fragment>
                )
                

            default:
                return <>Page Not Found</>;
        }
    }

  return (
    <Fragment>
        <div className="completePage">
            <MenuList selectedMenu={"Reports"} selectedSubMenu={"Production Report"}/>
            <div className="detailsContainer">
                <TopBanner />
                {renderSection(currentSection)}
            </div>
        </div>
    </Fragment>
  )
}
const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(ProductionReport)