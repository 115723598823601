import update from "immutability-helper";
import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { validateMandatory } from "../../CommonFunctions/ValidateFields";
import CreateEditModal from "../../SmallComponents/CreateEditModal";
import FormElement from "../../SmallComponents/FormElement";

const dieParamList = [
    // general info
    ["dieNo", "dieName", "size","die","cvt","drawingWeight",
     "runningWeight","date","pressMachineNo","rack","remarks","resize","recievedDate"],
];

const EditDie = ({
    // props
    setSection,

    // state
    isLogged,

    // dispatch
    addTokenToState,
    itemInfo,
    existingList
}) => {
    const [dieParams, setDieParams] = useState({
        dieNo: {
            inputType: "text",
            value: "",
            hintText: "Die No",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter Die No",
        },
       dieName: {
            inputType: "text",
            value: "",
            hintText: "Die Name",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter Die Name",
        },
        size: {
            inputType: "text",
            value: "",
            hintText: "Size",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter Size",
        },
        die: {
            inputType: "number",
            value: "",
            hintText: "Die",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter Die",
        },
        cvt: {
            inputType: "number",
            value: "",
            hintText: "CVT",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter CVT",
        },
        drawingWeight: {
            inputType: "text",
            value: "",
            hintText: "Drawing Weight",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter Drawing Weight",
        },
        runningWeight: {
            inputType: "text",
            value: "",
            hintText: "Running Weight",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter Running Weight",
        },
        date: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "date",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please Choose a Date",
        },
        pressMachineNo: {
            inputType: "text",
            value: "",
            hintText: "Press Machine No",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please Enter Press Machine No",
        },
        rack: {
            inputType: "number",
            value: "",
            hintText: "Rack",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please Enter Rack",
        },
        remarks: {
            inputType: "text",
            value: "",
            hintText: "Remarks",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
        },
        resize: {
            inputType: "number",
            value: "",
            hintText: "Resizing",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
        },
        recievedDate: {
            inputType: "dateFromEditPage",
            value: new Date(),
            hintText: "Recieved Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "",
            disabled:true
        },
    });

    const [dieDetails,setDieDetail] = useState([]);
    const getFormateDate =()=>{
        let currentDate = new Date();

        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;
        let dateValue = currentDate.getDate();

        if (month.toString().length === 1) month = `0${month}`;
        if (dateValue.toString().length === 1) dateValue = `0${dateValue}`;

        let formattedDate = `${year}-${month}-${dateValue}`;
        return formattedDate
    }

    const [isBroken,setIsBroken] = useState(false);

    const updateItemCategoryparamater = ({ paramName, section, key, value }) => {
        switch (section) {
            case "generalInfo":
                var itemCategoryInfo = Object.assign(dieParams);
                itemCategoryInfo = update(itemCategoryInfo, { [paramName]: { [key]: { $set: value } } });
                setDieParams(itemCategoryInfo);
                break;

            default:
                break;
        }
    };

    const [categoryErrors, setCategoryErrors] = useState([]);
    const checkErrors = () => {
        var errorList = [];
        var dieParamsCopy = Object.assign(dieParams);

        dieParamList[0].forEach((item) => {
            if (dieParamsCopy[item].mandatory) {
                if(item!=="recievedDate"){
                    dieParamsCopy = update(dieParamsCopy, {
                        [item]: { error: { $set: !validateMandatory(dieParamsCopy[item].value) } },
                    });
                }else{
                    if(dieParamsCopy.recievedDate.value){
                        dieParamsCopy = update(dieParamsCopy, {
                            recievedDate: { error: { $set: false} },
                        });
                    }
                }
            }

            if (dieParamsCopy[item].error) {
                errorList.push(dieParamsCopy[item].errorMessage);
            }

        });
        setDieParams(dieParamsCopy);
    
        setCategoryErrors(errorList);
        var data = {};
                dieParamList[0].forEach((item) => {
                    data[item] = dieParams[item].value||null;
                });
                data.isBroken = isBroken;
                data.brokenDate = isBroken ? getFormateDate():dieDetails.brokenDate?dieDetails.brokenDate:null;            
                data.rectifiedDate = !isBroken && dieDetails.brokenDate ? getFormateDate():null;            

        if (errorList.length === 0) {
            setIsOpen(true);
                submitData(data);
        }
    };

    useEffect(()=>{
      getInformation()
     
    },[]);

    const getInformation =async()=>{
      let detail = await getDieDetail()
      let dieParamsCopy = structuredClone(dieParams);
      dieParamList[0].map((item)=>{
        if(item === "recievedDate"){
            let dateValue = detail[0][item] ?new Date(detail[0][item]):new Date()
           return dieParamsCopy[item].value = dateValue;
        }else if(item==="date"){
            let dateValue = detail[0][item] ?new Date(detail[0][item]):""
            return dieParamsCopy[item].value = dateValue;
        }
        else{
              return dieParamsCopy[item].value= detail[0][item];
        }
      })
      setIsBroken(detail[0].isBroken);
      setDieDetail(detail[0]);

      setDieParams(dieParamsCopy);
    }

    const [categoryServerError, setCategoryServerError] = useState([]);
    async function submitData(data) {
        setDataSubmitted(false);
        await checkToken();

        var result = await fetchData({
            requestingPage: "createItemCategory",
            method: "put",
            url: "edit/die-master/"+itemInfo.dieMasterId,
            headers: { token: isLogged.accessToken,module:"Die Master" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Successfully Updated Die !");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setCategoryServerError([result.desc]);
        }
        setDataSubmitted(true);
    }

    const submitOkClick = () => {
        setIsOpen(false);
        if (categoryServerError.length === 0) setSection("dieList");
        setModalText("Uploading form. Please wait...");
    };

    const history = useHistory();
    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getDieDetail (){
      var data = await fetchData({
        requestingPage: "premiumCharges",
        method: "get",
        url: "fetch/die-master/"+ itemInfo.dieMasterId,
        headers: { token: isLogged.accessToken, module: "Die Master" },
      });
      if(data.msg==="success"){
        return data.asset
      }

    }

    const renderErrorMessage = () => {
        // if (plantErrors.length > 0) return `Plant Info Section : ${plantErrors[0]}`;
        // else
        if (categoryErrors.length > 0) return `${categoryErrors[0]}`;
        else return null;
    };

    const renderFormElements = ({ elementList, section }) => {
        return elementList.map((item) => {
            switch (section) {
                case "generalInfo":
                    return (
                        <FormElement
                            key={item}
                            inputType={dieParams[item].inputType}
                            value={dieParams[item].value}
                            setInput={(value) => {
                                updateItemCategoryparamater({ section, paramName: item, key: "value", value: value });
                            }}
                            hintText={dieParams[item].hintText}
                            mandatory={dieParams[item].mandatory}
                            colSpan={dieParams[item].colSpan}
                            options={dieParams[item].inputType === "options" ? dieParams[item].options : null}
                            error={dieParams[item].error}
                            disabled={dieParams[item].disabled}
                        />
                    );

                default:
                    return null;
            }
        });
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="formArea">
                <div
                    style={{
                        width: "1000px",
                        margin: "0 auto 4rem",
                        padding: "3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        overflow: "hidden",
                    }}
                >
                    <div className="createItemCategoryGrid" style={{gridTemplateColumns: "repeat(18, 1fr)",gridTemplateRows: "repeat(7, 4rem)"}}>
                        {renderFormElements({ elementList: dieParamList[0], section: "generalInfo" })}
                            <div
                            style={{
                            gridColumn: `auto / span 6`,
                            margin: "auto 0px",
                            color: "#666666",
                            cursor: "pointer",
                            }}
                            onClick={() => setIsBroken(!isBroken)}>
                            <input
                            type="checkbox"
                            name="Old / New Customer"
                            checked={isBroken}
                            readOnly
                            style={{ cursor: "pointer" }}
                            />
                            <label
                            htmlFor="vehicle1"
                            style={
                                isBroken
                                ? {
                                    fontWeight: "600",
                                    color: "#1f43e5",
                                    padding: "0 10px",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                }
                                : {
                                    padding: "0 10px",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                }
                            }>
                            Is Broken
                            </label>
                        </div>
                    </div>
                   
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                    <button
                        className="submitButton"
                        onClick={() => {
                            setCategoryErrors([]);
                            setCategoryServerError([]);
                            checkErrors();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        itemInfo:state.itemInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDie);
