import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import storeIcon from "../../assets/store2.svg";
import CreatePlant from "./CreatePlant";
import "../../components/styles/Plants.css";
import PlantList from "./PlantList";
import EditPlant from "./EditPlant";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from "react-redux";
import fetchData from "../../serverCall/fetchData";
import PlantDetail from "./PlantDetail";
export const  gstValidation=async({gstNo,isLogged})=>{
    let response = await fetchData({
        method: 'get',
        requestingPage: 'gstValidation',
        url: 'fetch/gst-validation/'+gstNo,
        headers: {token:isLogged.accessToken,module: "Plant & Store"}
    });
    if(response.asset.message === 'No records found')
    return 'Invalid';
    else
    return 'Valid';
}

export const  swiftCodeValidation=async({swiftCode,isLogged})=>{
    let response = await fetchData({
        method: 'get',
        requestingPage: 'gstValidation',
        url: 'fetch/swift-code-validation/'+swiftCode,
        headers: {token:isLogged.accessToken,module: "Plant & Store"}
    });
    console.log(response.asset)
    if(response.asset.data.partner_status.status_message === 'Success')
    return 'Valid';
    else
    return 'Invalid';
}

const Plants = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("plantList");

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "plantList" }];
    var createPlantButton = { buttonName: "Create Plant", className: "employeeButton", setSection: setCurrentSection, sectionName: "createPlant" };

    const renderSection = (section) => {
        //console.log(section);
        switch (section) {
            case "plantList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={storeIcon}
                            pageTitle="Plant & Store"
                            buttonList={
                                isLogged.access["Plant & Store"] === "Create" || isLogged.access["Plant & Store"] === "Edit"
                                    ? [createPlantButton]
                                    : null
                            }
                        />
                        <PlantList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createPlant":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={storeIcon} pageTitle="Create - Plant & Store" buttonList={goBackButton} />
                        <CreatePlant setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editPlant":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={storeIcon} pageTitle="Edit - Plant & Store" buttonList={goBackButton} />
                        <EditPlant setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );
            case "plantDetail":
                return(
                    <React.Fragment>
                        <PageTitle imgSrc={storeIcon} pageTitle="Plant & Store Detail" buttonList={goBackButton}/>
                        <PlantDetail setSection={setCurrentSection} />
                    </React.Fragment>
                )
            default:
                return <h1>Page yet to be created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Plant & Store" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(Plants);
