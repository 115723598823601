// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

// async function convertHTMLToImageUrl() {
//   const tableDivElement = document.getElementsByClassName(
//     "detailsPageContainer"
//   )[0];
//   if (tableDivElement) {
//     const canvas = await html2canvas(tableDivElement);
//     return canvas.toDataURL("image/jpeg");
//   }
//   return "";
// }

// export async function exportPDF(
//   isLogged,
//   reportName,
//   params = {dealerName:'',orderNo:'', url: "", method: "get" }
// ) {
//   let {dealerName,orderNo} = params;
//   const pdfName = reportsMap[reportName].filename + ".pdf";
//   const imgPos = { left: 15, top: 15 };
//   const imgFormat = "jpeg";
//   let options;
//   ["salesInvoice","packingSlip"].includes(reportName)
//   if(["salesInvoice","packingSlip"].includes(reportName)){
//    options = {
//     orientation: "p",
//     unit: "in",
//     format: "a4",
//   };} else{
//     options={
//       orientation: "p",
//       format: "a4",
//       unit: "mm"
//     }
//   }

//   const doc = new jsPDF(options);
//   doc.setFont("Helvetica", "");
//   doc.setFontSize(8);
  
//   const convert = () => {
//       const tableDivElements = document.getElementsByClassName(
//         "detailsPageContainer"
//       );
//       const containerPromise = [];
//       Array.from(tableDivElements).forEach(async (container, i) => {
//         containerPromise.push(
//           html2canvas(container, {
          
//           }).then((canvas) => {
//             const image = canvas.toDataURL("image/jpeg");
//             const imagePromise = doc.addImage(image, "jpeg", 0.2,0.2,8,10.5);
//             if (i < tableDivElements.length - 1) {
//               doc.addPage("a4", "p");
//             }
//             return imagePromise;
//           })
//         );
//       });

//       let invoiceFileName = `${dealerName}-${orderNo}`;
//       Promise.all(containerPromise).then((content) => {
//         doc.save(invoiceFileName);
//       });
    
//   };
//   if(["salesInvoice","packingSlip"].includes(reportName))
//   {
//     convert()
//   }
//   else{
//     const image = new Image();
//     image.src = await convertHTMLToImageUrl();
//       doc.addImage(image, imgFormat, 0, 0, 210, 297);
//       if(['soDetails','poDetails'].includes(reportName)){
//         let saleOrderFileName = `${dealerName}-${orderNo}`;
//         doc.save(saleOrderFileName)
//       }else{
//       doc.save(pdfName);
//       }
//     }
// }

// export const reportsMap = {
//   employeeDetails: {
//     module: "Employee Details",
//     filename: "EmployeeDetails",
//   },
//   soDetails: {
//     module: "Sales Order",
//     filename: "SaleOrderDetail",
//   },
//   srDetails: {
//     module: "Sales Return",
//     filename: "SaleReturnDetail",
//   },
//   salesInvoice: {
//     module: "Sale Invoice",
//     filename: "SaleOrderInvoice",
//   },
//   poDetails: {
//     module: "Purchase Order",
//     filename: "PurchaseOrderDetail",
//   },
//   importPODetail: {
//     module: "Import Purchase Detail",
//     filename: "ImportPurchaseOrderDetail",
//   },
//   purchaseImportDetail: {
//     module: "Purchase Import Detail",
//     filename: "PurchaseImportDetail",
//   },
//   prDetails: {
//     module: "Purchase Return",
//     filename: "PurchaseReturnDetail",
//   },
//   purchaseInvoice: {
//     module: "Purchase Invoice",
//     filename: "PurchaseOrderInvoice",
//   },
//   opdDetail: {
//     module: "OPD Detail",
//     filename: "OPDDetail",
//   },
//   inwardDetail: {
//     module: "Inward Detail",
//     filename: "InwardDetail",
//   },
//   outwardDetail: {
//     module: "Outward Detail",
//     filename: "OutwardDetail",
//   },
//   qcDetail: {
//     module: "QC Detail",
//     filename: "QCDetail",
//   },
//   soPendingDetail: {
//     module: "SO Pending Detail",
//     filename: "SOPendingDetail",
//   },
//   mrsPendingDetail: {
//     module: "MRS Pending Detail",
//     filename: "MRSPendingDetail",
//   },
//   stockTransferDetail: {
//     module: "Stock Transfer Detail",
//     filename: "StockTransferDetail",
//   },
//   productionOrderDetail: {
//     module: "Production Order Detail",
//     filename: "ProductionOrderDetail",
//   },
//   materialRequisitionDetail: {
//     module: "Material Requisition Detail",
//     filename: "MaterialRequisitionDetail",
//   },
//   customerLedger: {
//     module: "Customer Ledger",
//     filename: "CustomerLedger"
//   },
//   outstandingStatement: {
//     module: "Outstanding Statement",
//     filename: "OutstandingStatement"
//   },
//   stockReport: {
//     module: "Stock Report",
//     filename: "StockReport"
//   },
//   packingSlip:{
//     module: "Packing Slip",
//     filename: "PackingSlip"
//   },
//   otherCharges:{
//     module: "Other Charges",
//     filename: "PremiumList"
//   }
// };

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
async function convertHTMLToImageUrl() {
  let tableDivElement = document.getElementsByClassName(
    "detailsPageContainer"
  )[0];
  if (tableDivElement) {
    const canvas = await html2canvas(tableDivElement);
    return canvas.toDataURL("image/jpeg");
  }
  return "";
}
export async function exportPDF(
  isLogged,
  reportName,
  params = {dealerName:'',orderNo:'', url: "", method: "get" },
) {
  let {dealerName,orderNo} = params;
  const pdfName = reportsMap[reportName].filename + ".pdf";
  const imgPos = { left: 15, top: 15 };
  const imgFormat = "jpeg";
  let options;

  ["salesInvoice","packingSlip","proformaInvoice","purchaseImportListPdf"].includes(reportName)
  if(["salesInvoice","packingSlip","proformaInvoice","poDetails","purchaseImportListPdf","productionInwardDetails"].includes(reportName)){
   options = {
    orientation: "p",
    unit: "in",
    format: "a4",
  };} else{
    options={
      orientation: "p",
      format: "a4",
      unit: "mm"
    }
  }
  const doc = new jsPDF(options);
  doc.setFont("Helvetica", "");
  doc.setFontSize(8);

  const convert = () => {
      let tableDivElements = document.getElementsByClassName(
        "detailsPageContainer"
      );
      const containerPromise = [];
      Array.from(tableDivElements).forEach(async (container, i) => {
        containerPromise.push(
          html2canvas(container, {

          }).then((canvas) => {
            const image = canvas.toDataURL("image/jpeg");
            const imagePromise = doc.addImage(image, "jpeg", 0.2,0.2,8,10.5);
            if (i < tableDivElements.length - 1) {
              doc.addPage("a4", "p");
            }
            return imagePromise;
          })
        );
      });
      let invoiceFileName = `${dealerName}-${orderNo}`;
      console.log(containerPromise,"containerPromise")
      Promise.all(containerPromise).then((content) => {
        doc.save(invoiceFileName);
      });

  };
  if(["salesInvoice","packingSlip","proformaInvoice","otherCharges",'poDetails',"productionInwardDetails"].includes(reportName))
  {
    convert()
  }
  else{
    const image = new Image();
    image.src = await convertHTMLToImageUrl();
      doc.addImage(image, imgFormat, 0, 0, 210, 297);
      if(['soDetails'].includes(reportName)){
        let saleOrderFileName = `${dealerName}-${orderNo}`;
        doc.save(saleOrderFileName)
      }else{
      doc.save(pdfName);
      }
    }
}
export const reportsMap = {
  employeeDetails: {
    module: "Employee Details",
    filename: "EmployeeDetails",
  },
  soDetails: {
    module: "Sales Order",
    filename: "SaleOrderDetail",
  },
  srDetails: {
    module: "Sales Return",
    filename: "SaleReturnDetail",
  },
  salesInvoice: {
    module: "Sale Invoice",
    filename: "SaleOrderInvoice",
  },
  poDetails: {
    module: "Purchase Order",
    filename: "PurchaseOrderDetail",
  },
  importPODetail: {
    module: "Import Purchase Detail",
    filename: "ImportPurchaseOrderDetail",
  },
  purchaseImportDetail: {
    module: "Purchase Import Detail",
    filename: "PurchaseImportDetail",
  },
  prDetails: {
    module: "Purchase Return",
    filename: "PurchaseReturnDetail",
  },
  purchaseInvoice: {
    module: "Purchase Invoice",
    filename: "PurchaseOrderInvoice",
  },
  opdDetail: {
    module: "OPD Detail",
    filename: "OPDDetail",
  },
  inwardDetail: {
    module: "Inward Detail",
    filename: "InwardDetail",
  },
  outwardDetail: {
    module: "Outward Detail",
    filename: "OutwardDetail",
  },
  qcDetail: {
    module: "QC Detail",
    filename: "QCDetail",
  },
  soPendingDetail: {
    module: "SO Pending Detail",
    filename: "SOPendingDetail",
  },
  mrsPendingDetail: {
    module: "MRS Pending Detail",
    filename: "MRSPendingDetail",
  },
  stockTransferDetail: {
    module: "Stock Transfer Detail",
    filename: "StockTransferDetail",
  },
  productionOrderDetail: {
    module: "Production Order Detail",
    filename: "ProductionOrderDetail",
  },
  materialRequisitionDetail: {
    module: "Material Requisition Detail",
    filename: "MaterialRequisitionDetail",
  },
  customerLedger: {
    module: "Customer Ledger",
    filename: "CustomerLedger"
  },
  outstandingStatement: {
    module: "Outstanding Statement",
    filename: "OutstandingStatement"
  },
  stockReport: {
    module: "Stock Report",
    filename: "StockReport"
  },
  packingSlip:{
    module: "Packing Slip",
    filename: "PackingSlip"
  },
  otherCharges:{
    module: "Other Charges",
    filename: "PremiumList"
  },
  proformaInvoice:{
    module: "Proforma Invoice",
    filename: "ProformaInvoice"
  },
  purchaseImportListPdf:{
    module: "Purchase Import",
    filename: "Purchase Import Approved List"
  },
  inwardBill:{
    module: "Purchase Order",
    filename: "Inward Bill"
  },
  productionInwardDetails:{
    module: "Production Inward",
    filename: "Production Inward Detail"
  },
  agingReportDetail:{
    module:"Aging Report",
    filename: "Aging Report Detail"
  },
  productionReportDetail:{
    module:"Production Report",
    filename: "Production Report Detail"
  },
  productionPlanningDetail:{
    module:"Production Planning",
    filename: "Production Planning Detail"
  }
};