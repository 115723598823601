import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import imageNotAvailable from "../../../assets/imageNotAvailable.svg";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import Modal from "react-modal";
import { Fragment } from "react";
import CreateEditModal from "components/SmallComponents/CreateEditModal";

const SalesItemDetail = ({
    // props
    setSection,
    isApproval,
    // state
    isLogged,
    itemInfo,

    // dispatch
    addTokenToState,
}) => {
    const [itemData, setItemData] = useState(null);
    const [uomConversion, setUomConversion] = useState([]);
    const [storageOpeningStock, setStorageOpeningStock] = useState([]);
    const [billOfMaterial, setBillOfMaterial] = useState([]);
    const [processFlow, setProcessFlow] = useState([]);
    const [diagram, setDiagram] = useState([]);
    const [itemImage, setItemImage] = useState();

    const [diagramImage, setDiagramImage] = useState();

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getItemDetail();
    }

    const history = useHistory();

    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getItemDetail() {
        var data = await fetchData({
            requestingPage: "itemDetail",
            method: "get",
            url: "fetch/item/" + itemInfo.viewItemId,
            headers: { token: isLogged.accessToken,module:"Items" },
        });
        if (data.msg === "success") {

            setItemData(data.asset.item[0]);
            setUomConversion(data.asset.uomConversion);
            setStorageOpeningStock(data.asset.openingStock);
            setBillOfMaterial(data.asset.bom);
            setProcessFlow(data.asset.processFlow);
            setDiagram(data.asset.diagram);
        }
    }

    useEffect(() => {
        getItemImage();
        // eslint-disable-next-line
    }, [itemData]);

    async function getItemImage() {

        if (itemData?.itemImage !== null) {
            var data = await fetchData({
                requestingPage: "itemImage",
                method: "get",
                url: "image/item-image/" + itemInfo.viewItemId,
                headers: { token: isLogged.accessToken,module:"Items" },
            });
            setItemImage(data);
        }
    }

    const [isLoading, setIsLoading] = useState(false);

    async function getDiagramImage(imageName) {
        setDiagramImage();
        setIsOpen(true);
        setIsLoading(true);

        var data = await fetchData({
            requestingPage: "diagramImage",
            headers: { token: isLogged.accessToken,module:"Items" },
            url: `image/item-diagram/${imageName}`,
        });
        if (data === "") {
            // setProfileImage(view);
            setIsOpen(false);
            setIsLoading(false);
        } else {
            setDiagramImage(data);
            setIsLoading(false);
        }
    }

    Modal.setAppElement("body");
    const [modalIsOpen, setIsOpen] = useState(false);

    const renderImageStatus = () => {
        return (
            <div>
                <img
                    alt="Item"
                    className="vendorImage"
                    src={itemImage !== null && itemImage !== undefined && itemImage !== "" ? `data:image/png;base64,${itemImage}` : imageNotAvailable}
                />
            </div>
        );
    };

    const renderSummaryInfo = () => {
        if (itemData !== null) {
            return (
                <React.Fragment>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">Item Main Category</span>
                        <span className="vendorSummaryValue">: {itemData.category}</span>

                        <span className="vendorSummaryCategory">Section No</span>
                        <span className="vendorSummaryValue">: {itemData.sectionNo}</span>

                        <span className="vendorSummaryCategory">Section Name</span>
                        <span className="vendorSummaryValue">: {itemData.sectionName}</span>

                        <span className="vendorSummaryCategory">Description</span>
                        <span className="vendorSummaryValue" style={{ whiteSpace: "break-spaces" }}>
                            :{" "}
                            {itemData.description !== undefined && itemData.description !== null && itemData.description.length > 0
                                ? itemData.description
                                : " NA"}
                        </span>
                    </div>
                </React.Fragment>
            );
        }
    };

    const renderAddInfo = () => {
        if (itemData != null) {
            return (
                <React.Fragment>
                    <div className="vendorSummaryGrid">
                    <span className="vendorSummaryCategory">Item Main Category</span>
                        <span className="vendorSummaryValue">: {itemData.category}</span>

                        <span className="vendorSummaryCategory">Section No</span>
                        <span className="vendorSummaryValue">: {itemData.itemCode}</span>

                        <span className="vendorSummaryCategory">Section Name</span>
                        <span className="vendorSummaryValue">: {itemData.item}</span>
                        <span className="vendorSummaryCategory">Description</span>
                        <span className="vendorSummaryValue" style={{ whiteSpace: "break-spaces" }}>
                            :{" "}
                            {itemData.description !== undefined && itemData.description !== null && itemData.description.length > 0
                                ? itemData.description
                                : " NA"}
                        </span>
                    
                        <div className="vendorSummaryCategory">Item Active Status</div>
                        <div>
                            : {" "} {itemData.activeStatus ? itemData.activeStatus : ""}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    const tablist = [
        { id: "uomConversion", name: "Item UOM" },
        { id: "storageOpeningStock", name: "Storage & Opening Stock" },
        { id: "billOfMaterial", name: "Bill Of Material" },
        { id: "processFlow", name: "Process Flow" },
        { id: "diagram", name: "Diagram" },
    ];
    const [selectedTab, setSelectedTab] = useState("uomConversion");

    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={selectedTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => setSelectedTab(tab.id)}
                >
                    {tab.name}
                </div>
            );
        });
    };

    const RenderTable = ({ tab }) => {
        switch (tab) {
            case "uomConversion":
                return (
                    <table className="vendorDetailContactsTable">
                        <thead>
                            <tr className="vendorDetailContactsTableHeader">
                                <td>UOM</td>
                                <td>Count</td>
                                <td>Base UOM</td>
                            </tr>
                        </thead>
                        <tbody>
                            <RenderTableRows rows={uomConversion} tab="uomConversion" />
                        </tbody>
                    </table>
                );

            case "storageOpeningStock":
                return storageOpeningStock.length > 0 ? (
                    <table className="vendorDetailContactsTable">
                        <thead>
                            <tr className="vendorDetailContactsTableHeader">
                                <td>Plant Name</td>
                                <td>Storage</td>
                                <td>Batch No</td>
                                <td>Date</td>
                                <td>Quantity</td>
                                <td>Price</td>
                            </tr>
                        </thead>
                        <tbody>
                            <RenderTableRows rows={storageOpeningStock} tab="storageOpeningStock" />
                        </tbody>
                    </table>
                ) : (
                    <table className="vendorDetailContactsTable" style={{ backgroundColor: "white" }}>
                        <span style={{ padding: "40px" }}>No Data Available</span>
                    </table>
                );

            case "billOfMaterial":
                return billOfMaterial.length > 0 ? (
                    <table className="vendorDetailContactsTable">
                        <thead>
                            <tr className="vendorDetailContactsTableHeader">
                                <td>Raw Item</td>
                                <td>UOM</td>
                                <td>Quantity</td>
                            </tr>
                        </thead>

                        <tbody>
                            <RenderTableRows rows={billOfMaterial} tab="billOfMaterial" />
                        </tbody>
                    </table>
                ) : (
                    <table className="vendorDetailContactsTable" style={{ backgroundColor: "white" }}>
                        <span style={{ padding: "40px" }}>No Data Available</span>
                    </table>
                );

            case "processFlow":
                return processFlow.length > 0 ? (
                    <table className="vendorDetailContactsTable">
                        <thead>
                            <tr className="vendorDetailContactsTableHeader">
                                <td>Step No</td>
                                <td>Process</td>
                                <td>Quantity</td>
                                <td>Days</td>
                                <td>HH</td>
                                <td>MM</td>
                            </tr>
                        </thead>

                        <tbody>
                            <RenderTableRows rows={processFlow} tab="processFlow" />
                        </tbody>
                    </table>
                ) : (
                    <table className="vendorDetailContactsTable" style={{ backgroundColor: "white" }}>
                        <span style={{ padding: "40px" }}>No Data Available</span>
                    </table>
                );

            case "diagram":
                return diagram.length > 0 ? (
                    <table className="vendorDetailContactsTable">
                        <thead>
                            <tr className="vendorDetailContactsTableHeader">
                                <td>Diagram Name</td>
                            </tr>
                        </thead>

                        <tbody>
                            <RenderTableRows rows={diagram} tab="diagram" />
                        </tbody>
                    </table>
                ) : (
                    <table className="vendorDetailContactsTable" style={{ backgroundColor: "white" }}>
                        <span style={{ padding: "40px" }}>No Data Available</span>
                    </table>
                );

            default:
                return <div>No Table rendered</div>;
        }
    };

    var uomConversionKeys = ["uom", "uomCount", "baseUom"];
    var storageOpeningStockKeys = ["plant", "storage", "batchNo", "stockDate", "quantity", "price"];
    var billOfMaterialKeys = ["rawItem", "uom", "quantity"];
    var processFlowKeys = ["stepNo", "process", "quantity", "processDays", "processHours", "processMinutes"];
    var diagramKeys = ["diagram"];

    const RenderTableRows = ({ rows, tab }) => {

        var tableKeys = [];
        if (tab === "uomConversion") tableKeys = uomConversionKeys;
        if (tab === "storageOpeningStock") tableKeys = storageOpeningStockKeys;
        if (tab === "billOfMaterial") tableKeys = billOfMaterialKeys;
        if (tab === "processFlow") tableKeys = processFlowKeys;
        if (tab === "diagram") tableKeys = diagramKeys;

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {tableKeys.map((key, i) => {
                        return (
                            <td key={i}>
                                {key === "diagram" ? (
                                    <span
                                        style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
                                        onClick={() => {
                                            getDiagramImage(diagram[j].diagramFileName);
                                        }}
                                    >
                                        {row[key]}
                                    </span>
                                ) : key === "stockDate" ? (
                                    new Date(row[key]).toDateString()
                                ) : (
                                    row[key]
                                )}
                            </td>
                        );
                    })}
                </tr>
            );
        });
    };

    const [approveModalIsOpen, setApproveModalIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving PO. Please wait...");

    const [errorMessage,setErrorMessage] = useState([]);
    const renderErrorMessage=()=>{
        if(errorMessage.length[0]){
            return errorMessage[0];
        }
    }

    const approveItem=async()=>{
        setApproveModalIsOpen(true)          
        var data = await fetchData({
            requestingPage: "itemDetail",
            method: "put",
            url: "edit/item-approve/" + itemInfo.viewItemId,
            headers: { token: isLogged.accessToken,module:"Items" },
        });
                
        if(data.msg ==="success"){
            setDataSubmitted(true)
            setModalText('Successfully Approved')
        }
    }
    const submitOkClick = () => {
        setApproveModalIsOpen(false);
        if (errorMessage.length === 0) {
          setSection("salesItemList");
        }
        setModalText("Approving SO. Please wait...");
      };
    const ApproveButtons = () => {
        return (
          <div className="formSubmitArea">
            <div className="formSubmitInnerArea">
              <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
              <button
                className="submitButton"
                onClick={() => {
                  approveItem();
                }}>
                Approve
              </button>
            </div>
          </div>
        );
      };

    return (
    <Fragment>
        <CreateEditModal
        modalIsOpen={approveModalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
        <div className="detailsPageWrapper">
            <div className="detailsPageContainer">
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setIsOpen(false)}
                    contentLabel="Example Modal"
                    style={{
                        overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
                        content: {
                            textAlign: "center",
                            width: "70%",
                            height: "70%",
                            position: "fixed",
                            top: "15%",
                            left: "15%",
                            padding: "20px",
                            borderRadius: "5px",
                            boxShadow: "0 0 5px gray",
                        },
                    }}
                >
                    {isLoading ? (
                        <React.Fragment>
                            <div className="loader" />
                            <span>Loading </span>
                        </React.Fragment>
                    ) : null}
                    <img className="modalImage" src={`data:image/jpeg;base64,${diagramImage}`} alt="Item Diagram" />
                </Modal>
                <div className="vendorSummary">
                    {renderImageStatus()}
                    {/* <div className="vendorSummaryText">{renderSummaryInfo()}</div> */}
                    <div style={{ flex: "1" }} />
                    <div className="vendorSummaryText">{renderAddInfo()}</div>
                </div>

                <div className="vendorDetailTabArea">
                    <div className="vendorDetailTabs">
                        <RenderTabList tablist={tablist} />
                    </div>
                    <RenderTable tab={selectedTab} />
                </div>
            </div>
        </div>
        {isApproval ? <ApproveButtons /> : null}
    </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        itemInfo: state.itemInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesItemDetail);
