import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import fetchData from "../../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import CreateEditModal from "../../SmallComponents/CreateEditModal";

const CreateItemCurrency=({isLogged,addTokenToState})=>{
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getCurrencyList();
    }

    const history = useHistory();
    const [itemCurrencyList,setItemCurrencyList]=useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading data. Please wait...");

    const [itemCurrencyCountry, setItemCurrencyCountry] = useState("");
    const [itemCurrency,setItemCurrency]=useState("");
    const [currencyValue,setCurrencyValue]=useState("");

    async function checkToken() {
        console.log("Checking token");
        //   console.log(isLogged.accessToken);
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    };
    async function getCurrencyList(){
        var data = await fetchData({
            requestingPage: "itemTypeList",
            method: "get",
            url: "fetch/item-currency-list",
            headers: { token: isLogged.accessToken,module:"Item Currency" },
        });
        if (data.msg === "success") {
            setItemCurrencyList(data.asset);
        } else {
            console.log(data);
        }
    }

    const renderItemCurrencyList = () => {
        return itemCurrencyList.map((row) => {
            return (
                <tr className="roleList" key={row.itemCurrencyId}>
                    <td className="roleName">{row.itemCurrencyCountry}</td>
                    <td className="roleName">{row.currencyName}</td>
                    <td className="roleName">{row.itemCurrency}</td>
                    <td className="roleName">&nbsp;</td>
                </tr>
            );
        });
    };

   

    const createItemCurrency=async()=>{
        setDataSubmitted(false);
        var dataSent = await fetchData({
            requestingPage: "createItemType",
            method: "post",
            url: "create/item-currency",
            headers: { token: isLogged.accessToken,module:"Item Currency" },
            data: { itemCurrencyCountry: itemCurrencyCountry , itemCurrency: itemCurrency,currencyValue: currencyValue}
        });
        if (dataSent.msg === "success") {
            setModalText("Successfully Created Item Currency!");
            setItemCurrency("");
            setItemCurrencyCountry("");
            setCurrencyValue("");
            getInformation();
        } else {
            setModalText(`Upload failed: ${dataSent.desc}`);
        }
        setDataSubmitted(true);
    }
    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Uploading data. Please wait...");
    };
    return(
        <div className="rolesPageContainer">
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="roleListArea">
                <table className="roleListTable">
                    <thead>
                    <tr className=" roleListHeader">
                        <td className="roleNameHeader">
                            <span>Country Name</span>
                        </td>
                        <td className="roleNameHeader">
                            <span>Currency Name</span>
                        </td>
                        <td className="roleNameHeader">
                            <span>Currency Value</span>
                        </td>
                        <td className="roleNameHeader">
                            <span>Actions</span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="createRole">
                        <td>
                        <input
                            className="createRoleInput"
                            value={itemCurrencyCountry}
                            onChange={(e) => setItemCurrencyCountry(e.target.value)}
                            autoFocus/>
                        </td>
                        <td>
                        <input
                            className="createRoleInput"
                            value={itemCurrency}
                            onChange={(e) => setItemCurrency(e.target.value)}/>
                        </td>
                        <td>
                            <input className="createRoleInput"
                            value={currencyValue}
                            onChange={(e)=>setCurrencyValue(e.target.value)}/>
                        </td>
                        <td>
                            <button
                            className="cancelRoleButton"
                            onClick={() => {
                                setItemCurrencyCountry("");
                                setItemCurrency("");
                                setCurrencyValue("");}}>
                            Cancel
                        </button>
                        <button
                            id="SubmitCreate"
                            className="createRoleButton"
                            onClick={() => {if (itemCurrencyCountry.trim() !== "" && parseFloat(currencyValue) >0 && itemCurrency.trim()!=="") {
                                        setIsOpen(true);
                                        createItemCurrency();}else{
                                            window.alert("Make sure Country and Currency Name is filled and it's Currency Value is Non Negative Decimal")
                                        }}}>
                            Add
                        </button>
                        </td>
                    </tr>
                    {renderItemCurrencyList()}
                    </tbody>
                    </table>
                    </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        itemInfo: state.itemInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateItemCurrency);