import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";

const EditProductionPlanning = ({ isLogged, poInfo, setSection, loadSo }) => {
  const [soDetail, setSoDetail] = useState({});
  const [lineItems, setLineItems] = useState([]);

  const ParamList = [
    ["orderNo"],
  ];
  const [productionParams, setProductionParams] = useState({
    orderNo: {
      inputType: "multiSelect",
      value: [],
      hintText: "Party Order No",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please select order no",
      disabled: false,
    },

  });
  const [error,setError]= useState('')

  useEffect(() => {
    getInformation();  
  }, []);

  async function getPrDetails() {
    var data = await fetchData({
      requestingPage: "soList",
      method: "post",
      url: "po-fetch/production-planning-detail/" + poInfo.productionPlanningId ,
      headers: { token: isLogged.accessToken, module: "Production Planning" },
    });
    if (data.msg === "success") {
      return data.asset[0]
    } else {
      console.log(data);
    }
  }

  useEffect(async () => {
    let paramCopy = Object.assign(productionParams);
    if (productionParams.orderNo.value.length) {

      var [detail, lineItemsCopy] = await salesOrderDetail(
        productionParams.orderNo.value
      );

      setSoDetail(detail);
      setLineItems(lineItemsCopy);

    } else {
      setSoDetail({});;
      setLineItems([]);

    }
  }, [productionParams.orderNo.value]);

  async function getInformation() {
    var orderList = await getOrderList();
    var detail = await getPrDetails();
    let partyOrder = orderList.filter((item)=>detail.partyOrder.includes(item.optionId))
     orderList =orderList.filter(order=>!detail.partyOrder.includes(order.optionId))
    var paramCopy = Object.assign(productionParams);
      paramCopy = update(paramCopy, {
        orderNo: { options: { $set: orderList },value:{$set:partyOrder} },
      });
    
    setProductionParams(paramCopy);
    setLineItems(detail.lineItems);
  }

  async function getOrderList() {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "post",
      url: `so-fetch/so-approved-list-mini`,
      headers: { token: isLogged.accessToken, module: "Production Planning" },
    });
    if (data.msg === "success") {
      var orderList = [];
      data.asset.forEach((item) => {
        var orderObj = { optionId: item.soId, optionName: item.soNo,
                         value:item.soId,label:item.soNo 
                       };
        orderList.push(orderObj);
      });
      return orderList;
    }
  }

  async function salesOrderDetail(id) {
    var data = await fetchData({
      requestingPage: "createCustomer",
      method: "post",
      url: `so-fetch/sales-order-line-items`,
      data:{soId:productionParams.orderNo.value},
      headers: { token: isLogged.accessToken, module: "Production Planning" },
    });
    if (data.msg === "success") {
      var lineItemsCopy = [];
      data.asset.forEach((item) => {
        // var newObj = Object.assign(item);
        // newObj.quantitySo = item.quantity;
        // newObj.unitPriceSo = item.unitPrice;
        // if (item.discount === null) {
        //   newObj.discountSo = 0;
        //   newObj.discount = 0;
        // } else {
        //   newObj.discountSo = item.discount;
        // }

        // newObj.quantity = "";
        // newObj.netPrice = 0;

        lineItemsCopy.push(item);
      });


      return [data.asset[0], lineItemsCopy];
    }
    return null
  }

  const submitOkClick = () => {
    setIsOpen(false);
    if (errorList.length === 0) {
      setSection("productionPlanningList");
    }
    setModalText("Uploading form. Please wait...");
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState(
    "Submitting Production Planning. Please wait.."
  );

  const updateParameter = ({ paramName, section, key, value }) => {
    // if (section === "general") {
      var salesParamsCopy = Object.assign(productionParams);
      salesParamsCopy = update(salesParamsCopy, {
        [paramName]: { [key]: { $set: value } },
      });

      setProductionParams(salesParamsCopy);
    // }
  };

  const [errorList, setErrorList] = useState([]);
  async function submitData(data) {
    setIsOpen(true);
    setDataSubmitted(false);
    var result = await fetchData({
      requestingPage: "createCustomer",
      method: "put",
      url: "po-edit/production-planning",
      headers: { token: isLogged.accessToken, module: "Production Planning" },
      data: data,
    });
    // console.log(result);

    if (result.msg === "success") {
      setModalText("Production Planning Edited Successfully!");
    } else {
      setModalText(`Upload failed: ${result.desc}`);
      setErrorList([result.desc]);
    }
    setDataSubmitted(true);
  }

  const renderFormElements = ({ elementList, param, section }) => {
    return elementList.map((element) => {
      return (
        <FormElement
          key={element}
          inputType={param[element].inputType}
          value={param[element].value}
          setInput={(value) => {
            updateParameter({
              // section,
              paramName: element,
              key: "value",
              value: value,
            });
          }}
          hintText={param[element].hintText}
          mandatory={param[element].mandatory}
          colSpan={param[element].colSpan}
          options={
            param[element].inputType === "options" || param[element].inputType === "multiSelect"
              ? param[element].options
              : null
          }
          error={param[element].error}
          rowSpan={
            element === "vendorLogo" || element === "otherBusinessDetails"
              ? param[element].rowSpan
              : null
          }
          placeholder={param[element].placeholder}
          title={param[element].title}
          disabled={param[element].disabled}
          mindate={param[element].mindate}
        />
      );
    });
  };

  const checkErrors = () => {
    if(productionParams.orderNo.value.length){
      let dataToServer = {
        orderNo: productionParams.orderNo.value.map(item=>item.value),
        ppId:poInfo.productionPlanningId
      }

      submitData(dataToServer);

    }else{
      var salesParamsCopy = Object.assign(productionParams);
      salesParamsCopy = update(salesParamsCopy, {
        orderNo: { error: { $set: true } },
      });

      setProductionParams(salesParamsCopy);
      setError(salesParamsCopy.orderNo.errorMessage)
    }

  };
  const lineItemsTableCols = 
     [
        "itemCode",
        "itemName",
        "cuttingLength",
        "from",
        "to",
        "quantity",
      ];
  return (
    <React.Fragment>
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
      <div className="formArea">
        <div
          style={{
            width: "80%",
            margin: "0 auto 2rem",
            // padding: "3rem 3rem 1rem 3rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex" }}>
            <div className="createSalesInvoiceGrid" style={{gridTemplateRows:"repeat(1,3fr)"}}>
              {renderFormElements({
                elementList: ParamList[0],
                param: productionParams,
                // section: "general",
              })}
            </div>
          </div>

          {lineItems.length > 0 ? (
            <table className="createItemPurchaseTable">
              <thead>
                <tr className="createVendorContactsTableHeader">
                  <td className="stickyFirstColumn">Section No</td>
                  <td>Section Name</td>
                  <td>Cutting Length</td>
                  <td>Weight From</td>
                  <td>Weight To</td>
                  <td>Quantity</td>           
                </tr>
              </thead>
              <tbody>
                {lineItems.map((row, j) => (
                    
                  <tr className="createVendorContactsTableRows" key={j}>
                    {lineItemsTableCols.map((key, i) => {
                      switch (key) {
                        case "itemName":
                          return(
                            <td key={i} className="stickyFirstColumn">{row[key]}</td>
                          )
                        case "quantity":
                          return(
                            <td key={i} >{`${row[key]} ${row["uom"]}`}</td>
                          )
                        default:
                          return (
                            <td key={i}>
                              {row[key] !== null ? row[key] : "-"}
                            </td>
                          );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
          <div className="poNetTotalSurchargeDiv">
              <div>
                No Items: <span>{lineItems.length}</span>
              </div>
            <div className="poSpacer">&nbsp;</div>
          </div>
        </div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{error}</p>
          <button
            className="submitButton"
            onClick={() => {
              checkErrors();
            }}>
            Submit
          </button>
        </div>
      </div>

     
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProductionPlanning);
