import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";

import resetIcon from "../../assets/return.svg";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

const CreateOpd = ({ isLogged, addTokenToState, setSection }) => {
    const lineItemParams = isLogged.showHideWeight ? ["itemId", "from", "to", "netWeight", "grossWeight", "uom", "orderQuantity", "quantity"] : ["itemId","uom", "orderQuantity", "quantity"];
    var opdParamList = [
        ["orderType", "orderId", "packingDate", "plantId", "storageId"],
        lineItemParams,
    ];

    const [opdParams, setOpdParams] = useState({
        orderType: {
            inputType: "options",
            value: "",
            hintText: "Order Type",
            mandatory: false,
            colSpan: 6,
            error: false,
            options: [
                { optionId: "Sales Order", optionName: "Sales Order" },
                { optionId: "Purchase Return", optionName: "Purchase Return" },
                { optionId: "Plant Transfer", optionName: "Plant Transfer" },
            ],
            errorMessage: "Please select order type",
            // disabled: true,
        }, from: {
            inputType: "text",
            value: "",
            hintText: "Weight From(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        to: {
            inputType: "text",
            value: "",
            hintText: "Weight To(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        netWeight: {
            inputType: "text",
            value: "",
            hintText: "Net Weight(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        grossWeight: {
            inputType: "text",
            value: "",
            hintText: "Gross Weight(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        orderId: {
            inputType: "options",
            value: "",
            hintText: "Order No",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select Sales order",
        },
        plantId: {
            inputType: "options",
            value: "",
            hintText: "Plant Name",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select Plant Name",
        },
        storageId: {
            inputType: "options",
            value: "",
            hintText: "Storage",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select Storage Location",
        },
        packingDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Packing Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter packing date",
            maxdate: true,
        },
        itemId: {
            inputType: "options",
            value: "",
            hintText: "Item",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select an Item",
        },
        uom: {
            inputType: "text",
            value: "",
            hintText: "UOM",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
            disabled: true,
        },
        orderQuantity: {
            inputType: "text",
            value: "",
            hintText: "Order Quantity",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
            disabled: true,
        },
        quantity: {
            inputType: "text",
            value: "",
            hintText: "Picking Qty",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please enter a valid picking quantity",
        },
    });

    const [showCustomerDetail, setShowCustomerDetail] = useState(false);
    const [orderDetail, setOrderDetail] = useState({});

    const [storageList, setStorageList] = useState({});
    const [itemList, setItemList] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    useEffect(async () => {
        var paramsCopy = Object.assign(opdParams);

        paramsCopy = update(paramsCopy, {
            plantId: { value: { $set: "" } },
        });

        if (paramsCopy.orderType.value !== "") {
            if (paramsCopy.orderType.value === "Plant Transfer") {
                var transferList = await getPlantTransferList();
                paramsCopy = update(paramsCopy, {
                    orderId: { options: { $set: transferList }, value: { $set: "" } },
                    plantId: { disabled: { $set: true } },
                    storageId: { disabled: { $set: true } },
                });
            }

            if (paramsCopy.orderType.value === "Sales Order") {
                var soList = await getSalesOrderList();
                paramsCopy = update(paramsCopy, {
                    orderId: { options: { $set: soList }, value: { $set: "" } },
                    plantId: { disabled: { $set: false } },
                    storageId: { disabled: { $set: false } },
                });
            }

            if (paramsCopy.orderType.value === "Purchase Return") {
                var prList = await getPurchaseReturnList();
                paramsCopy = update(paramsCopy, {
                    orderId: { options: { $set: prList }, value: { $set: "" } },
                    plantId: { disabled: { $set: false } },
                    storageId: { disabled: { $set: false } },
                });
            }
        } else {
            paramsCopy = update(paramsCopy, {
                orderId: { options: { $set: [] }, value: { $set: "" } },
                plantId: { disabled: { $set: false } },
                storageId: { disabled: { $set: false } },
            });
        }
        setOpdParams(paramsCopy);
    }, [opdParams.orderType]);

    useEffect(async () => {
        var paramsCopy = Object.assign(opdParams);

        var orderDetailCopy = {};
        if (opdParams.orderId.value !== "") {
            if (opdParams.orderType.value === "Sales Order") {
                orderDetailCopy = await getSoDetail();
            }

            if (opdParams.orderType.value === "Plant Transfer") {
                orderDetailCopy = await getPlantTransferDetail();
            }

            if (opdParams.orderType.value === "Purchase Return") {
                orderDetailCopy = await getPrDetail();
                orderDetailCopy.lineItems = orderDetailCopy.prLineItems;
            }

            if (orderDetailCopy?.lineItems?.length > 0) {
                var itemOptions = [];
                orderDetailCopy.lineItems.forEach((item) => {
                    var lineItemIdentity = "";
                    switch (opdParams.orderType.value) {
                        case "Sales Order":
                            lineItemIdentity = item.soLineItemId;
                            break;

                        case "Purchase Return":
                            lineItemIdentity = item.prLineItemId;
                            break;

                        case "Plant Transfer":
                            lineItemIdentity = item.transferLineItemId;
                            break;
                    }
                    var itemOption = {
                        optionId: item.itemId,
                        optionName: item.itemName,
                        lineItemId: lineItemIdentity,
                        from: item.from,
                        to: item.to,
                        netWeight: item.netWeight,
                        grossWeight: item.grossWeight,
                        orderQuantity: item.quantity,
                        uom: item.uom,
                    };
                    itemOptions.push(itemOption);
                });

                paramsCopy = update(paramsCopy, { itemId: { options: { $set: itemOptions }, value: { $set: "" } } });

                if (opdParams.orderType.value === "Plant Transfer") {
                    paramsCopy = update(paramsCopy, {
                        plantId: { value: { $set: orderDetailCopy.fromPlantId } },
                        storageId: { value: { $set: orderDetailCopy.fromStorageId }, serverCall: { $set: false } },
                    });
                }
                setOpdParams(paramsCopy);
            }
            setOrderDetail(orderDetailCopy);
            setShowCustomerDetail(true);
        } else {
            paramsCopy = update(paramsCopy, { itemId: { options: { $set: [] }, value: { $set: "" } } });
            setOpdParams(paramsCopy);
        }
    }, [opdParams.orderId]);

    useEffect(async () => {
        var paramsCopy = Object.assign(opdParams);

        if (opdParams.itemId.value !== "") {
            var currentItem = orderDetail.lineItems.filter((item) => item.itemId === opdParams.itemId.value)[0];

            paramsCopy = update(paramsCopy, {
                from: { value: { $set: currentItem.from } },
                to: { value: { $set: currentItem.to } },
                netWeight: { value: { $set: currentItem.netWeight } },
                grossWeight: { value: { $set: currentItem.grossWeight } },
                uom: { value: { $set: currentItem.uom } },
                orderQuantity: {
                    value: { $set: opdParams.orderType.value === "Purchase Return" ? currentItem.returnQuantity : currentItem.quantity },
                },
            });
            setOpdParams(paramsCopy);
        }
    }, [opdParams.itemId]);

    useEffect(() => {
        var paramsCopy = Object.assign(opdParams);

        if (opdParams.plantId.value !== "") {
            if (opdParams.storageId.serverCall) {
                paramsCopy = update(paramsCopy, { storageId: { options: { $set: storageList[opdParams.plantId.value] }, value: { $set: "" } } });
            } else {
                paramsCopy = update(paramsCopy, { storageId: { options: { $set: storageList[opdParams.plantId.value] } } });
            }
            setOpdParams(paramsCopy);
        } else {
            paramsCopy = update(paramsCopy, { storageId: { options: { $set: [] }, value: { $set: "" } } });
            setOpdParams(paramsCopy);
        }
    }, [opdParams.plantId]);

    const getInformation = async () => {
        await checkToken();

        var [plantList, storageLocListFormatted] = await getPlants();

        setStorageList(storageLocListFormatted);

        // var soList = await getSalesOrderList();
        var paramsCopy = Object.assign(opdParams);
        paramsCopy = update(paramsCopy, { plantId: { options: { $set: plantList } } });
        setOpdParams(paramsCopy);
    };

    const history = useHistory();

    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getPlants() {
        var plantsData = await fetchData({
            requestingPage: "plantList",
            method: "get",
            url: "fetch/plants",
            headers: { token: isLogged.accessToken, module: "OPD" },
        });

        var plantList = [];
        var storageLocList = {};

        if (plantsData.msg === "success") {
            plantsData.asset.forEach((plant) => {
                var plantObj = {};
                plantObj.optionId = plant.plant.plantId;
                plantObj.optionName = plant.plant.plantName;
                plantObj.address =
                    plant.plant.plantName +
                    ", " +
                    plant.plant.city +
                    ", " +
                    plant.plant.state +
                    (plant.plant.zipCode !== null ? ` - ${plant.plant.zipCode}, ` : ", ") +
                    plant.plant.country;
                plantList.push(plantObj);

                storageLocList[plant.plant.plantId] = [];
                plant.storage.forEach((store) => {
                    var storeObj = {};
                    storeObj.optionId = store.storageId;
                    storeObj.optionName = store.storageName;
                    storeObj.address =
                        store.address +
                        ", " +
                        plant.plant.city +
                        ", " +
                        plant.plant.state +
                        (plant.plant.zipCode !== null ? ` - ${plant.plant.zipCode}, ` : ", ") +
                        plant.plant.country;
                    storeObj.gstNo = plant.plant.gstNo;
                    storageLocList[plant.plant.plantId].push(storeObj);
                });
            });

            return [plantList, storageLocList];
        }
    }

    async function getPlantTransferList() {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "post",
            url: "store-fetch/stock-transfer-approved-list-mini",
            headers: { token: isLogged.accessToken, module: "OPD" },
        });
        if (data.msg === "success") {
            var optionArray = [];
            data.asset.forEach((dataItem) => {
                var a = { optionName: dataItem.transferNo, optionId: dataItem.transferId };
                optionArray.push(a);
            });
            return optionArray;
        }else{
            return [];
        }
    }

    async function getSalesOrderList() {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "post",
            url: "so-fetch/opd-pre-create-so-approved-list-mini",
            headers: { token: isLogged.accessToken, module: "OPD" },
        });
        if (data.msg === "success") {
            var optionArray = [];
            data.asset.forEach((dataItem) => {
                var a = { optionName: dataItem.soNo, optionId: dataItem.soId };
                optionArray.push(a);
            });
            return optionArray;
        }else{
            return [];
        }
    }

    async function getPurchaseReturnList() {
        var data = await fetchData({
            requestingPage: "itemList",
            method: "post",
            url: "po-fetch/pr-approved-list-mini",
            headers: { token: isLogged.accessToken, module: "OPD" },
        });
        if (data.msg === "success") {
            var optionArray = [];
            data.asset.forEach((dataItem) => {
                var a = { optionName: dataItem.prNo, optionId: dataItem.prId };
                optionArray.push(a);
            });
            return optionArray;
        }else{
            return [];
        }
    }

    async function getSoDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: `so-fetch/so-detail/${opdParams.orderId.value}`,
            headers: { token: isLogged.accessToken, module: "OPD" },
        });
        if (data.msg === "success") {
            return data.asset[0];
        }
    }

    async function getPlantTransferDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "post",
            url: `store-fetch/stock-transfer-detail/${opdParams.orderId.value}`,
            headers: { token: isLogged.accessToken, module: "OPD" },
        });
        if (data.msg === "success") {
            return data.asset[0];
        }else{
            return [];
        }
    }

    async function getPrDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "post",
            url: "po-fetch/pr-detail/" + opdParams.orderId.value,
            headers: { token: isLogged.accessToken, module: "OPD" },
        });
        if (data.msg === "success") {
            return data.asset[0];
        }else{
            return [];
        }
    }

    const [opdErrors, setOpdErrors] = useState([]);
    const [itemErrors, setItemErrors] = useState([]);

    const submitOkClick = () => {
        setIsOpen(false);
        if (opdErrors.length === 0) {
            setSection("opdList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Submitting OPD. Please wait..");

    const RenderCustomerDetail = () => {
        switch (opdParams.orderType.value) {
            case "Sales Order":
                return (
                    <React.Fragment>
                        <div className="detailTitle">{orderDetail.customerName}</div>
                        <div className="detailText">
                            {`Customer ID: ${orderDetail.customerId}`},
                            <br /> {orderDetail.customerAddress},
                            <br /> {orderDetail.customerCity}
                            {orderDetail.customerPinCode !== null ? `, - ${orderDetail.customerPinCode}` : null}
                            {orderDetail.customerMobile !== null || orderDetail.customerPhone !== null ? <br /> : null}
                            {orderDetail.customerMobile !== null || orderDetail.customerPhone !== null ? `Phone: ` : null}
                            {orderDetail.customerMobile !== null ? orderDetail.customerMobile : null}
                            {orderDetail.customerPhone !== null ? `, ${orderDetail.customerPhone} ` : null}
                            {orderDetail.customerEmail !== null ? <br /> : null}
                            {orderDetail.customerEmail !== null ? `Email Id: ${orderDetail.customerEmail}` : null}
                            {orderDetail.customerGstNo !== null ? <br /> : null}
                            {orderDetail.customerGstNo !== null ? `GST No:  ${orderDetail.customerGstNo}` : null}
                        </div>
                    </React.Fragment>
                );

            case "Plant Transfer":
                return (
                    <React.Fragment>
                        <div className="detailTitle">{orderDetail.toStorageName}</div>
                        <div className="detailText">
                            {orderDetail.toPlantName},
                            <br /> {orderDetail.toStorageAddress}
                            {orderDetail.toPlantCity !== null ? <br /> : null}
                            {orderDetail.toPlantCity !== null ? orderDetail.toPlantCity : null}
                            {orderDetail.toPlantState !== null ? <br /> : null}
                            {orderDetail.toPlantState !== null ? orderDetail.toPlantState : null}
                            {orderDetail.toPlantPinCode !== null ? ` - ${orderDetail.toPlantPinCode}` : null}
                        </div>
                    </React.Fragment>
                );

            case "Purchase Return":
                return (
                    <React.Fragment>
                        <div className="detailTitle">{orderDetail.companyName}</div>
                        <div className="detailText">
                            {`Customer ID: ${orderDetail.companyId}`},
                            <br /> {orderDetail.vendorAddress},
                            <br /> {orderDetail.vendorCity}
                            {orderDetail.vendorPinCode !== null ? `, - ${orderDetail.vendorPinCode}` : null}
                            {orderDetail.vendorMobile !== null || orderDetail.vendorPhone !== null ? <br /> : null}
                            {orderDetail.vendorMobile !== null || orderDetail.vendorPhone !== null ? `Phone: ` : null}
                            {orderDetail.vendorMobile !== null ? orderDetail.vendorMobile : null}
                            {orderDetail.vendorPhone !== null ? `, ${orderDetail.vendorPhone} ` : null}
                            {orderDetail.vendorEmail !== null ? <br /> : null}
                            {orderDetail.vendorEmail !== null ? `Email Id: ${orderDetail.vendorEmail}` : null}
                            {orderDetail.vendorGstNo !== null ? <br /> : null}
                            {orderDetail.vendorGstNo !== null ? `GST No:  ${orderDetail.vendorGstNo}` : null}
                        </div>
                    </React.Fragment>
                );

            default:
                return null;
        }
    };

    const updateOpdParameter = ({ paramName, key, value }) => {

        var paramsCopy = Object.assign(opdParams);

        if ((paramName === "orderId" && paramsCopy.orderId.value !== "") || (paramName === "orderType" && paramsCopy.orderType.value !== "")) {
            var yes = window.confirm("Changing this option will reset all item information below. Proceed?");

            if (yes) {
                paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
                paramsCopy = update(paramsCopy, {
                    itemId: { value: { $set: "" } },
                    from: { value: { $set: "" } },
                    to: { value: { $set: "" } },
                    netWeight: { value: { $set: "" } },
                    grossWeight: { value: { $set: "" } },
                    uom: { value: { $set: "" } },
                    orderQuantity: { value: { $set: "" } },
                    plantId: { value: { $set: "" } },
                    storageId: { value: { $set: "" } },
                    quantity: { value: { $set: "" } },
                });
                setItemList([]);

                if (value === "") {
                    setShowCustomerDetail(false);
                    setOrderDetail({});
                }

                setShowCustomerDetail(false);
            }
        } else {
            paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        }
        setOpdParams(paramsCopy);
    };

    const editSubSectionItem = (i, action) => {
        var itemListCopy = itemList.slice();
        var paramsCopy = Object.assign(opdParams);
        switch (action) {
            case "edit":
                var proceed = true;
                opdParamList[1].forEach((item) => {
                    if (opdParams[item].value !== "") {
                        proceed = false;
                    }
                });

                if (!proceed) {
                    var yes = window.confirm("Unsaved item information found. Overwrite?");
                }

                if (proceed || yes) {
                    var editItem = itemListCopy.splice(i, 1)[0];

                    paramsCopy = update(paramsCopy, {
                        itemId: { value: { $set: editItem.itemId }, options: { $set: editItem.itemOptions } },
                        from: { value: { $set: editItem.from } },
                        to: { value: { $set: editItem.to } },
                        netWeight: { value: { $set: editItem.netWeight } },
                        grossWeight: { value: { $set: editItem.grossWeight } },
                        quantity: { value: { $set: editItem.quantity } },
                        uom: { value: { $set: editItem.uom } },
                        orderQuantity: { value: { $set: editItem.orderQuantity } },
                    });

                    setOpdParams(paramsCopy);
                    setItemList(itemListCopy);
                }

                break;

            case "delete":
                /*    To be verified 
                let alteredOptions= paramsCopy.itemId.options.push(itemListCopy.splice(i,1)[0]);
                 paramsCopy = update( paramsCopy ,{ itemId : {options: {$set: alteredOptions }}}); */
                itemListCopy.splice(i, 1);
                setItemList(itemListCopy);

                break;
        }
    };

    const checkItemError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(opdParams);

        opdParamList[1].forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (item === "quantity") {
                if (paramsCopy.quantity.value === "" || isNaN(paramsCopy.quantity.value)) {
                    paramsCopy = update(paramsCopy, { quantity: { error: { $set: true } } });
                } else {
                    if (parseFloat(paramsCopy.quantity.value < 0)) {
                        paramsCopy = update(paramsCopy, { quantity: { error: { $set: true } } });
                    } else {
                        paramsCopy = update(paramsCopy, { quantity: { error: { $set: false } } });
                    }
                }
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
        });
        if(paramsCopy["orderQuantity"].value < paramsCopy["quantity"].value){
            errorList.push("Picking Quantity Cannot be greater than Order Quantity");
        }
        if (errorList.length === 0) {
            var itemListCopy = JSON.parse(JSON.stringify(itemList));

            var opdItem = paramsCopy.itemId.options.filter((option) => option.optionId === paramsCopy.itemId.value)[0];

            itemListCopy.push({
                itemId: paramsCopy.itemId.value,
                item: opdItem.optionName,
                from: opdItem.from,
                to: opdItem.to,
                netWeight: opdItem.netWeight,
                grossWeight: opdItem.grossWeight,
                uom: opdItem.uom,
                orderQuantity: opdItem.orderQuantity,
                lineItemId: opdItem.lineItemId,
                itemOptions: paramsCopy.itemId.options,
                quantity: paramsCopy.quantity.value,
            });

            setItemList(itemListCopy);
            /*  To be Verified
            let filteredOptions= paramsCopy.itemId.options.filter((obj)=>obj.optionId !=paramsCopy.itemId.value );
             $set filteredOptions in itemId options */
            paramsCopy = update(paramsCopy, {
                itemId: { value: { $set: "" } },
                from: { value: { $set: "" } },
                to: { value: { $set: "" } },
                netWeight: { value: { $set: "" } },
                grossWeight: { value: { $set: "" } },
                quantity: { value: { $set: "" } },
                orderQuantity: { value: { $set: "" } },
                uom: { value: { $set: "" } },
            });
        }

        setOpdParams(paramsCopy);
        setItemErrors(errorList);
    };

    const checkOpdError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(opdParams);

        opdParamList[0].forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
        });

        if (itemList.length <= 0) {
            errorList.push("Enter atleast one item information");
        }

        if (errorList.length === 0) {

            var data2server = {
                orderId: paramsCopy.orderId.value,
                orderType: paramsCopy.orderType.value,
                packingDate: paramsCopy.packingDate.value,
                storageId: paramsCopy.storageId.value,
            };

            var lineItems = [];
            itemList.map((item) => {
                var lineObj = { itemId: item.itemId, quantity: item.quantity, lineItemId: item.lineItemId };
                lineItems.push(lineObj);
            });

            data2server.lineItems = lineItems;
            submitData(data2server);
        } else {
            console.log("Errors found");
        }
        setOpdErrors(errorList);
        setOpdParams(paramsCopy);
    };

    const submitData = async (data2server) => {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "createPoItem",
            method: "post",
            url: "store-create/opd",
            headers: { token: isLogged.accessToken, module: "OPD" },
            data: data2server,
        });

        if (result.msg === "success") {
            setDataSubmitted(true);
            setModalText("OPD Submitted for Approval");
        } else {
            console.log(result);
        }
    };

    const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value !== null ? param[element].value : ""}
                    setInput={(value) => {
                        updateOpdParameter({ section, paramName: element, key: "value", value: value });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                    maxdate={param[element].maxdate}
                />
            );
        });
    };

    const renderSubSection = () => {
        return (
            <React.Fragment>
                <div className="purchaseOrderIndividualItemsArea">
                    {renderFormElements({ elementList: opdParamList[1], param: opdParams, section: "lineItem" })}
                    <FormElement
                        inputType="addButton"
                        value="+ Add"
                        colSpan={3}
                        setInput={() => {
                            checkItemError();
                        }}
                    />
                    <div
                        style={{
                            marginTop: "26px",
                            gridColumn: "span 1",
                            color: "#666",
                            background: "rgb(230,230,230)",
                            cursor: "pointer",
                        }}
                    >
                        <img
                            style={{ padding: "10px", width: "1rem", height: "1rem" }}
                            src={resetIcon}
                            alt="Reset"
                            onClick={() => {
                                var yes = window.confirm("Clear Input data?");

                                if (yes) {
                                    var opdParamsEmpty = Object.assign(opdParams);
                                    opdParamList[1].forEach((item) => {
                                        opdParamsEmpty[item].value = "";
                                        opdParamsEmpty[item].error = false;
                                    });

                                    setOpdParams(opdParamsEmpty);
                                    setItemErrors([]);
                                }
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const lineItemCols = isLogged.showHideWeight ? ["item", "from", "to", "netWeight", "grossWeight", "uom", "orderQuantity", "quantity"] : ["item","uom", "orderQuantity", "quantity"];
    const RenderTable = () => {
        return (
            <table className="createVendorContactsTable">
                <thead>
                    <tr className="createVendorContactsTableHeader" key="tableHeader">
                        <td>Item</td>
                        {isLogged.showHideWeight ?
                        <>
                        <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td> </> : '' }
                        <td>UOM</td>
                        <td>Order Quantity</td>
                        <td>Picking Quantity</td>
                        <td className="vendorListHeadingActions">Actions</td>
                    </tr>
                </thead>
                {itemList.length > 0 ? (
                    <tbody>
                        <RenderTableRows rows={itemList} tab="contactActions" />
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="createVendorContactsTableRows" key="emptyRow">
                            {lineItemCols.map((item, i) => {
                                return <td key={i}>&nbsp;</td>;
                            })}
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                )}
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        return rows.map((row, j) => {
            return (
                <tr className="createVendorContactsTableRows" key={j}>
                    {lineItemCols.map((key, i) => {
                        return <td key={i}>{row[key] ? row[key] : " - "}</td>;
                    })}
                    <td className="vendorListInfoActions">
                        <img
                            alt="Edit Record"
                            className="createVendorContactsAction"
                            src={editIcon}
                            onClick={() => {
                                editSubSectionItem(j, "edit");
                            }}
                        />
                        <img
                            alt="Delete Record"
                            className="createVendorContactsAction"
                            src={deleteIcon}
                            onClick={() => {
                                var reset = window.confirm("Delete record?");
                                if (reset) {
                                    editSubSectionItem(j, "delete");
                                }
                            }}
                        />
                    </td>
                </tr>
            );
        });
    };

    const renderErrorMessage = () => {
        if (opdErrors.length > 0) return opdErrors[0];
        else if (itemErrors.length > 0) return itemErrors[0];
    };

    const RenderNoItems = () => {
        if (itemList.length > 0) {
            var uniqArr = Array.from(new Set(itemList.map((item) => item.itemId)));
            return <span>{uniqArr.length}</span>;
        } else {
            return <span>0</span>;
        }
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="formArea">
                <div
                    style={{
                        width: "1000px",
                        margin: "0 auto 2rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div className="createSalesOrderGrid" style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
                            {renderFormElements({ elementList: opdParamList[0], param: opdParams })}
                        </div>
                        <div style={{ width: "300px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{showCustomerDetail ? <RenderCustomerDetail /> : null}</div>
                            </div>
                        </div>
                    </div>
                    <div className="purchaseOrderSubSectionArea" style={{width:"90%"}}>
                        {renderSubSection()}
                        <RenderTable />
                    </div>
                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                Number of unique items:&nbsp;
                                <RenderNoItems />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="cancelButton"
                        onClick={() => {
                            var reset = window.confirm("Do you want to reset all fields?");
                            if (reset) {
                                var paramsCopy = Object.assign(opdParams);

                                opdParamList[0].forEach((item) => {
                                    if (item !== "orderType") paramsCopy[item].value = "";
                                    paramsCopy[item].error = false;
                                });

                                opdParamList[1].forEach((item) => {
                                    paramsCopy[item].value = "";
                                    paramsCopy[item].error = false;
                                });

                                setOpdParams(paramsCopy);
                                setItemList([]);
                                setItemErrors([]);
                                setOpdErrors([]);
                                setShowCustomerDetail(false);
                            }
                        }}
                    >
                        Reset Fields
                    </button>

                    <button
                        className="submitButton"
                        onClick={() => {
                            setOpdErrors([]);
                            checkOpdError();
                        }}
                    >
                        Submit for Approval
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOpd);
