import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { setDcListTab, viewDcId, editDcId, viewDcReturnId, editDcReturnId } from "../../redux/PurchaseOrder/PoInfoActions";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import viewIcon from "../../assets/view.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import approveIcon from "../../assets/approve2.svg";
import FormElement from "../SmallComponents/FormElement";
import Pagination from "../CommonFunctions/pagination";
import { handleSort } from "../CommonFunctions/utils";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import SortUpGreen from "../../assets/SortUpGreen.svg";

const DcListPage = ({
    isLogged,
    viewDcId, editDcId,
    setDcListTab,
    viewDcReturnId, editDcReturnId,
    poInfo, addTokenToState,
    setApproval, setEditAndApprove, setSection
}) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [plantId, setPlantId] = useState("");
    const [dcUnApprovedList, setDcUnApprovedList] = useState([]);
    const [dcApprovedList, setDcApprovedList] = useState([]);
    const [dcDeclinedList, setDcDeclinedList] = useState([]);
    const [dcReturnList, setDcReturnList] = useState([]);
    const [dcReturnApprovedList, setDcReturnApprovedList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [PageSize, setRowsPerPage] = useState(10);

    const dcUnApprovedCurrentPage = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return dcUnApprovedList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, dcUnApprovedList, PageSize]);
    const dcApprovedCurrentPage = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return dcApprovedList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, dcApprovedList, PageSize]);
    const dcDeclinedCurrentPage = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return dcDeclinedList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, dcDeclinedList, PageSize]);
    const dcReturnCurrentPage = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return dcReturnList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, dcReturnList, PageSize]);
    const dcReturnApprovedCurrentPage = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return dcReturnApprovedList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, dcReturnApprovedList, PageSize]);
    useState(() => {
        getInformation();
    }, [])

    async function getInformation() {
        await checkToken();
    }

    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getDcUnApprovedList() {
        var dcList1 = await fetchData({
            requestingPage: "dcListPage",
            method: "post",
            url: "dclist-fetch/dc-unapproved-list",
            headers: { token: isLogged.accessToken, module: "DC List" },
            data: { plantId }
        })
        if (dcList1.msg === "success") {
            var dcUnApproved = [];
            dcList1.asset.forEach((dataItem, i) => {
                var newElement = Object.assign(dataItem);
                newElement.collapse = true;
                dcUnApproved.push(newElement);
            })
            setDcUnApprovedList(dcUnApproved);
        }
        setLoading(false);
    }

    async function getDcApprovedList() {
        var dcList2 = await fetchData({
            requestingPage: "dcListPage",
            method: "post",
            url: "dclist-fetch/dc-approved-list",
            headers: { token: isLogged.accessToken, module: "DC List" },
            data: { plantId }
        })
        if (dcList2.msg === "success") {
            var dcApproved = [];
            dcList2.asset.forEach((dataItem, i) => {
                var newElement = Object.assign(dataItem);
                newElement.collapse = true;
                dcApproved.push(newElement);
            })
            setDcApprovedList(dcApproved);
        }
        setLoading(false);
    }

    async function getDcDeclinedList() {
        var dcList3 = await fetchData({
            requestingPage: "dcListPage",
            method: "post",
            url: "dclist-fetch/dc-declined-list",
            headers: { token: isLogged.accessToken, module: "DC List" },
            data: { plantId }
        })
        if (dcList3.msg === "success") {
            var dcDeclined = [];
            dcList3.asset.forEach((dataItem, i) => {
                var newElement = Object.assign(dataItem);
                newElement.collapse = true;
                dcDeclined.push(newElement);
            })
            setDcDeclinedList(dcDeclined);
        }
        setLoading(false);
    }

    async function getDcReturnList() {
        var dcList4 = await fetchData({
            requestingPage: "dcListPage",
            method: "post",
            url: "dclist-fetch/dc-return-unapproved-list",
            headers: { token: isLogged.accessToken, module: "DC List" },
            data: { plantId }
        })
        if (dcList4.msg === "success") {
            var dcReturnList = [];
            dcList4.asset.forEach((dataItem, i) => {
                var newElement = Object.assign(dataItem);
                newElement.collapse = true;
                dcReturnList.push(newElement);
            })
            setDcReturnList(dcReturnList);
        }
        setLoading(false);
    }

    async function getDcReturnApprovedList() {
        var dcList5 = await fetchData({
            requestingPage: "dcListPage",
            method: "post",
            url: "dclist-fetch/dc-return-approved-list",
            headers: { token: isLogged.accessToken, module: "DC List" },
            data: { plantId }
        })
        if (dcList5.msg === "success") {
            var dcReturnApprovedList = [];
            dcList5.asset.forEach((dataItem, i) => {
                var newElement = Object.assign(dataItem);
                newElement.collapse = true;
                dcReturnApprovedList.push(newElement);
            })
            setDcReturnApprovedList(dcReturnApprovedList);
        }
        setLoading(false);
    }
    const access = isLogged.access["DC List"];

    const setSelectedTab = (tab) => {
        setDcListTab(tab)
    }

    const editItemCollapse = (list, index) => {
        var listCopy = [];

        switch (list) {
            case "dcUnApprovedList":
                listCopy = dcUnApprovedList.slice();
                break;

            case "dcApprovedList":
                listCopy = dcApprovedList.slice();
                break;

            case "dcDeclinedList":
                listCopy = dcDeclinedList.slice();
                break;

            case "dcReturnList":
                listCopy = dcReturnList.slice();
                break;

            case "dcReturnApprovedList":
                listCopy = dcReturnApprovedList.slice();
        }

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });

        switch (list) {
            case "dcUnApprovedList":
                setDcUnApprovedList(listCopy);
                break;

            case "dcApprovedList":
                setDcApprovedList(listCopy);
                break;

            case "dcDeclinedList":
                setDcDeclinedList(listCopy);
                break;

            case "dcReturnList":
                setDcReturnList(listCopy);
                break;

            case "dcReturnApprovedList":
                setDcReturnApprovedList(listCopy);
        }
    };

    const tablist = [
        { id: "dcUnApprovedList", name: "DC List" },
        { id: "dcApprovedList", name: "Approved DC List" },
        { id: "dcDeclinedList", name: "Declined DC List" },
        { id: "dcReturnList", name: "DC Return List" },
        { id: "dcReturnApprovedList", name: "DC Return Approved List" },
    ];

    useEffect(async () => {
        setLoading(true);
        await checkToken();
        switch (poInfo.dcListTab) {
            case "dcUnApprovedList":
                setDcUnApprovedList([]);
                getDcUnApprovedList();

                break;

            case "dcApprovedList":
                setDcApprovedList([]);
                getDcApprovedList();
                break;

            case "dcDeclinedList":
                setDcDeclinedList([]);
                getDcDeclinedList();
                break;

            case "dcReturnList":
                setDcReturnList([]);
                getDcReturnList();
                break;

            case "dcReturnApprovedList":
                setDcReturnApprovedList([]);
                getDcReturnApprovedList();
                break;

            default:
                break;
        }
    }, [poInfo.dcListTab, plantId]);

    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={poInfo.dcListTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => setDcListTab(tab.id)}
                >
                    {tab.name}
                </div>
            );
        });
    };
    const [order, setOrder] = useState({ col: "dcNo", state: true, data: "" });
    const DcListCommonColumns = ({ data }) => {

        return (
            <>
                <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                    setOrder({
                        col: "dcNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "dcNo",
                        state: order.state,
                    });
                }}>Dc Id<img
                        className="sortArrow"
                        src={
                            order.col === "dcNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "userDcNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "userDcNo",
                        state: order.state,
                    });
                }}>Dc No<img
                        className="sortArrow"
                        src={
                            order.col === "userDcNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "orderDate",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "orderDate",
                        state: order.state,
                    });
                }}>Order date<img
                        className="sortArrow"
                        src={
                            order.col === "orderDate"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "expectDate",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "expectDate",
                        state: order.state,
                    });
                }}>Expected Date<img
                        className="sortArrow"
                        src={
                            order.col === "expectDate"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "prodNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "prodNo",
                        state: order.state,
                    });
                }}>Production Order No<img
                        className="sortArrow"
                        src={
                            order.col === "prodNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "vendorName",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "vendorName",
                        state: order.state,
                    });
                }}>Vendor Name<img
                        className="sortArrow"
                        src={
                            order.col === "vendorName"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "processName",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "processName",
                        state: order.state,
                    });
                }}>Process Name<img
                        className="sortArrow"
                        src={
                            order.col === "processName"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "createdBy",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "createdBy",
                        state: order.state,
                    });
                }}>Created By<img
                        className="sortArrow"
                        src={
                            order.col === "createdBy"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
            </>
        )
    }

    const DcReturnCommonColumns = ({ data }) => {

        return (
            <>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "dcReturnNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "dcReturnNo",
                        state: order.state,
                    });
                }}>Dc Return Id<img
                        className="sortArrow"
                        src={
                            order.col === "dcReturnNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "userDcReturnNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "userDcReturnNo",
                        state: order.state,
                    });
                }}>Dc Return No<img
                        className="sortArrow"
                        src={
                            order.col === "userDcReturnNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "dcReturnDate",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "dcReturnDate",
                        state: order.state,
                    });
                }}>Return Date<img
                        className="sortArrow"
                        src={
                            order.col === "dcReturnDate"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "dcNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "dcNo",
                        state: order.state,
                    });
                }}>Dc Id<img
                        className="sortArrow"
                        src={
                            order.col === "dcNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "prodNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "prodNo",
                        state: order.state,
                    });
                }}>Production Order No<img
                        className="sortArrow"
                        src={
                            order.col === "prodNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "createdBy",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "createdBy",
                        state: order.state,
                    });
                }}>Created By<img
                        className="sortArrow"
                        src={
                            order.col === "createdBy"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
            </>
        )
    }
    const renderDcUnApprovedList = () => {
        return dcUnApprovedList.map((data, i) => {
            if ((plantId.length > 0 && plantId.some((obj) => obj.value == data.plantId)) || plantId.length == 0)
                return (
                    <React.Fragment>
                        <tr className="vendorList">
                            <td className="vendorListInfo">{data.dcNo}</td>
                            <td className="vendorListInfo">{data.userDcNo}</td>
                            <td className="vendorListInfo">{data.orderDate}</td>
                            <td className="vendorListInfo">{data.expectDate}</td>
                            <td className="vendorListInfo">{data.prodNo}</td>
                            <td className="vendorListInfo">{data.vendorName}</td>
                            <td className="vendorListInfo">{data.processName}</td>
                            <td className="vendorListInfo">{data.createdBy}</td>
                            <td className="vendorListInfoActions">
                                <img alt="View Record" className="vendorActionImages" src={viewIcon}
                                    title="View DC"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        viewDcId(data.dcId);
                                        setSection("dcDetail");
                                        setApproval(false);
                                    }} />
                                <img alt="Edit Dc" className="vendorActionImages"
                                    title={access === "Edit" || access === "App/Dec" ? "Edit this Dc" : "Access Denied"}
                                    src={access === "Edit" || access === "App/Dec" ? editIcon : editIconDisabled}
                                    style={access === "Edit" || access === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (access === "Edit" || access === "App/Dec") {
                                            editDcId(data.dcId);
                                            setSection("editDc");
                                            setEditAndApprove(false);
                                        }
                                    }} />
                                <img alt="Approve/Decline Dc" className="vendorActionImages"
                                    title={access === "App/Dec" ? "Approve/Decline this Dc" : "Access Denied"}
                                    src={approveIcon}
                                    style={access === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (access === "App/Dec") {
                                            viewDcId(data.dcId);
                                            setApproval(true);
                                            setSection("dcDetail");
                                        }
                                    }} />
                                <img alt="Toggle Expand" className="vendorActionImages"
                                    title="Toggle Expand" src={data.collapse ? downIcon : upIcon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        editItemCollapse("dcUnApprovedList", i);
                                    }} />
                            </td>
                        </tr>
                        <tr>{!data.collapse ? <RenderSubTable tab="dcUnApprovedList" lineItem={data.lineItems} /> : null}</tr>
                    </React.Fragment>
                );
        })
    }

    const renderDcApprovedList = () => {
        return dcApprovedList.map((data, i) => {
            if ((plantId.length > 0 && plantId.some((obj) => obj.value == data.plantId)) || plantId.length == 0)
                return (
                    <React.Fragment>
                        <tr className="vendorList">
                            <td className="vendorListInfo">{data.dcNo}</td>
                            <td className="vendorListInfo">{data.userDcNo}</td>
                            <td className="vendorListInfo">{data.orderDate}</td>
                            <td className="vendorListInfo">{data.expectDate}</td>
                            <td className="vendorListInfo">{data.prodNo}</td>
                            <td className="vendorListInfo">{data.vendorName}</td>
                            <td className="vendorListInfo">{data.processName}</td>
                            {/* <td className="vendorListInfo">{data.inwardStatus|| "-"}</td>
                        <td className="vendorListInfo">{data.outWardStatus||"-"}</td> */}
                            <td className="vendorListInfo">{data.createdBy}</td>
                            <td className="vendorListInfo">{data.approvedBy}</td>
                            <td className="vendorListInfoActions">
                                <img alt="View Record" className="vendorActionImages" src={viewIcon}
                                    title="View DC"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        viewDcId(data.dcId);
                                        setSection("dcDetail");
                                        setApproval(false);
                                    }} />
                                <img alt="Toggle Expand" className="vendorActionImages"
                                    title="Toggle Expand" src={data.collapse ? downIcon : upIcon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        editItemCollapse("dcApprovedList", i);
                                    }} />
                            </td>
                        </tr>
                        <tr>{!data.collapse ? <RenderSubTable tab="dcApprovedList" lineItem={data.lineItems} /> : null}</tr>
                    </React.Fragment>
                );
        })
    }

    const renderDcDeclinedList = () => {
        return dcDeclinedList.map((data, i) => {
            if ((plantId.length > 0 && plantId.some((obj) => obj.value == data.plantId)) || plantId.length == 0)
                return (
                    <React.Fragment>
                        <tr className="vendorList">
                            <td className="vendorListInfo">{data.dcNo}</td>
                            <td className="vendorListInfo">{data.userDcNo}</td>
                            <td className="vendorListInfo">{data.orderDate}</td>
                            <td className="vendorListInfo">{data.expectDate}</td>
                            <td className="vendorListInfo">{data.prodNo}</td>
                            <td className="vendorListInfo">{data.vendorName}</td>
                            <td className="vendorListInfo">{data.processName}</td>
                            <td className="vendorListInfo">{data.createdBy}</td>
                            <td className="vendorListInfo">{data.declinedBy}</td>
                            <td className="vendorListInfoActions">
                                <img alt="View Record" className="vendorActionImages" src={viewIcon}
                                    title="View DC"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        viewDcId(data.dcId);
                                        setSection("dcDetail");
                                        setApproval(false);
                                    }} />
                                <img alt="Toggle Expand" className="vendorActionImages"
                                    title="Toggle Expand" src={data.collapse ? downIcon : upIcon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        editItemCollapse("dcDeclinedList", i);
                                    }} />
                            </td>
                        </tr>
                        <tr>{!data.collapse ? <RenderSubTable tab="dcDeclinedList" reason={data.declineReason} lineItem={data.lineItems} /> : null}</tr>
                    </React.Fragment>
                );
        })
    }

    const renderDcReturnList = () => {
        return dcReturnList.map((data, i) => {
            if ((plantId.length > 0 && plantId.some((obj) => obj.value == data.plantId)) || plantId.length == 0)
                return (<React.Fragment>
                    <tr className="vendorList" key={data.dcrId}>
                        <td className="vendorListInfo">{data.dcReturnNo}</td>
                        <td className="vendorListInfo">{data.userDcReturnNo}</td>
                        <td className="vendorListInfo">{data.dcReturnDate}</td>
                        <td className="vendorListInfo">{data.dcNo}</td>
                        <td className="vendorListInfo">{data.prodNo}</td>
                        <td className="vendorListInfo">{data.createdBy}</td>
                        <td className="vendorListInfoActions">
                            <img alt="View Record" className="vendorActionImages" src={viewIcon}
                                title="View DC Return"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewDcReturnId(data.dcrId);
                                    setSection("dcReturnDetail");
                                    setApproval(false);
                                }} />
                            <img alt="Edit Dc" className="vendorActionImages"
                                title={access === "Edit" || access === "App/Dec" ? "Edit this Dc Return" : "Access Denied"}
                                src={access === "Edit" || access === "App/Dec" ? editIcon : editIconDisabled}
                                style={access === "Edit" || access === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (access === "Edit" || access === "App/Dec") {
                                        editDcReturnId(data.dcrId);
                                        setSection("editDcReturn");
                                        setEditAndApprove(false);
                                    }
                                }} />
                            <img alt="Approve/Decline Dc" className="vendorActionImages"
                                title={access === "App/Dec" ? "Approve this Dc Return" : "Access Denied"}
                                src={approveIcon}
                                style={access === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (access === "App/Dec") {
                                        viewDcReturnId(data.dcrId);
                                        setApproval(true);
                                        setSection("dcReturnDetail");
                                    }
                                }} />
                            <img alt="Toggle Expand" className="vendorActionImages"
                                title="Toggle Expand" src={data.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("dcReturnList", i);
                                }} />
                        </td>
                    </tr>
                    <tr>{!data.collapse ? <RenderReturnSubTable tab={"dcReturnList"} lineItems={data.lineItems} /> : null}</tr>
                </React.Fragment>
                );
        })
    }
    const renderDcReturnApprovedList = () => {
        return dcReturnApprovedList.map((data, i) => {
            if ((plantId.length > 0 && plantId.some((obj) => obj.value == data.plantId)) || plantId.length == 0)
                return (
                    <React.Fragment>
                        <tr className="vendorList" key={data.dcrId}>
                            <td className="vendorListInfo">{data.dcReturnNo}</td>
                            <td className="vendorListInfo">{data.userDcReturnNo}</td>
                            <td className="vendorListInfo">{data.dcReturnDate}</td>
                            <td className="vendorListInfo">{data.dcNo}</td>
                            <td className="vendorListInfo">{data.prodNo}</td>
                            <td className="vendorListInfo">{data.createdBy}</td>
                            <td className="vendorListInfo">{data.approvedBy}</td>
                            <td className="vendorListInfoActions">
                                <img alt="View Record" className="vendorActionImages" src={viewIcon}
                                    title="View DC"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        viewDcReturnId(data.dcrId);
                                        setSection("dcReturnDetail");
                                        setApproval(false);
                                    }} />
                                <img alt="Toggle Expand" className="vendorActionImages"
                                    title="Toggle Expand" src={data.collapse ? downIcon : upIcon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        editItemCollapse("dcReturnApprovedList", i);
                                    }} />
                            </td>
                        </tr>
                        <tr>{!data.collapse ? <RenderReturnSubTable tab={"dcReturnList"} lineItems={data.lineItems} /> : null}</tr>
                    </React.Fragment>
                )
        })
    }
    const RenderReturnSubTable = ({ tab, lineItems }) => {
        return (
            <td colSpan={tab === "dcReturnApprovedList" ? "10" : "8"} className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Item Name</td>
                            <td className="vendorListInnerTableHeading">Item Code</td>
                            {isLogged.showHideWeight ?
                                <>
                                    <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                                    <td className="vendorListInnerTableHeading">Weight to(gms)</td>
                                    <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                                    <td className="vendorListInnerTableHeading">Gross Weight(gms)</td>
                                </> : ''}
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Production Qty</td>
                            <td className="vendorListInnerTableHeading">Current DC Qty</td>
                            <td className="vendorListInnerTableHeading">DC Return Qty</td>
                        </tr>
                    </thead>
                    <tbody>
                        {lineItems.map((row, i) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.dcrLineId}>
                                    <td className="vendorListInnerTableInfo">{row.itemName}</td>
                                    <td className="vendorListInnerTableInfo">{row.itemCode}</td>
                                    {isLogged.showHideWeight ?
                                        <>
                                            <td className="vendorListInnerTableInfo">{row.from ? row.from : " - "}</td>
                                            <td className="vendorListInnerTableInfo">{row.to ? row.to : " - "}</td>
                                            <td className="vendorListInnerTableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                            <td className="vendorListInnerTableInfo">{row.grossWeight ? row.grossWeight : " - "}</td> </> : ''}
                                    <td className="vendorListInnerTableInfo">{row.uom}</td>
                                    <td className="vendorListInnerTableInfo">{row.prodQty}</td>
                                    <td className="vendorListInnerTableInfo">{row.currentDc}</td>
                                    <td className="vendorListInnerTableInfo">{row.currentReturnDc}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>);
    };

    const RenderSubTable = ({ tab, lineItem, reason }) => {
        return (
            <td colSpan={tab === "dcApprovedList" ? "12" : "10"} className="vendorListInnerTableWrapper">
                {tab === "dcDeclinedList" ? (
                    <div style={{ boxSizing: "border-box", width: "100%", padding: "10px", backgroundColor: "#eee" }}>
                        <span style={{ color: "red", fontWeight: "bold" }}>Reason:&nbsp;&nbsp;&nbsp;</span>
                        {reason}
                    </div>
                ) : null}
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Item Name</td>
                            <td className="vendorListInnerTableHeading">Item Code</td>
                            {isLogged.showHideWeight ?
                                <>
                                    <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                                    <td className="vendorListInnerTableHeading">Weight to(gms)</td>
                                    <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                                    <td className="vendorListInnerTableHeading">Gross Weight(gms)</td>
                                </> : ''}
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Production Qty</td>
                            {tab !== "dcDeclinedList" ? <td className="vendorListInnerTableHeading">Total DC Qty</td> : null}
                            <td className="vendorListInnerTableHeading">Current DC Qty</td>
                        </tr>
                    </thead>
                    <tbody>
                        {lineItem.map((row, i) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.lineId}>
                                    <td className="vendorListInnerTableInfo">{row.itemName}</td>
                                    <td className="vendorListInnerTableInfo">{row.itemCode}</td>
                                    {isLogged.showHideWeight ?
                                        <>
                                            <td className="vendorListInnerTableInfo">{row.from ? row.from : " - "}</td>
                                            <td className="vendorListInnerTableInfo">{row.to ? row.to : " - "}</td>
                                            <td className="vendorListInnerTableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                            <td className="vendorListInnerTableInfo">{row.grossWeight ? row.grossWeight : " - "}</td>
                                        </> : ''}
                                    <td className="vendorListInnerTableInfo">{row.uom}</td>
                                    <td className="vendorListInnerTableInfo">{row.quantity}</td>
                                    {tab !== "dcDeclinedList" ? <td className="vendorListInnerTableInfo">{row.previousDc}</td> : null}
                                    <td className="vendorListInnerTableInfo">{row.currentDc}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>);
    }
    const renderSubSection = () => {
        switch (poInfo.dcListTab) {
            case "dcUnApprovedList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <DcListCommonColumns data={dcUnApprovedCurrentPage} />
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{dcUnApprovedList.length > 0 ? renderDcUnApprovedList() : null}</tbody>
                        </table>
                        {dcUnApprovedList.length === 0 && !loading ?
                            (<div className="noRecordsYet">No Records available</div>) : (loading ? <div className="loader" style={{ margin: "1rem auto" }}></div> : null)}
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={dcUnApprovedList.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </React.Fragment>
                );

            case "dcApprovedList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <DcListCommonColumns data={dcApprovedCurrentPage} />
                                    <td className="vendorListHeading" onClick={(e) => {
                                        setOrder({
                                            col: "approvedBy",
                                            state: !order.state,
                                        });
                                        handleSort({
                                            data: dcApprovedCurrentPage,
                                            col: "approvedBy",
                                            state: order.state,
                                        });
                                    }}>Approved By<img
                                            className="sortArrow"
                                            src={
                                                order.col === "approvedBy"
                                                    ? order.state
                                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                        /></td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{dcApprovedList.length > 0 ? renderDcApprovedList() : null}</tbody>
                        </table>
                        {dcApprovedList.length === 0 && !loading ?
                            (<div className="noRecordsYet">No Records available</div>) : (loading ? <div className="loader" style={{ margin: "1rem auto" }}></div> : null)}
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={dcApprovedList.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </React.Fragment>
                );

            case "dcDeclinedList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <DcListCommonColumns data={dcDeclinedCurrentPage} />
                                    <td className="vendorListHeading" onClick={(e) => {
                                        setOrder({
                                            col: "declinedBy",
                                            state: !order.state,
                                        });
                                        handleSort({
                                            data: dcDeclinedCurrentPage,
                                            col: "declinedBy",
                                            state: order.state,
                                        });
                                    }}>Declined By<img
                                            className="sortArrow"
                                            src={
                                                order.col === "declinedby"
                                                    ? order.state
                                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                        /></td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{dcDeclinedList.length > 0 ? renderDcDeclinedList() : null}</tbody>
                        </table>
                        {dcDeclinedList.length === 0 && !loading ?
                            (<div className="noRecordsYet">No Records available</div>) : (loading ? <div className="loader" style={{ margin: "1rem auto" }}></div> : null)}
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={dcDeclinedList.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </React.Fragment>
                );

            case "dcReturnList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <DcReturnCommonColumns data={dcReturnCurrentPage} />
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{dcReturnList.length > 0 ? renderDcReturnList() : null}</tbody>
                        </table>
                        {dcReturnList.length === 0 && !loading ?
                            (<div className="noRecordsYet">No Records available</div>) : (loading ? <div className="loader" style={{ margin: "1rem auto" }}></div> : null)}
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={dcReturnList.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </React.Fragment>
                );

            case "dcReturnApprovedList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <DcReturnCommonColumns data={dcReturnApprovedCurrentPage} />
                                    <td className="vendorListHeading" onClick={(e) => {
                                        setOrder({
                                            col: "approvedBy",
                                            state: !order.state,
                                        });
                                        handleSort({
                                            data: dcReturnApprovedCurrentPage,
                                            col: "approvedBy",
                                            state: order.state,
                                        });
                                    }}>Approved By <img
                                            className="sortArrow"
                                            src={
                                                order.col === "approvedBy"
                                                    ? order.state
                                                        ? SortUpGreen : SortDownGreen
                                                    : null}
                                        /></td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{dcReturnApprovedList.length > 0 ? renderDcReturnApprovedList() : null}</tbody>
                        </table>
                        {dcReturnApprovedList.length === 0 && !loading ?
                            (<div className="noRecordsYet">No Records available</div>) : (loading ? <div className="loader" style={{ margin: "1rem auto" }}></div> : null)}
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={dcReturnApprovedList.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </React.Fragment>
                );

            default:
                return ("");
        }
    }
    return (
        <React.Fragment>
            <div key={1} className="poListTabs">
                <RenderTabList key={1} tablist={tablist} />
            </div>
            <div style={{ display: "inline-flex", marginLeft: "25px" }} className="listPageParamsGrid">
                <span className="listPageParams">
                    <FormElement setInput={(value) => { setPlantId(value); }}
                        inputType={"options"}
                        value={plantId}
                        hintText={"Plant Name"}
                        options={isLogged.plantOptions}
                        colSpan={1}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false} />
                </span>
                <span style={{ flexGrow: 0, width: "80px" }}>
                    <FormElement
                        setInput={(value) => {
                            setRowsPerPage(value);
                        }}
                        inputType={"number"}
                        value={PageSize}
                        hintText={"Rows Per Page"}
                        colSpan={0.5}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false}
                    />
                </span>
            </div>
            <div key={3} className="vendorListArea">{renderSubSection()}</div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        setDcListTab: (tab) => dispatch(setDcListTab(tab)),
        viewDcId: (dcId) => dispatch(viewDcId(dcId)),
        editDcId: (dcId) => dispatch(editDcId(dcId)),
        viewDcReturnId: (dcReturnId) => dispatch(viewDcReturnId(dcReturnId)),
        editDcReturnId: (dcReturnId) => dispatch(editDcReturnId(dcReturnId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DcListPage);