import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";

import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import resetIcon from "../../assets/return.svg";
import FormHint from "../SmallComponents/FormHint";
import InputField from "../SmallComponents/InputField";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import fetchData from "../../serverCall/fetchData";
import {
  validateEmail,
  validateMandatory,
  validateMobileNumber,
} from "../CommonFunctions/ValidateFields";

const EditSalesOrder = ({
  setSection,
  isLogged,
  addTokenToState,
  poInfo,
  editApprove,
}) => {
  const [newCustomer, setNewCustomer] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  // const [showCuttingLength, setShowCuttingLength] = useState(false)
  const [tcsPercentage,setTcsPercentage] = useState(false);
  const salesOrderParamList = [
    ["customerId"],
    ["customerName", "mobileNo", "email"],
    [
      "soDate",
      "plantId",
      "storageId",
      "expectedDeliveryDate",
      "reference",
      "transporterName",
      "parentId"
      // "deliveryAddressId"
    ],
    ["deliveryAddressId"],
    ["address","country","state","cityId"]
  ];

  const [salesOrderParams, setSalesOrderParams] = useState({
    customerId: {
      inputType: "options",
      value: "",
      hintText: "Customer Name",
      mandatory: true,
      colSpan: 12,
      error: false,
      options: [],
      errorMessage: "Please select a Customer",
    },
    customerName: {
      inputType: "text",
      value: "",
      hintText: "Customer Name",
      mandatory: true,
      colSpan: 12,
      error: false,
      errorMessage: "Please provide a customer name",
    },

    mobileNo: {
      inputType: "text",
      value: "",
      hintText: "Mobile No",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Provide a valid mobile number",
    },
    email: {
      inputType: "text",
      value: "",
      hintText: "Email",
      mandatory: false,
      colSpan: 12,
      error: false,
      errorMessage: "Provide a valid email address",
    },

    soDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Order Date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a Order date",
      // mindate: true,
    },
    reference: {
      inputType: "text",
      value: "",
      hintText: "Reference",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    plantId: {
      inputType: "options",
      value: "",
      hintText: "Plant",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please pick a Plant",
    },
    storageId: {
      inputType: "options",
      value: "",
      hintText: "Store",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please select Store",
    },
    expectedDeliveryDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Expected Delivery Date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please Select delivery date",
      // mindate: true,
    },
    transporterName: {
      inputType: "text",
      value: "",
      hintText: "Transporter Name",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    deliveryAddressId: {
      inputType: "options",
      value: "",
      hintText: "Delivery Address",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      options: []
    },
    address: {
      inputType: "text",
      value: "",
      hintText: "Address",
      mandatory: true,
      colSpan: 8,
      error: false,
      errorMessage: "Please provide company address",
    },
    country: {
      inputType: "options",
      value: "",
      hintText: "Country",
      mandatory: true,
      colSpan: 4,
      options: [],
      error: false,
      errorMessage: "Please select country",
  },
  state: {
      inputType: "options",
      value: "",
      hintText: "State",
      mandatory: true,
      colSpan: 4,
      options: [],
      error: false,
      errorMessage: "Please select state",
  },
  cityId: {
      inputType: "options",
      value: "",
      hintText: "City",
      mandatory: true,
      colSpan: 4,
      options: [],
      error: false,
      errorMessage: "Please select city",
  },
  parentId :{
    inputType: "options",
    value: "",
    hintText: "Parent Sales Order Id",
    mandatory: false,
    colSpan: 4,
    options: [],
    error: false,
    errorMessage: "Please select parentId",
}
  });

  let lineItemsParamsList = isLogged.showHideWeight
    ? [
      "itemCategory",
      "sectionNo",
      "sectionName",
      "cuttingLength",
      "from",
      "to",
      "uomId",
      "quantity",
      "unitPrice",
      // "otherCharges",
      "discount",
      "netPrice",
      
    ]
    : [
      "itemCategory",
      "sectionNo",
      "sectionName",
      "cuttingLength",
      "from",
      "to",
      "uomId",
      "quantity",
      "unitPrice",
      // "otherCharges",
      "discount",
      "netPrice",
    ];
  const [lineItemParams, setLineItemParams] = useState({
    itemCategory: {
      inputType: "options",
      value: "",
      hintText: "Item Category",
      mandatory: false,
      colSpan: 8,
      options: [],
      error: false,
      errorMessage: "Select an Item",
      // callServer: true,
  },
  sectionNo: {
    inputType: "options",
    value: "",
    hintText: "Section No",
    mandatory: false,
    options:[],
    colSpan: 6,
    error: false,
    errorMessage: "",
    // disabled: true,
    },
    sectionName: {
      inputType: "text",
      value: "",
      hintText: "Section Name",
      mandatory: false,
      colSpan: 7,
      error: false,
      errorMessage: "",
      disabled: true,
    },
    cuttingLength: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Cutting Length",
      mandatory: true,
      colSpan: 4,
      error: false,
      errorMessage: "Select Cutting Length above to autofill this field",
      disabled: false,
    },
    from: {
      inputType: "text",
      value: "",
      hintText: "Weight From(gms)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: false,
    },
    to: {
      inputType: "text",
      value: "",
      hintText: "Weight To(gms)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: false,
    },
    quantity: {
      inputType: "number",
      value: "",
      hintText: "Quantity",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Enter a valid quantity",
    },
    uomId: {
      inputType: "options",
      value: "",
      hintText: "UOM",
      mandatory: true,
      colSpan: 5,
      options: [],
      error: false,
      errorMessage: "Pick a UOM",
    },
    unitPrice: {
      inputType: "float",
      value: "",
      hintText: "Unit Price",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid price",
    },
    discount: {
      inputType: "float",
      value: "",
      hintText: "Discount (%)",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid discount percent",
      disabled: false,
    },
    netPrice: {
      inputType: "text",
      value: "",
      hintText: "Net Price",
      mandatory: false,
      colSpan: 5,
      error: false,
      errorMessage: "",
      disabled: true,
    },
    // otherCharges: {
    //   inputType: "float",
    //   value: "",
    //   hintText: "Premium Charges",
    //   mandatory: false,
    //   colSpan: 5,
    //   error: false,
    //   errorMessage: "Enter Valid Amount Charges",
    //   disabled: false
    // },
    soLineItemId: null,
  });

  const [lineItemsList, setLineItemsList] = useState([]);

  const [transportChargesError, setTransportChargesError] = useState(false);
  const [otherChargesError, setOtherChargesError] = useState(false);
  const [salePriceTypes, setSalePriceTypes] = useState([]);

  const [storeDetail, setStoreDetail] = useState({});
  const [showStoreDetail, setShowStoreDetail] = useState(false);

  const [storageLocList, setStorageLocList] = useState({});
  const [customerDetail, setCustomerDetail] = useState({});
  const [showCustomerDetail, setShowCustomerDetail] = useState(false);
  const [itemUomList, setItemUomList] = useState({});

  const [itemsTotalCost, setItemsTotalCost] = useState(0);
  const [otherCharges, setOtherCharges] = useState(0);
  const [transportCharges, settransportCharges] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [salesInstruction, setSalesInstruction] = useState("");

  const [redirect, setRedirect] = useState(false);
  const [soDetail, setSoDetail] = useState([]);
  const [finalDiscount, setFinalDiscount] = useState(0);
  const [finalDiscountError, setFinalDiscountError] = useState("");
  const [showDiscount, setShowDiscount] = useState(false);
  const [cuttingLength, setCuttingLength] = useState([])
  const [itemList,setItemList] = useState()

  useEffect(() => {
    getInformation();
  }, []);

  async function getUoms() {
    var data = await fetchData({ requestingPage: "uomList", method: "get", url: "fetch/uom", headers: { token: isLogged.accessToken,module: "UOM" } });
    if (data.msg === "success") {
      setItemUomList(data.asset);
      return data.asset
    } else {

    }
}

  const getInformation = async () => {
    setDataSubmitted(false);
    setIsOpen(true);
    setModalText("Getting SO Information");

    await checkToken();
    var customerList = await getCustomerList();
    var [plantList, storageLocListFormatted] = await getPlants();
    var parentIdList = await getParentSalesOrdes()
     parentIdList =  parentIdList.filter(so=>so.optionId !== poInfo.editSoId)
 
    var [itemList] = await getItemUom();
    var [categoryList,subCategoryList] = await getCategory();
    var dataDim = await getDim({ field: "country" });
    var parsedDataDim = await parseDim(dataDim, "country");
    var uomList = await getUoms()

    var soDetail = await getSoDetail();

    //  soDetail.parentSoLineItems.forEach(item=>item["parentItem"] = true)
    setSoDetail(soDetail);
    setStorageLocList(storageLocListFormatted);

    var salesParamsCopy = Object.assign(salesOrderParams);

    salesParamsCopy = update(salesParamsCopy, {
      customerId: {
        options: { $set: customerList },
        value: { $set: soDetail.customerId },
      },
      country: { options: { $set: parsedDataDim }, value: { $set: "" } },
      plantId: {
        options: { $set: plantList },
        value: { $set: soDetail.plantId },
      },
      storageId: { options: { $set: storageLocListFormatted[soDetail.plantId] }, value: { $set: soDetail.storageId } },
      soDate: { value: { $set: soDetail.soDate } },
      reference: { value: { $set: soDetail.reference } },
      expectedDeliveryDate: { value: { $set: soDetail.expectedDeliveryDate } },
      transporterName: { value: { $set: soDetail.transporterName } },
      deliveryAddressId: { value: { $set: soDetail.deliveryAddressId || "" }, options: { $set: soDetail.deliveryAddressOptions } },
      parentId :{options :{$set : parentIdList},value:{$set : soDetail.parentId}}
    });
    setSalesOrderParams(salesParamsCopy);


    setItemsTotalCost(parseFloat(soDetail.netTotal));
    setOtherCharges(parseFloat(soDetail.otherCharges));
    settransportCharges(parseFloat(soDetail.transportCharges));
    setTotalAmount(parseFloat(soDetail.totalAmount));
    setTcsPercentage(parseFloat(soDetail.tcsPercentage))
    setSalesInstruction(soDetail.salesInstruction || "");
    if (soDetail.finalDiscount) {
      setShowDiscount(true);
      setFinalDiscount(soDetail.finalDiscount)
    }

    setLineItemsList(soDetail.lineItems);

    let cuttingLength = await getCuttingLength();
    

    var lineItemParamsCopy = Object.assign(lineItemParams);
    const trimSectionList = itemList.filter(item1 =>
      !soDetail.lineItems.some(item2 => (item2.sectionNo === item1.sectionNo)))
    lineItemParamsCopy = update(lineItemParamsCopy, {
      uomId: { options: { $set: uomList } },
      sectionNo: { options: { $set: trimSectionList } },
      itemCategory :{options :{$set :categoryList}},
      cuttingLength: { options: { $set: cuttingLength } }
    });
    setLineItemParams(lineItemParamsCopy);
    // setItemUomList(itemUomListFormatted);
   
  setItemList(itemList)

    setIsOpen(false);
  };


  async function getParentSalesOrdes(){
    var categoryData = await fetchData({
      requestingPage: "getCategory",
      method: "get",
      url: "so-fetch/sales-order-list",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    if(categoryData.msg === "success"){
      return categoryData.asset
    }
  }

  async function getState() {
    if (salesOrderParams.country.value !== "") {
        var dataDim = await getDim({ field: "state", filterBy: "country_id", filterValue: salesOrderParams.country.value });
        var parsedDataDim = await parseDim(dataDim, "state");
        var salesOrderParamsCopy = Object.assign(salesOrderParams);
        salesOrderParamsCopy = update(salesOrderParamsCopy, {
            state: {
                options: { $set: parsedDataDim },
                value: { $set: "" },
            },
        });

        setSalesOrderParams(salesOrderParamsCopy);
    }
}

async function getCity() {
  if (salesOrderParams.state.value !== "") {
      var dataDim = await getDim({ field: "city", filterBy: "state_id", filterValue: salesOrderParams.state.value });
      var parsedDataDim = await parseDim(dataDim, "cityId");
      var salesOrderParamsCopy = Object.assign(salesOrderParams);
      salesOrderParamsCopy = update(salesOrderParamsCopy, {
          cityId: {
              options: { $set: parsedDataDim },
              value: { $set: "" },
          },
      });

      setSalesOrderParams(salesOrderParamsCopy);
  }
}

useEffect(() => {
  getState();
  // eslint-disable-next-line
}, [salesOrderParams.country]);

useEffect(() => {
  getCity();
  // eslint-disable-next-line
}, [salesOrderParams.state]);


  async function getCategory() {
    var categoryData = await fetchData({
        requestingPage: "getCategory",
        method: "get",
        url: "fetch/categories",
        headers: { token: isLogged.accessToken, module: "Dropdowns" },
    });

    var categoryList = [];
    var subCategoryList = {};

    if (categoryData.msg === "success") {
        categoryData.asset.forEach((categ) => {
            var catObj = {};
            catObj.optionId = categ.category.categoryId;
            catObj.optionName = categ.category.categoryName;
            categoryList.push(catObj);

            subCategoryList[categ.category.categoryId] = [];

            categ.subCategory.forEach((subCateg) => {
                var subCatObj = {};
                subCatObj.optionId = subCateg.subCategoryId;
                subCatObj.optionName = subCateg.subCategoryName;

                subCategoryList[categ.category.categoryId].push(subCatObj);
            });
        });
        return [categoryList, subCategoryList];
    }
}

  async function getCuttingLength() {

    var data = await fetchData({
      requestingPage: "sectionMaster",
      method: "post",
      url: "fetch/cutting-length",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
    if (data.msg === "success") {
      setCuttingLength(data.asset)
      return data.asset

    }

  }


  const history = useHistory();

  async function getLastSaleUnitPrice(data) {
    var result = await fetchData({
      url: 'so-fetch/last-unit-price-of-item',
      method: "post",
      data,
      requestingPage: "createPoItem",
      headers: { token: isLogged.accessToken, module: "Sales Order" }
    });

    if (result.msg === 'success') {
      let { invoiceUnitPrice, createdDate } = result.asset;
      if (invoiceUnitPrice && createdDate) {
        return `${createdDate} : ₹${invoiceUnitPrice}`
      }
      else {
        return " ";
      }
    } else {

      return " ";
    }
  }
  useEffect(() => {
    let lineItemParamsCopy = Object.assign(lineItemParams);
    // if (salesOrderParams.customerId.value && lineItemParams.itemId.value) {

      getLastSaleUnitPrice({
        customerId: salesOrderParams.customerId.value,
        // itemId: lineItemParams.itemId.value,
      }).then(async (resp) => {
        /// if line item changes fetch detail by so date
        if (salesOrderParams.soDate.value)
          // lineItemParamsCopy = await getItemDetail(lineItemParamsCopy);

        setLineItemParams(lineItemParamsCopy)
      });

  }, [salesOrderParams.customerId.value]);


  async function checkToken() {
    //console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      //console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      //console.log("Token not changed");
    }
  }

  async function getCustomerList() {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "get",
      url: "fetch/customer-list",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
    if (data.msg === "success") {
      var optionArray = [];
      data.asset.forEach((dataItem) => {
        var a = {
          optionName: dataItem.customer,
          optionId: dataItem.customerId,
        };
        optionArray.push(a);
      });

      return optionArray;
    } else {
      //console.log(data);
    }
  }
  async function getPlants() {
    var plantsData = await fetchData({
      requestingPage: "plantList",
      method: "get",
      url: "fetch/plants",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    var plantList = [];
    var storageLocList = {};

    if (plantsData.msg === "success") {
      plantsData.asset.forEach((plant) => {
        var plantObj = {};
        plantObj.optionId = plant.plant.plantId;
        plantObj.optionName = plant.plant.plantName;
        plantObj.address =
          plant.plant.plantName +
          ", " +
          plant.plant.city +
          ", " +
          plant.plant.state +
          (plant.plant.zipCode !== null
            ? ` - ${plant.plant.zipCode}, `
            : ", ") +
          plant.plant.country;
        plantList.push(plantObj);

        storageLocList[plant.plant.plantId] = [];
        plant.storage.forEach((store) => {
          var storeObj = {};
          storeObj.optionId = store.storageId;
          storeObj.optionName = store.storageName;
          storeObj.address =
            store.address +
            ", " +
            plant.plant.city +
            ", " +
            plant.plant.state +
            (plant.plant.zipCode !== null
              ? ` - ${plant.plant.zipCode}, `
              : ", ") +
            plant.plant.country;
          storeObj.gstNo = plant.plant.gstNo;
          storageLocList[plant.plant.plantId].push(storeObj);
        });
      });

      return [plantList, storageLocList];
    }
  }
  const RenderStoreDetail = () => {
    return (
      <React.Fragment>
        <div className="detailTitle">Store Detail</div>
        <div className="detailText">
          Address: {storeDetail?.address}
          <br />
          GST No: {storeDetail?.gstNo}
        </div>
      </React.Fragment>
    );
  };
  async function CustomerDetailAndOptions() {
    let saleParamsCopy = Object.assign(salesOrderParams);
    if (salesOrderParams.customerId.value !== "") {
      let deliveryAddOpts = await getCustomerDetail();
      saleParamsCopy = update(saleParamsCopy, {
        deliveryAddressId: {
          options: { $set: deliveryAddOpts }
        }
      });
      setShowCustomerDetail(true);
    } else {
      saleParamsCopy = update(saleParamsCopy, {
        deliveryAddressId: {
          options: { $set: [] }, value: { $set: "" }
        }
      });
      setShowCustomerDetail(false);
    }
    setSalesOrderParams(saleParamsCopy);
  }

  useEffect(() => {
    CustomerDetailAndOptions();
  }, [salesOrderParams.customerId.value]);

  async function getSoDetail() {
    var data = await fetchData({
      requestingPage: "vendorDetail",
      method: "get",
      url: `so-fetch/so-detail/${poInfo.editSoId}`,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      // data: { itemId: lineItemParams.itemId.value, poDate: purchaseOrderParams.poDate.value },
    });
    if (data.msg === "success") {
      return data.asset[0];
    }
  }

  async function getCustomerDetail() {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "get",
      url: `fetch/customer/${salesOrderParams.customerId.value}`,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
    if (data.msg === "success") {
      setCustomerDetail(data.asset.customerDetail[0]);
      return data.asset.deliveryDetail
    }
  }

  const RenderCustomerDetail = () => {
    return (
      <React.Fragment>
        <div className="detailTitle">{customerDetail.companyName}</div>
        <div className="detailText">
          {`Customer ID: ${customerDetail.customerId}`},
          <br /> {customerDetail.address},
          <br /> {customerDetail.city}, {customerDetail.state}{" "}
          {customerDetail.zipCode !== null
            ? `, - ${customerDetail.zipCode}`
            : null}
          {customerDetail.mobile !== null || customerDetail.phone !== null ? (
            <br />
          ) : null}
          {customerDetail.mobile !== null || customerDetail.phone !== null
            ? `Phone: `
            : null}
          {customerDetail.mobile !== null ? customerDetail.mobile : null}
          {customerDetail.phone !== null ? `, ${customerDetail.phone} ` : null}
          {customerDetail.email !== null ? <br /> : null}
          {customerDetail.email !== null
            ? `Email Id: ${customerDetail.email}`
            : null}
          {customerDetail.gstNo !== null ? <br /> : null}
          {customerDetail.gstNo !== null
            ? `GST No:  ${customerDetail.gstNo}`
            : null}
        </div>
      </React.Fragment>
    );
  };

  async function getItemUom() {
    var itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data: { tab: "Active",type:"Sales" },
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    var itemList = [];
    if (itemData.msg === "success") {

      itemData.asset.forEach((item) => {
        var itemObj = {};
        itemObj.optionId = item.item.itemCode;
        itemObj.optionName = item.item.itemCode;
        itemObj.itemCode = item.item.itemCode;
        itemObj.itemName = item.item.itemName;
        // itemObj.optionId = item.item.sectionNo;
        // itemObj.optionName = item.item.sectionNo;
          itemObj.categoryId = item.item.categoryId;  
          itemObj.itemId = item.item.itemId;   
          itemObj.categoryName = item.item.categoryName;
        itemObj.sectionNo = item.item.itemCode;
        itemObj.sectionName = item.item.itemName;
        itemList.push(itemObj);

      });

      return [itemList];
    }
  }

  const updatePOParameter = ({ paramName, section, key, value }) => {
    var lineParamsCopy = Object.assign(lineItemParams);
    if (section === "general") {
        var salesParams = Object.assign(salesOrderParams);
            salesParams = update(salesParams, {
            [paramName]: { [key]: { $set: value } },
          });
      if (paramName === "plantId") {
            if (value) {
                salesParams = update(salesParams, {
                  storageId: {
                    options: { $set: storageLocList[value] },
                  },
                });
                // setSalesOrderParams(salesParams);
            }
            else {
                salesParams = update(salesParams, {
                  storageId: { options: { $set: [] }, value: { $set: "" } },
                });
            }
      }
      if (paramName === "storageId") {
            if (value) {
                setShowStoreDetail(true);
                setStoreDetail(
                  storageLocList[salesOrderParams.plantId.value]?.filter(
                    (arr) => arr.optionId === value
                  )[0]
                );
            } else {
                setShowStoreDetail(false);
            }
      }
      if (paramName === "poDate") {
          lineParamsCopy = update(lineParamsCopy, {
            // itemId: { callServer: { $set: true } },
          });
          setLineItemParams(lineParamsCopy);
      }
      setSalesOrderParams(salesParams);
    } else {
      lineParamsCopy = update(lineParamsCopy, {
        [paramName]: { [key]: { $set: value } },
      });
      if (paramName === "itemCategory") {
        if (value) {
          const filteredItems = itemList.filter(item => { return item.categoryId === value })

          const trimSectionList = filteredItems.filter(item1 =>
            !lineItemsList.some(item2 => (item2.sectionNo === item1.sectionNo)))

          lineParamsCopy = update(lineParamsCopy, {
            sectionNo: { options: { $set: trimSectionList } },
          });
        } else {

          const trimSectionList = itemList.filter(item1 =>
            !lineItemsList.some(item2 => (item2.sectionNo === item1.sectionNo)))
            lineParamsCopy = update(lineParamsCopy, {
              sectionNo: { options: { $set: trimSectionList } },
            });

        }
      }
    if(paramName === "sectionNo"){
      if (value) {
        const filteredSections = lineParamsCopy['sectionNo']?.options.filter(item => { return item.sectionNo === value })
        lineParamsCopy = update(lineParamsCopy, {
          sectionName: { value: { $set: filteredSections[0]?.itemName } },
        });
      } else {

        lineParamsCopy = update(lineParamsCopy, {
          sectionName: {value: { $set: "" } },
        });
      }
    }
      setLineItemParams(lineParamsCopy);
    }
  };

  useEffect(() => {

    var _otherCharges = otherCharges;
    var _transportCharges = transportCharges;
    var _finalDiscount = finalDiscount;

    if (!otherCharges) {
      _otherCharges = 0;
    }

    if (!transportCharges) {
      _transportCharges = 0;
    }

    if (finalDiscount === "") {
      _finalDiscount = 0;
    }

    var returnVal =
      !isNaN(_otherCharges) && parseFloat(_otherCharges) >= 0
        ? !isNaN(_transportCharges) && parseFloat(_transportCharges) >= 0
          ? !isNaN(_finalDiscount) && parseFloat(_finalDiscount) >= 0
            ? (parseFloat(_transportCharges) +
              parseFloat(_otherCharges) +
              itemsTotalCost) - (showDiscount ? parseFloat(_finalDiscount) : 0)
            : "-"
          : "-" : "-";
          if(tcsPercentage){
            setTotalAmount(returnVal + (returnVal* (tcsPercentage/100)));           
          }else{
            setTotalAmount(returnVal);
          }

  }, [otherCharges, transportCharges, itemsTotalCost, finalDiscount, showDiscount,tcsPercentage]);

  async function getItemDetail(paramCopy) {
    var data = await fetchData({
      requestingPage: "poItemDetail",
      method: "post",
      url: "so-fetch/so-line-item-pre-create",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: {
        // itemId: lineItemParams.itemId.value,
        soDate: salesOrderParams.soDate.value,
      },
    });
    if (data.msg === "success") {
      paramCopy = update(paramCopy, {
        from: { value: { $set: data.asset[0].from } },
        to: { value: { $set: data.asset[0].to } },
      });
      setSalePriceTypes(data.asset[0].salesPrice);

      return paramCopy;
    }
  }

  async function getDim(dataSent) {
    var data = await fetchData({
        requestingPage: "getDim",
        method: "post",
        url: "dim",
        headers: { token: isLogged.accessToken ,module:"Customers"},
        data: dataSent,
    });
    if (data.msg !== "failure") {
        return data;
    } else {
        console.log(data);
        return [];
    }
}

  async function parseDim(data, dimension) {
    var optionArray = [];

    switch (dimension) {
        case "country":
            data.forEach((dataItem) => {
                var a = { optionName: dataItem.country.toUpperCase(), optionId: dataItem.country_id };
                optionArray.push(a);
            });
            break;

        case "state":
            data.forEach((dataItem) => {
                var a = { optionName: dataItem.state.toUpperCase(), optionId: dataItem.state_id };
                optionArray.push(a);
            });
            break;

        case "cityId":
            data.forEach((dataItem) => {
                var a = { optionName: dataItem.city.toUpperCase(), optionId: dataItem.city_id };
                optionArray.push(a);
            });
            break;

        default:
            break;
    }

    return optionArray;
}

  const [lineItemErrors, setLineItemErrors] = useState([]);
  const checkLineItemError = () => {

    var errorList = [];

    var lineItemParamsCopy = Object.assign(lineItemParams);
    [
      // "itemId",
      "cuttingLength",
      "from",
      "to",
      "uomId",
      "quantity",
      // "tax",
      "discount",
      "unitPrice",
      // "otherCharges",
    ].forEach((element) => {
      if (element === "discount") {
        if (lineItemParamsCopy.discount.value !== "") {
          if (
            parseFloat(lineItemParamsCopy.discount.value) < 0 ||
            parseFloat(lineItemParamsCopy.discount.value) > 100
          ) {
            lineItemParamsCopy = update(lineItemParamsCopy, {
              [element]: { error: { $set: true } },
            });
          } else {
            lineItemParamsCopy = update(lineItemParamsCopy, {
              [element]: { error: { $set: false } },
            });
          }
        } else {
          lineItemParamsCopy = update(lineItemParamsCopy, {
            [element]: { error: { $set: false } },
          });
        }
      } else if (lineItemParamsCopy[element].value !== null && lineItemParamsCopy[element].mandatory == true) {
        lineItemParamsCopy = update(lineItemParamsCopy, {
          [element]: {
            error: {
              $set: !validateMandatory(
                lineItemParamsCopy[element].value.toString()
              ),
            },
          },
        });
      } else {
      }

      if (
        element === "quantity" ||
        element === "unitPrice" ||
        element === "from" ||
        element === "to" ||
        element === "netWeight" ||
        element === "grossWeight"
        // element === "otherCharges"
      ) {
        if (lineItemParamsCopy[element].value !== "") {
          if (
            isNaN(lineItemParamsCopy[element].value) ||
            parseFloat(lineItemParamsCopy[element].value) < 0
          ) {
            lineItemParamsCopy = update(lineItemParamsCopy, {
              [element]: { error: { $set: true } },
            });
          } else {
            lineItemParamsCopy = update(lineItemParamsCopy, {
              [element]: { error: { $set: false } },
            });
          }
        }
      }

      if (lineItemParamsCopy[element].error) {
        errorList.push(lineItemParamsCopy[element].errorMessage);
      }

      setLineItemErrors(errorList);
      setLineItemParams(lineItemParamsCopy);
    });

    if (errorList.length === 0) {
      let categoryName =itemList.filter(item=>item.categoryId === lineItemParamsCopy.itemCategory?.value)
      var newLineItem = {
        itemCategoryName:categoryName[0]?.categoryName ? categoryName[0]?.categoryName :"",
        itemCategory :categoryName[0]?.categoryName ? categoryName[0]?.categoryId :null,
        sectionNo: lineItemParamsCopy.sectionNo.value,
        sectionName: lineItemParamsCopy.sectionName.value,
        itemCode: lineItemParamsCopy.sectionNo.value,
        itemId: itemList.filter(item=>item.sectionNo === lineItemParamsCopy.sectionNo.value)[0].itemId,
        cuttingLengthName: cuttingLength.filter(
          (item) => item.optionId === lineItemParamsCopy.cuttingLength.value
        )[0]?.optionName,
        cuttingLength: lineItemParamsCopy.cuttingLength.value || null,
        // hsnCode: lineItemParamsCopy.hsnCode.value,
        uomId: lineItemParamsCopy.uomId.value,
        uom: itemUomList.filter(
          (item) => item.optionId === lineItemParamsCopy.uomId.value
        )[0].optionName,
        quantity: Number(parseFloat(lineItemParamsCopy.quantity.value).toFixed(2)),
        from: lineItemParamsCopy.from.value,
        to: lineItemParamsCopy.to.value,
        // cost: lineItemParamsCopy.cost.value,
        discount:
          lineItemParamsCopy.discount.value !== ""
            ? parseFloat(lineItemParamsCopy.discount.value)
            : null,
        unitPrice: parseFloat(lineItemParamsCopy.unitPrice.value),
        // tax: lineItemParamsCopy.tax.value,
        netPrice:
          Number(parseFloat(lineItemParams.quantity.value *
            (lineItemParams.unitPrice.value  -
              (lineItemParams.discount.value / 100) *
              lineItemParams.unitPrice.value) +
            // (lineItemParams.tax.value / 100) *
            (lineItemParams.quantity.value *
              (lineItemParams.unitPrice.value  -
                (lineItemParams.discount.value / 100) *
                lineItemParams.unitPrice.value))).toFixed(2)),
        // lastSaleUnitPrice: lineItemParamsCopy.lastSaleUnitPrice.value,
        // otherCharges: lineItemParamsCopy.otherCharges.value !== ""
        //   ? parseFloat(lineItemParamsCopy.otherCharges.value)
        //   : null,
        soLineItemId: lineItemParamsCopy.soLineItemId,
      };

      var lineItemsListCopy = lineItemsList;
      lineItemsListCopy.push(newLineItem);
      setLineItemsList(lineItemsListCopy);

      var itemsCost = 0;
      lineItemsListCopy.forEach((item) => {
        itemsCost = parseFloat(itemsCost) + parseFloat(item.netPrice);
      });
      setItemsTotalCost(itemsCost);

      const trimSectionList = itemList.filter(item1 =>
        !lineItemsListCopy.some(item2 => (item2.sectionNo === item1.sectionNo)))

        lineItemsParamsList.forEach((element) => {
          if(element === "sectionNo"){
            lineItemParamsCopy.sectionNo.value = "";
            lineItemParamsCopy.sectionNo.options = trimSectionList;
          }else{
  
            lineItemParamsCopy[element].value = "";
          }
        });

      lineItemsParamsList.soLineItemId = null;
      setLineItemParams(lineItemParamsCopy);
    }
  };

  const [soErrors, setSoErrors] = useState([]);
  const checkErrors = (submitMode) => {

    var errorList = [];
    var paramsCopy = Object.assign(salesOrderParams);

    if (newCustomer) {
      salesOrderParamList[1].forEach((item) => {
        if (paramsCopy[item].mandatory) {
          paramsCopy = update(paramsCopy, {
            [item]: {
              error: {
                $set: !validateMandatory(paramsCopy[item].value.toString()),
              },
            },
          });
        }

        if (item === "mobileNo") {
          paramsCopy = update(paramsCopy, {
            [item]: {
              error: { $set: !validateMobileNumber(paramsCopy[item].value) },
            },
          });
        }

        if (item === "email") {
          if (paramsCopy.email.value !== "") {
            paramsCopy = update(paramsCopy, {
              [item]: {
                error: { $set: !validateEmail(paramsCopy[item].value) },
              },
            });
          }
        }

        if (paramsCopy[item].error) {
          errorList.push(paramsCopy[item].errorMessage);
        }
      });
    }else if(newAddress){
      salesOrderParamList[4].forEach((item) => {
        if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
        [item]: {
          error: {
            $set: !validateMandatory(paramsCopy[item].value.toString()),
          },
        },
      });
    }
  
    if (paramsCopy[item].error) {
      errorList.push(paramsCopy[item].errorMessage);
    }
  })
    }
    else {
      salesOrderParamList[0].forEach((item) => {
        if (paramsCopy[item].mandatory) {
          paramsCopy = update(paramsCopy, {
            [item]: {
              error: {
                $set: !validateMandatory(paramsCopy[item].value.toString()),
              },
            },
          });
        }

        if (paramsCopy[item].error) {
          errorList.push(paramsCopy[item].errorMessage);
        }
      });
    }

    salesOrderParamList[2].forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    if (isNaN(transportCharges) || parseInt(transportCharges) < 0) {
      errorList.push("Enter a valid number for Transport Charges");
      setTransportChargesError(true);
    } else {
      setTransportChargesError(false);
    }
    if (isNaN(otherCharges) || parseInt(otherCharges) < 0) {
      errorList.push("Enter a valid number for Other Charges");
      setOtherChargesError(true);
    } else {
      setOtherChargesError(false);
    }

    if (errorList.length === 0) {
      var lineItemAllClear = true;
      lineItemsParamsList.forEach((item) => {
        if (lineItemParams[item].value !== "") {
          lineItemAllClear = false;
          setLineItemErrors(["Unsaved Item information found"]);
        }
      });

      if (lineItemAllClear) {
        if (lineItemsList.length > 0) {
          setModalText("Updating Sales Order");
          setDataSubmitted(false);
          setIsOpen(true);
          prepareDataToSumbit(submitMode);
        } else {
          setLineItemErrors(["Add atleast one item information"]);
        }
      }
    }

    setSalesOrderParams(paramsCopy);
    setSoErrors(errorList);
  };

  var newCustomerObj = {
    companyName: "ABC",
    mailingName: "-----",
    address: "-----",
    cityId: 1,
    gstNo: "-----",
    priceTypeId: 1,
    creditAmountLimit: "-----",
    creditDays: "-----",
    contactDetail: [{ contactName: "-----", mobile: "0000000000" }],
    deliveryAddress: [
      {
        consignorCompanyName: "ABC",
        gstNo: "-----",
        address: "-----",
        cityId: 1,
        contactName: "-----",
        mobile: "0000000000",
      },
    ],
  };

  const prepareDataToSumbit = (submitMode) => {
    var serverObj = {};
    if (newCustomer) {
      salesOrderParamList[1].forEach((item) => {
        if (item === "customerName") {
          serverObj.customerId = null;
        } else {
          if (salesOrderParams[item].value !== "") {
            serverObj[item] = salesOrderParams[item].value;
          } else {
            serverObj[item] = null;
          }
        }
      });
    } else {
      salesOrderParamList[0].forEach((item) => {
        if (salesOrderParams[item].value !== "") {
          serverObj[item] = salesOrderParams[item].value;
        } else {
          serverObj[item] = null;
        }
      });
    }
    if(newAddress){
      salesOrderParamList[4].forEach((item) => {
         if(item !== "country" || item !== "state")
         serverObj[item] = salesOrderParams[item].value;
      })
      serverObj["gstNo"] = "";
      serverObj["mobile"] = newCustomer ? salesOrderParams.mobileNo.value : "";
      let customerName = salesOrderParams.customerName.options.filter(item=>item.customerId === salesOrderParams.customerName.value )
      serverObj["customerName"] = customerName.customer
      serverObj["deliveryAddressLineId"] = 1
    }else{
      salesOrderParamList[3].forEach((item) => {
        if (salesOrderParams[item].value !== "") {
          serverObj[item] = salesOrderParams[item].value;
        } else {
          serverObj[item] = null;
        }
      });
    }
    salesOrderParamList[2].forEach((item) => {
      if (salesOrderParams[item].value !== "") {
        serverObj[item] = salesOrderParams[item].value;
      } else {
        serverObj[item] = null;
      }
    });
    serverObj.lineItems = lineItemsList;
    serverObj.otherCharges = otherCharges || 0;
    serverObj.transportCharges = transportCharges || 0;
    serverObj.netTotal = itemsTotalCost;
    serverObj.salesInstruction =
      salesInstruction !== "" ? salesInstruction : null;
    serverObj.soStatus = submitMode;
    serverObj.totalAmount = totalAmount;
    serverObj.soId = soDetail.soId;
    serverObj.finalDiscount = showDiscount ? parseFloat(finalDiscount) || 0 : 0;
    serverObj.tcsPercentage = tcsPercentage ? parseFloat(tcsPercentage) || 0 : 0;
    
    if (newCustomer) {
      // Create customer

      var name = salesOrderParams.customerId.options.filter(
        (cusName) =>
          cusName.optionName.toLowerCase() ===
          salesOrderParams.customerName.value.toLowerCase()
      );
      if (name.length > 0) {
        setSoErrors(["Customer Name Already Exists"]);
        setModalText("Customer Name Already Exists");
        var a = "notSubmitted";
        async function closeModal() {
          await setDataSubmitted(true);
          a = "submitted";
          return a;
        }

        closeModal();
      } else {

        var data2 = new FormData();
        Object.keys(newCustomerObj).forEach((key) => {
          if (key === "companyName") {
            data2.append(key, salesOrderParams.customerName.value);
          } else if (key === "mobile") {
            data2.append(key, salesOrderParams.mobileNo.value);
          } else if (key === "contactDetail" || key === "deliveryAddress") {
            data2.append(key, JSON.stringify(newCustomerObj[key]));
          } else {
            data2.append(key, newCustomerObj[key]);
          }
        });

        createCustomer(data2, serverObj);
      }
    } else {
      submitData(serverObj);
    }
  };

  async function createCustomer(data2, serverObj) {
    // setIsOpen(true);
    var result = await fetchData({
      requestingPage: "createCustomer",
      method: "post",
      url: "create/customer",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: data2,
    });

    if (result.msg === "success") {
      setModalText("Successfully Created Customer. Uploading Sales Order Data");

      serverObj.customerId = result.asset[0].customer_id;
      submitData(serverObj);
    } else {
      setModalText(`Upload failed: ${result.desc}`);
      setSoErrors([result.desc]);
      setDataSubmitted(true);
    }
    //
  }

  async function approveSo() {
    // setIsOpen(true);
    // setDataSubmitted(false);
    var data = await fetchData({
      requestingPage: "approvePo",
      method: "put",
      url: `so-edit/so-approve/${soDetail.soId}`,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    setDataSubmitted(true);
    if (data.msg === "success") {
      setModalText("Approved Sales Order");
    } else {
      setModalText(data.desc);
      setSoErrors([data.desc]);
    }
  }
  const submitData = async (serverObj) => {
    var result = await fetchData({
      requestingPage: "createPoItem",
      url: "so-edit/so",
      method: "put",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: serverObj,
    });
    if (result.msg === "success") {
      setRedirect(true);
      if (editApprove) {
        setModalText("Edit Successful. Approving Purchase Order....");
        approveSo();
      } else {
        setDataSubmitted(true);

        setModalText(
          serverObj.soStatus === "Draft"
            ? "Sales Order Saved"
            : "Sales Order Submitted for Approval"
        );
      }
    } else {
      setDataSubmitted(true);
      setModalText(result.desc);
      setSoErrors([result.desc]);
    }
  };

  const editSubSectionItem = (index, action) => {
    if (action === "edit") {
      var lineItemAllClear = true;

      lineItemsParamsList.forEach((item) => {
        if (lineItemParams[item].value !== "") {
          lineItemAllClear = false;
        }
      });
      if (!lineItemAllClear) {
        var yes = window.confirm(
          "Unsaved data found. Are you sure you want to overwrite it?"
        );
      }
      if (lineItemAllClear || yes) {
        var lineItemCopy = Object.assign(lineItemParams);
        var lineItemsListCopy = lineItemsList.slice();

        var editObj = lineItemsListCopy.splice(index, 1)[0];

        var lineItemsFromServer = [
          "soLineItemId",
          "itemCategory",
          "sectionNo",
          "sectionName",
          "cuttingLength",
          "from",
          "to",
          "uomId",
          "quantity",
          "unitPrice",
          "discount",
          // "otherCharges",
          "netPrice",
          // "lastSaleUnitPrice"
        ];

        lineItemsFromServer.forEach((itemKey) => {
          if (itemKey === "soLineItemId") {
            lineItemCopy[itemKey] = editObj[itemKey];
          }
          else if(itemKey === "sectionNo"){

            lineItemCopy[itemKey].options =itemList ;
            lineItemCopy[itemKey].value = editObj[itemKey];
          }
          else {
            if (editObj[itemKey] !== null) {
              lineItemCopy[itemKey].value = editObj[itemKey];
            } else {
              lineItemCopy[itemKey].value = "";
            }
          }
        });
        // lineItemCopy.itemId.callServer = false;
        // lineItemCopy.uomId.options = itemUomList[lineItemCopy.itemId.value];
        setLineItemParams(lineItemCopy);

        var itemsCost = 0;
        lineItemsListCopy.forEach((item) => {
          itemsCost = itemsCost + parseFloat(item.netPrice);
        });
        setItemsTotalCost(itemsCost);

        setLineItemsList(lineItemsListCopy);
      }
    }
    if (action === "delete") {
      var lineItemsListCopy = lineItemsList.slice();
      lineItemsListCopy.splice(index, 1);

      var itemsCost = 0;
      lineItemsListCopy.forEach((item) => {
        itemsCost = itemsCost + parseFloat(item.netPrice);
      });
      var lineItemCopy = Object.assign(lineItemParams);

      const trimSectionList = itemList.filter(item1 =>
        !lineItemsListCopy.some(item2 => (item2.sectionNo === item1.sectionNo)))

      lineItemCopy["sectionNo"].options = trimSectionList ;

      setLineItemParams(lineItemCopy);
      setItemsTotalCost(itemsCost);

      setLineItemsList(lineItemsListCopy);
    }
  };

  const renderFormElements = ({ elementList, param, section }) => {

    return elementList.map((element) => {
      let netPr = lineItemParams.quantity?.value *
        (lineItemParams.unitPrice.value  -
          (lineItemParams.discount.value / 100) *
          lineItemParams.unitPrice.value) +
        // (lineItemParams.tax.value / 100) *
        (lineItemParams.quantity.value *
          (lineItemParams.unitPrice.value -
            (lineItemParams.discount.value / 100) *
            lineItemParams.unitPrice.value));
      netPr = parseFloat(netPr.toFixed(2));
      return (
        <FormElement
          key={element}
          inputType={param[element].inputType}
          value={
            element === "netPrice"
              ? netPr >=
                0
                ? netPr
                : "-"
              : param[element].value !== null
                ? param[element].value
                : ""
          }
          setInput={(value) => {
            updatePOParameter({
              section,
              paramName: element,
              key: "value",
              value: value,
            });
          }}
          filterOptions={param[element].filterOptions}
          hintText={param[element].hintText}
          mandatory={param[element].mandatory}
          colSpan={param[element].colSpan}
          options={
            param[element].inputType === "options"
              ? param[element].options
              : null
          }
          error={param[element].error}
          rowSpan={
            element === "vendorLogo" || element === "otherBusinessDetails"
              ? param[element].rowSpan
              : null
          }
          placeholder={param[element].placeholder}
          title={param[element].title}
          disabled={param[element].disabled}
          mindate={param[element].mindate}
        />
      );
    });
  };

  const RenderTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr className="createVendorContactsTableHeader">
            {/* <td>Item Category</td> */}
            <td>Section No</td>
            <td>Section Name</td>
            <td>Cutting Length</td>
            <td>Weight From(gms)</td>
            <td>Weight To(gms)</td>  
            <td>Quantity</td>
            <td>UOM</td>
            <td>Actions</td>
          </tr>
        </thead>
        {lineItemsList.length > 0 ? (
          <tbody>
            <RenderTableRows rows={lineItemsList} tab="contactActions" />
          </tbody>
        ) : (
          <tbody>
            <tr className="createVendorContactsTableRows">
            {Array.from({ length: 8 }).map((item,i) => {
                return <td key={i}>&nbsp;</td>;
              })}
              <td key={""}>&nbsp;</td>
            </tr>
          </tbody>
        )}
      </table>
    );
  };

  const RenderTableRows = ({ rows, tab }) => {
    let RenderTableCols = isLogged.showHideWeight
      ? [
        // "itemCategoryName",
        "sectionNo",
        "sectionName",
        "cuttingLengthName",
        "from",
          "to",
          "quantity",
        "uom",
        // "unitPrice",
        // 'otherCharges',
        // "discount",
        // "tax",
        // "netPrice",
        // "lastSaleUnitPrice"
      ]
      : [
        // "itemCategoryName",
        // "itemCode",
        // "itemName",
        "sectionNo",
        "sectionName",
        "cuttingLengthName",
        "from",
        "to",
        // "hsnNo",
        "quantity",
        "uom",
        // "unitPrice",
        // 'otherCharges',
        // "discount",
        // "tax",
        // "netPrice",
        // "lastSaleUnitPrice"
      ];
    return rows.map((row, j) => {
      return (
        <tr className="createVendorContactsTableRows" key={j}>
          {RenderTableCols.map((key, i) => {
            return <td key={i}>{row[key] ? row[key] : "-"}</td>;
          })}
          {/* {!row?.parentItem?  */}
          <td>
            <img
              alt="Edit Record"
              className="createVendorContactsAction"
              src={editIcon}
              onClick={() => {
                editSubSectionItem(j, "edit");
              }}
            />
            <img
              alt="Delete Record"
              className="createVendorContactsAction"
              src={deleteIcon}
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) {
                  editSubSectionItem(j, "delete");
                }
              }}
            />
          </td>
          {/* :<td></td>
        } */}
        </tr>
      );
    });
  };

  const renderSubSection = () => {
    // if (!showCuttingLength) {
    //   lineItemsParamsList = lineItemsParamsList.filter(item => item != "cuttingLength")
    // }
    return (
      <React.Fragment>
        <div className="purchaseOrderIndividualItemsArea" style={{ gridAutoRows: "60px" }}>
          {renderFormElements({
            elementList: lineItemsParamsList,
            param: lineItemParams,
            section: "lineItem",
          })}

          {/* <div
            style={{
              gridColumn: `auto / span 6`,
              margin: "auto 0px",
              color: "#666666",
              cursor: "pointer",
            }}

            onClick={() => setShowCuttingLength(!showCuttingLength)}>
            <input
              type="checkbox"
              name="discount"
              checked={showCuttingLength}
              readOnly
              style={{ cursor: "pointer" }}
            />
            <label
              htmlFor="discount"
              style={
                showDiscount
                  ? {
                    fontWeight: "600",
                    color: "#1f43e5",
                    padding: "0 10px",
                    cursor: "pointer",
                    fontSize: "17px",
                  }
                  : {
                    padding: "0 10px",
                    cursor: "pointer",
                    fontSize: "17px",
                  }
              }>
              Add Cutting Length
            </label>
          </div> */}
          <FormElement
            inputType="addButton"
            value="+ Add"
            colSpan={3}
            setInput={() => {
              checkLineItemError();
            }}
          />
          <div
            style={{
              marginTop: "26px",
              gridColumn: "span 1",
              color: "#666",
              background: "rgb(230,230,230)",
              cursor: "pointer",
            }}>
            <img
              style={{ padding: "10px", width: "1rem", height: "1rem" }}
              src={resetIcon}
              alt="Reset"
              onClick={() => {
                var yes = window.confirm("Clear Input data?");

                if (yes) {
                  var lineItemParamEmpty = Object.assign(lineItemParams);
                  lineItemsParamsList.forEach((item) => {
                    lineItemParamEmpty[item].value = "";
                    lineItemParamEmpty[item].error = false;
                  });
                  setLineItemParams(lineItemParamEmpty);
                  setLineItemErrors([]);
                }
              }}
            />
          </div>

          {salePriceTypes.length > 0 ? <RenderSalesPriceTypes /> : null}
        </div>

        <RenderTable />
      </React.Fragment>
    );
  };

  const RenderSalesPriceTypes = () => {
    return (
      <div
        style={{
          padding: "10px 0 0 0",
          gridColumn: "span 24",
          fontWeight: "bold",
          color: "#ce9547",
        }}>
        Sales Price:&nbsp;&nbsp;&nbsp;&nbsp;
        {salePriceTypes.map((price) => {
          return (
            <span
              style={{
                paddingRight: "1rem",
                color: "#666666",
                fontWeight: "normal",
              }}>
              <b>{price.priceType}</b>: {price.price}
            </span>
          );
        })}
      </div>
    );
  };

  const submitOkClick = () => {
    setIsOpen(false);
    if (soErrors.length === 0 && redirect) {
      setSection("salesOrderList");
    }
    setModalText("Uploading form. Please wait...");
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState(
    "Submitting Sales Order. Please wait.."
  );

  const renderErrorMessage = () => {
    if (soErrors.length > 0) return soErrors[0];
    else if (lineItemErrors.length > 0) return lineItemErrors[0];
  };

  return (
    <React.Fragment>
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
      <div className="formArea">
        <div
          style={{
            width: "fit-content",
            margin: "0 auto 4rem",
            // padding: "3rem 3rem 1rem 3rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex" }}>
            <div
              className={
                newCustomer
                  ? "createSalesOrderGridNewCustomer"
                  : "createSalesOrderGrid"
              }>
              {renderFormElements({
                elementList: salesOrderParamList[0],
                param: salesOrderParams,
                section: "general",
              })}
              {newCustomer ? (
                <div
                  style={{
                    gridColumn: "auto / span 18",
                    borderBottom: "2px solid #dddddd",
                    height: "2.5rem",
                  }}></div>
              ) : null}
              {renderFormElements({
                elementList: salesOrderParamList[2],
                param: salesOrderParams,
                section: "general",
              })}

<div
                style={{
                  gridColumn: `auto / span 6`,
                  margin: "auto 0px",
                  color: "#666666",
                  cursor: "pointer",
                }}
                onClick={() => setNewAddress(!newAddress)}>
                <input
                  type="checkbox"
                  name="Old / New Customer"
                  checked={newAddress}
                  readOnly
                  style={{ cursor: "pointer" }}
                />
                <label
                  htmlFor="vehicle1"
                  style={
                    newAddress
                      ? {
                        fontWeight: "600",
                        color: "#1f43e5",
                        padding: "0 10px",
                        cursor: "pointer",
                        fontSize: "20px",
                      }
                      : {
                        padding: "0 10px",
                        cursor: "pointer",
                        fontSize: "20px",
                      }
                  }>
                  Add New Address
                </label>
              </div>
              {
                renderFormElements(
                  newAddress ?
                  {elementList: salesOrderParamList[4],
                    param: salesOrderParams,
                    section: "general",}
                    :
                    {elementList: salesOrderParamList[3],
                    param: salesOrderParams,
                    section: "general",})
              }
            </div>
            <div style={{ width: "300px" }}>
              <div className="vendorStoreDetailArea">
                <div className="poVendorAddressDetail">
                  {showCustomerDetail && !newCustomer ? (
                    <RenderCustomerDetail />
                  ) : null}
                </div>
                <div className="poStoreAddressDetail">
                  {showStoreDetail ? <RenderStoreDetail /> : null}
                </div>
              </div>
            </div>
          </div>
          <div className="purchaseOrderSubSectionArea">
            {renderSubSection()}
          </div>
          <div className="poNetTotalSurchargeDiv">
            <div className="purchaseInstruction">
              <div>
                No Items:&nbsp;
                <span>{lineItemsList.length}</span>
              </div>

              <div style={{ marginTop: "10px" }}>Sales Instructions</div>
              <textarea
                className="multilineInput"
                value={salesInstruction}
                rows="6"
                cols="30"
                name="text"
                placeholder="Enter your notes"
                style={{
                  padding: "10px",
                  resize: "none",
                  marginTop: "10px",
                  height: "114px",
                  fontFamily: "sans-serif",
                }}
                onChange={(e) => {
                  setSalesInstruction(e.target.value);
                }}
              />
            </div>
            <div className="poSpacer">&nbsp;</div>
            <div className="poNetTotal">
              <div className="poTotalTitle">
                <span>
                  <b>Net Total</b>
                </span>
                <span className="poNetTotalAmount">{itemsTotalCost}</span>
              </div>
              <div className="poFormElement" style={{ gridTemplateRows: "repeat(4, 4rem)" }}>
                <div className="formElement">
                  <FormHint hintText="Other Charges" mandatory={false} />
                  <InputField
                    type="float"
                    value={otherCharges}
                    setName={(value) => setOtherCharges(parseFloat(value) >= 0 ? parseFloat(value) : 0)}
                    align="end"
                    error={otherChargesError}
                  />
                </div>
                <div className="formElement">
                  <FormHint hintText="Transport Charges" mandatory={false} />

                  <InputField
                    type="float"
                    value={transportCharges}
                    setName={(value) => settransportCharges(parseFloat(value) >= 0 ? parseFloat(value) : 0)}
                    align="end"
                    error={transportChargesError}
                  />
                </div>

                {<div>
                  <div
                    style={{
                      gridColumn: `auto / span 6`,
                      margin: "auto 0px",
                      color: "#666666",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowDiscount(!showDiscount)}>
                    <input
                      type="checkbox"
                      name="discount"
                      checked={showDiscount}
                      readOnly
                      style={{ cursor: "pointer" }}
                    />
                    <label
                      htmlFor="discount"
                      style={
                        showDiscount
                          ? {
                            fontWeight: "600",
                            color: "#1f43e5",
                            padding: "0 10px",
                            cursor: "pointer",
                            fontSize: "17px",
                          }
                          : {
                            padding: "0 10px",
                            cursor: "pointer",
                            fontSize: "17px",
                          }
                      }>
                      Add Discount
                    </label>
                  </div>
                  {showDiscount ? <div className="formElement">
                    <FormHint hintText="Discount" mandatory={false} />

                    <InputField
                      type="float"
                      value={finalDiscount}
                      setName={(value) => setFinalDiscount(parseFloat(value) >= 0 ? parseFloat(value) : 0)}
                      align="end"
                      error={finalDiscountError}
                    />
                  </div> : null}
                </div>}

                <div className="formElement">
                <FormHint hintText="TCS(%)" mandatory={false} />
                <InputField
                    type="float"
                    value={tcsPercentage}
                    setName={(value) => setTcsPercentage(value)}
                    align="end"
                    error={""}
                  />
                </div>
              </div>

              <div className="poTotalTitle">
                <span>Round off</span>
                <span className="poNetTotalAmount">{'0.' + (totalAmount.toString().split('.')[1] || 0)}</span>
              </div>
              <div className="poTotalTitle" style={{ margin: "1rem 0 0 0", fontSize: "14px" }}>
                <span>
                  <b>Total Amount<em>(Rounded Off)</em></b>
                </span>
                <div className="poNetTotalAmount">{Math.round(totalAmount)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
          {soDetail.soStatus === "Draft" ? (
            <button
              className="saveButton"
              onClick={() => {
                setSoErrors([]);
                setLineItemErrors([]);
                checkErrors("Draft");
              }}>
              Save draft
            </button>
          ) : null}
          <button
            className="submitButton"
            onClick={() => {
              setSoErrors([]);
              setLineItemErrors([]);
              checkErrors(soDetail.soStatus);
            }}>
            {editApprove ? "Edit & Approve" : " Submit for Approval"}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSalesOrder);
