import React, { Fragment, useEffect, useState } from "react";
import update from "immutability-helper";
import FormElement from "../SmallComponents/FormElement";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormHint from "../SmallComponents/FormHint";
import InputField from "../SmallComponents/InputField";

const EditSalesQuotesParams = ({ setCurrentSection, isLogged, storeInfo }) => {

  const [quoteParams, setQuoteParams] = useState({
    opportunityId: {
      inputType: "text",
      value: "",
      hintText: "Opportunity Name",
      colSpan: 6,
      // options: [],
      error: false,
      errorMessage: "Please Choose a Deal",
      mandatory: true,
      disabled: true
    },
    quoteName: {
      inputType: "text",
      value: "",
      hintText: "Quote Name",
      colSpan: 6,
      error: false,
      errorMessage: "Please Enter A Deal Name",
      mandatory: true
    },
    expirationDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Expiration Date",
      colSpan: 6,
      error: false,
      errorMessage: "Please Enter A Expire Date",
      mandatory: true
    },
    buyerInfo: {
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "Buyer Info",
      colSpan: 6,
      error: false,
      errorMessage: "",
      mandatory: true,
      // disabled: true,
    },
    dealOwner: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Deal Owner",
      colSpan: 6,
      error: false,
      errorMessage: "",
      mandatory: true,
      // disabled: true,
    }
  })

  const [lineItemsParams, setLineItemParams] = useState({
    itemCategory: {
      inputType: "options",
      value: "",
      hintText: "Item Category",
      mandatory: false,
      colSpan: 12,
      options: [],
      error: false,
      errorMessage: "Select an Item",
      // callServer: true,
    },
    sectionNo: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Section Number",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Section No",
      mandatory: true
    }, 
      sectionName: {
        inputType: "text",
        value: "",
        hintText: "Section Name",
        colSpan: 6,
        error: false,
        errorMessage: "Please Select A Section",
        mandatory: false,
        disabled:true
      },
    unitPrice: {
      inputType: "number",
      value: "",
      hintText: "Unit Price",
      colSpan: 6,
      error: false,
      errorMessage: "Please Enter A Price",
      mandatory: true
    },
    quantity: {
      inputType: "number",
      value: "",
      hintText: "Quantity",
      colSpan: 6,
      error: false,
      errorMessage: "Please Enter A Quantity",
      mandatory: true
    },
    mrp: {
      inputType: "number",
      value: 0,
      hintText: "MRP",
      colSpan: 6,
      error: false,
      errorMessage: "",
      mandatory: false,
      disabled: true,
    },

    quoteItemId: null
  })

  const [submitted, setSubmitted] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [dealsError, setDealsError] = useState([])
  const [modalText, setModalText] = useState("")
  const [listItem, setListItem] = useState([]);
  const [showDiscount, setShowDiscount] = useState(false);
  const [totalFee, setTotalFee] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [disCount, setDisCount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [termsAndConditions, setTermsAndConditions] = useState("")
  const [itemList, setItemList] = useState([])



  useEffect(() => {

    //calculating subtotal of all item list
    if (listItem.length) {
      let subTotal = listItem.reduce((acc, value) => {
        acc += value.mrp || 0
        return acc
      }, 0)
      setSubTotal(subTotal)
      setTotal(subTotal);
    }
  }, [listItem.length])

  const calculateTotal = () => {

    //find total by calculating tax,fee and discount
    let result = subTotal
    if (totalFee) {
      result = subTotal + totalFee
    }
    if (totalTax) {
      result = result + totalTax
    }
    if (disCount) {
      if(showDiscount){
        
        result = result - disCount
      }else{
        result = result
      }
    }
    setTotal(result)
  }

  useEffect(() => {
    calculateTotal()
  }, [totalFee, totalTax, disCount,showDiscount])


  //get salesLead Details function
  const salesLeadDetail = async () => {

    let data = await fetchData({
      requestingPage: "employeeDetail",
      method: "get",
      url: "so-fetch/sales-lead-list",
      headers: { token: isLogged.accessToken }
    })

    if (data.msg === "success") {

      let leadOptions = [];
      data.asset.forEach((lead) => {
        leadOptions.push({
          optionId: lead.leadId,
          optionName: lead.firstName + lead.lastName,
          value: lead.leadId,
          label: lead.firstName + lead.lastName,
          companyName: lead.companyName
        });
      });
      return leadOptions;
    }
  }

  async function getItems(listItem) {
    let itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data: { tab: "Active",type:"Sales" },
      headers: { token: isLogged.accessToken, module: "Sales Quote" },
    });

    let itemList = [];
    if (itemData.msg === "success") {

      itemData.asset.forEach((item) => {
        let itemObj = {};
        itemObj.optionId = item.item.itemCode;
        itemObj.optionName = item.item.itemCode;
        itemObj.itemCode = item.item.itemCode;
        itemObj.itemName = item.item.itemName;
        itemObj.itemCode = item.item.itemCode;
        itemObj.mrp = item.item.mrp;
        itemObj.categoryId = item.item.categoryId;
        itemObj.categoryId = item.item.categoryId;
        itemObj.sectionNo = item.item.sectionNo;
        itemObj.sectionName = item.item.sectionName;
        itemList.push(itemObj);
      });
      var [categoryList] = await getCategory();
      let copy = structuredClone(lineItemsParams);

      const trimSectionList = itemList.filter(item1 =>
        !listItem.some(item2 => (item2.itemCode === item1.itemCode)))

      copy = update(copy, {
        sectionNo: { options: { $set: trimSectionList } },
        itemCategory: { options: { $set: categoryList } }
      })
      setLineItemParams(copy)
      setItemList(itemList)

    }
  }

  async function getCategory() {
    var categoryData = await fetchData({
      requestingPage: "getCategory",
      method: "get",
      url: "fetch/categories",
      headers: { token: isLogged.accessToken, module: "Dropdowns" },
    });

    var categoryList = [];
    var subCategoryList = {};

    if (categoryData.msg === "success") {
      categoryData.asset.forEach((categ) => {
        var catObj = {};
        catObj.optionId = categ.category.categoryId;
        catObj.optionName = categ.category.categoryName;
        categoryList.push(catObj);

        subCategoryList[categ.category.categoryId] = [];

        categ.subCategory.forEach((subCateg) => {
          var subCatObj = {};
          subCatObj.optionId = subCateg.subCategoryId;
          subCatObj.optionName = subCateg.subCategoryName;

          subCategoryList[categ.category.categoryId].push(subCatObj);
        });
      });
      return [categoryList, subCategoryList];
    }
  }

  async function getSalesQuoteDetail() {

    let data = await fetchData({
      requestingPage: "employeeDetail",
      method: "get",
      url: `so-fetch/sales-quote-detail/` + storeInfo.salesQuoteId,
      headers: { token: isLogged.accessToken, module: "Sales Quote" }
    })
    if (data.msg === "success") {
      let copy = structuredClone(quoteParams);
      let employeeList = await getEmployees()
      let leadListDetail = await salesLeadDetail()
      let detail = data.asset
      let leadOptions = leadListDetail.filter((item) => { return item.companyName === detail.leadList[0]?.companyName })
      copy = update(copy,
        {
          opportunityId: {
            value: { $set: detail.opportunityName }
          },
          quoteName: { value: { $set: detail.quoteName } },
          expirationDate: { value: { $set: detail.expirationDate } },
          dealOwner: { options: { $set: employeeList }, value: { $set: detail.dealOwnerId } },
          buyerInfo: { options: { $set: leadOptions }, value: { $set: detail.leadList } }

        })
      let lineItemsList = detail.lineItemsList.map((li) => ({
        ...li,
        mrp: li.quantity * li.unitPrice
      }))
      setListItem(lineItemsList)
      setTermsAndConditions(detail.purchaseTerm)
      setTotalFee(detail.fee)
      setTotalTax(detail.tax)
      setDisCount(detail.discount || 0)

      setQuoteParams(copy)

      if (detail.disCount) {
        setShowDiscount(true)
      }
      getItems(lineItemsList);
    }
   
  }

  async function getEmployees() {
    var data = await fetchData({
      requestingPage: "employeeList",
      method: "POST",
      url: "fetch/employees",
      headers: { token: isLogged.accessToken, module: "Sales Quote" },
      data: { filter: "" }
    });
    if (data.msg === "success") {
      return data.asset
    } else {
      console.log(data);
    }
  }

  useEffect(() => {
    getSalesQuoteDetail()
    
  }, [])

  const renderFormElements = ({ elementList, param }) => {
    return (
      <>
        {elementList.map((element, ind) => {
          return (
            <span style={{ flex: "50%", maxWidth: ind === 0 ? "500px" : "250px" }} key={element}>
              <FormElement
                inputType={param[element].inputType}
                value={param[element].value}
                setInput={(value) =>

                  updateParameter(element, "value", value, param)
                }
                hintText={param[element].hintText}
                mandatory={param[element].mandatory}
                colSpan={param[element].colSpan}
                options={
                  param[element].inputType === "options" || param[element].inputType === "multiSelect"
                    ? param[element].options
                    : null
                }
                error={param[element].error}
                key={ind}
                disabled={param[element].disabled}
              />
            </span>
          );

        })}


      </>
    );
  };

  const updateParameter = async (element, key, value, section) => {

    let salesQuoteCopy = Object.assign(section);
    let keyList = ["itemCategory", "sectionNo", "sectionName","quantity", "unitPrice"]

    salesQuoteCopy = update(salesQuoteCopy, {
      [element]: { [key]: { $set: value } },
    });

    if (element === "unitPrice" || element === "quantity") {
      let mrp = (salesQuoteCopy.unitPrice.value || 0) * (salesQuoteCopy.quantity.value || 0)
      salesQuoteCopy = update(salesQuoteCopy, {
        mrp: { value: { $set: mrp } }
      })
    }
    if (element === "itemCategory") {
      if (value) {
        const filteredItems = itemList.filter(item=>{return item.categoryId === value})
        const trimSectionList = filteredItems.filter(item1 =>
          !listItem.some(item2 => (item2.itemCode === item1.itemCode)))
          
        salesQuoteCopy = update(salesQuoteCopy, {
          sectionNo: { options: { $set: trimSectionList }, value: { $set: "" } },
        });
      } else {

        const trimSectionList = itemList.filter(item1 =>
          !listItem.some(item2 => (item2.itemCode === item1.itemCode)))
          
  
          salesQuoteCopy = update(salesQuoteCopy, {
            sectionNo: { options: {$set:trimSectionList}},
        });
      }
    }
    else if (element === "sectionNo") {

      if(value){
        let unitPrice = itemList.filter(item => item.optionId === value)
        
        let element = itemList.filter(item=>item.itemCode === value)
        salesQuoteCopy = update(salesQuoteCopy, {
          unitPrice: { value: { $set: unitPrice[0].mrp }},
          sectionName:{value :{$set :element[0].sectionName }}
        })
      }else{
        salesQuoteCopy = update(salesQuoteCopy, {
          unitPrice: {value: {$set: ""}},
          sectionName:{value :{$set :"" }}
        })
      }
    }

    if (keyList.includes(element)) {
      setLineItemParams(salesQuoteCopy);
    } else {
      setQuoteParams(salesQuoteCopy)

    }

  };

  const submitOkClick = () => {
    setIsOpen(false);
    setCurrentSection("salesQuoteList")
  }

  const RenderTableRows = ({ rows, tab }) => {
    return rows.map((row, j) => {
      return (
        <tr className="createVendorContactsTableRows" key={j}>
          {["itemCode","itemName", "quantity", "unitPrice", "mrp"].map((key, i) => {
            return <td key={i}>{row[key] ? row[key] : " - "}</td>;
          })}
          <td>
            <img
              alt="Edit Record"
              className="createVendorContactsAction"
              src={editIcon}
              onClick={() => {
                editSubSectionItem(j, "edit");
              }}
            />
            <img
              alt="Delete Record"
              className="createVendorContactsAction"
              src={deleteIcon}
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) {
                  editSubSectionItem(j, "delete");
                }
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const renderSubSection = () => {
    return (
      <React.Fragment>
        <div className="purchaseOrderIndividualItemsArea" style={{ gridAutoRows: "60px", display: "flex", flexWrap: "wrap" }}>
          {renderFormElements({
            elementList: ["itemCategory", "sectionNo","sectionName", "unitPrice", "quantity", "mrp"],
            param: lineItemsParams
          })}
          <div style={{ width: "165px", }}>
            <FormElement
              inputType="addButton"
              value="+ Add"
              colSpan={3}
              setInput={() => {
                checkLineItemError(lineItemsParams);
              }}
            />
          </div>
          <div
            style={{
              marginTop: "26px",
              gridColumn: "span 1",
              color: "#666",
              background: "rgb(230,230,230)",
              cursor: "pointer",
            }}>
          </div>
        </div>

        <RenderTable />
      </React.Fragment>
    );
  };

  const RenderTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr className="createVendorContactsTableHeader">
          <td>Section No</td>
            <td>Section Name</td>
            <td>Quantity</td>
            <td>Unit Price</td>
            <td>MRP</td>
            <td>Actions</td>
          </tr>
        </thead>
        {listItem.length > 0 ? (
          <tbody>
            <RenderTableRows rows={listItem} />
          </tbody>
        ) : (
          <tbody>
            <tr className="createVendorContactsTableRows">
              {["SectionNo","SectionName", "quantity", "unitPrice","mrp"].map((item) => {
                return <td key={item}>&nbsp;</td>;
              })}
              <td key={1}>&nbsp;</td>
            </tr>
          </tbody>
        )}
      </table>
    );
  };

  const editSubSectionItem = (index, action) => {

    let salesQuoteLinesCopy = listItem.slice();

    if (action === "edit") {
      let salesParamsCopy = structuredClone(lineItemsParams);

      let item = salesQuoteLinesCopy.splice(index, 1)[0]

      const lineItemsFromServer = [
        "quoteItemId",
        "sectionNo",
        "sectionName",
        "quantity",
        "unitPrice",
        "mrp"
      ]

      lineItemsFromServer.forEach((itemKey) => {
        if (itemKey === "quoteItemId") {
          salesParamsCopy.quoteItemId = item[itemKey];
        }
        else if(itemKey === "sectionNo"){

          salesParamsCopy.sectionNo.options = itemList;
          salesParamsCopy.sectionNo.value = item[itemKey];
        }
        else {
          
          if (item[itemKey] !== null) {
            salesParamsCopy[itemKey].value = item[itemKey];
          } else {
            salesParamsCopy[itemKey].value = "";
          }
        }
      });

      let { quantity,unitPrice, mrp,sectionNo,sectionName } = item;
      salesParamsCopy = update(salesParamsCopy,
        {
          quantity: { value: { $set: quantity } },
          sectionNo: { options :{$set :itemList },value: { $set: sectionNo } },
          sectionName: { value: { $set: sectionName } },
          unitPrice: { value: { $set: unitPrice } },
          mrp: { value: { $set: mrp } }
        })

      setLineItemParams(salesParamsCopy);

    }
    if (action === "delete") {
      salesQuoteLinesCopy.splice(index, 1)
    }
    setListItem(salesQuoteLinesCopy);
  }


  const checkLineItemError = (lineItemsParamsList) => {
    let errorList = [];
    let copy = structuredClone(lineItemsParams);
    ["sectionNo","sectionName", "quantity", "unitPrice"].forEach((item) => {
      if (copy[item].mandatory) {
           if(item==="quantity" || item === "unitPrice"){
            copy = update(copy,
              {
                [item]:
                {
                  error:
                    { $set: copy[item]?.value ? false :true }
                }
              })
           }else{

             copy = update(copy,
               {
                 [item]:
                 {
                   error:
                     { $set: !validateMandatory(copy[item]?.value) }
                 }
               })
           }
      }
      if (copy[item].error) {
        errorList.push(copy[item].errorMessage);
      }
    })
    if (errorList.length === 0) {
      let lineItemsCopy = listItem.slice();

      // let itemName = copy.itemId.options.filter((list) => list.optionId === copy.itemId.value)

      // let margin = (copy.unitPrice.value - copy.unitCost.value)
      //if no error add new object to list item to display in the table
      let newItem = {
        sectionName: copy.sectionName.value,
        sectionNo: copy.sectionNo.value,
        itemName: copy.sectionName.value,
        itemCode: copy.sectionNo.value,
        // itemName: itemName[0].optionName,
        itemId: itemList.filter(item=>item.itemCode === copy.sectionNo.value )[0].itemId,
        quantity: copy.quantity.value,
        unitPrice: copy.unitPrice.value,
        quoteItemId: copy.quoteItemId,
        mrp: copy.quantity.value * copy.unitPrice.value
      }
      let keys = ["itemCategory","sectionNo","sectionName", "quantity", "unitPrice", "mrp"]

      lineItemsCopy.push(newItem);
      const trimSectionList = itemList.filter(item1 =>
        !lineItemsCopy.some(item2 => (item2.itemCode === item1.itemCode)))

      keys.map((item) => {
        if(item === "sectionNo"){
          return copy = update(copy, { sectionNo: {options:{$set :trimSectionList}, value: { $set: "" } } })
        }else{

          return copy = update(copy, { [item]: { value: { $set: "" } } })
        }
      })
      copy.quoteItemId = null

      setListItem(lineItemsCopy);

    }
    setDealsError(errorList);
    setLineItemParams(copy)
  }

  const submitData = async () => {

    let errorList = [];
    let lineItemsParamsList = Object.keys(quoteParams)
    let copy = structuredClone(quoteParams);
    lineItemsParamsList.forEach((item) => {
      if (copy[item].mandatory) {
        copy = update(copy,
          {
            [item]:
            {
              error:
                { $set: !validateMandatory(copy[item].value.toString()) }
            }
          })
      }
      if (copy[item].error) {
        errorList.push(copy[item].errorMessage);
      }
    })
    setDealsError(errorList);
    setQuoteParams(copy);
    if (errorList.length === 0) {

      if (listItem?.length) {

        let { quoteName, expirationDate, buyerInfo, dealOwner } = quoteParams
        let dataToSent = {
          quoteId: storeInfo.salesQuoteId,
          quoteName: quoteName.value,
          expirationDate: expirationDate.value,
          buyerInfo: buyerInfo.value.map(item => item.value),
          dealOwner: dealOwner.value,
          terms: termsAndConditions,
          lineItems: listItem,
          disCount: showDiscount ? disCount : 0,
          tax: totalTax,
          fee: totalFee,
          totalAmount: total
        }
        // if (errorList.length === 0) {

        let response = await fetchData({
          requestingPage: "employeeList",
          method: "put",
          url: "so-edit/sales-quotes",
          data: dataToSent,
          headers: { token: isLogged.accessToken, module: "Sales Quote" }
        })
        setSubmitted(true)
        if (response.msg === "success") {
          setModalText("SuccessFully Edited The Quote")
          setIsOpen(true);
        } else {
          setModalText(response.desc);
        }
        // }
      }
      else {
        errorList.push("Please ADD A Item");
        setDealsError(errorList);
      }

    }
  }

  return (
    <Fragment>
      <CreateEditModal modalIsOpen={isOpen} modalText={modalText}
        dataSubmitted={submitted} submitOkClick={submitOkClick} />
      <div className="formArea" style={{ marginTop: "0", padding: "0" }}>
        <div
          style={{
            margin: "0 2rem 4rem",
            padding: "1rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>

            {renderFormElements({
              elementList: Object.keys(quoteParams),
              param: quoteParams,
            })}
          </div>

          <div className="purchaseOrderSubSectionArea">
            {renderSubSection()}
          </div>
          <div className="poNetTotalSurchargeDiv">
            <div className="purchaseInstruction" style={{ width: "50%" }}>
              <textarea
                className="multilineInput"
                value={termsAndConditions}
                rows="6"
                cols="30"
                name="text"
                placeholder="Purchase Terms"
                style={{
                  padding: "10px",
                  // resize: "none",
                  marginTop: "10px",
                  // height: "96px",
                  fontFamily: "sans-serif",
                  // width: "220px",
                  marginRight: "15px"
                }}
                onChange={(e) => {
                  setTermsAndConditions(e.target.value)
                }}
              />
            </div>
            <div className="poSpacer">&nbsp;</div>
            <div className="poNetTotal">
              <div className="poTotalTitle">
                <span>
                  <b>SubTotal</b>
                </span>
                <span className="poNetTotalAmount">{subTotal}</span>
              </div>
              <div className="poFormElement" style={{ gridTemplateRows: "repeat(4, 4rem)" }} >
                <div className="formElement">
                  <FormHint hintText="Fee" mandatory={false} />
                  <InputField
                    type="float"
                    value={totalFee}
                    setName={(value) =>
                      setTotalFee(parseFloat(value))
                    }
                    align="end"
                    error={""}
                  />
                </div>

                <div className="formElement">
                  <FormHint hintText="Tax" mandatory={false} />
                  <InputField
                    type="float"
                    value={totalTax}
                    setName={(value) =>
                      setTotalTax(value)
                    }
                    align="end"
                    error={""}
                  />
                </div>

                <div>
                  <div
                    style={{
                      gridColumn: `auto / span 6`,
                      margin: "auto 0px",
                      color: "#666666",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setShowDiscount(!showDiscount)
                    }>
                    <input
                      type="checkbox"
                      name="discount"
                      checked={showDiscount}
                      readOnly
                      style={{ cursor: "pointer" }}
                    />
                    <label
                      htmlFor="discount"
                      style={
                        showDiscount
                          ? {
                            fontWeight: "600",
                            color: "#1f43e5",
                            padding: "0 10px",
                            cursor: "pointer",
                            fontSize: "17px",
                          }
                          : {
                            padding: "0 10px",
                            cursor: "pointer",
                            fontSize: "17px",
                          }
                      }>
                      Add Discount
                    </label>
                  </div>
                  {showDiscount ? <div className="formElement">
                    <FormHint hintText="Discount" mandatory={false} />

                    <InputField
                      type="float"
                      value={disCount}
                      setName={(value) =>
                        setDisCount(value)
                      }
                      align="end"
                      error={""}
                    />
                  </div> : null}
                </div>
              </div>
              <div className="poTotalTitle">
                <span>
                  <b>Total</b>
                </span>
                <span className="poNetTotalAmount">{total}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{dealsError[0]}</p>
          <button className="submitButton" onClick={submitData}>Submit</button>
        </div></div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    storeInfo: state.storeInfo
  };
};

export default connect(mapStateToProps)(EditSalesQuotesParams);