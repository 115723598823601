import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import FormElement from "../SmallComponents/FormElement";
import { purchaseImportParamsList } from "./CreatePurchaseImport";
import { useHistory } from "react-router-dom";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FetchNewToken from "../../serverCall/FetchNewToken";
import update from 'immutability-helper';
import deleteIcon from '../../assets/delete.svg'
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import fetchData from "../../serverCall/fetchData";
// import detaildata from "./importdetaildata";
//editPoImportId
const EditPurchaseImport = ({ isLogged, poInfo, addTokenToState, setSection }) => {

  const history = useHistory();
  async function checkToken() {
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    }
    return true;
  }

  const [showContractDays, setShowContractDays] = useState(false);
  const [purchaseImportErrors, setPurchaseImportErrors] = useState([]);
  const [contractDayCounts, setContractDayCounts] = useState({ contractDate: "", contractDays: "", });
  const [contractDetails, setContractDetails] = useState({
    sellerId: {
      inputType: "text",
      value: "",
      hintText: "Seller name",
      // options: [],
      mandatory: true,
      colSpan: 6,
      disabled: true,
      error: false,
      errorMessage: "Pick a Seller",
    },
    poNo: {
      inputType: "text",
      value: "",
      hintText: "Contract Number",
      mandatory: true,
      colSpan: 6,
      // options: [],
      error: false,
      disabled: true,
      errorMessage: "Provide PO Number",
    },
    shipmentDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Shipment date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick shipment date",
    },
    packingDoc: {
      inputType: "upload",
      value: "",
      hintText: "Packing Document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Enter Packing info",
    },
  })
  const [advancePaymentDetails, setAdvancePaymentDetails] = useState({
    advancePaymentDate1: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Advance paid date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick advance payment date",
    },
    advancePaymentDollar1: {
      inputType: "float",
      value: "",
      hintText: "Advance paid against contract ($)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Advance paid amount",
    },
    advancePaymentRupee1: {
      inputType: "float",
      value: "",
      hintText: "Advance paid against contract (₹)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Advance paid amount",
    },

    advancePaymentDate2: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Advance paid date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick advance payment date",
    },
    advancePaymentDollar2: {
      inputType: "float",
      value: "",
      hintText: "Advance paid against contract ($)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Advance paid amount",
    },
    advancePaymentRupee2: {
      inputType: "float",
      value: "",
      hintText: "Advance paid against contract (₹)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Advance paid amount",
    },

    advancePaymentDate3: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Advance paid date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick advance payment date",
    },
    advancePaymentDollar3: {
      inputType: "float",
      value: "",
      hintText: "Advance paid against contract ($)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Advance paid amount",
    },
    advancePaymentRupee3: {
      inputType: "float",
      value: "",
      hintText: "Advance paid against contract (₹)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Advance paid amount",
    }
  })
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceNo: {
      inputType: "text",
      value: "",
      hintText: "Invoice no",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Provide invoice number",
    },
    invoiceDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Invoice date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a Invoice date",
    },
    invoiceQuantity: {
      inputType: "text",
      value: "",
      hintText: "Invoice Qty (MT)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for invoice quantity",
    },
    balancePaymentDollar1: {
      inputType: "float",
      value: "",
      hintText: "Balance paid against invoice ($)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Balance payment",
    },
    balancePaymentRupee1: {
      inputType: "float",
      value: "",
      hintText: "Balance paid against invoice (₹)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Balance payment",
    },
    balancePaymentDate1: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Balance Payment date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },

    balancePaymentDollar2: {
      inputType: "float",
      value: "",
      hintText: "Balance paid against invoice ($)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Balance payment",
    },
    balancePaymentRupee2: {
      inputType: "float",
      value: "",
      hintText: "Balance paid against invoice (₹)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Balance payment",
    },
    balancePaymentDate2: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Balance Payment date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },

    balancePaymentDollar3: {
      inputType: "float",
      value: "",
      hintText: "Balance paid against invoice ($)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Balance payment",
    },
    balancePaymentRupee3: {
      inputType: "float",
      value: "",
      hintText: "Balance paid against invoice (₹)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Balance payment",
    },
    balancePaymentDate3: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Balance Payment date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
  })
  const [billOfLading, setBillOfLading] = useState({
    billOfLadingDoc: {
      inputType: "upload",
      value: "",
      hintText: "Bill of ladding document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    billOfLadingNo: {
      inputType: "text",
      value: "",
      hintText: "Bill of Ladding (B/L) no",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    vesselName: {
      inputType: "text",
      value: "",
      hintText: "Vessel name",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
  });
  const [billOfEntry, setBillOfEntry] = useState({
    billOfEntryNo: {
      inputType: "text",
      value: "",
      hintText: "Bill of Entry (B/E) no",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    billOfEntryDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Bill of Entry date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    billOfEntryReferralNo: {
      inputType: "text",
      value: "",
      hintText: "Script Value",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Script Value",
    },
    billOfEntryDoc: {
      inputType: "upload",
      value: "",
      hintText: "Bill of entry document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
  });
  const [portDetails, setPortDetails] = useState({
    portArrivalDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Arrived on port",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    portDetail: {
      inputType: "text",
      value: "",
      hintText: "Port details",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    freeTimeDurationDays: {
      inputType: "text",
      value: "",
      hintText: "Free time duration (days)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for freetime duration",
    },
    freeTimeExpiryDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Free time expiry date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    arrivalSeplDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Arrived Factory date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    arrivalSeplDays: {
      inputType: "text",
      value: "",
      hintText: "Arrived Factory days",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for arrival Factory",
    },
  })
  const [bankDetails, setBankDetails] = useState({
    bankName: {
      inputType: "text",
      value: "",
      hintText: "Bank Name",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    bankRefNo: {
      inputType: "text",
      value: "",
      hintText: "Bank Ref no",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    bankDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Bank date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    bankDoc: {
      inputType: "upload",
      value: "",
      hintText: "Bank document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },

    //---------------------------------------------------bank 2----------------------
    bankName2: {
      inputType: "text",
      value: "",
      hintText: "Bank Name 2",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    bankRefNo2: {
      inputType: "text",
      value: "",
      hintText: "Bank Ref no 2",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    bankDate2: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Bank date 2",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    bankDoc2: {
      inputType: "upload",
      value: "",
      hintText: "Bank document 2",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
  });
  const [clearingAgent, setClearingAgent] = useState({
    clearingAgentId: {
      inputType: "options",
      value: "",
      hintText: "Agent Name",
      mandatory: false,
      options: [],
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    clearingAgentBillNo: {
      inputType: "text",
      value: "",
      hintText: "Clearing agent bill No",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    clearingAgentBillDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Clearing agent bill date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    clearingAgentBillAmount: {
      inputType: "float",
      value: "",
      hintText: "Clearing agent bill amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number clearing agent bill",
    },
    clearingDoc: {
      inputType: "upload",
      value: "",
      hintText: "Clearing document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    clearingAgentTaxAmount: {
      inputType: "float",
      value: "",
      hintText: "Clearing agent Tax amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number clearing agent bill Tax",
    },
    clearingAgentTotalAmount: {
      inputType: "float",
      value: "",
      hintText: "Clearing agent Total amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      disabled: true,
      errorMessage: "Provide valid number clearing agent bill",
    },
  });
  const [insuranceDetails, setInsuranceDetails] = useState({
    insuranceCompanyName: {
      inputType: "text",
      value: "",
      hintText: "Company Name",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    insuranceNo: {
      inputType: "text",
      value: "",
      hintText: "Insurance No",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    insuranceDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Insurance Date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    insuranceRupee: {
      inputType: "text",
      value: "",
      hintText: "Insurance Rupee",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    insuranceDollar: {
      inputType: "text",
      value: "",
      hintText: "Insurance Dollar",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    insuranceDoc: {
      inputType: "upload",
      value: "",
      hintText: "Insurance Document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
  })
  const [transporter, setTransporter] = useState({
    transporterId: {
      inputType: "options",
      value: "",
      hintText: "Transporter Name",
      options: [],
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    transporterBillNo: {
      inputType: "text",
      value: "",
      hintText: "WayBill No",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    transporterBillDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Bill Date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    transporterBillAmount: {
      inputType: "float",
      value: "",
      hintText: "Transport Amount Charge",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    transporterBillTaxAmount: {
      inputType: "float",
      value: "",
      hintText: "Transporter Tax Amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number Transporter Tax",
    },
    transporterBillTotalAmount: {
      inputType: "float",
      value: "",
      hintText: "Transporter Total Amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      disabled: true,
      errorMessage: "Provide valid number clearing agent bill",
    },
    transporterDoc: {
      inputType: "upload",
      value: "",
      hintText: "WayBill Document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
  });
  const [linerBill, setLinerBill] = useState({
    linerName: {
      inputType: "text",
      value: "",
      hintText: "Liner Bill Name",
      colSpan: 6,
      mandatory: false,
      error: false,
      errorMessage: "",
    },
    linerBillNo: {
      inputType: "text",
      value: "",
      hintText: "Liner Bill No",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    linerBillDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Liner Bill Date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    linerBillAmount: {
      inputType: "float",
      value: "",
      hintText: "Liner Bill Amount Charge",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    linerBillTaxAmount: {
      inputType: "float",
      value: "",
      hintText: "Liner Bill Tax amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number LinerBill Tax",
    },
    linerBillTotalAmount: {
      inputType: "float",
      value: "",
      hintText: "Liner Bill Total amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      disabled: true,
      errorMessage: "Provide valid number LinerBill Total",
    },
    linerBillDoc: {
      inputType: "upload",
      value: "",
      hintText: "Liner Bill document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
  });
  const [remarksPartyParams, setRemarksParty] = useState({
    remarks: {
      inputType: "text",
      value: "",
      hintText: "Remarks",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    remarksParty: {
      inputType: "text",
      value: "",
      hintText: "Remarks party",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
  });
  const [containerDetail, setContainerDetail] = useState({
    containerNo: {
      inputType: "text",
      value: "",
      hintText: "Container no",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Container Number",
    },
    containerWeightMt: {
      inputType: "text",
      value: "",
      hintText: "Container weight (MT)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Enter a valid container weight",
    },
    containerSize: {
      inputType: "text",
      value: "",
      hintText: "Container size (feet)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Enter a valid container size",
    },
    sealNo: {
      inputType: "text",
      value: "",
      hintText: "Seal no",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Seal Number",
    },
    dcNo: {
      inputType: "text",
      value: "",
      hintText: "Delivary Chellan (DC) no",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Delivary Chellan Number",
    },
    containerNoId: {
      value: ""
    },
  })
  const [otherCharges, setOtherCharges] = useState({
    exciseTaxAmount: {
      inputType: "text",
      value: "",
      hintText: "Excise Tax amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid excise tax amount",
    },
    splExciseTaxAmount: {
      inputType: "text",
      value: "",
      hintText: "Special Excise Tax Amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid special excise tax amount",
    },
    interestAmount: {
      inputType: "float",
      value: "",
      hintText: "Interest Amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter valid Interest Amount"
    },
    penaltyAmount: {
      inputType: "float",
      value: "",
      hintText: "Penalty Amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter valid Penalty Amount"
    },
    otherChargesDoc: {
      inputType: "upload",
      value: "",
      hintText: "Other Charges document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
  });
  const [itemDetail, setItemDetail] = useState({
    storageId: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Storage",
      mandatory: true,
      colSpan: 12,
      error: false,
      errorMessage: "Select Storage",
    },
    containerId: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Container No",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Select Container",
    },
    itemId: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Item Name",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Select item",
    },
    from: {
      inputType: "text",
      value: "",
      hintText: "Weight From",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: true,
    },
    to: {
      inputType: "text",
      value: "",
      hintText: "Weight To",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: true,
    },
    netWeight: {
      inputType: "text",
      value: "",
      hintText: "Net Weight",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: true,
    },
    grossWeight: {
      inputType: "text",
      value: "",
      hintText: "Gross Weight",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: true,
    },
    ratePerMt: {
      inputType: "text",
      value: "",
      hintText: "Rate/MT",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Enter a valid Rate",
    },
    quantity: {
      inputType: "text",
      value: "",
      hintText: "Qty (MT)",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Provide valid quantity",
    },
    netAmount: {
      inputType: "text",
      value: "",
      hintText: "Net Amount (USD)",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "",
      disabled: true,
    },
    currencyConversionRate: {
      inputType: "text",
      value: "",
      hintText: "Currency Conversion Rate",
      mandatory: true,
      colSpan: 4,
      error: false,
      errorMessage: "Conversion Rate is mandatory for currency conversion",
      disabled: false,
    },
    netAmountINR: {
      inputType: "text",
      value: "",
      hintText: "Net Amount (INR)",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "",
      disabled: true,
    },
  });
  const [taxAmount, setTaxAmount] = useState({
    cessTaxAmount: {
      inputType: "text",
      value: "",
      hintText: "Cess Tax Amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid cess amount",
    },
    tcsAmount: {
      inputType: "text",
      value: "",
      hintText: "TCS Amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid tcs amount",
    },
    gstAmount: {
      inputType: "text",
      value: "",
      hintText: "GST Amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid gst amount",
    },
    netAmountAfterTax: {
      inputType: "text",
      value: "",
      hintText: "Net Amount After Tax (INR)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      disabled: true,
    },
  });
  const [taxPercentage, setTaxPercentage] = useState({
    cessTaxPercentage: {
      inputType: "text",
      value: "",
      hintText: "Cess Tax Percentage",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid cess tax percentage",
    },
    tcsPercentage: {
      inputType: "text",
      value: "",
      hintText: "TCS Percentage",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid tcs percentage",
    },
    gstPercentage: {
      inputType: "text",
      value: "",
      hintText: "GST Percentage",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid gst percentage",
    },
    netAmountAfterTax: {
      inputType: "text",
      value: "",
      hintText: "Net Amount After Tax (INR)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      disabled: true,
    },
  });
  const [showTaxPercent, setShowTaxPercent] = useState(false);
  const [tabList, setTabList] = useState([{ id: "invoice1", name: "Invoice 1", tabNo: 1 }]);
  const tabListCondition = useMemo(() => tabList.length < 5, [tabList.length]);
  const [selectedTab, setSelectedTab] = useState("invoice1")
  const [invoiceList, setInvoiceList] = useState({});
  const [itemLineItems, setItemLineItems] = useState([]);
  const [linerBillItems, setLinerBillItems] = useState([]);
  const [containerLineItems, setContainerLineItems] = useState([]);
  const [invoiceErrors, setInvoiceErrors] = useState([]);
  const [importErrors, setImportErrors] = useState([]);
  const [containerErrorList, setContainerErrorList] = useState([]);
  const [itemErrorList, setItemErrorList] = useState([]);

  async function getPoDetail() {
    var data = await fetchData({
      requestingPage: "vendorDetail",
      method: "get",
      url: "po-fetch/purchase-import-detail3/" + poInfo.editPoImportId,
      headers: { token: isLogged.accessToken, module: "Purchase Import" },
    });

    if (data.msg === 'success') {
      return data.asset.importList;
    } else {
      setSection('purchaseImportList')
    }
  }
  async function getInformation() {
    await checkToken();
    // let detailData = detaildata[0];
    let detailData = await getPoDetail();
    detailData = detailData[0];
    let contractDetailCopy = structuredClone(contractDetails);
    contractDetailCopy.sellerId.value = detailData.sellerName;
    contractDetailCopy.poNo.value = detailData.poNo;
    contractDetailCopy.shipmentDate.value = new Date(detailData.shipmentDate);
    contractDetailCopy.packingDoc.value = detailData.packingDocument || "";
    let advanceDetailCopy = structuredClone(advancePaymentDetails);
    Object.keys(advanceDetailCopy).forEach((param) => {
      advanceDetailCopy[param].value = detailData[param] || "";
    })

    let tabListCopy = [];
    let ChangeAllInvoice = detailData?.invoiceLineItems.map((invoice, invIndex) => {
      Object.keys(invoice).forEach((param) => {
        if (param.includes('Document')) {
          let newParam = param.replace('ument', '');
          invoice[newParam] = invoice[param] || "";
        } else if (param.toLowerCase().includes('date')) {
          if (invoice[param])
            invoice[param] = new Date(invoice[param])
          else
            invoice[param] = '';
        } else {
          invoice[param] = invoice[param] || ""
        }
      })

      invoice['linerBillItems'].forEach((line) => {
        if (line.linerBillDocument)
          line['linerBillDoc'] = line.linerBillDocument;
        line['linerName'] = line.linerBillName
        invoice['itemLineItems'] = invoice['itemList']
      })

      let tabObj = { id: `invoice${invIndex + 1}`, name: `Invoice ${invIndex + 1}` }
      tabListCopy.push(tabObj);
      

      return invoice;
    })
    // console.log(ChangeAllInvoice[0],'[0]',ChangeAllInvoice);
    let firstInvoice = ChangeAllInvoice[0];
    console.log(firstInvoice,"firstInvoice")

    let invoiceListCopy = {};
    tabListCopy.forEach((tab, index) => {
      invoiceListCopy[tab.id] = ChangeAllInvoice[index];
    })


    let invoiceDetailCopy = structuredClone(invoiceDetails);
    Object.keys(invoiceDetailCopy).forEach((param) => {
      invoiceDetailCopy[param].value = firstInvoice[param] || ""
    });

    let billOfLadingCopy = structuredClone(billOfLading);
    Object.keys(billOfLadingCopy).forEach((param) => {
      billOfLadingCopy[param].value = firstInvoice[param] || ""
    })

    let billOfEntryCopy = structuredClone(billOfEntry);
    Object.keys(billOfEntryCopy).forEach((param) => {
      billOfEntryCopy[param].value = firstInvoice[param] || ""
    })

    let portDetailCopy = structuredClone(portDetails);
    Object.keys(portDetailCopy).forEach((param) => {
      portDetailCopy[param].value = firstInvoice[param] || ""
    })

    let bankDetailsCopy = structuredClone(bankDetails);
    Object.keys(bankDetailsCopy).forEach((param) => {
      bankDetailsCopy[param].value = firstInvoice[param] || ""
    })

    let clearingAgentCopy = structuredClone(clearingAgent);
    Object.keys(clearingAgentCopy).forEach((param) => {
      clearingAgentCopy[param].value = firstInvoice[param] || "";
    })

    let insuranceDetailsCopy = structuredClone(insuranceDetails);
    Object.keys(insuranceDetailsCopy).forEach((param) => {
      insuranceDetailsCopy[param].value = firstInvoice[param] || "";
    })

    let transporterCopy = structuredClone(transporter);
    Object.keys(transporterCopy).forEach((param) => {
      transporterCopy[param].value = firstInvoice[param] || "";
    })

    let remarksPartyParamsCopy = structuredClone(remarksPartyParams);
    Object.keys(remarksPartyParamsCopy).forEach((param) => {
      remarksPartyParamsCopy[param].value = firstInvoice[param] || "";
    })

    let otherChargesCopy = structuredClone(otherCharges);
    Object.keys(otherChargesCopy).forEach((param) => {
      otherChargesCopy[param].value = firstInvoice[param] || "";
    })

    let linerBillItemsCopy = structuredClone(linerBillItems);
    firstInvoice['linerBillItems'].forEach((line) => {
      if (line.linerBillDocument)
        line['linerBillDoc'] = line.linerBillDocument;
    })
    linerBillItemsCopy = firstInvoice['linerBillItems'];

    let containerLineItemsCopy = structuredClone(containerLineItems);
    containerLineItemsCopy = firstInvoice['containerLineItems'];

    let itemLineItemsCopy = structuredClone(itemLineItems);
    itemLineItemsCopy = firstInvoice['itemList'];

    setInvoiceDetails(invoiceDetailCopy);
    setContractDetails(contractDetailCopy);
    setAdvancePaymentDetails(advanceDetailCopy);
    setBillOfLading(billOfLadingCopy);
    setBillOfEntry(billOfEntryCopy);
    setPortDetails(portDetailCopy);
    setBankDetails(bankDetailsCopy);
    setClearingAgent(clearingAgentCopy);
    setInsuranceDetails(insuranceDetailsCopy);
    setTransporter(transporterCopy);
    setRemarksParty(remarksPartyParamsCopy);
    setOtherCharges(otherChargesCopy);
    setLinerBillItems(linerBillItemsCopy);
    setContainerLineItems(containerLineItemsCopy);
    setItemLineItems(itemLineItemsCopy);
    setTabList(tabListCopy);
    setInvoiceList(invoiceListCopy);
  }

  useEffect(() => {
    getInformation();
  }, [])

  const calculateNetAmountAfterTax = ({ itemParams, taxParams }) => {
    let cessTax = 0, tcs = 0, gst = 0;
    let netAmountINR = parseFloat(itemParams.netAmountINR.value) || 0;
    let netAmountAfterTaxes = netAmountINR;
    if (showTaxPercent) {

      if (
        taxParams.cessTaxPercentage.value !== "" &&
        !isNaN(taxParams.cessTaxPercentage.value) &&
        parseFloat(taxParams.cessTaxPercentage.value) > 0 &&
        parseFloat(taxParams.cessTaxPercentage.value) < 100
      ) {
        cessTax = parseFloat(taxParams.cessTaxPercentage.value);
        cessTax = netAmountINR * (cessTax / 100);
        netAmountAfterTaxes += cessTax;
      }

      if (
        taxParams.tcsPercentage.value !== "" &&
        !isNaN(taxParams.tcsPercentage.value) &&
        parseFloat(taxParams.tcsPercentage.value) > 0 &&
        parseFloat(taxParams.tcsPercentage.value) < 100
      ) {
        tcs = parseFloat(taxParams.tcsPercentage.value);
        tcs = netAmountINR * (tcs / 100);
        netAmountAfterTaxes += tcs;
      }

      if (
        taxParams.gstPercentage.value !== "" &&
        !isNaN(taxParams.gstPercentage.value) &&
        parseFloat(taxParams.gstPercentage.value) > 0 &&
        parseFloat(taxParams.gstPercentage.value) < 100
      ) {
        gst = parseFloat(taxParams.gstPercentage.value);
        gst = netAmountINR * (gst / 100);
        netAmountAfterTaxes += gst;
      }

    } else {
      if (
        taxParams.cessTaxAmount.value !== "" &&
        !isNaN(taxParams.cessTaxAmount.value) &&
        parseFloat(taxParams.cessTaxAmount.value) > 0
      ) {
        cessTax = parseFloat(taxParams.cessTaxAmount.value);
        netAmountAfterTaxes += cessTax;
      }

      if (
        taxParams.tcsAmount.value !== "" &&
        !isNaN(taxParams.tcsAmount.value) &&
        parseFloat(taxParams.tcsAmount.value) > 0
      ) {
        tcs = parseFloat(taxParams.tcsAmount.value);
        netAmountAfterTaxes += tcs;
      }

      if (
        taxParams.gstAmount.value !== "" &&
        !isNaN(taxParams.gstAmount.value) &&
        parseFloat(taxParams.gstAmount.value) > 0
      ) {
        gst = parseFloat(taxParams.gstAmount.value);
        netAmountAfterTaxes += gst;
      }

    }
    return netAmountAfterTaxes;
  }

  const updatePurchaseValues = ({ paramName, key, value, section }) => {
    let paramsCopy;
    let paramsCopy2;
    switch (paramName) {
      case 'clearingAgentBillAmount':
      case 'clearingAgentBillTaxAmount':
        paramsCopy = Object.assign(clearingAgent);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        let clearingAgentTotal = parseFloat(paramsCopy['clearingAgentBillAmount'].value) + parseFloat(paramsCopy['clearingAgentTaxAmount'].value);
        clearingAgentTotal = clearingAgentTotal || parseFloat(paramsCopy['clearingAgentBillAmount'].value) || 0;
        paramsCopy = update(paramsCopy, { ['clearingAgentTotalAmount']: { ['value']: { $set: clearingAgentTotal || 0 } } })
        break;

      case 'transporterBillAmount':
      case 'transporterTaxAmount':
        paramsCopy = Object.assign(transporter);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        let transporterTotal = parseFloat(paramsCopy['transporterBillAmount'].value) + parseFloat(paramsCopy['transporterBillTaxAmount'].value);
        transporterTotal = transporterTotal || parseFloat(paramsCopy['transporterBillAmount'].value) || 0;
        paramsCopy = update(paramsCopy, { ['transporterBillTotalAmount']: { ['value']: { $set: transporterTotal || 0 } } })
        break;

      case 'linerBillAmount':
      case 'linerBillTaxAmount':
        paramsCopy = Object.assign(linerBill);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        let linerTotal = parseFloat(paramsCopy['linerBillAmount'].value) + parseFloat(paramsCopy['linerBillTaxAmount'].value);
        linerTotal = linerTotal || parseFloat(paramsCopy['linerBillAmount'].value) || 0;
        paramsCopy = update(paramsCopy, { ['linerBillTotalAmount']: { ['value']: { $set: linerTotal || 0 } } });
        break;

      // =======================================================================
      // Calculate net amount in item detail section
      case 'ratePerMt':
      case 'quantity':
      case 'netAmount':
      case 'currencyConversionRate':
      case 'cessTaxAmount':
      case 'tcsAmount':
      case 'gstAmount':
      case 'cessTaxPercentage':
      case 'tcsPercentage':
      case 'gstPercentage':

        paramsCopy = Object.assign(itemDetail);
        paramsCopy2 = showTaxPercent ? Object.assign(taxPercentage) : Object.assign(taxAmount);
        if (section === 'itemDetail') {
          paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        }
        else if (section === 'taxAmount' || section === 'taxPercentage') {
          paramsCopy2 = update(paramsCopy2, { [paramName]: { [key]: { $set: value } } });
        }

        if (
          paramsCopy.ratePerMt.value !== "" &&
          !isNaN(paramsCopy.ratePerMt.value) &&
          parseFloat(paramsCopy.ratePerMt.value) > 0 &&
          paramsCopy.quantity.value !== "" &&
          !isNaN(paramsCopy.quantity.value) &&
          parseFloat(paramsCopy.quantity.value) > 0
        ) {

          paramsCopy = update(paramsCopy, {
            netAmount: {
              value: {
                $set:
                  parseFloat(paramsCopy.ratePerMt.value) *
                  parseFloat(paramsCopy.quantity.value),
              },
            },
          });
        } else {
          paramsCopy = update(paramsCopy, {
            netAmount: { value: { $set: 0 } },
          });
        }
        if (paramsCopy.netAmount.value !== '' &&
          !isNaN(paramsCopy.netAmount.value) &&
          parseFloat(paramsCopy.netAmount.value) > 0
          && paramsCopy.currencyConversionRate.value !== '' &&
          !isNaN(paramsCopy.currencyConversionRate.value) &&
          parseFloat(paramsCopy.currencyConversionRate.value) > 0 &&
          paramsCopy.ratePerMt.value !== "" &&
          !isNaN(paramsCopy.ratePerMt.value) &&
          parseFloat(paramsCopy.ratePerMt.value) > 0 &&
          paramsCopy.quantity.value !== "" &&
          !isNaN(paramsCopy.quantity.value) &&
          parseFloat(paramsCopy.quantity.value) > 0
        ) {
          var netAmountinr = parseFloat(paramsCopy.netAmount.value) * parseFloat(paramsCopy.currencyConversionRate.value);
          paramsCopy = update(paramsCopy, { netAmountINR: { value: { $set: netAmountinr } } });
        } else {
          paramsCopy = update(paramsCopy, { netAmountINR: { value: { $set: 0 } } });
        }

        // Calculate Net amount after tax values
        let netAmountAfterTaxValues = calculateNetAmountAfterTax({ itemParams: paramsCopy, taxParams: paramsCopy2 });
        paramsCopy2 = update(paramsCopy2, { netAmountAfterTax: { value: { $set: netAmountAfterTaxValues } } })
        setItemDetail(paramsCopy);
        if (showTaxPercent) {
          setTaxPercentage(paramsCopy2);
        } else {
          setTaxAmount(paramsCopy2);
        }
        break;

    }

    // if(['quantity','ratePerMt','currencyConversionRate',
    //     'cessTaxAmount','tcsAmount','gstAmount',
    //     'cessTaxPercentage','tcsPercentage','gstPercentage',
    //   ].includes(paramName)){
    //     if(!paramsCopy){
    //       paramsCopy = Object.assign(itemDetail);
    //     }
    //     paramsCopy2 = showTaxPercent ?  Object.assign(taxPercentage) : Object.assign(taxAmount); 
    //     if(section === 'taxAmount' || section === 'taxPercentage')
    //     paramsCopy2 = update(paramsCopy2,{[paramName]:{[key]:{$set: value} } });
    //     let netAmountAfterTaxValues = calculateNetAmountAfterTax({itemParams:paramsCopy,taxParams:paramsCopy2});
    //     paramsCopy2 = update(paramsCopy2, { netAmountAfterTax: {value : {$set : netAmountAfterTaxValues} } })
    //   }

    switch (section) {
      case "general":
        paramsCopy = Object.assign(contractDetails);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setContractDetails(paramsCopy);
        break;

      case "advancePayment":
        paramsCopy = Object.assign(advancePaymentDetails);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setAdvancePaymentDetails(paramsCopy);
        break;

      case 'invoiceDetails':
        paramsCopy = Object.assign(invoiceDetails);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setInvoiceDetails(paramsCopy);
        break;

      case 'billOfLading':
        paramsCopy = Object.assign(billOfLading);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setBillOfLading(paramsCopy);
        break;

      case 'billOfEntry':
        paramsCopy = Object.assign(billOfEntry);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setBillOfEntry(paramsCopy);
        break;

      case 'portDetails':
        paramsCopy = Object.assign(portDetails);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setPortDetails(paramsCopy);
        break;

      case 'bankDetails':
        paramsCopy = Object.assign(bankDetails);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setBankDetails(paramsCopy);
        break;

      case 'clearingAgent':
        paramsCopy = !paramsCopy ? Object.assign(clearingAgent) : paramsCopy;
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setClearingAgent(paramsCopy);
        break;

      case 'insuranceDetails':
        paramsCopy = Object.assign(insuranceDetails);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setInsuranceDetails(paramsCopy);
        break;

      case 'transporter':
        paramsCopy = !paramsCopy ? Object.assign(transporter) : paramsCopy;
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setTransporter(paramsCopy);
        break;

      case 'linerBill':
        paramsCopy = !paramsCopy ? Object.assign(linerBill) : paramsCopy;
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setLinerBill(paramsCopy);
        break;

      case 'remarks':
        paramsCopy = Object.assign(remarksPartyParams);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setRemarksParty(paramsCopy);
        break;

      case 'containerDetail':
        paramsCopy = Object.assign(containerDetail);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setContainerDetail(paramsCopy);
        break;

      case 'otherCharges':
        paramsCopy = Object.assign(otherCharges);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setOtherCharges(paramsCopy);
        break;

      default:
        break;
      // case 'itemDetail':
      //   paramsCopy = update(paramsCopy,{[paramName]:{[key]:{$set :value} } });
      //   setItemDetail(paramsCopy);

      //   if(showTaxPercent)
      //     {
      //       setTaxPercentage(paramsCopy2);
      //     }
      //     else{
      //       setTaxAmount(paramsCopy2);
      //     }

      //   break;

      // case 'taxAmount':
      //   paramsCopy2 = update(paramsCopy2,{[paramName]:{[key]:{$set: value} } });
      //   setTaxAmount(paramsCopy2);
      //   break;

      // case 'taxPercentage':
      //   paramsCopy2 = update(paramsCopy2,{[paramName]:{[key]:{$set: value} } });
      //   setTaxPercentage(paramsCopy2);
      //   break;
    }

  }
  const addNewTab = () => {
    if (tabListCondition) {
      setContainerErrorList([]);
      setItemErrorList([]);
      setInvoiceErrors([]);
      let isInvoiceSaved = saveCurrentInvoice(selectedTab);
      if (isInvoiceSaved) {
        let previousTab = tabList[tabList.length - 1];
        let newTabObj = {
          id: `invoice${previousTab.tabNo + 1}`,
          name: `Invoice ${previousTab.tabNo + 1}`,
          tabNo: previousTab.tabNo + 1
        };
        setTabList([...tabList, newTabObj]);
        setSelectedTab(newTabObj.id);
      }
    }
  }

  const saveCurrentInvoice = (tabId, flag) => {


    let containerErrorCopy = [];
    let itemErrorCopy = [];
    let invoiceErrorCopy = [];

    let containerCheck = ['containerNo', 'containerWeightMt', 'containerSize', 'sealNo', 'dcNo'].some((param) => containerDetail[param].value !== '');
    if (containerCheck) {
      containerErrorCopy.push(`: ${tabId} : Unsaved Information Found`);
    }

    let linerBillCheck = Object.keys(linerBill).some((param) => linerBill[param].value !== '');
    if (linerBillCheck) {
      invoiceErrorCopy.push(`Liner Bill : ${tabId} Unsaved Information Found`);
    }

    let itemDetailCheck = Object.keys(itemDetail).filter((keys) => !['currencyConversionRate'].includes(keys))
      .some((param) => itemDetail[param].value !== '')
    if (itemDetailCheck) {
      itemErrorCopy.push(` : ${tabId} Unsaved Information Found`);
    }

    if (itemLineItems.length == 0) {
      itemErrorCopy.push(`: ${tabId} Add atleast One Line Item in the invoice`)
    }

    let invoiceDetailCopy = structuredClone(invoiceDetails);
    Object.keys(invoiceDetailCopy).forEach((param) => {
      if (invoiceDetailCopy[param].mandatory) {
        invoiceDetailCopy = update(invoiceDetailCopy, { [param]: { error: { $set: !validateMandatory(invoiceDetailCopy[param].value.toString()) } } });
      }
      if (invoiceDetailCopy[param].error) {
        invoiceErrorCopy.push(`${tabId} : ${invoiceDetailCopy[param].errorMessage}`);
      }
    });

    setInvoiceDetails(invoiceDetailCopy);

    let proceedToSave = [containerErrorCopy, itemErrorCopy, invoiceErrorCopy].every((errorList) => errorList.length == 0);
    if (proceedToSave) {
      let allInvoiceCopy = Object.assign(invoiceList);
      let currentInvoiceObj = {
        invoiceDetails: Object.assign(invoiceDetails),
        billOfLading: Object.assign(billOfLading),
        billOfEntry: Object.assign(billOfEntry),
        portDetails: Object.assign(portDetails),
        bankDetails: Object.assign(bankDetails),
        clearingAgent: Object.assign(clearingAgent),
        insuranceDetails: Object.assign(insuranceDetails),
        transporter: Object.assign(transporter),
        remarksPartyParams: Object.assign(remarksPartyParams),
        otherCharges: Object.assign(otherCharges)
      };
      [
        "invoiceDetails",
        "billOfLading",
        "billOfEntry",
        "portDetails",
        "bankDetails",
        "clearingAgent",
        "insuranceDetails",
        "transporter",
        "remarksPartyParams",
        "otherCharges"
      ].forEach((obj) => {

        Object.entries(currentInvoiceObj[obj]).forEach(([param, paramObj]) => {
          if (param.includes('Doc')) {
            currentInvoiceObj[param] = paramObj.value?.size > 0 ? paramObj.value : null
          } else if (param.toLowerCase().includes('date')) {
            currentInvoiceObj[param] = paramObj.value || ''
          } else {
            currentInvoiceObj[param] = paramObj.value;
          }
          if (flag !== 'submit')
            paramObj.value = "";
        })

        switch (obj) {
          case 'invoiceDetails':
            setInvoiceDetails({ ...currentInvoiceObj[obj] })
            break;

          case 'billOfLading':
            setBillOfLading({ ...currentInvoiceObj[obj] })
            break;

          case 'billOfEntry':
            setBillOfEntry({ ...currentInvoiceObj[obj] })
            break;

          case 'portDetails':
            setPortDetails({ ...currentInvoiceObj[obj] });
            break;

          case 'bankDetails':
            setBankDetails({ ...currentInvoiceObj[obj] });
            break;

          case 'clearingAgent':
            setClearingAgent({ ...currentInvoiceObj[obj] });
            break;

          case 'insuranceDetails':
            setInsuranceDetails({ ...currentInvoiceObj[obj] });
            break;

          case 'transporter':
            setTransporter({ ...currentInvoiceObj[obj] });
            break;

          case 'remarksPartyParams':
            setRemarksParty({ ...currentInvoiceObj[obj] });
            break;

          case 'otherCharges':
            setOtherCharges({ ...currentInvoiceObj[obj] });
            break;

        }

        delete currentInvoiceObj[obj];
      });
      if (linerBillItems.length > 0) {
        linerBillItems.map((line, idx) => {
          for (var key of Object.keys(line)) {
            if (line[key] === "") {
              linerBillItems[idx][key] = null;
            }
          }
        })
        currentInvoiceObj['linerBillItems'] = structuredClone(linerBillItems);
      } else {
        currentInvoiceObj['linerBillItems'] = [];
      }
      currentInvoiceObj['containerLineItems'] = JSON.parse(JSON.stringify(containerLineItems));
      currentInvoiceObj['itemLineItems'] = JSON.parse(JSON.stringify(itemLineItems));
      currentInvoiceObj['showTaxPercent'] = showTaxPercent;


      setLinerBillItems([]);
      setContainerLineItems([]);
      setItemLineItems([]);
      allInvoiceCopy[tabId] = Object.assign(currentInvoiceObj);
      setInvoiceList(allInvoiceCopy);
      return true;
    } else {
      setContainerErrorList(containerErrorCopy);
      setItemErrorList(itemErrorCopy);
      setInvoiceErrors(invoiceErrorCopy);
      return false;
    }
  }
  const checkInvoiceErrors = (tabId) => {
    let errorList = [];

    // if(errorList.length == 0)
    // {
    //   let invoiceListCopy = Object.assign(invoiceList);
    //   invoiceListCopy[tabId] = {};
    //   setInvoiceList(invoiceListCopy)
    // }
  }

  const loadInvoiceDetails = (tabId) => {
    let allInvoiceCopy = Object.assign(invoiceList);
    let invoicePageOfId = allInvoiceCopy[tabId];
    [
      invoiceDetails,
      billOfLading,
      portDetails,
      bankDetails,
      clearingAgent,
      insuranceDetails,
      transporter,
      remarksPartyParams,
      otherCharges,
      billOfEntry,
    ].forEach((obj, index) => {
      let ParamsCopy = Object.assign(obj);
      Object.entries(ParamsCopy).forEach(([key, value]) => {
        ParamsCopy[key]['value'] = invoicePageOfId[key] ?? '';
      })

      switch (index) {
        case 0:
          setInvoiceDetails(ParamsCopy)
          break;

        case 1:
          setBillOfLading(ParamsCopy)
          break;

        case 2:
          setPortDetails(ParamsCopy);
          break;

        case 3:
          setBankDetails(ParamsCopy);
          break;

        case 4:
          setClearingAgent(ParamsCopy);
          break;

        case 5:
          setInsuranceDetails(ParamsCopy);
          break;

        case 6:
          setTransporter(ParamsCopy);
          break;

        case 7:
          setRemarksParty(ParamsCopy);
          break;

        case 8:
          setOtherCharges(ParamsCopy);
          break;

        case 9:
          setBillOfEntry(ParamsCopy);
          break;

      }

    });
    setShowTaxPercent(invoicePageOfId['showTaxPercent']);
    setLinerBillItems(invoicePageOfId['linerBillItems']);
    setContainerLineItems(invoicePageOfId['containerLineItems']);
    setItemLineItems(invoicePageOfId['itemLineItems']);

  }

  const deleteTab = (tabIndex, tabId) => {


    let tabListCopy = tabList.slice();
    let invoiceListCopy = structuredClone(invoiceList);
    let currentTab = tabListCopy[tabListCopy.length - 1];

    tabListCopy.splice(tabIndex, 1);
    delete invoiceListCopy[tabId];
    currentTab = tabListCopy[tabListCopy.length - 1];
    setTabList(tabListCopy);
    setSelectedTab(currentTab.id);
    setInvoiceList(invoiceListCopy);
    return { tabListCopy, currentTab, invoiceListCopy }
  }


  const RenderTabList = () => {

    return tabList.map((tab, tabIndex) => {
      return (
        <React.Fragment key={tab.id}>
          <div
            className={selectedTab === tab.id ? "createPurchaseImportTabSelected" : "createPurchaseImportTab"}
            onClick={() => {
              if (tab.id !== selectedTab) {
                setContainerErrorList([]);
                setItemErrorList([]);
                setInvoiceErrors([]);
                let isInvoiceSaved = saveCurrentInvoice(selectedTab)
                if (isInvoiceSaved) {
                  setSelectedTab(tab.id);
                  loadInvoiceDetails(tab.id);
                }
              }
            }}>
            {tab.name}
            {tab.id === selectedTab && tabList.length > 1 ? (
              <span
                className="tabCloseButton"
                title="Delete Invoice"
                onClick={(e) => {
                  let msg = window.confirm(`Are You sure to Delete this Invoice? It won't be available later`);
                  if (msg) {
                    const { tabListCopy, currentTab, invoiceListCopy } = deleteTab(tabIndex, tab.id)
                    //.then(({tabListCopy,currentTab,invoiceListCopy})=>{
                    setTabList(tabListCopy);
                    loadInvoiceDetails(currentTab.id)
                    setSelectedTab(currentTab.id);
                    setInvoiceList(invoiceListCopy);
                    //});
                  }
                  e.stopPropagation();
                }}>
                X
              </span>
            ) : null}
          </div>
        </React.Fragment>
      );
    });
  }
  const renderFormElements = ({ paramsList, params, section }) => {
    return paramsList.map((element) => {
      switch (element) {
        case "billOfLadingDoc":
        case "billOfEntryDoc":
        case "clearingDoc":
        case "packingDoc":
        case "insuranceDoc":
        case "transporterDoc":
        case "otherChargesDoc":
        case "bankDoc":
        case "bankDoc2":
        case "linerBillDoc":
          return (
            <FormElement key={element}
              inputType={params[element]?.value ? "uploadReady" : "upload"}
              value={params[element]?.value?.name || params[element]?.value || ""}
              setInput={(value) => updatePurchaseValues({ paramName: element, key: "value", value, section })}
              hintText={params[element].hintText} mandatory={params[element].mandatory}
              colSpan={params[element].colSpan} error={params[element].error}
              rowSpan={params[element].rowSpan} placeholder={params[element].placeholder}
              title={params[element].title} disabled={params[element].disabled} />
          );
        default:
          return (
            <FormElement key={element} inputType={params[element].inputType}
              value={params[element].value} hintText={params[element].hintText}
              setInput={(value) => updatePurchaseValues({ paramName: element, key: "value", value, section })}
              mandatory={params[element].mandatory} colSpan={params[element].colSpan}
              rowSpan={params[element].rowSpan} error={params[element].error}
              placeholder={params[element].placeholder} title={params[element].title}
              disabled={params[element].disabled} options={params[element].options || null} />
          );
      }
    })
  }

  const RenderContractDays = () => {
    return (
      <React.Fragment>
        <div className="detailTitle">Contract Detail</div>
        <div className="detailText">
          {` Contract Date: ${contractDayCounts.contractDate}`}
          <br /> {`Contract Days: ${contractDayCounts.contractDays}`}
        </div>
      </React.Fragment>
    );
  };

  const editLinersItem = (action, index) => {
    var linerBillsListCopy = JSON.parse(JSON.stringify(linerBillItems));

    var linerBillsInItems = [];
    linerBillItems.forEach((item) => {
      linerBillsInItems.push(item.linerBillNo);
    });

    switch (action) {
      case "add":
        var paramsCopy = Object.assign(linerBill);

        var linerBillObj = {};
        purchaseImportParamsList.liner.forEach((item) => {
          linerBillObj[item] = paramsCopy[item].value;
        });
        var containerListCopy = linerBillItems.slice();
        if (Object.keys(linerBillObj).length) {
          containerListCopy.push(linerBillObj);
        }

        purchaseImportParamsList.liner.forEach((item) => {
          paramsCopy[item].value = "";
        });
        setLinerBillItems(containerListCopy);
        setLinerBill(paramsCopy);
        break;
      case "delete":
        linerBillsListCopy.splice(index, 1);
        setLinerBillItems(linerBillsListCopy);
        break;
      default:
        break;
    }
  }
  const renderLinerItems = () => {
    return linerBillItems.map((line, i) => {
      return (
        <tr className="createVendorContactsTableRows" key={i}>
          <td>{line.linerName}</td>
          <td>{line.linerBillNo}</td>
          <td>{line.linerBillDate}</td>
          <td>{line.linerBillAmount}</td>
          <td>{line.linerBillTaxAmount}</td>
          <td>{line.linerBillTotalAmount}</td>
          <td className="itemIndiTabActions">
            <img
              className="createVendorContactsAction"
              src={deleteIcon}
              alt="Delete"
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) editLinersItem("delete", i);
              }}
            />
          </td>
        </tr>
      );
    });
  };


  const RenderLinersTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr
            className="createVendorContactsTableHeader"
            style={{ textAlign: "left" }}>
            <td style={{ padding: "5px 10px" }}>Liner Bill Name</td>
            <td style={{ padding: "5px 10px" }}>Liner Bill No</td>
            <td style={{ padding: "5px 10px" }}>Liner Bill Date</td>
            <td style={{ padding: "5px 10px" }}>Charge</td>
            <td style={{ padding: "5px 10px" }}>Tax Amount</td>
            <td style={{ padding: "5px 10px" }}>Liner Bill Total Amount</td>
            <td style={{ padding: "5px 20px", textAlign: "right" }}>Action</td>
          </tr>
        </thead>
        <tbody>
          {linerBillItems?.length > 0 ? (
            renderLinerItems()
          ) : (
            <tr className="createVendorContactsTableRows">
              {purchaseImportParamsList.liner.map((line) => (
                (line !== 'linerBillDoc') ?
                  <td key={line}>&nbsp;</td> : null
              ))}
              <td>&nbsp;</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const RenderContainerTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr
            className="createVendorContactsTableHeader"
            style={{ textAlign: "left" }}>
            <td style={{ padding: "5px 10px" }}>Container No</td>
            <td style={{ padding: "5px 10px" }}>Container Weight</td>
            <td style={{ padding: "5px 10px" }}>Container Size</td>
            <td style={{ padding: "5px 10px" }}>Seal Number</td>
            <td style={{ padding: "5px 20px", textAlign: "right" }}>Action</td>
          </tr>
        </thead>
        <tbody>
          {containerLineItems?.length > 0 ? (
            renderContainerItems()
          ) : (
            <tr className="createVendorContactsTableRows">
              {purchaseImportParamsList.containerDetail.map((line) => (
                <td key={line}>&nbsp;</td>
              ))}
              <td>&nbsp;</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };
  const editContainerItem = (action, index) => {
    var containerListCopy = JSON.parse(JSON.stringify(containerLineItems));

    var containerNamesInItems = [];
    itemLineItems.forEach((item) => {
      containerNamesInItems.push(item.containerNo);
    });

    var containersUnique = Array.from(
      new Set(containerNamesInItems.map((item) => item))
    );

    switch (action) {
      case "delete":
        if (containersUnique.includes(containerListCopy[index].containerNo)) {
          window.alert(
            "Container used in item table. Delete Item records with this container to delete here"
          );
          break;
        } else {
          containerListCopy.splice(index, 1);
          var containerOptionsForItems = [];
          containerListCopy.forEach((container) => {
            var cont = {
              optionId: container.containerNo,
              optionName: container.containerNo,
            };
            containerOptionsForItems.push(cont);
          });

          var paramsCopy = Object.assign(containerDetail);
          paramsCopy = update(paramsCopy, {
            containerNo: { options: { $set: containerOptionsForItems } },
          });
          setContainerDetail(paramsCopy);

          setContainerLineItems(containerListCopy);
          break;
        }
      default:
        break;
    }
  }

  const renderContainerItems = () => {
    return containerLineItems.map((line, i) => {
      return (
        <tr className="createVendorContactsTableRows" key={i}>
          <td>{line.containerNo}</td>
          <td>{line.containerWeightMt}</td>
          <td>{line.containerSize}</td>
          <td>{line.sealNo}</td>
          <td>{line.dcNo}</td>
          <td className="itemIndiTabActions">
            <img
              className="createVendorContactsAction"
              src={deleteIcon}
              alt="Delete"
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) editContainerItem("delete", i);
              }}
            />
          </td>
        </tr>
      );
    });
  };


  const checkContainerError = () => {
    var errorList = [];

    var paramsCopy = Object.assign(containerDetail);

    purchaseImportParamsList.containerDetail.forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (item === "containerWeightMt" || item === "containerSize") {

        if (paramsCopy[item].value !== "") {
          if (isNaN(paramsCopy[item].value)) {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: true } },
            });
          } else {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: false } },
            });
          }
        } else {
          paramsCopy = update(paramsCopy, {
            [item]: { error: { $set: false } },
          });
        }
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    if (errorList.length === 0) {
      var containerListCopy = containerLineItems.slice();
      containerListCopy.push({
        containerNo: paramsCopy.containerNo.value,
        containerWeightMt: paramsCopy.containerWeightMt.value,
        containerSize: paramsCopy.containerSize.value,
        sealNo: paramsCopy.sealNo.value,
        dcNo: paramsCopy.dcNo.value,
      });

      paramsCopy.containerNo.value = "";
      paramsCopy.containerWeightMt.value = "";
      paramsCopy.containerSize.value = "";
      paramsCopy.sealNo.value = "";
      paramsCopy.dcNo.value = "";
      paramsCopy.containerNoId.value = "";

      setContainerLineItems(containerListCopy);
    }

    var containerOptionsForItems = [];
    containerListCopy?.forEach((container) => {
      var cont = {
        optionId: container.containerNo,
        optionName: container.containerNo,
      };
      containerOptionsForItems.push(cont);
    });
    paramsCopy = update(paramsCopy, {
      containerNo: { options: { $set: containerOptionsForItems } },
    });

    setContainerErrorList(errorList);
    setContainerDetail(paramsCopy);
  };


  const checkItemError = () => {
    var errorList = [];

    var paramsCopy = Object.assign(itemDetail);

    purchaseImportParamsList.itemDetail.forEach((item) => {

      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (item === "ratePerMt" || item === "quantity") {

        if (paramsCopy[item].value !== "") {
          if (isNaN(paramsCopy[item].value)) {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: true } },
            });
          } else {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: false } },
            });
          }
        } else {
          paramsCopy = update(paramsCopy, {
            [item]: { error: { $set: false } },
          });
        }
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });
    let taxParamsCopy;
    if (showTaxPercent) {
      taxParamsCopy = Object.assign(taxPercentage);
      purchaseImportParamsList.itemDetailTaxPercentage.forEach((item) => {
        if (
          [
            // "exciseTaxPercentage",
            "cessTaxPercentage",
            // "splExciseTaxPercentage",
            "tcsPercentage",
            "gstPercentage",
            // 'interestPercentage',
            // 'penaltyPercentage'
          ].includes(item)
        ) {
          if (taxParamsCopy[item].value !== "") {
            if (isNaN(taxParamsCopy[item].value)) {
              taxParamsCopy = update(taxParamsCopy, {
                [item]: { error: { $set: true } },
              });
            } else {
              if (parseFloat(taxParamsCopy[item].value) < 100) {
                taxParamsCopy = update(taxParamsCopy, {
                  [item]: { error: { $set: false } },
                });
              } else {
                taxParamsCopy = update(taxParamsCopy, {
                  [item]: { error: { $set: true } },
                });
              }
            }
          } else {
            taxParamsCopy = update(taxParamsCopy, {
              [item]: { error: { $set: false } },
            });
          }

          // taxParamsCopy = update(taxParamsCopy, {
          //   exciseTaxAmount: { error: { $set: false } },
          //   cessTaxAmount: { error: { $set: false } },
          //   gstAmount: { error: { $set: false } },
          // });

          if (taxParamsCopy[item].error) {
            errorList.push(taxParamsCopy[item].errorMessage);
          }
        }
      });
    } else {
      taxParamsCopy = Object.assign(taxAmount)
      purchaseImportParamsList.itemDetailTaxAmount.forEach((item) => {
        if (
          [
            "cessTaxAmount",
            "tcsAmount",
            "gstAmount",
          ].includes(item)
        ) {
          if (taxParamsCopy[item].value !== "") {
            if (isNaN(taxParamsCopy[item].value)) {
              taxParamsCopy = update(taxParamsCopy, {
                [item]: { error: { $set: true } },
              });
            } else {
              taxParamsCopy = update(taxParamsCopy, {
                [item]: { error: { $set: false } },
              });
            }
          } else {
            taxParamsCopy = update(taxParamsCopy, {
              [item]: { error: { $set: false } },
            });
          }

          if (taxParamsCopy[item].error) {
            errorList.push(taxParamsCopy[item].errorMessage);
          }
        }
      });
    }

    if (errorList.length === 0) {

      var itemDetailObj = {
        storageId: paramsCopy.storageId.value,
        containerNo: paramsCopy.containerId.value,
        itemId: paramsCopy.itemId.value,
        itemName: paramsCopy.itemId.options.filter(
          (item) => item.optionId === paramsCopy.itemId.value
        )[0]?.optionName,
        from: paramsCopy.from.value,
        to: paramsCopy.to.value,
        netWeight: paramsCopy.netWeight.value,
        grossWeight: paramsCopy.grossWeight.value,
        ratePerMt: paramsCopy.ratePerMt.value,
        quantity: paramsCopy.quantity.value,
        netAmount: Number((paramsCopy.netAmount.value).toFixed(2)),
        netAmountINR: Number((paramsCopy.netAmountINR.value).toFixed(2)),
        currencyConversionRate: paramsCopy.currencyConversionRate.value,
        netAmountAfterTax: Number((taxParamsCopy.netAmountAfterTax.value)).toFixed(2),
      };

      if (showTaxPercent) {
        itemDetailObj.cessTaxPercentage = taxParamsCopy.cessTaxPercentage.value;

        itemDetailObj.tcsPercentage = taxParamsCopy.tcsPercentage.value;
        itemDetailObj.gstPercentage = taxParamsCopy.gstPercentage.value;
        taxParamsCopy = update(taxParamsCopy, {
          cessTaxPercentage: { value: { $set: "" } },
          tcsPercentage: { value: { $set: "" } },
          gstPercentage: { value: { $set: "" } },
          netAmountAfterTax: { value: { $set: "" } }
        });
        setTaxPercentage(taxParamsCopy);
      } else {
        itemDetailObj.cessTaxAmount = taxParamsCopy.cessTaxAmount.value;
        itemDetailObj.tcsAmount = taxParamsCopy.tcsAmount.value;
        itemDetailObj.gstAmount = taxParamsCopy.gstAmount.value;

        taxParamsCopy = update(taxParamsCopy, {
          cessTaxAmount: { value: { $set: "" } },
          tcsAmount: { value: { $set: "" } },
          gstAmount: { value: { $set: "" } },
          netAmountAfterTax: { value: { $set: "" } }
        });
        setTaxAmount(taxParamsCopy);
      }

      paramsCopy = update(paramsCopy, {
        storageId: { value: { $set: "" } },
        containerId: { value: { $set: "" } },
        itemId: { value: { $set: "" } },
        from: { value: { $set: "" } },
        to: { value: { $set: "" } },
        netWeight: { value: { $set: "" } },
        grossWeight: { value: { $set: "" } },
        ratePerMt: { value: { $set: "" } },
        quantity: { value: { $set: "" } },
        netAmount: { value: { $set: "" } },
        netAmountINR: { value: { $set: '' } },
      });

      var itemListCopy = itemLineItems.slice();
      itemListCopy.push(itemDetailObj);
      setItemLineItems(itemListCopy);
    }

    setItemErrorList(errorList);
    setItemDetail(paramsCopy);
  };

  const editItemLineItems = (action, index) => {
    var itemLineItemsCopy = itemLineItems.slice();

    switch (action) {
      case "delete":
        itemLineItemsCopy.splice(index, 1);
        setItemLineItems(itemLineItemsCopy);
    }
  }
  const renderItems = () => {
    return itemLineItems.map((line, i) => {
      return (
        <tr className="createVendorContactsTableRows" key={i}>
          <td>{line.containerNo}</td>
          <td>{line.itemName}</td>
          <td>{line.from ? line.from : " - "}</td>
          <td>{line.to ? line.to : " - "}</td>
          <td>{line.netWeight ? line.netWeight : " - "}</td>
          <td>{line.grossWeight ? line.grossWeight : " - "}</td>
          <td>{line.ratePerMt ? line.ratePerMt : " - "}</td>
          <td>{line.quantity ? line.quantity : " - "}</td>
          <td>{line.netAmount ? line.netAmount : " - "}</td>
          <td>{line.currencyConversionRate !== undefined ? line.currencyConversionRate : ' - '}</td>
          <td>{line.netAmountINR ? line.netAmountINR : '-'}</td>
          <td>{Number((line.netAmountAfterTax - line.netAmountINR).toFixed(2)) || "-"}</td>
          <td>{line.netAmountAfterTax !== undefined ? line.netAmountAfterTax : " - "}</td>
          <td className="itemIndiTabActions">
            <img
              className="createVendorContactsAction"
              src={deleteIcon}
              alt="Delete"
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) {
                  editItemLineItems("delete", i);
                }
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const RenderItemTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr
            className="createVendorContactsTableHeader"
            style={{ textAlign: "left" }}>
            <td style={{ padding: "5px 10px" }}>Container No</td>
            <td style={{ padding: "5px 10px" }}>Item Name</td>
            <td style={{ padding: "5px 10px" }}>Weight From</td>
            <td style={{ padding: "5px 10px" }}>Weight To</td>
            <td style={{ padding: "5px 10px" }}>Net Weight</td>
            <td style={{ padding: "5px 10px" }}>Gross Weight</td>
            <td style={{ padding: "5px 10px" }}>Rate / MT</td>
            <td style={{ padding: "5px 10px" }}>Quantity (MT)</td>
            <td style={{ padding: "5px 10px" }}>Net Amount (USD)</td>
            <td style={{ padding: '5px 10px' }}>Currency Conv. Rate</td>
            <td style={{ padding: '5px 10px' }}>Net Amount(INR)</td>
            <td style={{ padding: '5px 10px' }}>Tax Amount(INR)</td>
            <td style={{ padding: "5px 10px" }}>Net Amount After Tax(INR)</td>
            <td style={{ padding: "5px 20px", textAlign: "right" }}>Action</td>
          </tr>
        </thead>
        <tbody>
          {itemLineItems?.length > 0 ? (
            renderItems()
          ) : (
            <tr className="createVendorContactsTableRows">
              {[
                "containerNo",
                "itemName",
                "from",
                "to",
                "netWeight",
                "grossWeight",
                "ratePerMt",
                "quantity",
                "netAmount",
                "",
                "",
                "",
                "netAmountAfterTax",
              ].map((line, i) => (
                <td key={i}>&nbsp;</td>
              ))}
              <td>&nbsp;</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const PurchaseImportSummary = () => {
    if(itemLineItems.length>0){

    var lineItemsTotalSum = itemLineItems?.reduce((prev, row) => prev + parseFloat(row.netAmountAfterTax), 0);
    var lineItemsSum = itemLineItems?.reduce((prev, row) => prev + parseFloat(row.netAmountINR), 0)
    var lineItemsTaxSum = lineItemsTotalSum - lineItemsSum;
    var linerBillsAmount = linerBillItems?.reduce((prev, row) => prev + parseFloat(row.linerBillAmount), 0)
    var linerBillsTaxAmount = linerBillItems?.reduce((prev, row) => prev + parseFloat(row.linerBillTaxAmount), 0)
    var linerBillsTotalAmount = linerBillItems?.reduce((prev, row) => prev + parseFloat(row.linerBillTotalAmount), 0)
    var invoicePageSum = (parseFloat(clearingAgent['clearingAgentBillAmount'].value) || 0) +
      (parseFloat(transporter['transporterBillAmount'].value) || 0) +
      (parseFloat(linerBillsAmount || 0)) +
      (parseFloat(insuranceDetails['insuranceRupee'].value || 0)) +
      (parseFloat(otherCharges['exciseTaxAmount'].value || 0)) +
      (parseFloat(otherCharges['splExciseTaxAmount'].value || 0)) +
      (parseFloat(otherCharges['interestAmount'].value || 0)) +
      (parseFloat(otherCharges['penaltyAmount'].value || 0)) +
      lineItemsSum;

    var invoicePageTaxTotal = (parseFloat(clearingAgent["clearingAgentTaxAmount"].value) || 0) +
      (parseFloat(transporter["transporterBillTaxAmount"].value) || 0) +
      (parseFloat(linerBillsTaxAmount) || 0) + lineItemsTaxSum;

    var invoicePageTotal = invoicePageSum + invoicePageTaxTotal;

    return (
      <div className="vendorSummaryGrid plantsSubtitle purchaseImportSummary">
        <span className="vendorSummaryHeader">Title</span><span className="vendorSummaryHeader">Amount</span><span className="vendorSummaryHeader"> Tax Amount</span><span className="vendorSummaryHeader">Total Amount</span>
        <span className="vendorSummaryCategory">Clearing Agent Bill Amount</span>
        <span className="vendorSummaryValue">{clearingAgent["clearingAgentBillAmount"].value || 0}</span>
        <span className="vendorSummaryValue">{clearingAgent["clearingAgentTaxAmount"].value || 0}</span>
        <span className="vendorSummaryValue">{clearingAgent["clearingAgentTotalAmount"].value || 0}</span>
        <span className="vendorSummaryCategory">Transporter Bill Amount</span>
        <span className="vendorSummaryValue"> {transporter["transporterBillAmount"].value || 0}</span>
        <span className="vendorSummaryValue"> {transporter["transporterBillTaxAmount"].value || 0}</span>
        <span className="vendorSummaryValue"> {transporter["transporterBillTotalAmount"].value || 0}</span>
        <span className="vendorSummaryCategory">Liner Bill Amount</span>
        <span className="vendorSummaryValue">{linerBillsAmount || 0}</span>
        <span className="vendorSummaryValue">{linerBillsTaxAmount || 0}</span>
        <span className="vendorSummaryValue">{linerBillsTotalAmount || 0}</span>
        <span className="vendorSummaryCategory">Insurance Bill Amount</span>
        <span className="vendorSummaryValue">{insuranceDetails["insuranceRupee"].value || 0}</span>
        <span className="vendorSummaryValue">0</span>
        <span className="vendorSummaryValue">{insuranceDetails["insuranceRupee"].value || 0}</span>
        <span className="vendorSummaryCategory">Excise Tax Amount</span><span className="vendorSummaryValue">{otherCharges['exciseTaxAmount'].value || 0}</span><span className="vendorSummaryValue">0</span><span className="vendorSummaryValue">{otherCharges['exciseTaxAmount'].value || 0}</span>
        <span className="vendorSummaryCategory">Spl. Excise Tax Amount</span><span className="vendorSummaryValue">{otherCharges['splExciseTaxAmount'].value || 0}</span><span className="vendorSummaryValue">0</span><span className="vendorSummaryValue">{otherCharges['splExciseTaxAmount'].value || 0}</span>
        <span className="vendorSummaryCategory">Interest Amount</span><span className="vendorSummaryValue">{otherCharges['interestAmount'].value || 0}</span><span className="vendorSummaryValue">0</span><span className="vendorSummaryValue">{otherCharges['interestAmount'].value || 0}</span>
        <span className="vendorSummaryCategory">Penalty Amount</span><span className="vendorSummaryValue">{otherCharges['penaltyAmount'].value || 0}</span><span className="vendorSummaryValue">0</span><span className="vendorSummaryValue">{otherCharges['penaltyAmount'].value || 0}</span>

        <span className="vendorSummaryCategory">Items Total</span>
        <span className="vendorSummaryValue">{lineItemsSum}</span><span className="vendorSummaryValue">{Number((lineItemsTaxSum).toFixed(2))}</span><span className="vendorSummaryValue">{Number((lineItemsTotalSum).toFixed(2))}</span>
        <span className="vendorSummaryCategory">Total Amount</span>
        <span className="vendorSummaryValue">{Number((invoicePageSum).toFixed(2))} </span>
        <span className="vendorSummaryValue">{Number((invoicePageTaxTotal).toFixed(2))}</span>
        <span className="vendorSummaryValue">{Number((invoicePageTotal).toFixed(2))}</span>
      </div>
    )
    
    
  }else{
    return null
  }
  }

  const checkAllInvoiceErrors = () => {
    let allInvoiceCopy = JSON.parse(JSON.stringify(structuredClone(invoiceList)));
    let importErrorsCopy = JSON.parse(JSON.stringify(structuredClone(importErrors)));
    let invoiceDetailCopy = JSON.parse(JSON.stringify(structuredClone(invoiceDetails)));
    let contractDetailCopy = JSON.parse(JSON.stringify(structuredClone(contractDetails)));
    let advanceDetailCopy = JSON.parse(JSON.stringify(structuredClone(advancePaymentDetails)));

    Object.keys(invoiceDetailCopy).forEach((param) => {
      if (invoiceDetailCopy[param].mandatory) {
        invoiceDetailCopy = update(invoiceDetailCopy, { [param]: { error: { $set: !validateMandatory(invoiceDetailCopy[param].value.toString()) } } })
      }
      if (invoiceDetailCopy[param].error) {
        importErrorsCopy.push(invoiceDetailCopy[param].errorMessage);
      }

    })

    if (importErrorsCopy.length > 0) {
      setImportErrors(importErrors);
    } else {
      let dataToServer = new FormData();
      let invoiceToServer = [];
      dataToServer.append('shipMentDate', contractDetailCopy.shipmentDate.value);
      if (contractDetailCopy.packingDoc.value && contractDetailCopy.packingDoc.value.arrayBuffer)
        dataToServer.append('packingDoc', contractDetailCopy.packingDoc.value)

      Object.keys(advanceDetailCopy).forEach((key) => {
        dataToServer.append(key, advanceDetailCopy[key].value)
      })

      tabList.forEach((tab, tabArrayIndex) => {
        let currentTab = JSON.parse(JSON.stringify(allInvoiceCopy[tab.id]));
        var bolDocNames = [
          "billOfLadingDocumentA",
          "billOfLadingDocumentB",
          "billOfLadingDocumentC",
          "billOfLadingDocumentD",
          "billOfLadingDocumentE",
        ];
        var boeDocNames = [
          "billOfEntryDocumentA",
          "billOfEntryDocumentB",
          "billOfEntryDocumentC",
          "billOfEntryDocumentD",
          "billOfEntryDocumentE",
        ];

        var bankDocNames = [
          "bankDocumentA",
          "bankDocumentB",
          "bankDocumentC",
          "bankDocumentD",
          "bankDocumentE",
        ];

        var bankDocNames2 = [
          "bankDocumentF",
          "bankDocumentG",
          "bankDocumentH",
          "bankDocumentI",
          "bankDocumentJ",
        ]
        var clearDocNames = [
          "clearingDocumentA",
          "clearingDocumentB",
          "clearingDocumentC",
          "clearingDocumentD",
          "clearingDocumentE",
        ];

        var insurDocNames = [
          "insuranceDocumentA",
          "insuranceDocumentB",
          "insuranceDocumentC",
          "insuranceDocumentD",
          "insuranceDocumentE",
        ];

        var transportDocNames = [
          "transporterDocumentA",
          "transporterDocumentB",
          "transporterDocumentC",
          "transporterDocumentD",
          "transporterDocumentE",
        ];

        Object.entries({
          'billOfLadingDoc': bolDocNames, 'billOfEntryDoc': boeDocNames, 'bankDoc': bankDocNames,
          'bankDoc2': bankDocNames2, 'clearingDoc': clearDocNames,
          'insuranceDoc': insurDocNames, 'transporterDoc': transportDocNames,"otherChargesDoc":"otherChargesDoc"
        }).forEach(([docType, docNames]) => {
          if (currentTab[docType] && currentTab[docType].arrayBuffer) {
            dataToServer.append(docNames[tabArrayIndex], currentTab[docType]);
          }
        })

        if (currentTab.linerBillItems.length > 0) {

          // add current tab liner bill each line docs to serverform
          currentTab.linerBillItems?.forEach((linerLine, linerLineIndex) => {
            if (linerLine.linerBillDoc && linerLine.linerBillDoc.arrayBuffer) {
              dataToServer.append('linerBillDocuments', linerLine.linerBillDoc);
              currentTab.linerBillItems[linerLineIndex].linerBillDocName = linerLine.linerBillDoc?.name;
            } else {
              currentTab.linerBillItems[linerLineIndex].linerBillDocName = "";
            }
          });
        }
        Object.keys(currentTab).forEach((key) => {
          currentTab[key] = currentTab[key] || ''
        })
        invoiceToServer.push(currentTab);
      })
      dataToServer.append('invoiceLineItems', JSON.stringify(invoiceToServer));
      submitData(dataToServer);
    }

  }

  const submitData = async (serverData) => {
    let response = await fetchData({
      method: 'put',
      requestingPage: 'createItem',
      data: serverData,
      url: 'po-edit/purchase-import3/' + poInfo.editPoImportId,
      headers: { token: isLogged.accessToken, module: "Purchase Import" }
    })

    if (response.msg === 'success') {
      setIsOpen(true)
      setModalText('SuccessFully Edited Purchase Import')
    } else {
      setModalText(`Upload Failed:${response.desc}`);
      setImportErrors([response.desc]);
    }
    // console.log(serverData);
    // for(const pair of serverData){
    //   console.log(`${pair[0]} : ${pair[1]}`)
    // }
    setDataSubmitted(true);
  }
  const renderErrorMessage = () => {
    if (importErrors.length > 0) return importErrors[0];
    else if (invoiceErrors.length > 0) return invoiceErrors[0];
    else if (containerErrorList.length > 0)
      return `Container : ${containerErrorList[0]}`;
    else if (itemErrorList.length > 0)
      return `Item Detail: ${itemErrorList[0]}`;
    else return null;
  };
  const resetAllValues = () => {
    console.log('reset')
  }
  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState("Uploading form. Please wait...");
  const submitOkClick = () => {
    setIsOpen(false);
    if (purchaseImportErrors.length === 0) {
      setSection("purchaseImportList");
      resetAllValues();
    }
    setModalText("Uploading form. Please wait...");
  };

  return (
    <React.Fragment>
      <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText}
        dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
      <div className="formArea">
        <div style={{
          width: "1100px", margin: "0 auto 4rem", padding: "3rem",
          border: "1px solid lightgray", borderRadius: "5px", backgroundColor: "white",
        }}>
          <div className="purchaseSubTitle">Contract Details :</div>
          <div style={{ display: "flex" }}>
            <div className="createPurchaseOrderGrid" style={{ gridTemplateRows: "repeat(2,4rem)" }}>
              {renderFormElements({
                paramsList: purchaseImportParamsList.contractDetails,
                params: contractDetails, section: 'general'
              })}
            </div>
            <div style={{ width: "280px" }}>
              <div className="vendorStoreDetailArea">
                <div className="poStoreAddressDetail">
                  {showContractDays && <RenderContractDays />}
                </div>
              </div>
            </div>
          </div>

          {/*Advance Payment Details*/}
          <div className="subTitle">Advance Payment Details :</div>
          <div className="createPurchaseImportGrid" style={{ gridTemplateRows: " repeat(3, 4rem)" }}>
            {renderFormElements({
              paramsList: purchaseImportParamsList.advancePaymentDetails,
              params: advancePaymentDetails, section: "advancePayment"
            })}
            {renderFormElements({
              paramsList: purchaseImportParamsList.advancePaymentDetails2,
              params: advancePaymentDetails, section: "advancePayment"
            })}
            {renderFormElements({
              paramsList: purchaseImportParamsList.advancePaymentDetails3,
              params: advancePaymentDetails, section: "advancePayment"
            })}
          </div>

          {/*Tabs' Area*/}
          <div className='createPurchaseImportTabArea'>
            <div className="createVendorTabs" style={{ borderBottom: "1px solid rgb(240,240,240)" }}>
              <RenderTabList />
              <div className="createPurchaseImportTab"
                style={tabListCondition ? { cursor: "pointer", width: "wrapContent" } : { cursor: "not-allowed", color: "lightgray" }}
                onClick={() => tabListCondition ? addNewTab() : null}
                title={tabListCondition ? "Add Invoice" : "Max 5 invoice"}>
                {" "}+{" "}
              </div>
            </div>

            <div style={{ fontSize: "1px" }}>&nbsp;</div>

            <div className="plantsSubTitle">Invoice Details :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements({
                paramsList: purchaseImportParamsList.invoiceDetails,
                params: invoiceDetails, section: 'invoiceDetails'
              })}
            </div>

            <div className="plantsSubTitle">Balance Payment Details :</div>
            <div className="createPurchaseImportTabDetails"
              style={{ gridTemplateRows: "repeat(3,4rem)", gridTemplateColumns: "repeat(23,1fr)" }}>
              {renderFormElements({
                paramsList: purchaseImportParamsList.balancePaymentDetails,
                params: invoiceDetails, section: 'invoiceDetails'
              })}
              {renderFormElements({
                paramsList: purchaseImportParamsList.balancePaymentDetails2,
                params: invoiceDetails, section: 'invoiceDetails'
              })}
              {renderFormElements({
                paramsList: purchaseImportParamsList.balancePaymentDetails3,
                params: invoiceDetails, section: 'invoiceDetails'
              })}
            </div>

            <div className="plantsSubTitle">Bill Of Ladding :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements({
                paramsList: purchaseImportParamsList.billOfLading,
                params: billOfLading, section: 'billOfLading'
              })}
            </div>

            <div className="plantsSubTitle">Bill Of Entry :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements({
                paramsList: purchaseImportParamsList.billOfEntry,
                params: billOfEntry, section: 'billOfEntry'
              })}
            </div>

            <div className="plantsSubTitle">Port :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements({
                paramsList: purchaseImportParamsList.port,
                params: portDetails, section: "portDetails"
              })}
            </div>

            <div className="plantsSubTitle">Bank :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements({
                paramsList: purchaseImportParamsList.bankDetail,
                params: bankDetails, section: 'bankDetails'
              })}
            </div>

            <div className="plantsSubTitle">Clearing Agent :</div>
            <div className="createPurchaseImportTabDetails" style={{ gridTemplateRows: "repeat(2,4rem)" }}>
              {renderFormElements({
                paramsList: purchaseImportParamsList.clearingAgent,
                params: clearingAgent, section: 'clearingAgent'
              })}
            </div>

            <div className="plantsSubTitle">Insurance :</div>
            <div className="createPurchaseImportTabDetails" style={{ gridTemplateRows: "repeat(2,4rem)" }}>
              {renderFormElements({
                paramsList: purchaseImportParamsList.insurance,
                params: insuranceDetails, section: 'insuranceDetails'
              })}
            </div>

            <div className="plantsSubTitle">Transporter :</div>
            <div className="createPurchaseImportTabDetails" style={{ gridTemplateRows: "repeat(2,4rem)" }}>
              {renderFormElements({
                paramsList: purchaseImportParamsList.transporter,
                params: transporter, section: 'transporter'
              })}
            </div>

            <div className="plantsSubTitle">Liner Bill :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements({
                paramsList: purchaseImportParamsList.liner,
                params: linerBill, section: 'linerBill'
              })}
              <FormElement
                inputType="addButton"
                value="+ Add" colSpan={6}
                title={linerBillItems.length === 3 ? 'Maximum Limit reached' : ""}
                style={linerBillItems.length === 3 ? { backgroundColor: "gray", cursor: "not-allowed" } : {}}
                setInput={() => linerBillItems.length < 3 ? editLinersItem("add", purchaseImportParamsList.liner) : null} />
            </div>
            <RenderLinersTable />

            <div className="plantsSubTitle">Remarks :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements({
                paramsList: purchaseImportParamsList.remarks,
                params: remarksPartyParams, section: 'remarks'
              })}
            </div>

            <div className="plantsSubTitle">Container Detail :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements({
                paramsList: purchaseImportParamsList.containerDetail,
                params: containerDetail, section: 'containerDetail'
              })}
              <FormElement
                inputType="addButton"
                value="+ Add" colSpan={6}
                setInput={() => checkContainerError()} />
            </div>
            <RenderContainerTable />

            <div className="plantsSubTitle">Other Charges :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements({
                paramsList: purchaseImportParamsList.otherCharges,
                params: otherCharges, section: 'otherCharges'
              })}
            </div>

            <div className="plantsSubTitle">Item Detail :</div>
            <div className="createPurchaseImportTabDetails" style={{ gridTemplateRows: "repeat(3,4rem)" }}>
              {renderFormElements({
                paramsList: purchaseImportParamsList.itemDetail,
                params: itemDetail, section: 'itemDetail'
              })}
            </div>

            <span className="plantsSubTitle"
              style={showTaxPercent ? { color: "lightgray" } : null}>
              Tax Amount
            </span>
            <label className="importTaxSwitch">
              <input type="checkbox"
                className="importTaxCheckbox"
                onChange={(e) => {
                  setShowTaxPercent(!showTaxPercent);
                }} />
              <span className="toggle-thumb"></span>
            </label>

            <span className="plantsSubTitle"
              style={showTaxPercent ? null : { color: "lightgray" }}>
              Tax Percentage :
            </span>

            <div className="createPurchaseImportTabDetails">
              {showTaxPercent
                ? renderFormElements({
                  paramsList: purchaseImportParamsList.itemDetailTaxPercentage,
                  params: taxPercentage, section: 'taxPercentage'
                })
                : renderFormElements({
                  paramsList: purchaseImportParamsList.itemDetailTaxAmount,
                  params: taxAmount, section: 'taxAmount'
                })}
              <FormElement inputType="addButton"
                value="+ Add" colSpan={4}
                setInput={() => { checkItemError() }} />
            </div>

            <RenderItemTable />
            <PurchaseImportSummary />
          </div>
        </div>
      </div>

      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

          <button className='cancelButton'
            onClick={() => {
              var reset = window.confirm(`Do You want To Reset all Fields`);
              if (reset) {
                resetAllValues();
              }
            }}
          >
            Reset Fields
          </button>
          <button className="submitButton"
            onClick={() => {
              setImportErrors([]);
              setInvoiceErrors([]);
              setContainerErrorList([]);
              setItemErrorList([]);
              let isInvoiceSaved = saveCurrentInvoice(selectedTab, 'submit');
              if (isInvoiceSaved) {
                checkAllInvoiceErrors();
              }
            }}>
            Submit
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditPurchaseImport)