import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { editCustomer, viewCustomer } from "../../redux/Customer/CustomerInfoActions";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import viewIcon from "../../assets/view.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import Pagination from '../CommonFunctions/pagination';
import FormElement from "../SmallComponents/FormElement";
import SortUpGreen from '../../assets/SortUpGreen.svg';
import SortDownGreen from '../../assets/SortDownGreen.svg';
import {handleSort} from '../CommonFunctions/utils';

const CustomerList = ({
    // props
    setSection,

    // state
    isLogged,

    // dispatch
    addTokenToState,
    viewCustomerId,
    editCustomerId,
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);
    const [customerFilter,setCustomerFilter]=useState("");
    async function getInformation() {
        await checkToken();
        await getCustomers();
    }
    const sortBy = (data, col, order) => {
        if (order === false) {
          const sortAlphaNum = (a, b) =>
            a["customer"][col]?.toString().localeCompare(b["customer"][col]||"", "en", { numeric: true });
        return  data.sort(sortAlphaNum);
        } else {
          const sortAlphaNum = (a, b) =>
            b["customer"][col]?.toString().localeCompare(a["customer"][col] || "", "en", { numeric: true });
        return  data.sort(sortAlphaNum);
        }
      };
      const sortByDate = (data, col, order) => {
        if (order === true) {
          const sortAlphaNum = (a, b) => new Date(a["customer"][col]) - new Date(b["customer"][col]);
        return  data.sort(sortAlphaNum);
        } else {
          const sortAlphaNum = (a, b) => new Date(b["customer"][col]) - new Date(a["customer"][col]);
        return  data.sort(sortAlphaNum);
        }
      };
      const handleSort = ({ col, state, data }) => {
        if (col.includes("Date")) {
          return sortByDate(data, col, state);
        } else {
          return sortBy(data, col, state);
        }
      };
    const [customerList, setCustomerList] = useState([]);
    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [currentPage,setCurrentPage]=useState(1);
    const [PageSize,setRowsPerPage]=useState(20);
    const customerListCurrentPage = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return customerList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,customerList,PageSize]);
    
    async function checkToken() {
        console.log("Checking token");
        //  console.log(isLogged.accessToken);

        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getCustomers() {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: "fetch/customers",
            headers: { token: isLogged.accessToken,module:"Customer" },
        });
        if (data.msg === "success") {
            var data2 = [];

            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setCustomerList(data2);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    const editCustomerCollapse = (index) => {
        var customerListCopy = customerList.slice();
        customerListCopy.forEach((vendor, j) => {
            if (j === index) {
                customerListCopy[j].collapse = !vendor.collapse;
            } else {
                customerListCopy[j].collapse = true;
            }
        });
        setCustomerList(customerListCopy);
    };

    useEffect(()=>{
      if(customerFilter!="")
      setCustomerList(customerList.filter((obj)=>obj.customer.companyName.toLowerCase().includes(customerFilter.toLowerCase())))
      else
      getCustomers();
    },[customerFilter])
    const RenderSubTable = ({ data }) => {
        return (
            <td colSpan="8" className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Contact Name</td>
                            <td className="vendorListInnerTableHeading">Mobile No</td>
                            <td className="vendorListInnerTableHeading">Email Id</td>
                            <td className="vendorListInnerTableHeading">Designation</td>
                            <td className="vendorListInnerTableHeading">Department</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.contactDetailId}>
                                    <td className="vendorListInnertableInfo">{row.contactName}</td>
                                    <td className="vendorListInnertableInfo">{row.mobile}</td>
                                    <td className="vendorListInnertableInfo">{row.email}</td>
                                    <td className="vendorListInnertableInfo">{row.designation}</td>
                                    <td className="vendorListInnertableInfo">{row.department}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    const renderCustomerList = () => {
        return customerListCurrentPage.map((customer, i) => {
         //   if(((customerFilter!=="") && customer.customer.companyName.toLowerCase().includes(customerFilter.toLowerCase())  ) || customerFilter==="")    
            return (
                <React.Fragment>
                    <tr
                        className={customer.collapse ? "vendorList" : "vendorListExpanded"}
                        key={customer.customer.customerId}
                        onClick={() => editCustomerCollapse(i)}
                    >
                        <td className="vendorListInfo">{customer.customer.customerId}</td>
                        <td className="vendorListInfo">{customer.customer.companyName}</td>
                        <td className="vendorListInfo">{customer.customer.parentCompanyName}</td>
                        <td className="vendorListAddress">
                            {customer.customer.address}, <br /> {customer.customer.city}
                            {customer.customer.zipCode !== null && customer.customer.zipCode.length > 0 ? ` - ${customer.customer.zipCode},` : ","}
                            <br /> {customer.customer.state}, {customer.customer.country}.
                        </td>
                        <td className="vendorListInfo">{customer.customer.gstNo}</td>
                        <td className="vendorListInfo">{customer.customer.priceType}</td>
                        <td className="vendorListInfo">{customer.customer.creditDays}</td>
                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View customer Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewCustomerId(customer.customer.customerId);
                                    setSection("customerDetail");
                                }}
                            />
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={isLogged.access.Customer === "Edit" ? editIcon : editIconDisabled}
                                title={isLogged.access.Customer === "Edit" ? "Edit customer" : "No Edit Access"}
                                onClick={(e) => {
                                    if (isLogged.access.Customer === "Edit") {
                                        e.stopPropagation();
                                        editCustomerId(customer.customer.customerId);
                                        setSection("editCustomer");
                                    }
                                }}
                                style={isLogged.access.Customer === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={customer.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editCustomerCollapse(i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!customer.collapse ? <RenderSubTable data={customer.contact} /> : null}</tr>
                </React.Fragment>
            );
        });
    };
    const [order, setOrder] = useState({ col: "customerId", state: true, data: "" });

    return (
        <React.Fragment>
        <div style={{ display:"inline-flex",width: "500px",  }} className="purchaseOrderGrid">
             <span style={{flexGrow:0, width:"80px",marginLeft:"25px"}}>
        <FormElement
          setInput={(value) => {
            setRowsPerPage(value);
          }}
          inputType={"number"}
          value={PageSize}
          hintText={"Rows Per Page"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span>
            <FormElement 
            inputType={"text"}
            setInput={(value)=>setCustomerFilter(value)}
            value={customerFilter}
            hintText={"Customer Name"}
            colSpan={0.5}
            rowSpan={1}
            mandatory={false}
            error={false}
            errorMessage=""
            disabled={false}
            />
        </span>
        </div>
            <div className="vendorListArea">
                <table className="vendorListTable">
                    <thead>
                        <tr className="vendorListHeader">
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "customerId",
                        state: !order.state,
                      });
                      handleSort({
                        data: customerListCurrentPage,
                        col: "customerId",
                        state: order.state,
                      });
                    }}>Customer Id
                    <img
                          className="sortArrow"
                          src={
                               order.col === "customerId"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: customerListCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>Company Name
                    <img
                          className="sortArrow"
                          src={
                               order.col === "companyName"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "parentCompany",
                        state: !order.state,
                      });
                      handleSort({
                        data: customerListCurrentPage,
                        col: "parentCompany",
                        state: order.state,
                      });
                    }}>Parent Company</td>
                            <td className="vendorListHeading">Address</td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "gstNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: customerListCurrentPage,
                        col: "gstNo",
                        state: order.state,
                      });
                    }}>GST No
                    <img
                          className="sortArrow"
                          src={
                               order.col === "gstNo"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "priceType",
                        state: !order.state,
                      });
                      handleSort({
                        data: customerListCurrentPage,
                        col: "priceType",
                        state: order.state,
                      });
                    }}>Price Type 
                    <img
                          className="sortArrow"
                          src={
                               order.col === "priceType"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "creditDays",
                        state: !order.state,
                      });
                      handleSort({
                        data: customerListCurrentPage,
                        col: "creditDays",
                        state: order.state,
                      });
                    }}>Credit Days 
                    <img
                          className="sortArrow"
                          src={
                               order.col === "creditDays"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeadingActions">Actions</td>
                        </tr>
                    </thead>
                    <tbody>{customerList.length > 0 ? renderCustomerList() : null}</tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
            {customerList.length > 0 ?<Pagination 
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={customerList.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        viewCustomerId: (customerId) => dispatch(viewCustomer(customerId)),
        editCustomerId: (customerId) => dispatch(editCustomer(customerId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
