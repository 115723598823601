import React, { useEffect, useState } from "react";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import { validateEmail, validateEqualValues, validateMandatory, validateMobileNumber, validatePassword } from "../CommonFunctions/ValidateFields";
import { useHistory } from "react-router-dom";
import FetchNewToken from "../../serverCall/FetchNewToken";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const employeeParametersList = [
    // Primary Details
    ["photo", "employeeName", "employeeId", "roleId", "plantId", "dateOfJoining", "wages", "leaveEligible", "email", "department", "status","orgName"],

    // Personal details
    [
        "mobileNumber",
        "alternateMobileNumber",
        "personalEmail",
        "dateOfBirth",
        "idProofType",
        "idProofNo",
        "idProof",
        "address",
        "country",
        "state",
        "cityId",
        "zipCode",
        "bloodGroup",
    ],

    // Emergency Details
    ["emergencyContactName", "emergencyContactNumber", "emergencyContactRelationship"],

    // Bank Details
    ["accountHolderName", "accountNumber", "confirmAccountNumber", "bankName", "bankBranch", "ifscCode"],

    //Account Password
    ["accountPassword", "confirmAccountPassword"],

    ["additionalNotes"],
];

const CreateEmployee = ({
    // props
    setSection,

    // state
    isLogged,

    // dispatch
    addTokenToState,
}) => {

    const [employeeParameters, setemployeeParameters] = useState({
        // Primary Details
        photo: {
            inputType: "text",
            value: "",
            hintText: "Profile Photo",
            mandatory: false,
            colSpan: 5,
            url: "",
            error: false,
            errorMessage: "Upload a Profile photo",
        },
        employeeName: {
            inputType: "text",
            value: "",
            hintText: "Employee Name",
            mandatory: true,
            colSpan: 9,
            error: false,
            errorMessage: "Enter a valid name",
        },
        employeeId: {
            inputType: "text",
            value: "",
            hintText: "Employee Id",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Employee Id missing",
        },
        dateOfBirth: {
            inputType: "dateFromEditPage",
            value: null,
            hintText: "Date of Birth",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
        },
        roleId: {
            inputType: "options",
            value: "",
            hintText: "Role",
            mandatory: true,
            colSpan: 5,
            options: [],
            error: false,
            errorMessage: "Please choose a role",
        },
        plantId: {
            inputType: "options",
            value: "",
            hintText: "Plant name",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Select Plant Id",
        },
        dateOfJoining: {
            inputType: "dateFromEditPage",
            value: null,
            hintText: "Date of Joining",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
        },
        wages: {
            inputType: "text",
            value: "",
            hintText: "Wages",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Wages Column found empty",
        },
        leaveEligible: {
            inputType: "text",
            value: "",
            hintText: "No of leave eligible / year",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Enter eligible leave information",
        },
        personalEmail: {
            inputType: "text",
            value: "",
            hintText: "Personal Email ID",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "Enter a valid email address",
        },
        department: {
            inputType: "options",
            value: "",
            hintText: "Department",
            mandatory: false,
            colSpan: 4,
            options: [
                { optionId: "Management", optionName: "Management" },
                { optionId: "Marketing", optionName: "Marketing" },
                { optionId: "Sales", optionName: "Sales" },
                { optionId: "Service", optionName: "Service" },
                { optionId: "Operations", optionName: "Operations" },
                { optionId: "Projects", optionName: "Projects" },
                { optionId: "Production", optionName: "Production" },
                { optionId: "Maintenance", optionName: "Maintenance" },
                { optionId: "Stores / Warehouse", optionName: "Stores / Warehouse" },
                { optionId: "Purchase", optionName: "Purchase" },
                { optionId: "Quality", optionName: "Quality" },
                { optionId: "IT", optionName: "IT" },
                { optionId: "HR", optionName: "HR" },
                { optionId: "Office Admin", optionName: "Office Admin" },
                { optionId: "Branch Office", optionName: "Branch Office" },
            ],
            error: false,
            errorMessage: "Pick a department form Employee",
        },
        status: {
            inputType: "options",
            value: "Active",
            hintText: "Status",
            mandatory: true,
            colSpan: 4,
            options: [
                { optionId: "Active", optionName: "Active" },
                { optionId: "Inactive", optionName: "Inactive" }
            ],
            error: false,
            errorMessage: "Set active status for Employee",
        },
        orgName:{
            inputType:"text",
            value: "",
            hintText: "Organization Name",
            mandatory: true,
            colSpan: 4,
            error:false,
            errorMessage: "Organization Name Required"
        },
        idProofType: {
            inputType: "options",
            value: "",
            hintText: "ID Proof Type",
            mandatory: false,
            colSpan: 4,
            options: [
                { optionId: "Aaadhar", optionName: "Aaadhar" },
                { optionId: "Driving License", optionName: "Driving License" },
                { optionId: "Voter Id", optionName: "Voter Id" },
                { optionId: "Passport", optionName: "Passport" },
                { optionId: "PAN Card", optionName: "PAN Card" },
            ],
            error: false,
            errorMessage: "",
        },
        idProof: {
            inputType: "upload",
            value: "",
            hintText: "Id Proof",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Upload Id Proof",
        },
        idProofNo: {
            inputType: "text",
            value: "",
            hintText: "ID Proof Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Enter ID number",
        },

        // Personal Details
        mobileNumber: {
            inputType: "text",
            value: "",
            hintText: "Mobile Number",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Mobile number must be atleast 10 digits",
        },
        alternateMobileNumber: {
            inputType: "text",
            value: "",
            hintText: "Alternate Mobile Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Mobile number must be atleast 10 digits",
        },
        email: {
            inputType: "text",
            value: "",
            hintText: "Company Email ID",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "Enter a valid email address",
        },
        address: {
            inputType: "text",
            value: "",
            hintText: "Address",
            mandatory: true,
            colSpan: 16,
            error: false,
            errorMessage: "Please enter employee address",
        },
        country: {
            inputType: "options",
            value: "",
            hintText: "Country",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Select Country",
        },
        state: {
            inputType: "options",
            value: "",
            hintText: "State",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Select State",
        },
        cityId: {
            inputType: "options",
            value: "",
            hintText: "City",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Select City",
        },
        zipCode: { inputType: "text", value: "", hintText: "Zip Code", mandatory: false, colSpan: 4, error: false, errorMessage: "Enter Zip code" },

        // Emergency Details
        bloodGroup: {
            inputType: "text",
            value: "",
            hintText: "Blood Group",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Provide employee blood group",
        },
        emergencyContactNumber: {
            inputType: "text",
            value: "",
            hintText: "Emergency Contact Number",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Mobile number must be atleast 10 digits",
        },
        emergencyContactName: {
            inputType: "text",
            value: "",
            hintText: "Emergency Contact Name",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Enter emergency contact's name",
        },
        emergencyContactRelationship: {
            inputType: "text",
            value: "",
            hintText: "Relationship",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Provide emergency contact's relation with employee",
        },

        // Banking Details
        accountHolderName: {
            inputType: "text",
            value: "",
            hintText: "Account Holder Name",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "Account holder name not provided",
        },
        accountNumber: {
            inputType: "text",
            value: "",
            hintText: "Account Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Enter bank account number",
        },
        confirmAccountNumber: {
            inputType: "text",
            value: "",
            hintText: "Confirm Account Number",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Account numbers do not match",
        },
        bankName: {
            inputType: "text",
            value: "",
            hintText: "Bank Name",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "No bank name provided",
        },
        bankBranch: {
            inputType: "text",
            value: "",
            hintText: "Branch",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Specify Bank branch",
        },
        ifscCode: { inputType: "text", value: "", hintText: "IFSC Code", mandatory: false, colSpan: 4, error: false, errorMessage: "Input ISFC Code" },

        // Account Password
        accountPassword: {
            inputType: "password",
            value: "",
            hintText: "New Employee Login Password (Min 6 characters)",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Minimum 6 characters for login password",
        },
        confirmAccountPassword: {
            inputType: "text",
            value: "",
            hintText: "Retype Password",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Login passwords don't match",
        },

        additionalNotes: {
            inputType: "text",
            value: "",
            hintText: "Additional Notes about Employee",
            mandatory: false,
            colSpan: 16,
            error: false,
            errorMessage: "",
        },
    });

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getState();
        // eslint-disable-next-line
    }, [employeeParameters.country]);

    useEffect(() => {
        getCity();
        // eslint-disable-next-line
    }, [employeeParameters.state]);

    async function getInformation() {
        await checkToken();
        var empParamCopy = Object.assign(employeeParameters);
        var roleOptions = await getRoles();
        var plantOptions = await getPlants();
        var countryOptions = await getDim({ field: "country" }, "country");

        empParamCopy = update(empParamCopy, {
            roleId: { options: { $set: roleOptions } },
            plantId: { options: { $set: plantOptions } },
            country: { options: { $set: countryOptions } },
        });
        setemployeeParameters(empParamCopy);
    }

    const history = useHistory();

    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getRoles() {
        var data = await fetchData({ requestingPage: "roleList", method: "get", url: "fetch/role", headers: { token: isLogged.accessToken, module: "Employee" } });
        if (data.msg === "success") {
            var optionArray = [];
            data.asset.forEach((dataItem) => {
                var a = { optionName: dataItem.role, optionId: dataItem.role_id };
                optionArray.push(a);
            });

            return optionArray;
        } else {
            console.log(data);
            return [];
        }
    }

    async function getPlants() {
        var data = await fetchData({ requestingPage: "roleList", method: "get", url: "fetch/plant", headers: { token: isLogged.accessToken, module: "Employee" } });
        if (data.msg === "success") {
            var optionArray = [];
            data.asset.forEach((dataItem) => {
                var a = { optionName: dataItem.plant_name, optionId: dataItem.plant_id };
                optionArray.push(a);
            });

            return optionArray;
        } else {
            console.log(data);
            return [];
        }
    }

    async function getState() {
        if (employeeParameters.country.value !== "") {
            var empparam = await getDim({ field: "state", filterBy: "country_id", filterValue: employeeParameters.country.value }, "state");

            var employeeParametersCopy = Object.assign(employeeParameters);
            employeeParametersCopy = update(employeeParametersCopy, { state: { options: { $set: empparam } } });

            setemployeeParameters(employeeParametersCopy);
        }
    }

    async function getCity() {
        if (employeeParameters.state.value !== "") {
            var empparam = await getDim({ field: "city", filterBy: "state_id", filterValue: employeeParameters.state.value }, "cityId");

            var employeeParametersCopy = Object.assign(employeeParameters);
            employeeParametersCopy = update(employeeParametersCopy, { cityId: { options: { $set: empparam } } });

            setemployeeParameters(employeeParametersCopy);
        }
    }

    async function getDim(dataSent, dimension) {
        var data = await fetchData({
            requestingPage: "getDim",
            method: "post",
            url: "dim",
            headers: { token: isLogged.accessToken, module: "Employee" },
            data: dataSent,
        });
        if (data.msg !== "failure") {
            var optionArray = [];

            switch (dimension) {
                case "country":
                    data.forEach((dataItem) => {
                        var a = { optionName: dataItem.country.toUpperCase(), optionId: dataItem.country_id };
                        optionArray.push(a);
                    });
                    break;

                case "state":
                    data.forEach((dataItem) => {
                        var a = { optionName: dataItem.state.toUpperCase(), optionId: dataItem.state_id };
                        optionArray.push(a);
                    });
                    break;

                case "cityId":
                    data.forEach((dataItem) => {
                        var a = { optionName: dataItem.city.toUpperCase(), optionId: dataItem.city_id };
                        optionArray.push(a);
                    });
                    break;

                default:
                    break;
            }

            return optionArray;
        } else {
            console.log(data);
            return [];
        }
    }

    const updateEmployeeParameter = (paramName, key, value) => {
        const employeeparams = update(employeeParameters, { [paramName]: { [key]: { $set: value } } });
        setemployeeParameters(employeeparams);
    };

    const [errorList, setErrorList] = useState([]);
    const checkErrors = () => {
        var errorListArray = [];
        var updatedParams = Object.assign(employeeParameters);

        employeeParametersList.forEach((list, j) => {
            if ((j == 4 && createPassword) || [0, 1, 2, 3, 5].includes(j)) {
                list.forEach((item) => {
                    if (employeeParameters[item].mandatory && item!=="orgName") {
                        if (item === "photo") {
                            if (
                                employeeParameters[item].value !== null &&
                                employeeParameters[item].value.name !== null &&
                                employeeParameters[item].value.name !== undefined
                            ) {
                                updatedParams = update(updatedParams, { [item]: { error: { $set: false } } });
                            } else {
                                updatedParams = update(updatedParams, { [item]: { error: { $set: true } } });
                            }
                        } else
                            updatedParams = update(updatedParams, {
                                [item]: { error: { $set: !validateMandatory(employeeParameters[item].value.toString()) } },
                            });
                    }
                    if(item==="orgName" && isLogged.roleId==1){
                        updatedParams = update(updatedParams, {
                            [item]: { error: { $set: !validateMandatory(employeeParameters[item].value.toString()) } },
                        });
                    }
                    if (["mobileNumber", "alternateMobileNumber"].includes(item)) {
                        if (employeeParameters[item].value !== null && employeeParameters[item].value.length > 0) {
                            updatedParams = update(updatedParams, {
                                [item]: { error: { $set: !validateMobileNumber(employeeParameters[item].value) } },
                            });
                        } else updatedParams = update(updatedParams, { [item]: { error: { $set: false } } });
                    }

                    if (item === "emergencyContactNumber") {
                        updatedParams = update(updatedParams, { [item]: { error: { $set: !validateMobileNumber(employeeParameters[item].value) } } });
                    }

                    if (item === "email") {
                        if (employeeParameters[item].value.length > 0) {
                            updatedParams = update(updatedParams, { [item]: { error: { $set: !validateEmail(employeeParameters[item].value) } } });
                        } else updatedParams = update(updatedParams, { [item]: { error: { $set: false } } });
                    }

                    if (item === "confirmAccountNumber") {
                        updatedParams = update(updatedParams, {
                            [item]: { error: { $set: !validateEqualValues(employeeParameters[item].value, employeeParameters.accountNumber.value) } },
                        });
                    }
                    if (item === "accountPassword") {
                        updatedParams = update(updatedParams, { [item]: { error: { $set: !validatePassword(employeeParameters[item].value) } } });
                    }

                    if (item === "confirmAccountPassword") {
                        updatedParams = update(updatedParams, {
                            [item]: {
                                error: { $set: !validateEqualValues(employeeParameters[item].value, employeeParameters.accountPassword.value) },
                            },
                        });
                    }

                    if (updatedParams[item].error) {
                        errorListArray.push(updatedParams[item].errorMessage);
                    }
                });
            }
        });
        setemployeeParameters(updatedParams);

        setErrorList(errorListArray);

        if (errorListArray.length === 0) {
            var data2 = new FormData();
            employeeParametersList.forEach((list, i) => {
                if ((i == 4 && createPassword) || [0, 1, 1, 2, 3, 5].includes(i)) {
                    list.forEach((item) => {
                        switch (item) {
                            case "photo":
                            case "idProof":
                                if (updatedParams[item].value !== null) data2.append(item, updatedParams[item].value);
                                break;

                            default:
                                if (updatedParams[item].value !== null && updatedParams[item].value.toString().length > 0)
                                    data2.append(item, updatedParams[item].value);
                                break;
                        }
                    });
                }
            });

            submitData(data2);
        }
    };

    async function submitData(data2) {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "createEmployee",
            method: "post",
            url: "create/employee",
            headers: { token: isLogged.accessToken, module: "Employee" },
            data: data2,
        });

        if (result.msg === "success") {
            setModalText("Employee Created successfully!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setErrorList([result.desc]);
        }
        setDataSubmitted(true);
    }

    const submitOkClick = () => {
        setIsOpen(false);
        if (errorList.length === 0) {
            setSection("employeeList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const renderFormElements = (elementList) => {
        return elementList.map((param) => {
            if((param==="orgName" && isLogged.roleId==1) || param!=="orgName")
            switch (param) {
                case "photo":
                    return (
                        <div className="box profilePhoto" key={"photoElement"}>
                            {/* eslint-disable-next-line */}
                            <img style={{opacity:"1"}} src={employeeParameters[param].url} className="photoArea" alt="Profile Picture" />
                            <label
                                className={employeeParameters[param].error ? "uploadEditProfilePhotoError" : "uploadEditProfilePhoto"}
                                htmlFor="uploadProfilePhoto"
                            >
                                {employeeParameters[param].value !== null &&
                                    employeeParameters[param].value.name !== null &&
                                    employeeParameters[param].value.name !== undefined &&
                                    employeeParameters[param].value.name.length > 0
                                    ? "EDIT"
                                    : "UPLOAD"}
                            </label>
                            <input
                                className="uploadEditInput"
                                id="uploadProfilePhoto"
                                type="file"
                                accept="images/*"
                                onChange={(event) => {
                                    var empParamCopy = Object.assign(employeeParameters);
                                    empParamCopy = update(empParamCopy, {
                                        [param]: {
                                            value: { $set: event.target.files[0] },
                                            url: { $set: URL.createObjectURL(event.target.files[0]) },
                                        },
                                    });
                                    setemployeeParameters(empParamCopy);
                                }}
                            />
                        </div>
                    );

                case "idProof":
                    return (
                        <FormElement
                            inputType={
                                employeeParameters[param].value !== null &&
                                    employeeParameters[param].value.name !== null &&
                                    employeeParameters[param].value.name !== undefined
                                    ? "uploadReady"
                                    : "upload"
                            }
                            value={
                                employeeParameters[param].value !== null && employeeParameters[param].value !== ""
                                    ? employeeParameters[param].value.name
                                    : ""
                            }
                            setInput={(value) => {
                                if (value !== "deleteId") {
                                    updateEmployeeParameter(param, "value", value);
                                } else {
                                    updateEmployeeParameter(param, "value", "");
                                }
                            }}
                            hintText="ID Proof"
                            mandatory={employeeParameters[param].mandatory}
                            colSpan={employeeParameters[param].colSpan}
                            error={employeeParameters[param].error}
                            key={param}
                        />
                    );

                default:
                    return (
                        <FormElement
                            inputType={employeeParameters[param].inputType}
                            value={employeeParameters[param].value}
                            setInput={(value) => updateEmployeeParameter(param, "value", value)}
                            hintText={employeeParameters[param].hintText}
                            mandatory={employeeParameters[param].mandatory}
                            colSpan={employeeParameters[param].colSpan}
                            options={employeeParameters[param].inputType === "options" ? employeeParameters[param].options : null}
                            error={employeeParameters[param].error}
                            key={param}
                        />
                    );
            }
        });
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    const [createPassword, setCreatePassword] = useState(false);

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="formArea">
                <div
                    style={{
                        width: "900px",
                        margin: "0 auto 4rem",
                        padding: "3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div className="formGrid">{renderFormElements(employeeParametersList[0])}</div>

                    <div className="subTitle">Personal Details</div>
                    <div className="personalDetails">{renderFormElements(employeeParametersList[1])}</div>

                    <div className="subTitle">Emergency Details</div>
                    <div className="emergencyDetails">{renderFormElements(employeeParametersList[2])}</div>

                    <div className="subTitle">Bank Details</div>
                    <div className="bankDetails">{renderFormElements(employeeParametersList[3])}</div>

                    <div className="subTitle">
                        <input
                            type="checkbox"
                            checked={createPassword}
                            onChange={(e) => {
                                setCreatePassword(e.target.checked);
                            }}
                        />
                        <span>CREATE ACCOUNT PASSWORD</span>
                    </div>
                    {createPassword ? <div className="accountPassword">{renderFormElements(employeeParametersList[4])}</div> : null}

                    <div className="subTitle">Notes</div>
                    <div className="additionalNotes">{renderFormElements(employeeParametersList[5])}</div>
                </div>
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{errorList[0]}</p>

                    <button
                        className="cancelButton"
                        onClick={() => {
                            var reset = window.confirm("Do you want to reset all fields?");
                            if (reset) {
                                var empListCopy = JSON.parse(JSON.stringify(employeeParameters));
                                employeeParametersList.forEach((list) => {
                                    list.forEach((item) => {
                                        if (["dateOfBirth", "dateOfJoining"].includes(item)) {
                                            empListCopy[item].value = null;
                                        } else empListCopy[item].value = "";
                                        empListCopy[item].error = false;
                                    });
                                });

                                setemployeeParameters(empListCopy);
                                setErrorList([]);
                            }
                        }}
                    >
                        Reset Fields
                    </button>
                    <button
                        className="submitButton"
                        onClick={() => {
                            checkErrors();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmployee);
