import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import CreateCustomer from "./CreateCustomer";
import "../styles/Customer.css";
import CustomerList from "./CustomerList";
import CustomerDetail from "./CustomerDetail";
import customer from "../../assets/customer.svg";
import EditCustomer from "./EditCustomer";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from "react-redux";
import { exportData } from "../CommonFunctions/ExportExcel";

const Customer = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("customerList");

    var createCustomerButton = { buttonName: "Create Customer", className: "employeeButton", setSection: setCurrentSection, sectionName: "createCustomer" };

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "customerList" }];

    var IReportButton = { buttonName: "Export", className: "exportButton", setSection: async () => { await exportData(isLogged, "customerDetails") }, sectionName: "createCustomer" };
    const renderSection = (section) => {
        switch (section) {
            case "customerList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={customer}
                            pageTitle="Customer List"
                            buttonList={[isLogged.access.Customer === "Create" || isLogged.access.Customer === "Edit" ? createCustomerButton : null, IReportButton]}
                        />
                        <CustomerList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createCustomer":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={customer} pageTitle="Create Customer" buttonList={goBackButton} />
                        <CreateCustomer setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "customerDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={customer} pageTitle="Customer Detail" buttonList={goBackButton} />
                        <CustomerDetail setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editCustomer":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={customer} pageTitle="Edit Customer" buttonList={goBackButton} />
                        <EditCustomer setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <div>Page yet to be built</div>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Customer" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(Customer);
