import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import resetIcon from "../../assets/return.svg";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import { pendingSoProduction } from "../../redux/PurchaseOrder/PoInfoActions";
import InputField from "../SmallComponents/InputField";
import { getUom } from "serverCall/fetchData";

const CreateProduction = ({
  // props
  setSection,
  orderSelected,

  // state
  isLogged,
  poInfo,

  // dispatch
  addTokenToState,
}) => {

  const [productionParamsList,setProductionParamsList] = useState(["requestType", "soId", "expectDate"]);
  const lineItemsParamsList = isLogged.showHideWeight
    ? [
        "itemCategory",
        "itemId",
        "from",
        "to",
        "netWeight",
        "grossWeight",
        "uomId",
        "requestQuantity",
        "dcQuantity",
      ]
    : ["itemCategory","itemId", "uomId","requestQuantity","dcQuantity"];

  const [productionParams, setProductionParams] = useState({
    requestType: {
      inputType: "options",
      value: "",
      hintText: "Request Type",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [
        { optionId: "Make To Order", optionName: "Make To Order" },
        { optionId: "Make To Stock", optionName: "Make To Stock" },
      ],
      errorMessage: "Please select request type",
    },
    soId: {
      inputType: "options",
      value: "",
      hintText: "Order No",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please select SO No",
    },
    plantId: {
      inputType: "options",
      value: "",
      hintText: "Plant",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: isLogged.plantOptions,
      errorMessage: "Please pick a Plant",
    },
    storageId: {
      inputType: "options",
      value: "",
      hintText: "Store",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please select Store",
    },
    expectDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Expected request complete date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please select expected date for completing request",
      mindate: true,
    },
  });

  const [productionParamsMTS, setProductionParamsMTS] = useState({
    itemCategory: {
      inputType: "options",
      value: "",
      hintText: "Item Category",
      mandatory: false,
      colSpan: 12,
      options: [],
      error: false,
      errorMessage: "Select an Item",
      // callServer: true,
  },
    itemId: {
      inputType: "options",
      value: "",
      hintText: "Item ",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please select Item",
    },
    from: {
      inputType: "text",
      value: "",
      hintText: "Weight From(gms)",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: true,
    },
    to: {
      inputType: "text",
      value: "",
      hintText: "Weight To(gms)",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: true,
    },
    netWeight: {
      inputType: "text",
      value: "",
      hintText: "Net Weight(gms)",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: true,
    },
    grossWeight: {
      inputType: "text",
      value: "",
      hintText: "Gross Weight(gms)",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: true,
    },
    uomId: {
      inputType: "options",
      value: "",
      hintText: "UOM",
      mandatory: true,
      colSpan: 4,
      error: false,
      options: [],
      errorMessage: "Please select UOM",
      serverCall: true,
    },
    requestQuantity: {
      inputType: "float",
      value: "",
      hintText: "Request Quantity",
      mandatory: true,
      colSpan: 4,
      error: false,
      errorMessage: "Please enter a valid Request Quantity",
    },
    dcQuantity: {
      inputType: "float",
      value: "",
      hintText: "Dc Quantity",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Please enter a valid Request Quantity",
    },
  });

  async function getPlants() {
    var data = await fetchData({
        requestingPage: "plantList",
        method: "get",
        url: "fetch/plants",
        headers: { token: isLogged.accessToken,module:"Plant & Store" },
    });
    if (data.msg === "success") {
       return data.asset;
    } else {
        return []
    }
  }
  const [soDetail, setSoDetail] = useState({});

  const [lineItems, setLineItems] = useState([]);
  const [mtsLineItemsList, setMtsLineItemsList] = useState([]);

  const [instruction, setInstruction] = useState("");

  const [allItemUomList, setAllItemUomList] = useState({});
  const [itemList,setItemList] = useState([])

  useEffect(async () => {
    if (poInfo.pendingSoProduction !== null) {
      var paramsCopy = Object.assign(productionParams);
      paramsCopy = update(paramsCopy, {
        requestType: {
          value: { $set: "Make To Order" },
          disabled: { $set: true },
        },
        soId: {
          value: { $set: poInfo.pendingSoProduction },
          disabled: { $set: true },
        },
      });
    
      setProductionParams(paramsCopy);
    }
  }, []);

  const history = useHistory();
  async function checkToken() {
    console.log("Checking token");
    // console.log(isLogged.accessToken);
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  useEffect(async () => {
    var paramsCopy = Object.assign(productionParams);
    if (productionParams.requestType.value === "Make To Order") {

      await checkToken();
      var soOptions = await getSoApproved();
      paramsCopy = update(paramsCopy, {
        soId: { options: { $set: soOptions } },
      });
      setProductionParamsList(["requestType", "soId", "expectDate"])
    }else if(productionParams.requestType.value == 'Make To Stock'){
        
        setProductionParamsList(["requestType", 'plantId','storageId', "expectDate"]);
      await getPlants().then((resp)=> 
      paramsCopy = update(paramsCopy, {plantId: {options: {$set: resp || []} }})
      );
    }else{
      setProductionParamsList(["requestType", "soId", "expectDate"])
      paramsCopy = update(paramsCopy, {plantId: {options: {$set:  []} }})
    }
    setProductionParams(paramsCopy);
  }, [productionParams.requestType.value]);

  async function getSoApproved() {
    var data = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "so-fetch/prod-pre-create-so-approved-list-mini",
      headers: { token: isLogged.accessToken, module: "Production Order" },
    });
    if (data.msg === "success") {
      return data.asset;
    }
  }

  useEffect(async () => {
    if (productionParams.soId.value !== "") {
      await checkToken();
      var soDetailCopy = await getSoPendingDetail(productionParams.soId.value);
      setSoDetail(soDetailCopy);
      setLineItems(soDetailCopy.lineItems);
    } else {
      setSoDetail({});
      setLineItems([]);
    }
  }, [productionParams.soId]);

  async function getSoPendingDetail(id) {
    var data = await fetchData({
      requestingPage: "vendorDetail",
      method: "get",
      url: "so-fetch/so-detail/" + id,
      headers: { token: isLogged.accessToken, module: "Production Order" },
    });
    if (data.msg === "success") {
      var newLineItems = [];
      data.asset[0].lineItems.forEach((item) => {
        item["requestQuantity"] = 0;
        item["dcQuantity"] = 0;
        if (parseInt(item.quantity) - parseInt(item.outwardQuantity) <= 0) {
          console.log("Need not add line item");
        } else {
          item.requestQuantity = "";
          item.requestQuantityError = false;
          item.requestQuantityErrorMessage = "Enter a valid request quantity";
          newLineItems.push(item);
        }
      });
      if (newLineItems.length > 0) {
        data.asset[0].lineItems = newLineItems;
      }
      return data.asset[0];
    } else {
      console.log(data);
    }
  }

  const getInformation =async()=>{
    if (productionParams.requestType.value === "Make To Stock") {
      var [itemListCopy, itemUomList] = await getUom(isLogged);
      var [categoryList,subCategoryList] = await getCategory();

      var paramsCopy = Object.assign(productionParamsMTS);
      paramsCopy = update(paramsCopy, {
        itemId: { options: { $set: itemListCopy } },
        itemCategory :{options :{$set :categoryList }},
        uomId: { value: { $set: "" } },
      });
      setSoDetail({});
      setLineItems([]);
      setProductionParamsMTS(paramsCopy);
      setAllItemUomList(itemUomList);
     
    setItemList(itemListCopy)
    }
  }

  async function getCategory() {
    var categoryData = await fetchData({
        requestingPage: "getCategory",
        method: "get",
        url: "fetch/categories",
        headers: { token: isLogged.accessToken, module: "Dropdowns" },
    });

    var categoryList = [];
    var subCategoryList = {};

    if (categoryData.msg === "success") {
        categoryData.asset.forEach((categ) => {
            var catObj = {};
            catObj.optionId = categ.category.categoryId;
            catObj.optionName = categ.category.categoryName;
            categoryList.push(catObj);

            subCategoryList[categ.category.categoryId] = [];

            categ.subCategory.forEach((subCateg) => {
                var subCatObj = {};
                subCatObj.optionId = subCateg.subCategoryId;
                subCatObj.optionName = subCateg.subCategoryName;

                subCategoryList[categ.category.categoryId].push(subCatObj);
            });
        });
        return [categoryList, subCategoryList];
    }
}

  useEffect(() => {
   
    getInformation()
  }, [productionParams.requestType]);


  useEffect(() => {
    var paramsCopy = Object.assign(productionParamsMTS);
    if (productionParamsMTS.itemId.value !== "") {
      paramsCopy = update(paramsCopy, {
        uomId: {
          options: { $set: allItemUomList[productionParamsMTS.itemId.value] },
        },
        from: {
          value: {
            $set: allItemUomList[productionParamsMTS.itemId.value][0]["from"],
          },
        },
        to: {
          value: {
            $set: allItemUomList[productionParamsMTS.itemId.value][0]["to"],
          },
        },
        netWeight: {
          value: {
            $set: allItemUomList[productionParamsMTS.itemId.value][0][
              "netWeight"
            ],
          },
        },
        grossWeight: {
          value: {
            $set: allItemUomList[productionParamsMTS.itemId.value][0][
              "grossWeight"
            ],
          },
        },
      });
    } else {
      paramsCopy = update(paramsCopy, { uomId: { options: { $set: [] } } });
    }
    setProductionParamsMTS(paramsCopy);
  }, [productionParamsMTS.itemId.value]);

  const updateProductionParameter = ({ paramName, section, key, value,wholeObject }) => {

    if (section === "general") {
      var paramsCopy = Object.assign(productionParams);
      paramsCopy = update(paramsCopy, {
        [paramName]: { [key]: { $set: value } },
      });

      if (
        paramName === "requestType" &&
        (value === "Make To Stock" || value === "")
      ) {
        paramsCopy = update(paramsCopy, { soId: { value: { $set: "" } } });
      }

      if(paramName === 'plantId'){
        if(value)
        paramsCopy = update(paramsCopy, {storageId: {options : {$set: wholeObject.storage}} })
        else
        paramsCopy = update(paramsCopy, {storageId: {options: {$set: []} }});
      }
      setProductionParams(paramsCopy);
    }

    if (section === "lineItem") {
      var paramsCopy = Object.assign(productionParamsMTS);
      paramsCopy = update(paramsCopy, {
        [paramName]: { [key]: { $set: value } },
      });
      if(paramName === "itemCategory"){
        if(value){
          const filteredItems = itemList.filter(item=>{return item.categoryId === value})
          paramsCopy = update(paramsCopy, {
                itemId: { options: {$set:filteredItems}},
            });
        }else{

          paramsCopy = update(paramsCopy, {
                itemId: { options: {$set:itemList},
                         value:{$set:""}},
            });
        }
    }
      setProductionParamsMTS(paramsCopy);
    }
  };

  const [productionError, setProductionError] = useState([]);

  const checkLineItemError = () => {
    var errorList = [];

    var paramsCopy = Object.assign(productionParamsMTS);
    lineItemsParamsList.map((item) => {
      if (paramsCopy[item].mandatory)
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });

      if (
        item === "quantity" ||
        item === "from" ||
        item === "to" ||
        item === "netWeight" ||
        item === "grossWeight"
      ) {
  
        if (paramsCopy[item].value !== "") {
          if (
            isNaN(paramsCopy[item].value) ||
            parseFloat(paramsCopy[item].value) < 0
          ) {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: true } },
            });
          } else {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: false } },
            });
          }
        }
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    if (errorList.length === 0) {
      var lineItemsCopy = mtsLineItemsList.slice();

      var newItem = {
        itemId: paramsCopy.itemId.value,
        item: paramsCopy.itemId.options.filter(
          (item) => item.optionId === paramsCopy.itemId.value
        )[0].optionName,
        itemOption: paramsCopy.itemId.options.filter(
          (item) => item.optionId === paramsCopy.itemId.value
        )[0],
        from: paramsCopy.from.value,
        to: paramsCopy.to.value,
        netWeight: paramsCopy.netWeight.value,
        grossWeight: paramsCopy.grossWeight.value,
        uomId: paramsCopy.uomId.value,
        uom: paramsCopy.uomId.options.filter(
          (item) => item.optionId === paramsCopy.uomId.value
        )[0].optionName,
        requestQuantity: paramsCopy.requestQuantity.value,
        dcQuantity: paramsCopy.dcQuantity.value
      };
      lineItemsCopy.push(newItem);

      var trimmedItemList = paramsCopy.itemId.options.filter(
        (item) => item.optionId !== paramsCopy.itemId.value
      );

      paramsCopy = update(paramsCopy, {
        itemId: { value: { $set: "" }, options: { $set: trimmedItemList } },
        from: { value: { $set: "" } },
        to: { value: { $set: "" } },
        netWeight: { value: { $set: "" } },
        grossWeight: { value: { $set: "" } },
        uomId: { value: { $set: "" } },
        requestQuantity: { value: { $set: "" } },
        dcQuantity: {value: {$set: ""} }
      });

      setMtsLineItemsList(lineItemsCopy);
    }

    setProductionError(errorList);
    setProductionParamsMTS(paramsCopy);
  };

  const renderFormElements = ({ elementList, param, section }) => {
    return elementList.map((element) => {
        return (
          <FormElement
            key={element}
            inputType={param[element].inputType}
            value={param[element].value !== null ? param[element].value : ""}
            setInput={(value,wholeObject) => updateProductionParameter({section,paramName: element,
                                          key: "value",value,wholeObject})
                    }
            hintText={param[element].hintText}
            mandatory={param[element].mandatory}
            colSpan={param[element].colSpan}
            options={
              param[element].inputType === "options"
                ? param[element].options
                : null
            }
            error={param[element].error}
            rowSpan={
              element === "vendorLogo" || element === "otherBusinessDetails"
                ? param[element].rowSpan
                : null
            }
            placeholder={param[element].placeholder}
            title={param[element].title}
            disabled={param[element].disabled}
            mindate={param[element].mindate}
          />
        );
    });
  };

  const editSubSectionItem = (index, action) => {
    var lineItemsListCopy = mtsLineItemsList.slice();
    var paramsCopy = Object.assign(productionParamsMTS);

    if (action === "edit") {
      var lineItemAllClear = true;

      lineItemsParamsList.forEach((item) => {
        if (productionParamsMTS[item].value !== "") {
          lineItemAllClear = false;
        }
      });
      if (!lineItemAllClear) {
        var yes = window.confirm(
          "Unsaved data found. Are you sure you want to overwrite it?"
        );
      }
      if (lineItemAllClear || yes) {
        var editObj = lineItemsListCopy.splice(index, 1)[0];

        var allItemListCopy = productionParamsMTS.itemId.options.slice();
        allItemListCopy.push(editObj.itemOption);

        paramsCopy = update(paramsCopy, {
          itemId: {
            value: { $set: editObj.itemId },
            options: { $set: allItemListCopy },
          },
          from: { value: { $set: editObj.from } },
          to: { value: { $set: editObj.to } },
          netWeight: { value: { $set: editObj.netWeight } },
          grossWeight: { value: { $set: editObj.grossWeight } },
          uomId: {
            value: { $set: editObj.uomId },
            options: { $set: allItemUomList[editObj.itemId] },
            serverCall: { $set: false },
          },
          requestQuantity: { value: { $set: editObj.requestQuantity } },
          dcQuantity: {value: {$set: editObj.dcQuantity }}
        });
      }
    }

    if (action === "delete") {
      var editObj = lineItemsListCopy.splice(index, 1)[0];


      var allItemListCopy = productionParamsMTS.itemId.options.slice();
      allItemListCopy.push(editObj.itemOption);

      paramsCopy = update(paramsCopy, {
        itemId: { options: { $set: allItemListCopy } },
      });
    }

    setProductionParamsMTS(paramsCopy);
    setMtsLineItemsList(lineItemsListCopy);
  };
  const mtsLineItemsCols = isLogged.showHideWeight
    ? [
        "item",
        // "itemCode",
        "from",
        "to",
        "netWeight",
        "grossWeight",
        "uom",
        "requestQuantity",
        "dcQuantity"
      ]
    : ["item"//,"itemCode"
    , "uom", "requestQuantity","dcQuantity"];
  const RenderTableRows = ({ rows, tab }) => {

    return rows.map((row, j) => {
      return (
        <tr className="createVendorContactsTableRows" key={j}>
          {mtsLineItemsCols.map((key, i) => {
            return <td key={i}>{row[key] ? row[key] : " - "}</td>;
          })}
          <td className="itemIndiTabActions">
            <img
              alt="Edit Record"
              className="createVendorContactsAction"
              src={editIcon}
              onClick={() => {
                editSubSectionItem(j, "edit");
              }}
            />
            <img
              alt="Delete Record"
              className="createVendorContactsAction"
              src={deleteIcon}
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) {
                  editSubSectionItem(j, "delete");
                }
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const RenderTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr className="createVendorContactsTableHeader">
            <td>Item</td>
            {isLogged.showHideWeight ? (
              <>
                <td>Weight From(gms)</td>
                <td>Weight To(gms)</td>
                <td>Net Weight(gms)</td>
                <td>Gross Weight(gms)</td>
              </>
            ) : null}
            <td>UOM</td>
            <td>Request Quantity</td>
            <td>DC Quantity</td>
            <td className="itemIndiTabActions">Actions</td>
          </tr>
        </thead>
        {mtsLineItemsList.length > 0 ? (
          <tbody>
            <RenderTableRows rows={mtsLineItemsList} tab="contactActions" />
          </tbody>
        ) : (
          <tbody>
            <tr className="createVendorContactsTableRows">
              {mtsLineItemsCols.map((item, i) => {
                return <td key={i}>&nbsp;</td>;
              })}
              <td>&nbsp;</td>
            </tr>
          </tbody>
        )}
      </table>
    );
  };

  const renderSubSection = () => {
    return (
      <React.Fragment>
        <div className="purchaseOrderIndividualItemsArea">
          {renderFormElements({
            elementList: lineItemsParamsList,
            param: productionParamsMTS,
            section: "lineItem",
          })}
          <FormElement
            inputType="addButton"
            value="+ Add"
            colSpan={4}
            setInput={() => {
              checkLineItemError();
            }}
          />
          <div
            style={{
              marginTop: "26px",
              gridColumn: "span 1",
              color: "#666",
              background: "rgb(230,230,230)",
              cursor: "pointer",
            }}>
            <img
              style={{ padding: "10px", width: "1rem", height: "1rem" }}
              src={resetIcon}
              alt="Reset"
              onClick={() => {
                var yes = window.confirm("Clear Input data?");

                if (yes) {
                  var paramsCopy = Object.assign(productionParams);

                  lineItemsParamsList.forEach((item) => {
                    paramsCopy[item].value = "";
                    paramsCopy[item].error = false;
                  });

                  setProductionParams(paramsCopy);
                }
              }}
            />
          </div>
        </div>
        <RenderTable />
      </React.Fragment>
    );
  };

  const updateLineItems = (param, index, value) => {
    var updatedList = lineItems.slice();
    const invalidChars = ['E','e','+','-']
    if(value>=0 && parseFloat(value) >=0 && !invalidChars.includes(value))
    updatedList = update(updatedList, {
      [index]: { [param]: { $set: value } },
    });
    setLineItems(updatedList);
  };

  const renderErrorMessage = () => {
    if (productionError.length > 0) return productionError[0];
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState(
    "Creating Production request. Please wait..."
  );

  const submitOkClick = () => {
    setIsOpen(false);
    if (productionError.length === 0) {
      setSection("productionOrderList");
    }
  };

  const checkErrorsMTO = () => {
    var errorList = [];
    var paramsCopy = Object.assign(productionParams);
    productionParamsList.forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    var lineItemsCopy = lineItems.slice();

    let lineItemAllClear = true;
    lineItemsCopy.forEach((item) => {
      if (item.requestQuantity !== "") {
        if (
          isNaN(item.requestQuantity) ||
          parseFloat(item.requestQuantity) < 0 || 
          parseFloat(item.requestQuantity) + parseFloat(item.dcQuantity) > item.quantity
        ) {
          item.requestQuantityError = true;
          lineItemAllClear = false;
          errorList.push("Please Enter Valid Request Quantity");
        } else {
          item.requestQuantityError = false;
          item.toSend = true;
        }
      }
    });

    let checkCondition = lineItemsCopy.every((item)=>item["requestQuantity"] == 0);

    if(checkCondition)
    errorList.push("Provide Request Quantity for atleast One Item");

    if (errorList.length === 0) {
      var data2Server = {
        requestType: "Make To Order",
        soId: productionParams.soId.value,
        expectDate: productionParams.expectDate.value,
        instruction: instruction,
      };

      var itemsToServer = [];
      lineItemsCopy.forEach((item) => {
        if (item.toSend) {
          var newItem = {
            soLineItemId: item.soLineItemId,
            itemId: item.itemId,
            uomId: item.uomId,
            quantity: parseFloat(item.requestQuantity),
            dcQuantity: parseFloat(item.dcQuantity)
          };
          itemsToServer.push(newItem);
        }
      });

      if (itemsToServer.length > 0) {
        data2Server.lineItems = itemsToServer;
        submitData(data2Server);
      } else {
        errorList.push("Provide request quantity for atleast one item");
      }
    }

    setLineItems(lineItemsCopy);
    setProductionError(errorList);
    setProductionParams(paramsCopy);
  };

  const submitData = async (data) => {
    setIsOpen(true);
    setModalText("Creating Production request. Please wait...!");

    var result = await fetchData({
      requestingPage: "createPoItem",
      url: "production-create/request",
      method: "post",
      headers: { token: isLogged.accessToken, module: "Production Order" },
      data: data,
    });
    if (result.msg === "success") {
      setDataSubmitted(true);
      setModalText("Production Request created successfully!");
    } else {
      console.log(result);
    }
  };

  const checkErrorsMTS = () => {
    var errorList = [];
    var paramsCopy = Object.assign(productionParams);
    ["requestType", "expectDate"].forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    var lineItemsCopy = mtsLineItemsList.slice();

    if (lineItemsCopy.length > 0) {
      var itemsToServer = [];

      lineItemsCopy.forEach((item) => {
        var newItem = {
          itemId: item.itemId,
          uomId: item.uomId,
          quantity: parseFloat(item.requestQuantity),
          dcQuantity: parseFloat(item.dcQuantity)
        };

        itemsToServer.push(newItem);
      });

    } else {
      errorList.push("Provide request quantity for atleast one item");
    }

    if (errorList.length === 0) {
      var data2Server = {
        requestType: "Make To Stock",
        expectDate: productionParams.expectDate.value,
        instruction: instruction,
        lineItems: itemsToServer,
        storageId: productionParams.storageId.value
      };

      submitData(data2Server);
    }

    setProductionError(errorList);
    setProductionParams(paramsCopy);
  };
  const lineItemsTableCols = isLogged.showHideWeight
    ? [
      "sectionNo",
      "sectionName",
        "from",
        "to",
        "netWeight",
        "grossWeight",
        "uom",
        "quantity",
        "outwardQuantity",
        "dcQuantity",
        "requestQuantity",
      ]
    : ["sectionName","sectionNo", "uom", "quantity", "outwardQuantity","dcQuantity", "requestQuantity"];

    return (
    <React.Fragment>
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
      <div className="formArea">
        <div
          style={{
            width: "1000px",
            margin: "0 auto 2rem",
            // padding: "3rem 3rem 1rem 3rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex" }}>
            <div
              className="createPurchaseOrderGrid"
              style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
              {renderFormElements({
                elementList: productionParamsList,
                param: productionParams,
                section: "general",
              })}
            </div>
          </div>

          {productionParams.requestType.value === "Make To Stock" ? (
            <div className="purchaseOrderSubSectionArea">
              {renderSubSection()}
            </div>
          ) : lineItems.length > 0 ? (
            <table
              className="createItemPurchaseTable"
              style={{ margin: "2rem auto" }}>
              <thead>
                <tr className="createVendorContactsTableHeader">
                  <td className="stickyFirstColumn">Section No </td>
                  <td>section Name</td>
                  {isLogged.showHideWeight ? (
                    <>
                      <td>Weight From(gms)</td>
                      <td>Weight To(gms)</td>
                      <td>Net Weight(gms)</td>
                      <td>Gross Weight(gms)</td>
                    </>
                  ) : null}
                  <td>UOM</td>
                  <td>SO Qty</td>
                  <td>Outward Qty</td>
                  <td>Dc Quantity</td>
                  <td>Request Qty</td>
                </tr>
              </thead>
              <tbody>
                {lineItems.map((row, j) => (
                  <tr className="createVendorContactsTableRows" key={j}>
                    {lineItemsTableCols.map((key, i) => {
                      switch (key) {
                        case "requestQuantity":
                          return (
                            <td key={i} style={{ maxWidth: "15px" }}>
                              <input
                                className={
                                  lineItems[j].requestQuantityError
                                    ? "createPurchaseCostInputError"
                                    : "createPurchaseCostInput"
                                }
                                type="float"
                                value={row[key]}
                                onChange={(e) =>
                                  updateLineItems(key, j, e.target.value)
                                }
                              />
                            </td>
                          );
                        
                          case "dcQuantity":
                          return(
                            <td key={i} style={{maxWidth:"15px"}}>
                              <input
                                className={
                                  lineItems[j].requestQuantityError
                                    ? "createPurchaseCostInputError"
                                    : "createPurchaseCostInput"
                                }
                                type="float"
                                value={row[key]}
                                onChange={(e) =>
                                  updateLineItems(key, j, e.target.value)
                                }
                              />
                            </td>
                          );

                          case "itemName":
                          return(
                            <td key={i} className="stickyFirstColumn">{row[key]}</td>
                          )
                        default:
                          return (
                            <td key={i}>
                              {row[key] !== null ? row[key] : "-"}
                            </td>
                          );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}

          <div className="poNetTotalSurchargeDiv">
            <div className="productionInstruction" style={{width:"700px"}}>
              <div style={{ marginTop: "10px" }}>Production Instruction</div>
              <textarea
                className="multilineInput"
                value={instruction}
                rows="6"
                cols="30"
                name="text"
                placeholder="Enter your notes"
                style={{
                  padding: "10px",
                  resize: "none",
                  marginTop: "10px",
                  height: "114px",
                  fontFamily: "sans-serif",
                }}
                onChange={(e) => {
                  setInstruction(e.target.value);
                }}
              />
            </div>
            <div className="poSpacer">&nbsp;</div>
            <div className="poSpacer">&nbsp;</div>
            <div className="poSpacer">&nbsp;</div>
          </div>
        </div>
      </div>

      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

          <button
            className="submitButton"
            onClick={() => {
              switch (productionParams.requestType.value) {
                case "Make To Order":
                  checkErrorsMTO();
                  break;

                case "Make To Stock":
                  checkErrorsMTS();
                  break;

                default:
                  break;
              }
            }}>
            Submit
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProduction);
