import React,{useState,useEffect} from 'react';
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import resetIcon from "../../assets/return.svg";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

const EditWorkOrder=({isLogged,setSection,addTokenToState,poInfo})=>{
   const [selectedTab,setSelectedTab] = useState([]);
   const [workOrderInputs,setWorkOrderInputs] = useState({
      productionNo: {
         inputType: "text",
         hintText: "Production Number",
         value: "",
         mandatory: true,
         colSpan: 4,
         error: false,
         errorMessage: "Please Select Error Message to Proceed",
         disabled: true
      },
      soNo: {
         inputType: "text",
         hintText: "So No",
         value: "",
         error: false,
         colSpan: 4 ,
         disabled: true,
      },
      itemName:{
         inputType: "text",
         value: "",
         colSpan: 6,
         error: false,
         errorMessage: "Please Select an Item",
         hintText: "Item Name",
         mandatory: true,
         disabled: true
      },
      productionQuantity:{
      inputType: "float",
      value: "",
      hintText: "Production Quantity",
      colSpan: 4,
      error: false,
      errorMessage: "",
      disabled: true,
      },
      bomId: {
         inputType: "options",
         options: [],
         value: "",
         colSpan: 4,
         error: false,
         errorMessage: "",
         hintText: "Select Bill Of Material",
         mandatory: true,
         disabled :true
      },
      bomDocumentName:{
      inputType: "text",
      value: "",
      colSpan: 4,
      error: false,
      errorMessage: "",
      disabled: true,
      hintText: "BOM Document Name",
      mandatory: false
      },
      workOrderStartDate: {
         inputType: "dateTimePicker",
         hintText: "Work Order Start date",
         mandatory: true,
         colSpan: 4,
         value:"",
         error: false,
         errorMessage: "Please Select Process Start Date",
         mindate: true,
      },
      workOrderEndDate: {
         inputType: "dateTimePicker",
         hintText: "Work Order End Date",
         mandatory: false,
         colSpan : 4,
         value:"",
         error: false,
         errorMessage: "Please Select an End Date",
         mindate: false
      }
      });
   const [processCardParams,setProcessCardParams] = useState({
      workOrderLineId:{value: ""},
   employeeId: {
      inputType: "options",
      hintText: "Employee ID",
      colSpan: 5,
      value: "",
      options: [],
      error: false,
      errorMessage: "Please Select a Employee Name",
      mandatory: true,
      },
      employeeName:{
      inputType: "text",
      hintText: "Employee Name",
      disabled: true,
      mandatory: false,
      colSpan: 6,
      value: "",
      error: false,
      errorMessage: ""
   },
   machineNo: {
      inputType: "text",
      colSpan: 4,
      value: "",
      error: false,
      hintText: "Machine No"
   },
   pickedQuantity:{
      inputType:"float",
      hintText: "Assigned Quantity",
      colSpan: 4,
      mandatory: true,
      error: false,
      errorMessage: "Please Provide Valid Quantity and Less than Production Quantity",
      value: ""
   },
   producedQuantity:{
      inputType:"float",
      hintText: "Produced Quantity",
      colSpan: 4,
      mandatory: true,
      error: false,
      errorMessage: "Please Provide Valid Produced Quantity and Less than Production Quantity",
      value: ""
   },
   rejectedQuantity:{
      inputType:"float",
      hintText: "Rejected Quantity",
      colSpan: 4,
      mandatory: true,
      error: false,
      errorMessage: "Please Provide Valid Passed Quantity and Less than Rejected Quantity",
      value: "",
      disable: true
   },
   repairQuantity:{
      inputType:"float",
      hintText: "Repair Quantity",
      colSpan: 4,
      mandatory: true,
      error: false,
      errorMessage: "Please Provide Valid Repair Quantity and Less than Produced Quantity",
      value: ""
   },
   passedQuantity:{
      inputType:"float",
      hintText: "Passed Quantity",
      colSpan: 4,
      mandatory: true,
      error: false,
      errorMessage: "Please Provide Valid Rejected Quantity and Less than Passed Quantity",
      value: 0,
      disabled: true,
   },
   });
   const [selectedBOM,setSelectedBOM] = useState({});
   const [workOrderErrors,setWorkOrderErrors] = useState([]);
   const [processCardLineItems,setProcessCardLineItems] = useState({});
   const [workOrderDetail,setWorkOrderDetail] = useState({});

   const history = useHistory();

      async function checkToken() {
      console.log("Checking token");
      // console.log(isLogged.accessToken);
      const token2 = await FetchNewToken(isLogged.accessToken);
      if (token2 === "expired") {
      history.push("/");
      } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
      } else {
      console.log("Token not changed");
      }
      }
      
      async function getWorkOrderDetail(){
         let detailResponse = await fetchData({
            method: "POST",
            url: 'production-fetch/work-order-detail/'+poInfo.editWorkOrderId,
            requestingPage: "workOrder",
            headers: {token: isLogged.accessToken, module: "Work Order"}
         });

         if(detailResponse.msg=='success'){
            return detailResponse.asset;
         }else{
            console.log(detailResponse);
            setSection('workOrderList');
            return {};
         }
      }

      async function getInformation(){
         await checkToken();
         var workOrderResponse = await getWorkOrderDetail();
         let {workOrderDetail,lineItems,employeeOptions} = workOrderResponse;
         let workOrderInputsCopy = Object.assign(workOrderInputs);
         if(workOrderDetail){
         Object.keys(workOrderInputsCopy).forEach((key)=>{
            workOrderInputsCopy[key]['value'] = workOrderDetail[key];
         });
         let selectedBOM = workOrderDetail.bomOptions.filter((row)=>row.bomId == workOrderDetail.bomId)[0];
         setSelectedBOM(selectedBOM);
         setSelectedTab(selectedBOM.fgProcessItems[0] || ["Process 1"])
         workOrderInputsCopy['bomId']['options'] = workOrderDetail['bomOptions'];
         let processCardParamsCopy = Object.assign(processCardParams);
         processCardParamsCopy['employeeId']['options'] = employeeOptions;
         setWorkOrderInputs(workOrderInputsCopy);
         setProcessCardParams(processCardParamsCopy);
         setProcessCardLineItems(lineItems);
      }
      }
   useEffect(()=>{
      getInformation();
   },[])
   

   const updateWorkOrderParameters=({section,paramName,key,value,wholeObject})=>{
      if(section==="general"){
         let workOrderInputsCopy = Object.assign(workOrderInputs);
         workOrderInputsCopy = update(workOrderInputsCopy,{[paramName]: {[key]: {$set: value} } });
         if(paramName==='bomId'){
            if(value && wholeObject!==undefined){
               workOrderInputsCopy = update(workOrderInputsCopy,{ bomDocumentName: { value: {$set: 
                                                         wholeObject.bomDocumentName} }})
               setSelectedBOM(wholeObject);
            }else{
               workOrderInputsCopy = update(workOrderInputsCopy,{ bomDocumentName: { value: {$set: ""} }})
               setSelectedBOM({});
            }
         } else if(paramName==='itemId'){
            if(value && wholeObject!==undefined){
            workOrderInputsCopy = update(workOrderInputsCopy,{ productionQuantity: { value: {$set: 
                     wholeObject.productionQuantity} } })
            } else{
               workOrderInputsCopy = update(workOrderInputsCopy,{ productionQuantity: { value: {$set: ""} } })
            }
         }
         setWorkOrderInputs(workOrderInputsCopy);
      } else if(section==='subSection'){
         let processCardParamsCopy = Object.assign(processCardParams);
         
         if(paramName === 'employeeId')
         {  
            if(value && wholeObject!==undefined)
            {
               processCardParamsCopy = update(processCardParamsCopy,{[paramName]: {[key]: {$set: value } } });
            processCardParamsCopy = update(processCardParamsCopy,{employeeName: {value: 
                                                   {$set: wholeObject.employeeName} } });
            }else{
               processCardParamsCopy = update(processCardParamsCopy,{employeeName: {value: 
                  {$set: ""} } });
               }
            } else if(paramName === 'machineNo')
            {
            processCardParamsCopy = update(processCardParamsCopy,{machineNo: {value: 
               {$set: value} } });
            } else if(paramName !== 'passedQuantity' && paramName!=='employeeId' && paramName!== 'machineNo')
            {
            // value = parseFloat(value) > 0 ? parseFloat(value) : 0;
            processCardParamsCopy = update(processCardParamsCopy,{[paramName]: {[key]: {$set: value } } });
            let passedQtyCalc = processCardParamsCopy['producedQuantity'].value - (processCardParamsCopy['repairQuantity'].value+
                                       processCardParamsCopy['rejectedQuantity'].value);
            processCardParamsCopy = update(processCardParamsCopy, {['passedQuantity']: {value: {$set: passedQtyCalc} } })
         }
           
         setProcessCardParams(processCardParamsCopy);
      }
   }
      
   const renderFormElements = ({ elementList, param, section }) => {
      return elementList.map((element) => {
         if(element!=='workOrderLineId')
            return (
               <FormElement
               key={element}
               inputType={param[element].inputType}
               value={param[element].value !== null ? param[element].value : ""}
               setInput={(value,wholeObject) => {
                  updateWorkOrderParameters({section,paramName: element,
                                 key: "value",value: value,wholeObject});
               }}
               hintText={param[element].hintText}
               mandatory={param[element].mandatory}
               colSpan={param[element].colSpan}
               options={
                  param[element].inputType === "options"
                     ? param[element].options
                     : null
               }
               error={param[element].error}
               rowSpan={
                  element === "vendorLogo" || element === "otherBusinessDetails"
                     ? param[element].rowSpan
                     : null
               }
               placeholder={param[element].placeholder}
               title={param[element].title}
               disabled={param[element].disabled}
               mindate={param[element].mindate}
               />
            );
      });
   };
   
   const editSubSectionItem=(index,action)=>{
      let lineCopy = Object.assign(processCardLineItems)
      let lineItemsCopy = lineCopy[selectedTab].slice();
      let processCardParamsCopy = Object.assign(processCardParams);
      if(action==='edit')
      {
         let editObj = lineItemsCopy.splice(index,1)[0];

         Object.keys(processCardParamsCopy).forEach((key)=>{
            processCardParamsCopy = update(processCardParamsCopy,{[key] : {["value"]:  {$set: editObj[key]} } })
         });
         
      }else if(action==='delete'){
         let deleteObj = lineItemsCopy.splice(index,1)[0];
      }
      lineCopy = update(lineCopy,{[selectedTab]: {$set : lineItemsCopy} })
      setProcessCardParams(processCardParamsCopy);
      setProcessCardLineItems(lineCopy);
   }

   const checkLineItemError=()=>{

      let processCardParamsCopy = Object.assign(processCardParams);
      let errorList=[];
      const conditionInputs = ['pickedQuantity','producedQuantity',//'testedQuantity',
      'passedQuantity','repairQuantity','rejectedQuantity'];
      Object.keys(processCardParamsCopy).forEach((param)=>{

         if(processCardParamsCopy[param].mandatory){

            processCardParamsCopy = update(processCardParamsCopy, {
               [param]: {
                 error: {
                   $set: !validateMandatory(processCardParamsCopy[param].value.toString()),
                 },
               },
             });
            }
             if(conditionInputs.includes(param) )
             {
               processCardParamsCopy = update(processCardParamsCopy, {
                  [param]: {
                    error: {
                      $set: processCardParamsCopy[param].value > workOrderInputs['productionQuantity'].value || processCardParamsCopy[param].value <0 ,
                    },
                  },
                });
             }
             if(processCardParamsCopy[param].error){
               errorList.push(processCardParamsCopy[param].errorMessage);
             }
      });
      if(!processCardParamsCopy['pickedQuantity']['value']){
         processCardParamsCopy['pickedQuantity']['error'] =true
         errorList.push('Pick A Quantity to add to List')
      }
      // let orderOfParams = conditionInputs.map((paramName)=> processCardParamsCopy[paramName].value );
      // if(isSortedArray.ascending(orderOfParams))
      // errorList.push("The Sum of A Line Item Should Be Valid and Less Than Production Quantity");
      let conditionCheck1 = processCardParamsCopy["pickedQuantity"].value >= processCardParamsCopy["producedQuantity"].value;
      let conditionCheck2 = processCardParamsCopy["producedQuantity"].value === processCardParamsCopy["passedQuantity"].value + processCardParamsCopy["repairQuantity"].value + processCardParamsCopy["rejectedQuantity"].value;
      let conditionCheck3 = processCardParamsCopy["producedQuantity"].value >=  (processCardParamsCopy["rejectedQuantity"].value + processCardParamsCopy["repairQuantity"].value)
      if( !(conditionCheck1 && conditionCheck2 && conditionCheck3) )
      errorList.push("The Sum of A Line Item Should Be Valid and Less Than Produced Quantity");

      let lineCopy = Object.assign(processCardLineItems);
      let lineItemCopy = lineCopy[selectedTab]?.slice() || [];
      let selectedEmployee = processCardParamsCopy["employeeId"]["options"]
                           .filter(
                              (option) =>
          option["optionId"] === processCardParamsCopy["employeeId"]["value"]
          )[0];
      if(lineItemCopy.findIndex((line)=>line.employeeNo == selectedEmployee.optionName) > -1)
      errorList.push('Employee Already added in this Process')

      if(errorList.length===0)
      {  
         let newLineItem={};
         Object.keys(processCardParamsCopy).forEach((param)=>{
               newLineItem[param] = processCardParamsCopy[param].value
         });
         
         newLineItem['employeeOption'] = selectedEmployee;
         newLineItem['employeeNo'] = selectedEmployee['optionName'];
         lineItemCopy.push(newLineItem);

         processCardParamsCopy = update(processCardParamsCopy,
                                             {employeeId: {value: { $set: "" } },
                                             employeeName: {value: {$set: ""} },
                                             machineNo: {value: {$set: ""} } ,
                                             pickedQuantity: {value: {$set: ""} },
                                             producedQuantity: {value: {$set: ""} }, 
                                             passedQuantity: {value: {$set: 0} }, 
                                             repairQuantity: {value: {$set: ""} },
                                             rejectedQuantity: {value: {$set: ""}} 
                                                         });
         lineCopy[selectedTab] = lineItemCopy
         setProcessCardLineItems(lineCopy);
      }
         setWorkOrderErrors(errorList);
         setProcessCardParams(processCardParamsCopy);

   }

   const RenderTable=()=>{
      const tableColumns = ['employeeNo','employeeName','machineNo','pickedQuantity','producedQuantity',
                           'repairQuantity','rejectedQuantity','passedQuantity']; 
      return(
      <React.Fragment>
         <table className="createVendorContactsTable">
            <thead>
               <tr className="createVendorContactsTableHeader">
                  <td>Employee Id</td>
                  <td>Employee Name</td>
                  <td>Machine No</td>
                  <td>Picked Quantity</td>
                  <td>Produced Quantity</td>
                  <td>Repair</td>
                  <td>Rejected</td>
                  <td>Passed</td>
                  <td className="itemIndiTabActions">Actions</td>
               </tr>
            </thead>
            
            <tbody>
               {processCardLineItems[selectedTab]?.length > 0 ? (
                  processCardLineItems[selectedTab].map((row,i)=>
                  (<tr className="createVendorContactsTableRows" key={i}>
                     
                     {tableColumns.map((col)=>
                     (<td key={col}>{row[col] || 0}</td>)                     
                     )}
                        <td className="itemIndiTabActions">
                           <img
                           alt="Edit Record"
                           className="createVendorContactsAction"
                           src={editIcon}
                           onClick={() => {
                              editSubSectionItem(i, "edit");
                           }}
                           />
                           <img
                           alt="Delete Record"
                           className="createVendorContactsAction"
                           src={deleteIcon}
                           onClick={() => {
                              var reset = window.confirm("Delete record?");
                              if (reset) {
                                 editSubSectionItem(i, "delete");
                              }
                           }}
                           />
                     </td>
                  </tr>)
                  )
               ) :
               (<tr className="createVendorContactTableRows">
                  {Object.keys(processCardParams).map((item)=><td key={item}>&nbsp;</td>)}
                  <td>&nbsp;</td>
               </tr>)}
            </tbody>
         </table>
      </React.Fragment>)
   }
   const checkErrorsToEditWorkOrder=(status="In Progress")=>{
      let errorList=[];
      let workOrderInputsCopy = Object.assign(workOrderInputs);
      
      Object.keys(workOrderInputs).forEach((param)=>{
         if(workOrderInputsCopy[param].mandatory){

            workOrderInputsCopy = update(workOrderInputsCopy, {
               [param]: {
                 error: {
                   $set: !validateMandatory(workOrderInputsCopy[param].value.toString()),
                 },
               },
             });
            };

            if(workOrderInputsCopy[param].error){
               errorList.push(workOrderInputsCopy[param]["errorMessage"])
            }
      });

      if(new Date(workOrderInputsCopy['workOrderStartDate'].value) >= new Date(workOrderInputsCopy['workOrderEndDate'].value))
      errorList.push('Process End Date cannot be Older than Start Date')
      let lineItemsCopy =Object.assign(processCardLineItems);
      
      Object.entries(lineItemsCopy).map(([key,value])=>{
         if(value.length==0){
            errorList.push(`Add atleast one Employee  in ${key}`)
         }else{
         let sumOfLine=value.reduce((prev,curr)=>prev+ curr['pickedQuantity'],0);

         if(sumOfLine > workOrderInputsCopy['productionQuantity'].value)
         errorList.push(`Picked Quantity Exceeded the Total Production Quantity in ${key}`);
         }
      })
      if(errorList.length==0){
         let dataToServer = {
            bomId: workOrderInputsCopy['bomId'].value,
            workOrderStartDate: workOrderInputsCopy['workOrderStartDate'].value,
            workOrderEndDate: workOrderInputsCopy['workOrderEndDate'].value,
            lineItems: lineItemsCopy,
            status
         }

         submitWorkOrder(dataToServer);
      }
      setWorkOrderErrors(errorList);
      setWorkOrderInputs(workOrderInputsCopy);
   }
   
   const submitWorkOrder=async(data)=>{
      setIsOpen(true);
    setModalText("Editing Work Order. Please wait...!");

      let response = await fetchData({
         method: 'post',
         requestingPage: 'workOrder',
         headers: {token: isLogged.accessToken, module: "Work Order"},
         data,
         url: 'production-edit/edit-work-order/'+poInfo.editWorkOrderId,
      });

      setDataSubmitted(true);
      if(response.msg==='success'){
        setModalText('Work Order SuccessFully Edited')
      } else if(response.msg==='failure'){
         setModalText(response.desc);
         setWorkOrderErrors([response.desc])
         console.log(response)
      } else{
         setModalText("Please Try Again Later")
         setWorkOrderErrors([])
         console.log(response);
      }
   }
      const renderErrorMessage=()=>{
         if(workOrderErrors.length > 0)
         return workOrderErrors[0]
      }
      const [modalIsOpen, setIsOpen] = useState(false);
      const [dataSubmitted, setDataSubmitted] = useState(false);
      const [modalText, setModalText] = useState(
      "Creating Work Order. Please wait..."
      );
      const submitOkClick=()=>{
         setIsOpen(false);
         if(workOrderErrors.length== 0)
         setSection("workOrderList")
      };
      const copyProcess=({tabIndex})=>{
         let processCardLineCopy = Object.assign(processCardLineItems);
         let message;
         if(tabIndex == 0){
            let firstLine = Object.values(processCardLineCopy)[0]
            Object.keys(processCardLineCopy).forEach((key)=>{
               processCardLineCopy[key] = firstLine;
            });
            message="Copied For All Processes"
         }else{
            let previousTab = selectedBOM.fgProcessItems[tabIndex-1];
            processCardLineCopy[selectedTab] = processCardLineCopy[previousTab];
            message = "Copied From Previous Process"
         }
         setProcessCardLineItems(processCardLineCopy);
         window.alert(message);
      }
      
      const renderSubSection=({tabIndex})=>{
   
         return(
            <React.Fragment>
               <div className="purchaseOrderIndividualItemsArea" style={{gridAutoRows:"60px"}}>
                  {renderFormElements({elementList:Object.keys(processCardParams),param:processCardParams,section:'subSection'})}
                  
                  <FormElement inputType="addButton" value="+ Add"
                     colSpan={4} setInput={() => { setWorkOrderErrors([]); checkLineItemError(); }} />
               </div>
                {/*PROCESS TABLE*/}
                <RenderTable />
                   <FormElement inputType="addButton" value={tabIndex===0 ? "Copy table for All Process" : "Copy from Previous Process"}
                  setInput={()=>{copyProcess({tabIndex})}}
                  styles={{width:"250px",margin:"5px",}}/> 
            </React.Fragment>
         )
      }

      const renderTabs=()=>{
         const processSteps = selectedBOM.fgProcessItems || ["Process 1"]
         if(workOrderInputs.bomId.value > 0){
           return (<React.Fragment>
                   <div className="createPurchaseImportTabArea">
                     <div className="createVendorTabs"  style={{ borderBottom: "1px solid rgb(240,240,240)" }}>
                     { 
                       processSteps.map((tab,index)=>(
                         <div key={Math.random()} className={selectedTab === tab ? "createPurchaseImportTabSelected" : "createPurchaseImportTab"}
                           style={{lineHeight: "15px",fontWeight:"500"}}
                             onClick={()=>{if( processCardLineItems[processSteps[index-1]]?.length || index==0)setSelectedTab(tab)}}>
                             {tab.toUpperCase()}
                         </div>
                       ))
                     }
                     </div>
                   <div style={{fontSize:"2px"}}>&nbsp;</div>
                   <div className="purchaseOrderSubsectionArea">
                   {renderSubSection({tabIndex:  processSteps.findIndex((elem)=>elem===selectedTab)})}
                   </div>
                   </div>
                   </React.Fragment>
               )
         }else{
           return null
         }
       }

    return(
        <React.Fragment>
         <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText}
            dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
              <div className="formArea">
                  <div style={{width: "1000px", margin: "0 auto 2rem",
                           padding: "2rem",border: "1px solid lightgray",
                           borderRadius: "5px",backgroundColor: "white",}}>
                        {/*GENERAL SECTION */}
                     <div style={{ display: "flex",marginBottom: "15px" }}>
                        <div className="createPurchaseOrderGrid" style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
                           {renderFormElements({ elementList: Object.keys(workOrderInputs),
                                                   param: workOrderInputs,section: "general",})}
                        </div>
                     </div>
                           {/*Sub section*/}
                           {/* {workOrderInputs.bomId.value ? 
                           <div className="purchaseOrderSubSectionArea">
                              {renderSubSection()}
                           </div> : null } */}
                        {workOrderInputs.bomId.value ? renderTabs(): null}
                  </div>
               </div>
               <div className="formSubmitArea">
                  <div className="formSubmitInnerArea">
                     <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                     <button className='submitButton'
                     onClick={()=>{
                        setWorkOrderErrors([]);
                        checkErrorsToEditWorkOrder('Completed')
                     }}
                     >
                        Submit as Completed ✅
                     </button>
                     <button
                        className="submitButton"
                        onClick={() => {
                           setWorkOrderErrors([]);
                        checkErrorsToEditWorkOrder();
                        }}>
                        Submit
                     </button>
                  </div>
               </div>
        </React.Fragment>
    )
}

const mapStateToProps=(state)=>{
   return{
      isLogged: state.isLogged,
      poInfo: state.poInfo
   }
}

const mapDispatchToProps=(dispatch)=>{

   return {
      addTokenToState: (accessToken, employeeId) =>dispatch(addToken(accessToken, employeeId)),
   }
}


export default connect(mapStateToProps,mapDispatchToProps)(EditWorkOrder);