import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import productionIcon from "../../assets/production.svg";
import CreateProductionPlanning from "./CreateProductionPlanning"
import { connect } from "react-redux";
import ProductionPlanningList from "./ProductionPlanningList"
import EditProductionPlanning from "./EditProductionPlanning"
import ProductionPlanningDetail from "./ProductionPlanningDetail"
import { exportPDF } from "../CommonFunctions/ExportPDF";

const ProductionPlanning = ({ poInfo,  isLogged }) => {
    const [currentSection, setCurrentSection] = useState("productionPlanningList");

    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "productionPlanningList"};
    var productionPlanningBtn = { buttonName: "Create Production Planning", className: "employeeButton", setSection: setCurrentSection, sectionName: "createProductionPlanning", style:{width:"max-content"}
};
var downloadPRButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "productionPlanningDetail") }, sectionName: "productionPlanningDetail" };

    const renderSection = (section) => {
        switch (section) {
            case "createProductionPlanning":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={productionIcon} pageTitle="Create Production Planning" 
                        buttonList={[goBackButton]} 
                        />
                        <CreateProductionPlanning setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "productionPlanningList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={productionIcon} pageTitle={"Production Planning List"}
                            buttonList={isLogged.access["Production Planning"] === "Create" || isLogged.access["Production Planning"] === "Edit" || isLogged.access["Production Planning"] === "App/Dec"
                                ? [productionPlanningBtn] : null} />
                        <ProductionPlanningList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "productionPlanningDetail":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={productionIcon}
                            pageTitle={"Production Planning"}
                            buttonList={[goBackButton, 
                                downloadPRButton
                            ]}
                        />
                        <ProductionPlanningDetail setSection={(value) => setCurrentSection(value)}/>
                    </React.Fragment>
                );

            case "editProductionPlanning":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={productionIcon}
                            pageTitle={"Edit Production Planning"}
                            buttonList={[goBackButton]}
                        />
                        <EditProductionPlanning setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );
            
            default:
                return<></>
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Production" selectedSubMenu="Production Planning" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { poInfo: state.poInfo, isLogged: state.isLogged };
};

export default connect(mapStateToProps)(ProductionPlanning);
