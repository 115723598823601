import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import CreatePurchaseOrder from "./CreatePurchaseOrder";
import purchaseOrderIcon from "../../assets/purchaseOrder.svg";
import PurchaseOrderList from "./PurchaseOrderList";
import PurchaseOrderDetail from "./PurchaseDetailPage";
import EditPurchaseOrder from "./EditPurchaseOrder";
import CreatePurchaseReturn from "./CreatePurchaseReturn";
import PurchaseReturnDetail from "./PurchaseReturnDetail";
import EditPurchaseReturn from "./EditPurchaseReturn";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";
import ViewDoc from "./ViewDocument";


const PurchaseOrder = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("purchaseOrderList");
    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);
    const [inwardDetail,setInwardDetail]=useState({})

    const [tab, setTab] = useState("createPurchaseOrder");
    const [downloadReportParams,setDownloadReportParams] = useState({})

    var createPurchaseOrderButton = [
        { buttonName: "Create Purchase Order", className: "employeeButton", setSection: setCurrentSection, sectionName: "createPurchaseOrder" ,style:{width:"max-content"}},
    ];

    var createPurchaseReturnButton = [
        { buttonName: "Create Purchase Return", className: "employeeButton", setSection: setCurrentSection, sectionName: "createPurchaseReturn" ,style:{width:"max-content"}},
    ];
    var backToDetail = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "purchaseOrderDetail" };
    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "purchaseOrderList" };
    var docViewBtn = { buttonName: "View Doc", className: "exportButton", setSection: setCurrentSection, sectionName: "viewInwardDoc" };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "poDetails",downloadReportParams) }, sectionName: "purchaseOrderList" };
    var downloadPRButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "prDetails") }, sectionName: "purchaseOrderList" };
    var downloadDoc = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "inwardBill") }, sectionName: "purchaseOrderList" };

    const renderSection = (section) => {
        switch (section) {
            case "createPurchaseOrder":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={purchaseOrderIcon} pageTitle="Create Purchase Order" buttonList={[goBackButton]} />
                        <CreatePurchaseOrder setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "purchaseOrderList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={purchaseOrderIcon}
                            pageTitle="Purchase Order List"
                            buttonList={
                                tab === "createPurchaseOrder" && (isLogged.access["Purchase Order"] === "Create" || isLogged.access["Purchase Order"] === "Edit" || isLogged.access["Purchase Order"] === "App/Dec")
                                    ? createPurchaseOrderButton
                                    : tab === "createPurchaseReturn" && (isLogged.access["Purchase Order"] === "Create" || isLogged.access["Purchase Order"] === "Edit" || isLogged.access["Purchase Order"] === "App/Dec")
                                        ? createPurchaseReturnButton
                                        : null} />
                        <PurchaseOrderList
                            setSection={(value) => setCurrentSection(value)}
                            setApproval={(value) => setApprovalPage(value)}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                            setTab={setTab}
                        />
                    </React.Fragment>
                );

            case "purchaseOrderDetail":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={purchaseOrderIcon}
                            pageTitle={approvalPage ? "Approve Purchase Order" : "Purchase Order Detail"}
                            buttonList={approvalPage ? [goBackButton] : [,goBackButton,inwardDetail?docViewBtn:null, downloadButton]}
                        />
                        <PurchaseOrderDetail
                            setSection={(value) => setCurrentSection(value)}
                            setInwardDetail={(value) => setInwardDetail(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                            setDownloadReportParams={(value=>setDownloadReportParams(value))}
                        />
                    </React.Fragment>
                );

            case "editPurchaseOrder":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={purchaseOrderIcon}
                            pageTitle={editAndApprove ? "Edit & Approve PO" : "Edit Purchase Order"}
                            buttonList={[goBackButton]}
                        />
                        <EditPurchaseOrder setSection={(value) => setCurrentSection(value)} editApprove={editAndApprove} />
                    </React.Fragment>
                );

            case "createPurchaseReturn":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={purchaseOrderIcon} pageTitle="Create Purchase Order Return" buttonList={[goBackButton]} />
                        <CreatePurchaseReturn setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "purchaseOrderReturnDetail":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={purchaseOrderIcon}
                            pageTitle={approvalPage ? "Approve Purchase Return" : "Purchase Return Detail"}
                            buttonList={approvalPage ? [goBackButton] : [goBackButton, downloadPRButton]}
                        />
                        <PurchaseReturnDetail
                            setSection={(value) => setCurrentSection(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "editPurchaseReturn":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={purchaseOrderIcon}
                            pageTitle={editAndApprove ? "Edit & Approve PR" : "Edit Purchase Return"}
                            buttonList={[goBackButton]}
                        />
                        <EditPurchaseReturn setSection={(value) => setCurrentSection(value)} editApprove={editAndApprove} />
                    </React.Fragment>
                );
            case "viewInwardDoc":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={purchaseOrderIcon}
                            pageTitle={"View Inward Document"}
                            buttonList={[inwardDetail?.inwardDocument ?downloadDoc:null,backToDetail]}
                        />
                        <ViewDoc setSection={(value) => setCurrentSection(value)} inwardDetail={inwardDetail} />
                    </React.Fragment>
                );

            default:
                return <div>Page yet to be built</div>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Purchase" selectedSubMenu="Purchase Order" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(PurchaseOrder);
