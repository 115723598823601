import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import logoNotAvailable from "../../assets/no-stopping.svg";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import { editVendorPaymentId } from "../../redux/PurchaseOrder/PoInfoActions";

const VendorPaymentDetail=({ isLogged, poInfo, addTokenToState, setEditAndApprove, setSection, approval, editVenPayId })=>{

    const [venPaymentDetail, setVenPaymentDetail] = useState({});
    const [lineItems, setLineItems] = useState([]);
    const [vendorLogo, setVendorLogo] = useState("");
    const [notes, setNotes] = useState("");
    const [errorMessage, setErrorMessage] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    async function getInformation() {
        await checkToken();
        await getVenPaymentDetail();
    }

    const history = useHistory();

    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getVenPaymentDetail() {
        var result = await fetchData({
            requestingPage: "venPaymentDetail",
            method: "get",
            url: "po-fetch/ven-payment-detail/" + poInfo.viewVenPaymentId,
            headers: { token: isLogged.accessToken, module: "Vendor Payments" }
        });

        if (result.msg === "success") {
            setVenPaymentDetail(result.asset[0]);
            setLineItems(result.asset[0].lineItems);
            setNotes(result.asset[0].notes);
            return result.asset[0];
        } else {
            console.log(result);
        }
    };

    useEffect(() => {
        if(venPaymentDetail!={})
        getVendorLogo();
    }, [venPaymentDetail]);

    async function getVendorLogo() {
        var data = await fetchData({
            requestingPage: "vendorImage",
            method: "get",
            url: "image/vendor-logo/" + venPaymentDetail.vendorId,
            headers: { token: isLogged.accessToken, module: "Vendor Payments" },
        });
        setVendorLogo(data);
    };

    const renderSummaryInfo = () => {
        if (venPaymentDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        Receipt No: {venPaymentDetail.receiptNo}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={
                                venPaymentDetail.requestStatus === "Declined"
                                    ? { color: "red" }
                                    : venPaymentDetail.requestStatus === "Approved"
                                        ? { color: "#14c76a" }
                                        : { color: "#333333" }
                            }
                        >
                            ( {venPaymentDetail.receiptStatus} )
                        </span>
                    </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">Payment Date</span>
                        <span className="vendorSummaryValue">: {venPaymentDetail.paymentDate}</span>
                        <span className="vendorSummaryCategory">Payment Type</span>
                        <span className="vendorSummaryValue">: {venPaymentDetail.paymentType}</span>
                        <span className="vendorSummaryCategory">Total Invoice Amount</span>
                        <span className="vendorSummaryValue">: {venPaymentDetail.netTotal}</span>
                        <span className="vendorSummaryCategory">Payment Amount Received</span>
                        <span className="vendorSummaryValue">: {venPaymentDetail.paymentAmount}</span>
                        <span className="vendorSummaryCategory">Payment Amount Balance</span>
                        <span className="vendorSummaryValue">: {venPaymentDetail.paymentAmountBalance}</span>
                        <span className="vendorSummaryCategory">Paid From Plant</span>
                        <span className="vendorSummaryValue">: {venPaymentDetail.paidFromPlantName || ""}</span>
                        <span className="vendorSummaryCategory">Paid From Account</span>
                        <span className="vendorSummaryValue">: {`${venPaymentDetail.bankName || ""} - ${venPaymentDetail.accountHolder || ""} - ${venPaymentDetail.accountNo || ""}`}</span>
                        <span className="vendorSummaryCategory">Number of Invoices</span>
                        <span className="vendorSummaryValues">: {lineItems.length}</span>
                    </div>
                </React.Fragment>
            );
        }
    };

    const renderImageStatus = () => {
        return (
            <div>
                <span style={{ flex: "1" }}>&nbsp;</span>

                <img
                    className="poVendorImage"
                    src={
                        vendorLogo !== null && vendorLogo !== undefined && vendorLogo !== ""
                            ? `data:image/png;base64,${vendorLogo}`
                            : logoNotAvailable
                    }
                    alt="Vendor Logo"
                />
            </div>
        );
    };

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td >Invoice No</td>
                        <td >Purchase Order No</td>
                        <td >Invoice Amount</td>
                        <td >Invoice Amount Balance</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={lineItems} tab="contact" />
                </tbody>
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        let lineItemParams = ["invoiceNo", "purchaseOrderNo", "totalAmount", "invoiceBalance"];
        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i}>{row[key] !== null ? row[key] : " - "}</td>;
                    })}
                </tr>
            );
        });
    };

    const renderErrorMessage = () => {
        if (errorMessage.length > 0) {
            return errorMessage[0];
        }
    };

    async function approveReceipt() {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "approveCusReceipt",
            method: "put",
            url: "po-edit/approve-vendor-payment/" + poInfo.viewVenPaymentId,
            headers: { token: isLogged.accessToken, module: "Vendor Payments" }
        });
        setDataSubmitted(true);
        if (result.msg === "success") {
            setModalText("Vendor Payment Approved");
        } else {
            setModalText(result.desc);
            setErrorMessage([result.desc]);
        }
    };

    const ApproveButtons = () => {
        return (
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                    <button
                        className="saveButton"
                        onClick={() => {
                            setEditAndApprove(true);
                            editVenPayId(poInfo.viewVenPaymentId);
                            setSection("editVenPayment");
                        }}>
                        Edit
                    </button>
                    <button
                        className="submitButton"
                        onClick={() => {
                            approveReceipt();
                        }}>
                        Approve
                    </button>
                </div>
            </div>
        );
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (errorMessage.length === 0) {
            setSection("vendorPaymentsList");
        }
        setModalText("Approving Vendor Payment. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving Vendor Payment. Please wait...");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="poSummaryText">{renderSummaryInfo()}</div>
                        <div className="poSummaryText2">
                            <span className="vendorSummaryRole" style={{ textTransform: "uppercase" }}>
                                {`${venPaymentDetail.vendorId} - ${venPaymentDetail.vendorName}`}
                            </span>

                            <span className="poSummaryValue">
                                {venPaymentDetail.zipCode !== null
                                    ? `Address: ${venPaymentDetail.address}${venPaymentDetail.city} ${venPaymentDetail.state || ""}- ${venPaymentDetail.zipCode}`
                                    : `Address: ${venPaymentDetail.address} ${venPaymentDetail.city} ${venPaymentDetail.state || ""}`}
                            </span>

                            <span className="poSummaryValue">
                                {venPaymentDetail.mobile !== null || venPaymentDetail.vendorPhone !== null ? `\nPhone: ` : null}
                                {venPaymentDetail.mobile !== null ? venPaymentDetail.mobile : null}
                            </span>
                            <span className="poSummaryValue">{venPaymentDetail.email ? `Email Id: ${venPaymentDetail.email}` : null}</span>

                            <span className="poSummaryValue">{venPaymentDetail.panCardNo !== null ? `GST No: ${venPaymentDetail.panCardNo}` : null}</span>
                        </div>
                        {renderImageStatus()}
                    </div>
                        <div className="vendorDetailTabArea">
                            <RenderTable />
                        </div>
                    <div style={{ marginTop: "10px" }}>
                        Notes: {notes !== null ? notes : "-"}
                    </div>
                </div>
            </div>
            {approval ? <ApproveButtons /> : null}
        </React.Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editVenPayId: (venPayId) => dispatch(editVendorPaymentId(venPayId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorPaymentDetail);