import React,{useState,useCallback,useEffect, Fragment} from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import FetchNewToken from "serverCall/FetchNewToken";
import fetchData from "serverCall/fetchData";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import editIcon from "assets/edit.svg";
import editIconDisabled from "assets/edit_disabled.svg";
import {addAgingReportId} from "redux/PurchaseOrder/PoInfoActions"
import downIcon from "assets/down.svg";
import upIcon from "assets/up.svg";
import viewIcon from "../../assets/view.svg";
const AgingReportList = ({isLogged,
  setSection,
  // state

  // dispatch
  addAgingReportId,
  addTokenToState, }) => {
    const [loading, setLoading] = useState(true);
    const [agingReportList, setAgingReportList] = useState([]);
    const [noRecords, setNoRecords] = useState(false);
    const history = useHistory();
    const checkToken = useCallback(async () => {
      const token2 = await FetchNewToken(isLogged.accessToken);
      if (token2 === "expired") {
        history.push("/");
      } else if (token2 !== isLogged.accessToken) {
        addTokenToState(isLogged.employeeId, token2);
      } else {
       // console.log("Token not changed");
      }
    }, [isLogged, history, addTokenToState]);
  
    const getAgingReportList = useCallback(async () => {
      setLoading(false);
      var data = await fetchData({
        requestingPage: "sectionMaster",
        method: "post",
        url: "so-fetch/aging-report-list",
        headers: { token: isLogged.accessToken, module: "Aging Report" },
      });
      if (data.msg === "success") {
        if (data.asset.length === 0) {
          setNoRecords(true);
        } else {
            var detail = [];
            data.asset.forEach((dataItem, i) => {
                var newElement = Object.assign(dataItem);
                newElement.collapse = true;
                detail.push(newElement);
            })
          setAgingReportList(detail);
        }
      } else {
        console.log(data);
      }
    }, [isLogged]);

    const getInformation = useCallback(
      async () => {
        await checkToken();
        await getAgingReportList();
      },
      [checkToken, getAgingReportList]);
  
    useEffect(() => {
      getInformation();
    }, [getInformation]);

    const toggleCollapse=(index)=>{
      let lineCopy = agingReportList.slice();
      lineCopy = lineCopy.map((row,lineIndex)=>{
        if(index===lineIndex){
          row.collapse = !row.collapse
        }else{
          row.collapse = true;
        }
        return row;
      });
      setAgingReportList(lineCopy);
    }
    const RenderSubTable=({data})=>{

      return(
          <td colSpan={6} className="vendorListInnerTableWrapper">
        <table className="vendorListInnerTable">
          <thead>
            <tr className="vendorListInnerTableHeader">
              <td className="vendorListInnerTableHeading stickyFirstColumn">Section No</td>
              <td className="vendorListInnerTableHeading">Section Name</td>
              <td className="vendorListInnerTableHeading">Cutting Length</td>
              <td className="vendorListInnerTableHeading">Weight</td>
              <td className="vendorListInnerTableHeading">Pieces</td>
              <td className="vendorListInnerTableHeading">Party Order</td>
            </tr>
          </thead>
          <tbody>
            {data.map((row,index) => {
              return (
                <tr
                  className="vendorListInnerTable"
                  key={index}>
                  <td className="vendorListInnertableInfo stickyFirstColumn">{row.sectionNo}</td>
                  <td className="vendorListInnertableInfo">{row.sectionName}</td>
                  <td className="vendorListInnertableInfo">{row.cuttingLength}</td>
                  <td className="vendorListInnertableInfo">{row.weight}</td>
                  <td className="vendorListInnertableInfo">{row.pieces}</td>
                  <td className="vendorListInnertableInfo">{row.partyOrderNo}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
          </td>
      );
    }
    const renderAgingReportList=()=>{
      return( 
        agingReportList.map((row, index) => {
        return (
          <Fragment key={index}>
          <tr className='vendorList' key={index}>
            <td className='vendorListInfo'>{row.agingReportNo}</td>
            <td className="vendorListInfo">{row.agingMachineNo}</td>
            <td className="vendorListInfo">{row.operationNo}</td>
            <td className='vendorListInfo'>{row.agingDate}</td>
            <td className="vendorListInfo">{row.pressMachineNo}</td>
            <td className="vendorListInfoActions">
            <img
                  alt="Toggle Expand"
                  className="vendorActionImages"
                  title="Toggle Expand"
                  src={row.collapse ? downIcon : upIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleCollapse(index)
                  }}
                />
                 <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View Detail"
                                onClick={(e) => {
                                  if (isLogged.access["Aging Report"] === "Edit") {
                                    addAgingReportId(row.agingReportId);
                                    setSection("agingReportDetail");
                                  }
                                }}
                            />
              <img
                className="uomEditImage"
                alt="Edit Record"
                src={isLogged.access["Aging Report"] === "Edit" ? editIcon : editIconDisabled}
                onClick={() => {
                  if (isLogged.access["Aging Report"] === "Edit") {
                    addAgingReportId(row.agingReportId);
                    setSection("editAgingReport");
                  }
                }
              }
                title={isLogged.access["Aging Report"] === "Edit" ? "Edit Record" : "No Edit Access"}
                style={isLogged.access["Aging Report"] === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
              />
            </td>
          </tr>
          <tr>
          { !row.collapse ? <RenderSubTable data={row.lineItems}/> : null}
          </tr>
          </Fragment>
        )
      })
    )
    }
  return (
    <div className="uomListArea">
    <table className="vendorListTable">
      <thead>
        <tr className="vendorListHeader">
          <td className="vendorListHeading"><span>Aging Report No</span></td>
          <td className="vendorListHeading"><span>Aging Machine No</span></td>
          <td className='vendorListHeading'><span>Operation No</span></td>
          <td className='vendorListHeading'><span>Aging Date</span></td>
          <td className='vendorListHeading'><span>Press Machine No</span></td>
          <td className="vendorListHeadingActions"><span>Action</span></td>
        </tr>
      </thead>
      <tbody>
        {agingReportList.length > 0 ? renderAgingReportList() : null}
      </tbody>
    </table>
    {noRecords ? (
      <div className="noRecordsYet">No records found</div>
    ) : loading ? (
      <div className="loader" style={{ margin: "1rem auto" }}></div>
    ) : null}
  </div>
  )
}

const mapStateToProps = (state) => ({ isLogged: state.isLogged })

const mapDispatchToProps = (dispatch)=>{
  return {
    addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    addAgingReportId: (value)=>dispatch(addAgingReportId(value))
};
}

export default connect(mapStateToProps, mapDispatchToProps)(AgingReportList);
