import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { viewCustomerReceiptId,editCustomerReceiptId } from "../../redux/PurchaseOrder/PoInfoActions";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import viewIcon from "../../assets/view.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import approveIcon from "../../assets/approve2.svg";
import FormElement from "../SmallComponents/FormElement";
import Pagination from "../CommonFunctions/pagination";
import { handleSort } from "../CommonFunctions/utils";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import SortUpGreen from "../../assets/SortUpGreen.svg";

const CustomerReceiptList=({
    setSection,isLogged,
    viewCusRecId,
    editCusRecId,
    addTokenToState,
    setApproval,
    setEditAndApprove
})=>{
    const history=useHistory();
    const [loading,setLoading] = useState(true);
    const [customerId,setCustomerId]=useState("");
    const [cusReceiptList,setCusReceiptList]=useState([]);
    const [cusReceiptApprovedList,setCusReceiptApprovedList] = useState([]);
    const [selectedTab,setSelectedTab] = useState("receiptUnApprovedList");
    const [currentPage,setCurrentPage]=useState(1);
    const [PageSize,setRowsPerPage]=useState(10);
    const [customerOptions,setCustomerOptions]=useState([]);

    const access=isLogged.access["Customer Receipt"];

    useEffect(async()=>{
        await checkToken();
        await getCustomers();
    },[])

    const cusReceiptCurrentPage = useMemo(() => {
        if(selectedTab==="receiptUnApprovedList"){
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return cusReceiptList.slice(firstPageIndex, lastPageIndex);}
      }, [currentPage,cusReceiptList,PageSize]);

      const cusReceiptApprovedCurrentPage = useMemo(() => {
        if(selectedTab==="receiptApprovedList"){
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return cusReceiptApprovedList.slice(firstPageIndex, lastPageIndex);}
      }, [currentPage,cusReceiptApprovedList,PageSize]);


    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
     }

     async function getCustomers() {
        var data = await fetchData({
            requestingPage: "getParents",
            method: "get",
            url: "fetch/customer-list",
            headers: { token: isLogged.accessToken ,module:"Customer"},
        });
        // console.log(data);
        if (data.msg === "success") {
            var options = [];
            data.asset.forEach((parent) => {
                var newParent = { optionId: parent.customerId, optionName: parent.customer };
                options.push(newParent);
            });
            setCustomerOptions(options);
        }
    }

     async function getCusReceiptList(){
         var receiptList1=await fetchData({
             requestingPage: "cusReceiptList",
             method: "post",
             url : "so-fetch/cus-receipt-unapproved-list",
             headers: {token:isLogged.accessToken, module:"Customer Receipt"},
             data: {customerId}
         });

         if(receiptList1.msg==="success"){
             receiptList1.asset.forEach(obj => obj.collapse = true);
            setCusReceiptList(receiptList1.asset);
            setLoading(false);
         }else{
             console.log(receiptList1);
         }
     }

     async function getCusReceiptApprovedList(){
        var receiptList2=await fetchData({
            requestingPage: "cusReceiptList",
            method: "post",
            url : "so-fetch/cus-receipt-approved-list",
            headers: {token:isLogged.accessToken, module:"Customer Receipt"},
            data: {customerId}
        });

        if(receiptList2.msg==="success"){
            receiptList2.asset.forEach(obj => obj.collapse = true);
           setCusReceiptApprovedList(receiptList2.asset);
           setLoading(false);
        }else{
            console.log(receiptList2);
        }
    }

    const editItemCollapse = (list, index) => {
        var listCopy = [];

        switch (list) {
            case "receiptUnApprovedList":
                listCopy = cusReceiptList.slice();
                break;

            case "receiptApprovedList":
                listCopy = cusReceiptApprovedList.slice();
                break;

        }

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });

        switch (list) {
            case "receiptUnApprovedList":
                setCusReceiptList(listCopy);
                break;

            case "receiptApprovedList":
                setCusReceiptApprovedList(listCopy);
                break;
        }
    };

const tabList=[ {id: "receiptUnApprovedList",name:"Customer Receipts"},
                {id: "receiptApprovedList",name:"Approved Customer Receipts"}];

    useEffect(()=>{
        checkToken();
        setLoading(true);
        switch(selectedTab){
            case "receiptUnApprovedList":
                setCusReceiptList([]);
                 getCusReceiptList();
                break;
            
            case "receiptApprovedList":
                setCusReceiptApprovedList([]);
                 getCusReceiptApprovedList();
                break;

            default:
                break;
        }
    },[selectedTab,customerId]);

const setTab=(tabname)=>{
    setSelectedTab(tabname)
}
    const RenderTabList = ({ tabList }) => {
        return tabList.map((tab) => {
            return (
                <div className={selectedTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    key={tab.id} onClick={(e) => setTab(tab.id)}>
                    {tab.name}
                </div>
            );
        });
    };

    const RenderSubTable=({lineItems,tab})=>{
        return(
            <td colSpan={tab==="receiptUnApprovedList" ? "9":"10"} className="vendorListInnerTableWrapper">
                    <table className="vendorListInnerTable">
                       <thead>
                          <tr className="vendorListInnerTableHeader">
                              <td className="vendorListInnerTableHeading stickyFirstColumn">Invoice No</td>
                              <td className="vendorListInnerTableHeading">Sales Order No</td>
                              <td className="vendorListInnerTableHeading">Invoice Amount</td>
                              <td className="vendorListInnerTableHeading">Invoice Balance</td>
                           </tr>
                        </thead>
                        <tbody>
                            {lineItems.map((row,i)=>{
                                return (
                                    <tr className="vendorListInnerTable" key={row.lineId}>
                                        <td className="vendorListInnertableInfo stickyFirstColumn">{row.invoiceNo}</td>
                                        <td className="vendorListInnertableInfo">{row.salesOrderNo}</td>
                                        <td className="vendorListInnertableInfo">{row.totalAmount}</td>
                                        <td className="vendorListInnertableInfo">{row.invoiceBalance}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
            </td>
        );
    }
   const renderCusReceiptList=()=>{
        return cusReceiptCurrentPage?.map((data,i)=>{
            return(
              <React.Fragment key={data.receiptId}>
                <tr className="vendorList" >
                    <td className="vendorListInfo stickyFirstColumn">{data.receiptNo}</td>
                    <td className="vendorListInfo">{data.customerName}</td>
                    <td className="vendorListInfo">{data.paymentDate}</td>
                    <td className="vendorListInfo">{data.paymentType}</td>
                    <td className="vendorListInfo">{data.netTotal}</td>
                    <td className="vendorListInfo">{data.paymentAmount}</td>
                    <td className="vendorListInfo">{data.createdBy}</td>
                    <td className="vendorListInfoActions">
                        <img  alt="View Record" className="vendorActionImages" src={viewIcon}
                            title="View Receipt" onClick={(e)=>{
                                e.stopPropagation();
                                viewCusRecId(data.receiptId);
                                setSection("receiptDetail");
                                setApproval(false);}}/>
                        <img alt="Edit Record" className = "vendorActionImages" 
                        title={access==="Edit" || access ==="App/Dec" ? "Edit this Receipt" : "Access Denied"}
                        src={access === "Edit" || access === "App/Dec" ? editIcon : editIconDisabled}
                        style={access==="Edit" || access === "App/Dec" ? {cursor: "pointer"} : {cursor: "not-allowed"}}
                        onClick={(e)=>{
                            e.stopPropagation();
                            if(access==="Edit" || access === "App/Dec"){
                            editCusRecId(data.receiptId);
                            setSection("editReceipt");
                            setEditAndApprove(false);}}}/>
                        <img alt="Approve/Decline" className="vendorActionImages"
                            title={access==="App/Dec" ? "Approve/Decline this Receipt" : "Access Denied"}
                            src = {approveIcon}
                            style={access==="App/Dec" ? {cursor:"pointer"} : {cursor: "not-allowed"}}
                            onClick={(e)=>{
                                e.stopPropagation();
                                if(access==="App/Dec"){
                                    viewCusRecId(data.receiptId);
                                    setApproval(true);
                                    setSection("receiptDetail");}}}/>
                        <img alt="Toggle Expand" className="vendorActionImages"
                                title="Toggle Expand" src={data.collapse ? downIcon : upIcon}
                                onClick={(e)=>{e.stopPropagation();
                                editItemCollapse("receiptUnApprovedList",i);}}/>
                    </td>
                </tr>
                <tr>{!data.collapse ? <RenderSubTable tab="receiptUnApprovedList" lineItems={data.lineItems}/>:null}</tr>
              </React.Fragment>
            );
        })
   };
   const renderCusReceiptApprovedList=()=>{
       return cusReceiptApprovedCurrentPage?.map((data,i)=>{
        return(
              <React.Fragment key={data.receiptId}>
                    <tr className="vendorList">
                        <td className="vendorListInfo stickyFirstColumn">{data.receiptNo}</td>
                        <td className="vendorListInfo">{data.customerName}</td>
                        <td className="vendorListInfo">{data.paymentDate}</td>
                        <td className="vendorListInfo">{data.paymentType}</td>
                        <td className="vendorListInfo">{data.netTotal}</td>
                        <td className="vendorListInfo">{data.paymentAmount}</td>
                        <td className="vendorListInfo">{data.createdBy}</td>
                        <td className="vendorListInfo">{data.approvedBy}</td>
                        <td className="vendorListInfoActions">
                            <img  alt="View Record" className="vendorActionImages" src={viewIcon}
                                title="View Receipt" onClick={(e)=>{
                                    e.stopPropagation();
                                    viewCusRecId(data.receiptId);
                                    setSection("receiptDetail");
                                    setApproval(false);}}/>
                            <img alt="Toggle Expand" className="vendorActionImages"
                                title="Toggle Expand" src={data.collapse ? downIcon : upIcon}
                                onClick={(e)=>{e.stopPropagation();
                                editItemCollapse("receiptApprovedList",i);}}/>
                        </td>
                    </tr>
                    <tr>{!data.collapse ? <RenderSubTable tab="receiptApprovedList" lineItems={data.lineItems}/>:null}</tr>
              </React.Fragment>);
       });
   };
   const [order, setOrder] = useState({ col: "receiptId", state: true, data: "" });
   const CommonColumns=({data})=>{
    return(<>
    <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                      setOrder({
                        col: "receiptId",
                        state: !order.state,
                      });
                      handleSort({
                        data,
                        col: "receiptId",
                        state: order.state,
                      });
                    }}>Receipt Id <img
                    className="sortArrow"
                    src={
                         order.col === "receiptId"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "customerName",
                        state: !order.state,
                      });
                      handleSort({
                        data,
                        col: "customerName",
                        state: order.state,
                      });
                    }}>Customer Name<img
                    className="sortArrow"
                    src={
                         order.col === "customerName"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "paymentDate",
                        state: !order.state,
                      });
                      handleSort({
                        data,
                        col: "paymentDate",
                        state: order.state,
                      });
                    }}>Payment date<img
                    className="sortArrow"
                    src={
                         order.col === "paymentDate"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "paymentType",
                        state: !order.state,
                      });
                      handleSort({
                        data,
                        col: "paymentType",
                        state: order.state,
                      });
                    }}>Payment Type<img
                    className="sortArrow"
                    src={
                         order.col === "paymentType"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "netTotal",
                        state: !order.state,
                      });
                      handleSort({
                        data,
                        col: "netTotal",
                        state: order.state,
                      });
                    }}>Total Invoice Amount<img
                    className="sortArrow"
                    src={
                         order.col === "netTotal"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "paymentAmount",
                        state: !order.state,
                      });
                      handleSort({
                        data,
                        col: "paymentAmount",
                        state: order.state,
                      });
                    }}>Payment Amount Received<img
                    className="sortArrow"
                    src={
                         order.col === "paymentAmount"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                                    <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "createdBy",
                        state: !order.state,
                      });
                      handleSort({
                        data,
                        col: "createdBy",
                        state: order.state,
                      });
                    }}>Created By</td>
    </>)
   }
    const renderSubSection=()=>{
        
        switch(selectedTab){
            case "receiptUnApprovedList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader"  style={{zIndex:1}}>
                                    <CommonColumns data={cusReceiptCurrentPage}/>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                        <tbody>{cusReceiptList.length>0 ? renderCusReceiptList() :null }</tbody>
                        </table>
                        {cusReceiptList.length === 0 && !loading?
                        (<div className="noRecordsYet">No Records available</div>): (loading?<div className="loader" style={{ margin: "1rem auto" }}></div>:null)}
                    </React.Fragment>
                );

            case "receiptApprovedList":
                return(
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader"  style={{zIndex:1}}>
                                    <CommonColumns data={cusReceiptApprovedCurrentPage}/>
                                    <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "approvedBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: cusReceiptApprovedCurrentPage,
                        col: "approvedBy",
                        state: order.state,
                      });
                    }}>Approved By<img
                    className="sortArrow"
                    src={
                         order.col === "approvedBy"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                        <tbody>{cusReceiptApprovedList.length>0 ? renderCusReceiptApprovedList() :null }</tbody>
                        </table>
                        {cusReceiptApprovedList.length === 0 && !loading?
                        (<div className="noRecordsYet">No Records available</div>): (loading?<div className="loader" style={{ margin: "1rem auto" }}></div>:null)}
                    </React.Fragment>
                );

            default: 
            return(<div>Page yet to be Built</div>);
        }
    }

    const renderPagination=()=>{
        switch(selectedTab){
            case "receiptUnApprovedList":
                return(cusReceiptList.length >0   ?  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={cusReceiptList.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                  /> : null)
            case "receiptApprovedList":
                return(cusReceiptApprovedList.length > 0 ? <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={cusReceiptApprovedList.length}
                        pageSize={PageSize}
                        />:null)
        }
    }

    return(
        <React.Fragment>
            <div key={1} className="poListTabs">
                <RenderTabList tabList={tabList}/>
            </div>
    <div style={{ display:"inline-flex",width: "350px", marginLeft: "25px" }} className="purchaseOrderGrid">
        <span style={{flexGrow:1}}>
        <FormElement
          setInput={(value) => {
            setCustomerId(value);
          }}
          inputType={"options"}
          options={customerOptions}
          value={customerId}
          hintText={"Customer Name"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span style={{flexGrow:0, width:"80px"}}>
        <FormElement
          setInput={(value) => {
            setRowsPerPage(value);
          }}
          inputType={"number"}
          value={PageSize}
          hintText={"Rows Per Page"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
      </div>
            <div key={3} className="vendorListArea">{renderSubSection()}</div>
            {renderPagination()}
        </React.Fragment>
    )
}

const mapStateToProps=(state)=>{
    return { isLogged: state.isLogged }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        addTokenToState: (accessToken,employeeId)=>dispatch(addToken(accessToken,employeeId)),
        viewCusRecId: (cusRecId)=>dispatch(viewCustomerReceiptId(cusRecId)),
        editCusRecId: (cusRecId)=>dispatch(editCustomerReceiptId(cusRecId)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CustomerReceiptList);
