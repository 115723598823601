import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import downIcon from "../../../assets/down.svg";
import editIcon from "../../../assets/edit.svg";
import editIconDisabled from "../../../assets/edit_disabled.svg";
import upIcon from "../../../assets/up.svg";
import { editItemCategoryId } from "../../../redux/Item/ItemInfoActions";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";

const ItemCategoryList = ({
    // props
    setSection,

    // state
    isLogged,

    // dispatch
    // editPlant,
    editItemCategory,
    addTokenToState,

    setExistingList
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getItemCategory();
    }

    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);
    const [itemCategoryList, setItemCategoryList] = useState([]);
    const history = useHistory();

    async function checkToken() {
        console.log("Checking token");
        //   console.log(isLogged.accessToken);
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getItemCategory() {
        var data = await fetchData({
            requestingPage: "itemCategoryList",
            method: "get",
            url: "fetch/categories",
            headers: { token: isLogged.accessToken,module:"Item Category" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setItemCategoryList(data2);
            setExistingList(data2);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    const editCollapse = (index) => {
        var itemCategoryListCopy = itemCategoryList.slice();
        itemCategoryListCopy.forEach((plant, j) => {
            if (j === index) {
                itemCategoryListCopy[j].collapse = !plant.collapse;
            } else {
                itemCategoryListCopy[j].collapse = true;
            }
        });
        setItemCategoryList(itemCategoryListCopy);
    };

    const renderItemCategoryList = () => {
        return itemCategoryList.map((category, i) => {
            return (
                <React.Fragment key={i}>
                    <tr
                        className={category.collapse ? "vendorList" : "vendorListExpanded"}
                        key={category.category.customerId}
                        onClick={() => editCollapse(i)}
                    >
                        <td className="vendorListInfo">{category.category.categoryName}</td>
                        <td className="vendorListInfo">{category.category.description}</td>

                        <td className="vendorListInfo">{category.category.status}</td>
                        <td className="vendorListInfoActions">
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={isLogged.access["Item Category"] === "Edit" ? editIcon : editIconDisabled}
                                title={isLogged.access["Item Category"] === "Edit" ? "Edit Record" : "No Edit Access"}
                                onClick={(e) => {
                                    if (isLogged.access["Item Category"] === "Edit") {
                                        e.stopPropagation();
                                        // editPlant(category.category.plantId);
                                        // editCustomerId(category.category.customerId);
                                        editItemCategory(category.category.categoryId);
                                        setSection("editItemCategory");
                                    }
                                }}
                                style={isLogged.access["Item Category"] === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={category.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editCollapse(i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!category.collapse ? <RenderSubTable data={category.subCategory} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const RenderSubTable = ({ data }) => {
        console.log(data);
        return (
            <td colSpan="8" className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Item Subcategory</td>
                            <td className="vendorListInnerTableHeading">Description</td>
                            <td className="vendorListInnerTableHeading">Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.subCategoryId}>
                                    <td className="vendorListInnertableInfo">{row.subCategoryName}</td>
                                    <td className="vendorListInnertableInfo">{row.description}</td>
                                    <td className="vendorListInnertableInfo">{row.status}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    return (
        <div>
            <div className="vendorListArea">
                <table className="vendorListTable">
                    <thead>
                        <tr className="vendorListHeader">
                            <td className="vendorListHeading">Name</td>
                            <td className="vendorListHeading">Description</td>
                            <td className="vendorListHeading">Status</td>
                            <td className="vendorListHeadingActions">Action</td>
                        </tr>
                    </thead>
                    <tbody>{itemCategoryList.length > 0 ? renderItemCategoryList() : null}</tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>

            {/* </div> */}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editItemCategory: (itemCategoryId) => dispatch(editItemCategoryId(itemCategoryId)),
        // editPlant: (plantId) => dispatch(editPlantAction(plantId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCategoryList);
