import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import CreateOutward from "./CreateOutward";
import outwardIcon from "../../assets/outward.svg";
import OutwardList from "./OutwardList";
import OutwardDetail from "./OutwardDetail";
import EditOutward from "./EditOutward";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const Outward = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("outwardList");
    const [tab, setTab] = useState("createOutward");

    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);

    var outward = { buttonName: "Create Outward", className: "employeeButton", setSection: setCurrentSection, sectionName: "createOutward" };
    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "outwardList" };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "outwardDetail") }, sectionName: "outwardList" };

    const renderSection = (section) => {
        console.log(section);
        switch (section) {
            case "createOutward":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={outwardIcon} pageTitle="Create Outward" buttonList={[goBackButton]} />
                        <CreateOutward setSection={setCurrentSection} />
                    </React.Fragment>
                );

            case "outwardList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={outwardIcon} pageTitle="Outward List"
                            buttonList={isLogged.access["Outward"] === "Create" || isLogged.access["Outward"] === "Edit" || isLogged.access["Outward"] === "App/Dec" ? [outward] : null} />
                        <OutwardList
                            setTab={setTab}
                            setSection={setCurrentSection}
                            setApproval={(value) => setApprovalPage(value)}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "outwardDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={outwardIcon} pageTitle="Outward Detail" buttonList={[goBackButton, downloadButton]} />
                        <OutwardDetail approval={approvalPage} setSection={setCurrentSection} setEditAndApprove={setEditAndApprove} />
                    </React.Fragment>
                );

            case "editOutward":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={outwardIcon} pageTitle="Edit Outward" buttonList={[goBackButton]} />
                        <EditOutward approval={approvalPage} setSection={setCurrentSection} editApprove={editAndApprove} />
                    </React.Fragment>
                );

            default:
                return <span>{`No ${section} page created yet`}</span>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Store" selectedSubMenu="Outward" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged }
}

export default connect(mapStateToProps, null)(Outward);
