import React, { Fragment, useCallback, useEffect, useState } from "react";
import { renderFormElements } from "components/SmallComponents/FormElement";
import CreateEditModal from "components/SmallComponents/CreateEditModal";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import { connect } from "react-redux";
import InputField from "components/SmallComponents/InputField";
import update from "immutability-helper";
import { useHistory } from "react-router-dom";
import FetchNewToken from "serverCall/FetchNewToken";
import fetchData from "serverCall/fetchData";
import { validateMandatory } from "components/CommonFunctions/ValidateFields";


const CreateOtherCharges = ({ setSection, addTokenToState, isLogged }) => {
    const history = useHistory();
    const [filteredBundleLineItems, setFilteredBundleLineItems] = useState([])
    const [totalDetails, setTotalDetails] = useState({ totalQuantity: 0, totalWeight: 0, netAmount: 0 })
    const [roundedOff, setRoundedOff] = useState(0)
    const [otherChargesParams, setOtherChargesParams] = useState({
        packingSlipId: {
            inputType: "options",
            value: "",
            options: [],
            hintText: "Packing Slip",
            colSpan: 4,
            error: false,
            errorMessage: "Please select a Packing Slip No",
            mandatory: true,
        },
        ocDate: {
            inputType: "dateFromEditPage",
            value: "",
            colSpan: 4,
            error: false,
            errorMessage: "Please select Other Charges Date",
            hintText: "Other Charges Date",
            mandatory: true,
        }
    });
    const [sectionLineItems, setSectionLineItems] = useState([]);
    const [errors, setErrors] = useState([]);

    const checkToken = useCallback(async () => {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            //console.log("Token not changed");
        }
    }, [isLogged, addTokenToState, history]);

    const getPackingSlipList = useCallback(async () => {
        let data = await fetchData({
            url: "store-fetch/packing-slip",
            method: "post",
            requestingPage: "soList",
            headers: { token: isLogged.accessToken, module: "Other Charges" }
        });

        if (data.msg === "success") {
            return data.asset;
        } else {
            return [];
        }
    }, [isLogged])
    const getInformation = useCallback(async () => {
        await checkToken();
        await getPackingSlipList().then((optionList) => {
            let otherChargeParamsCopy = Object.assign(otherChargesParams);
            otherChargeParamsCopy = update(otherChargeParamsCopy, { packingSlipId: { options: { $set: optionList } } });
            setOtherChargesParams(otherChargeParamsCopy);
        })
    }, [])
    useEffect(() => {
        getInformation();
    }, [])
    const checkErrors = () => {
        let otherChargeParamsCopy = Object.assign(otherChargesParams);
        let errorList = [];
        Object.keys(otherChargeParamsCopy).forEach((key) => {
            //check if param is mandatory
            if (otherChargeParamsCopy[key].mandatory) {
                otherChargeParamsCopy = update(otherChargeParamsCopy, {
                    [key]: { error: { $set: !validateMandatory(otherChargeParamsCopy[key].value.toString()) } }
                });
                //check if it has error
                if (otherChargeParamsCopy[key].error) {
                    errorList.push(otherChargeParamsCopy[key].errorMessage);
                }

            }
        });

        if (filteredBundleLineItems.length == 0 && filteredBundleLineItems.every((line) => line.rate > 0)) {
            errorList.push("Send bundle list with valid rate");
        }
        if (errorList.length === 0) {
            let dataToServer = {
                packingSlipId: otherChargeParamsCopy.packingSlipId.value,
                ocDate: otherChargeParamsCopy.ocDate.value,
                lineItems: filteredBundleLineItems,
                roundedOff
            }
            submitData(dataToServer);
        }
        setErrors(errorList);
        setOtherChargesParams(otherChargeParamsCopy);
    }

    const submitData = async (data) => {
        setIsOpen(true);
        setModalText("Uploading Content...Please Wait");
        setDataSubmitted(false);
        let response = await fetchData({
            url: "so-create/other-charges",
            requestingPage: "OtherChargesList",
            method: "post",
            headers: { token: isLogged.accessToken, module: "Other Charges" },
            data
        })
        setDataSubmitted(true);
        if (response.msg === "success") {
            setModalText("Succesfully created")
        } else {
            setModalText(response.desc);
            setErrors([response.desc]);
        }
    }
    const renderErrorMessage = () => {
        if (errors.length > 0) {
            return errors[0];
        }
    }
    const updateLineItems = (value, index) => {
        let lineCopy = filteredBundleLineItems.slice();
        lineCopy[index].rate = value;
        lineCopy[index].totalAmount = value * lineCopy[index].totalQuantity

        let netAmount = 
         lineCopy.reduce((acc, val) => acc += val.totalAmount || 0, 0) + 
         sectionLineItems.reduce((acc,val)=>acc+= val.quantity * (val.premiumCharge||0),0)+roundedOff ;
        setFilteredBundleLineItems(lineCopy);
        setTotalDetails((prev) => ({ ...prev, netAmount }));

    }
    //function for calculate total weight and total quantity and eliminating duplicate bundles
    const calculatingTotalQuantityAndWeight = (forBundle) => {
        //adding bundleId as key for new object for eliminating duplicate bundles
        const filteredValue = forBundle.reduce((acc, val) => {
            if (acc[val.bundleId]) {
                acc[val.bundleId] = {
                    ...acc[val.bundleId],
                    //calculating total weight and total quantity by adding both quantity and weight 
                    // of duplicated bundles 
                    totalQuantity: val.quantity + acc[val.bundleId].totalQuantity,
                    totalWeight: val.weight + acc[val.bundleId].totalWeight
                }
            } else {
                //return the quantity and weight when there is no duplicates
                acc[val.bundleId] = {
                    ...val,
                    totalQuantity: val.quantity,
                    totalWeight: val.weight
                }
            }
            return acc
        }, {})


        const result = Object.values(filteredValue)
        setFilteredBundleLineItems(result)

        //for displaying total netAmount, total weight and quantity of total bundles  
        const totals = result.reduce((accumulator, item) => {
            accumulator.totalWeight += item.totalWeight;
            accumulator.totalQuantity += item.totalQuantity;
            return accumulator;
        }, { totalWeight: 0, totalQuantity: 0 })
        setTotalDetails({ ...totals, netAmount: 0 })
    }


    const updateOtherChargeParams = ({ value, paramName, key }) => {
        let otherChargeParamsCopy = Object.assign(otherChargesParams);
        otherChargeParamsCopy = update(otherChargeParamsCopy, { [paramName]: { [key]: { $set: value } } });
        setOtherChargesParams(otherChargeParamsCopy);
    }
    const getPackingSlipDetails = async () => {
        let dataDetail = await fetchData({
            requestingPage: "customerList",
            method: "post",
            url: "store-fetch/packing-slip-detail/" + otherChargesParams.packingSlipId.value,
            headers: { token: isLogged.accessToken, module: "Other Charges" }
        });

        if (dataDetail.msg === "success") {
            return dataDetail.asset.packingSlipLineItems;
        } else {
            return [];
        }
    }
    useEffect(() => {
        if (otherChargesParams.packingSlipId.value) {
            getPackingSlipDetails().then((bundleWithDuplicates) => {
                setSectionLineItems(bundleWithDuplicates);
                calculatingTotalQuantityAndWeight(bundleWithDuplicates)

            })
        } else {
            setSectionLineItems([]);
            setFilteredBundleLineItems([])
        }
    }, [otherChargesParams.packingSlipId.value])

    const renderBundles = () => {
        return (

            <table className="createVendorContactsTable" style={{ padding: "20px" }}>
                <thead>
                    <tr className="createVendorContactsTableHeader">
                        <td>Bundle No</td>
                        <td>Total Quantity</td>
                        <td>Total Weight</td>
                        <td>Rate</td>
                        <td>Total Amount</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredBundleLineItems.map((line, index) => (
                            <tr className="createVendorContactsTableRows" key={index} style={{ height: "50px" }}>
                                <td>{line.bundleId.toString().padStart(6, "0")}</td>
                                <td>{line.totalQuantity}</td>
                                <td>{line.totalWeight}</td>
                                <td>
                                    <InputField
                                        type="float"
                                        boxHeight={"30px"}
                                        boxWidth={"80px"}
                                        value={line.rate || ""}
                                        setName={(value) => updateLineItems(value, index)}
                                    />
                                </td>
                                <td>{line.totalAmount || 0}</td>
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>
        );
    }

    const renderSections = () => {
        return (
            <table className="createVendorContactsTable" style={{ padding: "20px" }}>
                <thead>
                    <tr className="createVendorContactsTableHeader">
                        <td>Section No</td>
                        <td>Section Name</td>
                        <td>Weight</td>
                        <td>Quantity</td>
                        <td>Premium Charge</td>
                        <td>Amount</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        sectionLineItems.map((line, index) => (
                            <tr className="createVendorContactsTableRows" key={index} style={{ height: "50px" }}>
                                <td>{line.sectionNo}</td>
                                <td>{line.sectionName}</td>
                                <td>{line.weight}</td>
                                <td>{line.quantity}</td>
                                <td>{line.premiumCharge}</td>
                                <td>{line.quantity * (line.premiumCharge||0)}</td>
                                
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>
        )
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading data. Please wait...");
    const submitOkClick = () => {
        setIsOpen(false);
        if (errors.length === 0) {
            setSection("otherChargesList");
        }
    }

    const getRoundedOffValue = (value) => {
        if (!isNaN(value)) {
            setRoundedOff(value)
        } else {
            setRoundedOff(0)
        }
    }

    return (
        <Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "1000px",
                        margin: "0 auto 2rem",
                        // padding: "3rem 3rem 1rem 3rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}>
                    <div style={{ display: "flex" }}>
                        <div
                            className="createPurchaseOrderGrid"
                            style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
                            {renderFormElements({
                                elementList: ["packingSlipId", "ocDate"], param: otherChargesParams,
                                setInput: updateOtherChargeParams
                            })}
                        </div>
                    </div>
                    <div className='purchaseOrderSubSectionArea'>
                        {renderBundles()}
                    </div>
                    <div className='purchaseOrderSubSectionArea'>
                        {renderSections()}
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end" }} >
                        <div className="poNetTotal">
                            <div className="poTotalTitle">
                                <span>
                                    <b>Rounded Off</b>
                                </span>
                                <span className="poNetTotalAmount">
                                    <InputField
                                        type="roundoff"
                                        value={roundedOff}
                                        setName={(value) =>
                                            getRoundedOffValue(value)
                                        }
                                        align="end"
                                        error={""}
                                    />
                               </span>
                            </div>

                          <div className="poTotalTitle">
                                <span>
                                    <b>Total Quantity</b>
                                </span>
                                <span className="poNetTotalAmount">{totalDetails.totalQuantity}</span>
                            </div>
                            <div className="poTotalTitle">
                                <span>
                                    <b>Total Weight</b>
                                </span>
                                <span className="poNetTotalAmount">{totalDetails.totalWeight}</span>
                            </div>
                            <div className="poTotalTitle">
                                <span>
                                    <b>Total Amount</b>
                                </span>
                                <span className="poNetTotalAmount">{totalDetails.netAmount}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                    <button className="submitButton" onClick={() => { setErrors([]); checkErrors(); }}>
                        Submit
                    </button>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    isLogged: state.isLogged
})

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOtherCharges);