import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import fetchData from "../../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import editIcon from "../../../assets/edit.svg";
import editIconDisabled from "../../../assets/edit_disabled.svg";
import { editItemCurrencyId } from "../../../redux/Item/ItemInfoActions";

const ItemCurrencyList=({isLogged,setSection,editItemCurrency,addTokenToState})=>{
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getCurrencyList();
    }

    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(false);
    const [itemCurrencyList, setItemCurrencyList] = useState([]);
    const history = useHistory();

    async function checkToken() {
        console.log("Checking token");
        //   console.log(isLogged.accessToken);
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    };

    async function getCurrencyList(){
        var data = await fetchData({
            requestingPage: "itemTypeList",
            method: "get",
            url: "fetch/item-currency-list",
            headers: { token: isLogged.accessToken,module:"Item Currency" },
        });
        if (data.msg === "success") {
            setItemCurrencyList(data.asset);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    
    const renderItemCurrencyList=()=>{
        return itemCurrencyList.map((row)=>{
            return(
                <tr className="roleList" key={row.itemCurrencyId}>
                    <td className="roleName">{row.itemCurrencyCountry}</td>
                    <td className="roleName">{row.currencyName}</td>
                    <td className="roleName">{row.itemCurrency}</td>
                    <td className="roleAction">
                        <img
                            className="roleEditImage"
                            src={isLogged.access["Item Currency"] === "Edit" ? editIcon : editIconDisabled}
                            alt="Edit Currency"
                            onClick={() => {
                                if (isLogged.access["Item Currency"] === "Edit") {
                                    editItemCurrency(row.itemCurrencyId);
                                    setSection("editItemCurrency");
                                }
                            }}
                            title={isLogged.access["Item Currency"] === "Edit" ? "Edit Record" : "No Edit Access"}
                            style={isLogged.access["Item Currency"] === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                        />
                    </td>
                </tr>
            );
        });
    };

    return(
        <div className="rolePagesContainer">
            <div className="roleListArea">
                <table className="roleListTable">
                    <thead>
                    <tr className=" roleListHeader">
                        <td className="roleNameHeader">
                            <span>Country Name</span>
                        </td>
                        <td className="roleNameHeader">
                            <span>Currency Name</span>
                        </td>
                        <td className="roleNameHeader">
                            <span>Currency Value</span>
                        </td>
                        <td className="roleActionHeader">
                            <span>Action</span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {itemCurrencyList.length > 0 ? renderItemCurrencyList() : null}
                    </tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editItemCurrency: (itemTypeId) => dispatch(editItemCurrencyId(itemTypeId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};


export default connect(mapStateToProps,mapDispatchToProps)(ItemCurrencyList);