import React, { useState } from "react";
import { useHistory } from "react-router";
import MenuList from "../../Menu/MenuList";
import PageTitle from "../../SmallComponents/PageTitle";
import TopBanner from "../../SmallComponents/TopBanner";
import priceTypeIcon from "../../../assets/pricetype_black.svg";
import CreateItemPriceType from "./CreateItemPriceType";
import ItemPriceTypeList from "./ItemPriceTypeList";
import EditItemPriceType from "./EditItemPriceType";
import { connect } from "react-redux";

const ItemPriceType = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("itemPriceTypeList");

    var history = useHistory();

    const pushBack = (section) => {
        history.push(section);
    };

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "itemPriceTypeList" }];

    var goBackToItemButton = {
        buttonName: "Back",
        className: "goBackButton",
        setSection: pushBack,
        sectionName: "/itemMenu",
    };

    var createItemPriceTypeButton = {
        buttonName: "Create Price Type",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createPriceType",
    };

    const renderSection = (section) => {
        console.log(section);
        switch (section) {
            case "itemPriceTypeList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={priceTypeIcon}
                            pageTitle="Item Price Type List"
                            buttonList={
                                isLogged.access["Item Price Type"] === "Create" || isLogged.access["Item Price Type"] === "Edit"
                                    ? [createItemPriceTypeButton, goBackToItemButton]
                                    : [goBackToItemButton]
                            }
                        />
                        <ItemPriceTypeList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createPriceType":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={priceTypeIcon} pageTitle="Create Price Type" buttonList={goBackButton} />
                        {/* <CreatePurchaseCost setSection={(value) => setCurrentSection(value)} /> */}
                        <CreateItemPriceType setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editItemPriceType":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={priceTypeIcon} pageTitle="Edit Price Type" buttonList={goBackButton} />
                        <EditItemPriceType setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <h1>Page not yet created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(ItemPriceType);
