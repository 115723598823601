import React, { Fragment,useState } from 'react'
import { useEffect } from 'react'
const ProductionReport = ({formType,data,bundleLineItems=[],paramsDetail}) => {
    
    formType=formType?formType:"production-detail-date"
    let {dateFrom,toDate,poNo}= paramsDetail;
    const [productionDetail,setProductionDetail] = useState([])
    const [lineCopy,setLineCopy] = useState([])
    useEffect(()=>{
        let lineItems=[]
    
      if(data?.plantName && bundleLineItems && bundleLineItems[0]?.bundleId){
        let copy = [];
        bundleLineItems.forEach((row,i)=>{
            copy.push(
                {
            ...row,
           serialNo:i+1,
           bundleNo: row.bundleId.toString().padStart(6, '0'),
           avgWeight:(Number(row.netWeight)/Number(row.pcs)).toFixed(2),
           id:i
             })
             let array = copy[copy.length-1]
             let begin=array.id
             let end=i+1;
             if(["production-detail-section","production-detail-bundle","production-detail-date"].includes(formType) 
                               && row?.sectionNo!==bundleLineItems[i+1]?.sectionNo && i+1<=bundleLineItems.length)
             {
                    copy.push({
                        bundleNo:bundleLineItems.slice(begin,end).length,
                        pcs:bundleLineItems.slice(begin,end).reduce((acc,val)=>{return acc+=Number(val.pcs)},0).toFixed(2),
                        netWeight:bundleLineItems.slice(begin,end).reduce((acc,val)=>{return acc+=Number(val.netWeight)},0).toFixed(2),
                        avgWeight:bundleLineItems.slice(begin,end).reduce((acc,val)=>{return acc+=Number(Number(row.netWeight)/Number(row.pcs))},0).toFixed(2),
                        id:i
                        
                    })
            }
            if(formType==="production-detail-date" && data.bundleLineItems[i+1]?.bundleDate!==row.bundleDate && i+1<=data.bundleLineItems.length){
                let totalCount = data.bundleLineItems.filter(item=>item.bundleDate===row.bundleDate)
                copy.push({
                bundleNo:totalCount.length,
                pcs:totalCount.reduce((acc,val)=>{return acc+=Number(val.pcs)},0).toFixed(2),
                netWeight:totalCount.reduce((acc,val)=>{return acc+=Number(val.netWeight)},0).toFixed(2),
                avgWeight:totalCount.reduce((acc,val)=>{return acc+=Number(Number(row.netWeight)/Number(row.pcs))},0).toFixed(2),
                id:i

              })
            }
        }
          
        )
        lineItems=copy;
      }
      else{
        if(data.plantName){
          
            let result = bundleLineItems?.map((row,i)=>({
                ...row,
               serialNo:i+1,
               avgWeight:(Number(row.netWeight)/Number(row.pcs)).toFixed(2),
               id:i
                 }))
                //  setProductionDetail(result)
                 lineItems=result
        }

      }
      
      splitLineItems(lineItems);
      setLineCopy(lineItems);
    },[data,bundleLineItems])

    const splitLineItems = (TableData) => {
        let lineCopy = [];
        for (let i = 0; i < TableData?.length; i += 20) // 20 items in each page
        {
          lineCopy.push(TableData.slice(i, i + 20));
        }
        setProductionDetail(lineCopy);
      }

    const RenderMainSection=()=>{
        return(
            <table  className="invoiceBill" style={{width:"100%"}}>
                <tbody>
                    <tr>
                        <td style={{    display: "flex",flexDirection: "column",flexWrap: "wrap",alignContent: "center"}}>
                            <div className="vendorSummary" style={{ maxWidth: "max-content", margin: "0", display: "inline-flex", verticalAlign: "top" }}>
                                <div className="poSummaryText" style={{ maxWidth: "max-content" }}>    
                                    <span className="vendorSummaryCategory" style={{ color: "#000", textTransform: "capitalize",textAlign:"center" }}><h2>{data?.plantName||""}</h2></span>
                                    <span className="vendorSummaryCategory" style={{ color: "#000",textAlign:"center" }}>{`${data?.city}-${data?.pincode}`}</span>
                                    <span className="vendorSummaryCategory" style={{ color: "#000",textAlign:"center" }}>{data?.gstNo}</span>
                                    <div className="vendorSummaryCategory" style={{ color: "#000",textAlign:"center" }}>{new Date().toLocaleTimeString()}</div>
                                    <div className="vendorSummaryCategory" style={{ color: "#000",textAlign:"start" }}>{`PoNo:${poNo}`}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                  
                </tbody>
            </table>
        )
       }

    const renderDetailPage=(formType)=>{
        switch(formType){
            case "production-detail-section" :
              case  "production-detail-bundle":
                                   return [{label:"Bundle No",key:"bundleNo"},{label:"Sec.No",key:"sectionNo"},{label:"Section Name",key:"sectionName"},{label:"Size",key:"cuttingLength"}]
              case "production-detail-date" :
                                   return [{label:"Date",key:"bundleDate"},{label:"Bundle No",key:"bundleNo"},{label:"Sec.No",key:"sectionNo"},{label:"Section Name",key:"sectionName"},{label:"Size",key:"cuttingLength"}]


            case "section-wise" : return [{label:"Sec.No",key:"sectionNo"},{label:"Section Name",key:"sectionName"},{label:"C/L",key:"cuttingLength"},{label:"Bundles",key:"bundles"}]
                                        

            case "ageing-no" : return [{label:"Ageing No",key:"agNo"},{label:"Bundles",key:"bundles"}]
                                        

            case "cutting-length" : return  [{label:"CL",key:"cuttingLength"},{label:"Bundles",key:"bundles"}]
                                       

            case "po-customer" : return [{label:"PO",key:"soNo"},{label:"Customer",key:"customerName"},{label:"Bundles",key:"bundles"}]
                                       

            case "po" : return [{label:"PO",key:"soNo"},{label:"Bundles",key:"bundles"}]

            case "production-abstract" : return [{label:"Date",key:"bundleDate"},{label:"Bundles",key:"bundles"}]
                                          
                                          
           default  :return null 
        }
       }

       const renderTableRows =(tableCols,productionDetail)=>{
            return  productionDetail?.map((item,i)=>{
                       return ( 
                        // <>
                       <tr className="vendorDetailContactsTableRows" key={i}>                     
                                    <td>{item?.serialNo}</td>
                                    {tableCols.map((row,index)=>{
                                    return (
                                    <td key={index}>
                                            {item[row.key]?item[row.key] :""}
                                        </td>
                                        
                                      )
                                    })}
                                     <td style={{textAlign: "end"}}>{item.pcs}</td>
                                        <td style={{textAlign: "end"}}>{item.netWeight}</td>
                                        <td style={{textAlign: "end"}}>{
                                            item.avgWeight
                                        // (Number(item.netWeight)/Number(item.pcs)).toFixed(2)
                                        }</td>
                                        {["production-detail-section","po-customer","production-detail-date"].includes(formType)?
                                         <td>{formType==="po-customer"? item.PoQty : item.Remark}</td>:null
                                     }
                              </tr>
                               ) 
                       
                       })
       }

       const RenderTable=({singlePageLineItems,pageNumber})=>{
        let tableCols = renderDetailPage(formType )
        return(
            <>
         <table className="innerTable" style={{width:"100%",height:"90%"}}>
             <thead>
                 <tr className="invoiceDetailContactsTableHeader" style={{color: "#000"}}>
                     <td>S. No</td>
                     {tableCols.map((cols,i)=><td key={i}>{cols.label}</td>)}
                     <td>PCS</td>
                     <td>Net.Weight</td>
                     <td>Avg.weight</td>
                     {["production-detail-section","po-customer","production-detail-date"].includes(formType)?
                        <td>{formType==="po-customer"? "PO Qty" : "Remark"}</td>:null

                     }
                 </tr>
             </thead>
             <tbody>
                 {renderTableRows(tableCols,singlePageLineItems)}
                {!["production-detail-section","production-detail-bundle","production-detail-date"].includes(formType) && productionDetail.length?
                <tr>
                <td></td>
                 <td colSpan={formType==="section-wise"?3:formType==="po-customer"?2:1}>Total</td>
                 <td style={{textAlign:"end"}}>{lineCopy?.reduce((acc,line)=>acc+Number(line.bundles),0).toFixed(2)}</td>
                 <td style={{textAlign:"end"}}>{lineCopy?.reduce((acc,line)=>acc+Number(line.pcs),0).toFixed(2)}</td>
                 <td style={{textAlign:"end"}}>{lineCopy?.reduce((acc,line)=>acc+Number(line.netWeight),0).toFixed(2)}</td>
                 <td style={{textAlign:"end"}}>{lineCopy?.reduce((acc,line)=>acc+Number(line.avgWeight),0).toFixed(2)}</td>
                </tr>
                :null}
                <tr className="invoiceDetailContactsTableRows">
                <td colSpan={10} style={{textAlign:"right"}}>
                {pageNumber === productionDetail.length ? "End" : `Continued To Page Number ${pageNumber+1}`}
              </td>
            </tr>
             </tbody>
         </table>
             </>
        )
        }

    return (
        <Fragment>
            <div className="detailsPageWrapper">
                {
                    productionDetail?.map((currPage, pageIndex) => (
                            <Fragment key={pageIndex}>
                                <div className="detailsPageContainer">
                                    <div style={{ border: "0.25px solid black", minHeight: "1100px", position: "relative" }}>
                                        {
                                            <RenderMainSection />
                                        }
                                        <RenderTable 
                                        singlePageLineItems={currPage} pageNumber={pageIndex + 1} 
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        ))
                }
            </div>
        </Fragment>
    )
}

export default ProductionReport