import React,{useState,useEffect} from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import FetchNewToken from "../../serverCall/FetchNewToken";
import deleteIcon from "../../assets/delete.svg";
import BomModal from "./bomModal";
import plusCircle from '../../assets/circle-plus-custom.svg';
import editIcon from '../../assets/edit.svg';
import bomTree from '../../assets/bomTree2.svg';
import { getUom } from "serverCall/fetchData";

const EditBom=({isLogged,addTokenToState,setSection,poInfo})=>{
    const history=useHistory();
    const [errors,setErrors]=useState([]);
    const [allItemUomList,setAllItemUomList] = useState([]);
    const [finishedGoodsALTItems,setFinishedGoodsALTItems] = useState([]);
    const [bomDetail,setBomDetail] = useState({});
    const [storeDetails,setStoreDetails]=useState({
        documentName:{
          inputType: "text",
          value: "",
          hintText: "Document Name",
          mandatory: true,
          colSpan: 4,
          error: false,
          errorMessage: "Document Name is Mandatory"
        },
          fgStore: {
              inputType: "options",
              value: "",
              hintText: "Finished Good Store",
              mandatory: true,
              colSpan: 4,
              error: false,
              options: [],
              errorMessage: "Please select a  Finished Goods Store",
          },
          rmStore: {
              inputType: "options",
              value: "",
              hintText: "Raw Materials Store",
              mandatory: true,
              colSpan: 4,
              error: false,
              options: [],
              errorMessage: "Please select a Raw Materials Store",
          },
          scrapStore: {
              inputType: "options",
              value: "",
              hintText: " Scrap/Reject Store",
              mandatory: true,
              colSpan: 4,
              error: false,
              options: [],
              errorMessage: "Please select a Scrap Store",
          }
      });
      const template = {
        itemId: {
            inputType: "options",
            value: "",
            hintText: "Item ",
            mandatory: true,
            colSpan: 5,
            error: false,
            options: [],
            errorMessage: "Please select Item",
          },
          from: {
            inputType: "text",
            value: "",
            hintText: "Weight From(gms)",
            mandatory: false,
            colSpan: 5,
            error: false,
            errorMessage: "",
            disabled: true,
          },
          to: {
            inputType: "text",
            value: "",
            hintText: "Weight To(gms)",
            mandatory: false,
            colSpan: 5,
            error: false,
            errorMessage: "",
            disabled: true,
          },
          netWeight: {
            inputType: "text",
            value: "",
            hintText: "Net Weight(gms)",
            mandatory: false,
            colSpan: 5,
            error: false,
            errorMessage: "",
            disabled: true,
          },
          grossWeight: {
            inputType: "text",
            value: "",
            hintText: "Gross Weight(gms)",
            mandatory: false,
            colSpan: 5,
            error: false,
            errorMessage: "",
            disabled: true,
          },
          uomId: {
            inputType: "options",
            value: "",
            hintText: "UOM",
            mandatory: true,
            colSpan: 5,
            error: false,
            options: [],
            errorMessage: "Please select UOM",
            serverCall: true,
          },
          quantity: {
            inputType: "float",
            value: "",
            hintText: "Quantity",
            mandatory: true,
            colSpan: 5,
            error: false,
            errorMessage: "Please enter a valid Quantity",
          },
          allocation:{
            inputType: "float",
            value:"",
            hintText: "Cost Allocation (%)",
            mandatory: true,
            colSpan: 5,
            error: false,
            errorMessage: "Please Enter Cost Allocation in Percentage"
          },
          lineItemId: {value: null} 
    };
    const [fgProcess,setFgProcess] = useState({
      fgProcess: {
        inputType: "options",
        value: "",
        hintText: "Finished Good Process",
        mandatory: true,
        colSpan: 4,
        error :false,
        options: [],
        errorMessage: "Please Select a FG Process",
      }
    })
    const [finishedGoods,setFinishedGoods]=useState({...template});
    const [rawMaterials,setRawMaterials]=useState({...template,tempRawALTLineItems:[],childBOM:{fgItemId: "",childBOMObject:{}}});
    const [rawMaterialLineItems,setRawMaterialLineItems] = useState([]);
    const [scrapGoods,setScrapGoods] = useState({...template});
    const [scrapLineItems,setScrapLineItems] = useState([]);
    const [fgProcessItems,setFgProcessItems] = useState([]);
    useEffect(()=>{
        getInformation();
    },[])
    async function getProcesses() {
      var data = await fetchData({
          requestingPage: "itemProcessList",
          method: "get",
          url: "fetch/item-processes",
          headers: { token: isLogged.accessToken,module:"Item Process" },
      });
      if (data.msg === "success") {
        let options = data.asset.map(({processId,processName})=>({optionId:processId,optionName:processName}))
         return options;
      } else {
          console.log(data);
          return [];
      }
  }
    async function getInformation(){
      let detailObject=await getBOMDetail();
      
      if(Object.keys(detailObject).length===0)
      setSection('billOfMaterialList');
      var paramsCopy1 = Object.assign(finishedGoods);
      var paramsCopy2 = Object.assign(rawMaterials);
      var paramsCopy3 = Object.assign(scrapGoods);
      var paramsCopy4 = Object.assign(fgProcess);
      var processList = await getProcesses();

      var [itemListCopy, itemUomList] = await getUom(isLogged);
        await checkToken()
       
        paramsCopy1 = update(paramsCopy1, {
                          itemId: { options: { $set: itemListCopy },value:{ $set: detailObject.finishedGoods.itemId}},
                          from: {value: {$set: detailObject.finishedGoods.from}},
                          to: {value: {$set: detailObject.finishedGoods.to}},
                          netWeight: {value: {$set: detailObject.finishedGoods.netWeight}},
                          grossWeight: {value: {$set: detailObject.finishedGoods.grossWeight}},
                          uomId: { value: { $set: detailObject.finishedGoods.uomId } },
                          quantity: {value: {$set: detailObject.finishedGoods.quantity} },
                          allocation: {value: {$set: detailObject.finishedGoods.allocation} }
        });
        paramsCopy2 = update(paramsCopy2,{
          itemId: {options: {$set: itemListCopy} },
          uomId: {value: {$set: ""}}
        })
        paramsCopy3 = update(paramsCopy3,{
          itemId: {options: {$set: itemListCopy} },
          uomId: {value: {$set: ""}}
        })
        paramsCopy4 = update(paramsCopy4,{
          fgProcess: {options: {$set: processList}}
        })
        setAllItemUomList(itemUomList);
        setFinishedGoods(paramsCopy1);
        setRawMaterials(paramsCopy2);
        setScrapGoods(paramsCopy3);
        setFgProcess(paramsCopy4);
        setFgProcessItems(detailObject.storeDetails.fgProcessItems || []);
        setFinishedGoodsALTItems(detailObject.finishedGoods.finishedGoodsALTItems)
        setRawMaterialLineItems(detailObject.rawMaterialLineItems);
        setScrapLineItems(detailObject.scrapLineItems || []);
        let fgStoreOptions = await getStoreByTypes('FinishedGoods Store')
        let rmStoreOptions = await getStoreByTypes('RawMaterials Store');
        let scrapStoreOptions = await getStoreByTypes('Scrap/Reject Store');

        let storeCopy = Object.assign(storeDetails);
        storeCopy = update(storeCopy,{ documentName: {value: {$set:detailObject.storeDetails.bomDocumentName } }, 
                                      fgStore: {options: {$set: fgStoreOptions},value:{$set: detailObject.storeDetails.fgStore}},
                                      rmStore: {options: {$set: rmStoreOptions},value:{$set: detailObject.storeDetails.rmStore} },
                                      scrapStore:{options: {$set: scrapStoreOptions},value:{$set: detailObject.storeDetails.scrapStore} } });
          setStoreDetails(storeCopy);
    }

    const getStoreByTypes=async(storeType)=>{
      let result = await fetchData({
        requestingPage: "BillOfMaterial",
        url: "bill-of-material/get-store-by-type",
        method: "post",
        data:{storeType },
        headers: { token: isLogged.accessToken, module: "Bill Of Material" },
      });

      if(result && result.msg==='success'){
        return result.asset.map((item)=>({optionId: item.storageId,optionName: item.storageName}));
      }else{
        return [];
      }
    }
    async function getBOMDetail(){
      var detail = await fetchData({
        url : 'bill-of-material/get-bom-detail',
        headers: {token: isLogged.accessToken, module: "Bill Of Material"},
        requestingPage: "BillOfMaterial",
        method: "post",
        data: {bomId:poInfo.editBomId}
      });

      if(detail && detail.msg==='success'){
        setBomDetail(detail.asset);
        return detail.asset;
      }else{
        console.log(detail);
        return {};
      }
    }
  
    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
          history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          console.log("New Token");
          addTokenToState(isLogged.employeeId, token2);
        } else {
          console.log("Token not changed");
        }
      }

      const updateParams=({section,paramName,key,value})=>{

        switch(section){

            case "general":
                let storeCopy = Object.assign(storeDetails);
                storeCopy = update(storeCopy,{[paramName]: {[key]: {$set: value} } });
                setStoreDetails(storeCopy);
                break;
                
            case "finishedGoods":
                let fgCopy = Object.assign(finishedGoods);
                fgCopy = update(fgCopy,{[paramName]: {[key]: {$set: value} } });
                setFinishedGoods(fgCopy);
                break;

            case "rawMaterials":
                let rawMaterialsCopy = Object.assign(rawMaterials);
                rawMaterialsCopy = update(rawMaterialsCopy,{[paramName]: {[key]: {$set: value} } });
                setRawMaterials(rawMaterialsCopy);
                break;

            case "scrapGoods":
                let scrapCopy = Object.assign(scrapGoods);
                scrapCopy = update(scrapCopy,{[paramName]: {[key]: {$set: value} } });
                setScrapGoods(scrapCopy);
                break;
            case "fgProcess":
              let fgProcessCopy = Object.assign(fgProcess);
              fgProcessCopy = update(fgProcessCopy,{[paramName]: {[key]: {$set: value} } });
              setFgProcess(fgProcessCopy);
              break;

                default:
                    document.write("Section Not Specified");
        }
    }

      useEffect(() => {
        var paramsCopy1 = Object.assign(rawMaterials);
        if (rawMaterials.itemId.value !== "") {
          paramsCopy1 = update(paramsCopy1, {
            uomId: {
              options: {
                $set: allItemUomList[paramsCopy1.itemId.value],
              },
            },
            from: {
              value: { $set: allItemUomList[paramsCopy1.itemId.value][0]["from"] },
            },
            to: {
              value: { $set: allItemUomList[paramsCopy1.itemId.value][0]["to"] },
            },
            netWeight: {
              value: {
                $set: allItemUomList[paramsCopy1.itemId.value][0]["netWeight"],
              },
            },
            grossWeight: {
              value: {
                $set: allItemUomList[paramsCopy1.itemId.value][0]["grossWeight"],
              },
            },
            childBOM: {
              fgItemId:{
                $set: paramsCopy1.itemId.value
              }
            }
          });
        } else {
          paramsCopy1 = update(paramsCopy1, { uomId: { options: { $set: [] },value:{$set: ""} },childBOM:{ fgItemId: {$set: ""},childBOMObject:{$set: {} } } });
        }
        setRawMaterials(paramsCopy1);
      }, [rawMaterials.itemId.value]);

      useEffect(() => {
        var paramsCopy2 = Object.assign(finishedGoods);
        if (finishedGoods.itemId.value !== "") {
          paramsCopy2 = update(paramsCopy2, {
            uomId: {
              options: {
                $set: allItemUomList[paramsCopy2.itemId.value],
              },
            },
            from: {
              value: { $set: allItemUomList[paramsCopy2.itemId.value][0]["from"] },
            },
            to: {
              value: { $set: allItemUomList[paramsCopy2.itemId.value][0]["to"] },
            },
            netWeight: {
              value: {
                $set: allItemUomList[paramsCopy2.itemId.value][0]["netWeight"],
              },
            },
            grossWeight: {
              value: {
                $set: allItemUomList[paramsCopy2.itemId.value][0]["grossWeight"],
              },
            },
          });
        } else {
          paramsCopy2 = update(paramsCopy2, { uomId: { options: { $set: [] },value:{$set: ""} } });
        }
        setFinishedGoods(paramsCopy2);
      }, [finishedGoods.itemId.value]);
    

      useEffect(() => {
        var paramsCopy3 = Object.assign(scrapGoods);
        if (scrapGoods.itemId.value !== "") {
          paramsCopy3 = update(paramsCopy3, {
            uomId: {
              options: {
                $set: allItemUomList[paramsCopy3.itemId.value],
              },
            },
            from: {
              value: { $set: allItemUomList[paramsCopy3.itemId.value][0]["from"] },
            },
            to: {
              value: { $set: allItemUomList[paramsCopy3.itemId.value][0]["to"] },
            },
            netWeight: {
              value: {
                $set: allItemUomList[paramsCopy3.itemId.value][0]["netWeight"],
              },
            },
            grossWeight: {
              value: {
                $set: allItemUomList[paramsCopy3.itemId.value][0]["grossWeight"],
              },
            },
          });
        } else {
          paramsCopy3 = update(paramsCopy3, { uomId: { options: { $set: [] },value:{$set: ""} } });
        }
        setScrapGoods(paramsCopy3);
      }, [scrapGoods.itemId.value]);

  const lineParamsList = {
    finishedGoods: isLogged.showHideWeight ? ["itemId","from","to","netWeight","grossWeight","uomId","quantity","allocation"] :  ["itemId","uomId","quantity","allocation"],
    rawMaterials: isLogged.showHideWeight ? ["itemId","from","to","netWeight","grossWeight","uomId","quantity"] :  ["itemId","uomId","quantity"],
    scrapGoods: isLogged.showHideWeight ? ["itemId","from","to","netWeight","grossWeight","uomId","quantity","allocation"] :  ["itemId","uomId","quantity","allocation"]
}

const tableColumns={
    rawMaterials: isLogged.showHideWeight ? ["item","from","to","netWeight","grossWeight","uom","quantity",'rawALTLineItems'] :["item","uom","quantity",'rawALTLineItems'],
    scrapGoods: isLogged.showHideWeight ? ["item","from","to","netWeight","grossWeight","uom","quantity","allocation"] :["item","uom","quantity","allocation"]
  }
      const RenderTableBody=({tableToDisplay,subTable})=>{
        return(
      <tbody>
            {tableToDisplay?.length > 0 ? tableToDisplay.map((row, i) => (
              <React.Fragment key={i} >
              <tr className="createVendorContactsTableRows">
                {tableColumns[subTable].map((col, j) => {
                  if (col !== 'rawALTLineItems')
                    return <td key={j}>{row[col] ? row[col] : '-'}</td>
                  else
                    return <td key={j}>{row[col].length}</td>
                })}
                <td>
                  <img
                    alt="Edit Record"
                    className="createVendorContactsAction"
                    src={editIcon}
                    onClick={() => {
                      editSubSectionItem(i, "edit",subTable);
                    }}
                  />
                  <img
                    alt="Delete Record"
                    className="createVendorContactsAction"
                    src={deleteIcon}
                    onClick={() => {
                      var reset = window.confirm("Delete record?");
                      if (reset) {
                        editSubSectionItem(i, "delete",subTable);
                      }
                    }}
                  />
                </td>
              </tr>
              {subTable === 'rawMaterials' && row["childBOM"]?.["bomId"] ? 
                <tr>{<RenderTableBody subTable={'rawMaterials'} 
                    tableToDisplay={row["childBOM"]["childBOMObject"]?.["rawMaterialLineItems"]}/>}</tr>:null}
              </React.Fragment>
            )) : <tr className="createVendorContactsTableRows">
              {tableColumns[subTable].map((col) => <td key={col}>&nbsp;</td>)}
              <td>&nbsp;</td>
            </tr>}
          </tbody>
        )
      }

      const RenderAltTable = ({ subTable }) => {
        let tableToDisplay = subTable === 'rawMaterials' ? rawMaterialLineItems.slice() : scrapLineItems.slice();
        return (
          <React.Fragment>
            <table className="createVendorContactsTable">
              <thead>
                <tr className="createVendorContactsTableHeader">
                  <td>Item</td>
                  {isLogged.showHideWeight ? (
                    <>
                      <td>Weight From(gms)</td>
                      <td>Weight To(gms)</td>
                      <td>Net Weight(gms)</td>
                      <td>Gross Weight(gms)</td>
                    </>
                  ) : null}
                  <td>UOM</td>
                  <td>Quantity</td>
                  <td>{subTable === "rawMaterials" ? "Alt. Items Count" : "Cost Allocation"}</td>
                  <td>Actions</td>
                </tr>
              </thead>
               <RenderTableBody tableToDisplay={tableToDisplay} subTable={subTable}/>
            </table>
          </React.Fragment>
        )
      }
      const checkLineItemError = (lineSectionName) => {
        var paramsCopy;
        if(lineSectionName == "rawMaterials")
        paramsCopy = Object.assign(rawMaterials);
        if(lineSectionName == "scrapGoods")
        paramsCopy = Object.assign(scrapGoods);
        var errorList = [];
    
        lineParamsList[lineSectionName].forEach((item) => {
          if (paramsCopy[item].mandatory)
            paramsCopy = update(paramsCopy, {
              [item]: {
                error: {
                  $set: !validateMandatory(paramsCopy[item].value.toString()),
                },
              },
            });
          if (item !== "itemId" || item !== "uomId") {
            if (paramsCopy[item].value !== "") {
              let constraint = item === 'allocation' ? 0 : 1;
              if (isNaN(paramsCopy[item].value) || parseFloat(paramsCopy[item].value) < constraint) {
                paramsCopy = update(paramsCopy, {[item]: { error: { $set: true } }, });
              } else {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: false } }, });
              }
            }
          }
          if (paramsCopy[item].error) {
            errorList.push(paramsCopy[item].errorMessage);
          }
        });
        var lineCopy;
        if(lineSectionName === "rawMaterials")
         lineCopy = rawMaterialLineItems.slice();
        if(lineSectionName === "scrapGoods")
        lineCopy = scrapLineItems.slice();
        if(paramsCopy.itemId.value === finishedGoods.itemId.value){
          errorList.push(`This item is already selected as Finished Good`)
        }
        if (errorList.length === 0) {
          var newItem = {
            itemId: paramsCopy.itemId.value,
            item: paramsCopy.itemId.options.filter(
              (item) => item.optionId === paramsCopy.itemId.value
            )[0].optionName,
            itemOption: paramsCopy.itemId.options.filter(
              (item) => item.optionId === paramsCopy.itemId.value
            )[0],
            from: paramsCopy.from.value,
            to: paramsCopy.to.value,
            netWeight: paramsCopy.netWeight.value,
            grossWeight: paramsCopy.grossWeight.value,
            uomId: paramsCopy.uomId.value,
            uom: paramsCopy.uomId.options.filter(
              (item) => item.optionId === paramsCopy.uomId.value
            )[0].optionName,
            quantity: paramsCopy.quantity.value,
            allocation : paramsCopy.allocation.value,
            lineItemId: paramsCopy.lineItemId.value
          };
          if(lineSectionName=='rawMaterials'){
           newItem["rawALTLineItems"] = paramsCopy["tempRawALTLineItems"];
           paramsCopy["tempRawALTLineItems"] =[];
           newItem["childBOM"] = paramsCopy["childBOM"];
           paramsCopy["childBOM"] = {fgItemId:"",childBOMObject:{}};    
          }
    
          lineCopy.push(newItem);
    
          var trimmedItemList = paramsCopy.itemId.options.filter(
            (item) => item.optionId !== paramsCopy.itemId.value
          );
    
          paramsCopy = update(paramsCopy, {
            itemId: { value: { $set: "" }, options: { $set: trimmedItemList } },
            from: { value: { $set: "" } },
            to: { value: { $set: "" } },
            netWeight: { value: { $set: "" } },
            grossWeight: { value: { $set: "" } },
            uomId: { value: { $set: "" } },
            quantity: { value: { $set: "" } },
            allocation: {value: {$set: ""}},
            lineItemId: {value: {$set: ""}}
          });
        };
    
        if(lineSectionName === "rawMaterials")
        {setRawMaterialLineItems(lineCopy);
        setRawMaterials(paramsCopy);
      }
    
        if(lineSectionName === "scrapGoods"){
          setScrapGoods(paramsCopy)
          setScrapLineItems(lineCopy);
      }
        setErrors(errorList);
      }
        
      const editSubSectionItem = (index, action, subTable) => {
        var lineItemsListCopy;
        var paramsCopy;
        var allItemListCopy;
        if (subTable === 'rawMaterials') {
          lineItemsListCopy = rawMaterialLineItems.slice();
          paramsCopy = Object.assign(rawMaterials);
          allItemListCopy = rawMaterials.itemId.options.slice();
        }
        else if (subTable === 'scrapGoods') {
          lineItemsListCopy = scrapLineItems.slice();
          paramsCopy = Object.assign(scrapGoods);
          allItemListCopy = scrapGoods.itemId.options.slice();
        }
    
    
        if (action === "edit") {
          var lineItemAllClear = true;
          let omittable= ["tempRawALTLineItems","childBOM","lineItemId"];
          Object.keys(paramsCopy).forEach((item) => {
            if (paramsCopy[item].value !== "" && !omittable.includes(item)) {
              lineItemAllClear = false;
            }
          });
          var yes = false;
          if (!lineItemAllClear) {
            yes = window.confirm(
              "Unsaved data found. Are you sure you want to overwrite it?"
            );
          }
    
          if (lineItemAllClear || yes) {
            var editObj = lineItemsListCopy.splice(index, 1)[0];
    
            allItemListCopy.push(editObj.itemOption);
    
            paramsCopy = update(paramsCopy, {
              itemId: {
                value: { $set: editObj.itemId },
                options: { $set: allItemListCopy },
              },
              from: { value: { $set: editObj.from } },
              to: { value: { $set: editObj.to } },
              netWeight: { value: { $set: editObj.netWeight } },
              grossWeight: { value: { $set: editObj.grossWeight } },
              uomId: {
                value: { $set: editObj.uomId },
                options: { $set: allItemUomList[editObj.itemId] },
                serverCall: { $set: false },
              },
              quantity: { value: { $set: editObj.quantity } },
              allocation: { value: { $set: editObj.allocation } },
              lineItemId: {value: {$set: editObj.lineItemId} }
            });
            if(subTable === 'rawMaterials'){
              paramsCopy.tempRawALTLineItems = editObj["rawALTLineItems"]
              paramsCopy.childBOM = editObj["childBOM"]
            }
          }
        }
        if (action === "delete") {
          var editObj = lineItemsListCopy.splice(index, 1)[0];
    
          allItemListCopy.push(editObj.itemOption);
    
          paramsCopy = update(paramsCopy, {
            itemId: { options: { $set: allItemListCopy } },
          });
        }
        if (subTable === 'rawMaterials') {
          setRawMaterials(paramsCopy);
          setRawMaterialLineItems(lineItemsListCopy);
        } else if (subTable === 'scrapGoods') {
          setScrapGoods(paramsCopy);
          setScrapLineItems(lineItemsListCopy);
        }
      };
    
      const renderErrorMessage = () => {
        if (errors.length > 0) return errors[0];
      };
    
      
    const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
         
            return (
              <FormElement
                key={element}
                inputType={param[element].inputType}
                value={param[element].value !== null ? param[element].value : ""}
                setInput={(value) => {
                  updateParams({
                    section,
                    paramName: element,
                    key: "value",
                    value: value,
                  });
                }}
                hintText={param[element].hintText}
                mandatory={param[element].mandatory}
                colSpan={param[element].colSpan}
                options={
                  param[element].inputType === "options"
                    ? param[element].options
                    : null
                }
                error={param[element].error}
                rowSpan={
                  element === "vendorLogo" || element === "otherBusinessDetails"
                    ? param[element].rowSpan
                    : null
                }
                placeholder={param[element].placeholder}
                title={param[element].title}
                disabled={param[element].disabled}
                mindate={param[element].mindate}
              />
            );
          }
        );
      };

    const [BomModalOpen,setBomModalOpen] = useState(false);
    const [BomModalText,setBomModalText] = useState('');
    
    const getALTLineItemsInPOPUP = () => {
  
      let toReturn;
      if (BomModalText === 'finishedGoodsALT')
        toReturn = finishedGoodsALTItems;
      else if (BomModalText === 'rawMaterialsALT')
        toReturn = rawMaterials['tempRawALTLineItems'];
      else if(BomModalText === 'childBOM')
        toReturn = rawMaterials['childBOM'];
      return toReturn;
  }
  
  const BomOkClick = (data) => {
    if (data.name === 'rawMaterialsALT') {
      let rawMaterialsCopy = Object.assign(rawMaterials);
      rawMaterialsCopy["tempRawALTLineItems"] = data.lineItems;
      setRawMaterials(rawMaterialsCopy);
    } else if(data.name === 'finishedGoodsALT'){
      setFinishedGoodsALTItems(data.lineItems);
    } else if(data.name === 'childBOM'){
      let rawMaterialsCopy = Object.assign(rawMaterials);
      rawMaterialsCopy['childBOM']['childBOMObject'] = data.childBOMObject;
      setRawMaterials(rawMaterialsCopy);
    }
    setBomModalOpen(false);
    setBomModalText("");
  }
    const checkErrorsBOMtoServer=()=>{
        var errorList=[];
        var storeDetailsCopy = Object.assign(storeDetails);
        Object.keys(storeDetailsCopy).forEach((item)=>{
          if(storeDetailsCopy[item].mandatory){
            storeDetailsCopy = update(storeDetailsCopy, { [item]: { error: { $set: !validateMandatory(storeDetailsCopy[item].value.toString()) } } });
          }
    
          if(storeDetailsCopy[item].error){
            errorList.push( storeDetailsCopy[item].errorMessage);
          }
          
        });
        var finishedGoodsCopy = Object.assign(finishedGoods)
    
        Object.keys(finishedGoodsCopy).forEach((item) => {
        let constraint = item === 'allocation' ? 0 : 1
        if(finishedGoodsCopy[item].mandatory){
        if ( !finishedGoodsCopy[item].value || (finishedGoodsCopy[item].value < constraint)) {
          finishedGoodsCopy = update(finishedGoodsCopy, { [item]: { error: { $set: true } } })
          let Message = finishedGoodsCopy[item].errorMessage+" in Finished Goods"
          errorList.push(Message);
          } }else {
            finishedGoodsCopy = update(finishedGoodsCopy,{[item]:{error: {$set: false} } });
          }
        })
    
        var rawMaterialsCopy = Object.assign(rawMaterials);
          var scrapCopy = Object.assign(scrapGoods);
        
            Object.keys(rawMaterialsCopy).forEach((item)=>{
              if(rawMaterialsCopy[item].value > 0)
              errorList.push(`Unsaved Data Found in Raw Materials`)
            });
            Object.keys(scrapCopy).forEach((item)=>{
              if(scrapCopy[item].value > 0)
              errorList.push(`Unsaved Data Found in Scrap goods`)
            });
    
            if(rawMaterialLineItems.length === 0)
            errorList.push('Please Insert atleast One raw Material Item')
            
            setFinishedGoods(finishedGoodsCopy)
            setStoreDetails(storeDetailsCopy);
            setRawMaterials(rawMaterialsCopy);
            setScrapGoods(scrapCopy);
            setErrors(errorList);
            if(errorList.length===0){
              let dataToServer = {
                bomId: poInfo.editBomId,
                storeDetails: Object.entries(storeDetails).reduce((acc,[key,value])=>({...acc,[key]: value.value}),{}),
                finishedGoods: Object.entries(finishedGoods).reduce((acc,[key,value])=>({...acc,[key]: value.value}),{}),
                finishedGoodsALTItems,
                rawMaterialLineItems,
                scrapLineItems,
                fgProcessItems
              }
              submitData(dataToServer);
            }
      }
    
      const submitData=async(dataToServer)=>{
        setIsOpen(true);
        setModalText("Editing Bill of Material Please wait...!");
        var result = await fetchData({
          requestingPage: "BillOfMaterial",
          url: "bill-of-material/edit-bom-detail",
          method: "post",
          data: dataToServer,
          headers: {token: isLogged.accessToken, module: "Bill Of Material"}
        });
        setDataSubmitted(true);
        if(result && result.msg==='success'){
          setModalText('Bill of Material Edited Successfully');
        }else{
          setErrors([result.desc])
          setModalText(result.desc);
          console.log(result);
        }
      }
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Creating Bill of Material Please wait...");
  
    const submitOkClick = () => {
        setIsOpen(false);
        if (errors.length === 0) {
            setSection("billOfMaterialList");
        }
    };

    const triggerPOPUP=(modalTitle)=>{
      switch(modalTitle){
        case "finishedGoodsALT":
          if(finishedGoods.itemId.value)
          {setBomModalText(modalTitle);
          setBomModalOpen(true);
          }
          break;
        case "rawMaterialsALT":
        case "childBOM":
          if(rawMaterials.itemId.value)
          {setBomModalText(modalTitle);
          setBomModalOpen(true);
          }
          break;
        default: 
          setBomModalText("");
          setBomModalOpen(false);
      }
    }

    const editFGProcessItems=({action,index})=>{
      let processCopy;
      switch(action){
        case "addItem":
            let processParamsCopy = Object.assign(fgProcess);
            processCopy = processParamsCopy.fgProcess.options.filter((opt)=>opt.optionId===fgProcess.fgProcess.value)[0];
            processCopy = processCopy["optionName"];
            setFgProcessItems([...fgProcessItems,processCopy]);
            processParamsCopy = update(processParamsCopy,{["fgProcess"]: {value: {$set: ""} } })
            setFgProcess(processParamsCopy);
          break;
        case "delete":
          let processListCopy = fgProcessItems.slice();
          processListCopy.splice(index,1);
          // console.log(processListCopy,'pr');
          setFgProcessItems(processListCopy);
          break;
  
          default:
            document.write("Action Not Specified")
          
      }
    }
  
    return(
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText}
              dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <BomModal modalIsOpen={BomModalOpen} isLogged={isLogged} paramsTemplate={template}
                modalText={BomModalText} ALTlineItems={getALTLineItemsInPOPUP()} submitOkClick={(data)=>BomOkClick(data)}/>
            <div className="formArea">
                    <div
                        style={{
                            width: "950px",
                            margin: "0 auto 2rem",
                            height: 'fit-content',
                        // padding: "3rem 3rem 1rem 3rem",
                            padding: "2rem",
                            border: "1px solid lightgray",
                            borderRadius: "5px",
                            backgroundColor: "white",
                        }}>
                        <div style={{ display: "flex" }}>
                            <div className="createPurchaseOrderGrid" style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
                                {renderFormElements({ elementList: Object.keys(storeDetails), param: storeDetails, section: "general" })}
                            </div>
                        </div>
                        
                        {/*Finished Goods*/}
                        <div className="purchaseOrderSubSectionArea" style={{width:"fit-content" }}>
                            <div className="vendorSummaryRole">Finished Goods</div>
                            <div className="purchaseOrderIndividualItemsArea" style={{gridAutoRows: "60px"}}>
                                {renderFormElements({ elementList: lineParamsList.finishedGoods, param: finishedGoods, section: "finishedGoods" })}
                                <label className={`customLineCheck ${finishedGoods.itemId.value ? "" : ' disableBomPOPup'}`} 
                                onClick={(e)=>triggerPOPUP('finishedGoodsALT')}>
                                <img  src={plusCircle}  />{finishedGoodsALTItems.length} Alternate Items</label>
                            </div>
                        </div>

                        {/*Raw Materials*/}

                        <div className="purchaseOrderSubSectionArea" style={{width:"fit-content" }}>
                            <div className="vendorSummaryRole">Raw Materials</div>
                            <div className="purchaseOrderIndividualItemsArea" style={{gridAutoRows: "60px"}}>
                                
                                {renderFormElements({ elementList: lineParamsList.rawMaterials, param: rawMaterials, section: "rawMaterials" })}
                                
                                <FormElement inputType="addButton" value="+ Add" colSpan={4} setInput={() => {checkLineItemError("rawMaterials");}}/>                        
                                
                                <label className={`customLineCheck ${rawMaterials.itemId.value ? "" : ' disableBomPOPup'}`}  
                                onClick={(e)=>triggerPOPUP('rawMaterialsALT')}><img src={plusCircle} />{rawMaterials.tempRawALTLineItems.length} Alternate Items</label>
                                
                                <label className={`customLineCheck ${rawMaterials.itemId.value ? "" : ' disableBomPOPup'}`} 
                                onClick={(e)=>triggerPOPUP('childBOM')}> <img style={{ height: "40px",width: "60px",margin:"-9px"}} src={bomTree}/>Child Bom{rawMaterials.childBOM?.childBOMObject?.bomId ? <strong>Added</strong> : null }</label>
                            </div>
                            <RenderAltTable subTable={"rawMaterials"}/>
                        </div>

                            {/*Scrap Goods*/}
                            <div className="purchaseOrderSubSectionArea" style={{width:"fit-content" }}>
                                <div className="vendorSummaryRole">Scrap Goods</div>
                                <div className="purchaseOrderIndividualItemsArea" style={{gridAutoRows: "60px"}}>
                                    
                                    {renderFormElements({ elementList: lineParamsList.scrapGoods, param: scrapGoods, section: "scrapGoods" })}
                                    
                                    <FormElement inputType="addButton" value="+ Add" colSpan={4} setInput={() => {checkLineItemError("scrapGoods");}}/>                        
                                </div>
                                <RenderAltTable subTable={"scrapGoods"}/>
                            </div>

                            {/*Finished Good Process */}
                            <div className="purchaseOrderSubSectionArea" style={{width:"fit-content"}}>
                              <div className="vendorSummaryRole">Finished Good Process</div>
                              <div className="purchaseOrderIndividualItemsArea" style={{gridAutoRows: "60px"}}>
                                {renderFormElements({param: fgProcess,elementList: ["fgProcess"],section: "fgProcess"})}
                                <FormElement inputType="addButton" value="+ Add" colSpan={4} setInput={() => {editFGProcessItems({action:"addItem"})}}/>                        
                              </div>
                              <table className="createVendorContactsTable">
                                <thead>
                                  <tr className="createVendorContactsTableHeader">
                                    <td>Step No</td>
                                    <td>Process Name</td>
                                    <td>Actions</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    fgProcessItems?.length >0 ? 
                                    fgProcessItems.map((item,index)=>(
                                    <tr className="createVendorContactsTableRows" key={index}>
                                      <td>{index+1}</td>
                                      <td>{item}</td>
                                      <td><img src={deleteIcon} alt="delete" 
                                      className="createVendorContactsAction"
                                      onClick={()=>editFGProcessItems({action:"delete",index})}/></td>
                                    </tr>)) : 
                                      <tr className="createVendorContactsTableRows">
                                          <td>&nbsp;</td>
                                          <td>&nbsp;</td>
                                          <td>&nbsp;</td>
                                      </tr>
                                  }
                                </tbody>
                              </table>
                            </div>
                    </div>
                </div>
                <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

          <button
            className="submitButton"
            onClick={() => {
              setErrors([]);
              checkErrorsBOMtoServer();
            }}>
            Submit
          </button>
        </div>
      </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditBom);