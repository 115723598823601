import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import FetchNewToken from "../../serverCall/FetchNewToken";
import deleteIcon from "../../assets/delete.svg";

const CreateCustomerReceipt =({isLogged,addTokenToState,setSection})=>{
    const [customerReceiptParams,setCustomerReceiptParams] = useState({
        orderType:{
            inputType: "text",
            value:"Sales Order",
            hintText:"Order Type",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please Enter Order Type",
            disabled: true
        },
        customerId:{
            inputType:"options",
            value:"",
            options:[],
            hintText:"Customer Name",
            mandatory: true,
            colSpan:5,
            error: false,
            errorMessage : "Please Select a Customer",
        },
        paymentDate:{
            inputType:"dateFromEditPage",
            value: "",
            hintText: "Payment Date",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please Enter Payment Date",
        },
        paymentType:{
            inputType: "options",
            value:"",
            options:[
                {optionId:"Cash",optionName:"Cash"},
                {optionId:"Online",optionName:"Online"},
                {optionId:"Cheque",optionName:"Cheque"},
                {optionId:"Debit/Credit Card",optionName:"Debit/Credit Card"},
            ],
            hintText:"Payment Type",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please Select a Payment Type",
        },
        paymentAmount:{
            inputType: "number",
            value:"",
            hintText:"Payment Amount",
            mandatory: true,
            colSpan:  4,
            error: false,
            errorMessage:"Enter Valid Payment Amount",
        },
        paidToPlant: {
            inputType: "options",
            value: "",
            options: [],
            hintText: "Paid From Plant",
            colSpan: 4,
            mandatory: false,
        },
        paidToAccount: {
            inputType: "options",
            value: "",
            options: [],
            hintText: "Bank Account",
            colSpan: 4,
            mandatory: false
        }
    });
    const [paymentAmount,setPaymentAmount]=useState(0);
    const [invoiceParams,setInvoiceParams] = useState({
        invoiceNo:{
            inputType:"options",
            options:[],
            value:"",
            hintText:"Invoice No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please Select a Invoice Number",
        },
        salesOrderNo:{
            inputType: "text",
            value:"",
            hintText:"Sales Order No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Sales Order No can't be Empty",
            disabled: true,
        },
        totalAmount:{
            inputType: "text",
            value:"",
            hintText:"Invoice Amount",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Total Amount can't be Empty",
            disabled: true,
        },
        oldInvoiceBalance:{
            inputType: "text",
            value: "",
            hintText:" Old Invoice Balance",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Balance Amount can't be Empty",
            disabled: true,
        },
        invoiceBalance:{
            inputType: "text",
            value: "",
            hintText:"Invoice Balance",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Balance Amount can't be Empty",
            disabled: true,
        }
    });

    const [lineItems,setLineItems]=useState([]);
    const history=useHistory();
    const [showCustomerDetail,setShowCustomerDetail] = useState(false);
    const [notes,setNotes] = useState("");
    const [errors,setErrors] = useState([]);
    const [netTotal,setNetTotal] = useState(0);
    const [customerList,setCustomerList]=useState([]);
    const [customerDetail,setCustomerDetail]=useState({});
    const [invoiceAppList,setInvoiceAppList]=useState([]);
    const [invoiceDetails,setInvoiceDetails]= useState([]);
    const [totalInvoiceBalance,setTotalInvoiceBalance]=useState(0);
    // const [openBalance,setOpenBalance]=useState(0);
    let remainingBalance=0;
    useEffect(()=> {getInformation()},[]);

    async function getInformation(){
        await checkToken();
        await getCustomers();
        await getSalesInvoiceApproved();
    }

    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    };

    async function getCustomers(){
        let paramsCopy = Object.assign(customerReceiptParams);
        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: "fetch/customers",
            headers: { token: isLogged.accessToken,module:"Customer Receipt" },
        });

        if(data.msg==="success"){
  
          paramsCopy = update(paramsCopy,{['customerId']: {['options']: {$set: data.asset} } });
            setCustomerList(data.asset);
        } else{
            console.log(data);
        }
        var bankList = await fetchData({
            requestingPage: "venPaymentList",
            method: "get",
            url: 'po-fetch/bank-options-list',
            headers: {token: isLogged.accessToken, module: "Customer Receipt"}
        });

        if(bankList.msg='success'){
            paramsCopy = update(paramsCopy,{['paidToPlant']: {['options']: {$set: bankList.asset} } })
        }else{
            console.log(bankList);
        }

        setCustomerReceiptParams(paramsCopy);
    };

    async function getSalesInvoiceApproved(){
        var invoices=await fetchData({
            requestingPage: "customerList",
            method: "post",
            url: "so-fetch/sales-invoice-approved-list",
            headers: { token: isLogged.accessToken,module: "Customer Receipt" },
        });

        if(invoices.msg==="success"){
            let invoiceList=[];
            invoices.asset=invoices.asset.filter((invoice)=>invoice.oldInvoiceBalance!=0);
            invoices.asset.forEach(({invoiceNo,soNo,totalAmount,customerId,oldInvoiceBalance},i)=>
            {invoiceList.push({invoiceNo,soNo,totalAmount:parseInt(totalAmount),customerId,oldInvoiceBalance :oldInvoiceBalance || parseInt(totalAmount)}); });
                                setInvoiceAppList(invoiceList);
        }
    }
    const updateParameters=({section,param,key,value,wholeObject})=>{
        if(section==="general"){
            if(param==="paymentAmount")
            setPaymentAmount(value);
            let paramsCopy=Object.assign(customerReceiptParams);
            paramsCopy=update(paramsCopy,{[param]:{[key]: {$set: value} } });

            
            if(param==='paidToPlant'){
                if(value)
                paramsCopy = update(paramsCopy,{['paidToAccount']: {['options']: {$set: wholeObject.bankList} } })
                else
                paramsCopy = update(paramsCopy,{['paidToAccount']: {['options']: {$set: [] },['value']:{$set: "" }} })
            }

            setCustomerReceiptParams(paramsCopy);
        }else if(section==="invoice"){
            let invoiceCopy = Object.assign(invoiceParams);
            invoiceCopy=update(invoiceCopy,{[param]:{[key]:{$set: value} } });
            setInvoiceParams(invoiceCopy);
        }
    }


    useEffect(()=>{
         let invoiceCopy = Object.assign(invoiceParams);
        if(customerReceiptParams.customerId.value !="" && customerReceiptParams.paymentAmount.value!=""){
            setShowCustomerDetail(true);
            setCustomerDetail( customerList.filter((cus)=>cus["customer"].customerId===customerReceiptParams.customerId.value)[0]);
            let invoiceOptions=invoiceAppList.filter((invoice)=>invoice.customerId===customerReceiptParams.customerId.value);
            invoiceOptions.forEach((obj)=>{obj.optionId= obj.invoiceNo;obj.optionName= obj.invoiceNo});
            setInvoiceDetails(invoiceOptions);
            invoiceCopy = update(invoiceCopy, {invoiceNo : {value: {$set: ""} , options: {$set: invoiceOptions} },
                                               salesOrderNo :{value :{$set  :""} },
                                                totalAmount:{value: {$set: ""} },
                                                oldInvoiceBalance: {value: {$set: ""}},
                                                invoiceBalance: {value: {$set: ""} }  });
            setInvoiceParams(invoiceCopy);
            setLineItems([]);
            remainingBalance=0;
            setPaymentAmount(customerReceiptParams.paymentAmount.value);
        }else{
            setShowCustomerDetail(false);
            setCustomerDetail({});
            setInvoiceDetails([]);
            invoiceCopy = update(invoiceCopy, {invoiceNo : {value: {$set: ""} , options: {$set: [] } },
                                               salesOrderNo :{value :{$set  :""} },
                                                totalAmount:{value: {$set: ""} },
                                                oldInvoiceBalance: {value: {$set: ""}},
                                                invoiceBalance: {value: {$set: ""} }  });
            setInvoiceParams(invoiceCopy);
            setLineItems([]);
            remainingBalance=0;
            setPaymentAmount(customerReceiptParams.paymentAmount.value);
        }

    },[customerReceiptParams.customerId,customerReceiptParams.paymentAmount]);

    useEffect(()=>{
        let paramsCopy=Object.assign(invoiceParams);
        if(invoiceParams.invoiceNo.value!=""){
            let selectedInvoice = invoiceDetails.length>0 && invoiceDetails.filter((obj)=>obj.optionId===invoiceParams.invoiceNo.value)[0];
            remainingBalance=selectedInvoice["oldInvoiceBalance"]-paymentAmount;
           //console.log("rrr",remainingBalance,paymentAmount);
            if(remainingBalance < 0){
                selectedInvoice["newInvoiceBalance"] = 0;
                setPaymentAmount((remainingBalance*-1))
            } else{
                selectedInvoice["newInvoiceBalance"] = parseInt(remainingBalance);
                setPaymentAmount(NaN);
            }
            // if(remainingBalance < 0){
            //     setPaymentAmount((remainingBalance*-1));
            // } else{
            //     setPaymentAmount(NaN);
            // }
            paramsCopy= update(paramsCopy,{ salesOrderNo: {value : {$set :selectedInvoice["soNo"]} },
                                        totalAmount: {value: {$set: selectedInvoice["totalAmount"]} },
                                        oldInvoiceBalance: {value: {$set: selectedInvoice["oldInvoiceBalance"]}},
                                        invoiceBalance: {value: {$set: selectedInvoice["newInvoiceBalance"]} } 
                                    });
             setInvoiceParams(paramsCopy);
        } else {
            paramsCopy= update(paramsCopy,{ salesOrderNo: {value : {$set : "" } },totalAmount: {value: {$set: ""} },
                                            oldInvoiceBalance:{value: {$set: ""}},invoiceBalance: {value: {$set: ""}} });
            setInvoiceParams(paramsCopy);
        }
    },[invoiceParams.invoiceNo])

    const checkErrors=()=>{
        var errorList=[];
        let cusParamCopy=Object.assign(customerReceiptParams);
        Object.keys(cusParamCopy).map((item)=>{
            if(cusParamCopy[item].mandatory)
            cusParamCopy[item].error= !validateMandatory(cusParamCopy[item].value.toString());
            if(cusParamCopy[item].error){
                errorList.push(cusParamCopy[item].errorMessage);
            }
        });

        let invoiceParamsCopy= Object.assign(invoiceParams); 

        for(let item in invoiceParamsCopy){
            if(invoiceParamsCopy[item].value!=="")
                {errorList.push("Unsaved Invoice Record Found!");
            break;}           
        }

        let lineItemsCopy = lineItems.slice();
        lineItemsCopy.forEach((row)=>{
            for (let item in row){
                if(row[item]==null || row[item]==undefined)
                errorList.push("Invalid Invoice Item Found");}
            });
        // if(!parseInt(openBalance) && openBalance!==0)
        // errorList.push("Provide Valid Open Balance");

        if(!parseInt(paymentAmount) && paymentAmount!==0 && !isNaN(paymentAmount))
        errorList.push("Provide valid Payment Amount");

        if(lineItems.length<1)
        errorList.push("Select Invoice No");

        if(errorList.length===0 && lineItems.length>0)
        {
            var dataToServer={
                customerId: cusParamCopy.customerId.value,
                paymentDate: cusParamCopy.paymentDate.value,
                paymentType: cusParamCopy.paymentType.value,
                // openBalance: openBalance,
                paymentAmount: cusParamCopy.paymentAmount.value ||null,
                paymentAmountBalance: paymentAmount || 0,
                paidToAccount: cusParamCopy.paidToAccount.value || null,
                paidToPlant: cusParamCopy.paidToPlant.value || null,
                notes:notes,
                netTotal:netTotal
            };
            dataToServer.lineItems=lineItemsCopy;
            //console.log("server",dataToServer);
            submitData(dataToServer);
        }else{
            setCustomerReceiptParams(cusParamCopy);
            setLineItems(lineItemsCopy);
            setErrors(errorList);
        }
    }

   async function submitData(dataToServer){
       setIsOpen(true);
       setModalText("Creating Customer Receipt... Please Wait...")
        var result = await fetchData({
            requestingPage: "createPoItem",
            url: "so-create/create-customer-receipt",
            method: "post",
            headers: {token: isLogged.accessToken, module: "Customer Receipt"},
            data: dataToServer
        });
        console.log("result",result);
        if(result.msg==="success"){
            setDataSubmitted(true);
            setModalText("Customer Receipt Created SuccessFully");
        }else{
            setDataSubmitted(true);
            setModalText(result.desc);
            console.log(result);
            setErrors([result.desc]);
        }
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Creating Customer Receipt. Please wait...");

    const submitOkClick = () => {
        setIsOpen(false);
        if (errors.length === 0) {
            setSection("receiptList");
        }
    };

    const renderFormElements=({elementList,param,section})=>{
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value !== null ? param[element].value : ""}
                    setInput={(value,wholeObject) => {
                        updateParameters({ section, param: element, key: "value", value: value,wholeObject });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                />
            );
        });
    }

    const addInvoiceToLine=()=>{
        var lineCopy=lineItems.slice();
        var errorList=[]
        let invoiceCopy = Object.assign(invoiceParams);
        Object.keys(invoiceParams).map((item)=>{
            if(invoiceCopy[item].value ==="" || invoiceCopy[item].value === null){
                invoiceCopy[item].error=true;
                errorList.push("Provide Valid Invoice Details");
            }
        });

        if(isNaN(invoiceCopy["invoiceBalance"].value)){
            invoiceCopy["invoiceBalance"].error=true;
            errorList.push("Payment Amount Insufficient To add This Invoice");
        };
       
        if(errorList.length===0){
            lineCopy.push({invoiceNo: invoiceCopy.invoiceNo.value,salesOrderNo: invoiceCopy.salesOrderNo.value, totalAmount: invoiceCopy.totalAmount.value, 
                oldInvoiceBalance: invoiceCopy.oldInvoiceBalance.value,newInvoiceBalance: invoiceCopy.invoiceBalance.value});
            var trimmedList = invoiceCopy.invoiceNo.options.filter((item) => item.optionId !== invoiceCopy.invoiceNo.value);
            invoiceCopy = update(invoiceCopy,{invoiceNo: {value: {$set: ""},options: {$set: trimmedList} },
                                             salesOrderNo : {value: {$set: ""} },
                                             totalAmount: {value: {$set: ""} },
                                             oldInvoiceBalance: {value: {$set: ""}},
                                             invoiceBalance: {value: {$set: ""},error: {$set: false} } });
            setInvoiceParams(invoiceCopy);
            setLineItems(lineCopy);
        }else{
            setErrors(errorList);
        }
    }

    const editSubSectionItem=(rowIndex,action)=>{
        let invoiceCopy = Object.assign(invoiceParams);
        let lineCopy = lineItems.slice();
        if(action === "delete"){
            var deletedObj=lineCopy.splice(rowIndex,1)[0];
            deletedObj.optionId = deletedObj.invoiceNo;
            deletedObj.optionName = deletedObj.invoiceNo; 

            let deductedAmount=deletedObj["oldInvoiceBalance"]-deletedObj["newInvoiceBalance"];
            setPaymentAmount(+deductedAmount);
            remainingBalance=(-(remainingBalance)-deletedObj["newInvoiceBalance"]);
            deletedObj["newInvoiceBalance"]= 0;

            var invoiceOptions= invoiceParams.invoiceNo.options.slice();
            invoiceOptions.push(deletedObj);
            invoiceCopy = update(invoiceCopy,{invoiceNo: {options: {$set: invoiceOptions} } });
            setInvoiceParams(invoiceCopy);
            setLineItems(lineCopy);
            //console.log("crus",customerReceiptParams.paymentAmount.value,deletedObj,remainingBalance);
        }
    };

    useEffect(()=>{
        if(lineItems.length>0){
        let totalInvoiceTemp=0;
        lineItems.forEach((row)=>{
            totalInvoiceTemp+=parseInt(row.oldInvoiceBalance);
        });
        setNetTotal(totalInvoiceTemp);
        let totalInvoiceBalanceTemp = lineItems[lineItems.length-1]["newInvoiceBalance"];
        setTotalInvoiceBalance(totalInvoiceBalanceTemp);}
    },[lineItems.length]);

    const RenderTableRows=({data})=>{
        return data.map((row,i)=>{
            return ( 
                <tr className="createVendorContactsTableRows" key={i}>
                    {["invoiceNo","salesOrderNo","totalAmount","oldInvoiceBalance","newInvoiceBalance"].map((col,j)=>{
                        return <td key={j}>{row[col]}</td> 
                    })}
                    <td className="itemIndiTabActions">
                        <img
                            alt="Delete Record"
                            className="createVendorContactsAction"
                            src={deleteIcon}
                            onClick={() => {
                                var reset = window.confirm("Delete record?");
                                if (reset) {
                                    editSubSectionItem(i, "delete");
                                }
                            }}
                        />
                    </td>
                </tr>
            ) 
        });
    };

    const RenderTable=()=>{
        return (
                <table className="createVendorContactsTable">
                <thead>
                    <tr className="createVendorContactsTableHeader">
                        <td>Invoice  No</td>
                        <td>Sales Order No</td>
                        <td>Invoice Amount</td>
                        <td>old Invoice Balance</td>
                        <td>Invoice Balance</td>
                        <td className="itemIndiTabActions">Actions</td>
                    </tr>
                </thead>
                    {lineItems.length>0 ?
                    (<tbody>
                        <RenderTableRows data={lineItems}/>
                    </tbody> ) :
                    (<tbody>
                        <tr className="createVendorContactsTableRows">
                        {["","","","","",""].map((item,i)=>{
                            return <td key={i}>&nbsp;</td>;
                                })}
                    </tr>
                    </tbody>)}
                </table>
                );
    };

    const renderSubSection=()=>{
        return(
            <React.Fragment>
                 <div className="purchaseOrderIndividualItemsArea">
                    { renderFormElements({ elementList: ["invoiceNo","salesOrderNo","totalAmount","oldInvoiceBalance","invoiceBalance"], param: invoiceParams, section: "invoice" })}
                    <FormElement
                        inputType="addButton"
                        value="+ Add"
                        colSpan={4}
                        setInput={() => {
                            setErrors([]);
                            addInvoiceToLine();
                        }}
                    />

                    </div>
                    <RenderTable />
            </React.Fragment>
        )
    }

    const RenderCustomerDetail=({customerDetail})=>{
        return (
            <React.Fragment>
                <div className="detailTitle">{`${customerDetail["customer"].customerId}-${customerDetail["customer"].companyName}`}</div>
                <div className="detailText">
                   <b>Address:</b> {customerDetail["customer"].address},
                    <br /> {customerDetail["customer"].city} {customerDetail["customer"].state}
                    {customerDetail["customer"].zipCode  ? `, - ${customerDetail["customer"].zipCode}` : null}
                    {customerDetail["contact"][0].mobile   ? <br /> : null}
                    {customerDetail["contact"][0].mobile  ? <b>Phone: </b> : null}
                    {customerDetail["contact"][0].mobile ? customerDetail["contact"][0].mobile : null}
                    {customerDetail["contact"][0].email  ? <><br /> <b>Email Id:</b></> : null}
                    {customerDetail["contact"][0].email  ?  <>{customerDetail["contact"][0].email}</> : null}
                    {customerDetail["customer"].gstNo  ? <><br /> <b>GST No:</b></> : null}
                    {customerDetail["customer"].gstNo ? ` ${customerDetail["customer"].gstNo}` : null}
                </div>
            </React.Fragment>
        );
    }

    const renderErrorMessage=()=>{
        if(errors.length>0)
        return errors[0]
    }

    return(
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
            <div
                    style={{
                        width: "fit-content",
                        margin: "0 auto 2rem",
                        // padding: "3rem 3rem 1rem 3rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}>
                    <div style={{ display: "flex" }}>
                        <div className="createPurchaseOrderGrid" style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
                            {renderFormElements({ elementList: Object.keys(customerReceiptParams), param: customerReceiptParams, section: "general" })}
                        </div>
                        <div style={{ width: "260px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{showCustomerDetail ? <RenderCustomerDetail customerDetail={customerDetail}/> : null}</div>
                            </div>
                        </div>
                    </div>

                    <div className="purchaseOrderSubSectionArea">{renderSubSection()}</div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div style={{ marginTop: "10px" }}>Notes</div>
                            <textarea
                                className="multilineInput"
                                value={notes}
                                rows="6"
                                cols="30"
                                name="text"
                                placeholder="Enter your notes"
                                style={{
                                    padding: "10px",
                                    resize: "none",
                                    marginTop: "10px",
                                    height: "114px",
                                    fontFamily: "sans-serif",
                                }}
                                onChange={(e) => {
                                    setNotes(e.target.value);
                                }}
                            />
                        </div>
                        <div className="poSpacer">&nbsp;</div>
                        <div className="poNetTotal">
                             <div className="poTotalTitle">
                                <span>
                                    <b>Total Invoice Amount</b>
                                </span>
                                <span className="poNetTotalAmount">{netTotal}</span>
                             </div>
                             <div className="poTotalTitle">
                                <span>
                                    <b>Total Invoice Balance</b>
                                </span>
                                <span className="poNetTotalAmount">{isNaN(totalInvoiceBalance) ? 0 : totalInvoiceBalance}</span>
                             </div>
                             <div className="poTotalTitle">
                                <span>
                                    <b>Payment Amount Balance</b>
                                </span>
                                <span className="poNetTotalAmount">{isNaN(paymentAmount) ? 0 : paymentAmount}</span>
                             </div>
                            
                            {/* <div className="poFormElement">
                                <div className="formElement">
                                    <FormHint hintText="Open Balance" mandatory={true} />
                                    <InputField
                                        value={openBalance}
                                        setName={(value) => setOpenBalance((parseInt(value) || 0))}
                                        align="end"/>
                                </div> 
                            </div> */}
                            </div>
                        </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                    <button
                        className="submitButton"
                        onClick={() => {setErrors([]);checkErrors();}}>
                        Submit
                    </button>
            </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomerReceipt);