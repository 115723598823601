import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import { useHistory } from "react-router-dom";
import FormElement from "../../SmallComponents/FormElement";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../../CommonFunctions/ValidateFields";
import { addToOutStandingParams } from "../../../redux/Store/StoreInfoActions";


const OutStandingParams = ({ setCurrentSection, isLogged, addTokenToState, addToOutStandingParams }) => {

    const [outStandingStatementParams, setOutStandingStatementParams] = useState({
        assignee: {
            inputType: "options",
            value: "",
            options: [],
            hintText: "Assignee",
            colSpan: 6,
            error: false,
            errorMessage: "Please Select A Assignee",
            mandatory: true
        },
        customerId: {
            inputType: "options",
            value: "",
            options: [],
            hintText: "Available Customers",
            colSpan: 6,
            error: false,
            errorMessage: "Please Select A customer",
            mandatory: true
        },
        plantId: {
            inputType: "options",
            hintText: "Plant Name",
            value: "",
            options: isLogged.plantOptions,
            colSpan: 6,
            error: false,
            errorMessage: "Please Select A Plant Name",
            mandatory: true
        },
        fromDate: {
            inputType: "dateFromEditPage",
            value: new Date(),
            hintText: "From Date",
            colSpan: 6,
            error: false,
            errorMessage: "Please Select a From Date Range",
            mandatory: true
        },
        toDate: {
            inputType: "dateFromEditPage",
            value: new Date(),
            hintText: "To Date",
            colSpan: 6,
            error: false,
            errorMessage: "Please Select a To Date Range",
            mandatory: true
        }
    });
    const [customerDetails, setCustomerDetail] = useState([])

    async function getEmployees() {
        var data = await fetchData({
            requestingPage: "employeeList",
            method: "POST",
            url: "fetch/employees",
            headers: { token: isLogged.accessToken, module: "Employee" },
        });
        if (data.msg === "success") {

            var outStandingStatementParamsCopy = Object.assign(outStandingStatementParams);
            outStandingStatementParamsCopy = update(outStandingStatementParamsCopy, {
                assignee: { options: { $set: data.asset } },
            });
            setOutStandingStatementParams(outStandingStatementParamsCopy);

        } else {
            console.log(data);
            return [];
        }
    }

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        var customerLedgerParamsCopy = Object.assign(outStandingStatementParams);
        if (outStandingStatementParams.assignee.value) {
            //filtering customers based on assignee choosed
            let customerDetail = customerDetails.filter((item) => {
                if (item.customer.assignedEmployees.includes(outStandingStatementParams.assignee.value)) {
                    return item
                }
            })

            //assigned filtered customers into customers drop down input
            customerLedgerParamsCopy = update(customerLedgerParamsCopy, {
                customerId: { options: { $set: customerDetail } },
            });
        } else {

            customerLedgerParamsCopy = update(customerLedgerParamsCopy, {
                customerId: { options: { $set: [] },value: {$set:""} },
            });
        }

        setOutStandingStatementParams(customerLedgerParamsCopy);

    }, [outStandingStatementParams.assignee.value])

    async function getInformation() {
        await checkToken();
        await getEmployees();
        await getCustomers();
    }
    const history = useHistory();

    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getCustomers() {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: "fetch/customers",
            headers: { token: isLogged.accessToken, module: "Customer" },
        });
        // console.log(data);
        if (data.msg === "success") {
            setCustomerDetail(data.asset);

        } else {
            console.log(data);
            return [];
        }
    }

    const updateParameter = (element, key, value) => {
        let outStandingCopy = Object.assign(outStandingStatementParams);
        outStandingCopy = update(outStandingCopy, {
            [element]: { [key]: { $set: value } },
        });
        setOutStandingStatementParams(outStandingCopy);
    };

    const [outStandingErrors, setOutStandingErrors] = useState([]);
    const checkOutStandingError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(outStandingStatementParams);
        let queryParams = {}
        Object.keys(paramsCopy).forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } })
                queryParams[item] = paramsCopy[item].value
            }
            if (paramsCopy[item].error)
                errorList.push(paramsCopy[item].errorMessage);
        })
        if (errorList.length == 0) {
            addToOutStandingParams(queryParams);
            setCurrentSection('outStandingStatementList')
        } else {
            setOutStandingErrors(errorList)
        }
    };

    const renderFormElements = ({ elementList, param }) => {
        return (
            <>
                {elementList.map((element, ind) => {
                    return (
                        <span style={{ flex: "50%", maxWidth: ind === 0 ? "500px" : "250px" }} key={element}>
                            <FormElement
                                inputType={param[element].inputType}
                                value={param[element].value}
                                setInput={(value) => updateParameter(element, "value", value)}
                                hintText={param[element].hintText}
                                mandatory={param[element].mandatory}
                                colSpan={param[element].colSpan}
                                options={
                                    param[element].inputType === "options"
                                        ? param[element].options
                                        : null
                                }
                                error={param[element].error}
                                key={ind}
                            />
                        </span>
                    );

                })}
                <FormElement
                    inputType="addButton"
                    value="Submit"
                    colSpan={5}
                    boxWidth="80px"
                    setInput={() => {
                        setOutStandingErrors([]);
                        checkOutStandingError();
                    }}
                />
            </>
        );
    };

    return (<React.Fragment>
        <div className="formArea" style={{ marginTop: "0", padding: "0" }}>
            <div
                style={{
                    width: "1000px",
                    height: "500px",
                    margin: "0 2rem 4rem",
                    padding: "1rem",
                    border: "1px solid inherit",
                    borderRadius: "5px",
                    backgroundColor: "inherit",
                }}>
                {/* <div style={{ display:"inline-flex",maxWidth: "inherit", marginLeft: "25px" }} className="purchaseOrderGrid"> */}
                {/* <div className="createSalesInvoiceGrid"> */}
                <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>

                    {renderFormElements({
                        elementList: ["assignee", "customerId", "plantId", "fromDate", "toDate"],
                        param: outStandingStatementParams,
                    })}
                </div>
                <p className="formSubmitErrorTextArea" style={{ textAlign: "center", fontSize: "16px" }}>
                    {outStandingErrors[0]}
                </p>
            </div>
        </div>
    </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return { isLogged: state.isLogged }
}

const mapDispatchToProps = (dispatch) => {

    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        addToOutStandingParams: (value) => dispatch(addToOutStandingParams(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutStandingParams);

