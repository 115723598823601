import React, { useState, useEffect } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import dashboard from "../../assets/dashboard.svg";
import "../styles/Dashboard.css";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import ProductionReport from "./ProductionReportPage";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const ProductionInward=({isLogged})=>{

  const [printType,setPrintType] = useState("production-detail-date");
  var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "productionInwardDetails") }, sectionName: "" };

  const [detail,setDetail]=useState({})
    const [queryParams, setQueryParams] = useState({
        fromDate: {
          inputType: "dateFromEditPage",
          value: "04-01-" + new Date().getFullYear(),
          hintText: "Inward Date From",
          mandatory: true,
          error: false,
          mindate: true,
          maxdate: true,
          disabled:false
        },
        toDate: {
          inputType: "dateFromEditPage",
          value: new Date(),
          hintText: "To Date",
          mandatory: true,
          error: false,
          maxdate: true,
          disabled:false
        },
        sectionNo: {
          inputType: "options",
          value: "",
          options: [],
          hintText: "Section No",
          mandatory: false,
          error: false,
          colSpan:4,
          disabled:false
        },
        bundleNoFrom: {
          inputType: "options",
          value: "",
          options: [],
          hintText: "Bundle No From",
          mandatory: false,
          error: false,
          colSpan:4,
          disabled:false
        },
        bundleNoTo: {
          inputType: "options",
          value: "",
          options: [],
          hintText: "Bundle No To",
          mandatory: false,
          error: false,
          colSpan:4,
          disabled:false
        },
        // weightFrom: {
        //   inputType: "text",
        //   value: "",
        //   options: [],
        //   hintText: "Weight From",
        //   mandatory: false,
        //   error: false,
        //   colSpan:4,
        //   disabled:false
        // },
        // weightTo: {
        //   inputType: "text",
        //   value: "",
        //   options: [],
        //   hintText: "Weight To",
        //   mandatory: false,
        //   error: false,
        //   colSpan:4,
        //   disabled:false
        // },
        printType:{
          inputType: "options",
          value: printType,
          options: [
                    {optionId:"production-detail-date",optionName:"Production Date"},
                    {optionId:"production-detail-section",optionName:"Production Detail Section"},
                    {optionId:"production-detail-bundle",optionName:"Production Bundle Details"},
                    {optionId:"production-abstract",optionName:"Production Abstract"},
                    {optionId:"section-wise",optionName:"Section Wise"},
                    {optionId:"ageing-no",optionName:"Ageing No"},
                    {optionId:"cutting-length",optionName:"Cutting Length"},
                    {optionId:"po-customer",optionName:"Customer Wise"},
                    {optionId:"po",optionName:"Production No"},
                  ],
                  
          hintText: "Print Type",
          mandatory: false,
          colSpan:6,
          error: false,
          disabled:false
        }
      });

      const [filterBy,setFilterBy] = useState({
        poNo: {
          inputType: "options",
          value:"" ,
          options:[],
          hintText: "Po No",
          mandatory: false,
          error: false,
          disabled:false
        },
        cuttingLength: {
          inputType: "options",
          value:"" ,
          options:[],
          hintText: "C/L",
          mandatory: false,
          error: false,
          disabled:false
        },
        ageing: {
          inputType: "options",
          value:"" ,
          options:[],
          hintText: "Ageing",
          mandatory: false,
          error: false,
          disabled:false
        },

      })
      const [bundleNoList,setBundleNoList] = useState([])
      const [bundleLineItems,setBundleLineItems]= useState([])


    const bundleList =async()=>{
      const bundleConfig = await fetchData({
        requestingPage: "sectionMaster",
        method: "post",
        url: "fetch/bundle-line-item",
        headers: { token: isLogged.accessToken, module: "Packing Slip" },
      })
      if(bundleConfig.msg==="success"){
        const updateBundleList = bundleConfig.asset.bundleList.map((bundle) =>({
          ...bundle,
          optionId: bundle.bundleId,
          optionName: bundle.bundleId.toString().padStart(6,'0')
        }))
        
        setBundleNoList(updateBundleList)
        return updateBundleList
      }
    }

    async function getItems() {
      let itemData = await fetchData({
        requestingPage: "itemList",
        method: "post",
        url: "fetch/items",
        data:{tab:"Active",type:"Sales"},
        headers: { token: isLogged.accessToken, module: "Items" },
      });
  
      let itemList = [];
      if (itemData.msg === "success") {
  
        itemData.asset.forEach((item) => {
          let itemObj = {};
          itemObj.optionId = item.item.itemCode;
          itemObj.optionName = item.item.itemCode
          itemObj.itemId = item.item.itemId;
          itemObj.mrp = item.item.mrp;
          itemObj.categoryId = item.item.categoryId;
          itemObj.sectionNo = item.item.itemCode;
          itemObj.sectionName = item.item.itemName;
  
          itemList.push(itemObj);
        });
        return itemList
      }
    }


    async function getSalesOrderApproved() {
      let salesOrder = await fetchData({
        requestingPage: "customerList",
        method: "post",
        url: "so-fetch/so-approved-list-mini",
        headers: { token: isLogged.accessToken, module: "Bundle Creation" },
      });
  
      if (salesOrder.msg === "success") {
        return salesOrder.asset
      }
      else {
        return []
      }
    };

    async function cuttingLength (){
      var data = await fetchData({
        requestingPage: "sectionMaster",
        method: "post",
        url: "fetch/cutting-length",
        headers: { token: isLogged.accessToken, module: "Cutting Length" },
      });
           if(data.msg==="success"){
            return data.asset
           }
    }

  async function getProductionDetail(){
    let keys = Object.keys(queryParams);
    let serObj ={} 
    keys.map(key=>(serObj[key]=queryParams[key].value))
     var data = await fetchData({
        requestingPage: "itemList",
        method: "post",
        url: "production-fetch/production-inward-report",
        data:serObj,
        headers: { token: isLogged.accessToken, module: "Production Inward" },
    });
    if(data.msg==="success"){
      setDetail(data.asset)
      setBundleLineItems(data.asset?.bundleLineItems)
    }
    // console.log(data,"data")
  }

  const getAgingReportList = async () => {
    var data = await fetchData({
      requestingPage: "sectionMaster",
      method: "post",
      url: "so-fetch/aging-report-list",
      headers: { token: isLogged.accessToken, module: "Aging Report" },
    });
    if (data.msg === "success") {
     return data.asset
    } else {
      // console.log(data);
    }
  };

    const getInformation=async()=>{
      let bundles = await bundleList()
      let sectionList = await getItems();
      let PoNoList = await getSalesOrderApproved()
      let clList = await cuttingLength();
      let ageingReportList= await getAgingReportList()
      

      let paramsCopy = Object.assign(queryParams);
        paramsCopy = update(paramsCopy, {
          bundleNoFrom: { options: { $set: bundles} },
          sectionNo: { options: { $set: sectionList} },
        });
        setQueryParams(paramsCopy);

      let filterCopy = Object.assign(filterBy);
      filterCopy = update(filterCopy, {
          poNo: { options: { $set: PoNoList} },
          cuttingLength: { options: { $set: clList} },
          ageing: { options: { $set:ageingReportList } },
        });
        setFilterBy(filterCopy);
    }

    useEffect(()=>{
      getInformation()
    },[])

    useEffect(()=>{
      let {sectionNo,bundleNoFrom,bundleNoTo}=queryParams
      let filteredList=detail?.bundleLineItems

      if(sectionNo.value !== ""){
       let tempSectionList = filteredList.filter((list)=>{ return list.sectionNo===sectionNo.value})
       filteredList = Array.from(new Set(tempSectionList.map((item) => item)));
      }
      if(bundleNoFrom.value){
       let tempBundleFromDateList = filteredList.filter((list)=>{ return list.bundleId>=bundleNoFrom.value})
       filteredList = Array.from(new Set(tempBundleFromDateList.map((item) => item)));
        }
        if(bundleNoTo.value){
         let tempBundleToDateList = filteredList.filter((list)=>{ return list.bundleId<=bundleNoTo.value})
         filteredList = Array.from(new Set(tempBundleToDateList.map((item) => item)));
          }

        setBundleLineItems(filteredList)
        
    },[queryParams.sectionNo.value,queryParams.bundleNoFrom.value,queryParams.bundleNoTo.value])

    const disableInput =(elementList,value)=>{
      let filterCopy = Object.assign(filterBy);
      let key = Object.keys(filterBy);

      elementList.forEach((item)=>{
        filterCopy=update(filterCopy,{
          [item]:{disabled:{$set:value}},
        })
        //for updating rest of the inputs depends  on value
        let index = key.indexOf(item)
        key.splice(index,1)

      })
      key.forEach((element)=>{
        filterCopy=update(filterCopy,{
          [element]:{disabled:{$set:!value}},
        })
      })
      setFilterBy(filterCopy)
    }

    const paramsDisableElement=()=>{
      let paramsCopy = Object.assign(queryParams);
      paramsCopy = update(paramsCopy, {
        sectionNo:{disabled:{$set:true}},
        bundleNoFrom:{disabled:{$set:true}},
        bundleNoTo:{disabled:{$set:true}},        
      });
      setQueryParams(paramsCopy)
    }

    useEffect(()=>{
      if(printType){
        let paramsCopy = Object.assign(queryParams);
        if(["production-detail-date","production-detail-section","production-detail-bundle"].includes(printType)){
          paramsCopy = update(paramsCopy, {
            sectionNo:{disabled:{$set:false}},
            bundleNoFrom:{disabled:{$set:false}},
            bundleNoTo:{disabled:{$set:false}},        
          });
          setQueryParams(paramsCopy)
          disableInput(["poNo","cuttingLength","ageing"],false)
        }
        if(["po-customer","po"].includes(printType)){
          paramsDisableElement()
          disableInput(["poNo"],false)
        }
        if(printType==="cutting-length"){
          paramsDisableElement()
          disableInput(["cuttingLength"],false)
        }
        if(printType==="ageing-no"){
          paramsDisableElement()
          disableInput(["ageing"],false)
        }
        if(printType==="section-wise"){
          paramsCopy = update(paramsCopy, {
            sectionNo:{disabled:{$set:false}},
            bundleNoFrom:{disabled:{$set:false}},
            bundleNoTo:{disabled:{$set:false}},       
          });
          setQueryParams(paramsCopy)
          disableInput(["cuttingLength","ageing"],false)
        }
        if(printType==="production-abstract"){
          paramsDisableElement()
          disableInput(["poNo","cuttingLength","ageing"],true)
        }
      }
    },[printType])

    useEffect(()=>{
      let list =filterList();
      setBundleLineItems(list);
    },[filterBy])

    const  filterList=()=>{
      let {poNo,cuttingLength,ageing} = filterBy;
      
      let filteredList=detail.bundleLineItems
      if(poNo.value !== ""){
       let tempPoNoList = filteredList.filter((list)=>{ return list.soId===poNo.value})
       filteredList = Array.from(new Set(tempPoNoList.map((item) => item)));
      }
      if(cuttingLength.value !== ""){
       let tempClList = filteredList.filter((list)=>{ return list.cuttingLengthId===cuttingLength.value})
       filteredList = Array.from(new Set(tempClList.map((item) => item)));
        }    
      if(ageing.value !== ""){
           let tempAgeingList = filteredList.filter((list)=>{ return list.batchNo===ageing.value})
           filteredList = Array.from(new Set(tempAgeingList.map((item) => item)));
          }
        
          return filteredList;
     
    }

    useEffect(()=>{
      if(printType){
        getProductionDetail()
      }
    },[printType,queryParams.fromDate.value,queryParams.toDate.value])

    const updateSaleOrderParameters = ({ param, paramName, key, value }) => {
        let paramsCopy = Object.assign(param);
        paramsCopy = update(paramsCopy, {
          [paramName]: { [key]: { $set: value } },
        });
        if(param.poNo){
          setFilterBy(paramsCopy)
        }else{
          if(paramName==="bundleNoFrom" && value){
            let bundleToFilteredList =bundleNoList.filter((bundle)=>bundle.bundleId>value)
            paramsCopy.bundleNoTo.options =bundleToFilteredList
          }
          setQueryParams(paramsCopy);
          setPrintType(paramsCopy.printType.value)
        }
      };

    const renderFormElements = ({ elementList, params }) => {
        return (
            elementList.map((item,ind) => {
              return (
                  <FormElement
                    key={ind}
                    inputType={params[item].inputType}
                    value={params[item].value}
                    setInput={(value) => {
                      updateSaleOrderParameters({
                        param: params,
                        paramName: item,
                        key: "value",
                        value: value,
                      });
                    }}
                    hintText={params[item].hintText}
                    mandatory={params[item].mandatory}
                    colSpan={params[item].colSpan}
                    options={
                      params[item].inputType === "options" ||
                      params[item].inputType === "multiSelect"
                        ? params[item].options
                        : null
                    }
                    error={params[item].error}
                    isClearable={params[item].isClearable}
                    maxdate={params[item].maxdate}
                    disabled={params[item].disabled}
                  />
                // </div>
              );
            })
          
        );
      };

    const renderSubSection =()=>{
      let params={
        dateFrom:queryParams?.fromDate?.value,
        toDate:queryParams?.toDate?.value,
        poNo:filterBy?.poNo?.value||""
      }
        return(
          <>
               <div className="inventoryPageFilterGrid">
                <div
                    className="createPlantGeneralGrid"
                    style={{
                        margin: "0 0",
                        gridTemplateRows: "repeat(3, minmax(4rem, auto))",
                        flex: "3.5 1 0%",
                        gridTemplateColumns: "repeat(15, 1fr)",
                        gap: "10px 46px"
                    }}
                >
                   {renderFormElements({
                        elementList: Object.keys(queryParams),
                        params: queryParams,
                     })}
                  </div>
                </div>
                 <div className="dashboardCard" 
                  style={{display: "grid",gridTemplateColumns: "1fr 1fr 1fr",gridGap: "10px",padding: "10px",marginBottom:"20px"
                }}>
                 {renderFormElements({
                   elementList: Object.keys(filterBy),
                   params: filterBy,
                 })}
                </div>
                {
                 detail.plantName && bundleLineItems!==undefined && bundleLineItems.length?
                 <ProductionReport formType={printType} data={detail} 
                 bundleLineItems={bundleLineItems}
                 paramsDetail={params}
                 />
                 :
                 <div className="noRecordsYet">No records found</div>
                }

                </>
        )
    }
return(
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Sales" selectedSubMenu="Production Inward" />
        <div className="detailsContainer">
          <TopBanner />
          <PageTitle imgSrc={dashboard} pageTitle="Production Inward" buttonList={[downloadButton]}/>
          {renderSubSection()}
        </div>
      </div>
    </React.Fragment>
)
}
const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) =>
        dispatch(addToken(accessToken, employeeId)),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(ProductionInward);