import React from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import itemMenuIcon from "../../assets/item_menu.svg";

import categoryIcon from "../../assets/category.svg";
import brandIcon from "../../assets/brand.svg";
import itemTypeIcon from "../../assets/types.svg";
import processIcon from "../../assets/process.svg";
import purchaseCostIcon from "../../assets/cost.svg";
import itemPriceTypeIcon from "../../assets/pricetype.svg";

import itemIcon from "../../assets/item.svg";
import salesPriceIcon from "../../assets/saleprice.svg";
import itemTaxIcon from "../../assets/tax.svg";
import itemCurrency from "../../assets/itemCurrency.svg";
import "../styles/item.css";
import { useHistory } from "react-router-dom";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from "react-redux";
import sectionMaster from "assets/section-master.svg";
import cuttingLengthIcon  from "assets/laser-cutting-color.svg";

const ItemMenu = ({ isLogged }) => {
  const history = useHistory();

  const itemMenuParams = [
    { menu: "Item Category", route: "itemcategory", icon: categoryIcon },
    { menu: "Item Brand", route: "itembrand", icon: brandIcon },
    { menu: "Item Type", route: "itemtype", icon: itemTypeIcon },
    { menu: "Item Process", route: "itemprocess", icon: processIcon },
    { menu: "Items", route: "item", icon: itemIcon },
    { menu: "Item Purchase Cost",route: "itempurchasecost",icon: purchaseCostIcon,},
    { menu: "Item Price Type",route: "itempricetype",icon: itemPriceTypeIcon,},
    { menu: "Item Tax", route: "itemtax", icon: itemTaxIcon },
    { menu: "Item Sales Price", route: "itemsalesprice", icon: salesPriceIcon },
    { menu: "Item Currency", route: "itemcurrency", icon: itemCurrency },
    { menu: "Cutting Length", route: "cutting-length",icon: cuttingLengthIcon},
    // { menu: "Section Master", route: "section-master", icon: sectionMaster },
    { menu: "Premium Charges", route: "premium-charges", icon: sectionMaster },
    // { menu: "Sales Items", route: "sales-items", icon: itemIcon },
    { menu: "Section Master", route: "section-master", icon: itemIcon },
    { menu: "Die Master", route: "die-master", icon: itemIcon },
  ];

  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
        <div className="detailsContainer">
          <TopBanner />
          <PageTitle imgSrc={itemMenuIcon} pageTitle="Item Menu" />
          {/* <div className="formArea"> */}
          <span className="itemImagesGrid" style={{display:"flex",flexWrap:"wrap",maxWidth:"80%"}}>
            {itemMenuParams.map((obj,i) =>
              isLogged.access[obj.menu] ? (
                <div key={i}
                  className={
                    isLogged.access[obj.menu] !== null
                      ? "singleItem"
                      : "singleItemDisabled"
                  }
                  onClick={() => {
                    if (isLogged.access[obj.menu] !== null) {
                      history.push(`/${obj.route}`);
                    }
                  }}
                  alt={obj.menu}
                  title={
                    isLogged.access[obj.menu] !== null ? obj.menu : "No Access"
                  }>
                  <span className="itemText"></span>
                  <img
                    className={
                      isLogged.access[obj.menu] !== null
                        ? "itemImage"
                        : "itemImageDisabled"
                    }
                    src={obj.icon}
                    alt="Category"
                  />
                  <span className="itemText">{obj.menu}</span>
                </div>
              ) : null
            )}
          </span>
          {/* </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(ItemMenu);
