import React, { useEffect, useState } from "react";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";

import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { connect } from "react-redux";
import fetchData from "../../serverCall/fetchData";
import CreateEditModal from "../SmallComponents/CreateEditModal";

export const purchaseImportParamsList = {
  contractDetails: ["sellerId", "poNo", "shipmentDate", "packingDoc"],
  advancePaymentDetails: [
    "advancePaymentDollar1",
    "advancePaymentRupee1",
    "advancePaymentDate1",
  ],
  advancePaymentDetails2: [
    "advancePaymentDollar2",
    "advancePaymentRupee2",
    "advancePaymentDate2",
  ],
  advancePaymentDetails3: [
    "advancePaymentDollar3",
    "advancePaymentRupee3",
    "advancePaymentDate3",
  ],
  invoiceDetails: ["invoiceNo", "invoiceDate"],
  balancePaymentDetails: [
    "balancePaymentDollar1",
    "balancePaymentRupee1",
    "balancePaymentDate1",
  ],
  balancePaymentDetails2: [
    "balancePaymentDollar2",
    "balancePaymentRupee2",
    "balancePaymentDate2",
  ],
  balancePaymentDetails3: [
    "balancePaymentDollar3",
    "balancePaymentRupee3",
    "balancePaymentDate3",
  ],
  billOfLading: ["billOfLadingNo", "vesselName", "billOfLadingDoc"],
  port: [
    "portArrivalDate",
    "portDetail",
    "freeTimeDurationDays",
    "freeTimeExpiryDate",
    "arrivalSeplDate",
    "arrivalSeplDays",
  ],
  bankDetail: ["bankName", "bankRefNo", "bankDate", "bankDoc", "bankName2", "bankRefNo2", "bankDate2", "bankDoc2"],
  containerDetail: ["containerNo", "containerWeightMt", "containerSize", "sealNo", "dcNo"],
  clearingAgent: [
    "clearingAgentId",
    "clearingAgentBillNo",
    "clearingAgentBillDate",
    "clearingAgentBillAmount",
    "clearingAgentTaxAmount",
    "clearingAgentTotalAmount",
    "clearingDoc",
  ],
  liner: [
    "linerName",
    "linerBillNo",
    "linerBillDate",
    "linerBillAmount",
    "linerBillTaxAmount",
    "linerBillTotalAmount",
    "linerBillDoc",
  ],
  remarks: ["remarks", "remarksParty"],
  insurance: [
    "insuranceCompanyName",
    "insuranceNo",
    "insuranceDate",
    "insuranceDollar",
    "insuranceRupee",
    "insuranceDoc",
  ],
  transporter: [
    "transporterId",
    "transporterBillNo",
    "transporterBillDate",
    "transporterBillAmount",
    "transporterBillTaxAmount",
    "transporterBillTotalAmount",
    "transporterDoc",
  ],
  itemDetail: [
    "storageId",
    "containerId",
    "itemId",
    "from",
    "to",
    "netWeight",
    "grossWeight",
    "ratePerMt",
    "quantity",
    "netAmount",
    "currencyConversionRate",
    "netAmountINR"
  ],
  otherCharges: [
    "exciseTaxAmount",
    "splExciseTaxAmount",
    "interestAmount",
    "penaltyAmount",
    "otherChargesDoc"
  ],
  itemDetailTaxAmount: [
    // "exciseTaxAmount",
    "cessTaxAmount",
    // "splExciseTaxAmount",
    "tcsAmount",
    "gstAmount",
    // 'interestAmount',
    // 'penaltyAmount',
    "netAmountAfterTax",
  ],
  itemDetailTaxPercentage: [
    // "exciseTaxPercentage",
    "cessTaxPercentage",
    // "splExciseTaxPercentage",
    "tcsPercentage",
    "gstPercentage",
    // 'interestPercentage',
    // 'penaltyPercentage',
    "netAmountAfterTax",
  ],
  billOfEntry: [
    "billOfEntryNo",
    "billOfEntryDate",
    "billOfEntryReferralNo",
    "billOfEntryDoc",
  ]
};
const CreatePurchaseImport = ({ isLogged, addTokenToState, setSection }) => {

  const [contractDayCounts, setContractDayCounts] = useState({
    contractDate: "",
    contractDays: "",
  });

  const [showContractDays, setShowContractDays] = useState(false);
  const [showTaxPercent, setShowTaxPercent] = useState(false);

  const [purchaseImportParams, setPurchaseImportParams] = useState({
    // ------------------------------------------------------------------------------
    sellerId: {
      inputType: "options",
      value: "",
      hintText: "Seller name",
      options: [],
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Pick a Seller",
    },

    poNo: {
      inputType: "options",
      value: "",
      hintText: "Contract Number",
      mandatory: true,
      colSpan: 6,
      options: [],
      error: false,
      errorMessage: "Provide PO Number",
    },
    contractDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Contract date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick contract date",
    },
    contractDays: {
      inputType: "text",
      value: "",
      hintText: "Contract days",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Enter valid # days",
    },
    shipmentDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Shipment date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick shipment date",
    },
    packingDoc: {
      inputType: "upload",
      value: "",
      hintText: "Packing Document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Enter Packing info",
    },

    // ------------------------------------------------------------------------------
    advancePaymentDate1: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Advance paid date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick advance payment date",
    },
    advancePaymentDollar1: {
      inputType: "text",
      value: "",
      hintText: "Advance paid against contract ($)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Advance paid amount",
    },
    advancePaymentRupee1: {
      inputType: "text",
      value: "",
      hintText: "Advance paid against contract (₹)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Advance paid amount",
    },

    advancePaymentDate2: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Advance paid date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick advance payment date",
    },
    advancePaymentDollar2: {
      inputType: "text",
      value: "",
      hintText: "Advance paid against contract ($)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Advance paid amount",
    },
    advancePaymentRupee2: {
      inputType: "text",
      value: "",
      hintText: "Advance paid against contract (₹)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Advance paid amount",
    },

    advancePaymentDate3: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Advance paid date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick advance payment date",
    },
    advancePaymentDollar3: {
      inputType: "text",
      value: "",
      hintText: "Advance paid against contract ($)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Advance paid amount",
    },
    advancePaymentRupee3: {
      inputType: "text",
      value: "",
      hintText: "Advance paid against contract (₹)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Advance paid amount",
    },

    // ------------------------------------------------------------------------------
    invoiceNo: {
      inputType: "text",
      value: "",
      hintText: "Invoice no",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Provide invoice number",
    },
    invoiceDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Invoice date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    invoiceQuantity: {
      inputType: "text",
      value: "",
      hintText: "Invoice Qty (MT)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for invoice quantity",
    },

    // ------------------------------------------------------------------------------
    balancePaymentDollar1: {
      inputType: "text",
      value: "",
      hintText: "Balance paid against invoice ($)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Balance payment",
    },
    balancePaymentRupee1: {
      inputType: "text",
      value: "",
      hintText: "Balance paid against invoice (₹)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Balance payment",
    },
    balancePaymentDate1: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Balance Payment date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },

    balancePaymentDollar2: {
      inputType: "text",
      value: "",
      hintText: "Balance paid against invoice ($)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Balance payment",
    },
    balancePaymentRupee2: {
      inputType: "text",
      value: "",
      hintText: "Balance paid against invoice (₹)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Balance payment",
    },
    balancePaymentDate2: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Balance Payment date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },

    balancePaymentDollar3: {
      inputType: "text",
      value: "",
      hintText: "Balance paid against invoice ($)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Balance payment",
    },
    balancePaymentRupee3: {
      inputType: "text",
      value: "",
      hintText: "Balance paid against invoice (₹)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Balance payment",
    },
    balancePaymentDate3: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Balance Payment date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },

    // ------------------------------------------------------------------------------
    billOfLadingDoc: {
      inputType: "upload",
      value: "",
      hintText: "Bill of ladding document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    billOfLadingNo: {
      inputType: "text",
      value: "",
      hintText: "Bill of Ladding (B/L) no",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    vesselName: {
      inputType: "text",
      value: "",
      hintText: "Vessel name",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },

    // ------------------------------------------------------------------------------ 
    billOfEntryNo: {
      inputType: "text",
      value: "",
      hintText: "Bill of Entry (B/E) no",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    billOfEntryDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Bill of Entry date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    billOfEntryReferralNo: {
      inputType: "text",
      value: "",
      hintText: "Script Value",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Script Value",
    },
    billOfEntryDoc: {
      inputType: "upload",
      value: "",
      hintText: "Bill of entry document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    // ------------------------------------------------------------------------------
    containerNo: {
      inputType: "text",
      value: "",
      hintText: "Container no",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Container Number",
    },
    containerWeightMt: {
      inputType: "text",
      value: "",
      hintText: "Container weight (MT)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Enter a valid container weight",
    },
    containerSize: {
      inputType: "text",
      value: "",
      hintText: "Container size (feet)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Enter a valid container size",
    },
    sealNo: {
      inputType: "text",
      value: "",
      hintText: "Seal no",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Seal Number",
    },
    dcNo: {
      inputType: "text",
      value: "",
      hintText: "Delivary Chellan (DC) no",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide Delivary Chellan Number",
    },

    // ------------------------------------------------------------------------------
    portArrivalDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Arrived on port",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    portDetail: {
      inputType: "text",
      value: "",
      hintText: "Port details",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    freeTimeDurationDays: {
      inputType: "text",
      value: "",
      hintText: "Free time duration (days)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for freetime duration",
    },
    freeTimeExpiryDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Free time expiry date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    arrivalSeplDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Arrived Factory date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    arrivalSeplDays: {
      inputType: "text",
      value: "",
      hintText: "Arrived Factory days",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for arrival Factory",
    },

    // --------------------------------bank 1----------------------------------------------
    bankName: {
      inputType: "text",
      value: "",
      hintText: "Bank Name",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    bankRefNo: {
      inputType: "text",
      value: "",
      hintText: "Bank Ref no",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    bankDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Bank date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    bankDoc: {
      inputType: "upload",
      value: "",
      hintText: "Bank document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },

    //---------------------------------------------------bank 2----------------------
    bankName2: {
      inputType: "text",
      value: "",
      hintText: "Bank Name 2",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    bankRefNo2: {
      inputType: "text",
      value: "",
      hintText: "Bank Ref no 2",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    bankDate2: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Bank date 2",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    bankDoc2: {
      inputType: "upload",
      value: "",
      hintText: "Bank document 2",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },

    // ------------------------------------------------------------------------------
    clearingAgentId: {
      inputType: "options",
      value: "",
      hintText: "Agent Name",
      mandatory: false,
      options: [],
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    clearingAgentBillNo: {
      inputType: "text",
      value: "",
      hintText: "Clearing agent bill No",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    clearingAgentBillDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Clearing agent bill date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Please pick a date",
    },
    clearingAgentBillAmount: {
      inputType: "float",
      value: "",
      hintText: "Clearing agent bill amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number clearing agent bill",
    },
    clearingDoc: {
      inputType: "upload",
      value: "",
      hintText: "Clearing document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    clearingAgentTaxAmount: {
      inputType: "float",
      value: "",
      hintText: "Clearing agent Tax amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number clearing agent bill Tax",
    },
    clearingAgentTotalAmount: {
      inputType: "float",
      value: "",
      hintText: "Clearing agent Total amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      disabled: true,
      errorMessage: "Provide valid number clearing agent bill",
    },
    // ------------------------------------------------------------------------------
    remarks: {
      inputType: "text",
      value: "",
      hintText: "Remarks",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    remarksParty: {
      inputType: "text",
      value: "",
      hintText: "Remarks party",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },

    // ------------------------------------------------------------------------------
    insuranceCompanyName: {
      inputType: "text",
      value: "",
      hintText: "Company Name",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    insuranceNo: {
      inputType: "text",
      value: "",
      hintText: "Insurance No",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    insuranceDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Insurance Date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    insuranceDollar: {
      inputType: "text",
      value: "",
      hintText: "Insurance Amount in ($) Dollar",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    insuranceRupee: {
      inputType: "text",
      value: "",
      hintText: "Insurance Amount in (₹) Rupees",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Insurance amount",
    },
    insuranceDoc: {
      inputType: "upload",
      value: "",
      hintText: "Insurance Document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number for Insurance amount",
    },

    // ------------------------------------------------------------------------------
    transporterId: {
      inputType: "options",
      value: "",
      hintText: "Transporter Name",
      options: [],
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    transporterBillNo: {
      inputType: "text",
      value: "",
      hintText: "WayBill No",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    transporterBillDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Bill Date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    transporterBillAmount: {
      inputType: "float",
      value: "",
      hintText: "Transport Amount Charge",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    transporterBillTaxAmount: {
      inputType: "float",
      value: "",
      hintText: "Transporter Tax Amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number Transporter Tax",
    },
    transporterBillTotalAmount: {
      inputType: "float",
      value: "",
      hintText: "Transporter Total Amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      disabled: true,
      errorMessage: "Provide valid number clearing agent bill",
    },
    transporterDoc: {
      inputType: "upload",
      value: "",
      hintText: "WayBill Document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    // ------------------------Liner------------------------------------------------------
    linerName: {
      inputType: "text",
      value: "",
      hintText: "Liner Bill Name",
      colSpan: 6,
      mandatory: false,
      error: false,
      errorMessage: "",
    },
    linerBillNo: {
      inputType: "text",
      value: "",
      hintText: "Liner Bill No",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    linerBillDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Liner Bill Date",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    linerBillAmount: {
      inputType: "float",
      value: "",
      hintText: "Liner Bill Amount Charge",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    linerBillTaxAmount: {
      inputType: "float",
      value: "",
      hintText: "Liner Bill Tax amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Provide valid number LinerBill Tax",
    },
    linerBillTotalAmount: {
      inputType: "float",
      value: "",
      hintText: "Liner Bill Total amount",
      mandatory: false,
      colSpan: 6,
      error: false,
      disabled: true,
      errorMessage: "Provide valid number LinerBill Total",
    },
    linerBillDoc: {
      inputType: "upload",
      value: "",
      hintText: "Liner Bill document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    ratePerMt: {
      inputType: "text",
      value: "",
      hintText: "Rate/MT",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Enter a valid Rate",
    },
    quantity: {
      inputType: "text",
      value: "",
      hintText: "Qty (MT)",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Provide valid quantity",
    },
    itemId: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Item Name",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Select item",
    },
    from: {
      inputType: "text",
      value: "",
      hintText: "Weight From",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: true,
    },
    to: {
      inputType: "text",
      value: "",
      hintText: "Weight To",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: true,
    },
    netWeight: {
      inputType: "text",
      value: "",
      hintText: "Net Weight",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: true,
    },
    grossWeight: {
      inputType: "text",
      value: "",
      hintText: "Gross Weight",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Select Order date above to autofill this field",
      disabled: true,
    },
    containerId: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Container No",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "Select Container",
    },
    netAmount: {
      inputType: "text",
      value: "",
      hintText: "Net Amount (USD)",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "",
      disabled: true,
    },
    currencyConversionRate: {
      inputType: "text",
      value: "",
      hintText: "Currency Conversion Rate",
      mandatory: true,
      colSpan: 4,
      error: false,
      errorMessage: "Conversion Rate is mandatory for currency conversion",
      disabled: false,
    },
    netAmountINR: {
      inputType: "text",
      value: "",
      hintText: "Net Amount (INR)",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "",
      disabled: true,
    },

    storageId: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Storage",
      mandatory: true,
      colSpan: 12,
      error: false,
      errorMessage: "Select Storage",
    },
    // ------------------------------------------------------------------------------
    exciseTaxAmount: {
      inputType: "text",
      value: "",
      hintText: "Excise Tax amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid excise tax amount",
    },
    cessTaxAmount: {
      inputType: "text",
      value: "",
      hintText: "Cess Tax Amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid cess amount",
    },
    splExciseTaxAmount: {
      inputType: "text",
      value: "",
      hintText: "Special Excise Tax Amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid special excise tax amount",
    },
    tcsAmount: {
      inputType: "text",
      value: "",
      hintText: "TCS Amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid tcs amount",
    },
    gstAmount: {
      inputType: "text",
      value: "",
      hintText: "GST Amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid gst amount",
    },

    exciseTaxPercentage: {
      inputType: "text",
      value: "",
      hintText: "Excise Tax Percentage",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid excise tax percentage",
    },
    cessTaxPercentage: {
      inputType: "text",
      value: "",
      hintText: "Cess Tax Percentage",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid cess tax percentage",
    },
    splExciseTaxPercentage: {
      inputType: "text",
      value: "",
      hintText: "Special Excise Tax Percentage",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid spl excise tax percentage",
    },
    tcsPercentage: {
      inputType: "text",
      value: "",
      hintText: "TCS Percentage",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid tcs percentage",
    },
    gstPercentage: {
      inputType: "text",
      value: "",
      hintText: "GST Percentage",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter a valid gst percentage",
    },
    interestAmount: {
      inputType: "float",
      value: "",
      hintText: "Interest Amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter valid Interest Amount"
    },
    interestPercentage: {
      inputType: "float",
      value: "",
      hintText: "Interest Percentage",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter valid Interest Percentage"
    },
    penaltyAmount: {
      inputType: "float",
      value: "",
      hintText: "Penalty Amount",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter valid Penalty Amount"
    },
    otherChargesDoc: {
      inputType: "upload",
      value: "",
      hintText: "Other Charges document",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
    penaltyPercentage: {
      inputType: "float",
      value: "",
      hintText: "Penalty Percentage",
      mandatory: false,
      colSpan: 4,
      error: false,
      errorMessage: "Enter valid Penalty Percentage"
    },
    netAmountAfterTax: {
      inputType: "text",
      value: "",
      hintText: "Net Amount After Tax (INR)",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
      disabled: true,
    },
  });

  const [containerLineItems, setContainerLineItems] = useState([]);

  const [linerBillItems, setLinerBillItems] = useState([]);

  const [itemLineItems, setItemLineItems] = useState([]);

  const [packingDocument, setPackingDocument] = useState("");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState("Uploading form. Please wait...");

  const [invoiceList, setInvoiceList] = useState({});

  const [tablist, setTablist] = useState([
    { id: "invoice1", name: "Invoice 1" },
  ]);

  const [nextTabNo, setNextTabNo] = useState(2);
  const [selectedTab, setSelectedTab] = useState("invoice1");

  const [poSpecificItems, setPoSpecificItems] = useState({});

  useEffect(() => {
    getInformation();
  }, []);

  async function getInformation() {
    var paramsCopy = Object.assign(purchaseImportParams);
    await checkToken();

    var vendorList = await getVendorList();
    var clearingList = await getClearingList();
    var transporterList = await getTransporterList();
    var storageList = await getStorageList();

    paramsCopy = update(paramsCopy, {
      sellerId: { options: { $set: vendorList } },
      clearingAgentId: { options: { $set: clearingList } },
      transporterId: { options: { $set: transporterList } },
      storageId: { options: { $set: storageList } },
    });
    setPurchaseImportParams(paramsCopy);
  }

  async function getVendorList() {
    var data = await fetchData({
      requestingPage: "vendorList",
      method: "get",
      url: "fetch/import-pre-create-vendor-names",
      headers: { token: isLogged.accessToken, module: "Purchase Import" },
    });
    if (data.msg === "success") {
      var optionArray = [];
      data.asset.forEach((dataItem) => {
        var a = {
          optionName: dataItem.VendorName,
          optionId: dataItem.vendorId,
        };
        optionArray.push(a);
      });

      return optionArray;
    } else {
      console.log(data);
    }
  }

  async function getPoList(vendorId) {
    var data = await fetchData({
      requestingPage: "vendorList",
      method: "post",
      url: `po-fetch/import-po-list/vendorid=${vendorId}`,
      headers: { token: isLogged.accessToken, module: "Purchase Import" },
    });
    if (data.msg === "success") {
      var optionArray = [];

      var itemsForAllPo = {};
      console.log(data.asset,"data.asset")
      data.asset.forEach((dataItem) => {
        var a = { optionName: dataItem.poNo, optionId: dataItem.poId };
        optionArray.push(a);

        var itemsInPo = [];
        dataItem.lineItems.forEach((line) => {
          var itemInPo = {
            optionId: line.itemId,
            optionName: line.itemName,
            from: line.from,
            to: line.to,
            netWeight: line.netWeight,
            grossWeight: line.grossWeight,
          };
          itemsInPo.push(itemInPo);
        });

        itemsForAllPo[dataItem.poId] = itemsInPo;
      });
      setPoSpecificItems(itemsForAllPo);

      return optionArray;
    } else {
      console.log(data);
    }
  }

  async function getClearingList() {
    var data = await fetchData({
      requestingPage: "vendorList",
      method: "get",
      url: "fetch/clearing-agent-list",
      headers: { token: isLogged.accessToken, module: "Purchase Import" },
    });
    if (data.msg === "success") {
      var optionArray = [];
      data.asset.forEach((dataItem) => {
        var a = {
          optionName: dataItem.clearingAgentName,
          optionId: dataItem.clearingAgentId,
        };
        optionArray.push(a);
      });

      return optionArray;
    } else {
      console.log(data);
    }
  }

  async function getTransporterList() {
    var data = await fetchData({
      requestingPage: "vendorList",
      method: "get",
      url: "fetch/transporter-list",
      headers: { token: isLogged.accessToken, module: "Purchase Import" },
    });
    if (data.msg === "success") {
      var optionArray = [];
      data.asset.forEach((dataItem) => {
        var a = {
          optionName: dataItem.transporterName,
          optionId: dataItem.transporterId,
        };
        optionArray.push(a);
      });

      return optionArray;
    } else {
      console.log(data);
    }
  }

  async function getStorageList() {
    var data = await fetchData({
      requestingPage: "vendorList",
      method: "get",
      url: "fetch/storages",
      headers: { token: isLogged.accessToken, module: "Purchase Import" },
    });
    if (data.msg === "success") {
      var optionArray = [];
      data.asset.forEach((dataItem) => {
        var a = {
          optionName: `${dataItem.storageName} (${dataItem.plantName})`,
          optionId: dataItem.storageId,
        };
        optionArray.push(a);
      });

      return optionArray;
    } else {
      console.log(data);
    }
  }

  const history = useHistory();
  async function checkToken() {
    console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
    return true;
  }

  async function getImportPoDetail(importPoId) {
    var data = await fetchData({
      requestingPage: "vendorList",
      method: "get",
      url: `po-fetch/import-po-detail/${importPoId}`,
      headers: { token: isLogged.accessToken, module: "Purchase Import" },
    });
    if (data.msg === "success") {
      var date1 = new Date(data.asset[0].poDate);
      var date2 = new Date(data.asset[0].expectedDeliveryDate);

      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      setContractDayCounts({
        contractDate: data.asset[0].poDate,
        contractDays: `${Difference_In_Days} days`,
      });
      setShowContractDays(true);
    } else {
      console.log(data);
    }
  }

  useEffect(() => {
    if (purchaseImportParams.poNo.value !== "") {
      getImportPoDetail(purchaseImportParams.poNo.value);

      var paramsCopy = Object.assign(purchaseImportParams);
      paramsCopy = update(paramsCopy, {
        itemId: {
          options: { $set: poSpecificItems[purchaseImportParams.poNo.value] },
          value: { $set: "" },
        },
      });
      setPurchaseImportParams(paramsCopy);
    } else {
      setShowContractDays(false);
    }
  }, [purchaseImportParams.poNo]);
  useEffect(() => {
    if (purchaseImportParams.itemId.value !== "") {
      var paramCopy = Object.assign(purchaseImportParams);
      var tempObj =
        poSpecificItems[purchaseImportParams.poNo.value].filter(
          (i) => i.optionId === purchaseImportParams.itemId.value
        )[0] || {};
      paramCopy = update(paramCopy, {
        from: { value: { $set: tempObj["from"] } },
        to: { value: { $set: tempObj["to"] } },
        netWeight: { value: { $set: tempObj["netWeight"] } },
        grossWeight: { value: { $set: tempObj["grossWeight"] } },
      });
      setPurchaseImportParams(paramCopy);
    } else if (purchaseImportParams.itemId.value == "") {
      var paramCopy = Object.assign(purchaseImportParams);
      paramCopy = update(paramCopy, {
        from: { value: { $set: "" } },
        to: { value: { $set: "" } },
        netWeight: { value: { $set: "" } },
        grossWeight: { value: { $set: "" } },
      });
      setPurchaseImportParams(paramCopy);
    }
  }, [purchaseImportParams.itemId]);
  useEffect(async () => {
    if (purchaseImportParams.sellerId.value !== "") {
      var poList = await getPoList(purchaseImportParams.sellerId.value);

      var paramsCopy = Object.assign(purchaseImportParams);
      paramsCopy = update(paramsCopy, {
        poNo: { options: { $set: poList }, value: { $set: "" } },
        itemId: { options: { $set: [] }, value: { $set: "" } },
      });
      setPurchaseImportParams(paramsCopy);
    }
  }, [purchaseImportParams.sellerId]);

  useEffect(() => {
    var paramsCopy = Object.assign(purchaseImportParams);

    var totalWithTaxCalc = calculateNetAmountWithTax(paramsCopy);
    paramsCopy = update(paramsCopy, {
      netAmountAfterTax: { value: { $set: totalWithTaxCalc } },
    });
    setPurchaseImportParams(paramsCopy);
  }, [showTaxPercent]);

  useEffect(() => {
    if (purchaseImportParams.packingDoc.value === "") {
      setPackingDocument("");
    }
  }, [purchaseImportParams.packingDoc.value]);

  const calculateNetAmountWithTax = (paramsCopy) => {
    var cessTax = 0;
    var tcs = 0;
    var gst = 0;
    var interest = 0;
    var penalty = 0;

    if (showTaxPercent) {

      if (
        paramsCopy.cessTaxPercentage.value !== "" &&
        !isNaN(paramsCopy.cessTaxPercentage.value) &&
        parseFloat(paramsCopy.cessTaxPercentage.value) > 0 &&
        parseFloat(paramsCopy.cessTaxPercentage.value) < 100
      ) {
        cessTax = parseFloat(paramsCopy.cessTaxPercentage.value);
      }
      if (
        paramsCopy.tcsPercentage.value !== "" &&
        !isNaN(paramsCopy.tcsPercentage.value) &&
        parseFloat(paramsCopy.tcsPercentage.value) > 0 &&
        parseFloat(paramsCopy.tcsPercentage.value) < 100
      ) {
        tcs = parseFloat(paramsCopy.tcsPercentage.value);
      }

      if (
        paramsCopy.gstPercentage.value !== "" &&
        !isNaN(paramsCopy.gstPercentage.value) &&
        parseFloat(paramsCopy.gstPercentage.value) > 0 &&
        parseFloat(paramsCopy.gstPercentage.value) < 100
      ) {
        gst = parseFloat(paramsCopy.gstPercentage.value);
      }

      if (!isNaN(paramsCopy.netAmountINR.value)) {

        var cessTaxAmt = parseFloat(paramsCopy.netAmountINR.value) * (cessTax / 100);

        var subTotal = parseFloat(paramsCopy.netAmountINR.value) + cessTaxAmt

        var tcsAmt = subTotal * (tcs / 100);
        var subTotal2 = subTotal + tcsAmt;

        var gstAmt = subTotal2 * (gst / 100);
        var interestAmt = subTotal2 * (interest / 100);
        var penaltyAmt = subTotal2 * (penalty / 100);
        var totalWithTaxCalc = subTotal2 + gstAmt + interestAmt + penaltyAmt;

        return totalWithTaxCalc;
      } else {
        return 0;
      }
    } else {


      if (
        paramsCopy.cessTaxAmount.value !== "" &&
        !isNaN(paramsCopy.cessTaxAmount.value) &&
        parseFloat(paramsCopy.cessTaxAmount.value) > 0
      ) {
        cessTax = parseFloat(paramsCopy.cessTaxAmount.value);
      }

      if (
        paramsCopy.tcsAmount.value !== "" &&
        !isNaN(paramsCopy.tcsAmount.value) &&
        parseFloat(paramsCopy.tcsAmount.value) > 0
      ) {
        tcs = parseFloat(paramsCopy.tcsAmount.value);
      }

      if (
        paramsCopy.gstAmount.value !== "" &&
        !isNaN(paramsCopy.gstAmount.value) &&
        parseFloat(paramsCopy.gstAmount.value) > 0
      ) {
        gst = parseFloat(paramsCopy.gstAmount.value);
      }


      if (!isNaN(paramsCopy.netAmountINR.value)) {
        var totalWithTaxCalc =
          parseFloat(paramsCopy.netAmountINR.value) + cessTax + tcs + gst;

        return totalWithTaxCalc;
      } else {
        return 0;
      }
    }
  };

  const updatePurchaseValues = ({ paramName, key, value }) => {
    var paramsCopy = Object.assign(purchaseImportParams);

    paramsCopy = update(paramsCopy, {
      [paramName]: { [key]: { $set: value } },
    });

    if (paramName == 'clearingAgentBillAmount' || paramName == 'clearingAgentTaxAmount') {
      let clearingAgentTotal = parseFloat(paramsCopy['clearingAgentBillAmount'].value) + parseFloat(paramsCopy['clearingAgentTaxAmount'].value);
      clearingAgentTotal = clearingAgentTotal || parseFloat(paramsCopy['clearingAgentBillAmount'].value) || 0;
      paramsCopy = update(paramsCopy, { ['clearingAgentTotalAmount']: { ['value']: { $set: clearingAgentTotal || 0 } } })
    }

    if (paramName == 'transporterBillAmount' || paramName == 'transporterBillTaxAmount') {
      let transporterTotal = parseFloat(paramsCopy['transporterBillAmount'].value) + parseFloat(paramsCopy['transporterBillTaxAmount'].value);
      transporterTotal = transporterTotal || parseFloat(paramsCopy['transporterBillAmount'].value) || 0;
      paramsCopy = update(paramsCopy, { ['transporterBillTotalAmount']: { ['value']: { $set: transporterTotal || 0 } } })
    }

    if (paramName == 'linerBillAmount' || paramName == 'linerBillTaxAmount') {
      let linerTotal = parseFloat(paramsCopy['linerBillAmount'].value) + parseFloat(paramsCopy['linerBillTaxAmount'].value);
      linerTotal = linerTotal || parseFloat(paramsCopy['linerBillAmount'].value) || 0;
      paramsCopy = update(paramsCopy, { ['linerBillTotalAmount']: { ['value']: { $set: linerTotal || 0 } } })
    }

    if (paramName === "packingDoc") {
      if (value.size > 0) {
        setPackingDocument(value);
      } else {
        setPackingDocument("");
      }
    }

    // =======================================================================
    // Calculate net amount in item detail section

    if (paramName === "ratePerMt" || paramName === "quantity") {
      if (
        paramsCopy.ratePerMt.value !== "" &&
        !isNaN(paramsCopy.ratePerMt.value) &&
        parseFloat(paramsCopy.ratePerMt.value) > 0 &&
        paramsCopy.quantity.value !== "" &&
        !isNaN(paramsCopy.quantity.value) &&
        parseFloat(paramsCopy.quantity.value) > 0
      ) {
        // var totalWithTaxCalc = calculateNetAmountWithTax(paramsCopy);

        paramsCopy = update(paramsCopy, {
          netAmount: {
            value: {
              $set:
                parseFloat(paramsCopy.ratePerMt.value) *
                parseFloat(paramsCopy.quantity.value),
            },
          },
          // netAmountAfterTax: { value: { $set: totalWithTaxCalc } },
        });
      } else {
        paramsCopy = update(paramsCopy, {
          netAmount: { value: { $set: 0 } },
          // netAmountAfterTax: { value: { $set: 0 } },
        });
      }
    }
    //Calculate Currency Conversion
    if (paramName === 'netAmount' || paramName === 'currencyConversionRate' || paramName === "ratePerMt" || paramName === "quantity") {
      if (paramsCopy.netAmount.value !== '' &&
        !isNaN(paramsCopy.netAmount.value) &&
        parseFloat(paramsCopy.netAmount.value) > 0
        && paramsCopy.currencyConversionRate.value !== '' &&
        !isNaN(paramsCopy.currencyConversionRate.value) &&
        parseFloat(paramsCopy.currencyConversionRate.value) > 0 &&
        paramsCopy.ratePerMt.value !== "" &&
        !isNaN(paramsCopy.ratePerMt.value) &&
        parseFloat(paramsCopy.ratePerMt.value) > 0 &&
        paramsCopy.quantity.value !== "" &&
        !isNaN(paramsCopy.quantity.value) &&
        parseFloat(paramsCopy.quantity.value) > 0
      ) {
        var netAmountinr = parseFloat(paramsCopy.netAmount.value) * parseFloat(paramsCopy.currencyConversionRate.value);
        paramsCopy = update(paramsCopy, { netAmountINR: { value: { $set: netAmountinr } } });
      } else {
        paramsCopy = update(paramsCopy, { netAmountINR: { value: { $set: 0 } } });
      }
    }
    // =======================================================================
    // Calculate net amount with tax in item detail section

    if (showTaxPercent) {
      if (
        [
          "quantity",
          "ratePerMt",
          'currencyConversionRate',
          'netAmountINR',
          "exciseTaxPercentage",
          "cessTaxPercentage",
          "splExciseTaxPercentage",
          "tcsPercentage",
          "gstPercentage",
          'interestPercentage',
          'penaltyPercentage'
        ].includes(paramName)
      ) {
        var totalWithTaxCalc = calculateNetAmountWithTax(paramsCopy);
        paramsCopy = update(paramsCopy, {
          netAmountAfterTax: { value: { $set: totalWithTaxCalc } },
        });
      }
    } else {
      if (
        [
          "quantity",
          "ratePerMt",
          'currencyConversionRate',
          'netAmountINR',
          // "exciseTaxAmount",
          "cessTaxAmount",
          // "splExciseTaxAmount",
          "tcsAmount",
          "gstAmount",
          // 'interestAmount',
          // 'penaltyAmount'
        ].includes(paramName)
      ) {
        var totalWithTaxCalc = calculateNetAmountWithTax(paramsCopy);
        paramsCopy = update(paramsCopy, {
          netAmountAfterTax: { value: { $set: totalWithTaxCalc } },
        });
      }
    }

    setPurchaseImportParams(paramsCopy);
  };


  const renderFormElements = (elemList) => {
    return elemList.map((element) => {
      switch (element) {
        case "billOfLadingDoc":
        case "billOfEntryDoc":
        case "clearingDoc":
        case "packingDoc":
        case "insuranceDoc":
        case "transporterDoc":
        case "bankDoc":
        case "bankDoc2":
        case "linerBillDoc":
        case "otherChargesDoc":
          return (
            <FormElement
              key={element}
              inputType={
                purchaseImportParams[element].value.name !== undefined
                  ? "uploadReady"
                  : "upload"
              }
              value={
                purchaseImportParams[element].value !== null &&
                  purchaseImportParams[element].value !== ""
                  ? purchaseImportParams[element].value.name
                  : ""
              }
              setInput={(value) => {
                updatePurchaseValues({
                  paramName: element,
                  key: "value",
                  value: value,
                });
              }}
              hintText={purchaseImportParams[element].hintText}
              mandatory={purchaseImportParams[element].mandatory}
              colSpan={purchaseImportParams[element].colSpan}
              options={
                purchaseImportParams[element].inputType === "options"
                  ? purchaseImportParams[element].options
                  : null
              }
              error={purchaseImportParams[element].error}
              rowSpan={
                element === "vendorLogo" || element === "otherBusinessDetails"
                  ? purchaseImportParams[element].rowSpan
                  : null
              }
              placeholder={purchaseImportParams[element].placeholder}
              title={purchaseImportParams[element].title}
              disabled={purchaseImportParams[element].disabled}
            />
          );

        default:
          return (
            <FormElement
              key={element}
              inputType={purchaseImportParams[element].inputType}
              value={
                purchaseImportParams[element].value
                  ? purchaseImportParams[element].value
                  : ""
              }
              setInput={(value) => {
                updatePurchaseValues({
                  paramName: element,
                  key: "value",
                  value: value,
                });
              }}
              hintText={purchaseImportParams[element].hintText}
              mandatory={purchaseImportParams[element].mandatory}
              colSpan={purchaseImportParams[element].colSpan}
              options={
                purchaseImportParams[element].inputType === "options"
                  ? purchaseImportParams[element].options
                  : null
              }
              error={purchaseImportParams[element].error}
              rowSpan={
                element === "vendorLogo" || element === "otherBusinessDetails"
                  ? purchaseImportParams[element].rowSpan
                  : null
              }
              placeholder={purchaseImportParams[element].placeholder}
              title={purchaseImportParams[element].title}
              disabled={purchaseImportParams[element].disabled}
            />
          );
      }
    });
  };

  const RenderLinersTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr
            className="createVendorContactsTableHeader"
            style={{ textAlign: "left" }}>
            <td style={{ padding: "5px 10px" }}>Liner Bill Name</td>
            <td style={{ padding: "5px 10px" }}>Liner Bill No</td>
            <td style={{ padding: "5px 10px" }}>Liner Bill Date</td>
            <td style={{ padding: "5px 10px" }}>Charge</td>
            <td style={{ padding: "5px 10px" }}>Tax Amount</td>
            <td style={{ padding: "5px 10px" }}>Liner Bill Total Amount</td>
            <td style={{ padding: "5px 20px", textAlign: "right" }}>Action</td>
          </tr>
        </thead>
        <tbody>
          {linerBillItems?.length > 0 ? (
            renderLinerItems()
          ) : (
            <tr className="createVendorContactsTableRows">
              {purchaseImportParamsList.liner.map((line) => (
                (line !== 'linerBillDoc') ?
                  <td key={line}>&nbsp;</td> : ''
              ))}
              <td>&nbsp;</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const RenderContainerTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr
            className="createVendorContactsTableHeader"
            style={{ textAlign: "left" }}>
            <td style={{ padding: "5px 10px" }}>Container No</td>
            <td style={{ padding: "5px 10px" }}>Container Weight</td>
            <td style={{ padding: "5px 10px" }}>Container Size</td>
            <td style={{ padding: "5px 10px" }}>Seal No</td>
            <td style={{ padding: "5px 10px" }}>DC No</td>
            <td style={{ padding: "5px 20px", textAlign: "right" }}>Action</td>
          </tr>
        </thead>
        <tbody>
          {containerLineItems?.length > 0 ? (
            renderContainerItems()
          ) : (
            <tr className="createVendorContactsTableRows">
              {purchaseImportParamsList.containerDetail.map((line) => (
                <td key={line}>&nbsp;</td>
              ))}
              <td>&nbsp;</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const RenderItemTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr
            className="createVendorContactsTableHeader"
            style={{ textAlign: "left" }}>
            <td style={{ padding: "5px 10px" }}>Container No</td>
            <td style={{ padding: "5px 10px" }}>Item Name</td>
            <td style={{ padding: "5px 10px" }}>Weight From</td>
            <td style={{ padding: "5px 10px" }}>Weight To</td>
            <td style={{ padding: "5px 10px" }}>Net Weight</td>
            <td style={{ padding: "5px 10px" }}>Gross Weight</td>
            <td style={{ padding: "5px 10px" }}>Rate / MT</td>
            <td style={{ padding: "5px 10px" }}>Quantity (MT)</td>
            <td style={{ padding: "5px 10px" }}>Net Amount (USD)</td>
            <td style={{ padding: '5px 10px' }}>Currency Conv. Rate</td>
            <td style={{ padding: '5px 10px' }}>Net Amount(INR)</td>
            <td style={{ padding: '5px 10px' }}>Tax Amount(INR)</td>
            <td style={{ padding: "5px 10px" }}>Net Amount After Tax(INR)</td>
            <td style={{ padding: "5px 20px", textAlign: "right" }}>Action</td>
          </tr>
        </thead>
        <tbody>
          {itemLineItems?.length > 0 ? (
            renderItems()
          ) : (
            <tr className="createVendorContactsTableRows">
              {[
                "containerNo",
                "itemName",
                "from",
                "to",
                "netWeight",
                "grossWeight",
                "ratePerMt",
                "quantity",
                "netAmount",
                "",
                "",
                "",
                "netAmountAfterTax",
              ].map((line, i) => (
                <td key={i}>&nbsp;</td>
              ))}
              <td>&nbsp;</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const editLinersItem = (action, index) => {
    var linerBillsListCopy = JSON.parse(JSON.stringify(linerBillItems));

    var linerBillsInItems = [];
    linerBillItems.forEach((item) => {
      linerBillsInItems.push(item.linerBillNo);
    });

    switch (action) {
      case "add":
        var paramsCopy = Object.assign(purchaseImportParams);

        var linerBillObj = {};
        purchaseImportParamsList.liner.forEach((item) => {
          linerBillObj[item] = paramsCopy[item].value;
        });
        var containerListCopy = linerBillItems.slice();
        if (Object.keys(linerBillObj).length) {
          containerListCopy.push(linerBillObj);
        }

        purchaseImportParamsList.liner.forEach((item) => {
          paramsCopy[item].value = "";
        });
        setLinerBillItems(containerListCopy);
        setPurchaseImportParams(paramsCopy);
        break;
      case "delete":
        linerBillsListCopy.splice(index, 1);
        setLinerBillItems(linerBillsListCopy);
        break;
      default:
        break;
    }
  };
  const editContainerItem = (action, index) => {
    var containerListCopy = JSON.parse(JSON.stringify(containerLineItems));

    var containerNamesInItems = [];
    itemLineItems.forEach((item) => {
      containerNamesInItems.push(item.containerNo);
    });

    var containersUnique = Array.from(
      new Set(containerNamesInItems.map((item) => item))
    );

    switch (action) {
      case "delete":
        if (containersUnique.includes(containerListCopy[index].containerNo)) {
          window.alert(
            "Container used in item table. Delete Item records with this container to delete here"
          );
          break;
        } else {
          containerListCopy.splice(index, 1);
          var containerOptionsForItems = [];
          containerListCopy.forEach((container) => {
            var cont = {
              optionId: container.containerNo,
              optionName: container.containerNo,
            };
            containerOptionsForItems.push(cont);
          });

          var paramsCopy = Object.assign(purchaseImportParams);
          paramsCopy = update(paramsCopy, {
            containerId: { options: { $set: containerOptionsForItems } },
          });
          setPurchaseImportParams(paramsCopy);

          setContainerLineItems(containerListCopy);
          break;
        }
      default:
        break;
    }
  };

  const editItemLineItems = (action, index) => {
    var itemLineItemsCopy = itemLineItems.slice();

    switch (action) {
      case "delete":
        itemLineItemsCopy.splice(index, 1);
        setItemLineItems(itemLineItemsCopy);
    }
  };

  const renderLinerItems = () => {
    return linerBillItems.map((line, i) => {
      return (
        <tr className="createVendorContactsTableRows" key={i}>
          <td>{line.linerName}</td>
          <td>{line.linerBillNo}</td>
          <td>{line.linerBillDate}</td>
          <td>{line.linerBillAmount}</td>
          <td>{line.linerBillTaxAmount}</td>
          <td>{line.linerBillTotalAmount}</td>
          <td className="itemIndiTabActions">
            <img
              className="createVendorContactsAction"
              src={deleteIcon}
              alt="Delete"
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) editLinersItem("delete", i);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const renderContainerItems = () => {
    return containerLineItems.map((line, i) => {
      return (
        <tr className="createVendorContactsTableRows" key={i}>
          <td>{line.containerNo}</td>
          <td>{line.containerWeightMt}</td>
          <td>{line.containerSize}</td>
          <td>{line.sealNo}</td>
          <td>{line.dcNo}</td>
          <td className="itemIndiTabActions">
            <img
              className="createVendorContactsAction"
              src={deleteIcon}
              alt="Delete"
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) editContainerItem("delete", i);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const renderItems = () => {
    return itemLineItems.map((line, i) => {
      return (
        <tr className="createVendorContactsTableRows" key={i}>
          <td>{line.containerNo}</td>
          <td>{line.itemName}</td>
          <td>{line.from ? line.from : " - "}</td>
          <td>{line.to ? line.to : " - "}</td>
          <td>{line.netWeight ? line.netWeight : " - "}</td>
          <td>{line.grossWeight ? line.grossWeight : " - "}</td>
          <td>{line.ratePerMt ? line.ratePerMt : " - "}</td>
          <td>{line.quantity ? line.quantity : " - "}</td>
          <td>{line.netAmount ? line.netAmount : " - "}</td>
          <td>{line.currencyConversionRate !== undefined ? line.currencyConversionRate : ' - '}</td>
          <td>{line.netAmountINR ? line.netAmountINR : '-'}</td>
          <td>{Number((line.netAmountAfterTax - line.netAmountINR).toFixed(2)) || "-"}</td>
          <td>{line.netAmountAfterTax !== undefined ? line.netAmountAfterTax : " - "}</td>
          <td className="itemIndiTabActions">
            <img
              className="createVendorContactsAction"
              src={deleteIcon}
              alt="Delete"
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) {
                  // editContainerItem("delete", i);
                  editItemLineItems("delete", i);
                }
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const [containerErrorList, setContainerErrorList] = useState([]);

  const checkContainerError = () => {
    var errorList = [];

    var paramsCopy = Object.assign(purchaseImportParams);

    purchaseImportParamsList.containerDetail.forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (item === "containerWeightMt" || item === "containerSize") {

        if (paramsCopy[item].value !== "") {
          if (isNaN(paramsCopy[item].value)) {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: true } },
            });
          } else {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: false } },
            });
          }
        } else {
          paramsCopy = update(paramsCopy, {
            [item]: { error: { $set: false } },
          });
        }
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    if (errorList.length === 0) {
      var containerListCopy = containerLineItems.slice();
      containerListCopy.push({
        containerNo: paramsCopy.containerNo.value,
        containerWeightMt: paramsCopy.containerWeightMt.value,
        containerSize: paramsCopy.containerSize.value,
        sealNo: paramsCopy.sealNo.value,
        dcNo: paramsCopy.dcNo.value
      });

      paramsCopy.containerNo.value = "";
      paramsCopy.containerWeightMt.value = "";
      paramsCopy.containerSize.value = "";
      paramsCopy.containerId.value = "";
      paramsCopy.sealNo.value = "";
      paramsCopy.dcNo.value = "";

      setContainerLineItems(containerListCopy);
    }

    var containerOptionsForItems = [];
    containerListCopy?.forEach((container) => {
      var cont = {
        optionId: container.containerNo,
        optionName: container.containerNo,
      };
      containerOptionsForItems.push(cont);
    });
    paramsCopy = update(paramsCopy, {
      containerId: { options: { $set: containerOptionsForItems } },
    });

    setContainerErrorList(errorList);
    setPurchaseImportParams(paramsCopy);
  };

  const [itemErrorList, setItemErrorList] = useState([]);
  const checkItemError = () => {
    var errorList = [];

    var paramsCopy = Object.assign(purchaseImportParams);

    purchaseImportParamsList.itemDetail.forEach((item) => {

      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (item === "ratePerMt" || item === "quantity") {

        if (paramsCopy[item].value !== "") {
          if (isNaN(paramsCopy[item].value)) {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: true } },
            });
          } else {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: false } },
            });
          }
        } else {
          paramsCopy = update(paramsCopy, {
            [item]: { error: { $set: false } },
          });
        }
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    if (showTaxPercent) {
      purchaseImportParamsList.itemDetailTaxPercentage.forEach((item) => {
        if (
          [
            // "exciseTaxPercentage",
            "cessTaxPercentage",
            // "splExciseTaxPercentage",
            "tcsPercentage",
            "gstPercentage",
            // 'interestPercentage',
            // 'penaltyPercentage'
          ].includes(item)
        ) {
          if (paramsCopy[item].value !== "") {
            if (isNaN(paramsCopy[item].value)) {
              paramsCopy = update(paramsCopy, {
                [item]: { error: { $set: true } },
              });
            } else {
              if (parseFloat(paramsCopy[item].value) < 100) {
                paramsCopy = update(paramsCopy, {
                  [item]: { error: { $set: false } },
                });
              } else {
                paramsCopy = update(paramsCopy, {
                  [item]: { error: { $set: true } },
                });
              }
            }
          } else {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: false } },
            });
          }

          paramsCopy = update(paramsCopy, {
            exciseTaxAmount: { error: { $set: false } },
            cessTaxAmount: { error: { $set: false } },
            splExciseTaxAmount: { error: { $set: false } },
            tcsAmount: { error: { $set: false } },
            gstAmount: { error: { $set: false } },
            interestAmount: { error: { $set: false } },
            penaltyAmount: { error: { $set: false } }
          });

          if (paramsCopy[item].error) {
            errorList.push(paramsCopy[item].errorMessage);
          }
        }
      });
    } else {
      purchaseImportParamsList.itemDetailTaxAmount.forEach((item) => {
        if (
          [
            // "exciseTaxAmount",
            "cessTaxAmount",
            // "splExciseTaxAmount",
            "tcsAmount",
            "gstAmount",
            // 'interestAmount',
            // 'penaltyAmount'
          ].includes(item)
        ) {
          if (paramsCopy[item].value !== "") {
            if (isNaN(paramsCopy[item].value)) {
              paramsCopy = update(paramsCopy, {
                [item]: { error: { $set: true } },
              });
            } else {
              paramsCopy = update(paramsCopy, {
                [item]: { error: { $set: false } },
              });
            }
          } else {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: false } },
            });
          }

          paramsCopy = update(paramsCopy, {
            // exciseTaxPercentage: { error: { $set: false } },
            cessTaxPercentage: { error: { $set: false } },
            // splExciseTaxPercentage: { error: { $set: false } },
            tcsPercentage: { error: { $set: false } },
            gstPercentage: { error: { $set: false } },
            // interestPercentage: {error: {$set: false}},
            // penaltyPercentage: {error: {$set: false}}
          });

          if (paramsCopy[item].error) {
            errorList.push(paramsCopy[item].errorMessage);
          }
        }
      });
    }

    if (errorList.length === 0) {

      var itemDetailObj = {
        storageId: paramsCopy.storageId.value,
        containerNo: paramsCopy.containerId.value,
        itemId: paramsCopy.itemId.value,
        itemName: paramsCopy.itemId.options.filter(
          (item) => item.optionId === paramsCopy.itemId.value
        )[0].optionName,
        from: paramsCopy.from.value,
        to: paramsCopy.to.value,
        netWeight: paramsCopy.netWeight.value,
        grossWeight: paramsCopy.grossWeight.value,
        ratePerMt: paramsCopy.ratePerMt.value,
        quantity: paramsCopy.quantity.value,
        netAmount: Number((paramsCopy?.netAmount?.value||0).toFixed(2)),
        netAmountINR: Number((paramsCopy.netAmountINR.value||0)).toFixed(2),
        currencyConversionRate: paramsCopy.currencyConversionRate.value,
        netAmountAfterTax: Number((paramsCopy.netAmountAfterTax.value)).toFixed(2),
      };

      if (showTaxPercent) {
        // itemDetailObj.exciseTaxPercentage =
        //   paramsCopy.exciseTaxPercentage.value;
        itemDetailObj.cessTaxPercentage = paramsCopy.cessTaxPercentage.value;
        // itemDetailObj.splExciseTaxPercentage =
        //   paramsCopy.splExciseTaxPercentage.value;
        itemDetailObj.tcsPercentage = paramsCopy.tcsPercentage.value;
        itemDetailObj.gstPercentage = paramsCopy.gstPercentage.value;
        // itemDetailObj.interestPercentage = paramsCopy.interestPercentage.value;
        // itemDetailObj.penaltyPercentage = paramsCopy.penaltyPercentage.value;
        paramsCopy = update(paramsCopy, {
          // exciseTaxPercentage: { value: { $set: "" } },
          cessTaxPercentage: { value: { $set: "" } },
          // splExciseTaxPercentage: { value: { $set: "" } },
          tcsPercentage: { value: { $set: "" } },
          gstPercentage: { value: { $set: "" } },
          // interestPercentage: {value: {$set: ""}},
          // penaltyPercentage: {value: {$set: ""}}
        });
      } else {
        // itemDetailObj.exciseTaxAmount = paramsCopy.exciseTaxAmount.value;
        itemDetailObj.cessTaxAmount = paramsCopy.cessTaxAmount.value;
        // itemDetailObj.splExciseTaxAmount = paramsCopy.splExciseTaxAmount.value;
        itemDetailObj.tcsAmount = paramsCopy.tcsAmount.value;
        itemDetailObj.gstAmount = paramsCopy.gstAmount.value;
        // itemDetailObj.interestAmount = paramsCopy.interestAmount.value;
        // itemDetailObj.penaltyAmount = paramsCopy.penaltyAmount.value;
        paramsCopy = update(paramsCopy, {
          // exciseTaxAmount: { value: { $set: "" } },
          cessTaxAmount: { value: { $set: "" } },
          // splExciseTaxAmount: { value: { $set: "" } },
          tcsAmount: { value: { $set: "" } },
          gstAmount: { value: { $set: "" } },
          // interestAmount: {value: {$set: ""}},
          // penaltyAmount: {value: {$set: ""}}
        });
      }

      paramsCopy = update(paramsCopy, {
        storageId: { value: { $set: "" } },
        containerId: { value: { $set: "" } },
        itemId: { value: { $set: "" } },
        from: { value: { $set: "" } },
        to: { value: { $set: "" } },
        netWeight: { value: { $set: "" } },
        grossWeight: { value: { $set: "" } },
        ratePerMt: { value: { $set: "" } },
        quantity: { value: { $set: "" } },
        netAmount: { value: { $set: "" } },
        netAmountINR: { value: { $set: '' } },
        netAmountAfterTax: { value: { $set: "" } },
      });

      var itemListCopy = itemLineItems.slice();
      itemListCopy.push(itemDetailObj);
      setItemLineItems(itemListCopy);
    }

    setItemErrorList(errorList);
    setPurchaseImportParams(paramsCopy);
  };

  const [invoiceErrors, setInvoiceErrors] = useState([]);

  const checkInvoiceErrors = (tabId, tabIndex) => {
    var errorList = [];

    var paramsCopy = Object.assign(purchaseImportParams);

    [
      "invoiceNo",
      "invoiceDate",

      "balancePaymentDollar1",
      "balancePaymentRupee1",
      "balancePaymentDate1",
      "balancePaymentDollar2",
      "balancePaymentRupee2",
      "balancePaymentDate2",
      "balancePaymentDollar3",
      "balancePaymentRupee3",
      "balancePaymentDate3",

      "billOfLadingNo",
      "vesselName",
      "billOfLadingDoc",

      "billOfEntryNo",
      "billOfEntryDate",
      "billOfEntryReferralNo",
      "billOfLadingDoc",

      "portArrivalDate",
      "portDetail",
      "freeTimeDurationDays",
      "freeTimeExpiryDate",
      "arrivalSeplDate",
      "arrivalSeplDays",

      "bankName",
      "bankRefNo",
      "bankDate",
      "bankDoc",

      "bankName2",
      "bankRefNo2",
      "bankDate2",
      "bankDoc2",

      "clearingAgentId",
      "clearingAgentBillNo",
      "clearingAgentBillDate",
      "clearingAgentBillAmount",
      "clearingAgentTaxAmount",
      "clearingAgentTotalAmount",
      "clearingDoc",

      "insuranceCompanyName",
      "insuranceNo",
      "insuranceDate",
      "insuranceDollar",
      "insuranceRupee",
      "insuranceDoc",

      "transporterId",
      "transporterBillNo",
      "transporterBillDate",
      "transporterBillAmount",
      "transporterBillTaxAmount",
      "transporterBillTotalAmount",
      "transporterDoc",

      "linerName",
      "linerBillNo",
      "linerBillDate",
      "linerBillAmount",
      "linerBillTaxAmount",
      "linerBillTotalAmount",
      "linerBillDoc",

      "remarks",
      "remarksParty",
    ].forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (
        [
          "balancePaymentDollar1",
          "balancePaymentRupee1",
          "balancePaymentDollar2",
          "balancePaymentRupee2",
          "balancePaymentDollar3",
          "balancePaymentRupee3",
          "freeTimeDurationDays",
          "arrivalSeplDays",
          "clearingAgentBillAmount",
          "insuranceDollar",
          "insuranceRupee",
          "transporterBillAmount",
        ].includes(item)
      ) {
        if (paramsCopy[item].value !== "") {
          if (isNaN(paramsCopy[item].value)) {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: true } },
            });
          } else {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: false } },
            });
          }
        }
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    var proceed = true;

    purchaseImportParamsList.liner.forEach((item) => {
      if (paramsCopy[item].value !== "") {
        proceed = false;
        errorList.push(["Unsaved liner bill information found"]);
      }
    });

    purchaseImportParamsList.containerDetail.forEach((item) => {
      if (paramsCopy[item].value !== "") {
        proceed = false;
        errorList.push(["Unsaved container information found"]);
      }
    });

    purchaseImportParamsList.itemDetail.forEach((item) => {
      if (paramsCopy[item].value !== "" && item !== 'currencyConversionRate') {
        proceed = false;
        errorList.push(["Unsaved Item Detail found"]);
      }
    });

    if (showTaxPercent) {
      purchaseImportParamsList.itemDetailTaxPercentage.forEach((item) => {
        if (paramsCopy[item].value !== "") {
          proceed = false;
          errorList.push(["Unsaved item Detail found"]);
        }
      });
    } else {
      purchaseImportParamsList.itemDetailTaxAmount.forEach((item) => {
        if (paramsCopy[item].value !== "") {
          proceed = false;
          errorList.push(["Unsaved item Detail found"]);
        }
      });
    }

    if (errorList.length === 0 && proceed) {
      var invoiceObj = {
        invoiceNo: paramsCopy.invoiceNo.value,
        invoiceDate: paramsCopy.invoiceDate.value,
      };

      if (paramsCopy.invoiceQuantity.value !== "") {
        invoiceObj.invoiceQuantity = paramsCopy.invoiceQuantity.value;
      }

      //  --------------------------------------------------------
      if (paramsCopy.balancePaymentDollar1.value !== "") {
        invoiceObj.balancePaymentDollar1 =
          paramsCopy.balancePaymentDollar1.value;
      }
      if (paramsCopy.balancePaymentRupee1.value !== "") {
        invoiceObj.balancePaymentRupee1 = paramsCopy.balancePaymentRupee1.value;
      }
      if (paramsCopy.balancePaymentDate1.value !== "") {
        invoiceObj.balancePaymentDate1 = paramsCopy.balancePaymentDate1.value;
      }

      if (paramsCopy.balancePaymentDollar2.value !== "") {
        invoiceObj.balancePaymentDollar2 =
          paramsCopy.balancePaymentDollar2.value;
      }
      if (paramsCopy.balancePaymentRupee2.value !== "") {
        invoiceObj.balancePaymentRupee2 = paramsCopy.balancePaymentRupee2.value;
      }
      if (paramsCopy.balancePaymentDate2.value !== "") {
        invoiceObj.balancePaymentDate2 = paramsCopy.balancePaymentDate2.value;
      }

      if (paramsCopy.balancePaymentDollar3.value !== "") {
        invoiceObj.balancePaymentDollar3 =
          paramsCopy.balancePaymentDollar3.value;
      }
      if (paramsCopy.balancePaymentRupee3.value !== "") {
        invoiceObj.balancePaymentRupee3 = paramsCopy.balancePaymentRupee3.value;
      }
      if (paramsCopy.balancePaymentDate3.value !== "") {
        invoiceObj.balancePaymentDate3 = paramsCopy.balancePaymentDate3.value;
      }

      //     --------------------------------------------------------
      if (paramsCopy.billOfLadingNo.value !== "") {
        invoiceObj.billOfLadingNo = paramsCopy.billOfLadingNo.value;
      }
      if (paramsCopy.vesselName.value !== "") {
        invoiceObj.vesselName = paramsCopy.vesselName.value;
      }
      if (
        paramsCopy.billOfLadingDoc.value !== "" &&
        paramsCopy.billOfLadingDoc.value.size > 0
      ) {
        invoiceObj.billOfLadingDoc = paramsCopy.billOfLadingDoc.value;
      }
      //     --------------------------------------------------------
      if (paramsCopy.billOfEntryNo.value !== "") {
        invoiceObj.billOfEntryNo = paramsCopy.billOfEntryNo.value;
      }
      if (paramsCopy.billOfEntryDate.value !== "") {
        invoiceObj.billOfEntryDate = paramsCopy.billOfEntryDate.value;
      }
      if (paramsCopy.billOfEntryReferralNo.value !== "") {
        invoiceObj.billOfEntryReferralNo = paramsCopy.billOfEntryReferralNo.value;
      }
      if (
        paramsCopy.billOfEntryDoc.value !== "" &&
        paramsCopy.billOfEntryDoc.value.size > 0
      ) {
        invoiceObj.billOfEntryDoc = paramsCopy.billOfEntryDoc.value;
      }

      //   --------------------------------------------------------
      if (linerBillItems.length > 0) {
        linerBillItems.map((line, idx) => {
          for (var key of Object.keys(line)) {
            if (line[key] === "") {
              linerBillItems[idx][key] = null;
            }
          }
        })
        invoiceObj.linerBillItems = linerBillItems;

      }

      //   --------------------------------------------------------
      if (containerLineItems.length > 0) {
        invoiceObj.containerLineItems = JSON.parse(
          JSON.stringify(containerLineItems)
        );
      }

      //    --------------------------------------------------------
      if (itemLineItems.length > 0) {
        invoiceObj.itemList = JSON.parse(JSON.stringify(itemLineItems));
      } else {
        errorList.push('Add Atleast One Line Item in this invoice')
      }

      //   --------------------------------------------------------
      if (paramsCopy.portArrivalDate.value !== "") {
        invoiceObj.portArrivalDate = paramsCopy.portArrivalDate.value;
      }
      if (paramsCopy.portDetail.value !== "") {
        invoiceObj.portDetail = paramsCopy.portDetail.value;
      }
      if (paramsCopy.freeTimeDurationDays.value !== "") {
        invoiceObj.freeTimeDurationDays = paramsCopy.freeTimeDurationDays.value;
      }
      if (paramsCopy.freeTimeExpiryDate.value !== "") {
        invoiceObj.freeTimeExpiryDate = paramsCopy.freeTimeExpiryDate.value;
      }
      if (paramsCopy.arrivalSeplDate.value !== "") {
        invoiceObj.arrivalSeplDate = paramsCopy.arrivalSeplDate.value;
      }
      if (paramsCopy.arrivalSeplDays.value !== "") {
        invoiceObj.arrivalSeplDays = paramsCopy.arrivalSeplDays.value;
      }

      //    ----------------------bank 1----------------------------------
      if (paramsCopy.bankName.value !== "") {
        invoiceObj.bankName = paramsCopy.bankName.value;
      }
      if (paramsCopy.bankRefNo.value !== "") {
        invoiceObj.bankRefNo = paramsCopy.bankRefNo.value;
      }
      if (paramsCopy.bankDate.value !== "") {
        invoiceObj.bankDate = paramsCopy.bankDate.value;
      }
      if (
        paramsCopy.bankDoc.value !== "" &&
        paramsCopy.bankDoc.value.size > 0
      ) {
        invoiceObj.bankDoc = paramsCopy.bankDoc.value;
      }

      //     -------------------------bank 2-------------------
      if (paramsCopy.bankName2.value !== "") {
        invoiceObj.bankName2 = paramsCopy.bankName2.value;
      }
      if (paramsCopy.bankRefNo2.value !== "") {
        invoiceObj.bankRefNo2 = paramsCopy.bankRefNo2.value;
      }
      if (paramsCopy.bankDate2.value !== "") {
        invoiceObj.bankDate2 = paramsCopy.bankDate2.value;
      }
      if (
        paramsCopy.bankDoc2.value !== "" &&
        paramsCopy.bankDoc2.value.size > 0
      ) {
        invoiceObj.bankDoc2 = paramsCopy.bankDoc2.value;
      }

      //      --------------------------------------------------------
      if (paramsCopy.clearingAgentBillNo.value !== "") {
        invoiceObj.clearingAgentBillNo = paramsCopy.clearingAgentBillNo.value;
      }
      if (paramsCopy.clearingAgentBillDate.value !== "") {
        invoiceObj.clearingAgentBillDate =
          paramsCopy.clearingAgentBillDate.value;
      }
      if (paramsCopy.clearingAgentBillAmount.value !== "") {
        invoiceObj.clearingAgentBillAmount =
          paramsCopy.clearingAgentBillAmount.value;
      }
      if (paramsCopy.clearingAgentTaxAmount.value !== "") {
        invoiceObj.clearingAgentTaxAmount =
          paramsCopy.clearingAgentTaxAmount.value;
      }
      if (paramsCopy.clearingAgentTotalAmount.value !== "") {
        invoiceObj.clearingAgentTotalAmount =
          paramsCopy.clearingAgentTotalAmount.value;
      }
      if (
        paramsCopy.clearingDoc.value !== "" &&
        paramsCopy.clearingDoc.value.size > 0
      ) {
        invoiceObj.clearingDoc = paramsCopy.clearingDoc.value;
      }

      //    --------------------------------------------------------
      if (paramsCopy.insuranceCompanyName.value !== "") {
        invoiceObj.insuranceCompanyName = paramsCopy.insuranceCompanyName.value;
      }
      if (paramsCopy.insuranceNo.value !== "") {
        invoiceObj.insuranceNo = paramsCopy.insuranceNo.value;
      }
      if (paramsCopy.insuranceDate.value !== "") {
        invoiceObj.insuranceDate = paramsCopy.insuranceDate.value;
      }
      if (paramsCopy.insuranceCompanyName.value !== "") {
        invoiceObj.insuranceCompanyName = paramsCopy.insuranceCompanyName.value;
      }
      if (paramsCopy.insuranceDollar.value !== "") {
        invoiceObj.insuranceDollar = paramsCopy.insuranceDollar.value;
      }
      if (paramsCopy.insuranceRupee.value !== "") {
        invoiceObj.insuranceRupee = paramsCopy.insuranceRupee.value;
      }
      if (
        paramsCopy.insuranceDoc.value !== "" &&
        paramsCopy.insuranceDoc.value.size > 0
      ) {
        invoiceObj.insuranceDoc = paramsCopy.insuranceDoc.value;
      }

      //     --------------------------------------------------------
      if (paramsCopy.transporterId.value !== "") {
        invoiceObj.transporterId = paramsCopy.transporterId.value;
      }
      if (paramsCopy.transporterBillNo.value !== "") {
        invoiceObj.transporterBillNo = paramsCopy.transporterBillNo.value;
      }
      if (paramsCopy.transporterBillDate.value !== "") {
        invoiceObj.transporterBillDate = paramsCopy.transporterBillDate.value;
      }
      if (paramsCopy.transporterBillAmount.value !== "") {
        invoiceObj.transporterBillAmount =
          paramsCopy.transporterBillAmount.value;
      }
      if (paramsCopy.transporterBillTaxAmount.value !== "") {
        invoiceObj.transporterBillTaxAmount =
          paramsCopy.transporterBillTaxAmount.value;
      }
      if (paramsCopy.transporterBillTotalAmount.value !== "") {
        invoiceObj.transporterBillTotalAmount =
          paramsCopy.transporterBillTotalAmount.value;
      }
      if (
        paramsCopy.transporterDoc.value !== "" &&
        paramsCopy.transporterDoc.value.size > 0
      ) {
        invoiceObj.transporterDoc = paramsCopy.transporterDoc.value;
      }
      if (
        paramsCopy.otherChargesDoc.value !== "" &&
        paramsCopy.otherChargesDoc.value.size > 0
      ) {
        invoiceObj.otherChargesDoc = paramsCopy.otherChargesDoc.value;
      }

      // --------------------------------------------------------
      if (paramsCopy.remarks.value !== "") {
        invoiceObj.remarks = paramsCopy.remarks.value;
      }
      if (paramsCopy.remarksParty.value !== "") {
        invoiceObj.remarksParty = paramsCopy.remarksParty.value;
      }
      ['exciseTaxAmount', 'splExciseTaxAmount',
        'interestAmount', 'penaltyAmount'].forEach((item) => {
          invoiceObj[item] = paramsCopy[item].value || 0
        });
      [
        "invoiceNo",
        "invoiceDate",
        "invoiceQuantity",

        "balancePaymentDollar1",
        "balancePaymentRupee1",
        "balancePaymentDate1",
        "balancePaymentDollar2",
        "balancePaymentRupee2",
        "balancePaymentDate2",
        "balancePaymentDollar3",
        "balancePaymentRupee3",
        "balancePaymentDate3",

        "billOfLadingNo",
        "vesselName",
        "billOfLadingDoc",

        "billOfEntryNo",
        "billOfEntryDate",
        "billOfEntryReferralNo",
        "billOfLadingDoc",

        "portArrivalDate",
        "portDetail",
        "freeTimeDurationDays",
        "freeTimeExpiryDate",
        "arrivalSeplDate",
        "arrivalSeplDays",

        "bankName",
        "bankRefNo",
        "bankDate",
        "bankDoc",

        "bankName2",
        "bankRefNo2",
        "bankDate2",
        "bankDoc2",

        "clearingAgentId",
        "clearingAgentBillNo",
        "clearingAgentBillDate",
        "clearingAgentBillAmount",
        "clearingAgentTaxAmount",
        "clearingAgentTotalAmount",
        "clearingDoc",

        "insuranceCompanyName",
        "insuranceNo",
        "insuranceDate",
        "insuranceDollar",
        "insuranceRupee",
        "insuranceDoc",

        "transporterId",
        "transporterBillNo",
        "transporterBillDate",
        "transporterBillAmount",
        "transporterBillTaxAmount",
        "transporterBillTotalAmount",
        "transporterDoc",

        'exciseTaxAmount', 'splExciseTaxAmount',
        'interestAmount', 'penaltyAmount',"otherChargesDoc",
        "remarks",
        "remarksParty",
      ].forEach((item) => {
        paramsCopy[item].value = "";
      });

      var invoiceListCopy = Object.assign(invoiceList);

      invoiceListCopy[tabId] = invoiceObj;

      setInvoiceList(invoiceListCopy);

      setContainerLineItems([]);
      setItemLineItems([]);
      setLinerBillItems([]);
    }

    setInvoiceErrors(errorList);
    setPurchaseImportParams(paramsCopy);

    if (errorList.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const [importErrors, setImportErrors] = useState([]);

  const loadInvoiceDetails = (tabId, callingFunc) => {

    var thisInvoice = invoiceList[tabId];
    var paramsCopy = Object.assign(purchaseImportParams);

    [
      "invoiceNo",
      "invoiceDate",

      "balancePaymentDollar1",
      "balancePaymentRupee1",
      "balancePaymentDate1",
      "balancePaymentDollar2",
      "balancePaymentRupee2",
      "balancePaymentDate2",
      "balancePaymentDollar3",
      "balancePaymentRupee3",
      "balancePaymentDate3",

      "billOfLadingNo",
      "vesselName",
      "billOfLadingDoc",

      "billOfEntryNo",
      "billOfEntryDate",
      "billOfEntryReferralNo",
      "billOfLadingDoc",

      "portArrivalDate",
      "portDetail",
      "freeTimeDurationDays",
      "freeTimeExpiryDate",
      "arrivalSeplDate",
      "arrivalSeplDays",

      "bankName",
      "bankRefNo",
      "bankDate",
      "bankDoc",

      "bankName2",
      "bankRefNo2",
      "bankDate2",
      "bankDoc2",

      "clearingAgentId",
      "clearingAgentBillNo",
      "clearingAgentBillDate",
      "clearingAgentBillAmount",
      "clearingAgentTaxAmount",
      "clearingAgentTotalAmount",
      "clearingDoc",

      "insuranceCompanyName",
      "insuranceNo",
      "insuranceDate",
      "insuranceDollar",
      "insuranceRupee",
      "insuranceDoc",

      "transporterId",
      "transporterBillNo",
      "transporterBillDate",
      "transporterBillAmount",
      "transporterBillTaxAmount",
      "transporterBillTotalAmount",
      "transporterDoc",

      'exciseTaxAmount', 'splExciseTaxAmount',
      'interestAmount', 'penaltyAmount',
      "otherChargesDoc",
      "remarks",
      "remarksParty",
    ].forEach((item) => {
      if (paramsCopy[item].inputType === "dateFromEditPage") {
        if (thisInvoice && (thisInvoice[item] !== undefined)) {
          var dateValue = new Date(thisInvoice[item]);
          paramsCopy = update(paramsCopy, {
            [item]: { value: { $set: dateValue }, error: { $set: false } },
          });
        }
      } else {
        if (thisInvoice && (thisInvoice[item] !== undefined)) {
          paramsCopy = update(paramsCopy, {
            [item]: {
              value: { $set: thisInvoice[item] },
              error: { $set: false },
            },
          });
        } else {
          paramsCopy = update(paramsCopy, {
            [item]: { value: { $set: "" }, error: { $set: false } },
          });
        }
      }
    });

    if (thisInvoice && thisInvoice.itemList) {
      setItemLineItems(thisInvoice.itemList);
    }
    if (thisInvoice && thisInvoice.containerLineItems) {
      setContainerLineItems(thisInvoice.containerLineItems);
    }
    if (thisInvoice && thisInvoice.linerBillItems) {
      setLinerBillItems(thisInvoice.linerBillItems);
    }

    setPurchaseImportParams(paramsCopy);
  };

  const checkErrors = () => {
    var errorList = [];
    var paramsCopy = JSON.parse(JSON.stringify(purchaseImportParams));
    [
      "sellerId",
      "poNo",
      "shipmentDate",
      "packingDoc",

      "advancePaymentDollar1",
      "advancePaymentRupee1",
      "advancePaymentDate1",
      "advancePaymentDollar2",
      "advancePaymentRupee2",
      "advancePaymentDate2",
      "advancePaymentDollar3",
      "advancePaymentRupee3",
      "advancePaymentDate3",
    ].forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (
        item === "advancePaymentDollar1" ||
        item === "advancePaymentRupee1" ||
        item === "advancePaymentDollar2" ||
        item === "advancePaymentRupee2" ||
        item === "advancePaymentDollar3" ||
        item === "advancePaymentRupee3"
      ) {
        if (paramsCopy[item].value !== "") {
          if (isNaN(paramsCopy[item].value)) {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: true } },
            });
          } else {
            paramsCopy = update(paramsCopy, {
              [item]: { error: { $set: false } },
            });
          }
        }
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    if (errorList.length === 0) {

      var data2server = new FormData();

      data2server.append("poId", paramsCopy.poNo.value);
      data2server.append("shipmentDate", paramsCopy.shipmentDate.value);
      data2server.append("expectedDeliveryDate", "2021-08-10");


      if (packingDocument !== "" && packingDocument.size > 0) {
        data2server.append("packingDocument", packingDocument);
      }

      if (paramsCopy.advancePaymentDollar1.value !== "") {
        data2server.append(
          "advancePaymentDollar1",
          paramsCopy.advancePaymentDollar1.value
        );
      }
      if (paramsCopy.advancePaymentRupee1.value !== "") {
        data2server.append(
          "advancePaymentRupee1",
          paramsCopy.advancePaymentRupee1.value
        );
      }
      if (paramsCopy.advancePaymentDate1.value !== "") {
        data2server.append(
          "advancePaymentDate1",
          paramsCopy.advancePaymentDate1.value
        );
      }

      if (paramsCopy.advancePaymentDollar2.value !== "") {
        data2server.append(
          "advancePaymentDollar2",
          paramsCopy.advancePaymentDollar2.value
        );
      }
      if (paramsCopy.advancePaymentRupee2.value !== "") {
        data2server.append(
          "advancePaymentRupee2",
          paramsCopy.advancePaymentRupee2.value
        );
      }
      if (paramsCopy.advancePaymentDate2.value !== "") {
        data2server.append(
          "advancePaymentDate2",
          paramsCopy.advancePaymentDate2.value
        );
      }

      if (paramsCopy.advancePaymentDollar3.value !== "") {
        data2server.append(
          "advancePaymentDollar3",
          paramsCopy.advancePaymentDollar3.value
        );
      }
      if (paramsCopy.advancePaymentRupee3.value !== "") {
        data2server.append(
          "advancePaymentRupee3",
          paramsCopy.advancePaymentRupee3.value
        );
      }
      if (paramsCopy.advancePaymentDate3.value !== "") {
        data2server.append(
          "advancePaymentDate3",
          paramsCopy.advancePaymentDate3.value
        );
      }

      if (tablist.length > 0) {
        var invoiceLineItems = [];

        var bolDocNames = [
          "billOfLadingDocumentA",
          "billOfLadingDocumentB",
          "billOfLadingDocumentC",
          "billOfLadingDocumentD",
          "billOfLadingDocumentE",
        ];
        var boeDocNames = [
          "billOfEntryDocumentA",
          "billOfEntryDocumentB",
          "billOfEntryDocumentC",
          "billOfEntryDocumentD",
          "billOfEntryDocumentE",
        ];

        var bankDocNames = [
          "bankDocumentA",
          "bankDocumentB",
          "bankDocumentC",
          "bankDocumentD",
          "bankDocumentE",
        ];

        var bankDocNames2 = [
          "bankDocumentF",
          "bankDocumentG",
          "bankDocumentH",
          "bankDocumentI",
          "bankDocumentJ",
        ]
        var clearDocNames = [
          "clearingDocumentA",
          "clearingDocumentB",
          "clearingDocumentC",
          "clearingDocumentD",
          "clearingDocumentE",
        ];

        var insurDocNames = [
          "insuranceDocumentA",
          "insuranceDocumentB",
          "insuranceDocumentC",
          "insuranceDocumentD",
          "insuranceDocumentE",
        ];

        var transportDocNames = [
          "transporterDocumentA",
          "transporterDocumentB",
          "transporterDocumentC",
          "transporterDocumentD",
          "transporterDocumentE",
        ];

        tablist.forEach((tab, index) => {
          if (invoiceList[tab.id]) {
            invoiceLineItems.push(invoiceList[tab.id]);

            if (
              invoiceList[tab.id].billOfLadingDoc !== "" &&
              invoiceList[tab.id].billOfLadingDoc !== undefined
            ) {
              data2server.append(
                bolDocNames[index],
                invoiceList[tab.id].billOfLadingDoc
              );
            }

            if (
              invoiceList[tab.id].billOfEntryDoc !== "" &&
              invoiceList[tab.id].billOfEntryDoc !== undefined
            ) {
              data2server.append(
                boeDocNames[index],
                invoiceList[tab.id].billOfEntryDoc
              );
            }

            if (
              invoiceList[tab.id].bankDoc !== "" &&
              invoiceList[tab.id].bankDoc !== undefined
            ) {
              data2server.append(
                bankDocNames[index],
                invoiceList[tab.id].bankDoc
              );
            }

            if (
              invoiceList[tab.id].bankDoc2 !== "" &&
              invoiceList[tab.id].bankDoc2 !== undefined
            ) {
              data2server.append(
                bankDocNames2[index],
                invoiceList[tab.id].bankDoc2
              );
            }

            if (
              invoiceList[tab.id].clearingDoc !== "" &&
              invoiceList[tab.id].clearingDoc !== undefined
            ) {
              data2server.append(
                clearDocNames[index],
                invoiceList[tab.id].clearingDoc
              );
            }

            if (
              invoiceList[tab.id].insuranceDoc !== "" &&
              invoiceList[tab.id].insuranceDoc !== undefined
            ) {
              data2server.append(
                insurDocNames[index],
                invoiceList[tab.id].insuranceDoc
              );
            }

            if (
              invoiceList[tab.id].transporterDoc !== "" &&
              invoiceList[tab.id].transporterDoc !== undefined
            ) {
              data2server.append(
                transportDocNames[index],
                invoiceList[tab.id].transporterDoc
              );
            }
            if (
              invoiceList[tab.id].otherChargesDoc !== "" &&
              invoiceList[tab.id].otherChargesDoc !== undefined
            ) {
              console.log(invoiceList[tab.id].otherChargesDoc,"invoiceList[tab.id].otherChargesDoc")
              data2server.append(
                "otherChargesDoc",
                invoiceList[tab.id].otherChargesDoc
              );
            }
          }
          if (
            invoiceList[tab.id].linerBillItems?.length > 0
          ) {
            invoiceList[tab.id].linerBillItems?.map((line, idx) => {
              if (line.linerBillDoc) {
                data2server.append(
                  'linerBillDocuments',
                  line.linerBillDoc
                );
                invoiceList[tab.id].linerBillItems[idx].linerBillDoc = line.linerBillDoc?.name;
              } else {
                invoiceList[tab.id].linerBillItems[idx].linerBillDoc = '';
              }
            });
          }
        });

        data2server.append(
          "invoiceLineItems",
          JSON.stringify(invoiceLineItems)
        );
      }

      submitData(data2server);
      setIsOpen(true);
    }
    setPurchaseImportParams(paramsCopy);
    setImportErrors(errorList);
  };

  async function submitData(data) {
    setDataSubmitted(false);

    var result = await fetchData({
      requestingPage: "createItem",
      method: "post",
      url: "po-create/purchase-import3",
      headers: { token: isLogged.accessToken, module: "Purchase Import" },
      data: data,
    });

    if (result.msg === "success") {
      setModalText("Successfully created Purchase Import");
    } else {
      setModalText(`Upload failed: ${result.desc}`);
      setImportErrors([result.desc]);
    }
    setDataSubmitted(true);
  }

  const submitOkClick = () => {
    setIsOpen(false);
    if (importErrors.length === 0) {
      setSection("purchaseImportList");
      resetAllValues();
    }
    setModalText("Uploading form. Please wait...");
  };

  const resetAllValues = () => {
    // ==============================================================================
    // reset all params
    var paramsCopy = Object.assign(purchaseImportParams);
    [
      "sellerId",
      "poNo",
      "poNo",
      "contractDate",
      "contractDays",
      "ratePerMt",
      "shipmentDate",
      "quantity",
      "packingDoc",
      "advancePaymentDollar1",
      "advancePaymentRupee1",
      "advancePaymentDate1",
      "invoiceNo",
      "invoiceDate",
      "invoiceQuantity",
      "balancePaymentDollar1",
      "balancePaymentRupee1",
      "balancePaymentDate1",
      "billOfLadingNo",
      "vesselName",
      "billOfLadingDoc",
      "billOfEntryNo",
      "billOfEntryDate",
      "billOfEntryReferralNo",
      "billOfLadingDoc",
      "containerNo",
      "containerWeightMt",
      "containerSize",
      "sealNo",
      "dcNo",
      "portArrivalDate",
      "portDetail",
      "freeTimeDurationDays",
      "freeTimeExpiryDate",
      "arrivalSeplDate",
      "arrivalSeplDays",
      "bankName",
      "bankRefNo",
      "bankDate",
      "bankDoc",
      "bankName2",
      "bankRefNo2",
      "bankDate2",
      "bankDoc2",
      "clearingAgentBillNo",
      "clearingAgentBillDate",
      "clearingAgentBillAmount",
      "clearingAgentTaxAmount",
      "clearingAgentTotalAmount",
      "clearingDoc",
      "linerName",
      "linerBillNo",
      "linerBillDate",
      "linerBillAmount",
      "linerBillTaxAmount",
      "linerBillTotalAmount",
      "linerBillDoc",
      "remarks",
      'exciseTaxAmount', 'splExciseTaxAmount',
      'interestAmount', 'penaltyAmount',"otherChargesDoc",
      "remarksParty",
    ].forEach((item) => {
      paramsCopy[item].value = "";
      paramsCopy[item].error = false;
    });
    setPurchaseImportParams(paramsCopy);
    setImportErrors([]);
    setContainerErrorList([]);
    setInvoiceList({});
    setInvoiceErrors([]);
    setTablist([{ id: "invoice1", name: "Invoice 1" }]);
    setSelectedTab("invoice1");
    setNextTabNo(2);
  };

  const RenderTabList = () => {
    return tablist.map((tab, tabIndex) => {
      return (
        <React.Fragment key={tab.id}>
          <div
            key={tab.id}
            className={
              selectedTab === tab.id
                ? "createPurchaseImportTabSelected"
                : "createPurchaseImportTab"
            }
            onClick={() => {
              if (tab.id !== selectedTab) {
                var currentTab = JSON.parse(JSON.stringify(selectedTab));
                var changeTab = checkInvoiceErrors(currentTab, tabIndex);

                // if (changeTab) {
                setSelectedTab(tab.id);
                loadInvoiceDetails(tab.id, "Switch Tab");
                // }
              }
            }}>
            {tab.name}
            {tab.id === selectedTab && tablist.length > 1 ? (
              <span
                className="tabCloseButton"
                title="Delete Invoice"
                onClick={(e) => {
                  deleteTab(tabIndex, tab.id);
                  e.stopPropagation();
                }}>
                X
              </span>
            ) : null}
          </div>
        </React.Fragment>
      );
    });
  };

  const addNewTab = () => {
    let tabNo = nextTabNo;

    for (let idx = 1; idx < 6; idx++) {
      const obj = tablist.find(o => o.name === `Invoice ${idx}`);
      if (!obj) {
        tabNo = idx;
        break;
      }

    }

    var newTabObj = {
      id: `invoice${nextTabNo}`,
      name: `Invoice ${tabNo}`,
    };

    var currentTab = JSON.parse(JSON.stringify(selectedTab));
    var newTab = checkInvoiceErrors(selectedTab, tablist.length);

    if (newTab && tablist.length < 5) {
      setTablist([...tablist, newTabObj]);
      setSelectedTab(`invoice${nextTabNo}`);
      setNextTabNo(nextTabNo + 1);
    } else {
      window.alert(
        "Complete all mandatory fields and make sure there are no unsaved Container & Item information before creating new invoice"
      );
    }
  };

  const deleteTab = async (index, tabId) => {
    var deleteInvoice = window.confirm("Delete this invoice?");

    if (deleteInvoice) {

      var tablistCopy = tablist.slice();
      tablistCopy.splice(index, 1);

      var invoiceListCopy = JSON.parse(JSON.stringify(invoiceList));
      delete invoiceListCopy[tabId];

      setInvoiceList(invoiceListCopy);
      setTablist(tablistCopy);
      setNextTabNo(nextTabNo - 1);
      setSelectedTab(tablistCopy[0].id);
      loadInvoiceDetails(tablistCopy[0].id, "Delete Tab");
    }
  };

  const RenderContractDays = () => {
    return (
      <React.Fragment>
        <div className="detailTitle">Contract Detail</div>
        <div className="detailText">
          {` Contract Date: ${contractDayCounts.contractDate}`}
          <br /> {`Contract Days: ${contractDayCounts.contractDays}`}
        </div>
      </React.Fragment>
    );
  };

  const renderErrorMessage = () => {
    if (importErrors.length > 0) return importErrors[0];
    else if (invoiceErrors.length > 0) return invoiceErrors[0];
    else if (containerErrorList.length > 0)
      return `Container : ${containerErrorList[0]}`;
    else if (itemErrorList.length > 0)
      return `Item Detail: ${itemErrorList[0]}`;
    else return null;
  };

  const PurchaseImportSummary = () => {
    var lineItemsTotalSum = itemLineItems.reduce((prev, row) => prev + parseFloat(row.netAmountAfterTax), 0);
    var lineItemsSum = itemLineItems.reduce((prev, row) => prev + parseFloat(row.netAmountINR), 0)
    var lineItemsTaxSum = lineItemsTotalSum - lineItemsSum;
    var linerBillsAmount = linerBillItems.reduce((prev, row) => prev + parseFloat(row.linerBillAmount), 0)
    var linerBillsTaxAmount = linerBillItems.reduce((prev, row) => prev + parseFloat(row.linerBillTaxAmount), 0)
    var linerBillsTotalAmount = linerBillItems.reduce((prev, row) => prev + parseFloat(row.linerBillTotalAmount), 0)
    var invoicePageSum = (parseFloat(purchaseImportParams['clearingAgentBillAmount'].value) || 0) +
      (parseFloat(purchaseImportParams['transporterBillAmount'].value) || 0) +
      (parseFloat(linerBillsAmount || 0)) +
      (parseFloat(purchaseImportParams['insuranceRupee'].value || 0)) +
      (parseFloat(purchaseImportParams['exciseTaxAmount'].value || 0)) +
      (parseFloat(purchaseImportParams['splExciseTaxAmount'].value || 0)) +
      (parseFloat(purchaseImportParams['interestAmount'].value || 0)) +
      (parseFloat(purchaseImportParams['penaltyAmount'].value || 0)) +
      lineItemsSum;

    var invoicePageTaxTotal = (parseFloat(purchaseImportParams["clearingAgentTaxAmount"].value) || 0) +
      (parseFloat(purchaseImportParams["transporterBillTaxAmount"].value) || 0) +
      (parseFloat(linerBillsTaxAmount) || 0) + lineItemsTaxSum;

    var invoicePageTotal = invoicePageSum + invoicePageTaxTotal;
    return (
      <div className="vendorSummaryGrid plantsSubtitle purchaseImportSummary">
        <span className="vendorSummaryHeader">Title</span><span className="vendorSummaryHeader">Amount</span><span className="vendorSummaryHeader"> Tax Amount</span><span className="vendorSummaryHeader">Total Amount</span>
        <span className="vendorSummaryCategory">Clearing Agent Bill Amount</span>
        <span className="vendorSummaryValue">{purchaseImportParams["clearingAgentBillAmount"].value || 0}</span>
        <span className="vendorSummaryValue">{purchaseImportParams["clearingAgentTaxAmount"].value || 0}</span>
        <span className="vendorSummaryValue">{purchaseImportParams["clearingAgentTotalAmount"].value || 0}</span>
        <span className="vendorSummaryCategory">Transporter Bill Amount</span>
        <span className="vendorSummaryValue"> {purchaseImportParams["transporterBillAmount"].value || 0}</span>
        <span className="vendorSummaryValue"> {purchaseImportParams["transporterBillTaxAmount"].value || 0}</span>
        <span className="vendorSummaryValue"> {purchaseImportParams["transporterBillTotalAmount"].value || 0}</span>
        <span className="vendorSummaryCategory">Liner Bill Amount</span>
        <span className="vendorSummaryValue">{linerBillsAmount || 0}</span>
        <span className="vendorSummaryValue">{linerBillsTaxAmount || 0}</span>
        <span className="vendorSummaryValue">{linerBillsTotalAmount || 0}</span>
        <span className="vendorSummaryCategory">Insurance Bill Amount</span>
        <span className="vendorSummaryValue">{purchaseImportParams["insuranceRupee"].value || 0}</span>
        <span className="vendorSummaryValue">0</span>
        <span className="vendorSummaryValue">{purchaseImportParams["insuranceRupee"].value || 0}</span>
        <span className="vendorSummaryCategory">Excise Tax Amount</span><span className="vendorSummaryValue">{purchaseImportParams['exciseTaxAmount'].value || 0}</span><span className="vendorSummaryValue">0</span><span className="vendorSummaryValue">{purchaseImportParams['exciseTaxAmount'].value || 0}</span>
        <span className="vendorSummaryCategory">Spl. Excise Tax Amount</span><span className="vendorSummaryValue">{purchaseImportParams['splExciseTaxAmount'].value || 0}</span><span className="vendorSummaryValue">0</span><span className="vendorSummaryValue">{purchaseImportParams['splExciseTaxAmount'].value || 0}</span>
        <span className="vendorSummaryCategory">Interest Amount</span><span className="vendorSummaryValue">{purchaseImportParams['interestAmount'].value || 0}</span><span className="vendorSummaryValue">0</span><span className="vendorSummaryValue">{purchaseImportParams['interestAmount'].value || 0}</span>
        <span className="vendorSummaryCategory">Penalty Amount</span><span className="vendorSummaryValue">{purchaseImportParams['penaltyAmount'].value || 0}</span><span className="vendorSummaryValue">0</span><span className="vendorSummaryValue">{purchaseImportParams['penaltyAmount'].value || 0}</span>

        <span className="vendorSummaryCategory">Items Total</span>
        <span className="vendorSummaryValue">{lineItemsSum}</span><span className="vendorSummaryValue">{Number((lineItemsTaxSum).toFixed(2))}</span><span className="vendorSummaryValue">{Number((lineItemsTotalSum).toFixed(2))}</span>
        <span className="vendorSummaryCategory">Total Amount</span>
        <span className="vendorSummaryValue">{Number((invoicePageSum).toFixed(2))} </span>
        <span className="vendorSummaryValue">{Number((invoicePageTaxTotal).toFixed(2))}</span>
        <span className="vendorSummaryValue">{Number((invoicePageTotal).toFixed(2))}</span>
      </div>
    )
  }
  return (
    <React.Fragment>
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
      <div className="formArea">
        <div
          style={{
            width: "1100px",
            margin: "0 auto 4rem",
            padding: "3rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div className="purchaseSubTitle">Contract Details :</div>
          <div style={{ display: "flex" }}>
            <div
              className="createPurchaseOrderGrid"
              style={{ gridTemplateRows: " repeat(2, 4rem)" }}>
              {renderFormElements(purchaseImportParamsList.contractDetails)}
            </div>
            <div style={{ width: "280px" }}>
              <div className="vendorStoreDetailArea">
                {/* <div className="poVendorAddressDetail">{showVendorDetail ? <RenderVendorDetail /> : null}</div> */}
                <div className="poStoreAddressDetail">
                  {showContractDays ? (
                    <div>
                      <RenderContractDays />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="subTitle">Advance Payment Details :</div>
          <div
            className="createPurchaseImportGrid"
            style={{ gridTemplateRows: " repeat(1, 4rem)" }}>
            {renderFormElements(purchaseImportParamsList.advancePaymentDetails)}
          </div>
          <div
            className="createPurchaseImportGrid"
            style={{ gridTemplateRows: " repeat(1, 4rem)", marginTop: "10px" }}>
            {renderFormElements(
              purchaseImportParamsList.advancePaymentDetails2
            )}
          </div>
          <div
            className="createPurchaseImportGrid"
            style={{ gridTemplateRows: " repeat(1, 4rem)", marginTop: "10px" }}>
            {renderFormElements(
              purchaseImportParamsList.advancePaymentDetails3
            )}
          </div>

          <div className="createPurchaseImportTabArea">
            <div
              className="createVendorTabs"
              style={{ borderBottom: "1px solid rgb(240,240,240)" }}>
              <RenderTabList />
              <div
                className="createPurchaseImportTab"
                style={
                  tablist.length < 5
                    ? { cursor: "pointer", width: "wrapContent" }
                    : { cursor: "not-allowed", color: "lightgray" }
                }
                onClick={() => {
                  if (tablist.length < 5) {
                    addNewTab();
                  }
                }}
                title={tablist.length < 5 ? "Add Invoice" : "Max 5 invoice"}>
                {" "}
                +{" "}
              </div>
            </div>
            <div style={{ fontSize: "1px" }}>&nbsp;</div>

            <div className="plantsSubTitle">Invoice Details :</div>
            <div
              className="createPurchaseImportTabDetails">
              {renderFormElements(purchaseImportParamsList.invoiceDetails)}
            </div>

            <div className="plantsSubTitle">Balance Payment Details :</div>
            <div
              className="createPurchaseImportTabDetails"
              style={{ padding: "20px 20px 10px 20px", }}>
              {renderFormElements(
                purchaseImportParamsList.balancePaymentDetails
              )}
            </div>
            <div
              className="createPurchaseImportTabDetails"
              style={{
                padding: "0 20px 10px 20px",
              }}>
              {renderFormElements(
                purchaseImportParamsList.balancePaymentDetails2
              )}
            </div>
            <div
              className="createPurchaseImportTabDetails"
              style={{ padding: "0 20px 10px 20px", }}>
              {renderFormElements(
                purchaseImportParamsList.balancePaymentDetails3
              )}
            </div>

            <div className="plantsSubTitle">Bill Of Ladding :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements(purchaseImportParamsList.billOfLading)}
            </div>

            <div className="plantsSubTitle">Bill Of Entry :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements(purchaseImportParamsList.billOfEntry)}
            </div>

            <div className="plantsSubTitle">Port :</div>
            <div
              className="createPurchaseImportTabDetails"
              style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
              {renderFormElements(purchaseImportParamsList.port)}
            </div>

            <div className="plantsSubTitle">Bank :</div>
            <div
              className="createPurchaseImportTabDetails"
              style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
              {renderFormElements(purchaseImportParamsList.bankDetail)}
            </div>

            <div className="plantsSubTitle">Clearing Agent :</div>
            <div
              className="createPurchaseImportTabDetails"
              style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
              {renderFormElements(purchaseImportParamsList.clearingAgent)}
            </div>

            <div className="plantsSubTitle">Insurance :</div>
            <div
              className="createPurchaseImportTabDetails"
              style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
              {renderFormElements(purchaseImportParamsList.insurance)}
            </div>

            <div className="plantsSubTitle">Transporter :</div>
            <div className="createPurchaseImportTabDetails" style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
              {renderFormElements(purchaseImportParamsList.transporter)}
            </div>

            <div className="plantsSubTitle">Liner Bill :</div>
            <div className="createPurchaseImportTabDetails" style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
              {renderFormElements(purchaseImportParamsList.liner)}
              <FormElement
                inputType="addButton" value="+ Add" colSpan={6}
                title={linerBillItems.length === 3 ? 'Maximum Limit reached' : ""}
                style={linerBillItems.length === 3 ? { backgroundColor: "gray", cursor: "not-allowed" } : {}}
                setInput={() => linerBillItems.length < 3 ? editLinersItem("add", purchaseImportParamsList.liner) : null}
              />
            </div>
            <RenderLinersTable />

            <div className="plantsSubTitle">Remarks :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements(purchaseImportParamsList.remarks)}
            </div>

            {/* <div style={{ backgroundColor: "#ffffff", padding: "1rem 0 2rem", margin: "1rem 0" }}> */}
            <div className="plantsSubTitle">Container Detail :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements(purchaseImportParamsList.containerDetail)}
              <FormElement
                inputType="addButton"
                value="+ Add"
                colSpan={6}
                setInput={() => {
                  checkContainerError();
                }}
              />
            </div>

            <RenderContainerTable />
            <div className="plantsSubTitle">Other Charges :</div>
            <div className="createPurchaseImportTabDetails">
              {renderFormElements(purchaseImportParamsList.otherCharges)}
            </div>
            <div className="plantsSubTitle">Item Detail :</div>
            <div
              className="createPurchaseImportTabDetails"
              style={{ gridTemplateRows: "repeat(3, 4rem)" }}>
              {renderFormElements(purchaseImportParamsList.itemDetail)}
            </div>

            <span
              className="plantsSubTitle"
              style={showTaxPercent ? { color: "lightgray" } : null}>
              Tax Amount
            </span>
            <label className="importTaxSwitch">
              <input
                type="checkbox"
                className="importTaxCheckbox"
                onChange={(e) => {
                  setShowTaxPercent(!showTaxPercent);
                }}
              />
              <span className="toggle-thumb"></span>
            </label>

            <span
              className="plantsSubTitle"
              style={showTaxPercent ? null : { color: "lightgray" }}>
              Tax Percentage :
            </span>

            <div className="createPurchaseImportTabDetails">
              {showTaxPercent
                ? renderFormElements(
                  purchaseImportParamsList.itemDetailTaxPercentage
                )
                : renderFormElements(
                  purchaseImportParamsList.itemDetailTaxAmount
                )}
              <FormElement
                inputType="addButton"
                value="+ Add"
                colSpan={4}
                setInput={() => {
                  checkItemError();
                }}
              />
            </div>

            <RenderItemTable />
            <PurchaseImportSummary />
            {/* </div> */}
          </div>
        </div>
      </div>

      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

          <button
            className="cancelButton"
            onClick={() => {
              var reset = window.confirm("Do you want to reset all fields?");
              if (reset) {
                resetAllValues();
              }
            }}>
            Reset Fields
          </button>
          <button
            className="submitButton"
            onClick={() => {
              var tabIndex = tablist.findIndex(
                (item) => item.id === selectedTab
              );

              var submitOk = checkInvoiceErrors(selectedTab, tabIndex);

              if (submitOk) {
                // setSelectedTab(tab.id);
                // loadInvoiceDetails(tab.id, "Switch Tab");

                checkErrors();
              }
            }}>
            Submit
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePurchaseImport);
