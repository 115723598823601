import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import fetchData from "serverCall/fetchData";
import FetchNewToken from "serverCall/FetchNewToken";
import CreateEditModal from "components/SmallComponents/CreateEditModal";

const CreatePremiumCharges = ({
    // props
    setSection,

    // state
    // roleInfo,
    isLogged,

    // dispatch
    // editUom,
    addTokenToState,
  }) => {
    const initialPremiumCharges = {premiumNo:"",premiumName:"",rate:""};
  const [premiumChargesList, setPremiumChargesList] = useState([]);
  const [newPremiumCharges, setNewPremiumCharges] = useState({...initialPremiumCharges});
    const history = useHistory();
  const checkToken=useCallback(async() =>{
      console.log("Checking token");
      //   console.log(isLogged.accessToken);
      const token2 = await FetchNewToken(isLogged.accessToken);
      if (token2 === "expired") {
          history.push("/");
      } else if (token2 !== isLogged.accessToken) {
          console.log("New Token");
          addTokenToState(isLogged.employeeId, token2);
      } else {
          console.log("Token not changed");
      }
  },[isLogged,addTokenToState,history]);

  const getPremiumCharges=useCallback(async()=> {
      var data = await fetchData({
          requestingPage: "premiumCharges",
          method: "get",
          url: "fetch/premium-charges",
          headers: { token: isLogged.accessToken,module:"Premium Charges" },
      });
      if (data.msg === "success") {
          setPremiumChargesList(data.asset);
      } else {
          console.log(data);
      }

  },[isLogged]);

  const getInformation=useCallback(async()=> {
      await checkToken();
      await getPremiumCharges();
    },[checkToken,getPremiumCharges]);

    useEffect(() => {
        getInformation();
    }, [getInformation]);

    const submitPremiumCharges=async()=>{
        setDataSubmitted(false);
        let submitted = await fetchData({
            requestingPage: "premiumCharges",
            method: "POST",
            data: newPremiumCharges,
            url: "create/premium-charges",
            headers:{token :isLogged.accessToken,module: "Premium Charges"}
        });

        if(submitted.msg==="success"){
            setModalText("Premium Charges Created Successfully");
            setNewPremiumCharges(initialPremiumCharges);
            getPremiumCharges();
        }else{
            setModalText(submitted.desc);
        }
        setDataSubmitted(true);
    }
    const renderPremiumChargesList = (list) => {
      return(
        list.map((row,index)=>{
          return(
            <tr className='uomListData' key={index}>
              <td className="uomSymbolData">{row.premiumNo}</td>
              <td className='uomSymbolData'>{row.premiumName}</td>
              <td className='uomSymbolData'>{row.rate}</td>
            </tr>
          )
        })
      )
    };

    const renderCreatePremiumCharges = () => {
        return (
            <tr className="uomListData">
                <td className="uomSymbolData2">
                    <input
                        className="createUom"
                        value={newPremiumCharges.premiumNo}
                        onChange={(e) => setNewPremiumCharges({ ...newPremiumCharges, premiumNo: e.target.value })}
                        autoFocus
                    />
                </td>
                <td className="uomSymbolData2">
                    <input
                        className="createUom"
                        value={newPremiumCharges.premiumName}
                        onChange={(e) => setNewPremiumCharges({ ...newPremiumCharges, premiumName: e.target.value })}
                    />
                </td>
                <td className="uomSymbolData3" style={{minWidth:"10rem"}}>
                <div style={{flex:"1 0.5 65%"}}>
                    <input
                        className="createUom"
                        type="number"
                        value={newPremiumCharges.rate}
                        onChange={(e) => setNewPremiumCharges({ ...newPremiumCharges, rate: e.target.value })}
                    />
                    </div>
                    <button
                        style={{width:"7rem"}}
                        className="cancelRoleButton"
                        onClick={() => {
                            setNewPremiumCharges({...initialPremiumCharges});
                        }}
                    >
                        Cancel
                    </button>
                    <button
                    style={{width:"7rem"}}
                        id="SubmitCreate"
                        className="createRoleButton"
                        onClick={() => {
                            if(newPremiumCharges.premiumNo && newPremiumCharges.premiumName && newPremiumCharges.rate)
                            submitPremiumCharges();
                        }
                      }
                    >
                        Add
                    </button>
                </td>
                {/* <td className="uomSymbolData3" style={{minWidth:"10rem"}}>
                    <div style={{flex:"1 0.5 65%"}}>
                    <SearchableDropDown 
                        options={uomOptionsList}
                        selected={newPremiumCharges.uomId}
                        multiple={false}
                        isClearable={true}
                        setInput={(value)=>setNewPremiumCharges({...newPremiumCharges,uomId:value})}
                    />
                    </div>
               </td> */}
               {/* <td className="uomSymbolData2" >
                    
                </td> */}
            </tr>
        );
    };

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Uploading data. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading data. Please wait...");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="uomListArea">
                <table className="uomListTable">
                    <thead>
                    <tr className=" uomListHeader">
                        <td className="uomSymbolHeader">
                            <span>Premium No</span>
                        </td>
                        <td className="uomSymbolHeader">
                            <span>Premium Name</span>
                        </td>
                        <td className="uomSymbolHeader">
                            <span>Rate</span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {renderCreatePremiumCharges()}
                    {renderPremiumChargesList(premiumChargesList)}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePremiumCharges);
