import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useHistory } from "react-router";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import { connect } from "react-redux";
import fetchData from "../../../serverCall/fetchData";
import CreateEditModal from "../../SmallComponents/CreateEditModal";
import axios from "axios";
import { serverEndpoint } from "../../../serverCall/environmentVariables";
import FileDownload from "js-file-download";

const UploadDies = ({ isLogged, addTokenToState, setSection }) => {
    const [itemsFile, setItemsFile] = useState("");
    const [toServerData, setToServerData] = useState([]);
    const [errorData, setErrorData] = useState([]);
    const [excelData, setExcelData] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    async function getInformation() {
        await checkToken();    
    }

    const history = useHistory();
    async function checkToken() {
       
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
        return true;
    }

    const readExcel = (file) => {
        setIsOpen(true);
        setModalText("Reading excel file. Please wait...");
        setDataSubmitted(false);
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const workBook = XLSX.read(bufferArray, { type: "buffer" });

                const workSheetName = workBook.SheetNames[0];
                const workSheet = workBook.Sheets[workSheetName];
                const data = XLSX.utils.sheet_to_json(workSheet);                
                resolve({ data });
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then(({ data }) => {
            parseData(data);
        });
    };
    

    const parseData = (data) => {
        var errorList = [];
        var serverList = [];
        var uniqueRows = [];

        data.forEach((row, i) => {
            var oldFlag = false;
            row.Line_Number = row.__rowNum__ + 1;

            uniqueRows.forEach((item) => {
                if (item["Die No"]=== row["Die No"]) {
                    row.die_No_Error = "Duplicate Die No in file";
                    oldFlag = true;
                }
                if (item["Die Name"] === row["Die Name"]) {
                    row.die_Name_Error = "Duplicate Name in file";
                    oldFlag = true;
                }
            });
            if (!oldFlag || uniqueRows.length === 0) {
                uniqueRows.push(row);
            } else {
                errorList.push(row);
            }
        });

        uniqueRows.forEach((row, i) => {
            var rowError = false;

          let updateRow = {
            cvt : row.CVT,
            date : row.Date?formatDate(row.Date):null,
            drawingWeight : row["Drawing Wt"],
            die : row["Die"],
            dieNo : row["Die No"],
            dieName : row["Die Name"],
            pn : row["P.N"],
            pgNo: row["PG.no"],
            rack: row["Rack"],
            size: row["Size"],
            runningWeight: row["Running Wt"],
            soNo: row["s.No"],
            remark: row["Remark"],
            resizing: row["Resizing"],
            receivedDate: formatDate(row["Die Received Date"]?row["Die Received Date"]: new Date()),
          }
                serverList.push(updateRow);

        });

        errorList.sort((a, b) => (a.Line_Number > b.Line_Number ? 1 : -1));

        setExcelData(data);
        setErrorData(errorList);
        setToServerData(serverList);
        setIsOpen(false);
    };

    const checkErrors = () => {
        if (toServerData.length > 0) {
             submitData(toServerData);
        } else {
            setIsOpen(true);
            setModalText("No valid die(s) to submit");
            setItemServerError(["No valid die(s) to submit"]);
            setDataSubmitted(true);
        }
    };

    const [itemServerError, setItemServerError] = useState([]);

    const submitOkClick = () => {
        setIsOpen(false);
        if (itemServerError.length === 0) {
            setSection("dieList");
        }
        setModalText("Uploading form. Please wait...");
        setItemServerError([]);
    };

    const submitData = async (data) => {
        setIsOpen(true);
        setModalText("Uploading items. Please wait...");

        var result = await fetchData({
            requestingPage: "createItem",
            method: "post",
            url: "create/die-master-upload",
            headers: { token: isLogged.accessToken,module:"Die Master" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Successfully uploaded Dies!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setItemServerError([result.desc]);
        }
        setDataSubmitted(true);
    };

    const downloadTemplate = async () => {

        axios({
            method: "get",
            url: serverEndpoint + "image/dies-upload-template",
            headers: { token: isLogged.accessToken,module:"Die Master" },
            responseType: "arraybuffer",
        }).then((response) => {
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            FileDownload(blob, "Template_Die_Upload.xlsx");
        });
    };

    const formatDate = (dateValue) => {
        var date = new Date();
        date.setTime((dateValue - 25569) * 24 * 3600 * 1000);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dateValue = date.getDate();
        if (month.toString().length === 1) month = `0${month}`;
        if (dateValue.toString().length === 1) dateValue = `0${dateValue}`;
        var formattedDate = `${year}-${month}-${dateValue}`;
        return formattedDate;
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    const renderErrorMessage = () => {
        if (itemServerError.length > 0) {
            return itemServerError[0];
        } else {
            return null;
        }
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "95%",
                        margin: "0 auto 4rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        boxSizing: "border-box",
                    }}
                >
                    <div className="itemsUploadButtonsDiv">
                        <div className="itemUpload">
                            <div className="itemsUploadText">Download Template</div>
                            <div className="itemsUploadGrid">
                                <button className="inputLabel" style={{ gridColumn: "auto / span 4" }} onClick={() => downloadTemplate()}>
                                    Download
                                </button>
                            </div>
                        </div>
                        <div className="itemUpload">
                            <div className="itemsUploadText">Upload Die</div>
                            <div className="itemsUploadGrid">
                                {itemsFile !== "" ? (
                                    <div className="formElement" style={{ gridColumn: `auto / span ${4}` }}>
                                        <div className="inputDone">
                                            <span
                                                style={{
                                                    flex: 1,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "25rem",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                ✅ {itemsFile.name}
                                            </span>
                                            <span
                                                style={{
                                                    paddingRight: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    "&:hover": { backgroundColor: "gray" },
                                                    maxWidth: "25rem",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    boxSizing: "border-box",
                                                }}
                                                onClick={() => {
                                                    setItemsFile("");

                                                    setErrorData([]);
                                                    setExcelData([]);
                                                }}
                                                title="Remove and Re-upload LR Copy"
                                            >
                                                ❌
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="formElement" style={{ gridColumn: `auto / span ${4}`, gridRow: `auto / span ${1}` }}>
                                        <React.Fragment>
                                            <label className={"inputLabel"} htmlFor="upload_file">
                                                Upload
                                            </label>

                                            <input
                                                type="file"
                                                name="items"
                                                accept=".xls, .xlsx"
                                                onChange={(event) => {
                                                    setItemsFile(event.target.files[0]);
                                                    readExcel(event.target.files[0]);
                                                }}
                                                id="upload_file"
                                            />
                                        </React.Fragment>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {errorData.length > 0 ? (
                        <React.Fragment>
                            <div
                                style={{
                                    margin: "2rem 0",
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr 1fr",
                                    gap: "10px 5px",
                                    textAlign: "center",
                                    // fontWeight: "bold",
                                    // fontStyle: "italic",
                                }}
                            >
                                <div>
                                    <span>No of items in file</span>
                                    <span>&nbsp;&nbsp;:&nbsp;&nbsp;{excelData.length}</span>
                                </div>

                                <div>
                                    <span style={{ color: "#14c76a" }}>No of valid items</span>
                                    <span style={{ color: "#14c76a" }}>&nbsp;&nbsp;:&nbsp;&nbsp;{excelData.length - errorData.length}</span>
                                </div>
                                <div>
                                    <span style={{ color: "red" }}>No of Items with errors</span>
                                    <span style={{ color: "red" }}>&nbsp;&nbsp;:&nbsp;&nbsp;{errorData.length}</span>
                                </div>
                            </div>
                            {/* <h4 style={{ margin: "1rem 0 0.5rem 0 " }}>Errors in items shown below</h4> */}
                        </React.Fragment>
                    ) : null}

                    {/* {errorData.length > 0 ? (
                        <React.Fragment>
                            <div className="itemsTable">
                                <table
                                    style={{
                                        width: "100%",
                                        // border: "1px solid lightgray",
                                        borderCollapse: "collapse",
                                        fontSize: "12px",
                                        overflow: "auto",
                                    }}
                                >
                                    <thead>
                                        <tr>
                                            <th className="itemsTableHeaderBold" colspan="3"></th>
                                            <th className="itemsTableHeader" colspan="13" style={{ backgroundColor: "lightgray", fontSize: "16px" }}>
                                                ERRORS
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="itemsTableHeaderBold">Line_No</th>
                                            <th className="itemsTableHeaderBold">Item_Code</th>
                                            <th className="itemsTableHeaderBold">Item_Name</th>
                                            <th className="itemsTableHeader">Item_Code</th>
                                            <th className="itemsTableHeader">Item_Name</th>
                                            <th className="itemsTableHeader">Category</th>
                                            <th className="itemsTableHeader">Sub_Category</th>
                                            <th className="itemsTableHeader">Brand_Name</th>
                                            <th className="itemsTableHeader">Item_Type</th>
                                            <th className="itemsTableHeader">Tax_Type</th>
                                            <th className="itemsTableHeader">Tax_Percentage</th>
                                            <th className="itemsTableHeader">MRP</th>
                                            <th className="itemsTableHeader">Default_UOM</th>
                                            <th className="itemsTableHeader">Conversion_UOM</th>
                                            <th className="itemsTableHeader">Count</th>
                                            <th className="itemsTableHeader">Conversion_Base_UOM</th>​​​ ​​​ ​​​ ​​​ ​​​ ​​​ ​​​ ​​​
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {errorData.map((data) => {
                                            var date = new Date();

                                            if (data.Tax_Applicable_Date !== undefined) {
                                                date.setTime((data.Tax_Applicable_Date - 25569) * 24 * 3600 * 1000);
                                            }

                                            return (
                                                <tr key={data.Item_Code}>
                                                    <td className="itemsTableValueBold">{data.Line_Number}</td>
                                                    <td className="itemsTableValueBold">{data.Item_Code}</td>
                                                    <td className="itemsTableValueBold">{data.Item_Name}</td>
                                                    <td className="itemsTableValue">{data.Item_Code_Error}</td>
                                                    <td className="itemsTableValue">{data.Item_Name_Error}</td>
                                                    <td className="itemsTableValue">{data.Main_Category_Error}</td>
                                                    <td className="itemsTableValue">{data.Sub_Category_Error}</td>
                                                    <td className="itemsTableValue">{data.Brand_Name_Error}</td>
                                                    <td className="itemsTableValue">{data.Item_Type_Error}</td>
                                                    <td className="itemsTableValue">{data.Tax_Type_Error}</td>
                                                    <td className="itemsTableValue">{data.Tax_Percentage_Error}</td>
                                                    <td className="itemsTableValue">{data.MRP_Error}</td>
                                                    <td className="itemsTableValue">{data.Default_UOM_Error}</td>
                                                    <td className="itemsTableValue">{data.Conversion_UOM_Error}</td>
                                                    <td className="itemsTableValue">{data.Count_Error}</td>
                                                    <td className="itemsTableValue">{data.Conversion_Base_UOM_Error}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: "2rem" }}>
                                {toServerData.length > 0
                                    ? "* You may still proceed to submit. The above listed items will be omitted during submission"
                                    : "No valid items found to upload. Please fix the above listed errors and try again"}
                            </div>
                        </React.Fragment>
                    ) : null} */}
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="cancelButton"
                        onClick={() => {
                            var reset = window.confirm("Do you want to reset?");
                            if (reset) {
                                setItemsFile("");
                                setErrorData([]);
                                setExcelData([]);
                            }
                        }}
                    >
                        Reset Fields
                    </button>
                    <button
                        className="submitButton"
                        onClick={() => {
                            checkErrors();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDies);
