import React,{useEffect, useState} from "react";
import { connect } from 'react-redux';
import fetchData from "serverCall/fetchData";

const AgingReportDetail =({poInfo,isLogged})=>{

    const [agingReport,setAgingReport] = useState([])
    const [lineItems,setLinItems]= useState([])

      const RenderTable = ({currentPageLineItems,pageNumber}) => {

        return (
                <>
                <table className="innerTable" style={{width:"100%",height:"90%"}}>
                    <thead>
                        <tr>
                        <td colSpan={11}>
                        <div style={{display: "grid",gridTemplateColumns: "auto auto",padding:"13px"}}>
                            <span>Operation No : {agingReport.operationNo}</span>
                            <span>Date : {agingReport.date}</span>
                            </div>
                        </td>
                        </tr>
                        <tr>
                            <td colSpan={11}>
                                <div style={{display: "grid",gridTemplateColumns: "auto auto",padding:"13px"}}>
                            <span>Press : {agingReport.pressMachineNo}</span>
                            </div>
                            </td>
                        </tr>
                        <tr className="invoiceDetailContactsTableHeader" style={{color: "#000"}}>
                        
                        <td>s.No</td>
                        <td>Section No</td>
                        <td>Referee No</td>
                        <td>Section Name</td>
                        <td>C/L</td>
                        <td>Weight Per Piece</td>
                        <td>No.Pieces</td>
                        <td>Total Qty</td>
                        <td>Party Order</td>
                        <td>Hardness</td>
                        <td>Damage Pieces</td>
                       
                        </tr>
                    </thead>
                    <tbody>
                        <RenderTableRows  currentPageLineItems={currentPageLineItems} pageNumber={pageNumber}  tab="contact" />
                    </tbody>
                </table>
                </>
            );
       };

    const RenderTableRows = ({ currentPageLineItems,pageNumber }) => {
        if(currentPageLineItems?.length>0){
        return (<>
            {currentPageLineItems.map((row, j) => {
                return (
                    <tr className="invoiceDetailContactsTableRows" key={j}>
                        <td  style={{borderBottom: "none", borderTop: "none"}}>{((pageNumber-1)*16)+j+1}</td>
                        {["sectionNo","refereeNo", "sectionName","cuttingLengthId", "weight",
                  "pieces","quantity","partyOrderNo","hardness","damagePieces"].map((key, i) => {
                           
                                return <td  style={{borderBottom: "none", borderTop: "none"}} key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                        })}
                    </tr>
                );
            })}
            {/* <tr className="invoiceDetailContactsTableRows">
                        {lineItemParams.map((key, i) => {
                               return <td  key={i} style={{borderBottom: "none", borderTop: "none"}}>&nbsp;</td>;
                         })}
                         <td style={{textAlign:"right",borderBottom:"none"}}>{getIndianCurrency(poDetail.netAmount)}</td>
                    </tr> */}
            {/* <RenderEmptyRows columns={lineItemParams} emptyRows={21-currentPageLineItems.length}/> */}
        </>);
    } else return(<></>)
       };

    async function getReportDetail() {
        let itemData = await fetchData({
          requestingPage: "itemList",
          method: "post",
          url: "so-fetch/aging-report/"+ poInfo.agingReportId,
          headers: { token: isLogged.accessToken, module: "Aging Report" },
        });
        if(itemData.msg==="success"){
            return itemData.asset[0]
        }else{

            return []
        }
    }

    const getInfo=async()=>
    {
        let detail = await getReportDetail();
        setAgingReport(detail)
        let lineChunk =[];
        for(let i=0;i< detail?.lineItems.length; i+=16) // 16 items in each page
                lineChunk.push(detail?.lineItems.slice(i,i+16));
                setLinItems(lineChunk);
        
    }


    useEffect(()=>{
        getInfo();
    },[])

return(
    <>
      <div className="detailsPageWrapper">
        { //Invoice Pages
        lineItems?.length > 0 ?
        lineItems?.map((currentPage,i)=>{                   
                    return(
                    <div className="detailsPageContainer" key={i}>
                        <div className="invoicePageTitle">Aging Report</div>
                        <div style={{border:"0.25px solid black",minHeight:"1100px",position:"relative"}}>
                            <RenderTable currentPageLineItems={currentPage} pageNumber={i+1}/>
                        </div>
                            <div style={{width: "100%", textAlign: "center"}}>
                                {/* <span style={{fontSize: "9px"}}>SUBJECT TO {poDetail.plantCity?.toUpperCase()} JURISDICTION</span> */}
                            </div>
                            <div style={{width: "100%", textAlign: "center"}}>
                                <span style={{fontSize: "9px"}}>This is a Computer Generated Invoice</span>
                            </div>
                    </div>
                    )
                    }) : null
        }
        </div>
    </>
)
}

const mapStateToProps = (state) => ({
    isLogged: state.isLogged,
    poInfo :state.poInfo
  })

export default connect (mapStateToProps)(AgingReportDetail);