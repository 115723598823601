import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import logoNotAvailable from "../../assets/no-stopping.svg";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import { editDcReturnId } from "../../redux/PurchaseOrder/PoInfoActions";

const DcReturnDetail=({isLogged,poInfo,setEditAndApprove,setSection,editDcReturnId,approval,addTokenToState})=>{
    const [dcReturnDetail, setDcReturnDetail] = useState({});
    const [lineItemsList, setLineItemsList] = useState([]);
    const [vendorLogo, setVendorLogo] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    async function getInformation() {
        await checkToken();
        await getDcReturnDetail();
    }

    const history = useHistory();

    async function checkToken() {
        console.log("Checking token");
        console.log(isLogged.accessToken);
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getDcReturnDetail(){
        var data = await fetchData({
            requestingPage: "dcDetail",
            method: "get",
            url: "dclist-fetch/dc-return-detail/" + poInfo.viewDcReturnId,
            headers: { token: isLogged.accessToken,module: "DC List" },
        });
        if (data.msg === "success") {
            setDcReturnDetail(data.asset[0]);
            setLineItemsList(data.asset[0].lineItems);
        } else {
            console.log(data);
        }
    }

    useEffect(() => {
        getVendorLogo();
    }, [dcReturnDetail]);

    async function getVendorLogo() {
        var data = await fetchData({
            requestingPage: "vendorImage",
            method: "get",
            url: "image/vendor-logo/" + dcReturnDetail.vendorId,
            headers: { token: isLogged.accessToken,module: "DC List" },
        });
        setVendorLogo(data);
    }

    const renderSummaryInfo = () => {
        if (dcReturnDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        DC Return Id: {dcReturnDetail.dcrId}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={
                                dcReturnDetail.dcReturnStatus === "Declined"
                                    ? { color: "red" }
                                    : dcReturnDetail.dcReturnStatus === "Approved"
                                    ? { color: "#14c76a" }
                                    : { color: "#333333" }
                            }
                        >
                            ( {dcReturnDetail.dcReturnStatus} )
                        </span>
                    </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">DC Return Date</span>
                        <span className="vendorSummaryValue">: {dcReturnDetail.dcReturnDate}</span>
                        <span className="vendorSummaryCategory">DC Return No</span>
                        <span className="vendorSummaryValue">: {dcReturnDetail.userDcReturnNo}</span>
                        <span className="vendorSummaryCategory">DC Order Date</span>
                        <span className="vendorSummaryValue">: {dcReturnDetail.orderDate}</span>
                        <span className="vendorSummaryCategory">DC Expected Delivery Date</span>
                        <span className="vendorSummaryValue">: {dcReturnDetail.expectDate}</span>
                        <span className="vendorSummaryCategory">Production Order No</span>
                        <span className="vendorSummaryValue">: {dcReturnDetail.prodNo}</span>
                        <span className="vendorSummaryCategory">Process Name</span>
                        <span className="vendorSummaryValue">: {dcReturnDetail.processName}</span>
                        <span className="vendorSummaryCategory">Production Order No</span>
                        <span className="vendorSummaryValue">: {dcReturnDetail.prodNo}</span>
                        <span className="vendorSummaryCategory">Process Name</span>
                        <span className="vendorSummaryValue">: {dcReturnDetail.processName}</span>
                    </div>
                </React.Fragment>
            );
        }
    };

    const renderImageStatus = () => {
        return (
            <div>
                <span style={{ flex: "1" }}>&nbsp;</span>

                <img
                    className="poVendorImage"
                    src={
                        vendorLogo !== null && vendorLogo !== undefined && vendorLogo !== ""
                            ? `data:image/png;base64,${vendorLogo}`
                            : logoNotAvailable
                    }
                    alt="Vendor Logo"
                />
            </div>
        );
    };

    const RenderTable=()=>{
        return(
            <React.Fragment>
                <table className="vendorDetailContactsTable">
                  <thead>
                    <tr className="vendorDetailContactsTableHeader">
                    <td>Item</td>
                        <td>Item Code</td>
                       <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td> 
                        <td>UOM</td>
                        <td>Production Qty</td>
                        <td>Current Dc Qty</td>
                        <td>Total Return Dc Qty</td>
                        <td>Current Return Dc Qty</td>
                    </tr>
                  </thead>
                  <tbody>
                      <RenderTableRows rows={lineItemsList} tab="contact"/>
                  </tbody>
                </table>
            </React.Fragment>
        )
    };
    const RenderTableRows = ({ rows, tab }) => {
        var lineItemParams = ["itemName", "itemCode","from","to","netWeight","grossWeight", "uom", "prodQty","currentDc","totalReturnDc","currentReturnDc"];
        return rows.map((row,j)=>{
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i}>{row[key] !==null? row[key] : " - "}</td>;
                    })}
                </tr>
            );
        });
    };

    const [errorMessage, setErrorMessage] = useState([]);

    const renderErrorMessage = () => {
        if (errorMessage.length > 0) {
            return errorMessage[0];
        }
    };
    async function approveDcReturn() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approveDc",
            method: "put",
            url: `dclist-edit/approve-dc-return/${poInfo.viewDcReturnId}`,
            headers: { token: isLogged.accessToken,module: "DC List" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("DC Return Approved");
            console.log("DC Return Approved");
        } else {
            console.log(data);
            setModalText(data.desc);
            setErrorMessage([data.desc]);
        }
    }
    const ApproveButtons = () => {
        return (
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="saveButton"
                        onClick={() => {
                            setEditAndApprove(true);
                            setSection("editDcReturn");
                            editDcReturnId(dcReturnDetail.dcrId);
                        }}
                    >
                        Edit
                    </button>
                    <button
                        className="submitButton"
                        onClick={() => {
                            approveDcReturn();
                        }}
                    >
                        Approve
                    </button>
                </div>
            </div>
        );
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (errorMessage.length === 0) {
            setSection("dcList");
            console.log("Redirecting");
        }
        setModalText("Approving DC Return. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving Dc Return. Please wait...");

    return(
        <React.Fragment>
        <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
        <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="poSummaryText">{renderSummaryInfo()}</div>
                        <div className="poSummaryText2">
                            <span className="vendorSummaryRole" style={{ textTransform: "uppercase" }}>
                                {`${dcReturnDetail.vendorId} - ${dcReturnDetail.vendorName}`}
                            </span>

                            <span className="poSummaryValue">
                                {dcReturnDetail.vendorPinCode !== null
                                    ? `Address: ${dcReturnDetail.vendorAddress},${dcReturnDetail.vendorCity}, ${dcReturnDetail.vendorState || ""}- ${dcReturnDetail.vendorPinCode}`
                                    : `Address: ${dcReturnDetail.vendorAddress}, ${dcReturnDetail.vendorCity}, ${dcReturnDetail.vendorState || ""}`}
                            </span>

                            <span className="poSummaryValue">
                                {dcReturnDetail.vendorMobile !== null || dcReturnDetail.vendorPhone !== null ? `\nPhone: ` : null}
                                {dcReturnDetail.vendorMobile !== null ? dcReturnDetail.vendorMobile : null}
                                {dcReturnDetail.vendorPhone !== null ? `, ${dcReturnDetail.vendorPhone} ` : null}
                            </span>
                            <span className="poSummaryValue">{dcReturnDetail.vendorEmail !== null ? `Email Id: ${dcReturnDetail.vendorEmail}` : null}</span>

                            <span className="poSummaryValue">{dcReturnDetail.vendorGstNo !== null ? `GST No: ${dcReturnDetail.vendorGstNo}` : null}</span>
                        </div>
                        {renderImageStatus()}
                    </div>
                    <br/>
                        <div className="purchaseInstruction">
                            <div style={{fontSize:"14px"}}>
                                Number of Items: <span>{lineItemsList.length}</span>
                            </div>
                            <div className="vendorDetailTabArea">
                        <RenderTable />
                        </div>
                            <div style={{ marginTop: "10px" }}>
                                Dc  Return Remarks: {dcReturnDetail.remarks !== null ? dcReturnDetail.remarks : "-"}
                            </div>
                        </div>
                        </div>
            </div>
            {approval ? <ApproveButtons /> : null}
        </React.Fragment>
        );
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
        storeInfo: state.storeInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editDcReturnId: (dcrId) => dispatch(editDcReturnId(dcrId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DcReturnDetail);