import React from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";

import dashboard from "../../assets/dashboard.svg";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";

import "../styles/Dashboard.css";

const TemplateDashboard = () => {
    var barData = [
        {
            date: "15-10-2021",
            Purchases: 219,
        },
        {
            date: "16-10-2021",
            Purchases: 156,
        },
        {
            date: "17-10-2021",
            Purchases: 58,
        },
        {
            date: "18-10-2021",
            Purchases: 146,
        },
        {
            date: "19-10-2021",
            Purchases: 131,
        },
        {
            date: "20-10-2021",
            Purchases: 108,
        },
        {
            date: "21-10-2021",
            Purchases: 154,
        },
    ];

    var barData2 = [
        {
            date: "15-10-2021",
            Sales: 29,
        },
        {
            date: "16-10-2021",
            Sales: 56,
        },
        {
            date: "17-10-2021",
            Sales: 158,
        },
        {
            date: "18-10-2021",
            Sales: 169,
        },
        {
            date: "19-10-2021",
            Sales: 39,
        },
        {
            date: "20-10-2021",
            Sales: 108,
        },
        {
            date: "21-10-2021",
            Sales: 154,
        },
    ];

    var pieData = [
        {
            id: "RealMe",
            label: "RealMe",
            value: 576,
            color: "hsl(66, 70%, 50%)",
        },
        {
            id: "Nokia",
            label: "Nokia",
            value: 570,
            color: "hsl(358, 70%, 50%)",
        },
        {
            id: "Samsung",
            label: "Samsung",
            value: 434,
            color: "hsl(129, 70%, 50%)",
        },
        {
            id: "Oppo",
            label: "Oppo",
            value: 37,
            color: "hsl(28, 70%, 50%)",
        },
        {
            id: "Brand 5",
            //label: "Brand 5",
            value: 138,
            color: "hsl(142, 70%, 50%)",
        },
    ];

    var dashData = {
        customers: {
            type: "numbers",
            title: "# Customers",
            value: 100,
            color: "sales",
        },
        vendors: {
            type: "numbers",
            title: "# Vendors",
            value: "999999999",
            color: "purchase",
        },

        purchaseOrders: {
            type: "numbers",
            title: "# Purchase Orders",
            value: 100,
            color: "purchase",
        },
        salesOrders: {
            type: "numbers",
            title: "# Sales Orders",
            value: 100,
            color: "sales",
        },

        receivedAmount: {
            type: "numbers",
            title: "Received Amount",
            value: 23599,
        },
        paidAmount: {
            type: "numbers",
            title: "Paid Amount",
            value: 23599,
        },
        needToPay: {
            type: "numbers",
            title: "Need To Pay ",
            value: 23599,
        },
        needToReceive: {
            type: "numbers",
            title: "Need To Receive ",
            value: 23599,
        },
        totalPayments: {
            type: "numbers",
            title: "# Payments",
            value: 23599,
        },
        approvalPendingPayment: {
            type: "numbers",
            title: "Approval Pending Payment",
            value: 23599,
        },
        invoice: {
            type: "numbers",
            title: "# Invoice",
            value: 23599,
        },
        pendingInvoice: {
            type: "numbers",
            title: "# Pending Invoice",
            value: 23599,
        },
        inward: {
            type: "numbers",
            title: "# Inward",
            value: 23599,
        },
        approvalPendingInward: {
            type: "numbers",
            title: "Approval Pending Inward",
            value: 23599,
        },
        qualityChecking: {
            type: "numbers",
            title: "# Quality Checking",
            value: 23599,
        },
        approvalPendingQualityChecking: {
            type: "numbers",
            title: "Approval Pending Quality Checking",
            value: 23599,
        },
        outward: {
            type: "numbers",
            title: "# Outward",
            value: 23599,
        },
        approvalPendingOutward: {
            type: "numbers",
            title: "Approval Pending Outward",
            value: 23599,
        },
        brand: {
            type: "numbers",
            title: "# Brand",
            value: 23599,
        },
        storage: {
            type: "numbers",
            title: "# Storage",
            value: 23599,
        },
        stockExport: {
            type: "numbers",
            title: "# Stock Export",
            value: 23599,
        },
        approvalPendingStockExport: {
            type: "numbers",
            title: "Approval Pending Stock Export",
            value: 23599,
        },
        employees: {
            type: "numbers",
            title: "# Employees",
            value: 100,
        },
    };

    const dashData2 = {
        purchaseReturn: {
            type: "numbers",
            title: "# Return",
            value: 111,
        },
        amount: {
            type: "numbers",
            title: "Total Received Amount",
            value: 100,
        },
    };

    const alertStockData = [
        { item: "Realme 6 Pro", count: "10" },
        { item: "Nokia Note 7 4gb", count: "50" },
        { item: "Samsung Note 8gb", count: "99" },
        { item: "Realme 6 Pro", count: "10" },
        { item: "Nokia Note 7 4gb", count: "50" },
        { item: "Samsung Note 8gb", count: "99" },
        { item: "Realme 6 Pro", count: "10" },
    ];

    const renderPieChart = () => {
        return (
            <div
                className="dashboardCard"
                style={{
                    height: "500px",
                    gridColumn: `auto / span 4`,
                    gridRow: `auto / span 4`,
                }}
            >
                <ResponsivePie
                    data={pieData}
                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                    innerRadius={0.25}
                    padAngle={0.7}
                    cornerRadius={0}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    colors={{scheme:"category10"}}
                    arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
                    legends={[
                        {
                            anchor: "bottom",
                            direction: "row",
                            justify: false,
                            translateX: 0,
                            translateY: 56,
                            itemsSpacing: 0,
                            itemWidth: 80,
                            itemHeight: 18,
                            itemTextColor: "#999",
                            itemDirection: "left-to-right",
                            itemOpacity: 1,
                            symbolSize: 18,
                            symbolShape: "circle",
                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemTextColor: "#000",
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </div>
        );
    };

    const renderBarGraph = (data, key, title) => {
        return (
            <div
                className="dashboardCard"
                style={{
                    height: "390px",
                    // height: "250px",
                    gridColumn: `span 2`,
                    gridRow: `span 3`,
                }}
            >
                <div className="dashboardCardHeader">
                    <span className="dashboardCardTitle">{title}</span>
                </div>
                <div className="dashboardGraphBody">
                    <ResponsiveBar
                        data={data}
                        keys={[key]}
                        indexBy="date"
                        margin={{ top: 10, right: 0, bottom: 60, left: 50 }}
                        padding={0.3}
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        valueFormat={{ format: "", enabled: false }}
                        colors={{ scheme: "nivo" }}
                        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -20,
                            legend: "Date",
                            legendPosition: "middle",
                            legendOffset: 42,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: key,
                            legendPosition: "middle",
                            legendOffset: -40,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                    />
                </div>
            </div>
        );
    };

    const renderCardBody = (item) => {
        switch (item.type) {
            case "numbers":
                var color = "";

                switch (item.color) {
                    case "purchase":
                        color = "rgba(89, 191, 205, 0.7)";
                        break;

                    case "sales":
                        color = "rgb(206, 149, 71, 0.7)";
                        break;
                }

                return (
                    <React.Fragment>
                        <div className="dashSingle">
                            <div style={{ color: color }}>{item.value}</div>
                        </div>
                    </React.Fragment>
                );
        }
    };

    const renderAlertStock = () => {
        return (
            <div
                className="dashboardCard"
                style={{
                    height: "250px",
                    gridColumn: `span 2`,
                    gridRow: `span 1`,
                    padding: "0",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div className="dashboardCardHeader">
                    <span className="dashboardCardTitle">Alert Stock Level</span>
                </div>
                <div className="dashboardAlertBody">
                    {alertStockData.map((stock) => {
                        return (
                            <div className="alertStockLine">
                                <span style={{ flex: "1" }}>{stock.item}</span>
                                <span>{stock.count}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderCards = (data) => {
        var list = Object.keys(data);
        console.log(list);
        return list.map((item) => {
            console.log(item);
            return (
                <div className="dashboardCard">
                    <div className="dashboardCardHeader">
                        <span className="dashboardCardTitle">{data[item].title}</span>
                    </div>
                    <div className="dashboardCardBody">{renderCardBody(data[item])}</div>
                </div>
            );
        });
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Dashboard" selectedSubMenu="Admin Dashboard" />
                <div className="detailsContainer">
                    <TopBanner />
                    <PageTitle imgSrc={dashboard} pageTitle="Dashboard" />
                    <div className="formArea">
                        <div className="dashboardGrid">
                            {renderCards(dashData)}
                            {renderBarGraph(barData, "Purchases", "Total Purchase Orders")}
                            {renderBarGraph(barData2, "Sales", "Total Sales Orders")}
                            {renderPieChart()}
                            {renderCards(dashData2)}
                            {renderAlertStock()}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TemplateDashboard;
