import React, { useEffect, useState,useRef } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import FetchNewToken from "../../serverCall/FetchNewToken";
import deleteIcon from "../../assets/delete.svg";

const EditVendorPayment=({isLogged,addTokenToState,setSection,poInfo,editApprove})=>{

    const [vendorPaymentParams,setVendorPaymentParams] = useState({
        orderType:{
            inputType: "text",
            value:"Purchase Order",
            hintText:"Order Type",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please Enter Order Type",
            disabled: true
        },
        vendorId:{
            inputType:"text",
            value:"",
            hintText:"Vendor Name",
            mandatory: true,
            colSpan:5,
            error: false,
            errorMessage : "Please Select a Vendor",
            disabled: true,
        },
        paymentDate:{
            inputType:"dateFromEditPage",
            value: "",
            hintText: "Payment Date",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please Enter Payment Date",
        },
        paymentType:{
            inputType: "options",
            value:"",
            options:[
                {optionId:"Cash",optionName:"Cash"},
                {optionId:"Online",optionName:"Online"},
                {optionId:"Cheque",optionName:"Cheque"},
                {optionId:"Debit/Credit Card",optionName:"Debit/Credit Card"},
            ],
            hintText:"Payment Type",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please Select a Payment Type",
        },
        paymentAmount:{
            inputType: "number",
            value:"",
            hintText:"Payment Amount",
            mandatory: true,
            colSpan:  4,
            error: false,
            errorMessage:"Enter Valid Payment Amount",
        },
        paidFromPlant: {
            inputType: "options",
            value: "",
            options: [],
            hintText: "Paid From Plant",
            colSpan: 4,
            mandatory: false,
        },
        paidFromAccount: {
            inputType: "options",
            value: "",
            options: [],
            hintText: "Bank Account",
            colSpan: 4,
            mandatory: false
        }
    });
    const [paymentAmount,setPaymentAmount]=useState(0);
    const [invoiceParams,setInvoiceParams] = useState({
        lineId:"",
        receiptId:"",
        invoiceNo:{
            inputType:"options",
            options:[],
            value:"",
            hintText:"Invoice No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Please Select a Invoice Number",
        },
        purchaseOrderNo:{
            inputType: "text",
            value:"",
            hintText:"Purchase Order No",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Purchase Order No can't be Empty",
            disabled: true,
        },
        totalAmount:{
            inputType: "text",
            value:"",
            hintText:"Total Amount",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Total Amount can't be Empty",
            disabled: true,
        },
        oldInvoiceBalance:{
            inputType: "text",
            value: "",
            hintText:" Old Invoice Balance",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Balance Amount can't be Empty",
            disabled: true,
        },
        invoiceBalance:{
            inputType: "text",
            value: "",
            hintText:"Invoice Balance Amount",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Balance Amount can't be Empty",
            disabled: true,
        }
    });
    let remainingBalance=0;
    const [lineItems,setLineItems]=useState([]);
    const history=useHistory();
    const [showVendorDetail,setShowVendorDetail] = useState(false);
    const [notes,setNotes] = useState("");
    const [errors,setErrors] = useState([]);
    const [netTotal,setNetTotal] = useState(0);
    const [venPaymentDetail,setVenPaymentDetail]=useState({});
    const [totalInvoiceBalance,setTotalInvoiceBalance]=useState(0);

    useEffect(()=> {getInformation()},[]);

    async function getInformation(){
        await checkToken();
        var receiptDetail=await getVendorPaymentDetail();
        var bankList = await getBankListOptions();
        let initialAccountOption = bankList.filter((row)=>row.plantId==receiptDetail.paidFromPlant)[0];
            initialAccountOption = initialAccountOption.bankList;
        let paramsCopy=Object.assign(vendorPaymentParams);
        paramsCopy=update(paramsCopy,{ vendorId:{value: {$set: receiptDetail.vendorName} },
                                        paymentDate : {value: {$set: receiptDetail.paymentDate}},
                                        paymentType: {value: {$set: receiptDetail.paymentType}},
                                        paymentAmount: {value: {$set: receiptDetail.paymentAmount}},
                                        paidFromPlant: {options: {$set: bankList},value: {$set: receiptDetail.paidFromPlant} },
                                        paidFromAccount: {options: {$set: initialAccountOption},value: {$set: receiptDetail.paidFromAccount} }
                                  });
        setVendorPaymentParams(paramsCopy);
    };
    async function getBankListOptions(){
        var response = await fetchData({
            requestingPage: "venPaymentList",
            method: "get",
            url: 'po-fetch/bank-options-list',
            headers: {token: isLogged.accessToken, module: "Vendor Payments"}
        });

        if(response.msg='success'){
           return response.asset;
        }else{
            console.log(response);
            return [];
        }
    }
    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    };

    async function getVendorPaymentDetail(){
        var result= await fetchData({
            requestingPage:"venPaymentDetail",
            method: "get",
            url: "po-fetch/ven-payment-detail/" + poInfo.editVenPaymentId,
            headers: {token: isLogged.accessToken, module : "Vendor Payments"}
        });

        if(result.msg==="success"){
            setVenPaymentDetail(result.asset[0]);
           
            setShowVendorDetail(true);
            setNotes(result.asset[0].notes);
            setLineItems(result.asset[0].lineItems);
            remainingBalance=result.asset[0]["paymentAmountBalance"];
            setPaymentAmount((result.asset[0]["paymentAmountBalance"] ==0 ? NaN : result.asset[0]["paymentAmountBalance"] ));
            return  result.asset[0];
        }else{
            console.log(result);
        }
    };

    const filterByKeyValue = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
           return !arr2.find(element => {
              return element.invoiceNo == el.invoiceNo;
           });
        });
        return res;
     };

     useEffect(async()=>{
        if(vendorPaymentParams.vendorId.value!="" && lineItems.length>0){
                var invoices=await fetchData({
                    requestingPage: "customerList",
                    method: "get",
                    url: "po-fetch/invoice-approved-list",
                    headers: { token: isLogged.accessToken,module: "Vendor Payments" },
                });
        
                if(invoices.msg==="success"){
                    let invoiceList=[];
                    invoices.asset=invoices.asset.filter((invoice)=>invoice.oldInvoiceBalance!=0);
                    invoices.asset=invoices.asset.filter((invoice)=>invoice.vendorId===venPaymentDetail.vendorId);
                    invoices.asset.forEach(({invoiceNo,poNo,totalAmount,vendorId,oldInvoiceBalance},i)=>
                        invoiceList.push({optionId:invoiceNo,optionName:invoiceNo,invoiceNo,purchaseOrderNo:poNo,totalAmount:parseInt(totalAmount),vendorId,
                            lineId:"",receiptId:"",oldInvoiceBalance: oldInvoiceBalance || parseInt(totalAmount)}));
                    invoiceList=filterByKeyValue(invoiceList,lineItems);
                    let invoiceCopy = Object.assign(invoiceParams);

                    invoiceCopy = update(invoiceCopy,{invoiceNo: {options: {$set: invoiceList} } });

                    setInvoiceParams(invoiceCopy);
                }
        }
    },[vendorPaymentParams.vendorId.value]);

    useEffect(()=>{
        let invoiceParamsCopy = Object.assign(invoiceParams);
        if(invoiceParams.invoiceNo.value!==""){
            let selectedInvoice = invoiceParamsCopy.invoiceNo.options.filter((row)=> row.invoiceNo==invoiceParamsCopy.invoiceNo.value)[0];
            
            remainingBalance=selectedInvoice["oldInvoiceBalance"]-paymentAmount;
            if(remainingBalance < 0){
                selectedInvoice["invoiceBalance"] = 0;
                setPaymentAmount((remainingBalance*-1));
            } else{
                selectedInvoice["invoiceBalance"] = parseInt(remainingBalance);
                setPaymentAmount(NaN)
            }
            
            invoiceParamsCopy = update(invoiceParamsCopy,{ purchaseOrderNo: { value: {$set: selectedInvoice.purchaseOrderNo} },
                                                            totalAmount: {value: {$set: selectedInvoice.totalAmount} },
                                                            oldInvoiceBalance: {value: {$set: selectedInvoice.oldInvoiceBalance}},
                                                            invoiceBalance: {value: {$set: selectedInvoice.invoiceBalance}},
                                                            lineId: {$set: selectedInvoice.lineId},
                                                            receiptId: {$set: selectedInvoice.receiptId}
                                                        });
            setInvoiceParams(invoiceParamsCopy);
            //console.log("rrr",remainingBalance,paymentAmount);
        } else {
            invoiceParamsCopy= update(invoiceParamsCopy,{ purchaseOrderNo: {value : {$set : "" } },totalAmount: {value: {$set: ""} },
                                                        oldInvoiceBalance: {value: {$set: ""}},invoiceBalance: {value: {$set: ""}} 
                                                    });
            setInvoiceParams(invoiceParamsCopy);
        }
    },[invoiceParams.invoiceNo]);

    const addInvoiceToLine=()=>{
        var lineCopy=lineItems.slice();
        var errorList=[]
        let invoiceCopy = Object.assign(invoiceParams);
        ["invoiceNo","purchaseOrderNo","totalAmount","oldInvoiceBalance","invoiceBalance"].map((item)=>{
            if(invoiceParams[item].value==="" || invoiceParams[item].value === null){
                invoiceParams[item].error=true;
                errorList.push("Provide Valid Invoice Details");
            }
        })
        //console.log("@add",remainingBalance,paymentAmount);

        if(isNaN(invoiceCopy["invoiceBalance"].value)){
            invoiceCopy["invoiceBalance"].error=true;
            errorList.push("Payment Amount Insufficient To add This Invoice");
        };
        
        if(errorList.length===0){
            lineCopy.push({invoiceNo: invoiceCopy.invoiceNo.value,purchaseOrderNo: invoiceCopy.purchaseOrderNo.value, totalAmount: invoiceCopy.totalAmount.value,
                lineId: invoiceCopy.lineId, receiptId: invoiceCopy.receiptId,oldInvoiceBalance: invoiceCopy.oldInvoiceBalance.value,invoiceBalance: invoiceCopy.invoiceBalance.value});
            var trimmedList = invoiceCopy.invoiceNo.options.filter((item) => item.optionId !== invoiceCopy.invoiceNo.value);
            invoiceCopy = update(invoiceCopy,{invoiceNo: {value: {$set: ""},options: {$set: trimmedList} },
                                             purchaseOrderNo : {value: {$set: ""} },
                                             totalAmount: {value: {$set: ""} },
                                             oldInvoiceBalance: {value: {$set: ""}},
                                             invoiceBalance: {value: {$set: ""},error: {$set: false} } 
                                            });
            setInvoiceParams(invoiceCopy);
            setLineItems(lineCopy);
        }else{
            setErrors(errorList);
        }
    };

    const editSubSectionItem=(rowIndex,action)=>{
        let invoiceCopy = Object.assign(invoiceParams);
        let lineCopy = lineItems.slice();
        if(action === "delete"){
            var deletedObj=lineCopy.splice(rowIndex,1)[0];
            deletedObj.optionId = deletedObj.invoiceNo;
            deletedObj.optionName = deletedObj.invoiceNo; 
            
            let deductedAmount=deletedObj["oldInvoiceBalance"]-deletedObj["invoiceBalance"];
            // console.log("deduce",deductedAmount);
            setPaymentAmount(+deductedAmount);
            remainingBalance=(-(remainingBalance)-deletedObj["invoiceBalance"]);
            deletedObj["invoiceBalance"]= 0;

            var invoiceOptions= invoiceParams.invoiceNo.options.slice();
            invoiceOptions.push(deletedObj);
            invoiceCopy = update(invoiceCopy,{invoiceNo: {options: {$set: invoiceOptions} } });
            setInvoiceParams(invoiceCopy);
            setLineItems(lineCopy);
        }
    };

    const RenderTableRows=({data})=>{
        return data.map((row,i)=>{
            return ( 
                <tr className="createVendorContactsTableRows" key={i}>
                    {["invoiceNo","purchaseOrderNo","totalAmount","oldInvoiceBalance","invoiceBalance"].map((col,j)=>{
                        return <td key={j}>{row[col]}</td> 
                    })}
                    <td className="itemIndiTabActions">
                        <img
                            alt="Delete Record"
                            className="createVendorContactsAction"
                            src={deleteIcon}
                            onClick={() => {
                                var reset = window.confirm("Delete record?");
                                if (reset) {
                                    editSubSectionItem(i, "delete");
                                }
                            }}
                        />
                    </td>
                </tr>
            ) 
        });
    };

    const RenderTable=()=>{
        return (
                <table className="createVendorContactsTable">
                <thead>
                    <tr className="createVendorContactsTableHeader">
                        <td>Invoice  No</td>
                        <td>Purchase Order No</td>
                        <td>Invoice Amount</td>
                        <td>Old Invoice Balance</td>
                        <td>Invoice Balance</td>
                        <td className="itemIndiTabActions">Actions</td>
                    </tr>
                </thead>
                    {lineItems.length>0 ?
                    (<tbody>
                        <RenderTableRows data={lineItems}/>
                    </tbody> ) :
                    (<tbody>
                        <tr className="createVendorContactsTableRows">
                        {["","","","","",""].map((item,i)=>{
                            return <td key={i}>&nbsp;</td>;
                                })}
                    </tr>
                    </tbody>)}
                </table>
                );
    };

    const renderSubSection=()=>{
        return(
            <React.Fragment>
                 <div className="purchaseOrderIndividualItemsArea">
                    { renderFormElements({ elementList: ["invoiceNo","purchaseOrderNo","totalAmount","oldInvoiceBalance","invoiceBalance"], param: invoiceParams, section: "invoice" })}
                    <FormElement
                        inputType="addButton"
                        value="+ Add"
                        colSpan={4}
                        setInput={() => {
                            setErrors([]);
                            addInvoiceToLine();
                        }}
                    />

                    </div>
                    <RenderTable />
            </React.Fragment>
        )
    };

    const RenderVendorDetail=({vendorDetail})=>{
        return (
            <React.Fragment>
                <div className="detailTitle">{`${vendorDetail.vendorId}-${vendorDetail.vendorName}`}</div>
                <div className="detailText">
                   <b>Address:</b> {vendorDetail.address},
                    <br /> {vendorDetail.city} { vendorDetail.state}
                    {vendorDetail.zipCode  ? `, - ${vendorDetail.zipCode}` : null}
                    {vendorDetail.mobile   ? <br /> : null}
                    {vendorDetail.mobile  ? <b>Phone: </b> : null}
                    {vendorDetail.mobile ? vendorDetail.mobile : null}
                    {vendorDetail.email  ? <><br /> <b>Email Id:</b></> : null}
                    {vendorDetail.email  ?  <>{vendorDetail.email}</> : null}
                    {vendorDetail.panCardNo  ? <><br /> <b>Pan Card No:</b></> : null}
                    {vendorDetail.panCardNo ? ` ${vendorDetail.panCardNo}` : null}
                </div>
            </React.Fragment>
        );
    };

    const updateParameters=({section,param,key,value,wholeObject})=>{
        if(section==="general"){
            let paramsCopy=Object.assign(vendorPaymentParams);
            if(param==="paymentAmount")
            setPaymentAmount(value);

            paramsCopy=update(paramsCopy,{[param]:{[key]: {$set: value} } });

            if(param==='paidFromPlant'){
                if(value)
                paramsCopy = update(paramsCopy,{['paidFromAccount']: {['options']: {$set: wholeObject.bankList} } })
                else
                paramsCopy = update(paramsCopy,{['paidFromAccount']: {['options']: {$set: [] },['value']:{$set: "" }} })
            }

            setVendorPaymentParams(paramsCopy);
        }else if(section==="invoice"){
            let invoiceCopy = Object.assign(invoiceParams);
            invoiceCopy=update(invoiceCopy,{[param]:{[key]:{$set: value} } });
            setInvoiceParams(invoiceCopy);
        }
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
      }
      const pAValue= usePrevious(vendorPaymentParams.paymentAmount.value);

      useEffect(()=>{
        let invoiceCopy = Object.assign(invoiceParams);
        
        if(pAValue!==vendorPaymentParams.paymentAmount.value && pAValue!==""){
            
            remainingBalance=paymentAmount;
            //console.log("@paid",remainingBalance,paymentAmount);
            let invoiceOptionsCopy=invoiceParams.invoiceNo.options.slice();
            invoiceOptionsCopy.push(...lineItems);
            setLineItems([]);
            invoiceCopy = update(invoiceCopy, {invoiceNo : {value: {$set: ""},options: {$set: invoiceOptionsCopy} },
                                               purchaseOrderNo :{value :{$set  :""} },
                                                totalAmount:{value: {$set: ""} },
                                                oldInvoiceBalance: {value: {$set: ""}},
                                                invoiceBalance: {value: {$set: ""} }  });
            setInvoiceParams(invoiceCopy);
        }
         if(vendorPaymentParams.paymentAmount.value===""){
             setLineItems([]);
             invoiceCopy = update(invoiceCopy, {invoiceNo : {value: {$set: ""} },
                                               purchaseOrderNo :{value :{$set  :""} },
                                                totalAmount:{value: {$set: ""} },
                                                oldInvoiceBalance: {value: {$set: ""}},
                                                invoiceBalance: {value: {$set: ""} }  });
            setInvoiceParams(invoiceCopy);
         }
    },[vendorPaymentParams.paymentAmount]);

    const renderFormElements=({elementList,param,section})=>{
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value !== null ? param[element].value : ""}
                    setInput={(value,wholeObject) => {
                        updateParameters({ section, param: element, key: "value", value: value,wholeObject });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                />
            );
        });
    };

    useEffect(()=>{
        if(lineItems.length>0){
        let totalInvoiceTemp=0;
        lineItems.forEach((row)=>{
            totalInvoiceTemp+=parseInt(row.oldInvoiceBalance);
        });
        setNetTotal(totalInvoiceTemp);
        let totalInvoiceBalanceTemp = lineItems[lineItems.length-1]["newInvoiceBalance"];
        setTotalInvoiceBalance(totalInvoiceBalanceTemp);
    }
    },[lineItems.length]);


    const renderErrorMessage=()=>{
        if(errors.length>0)
        return errors[0]
    };

    const checkErrors=()=>{
        var errorList=[];
        let venParamCopy=Object.assign(vendorPaymentParams);
        Object.keys(venParamCopy).map((item)=>{
            if(venParamCopy[item].mandatory)
            venParamCopy[item].error= !validateMandatory(venParamCopy[item].value.toString());
            if(venParamCopy[item].error){
                errorList.push(venParamCopy[item].errorMessage);
            }
        });

        let invoiceParamsCopy= Object.assign(invoiceParams); 

        ["invoiceNo","purchaseOrderNo","totalAmount"].map((item,i)=>{
            if(invoiceParamsCopy[item].value!=="")
                errorList.push("Unsaved Invoice Record Found!");
        })

        let lineItemsCopy = lineItems.slice();
        lineItemsCopy.forEach((row)=>{
            for (let item in row){
                if(row[item]==null || row[item]==undefined)
                errorList.push("Invalid Invoice Item Found");}
            });
       
        if(!parseInt(paymentAmount) && paymentAmount!==0 && !isNaN(paymentAmount))
        errorList.push("Provide valid Payment Amount");

        if(lineItems.length<1)
        errorList.push("Select Invoice No");

        if(errorList.length===0 && lineItems.length>0)
        {
            var dataToServer={
                receiptId: venPaymentDetail.receiptId,
                vendorId: venPaymentDetail.vendorId,
                paymentDate: venParamCopy.paymentDate.value,
                paymentType: venParamCopy.paymentType.value,
                paymentAmount: venParamCopy.paymentAmount.value,
                paidFromPlant: venParamCopy.paidFromPlant.value || null,
                paidFromAccount: venParamCopy.paidFromAccount.value || null,
                paymentAmountBalance: paymentAmount || 0,
                notes:notes,
                netTotal:netTotal
            };
            dataToServer.lineItems=lineItemsCopy;
            submitData(dataToServer);
        }else{
            setVendorPaymentParams(venParamCopy);
            setLineItems(lineItemsCopy);
            setErrors(errorList);
        }
    };

    async function approveReceipt(){
        setIsOpen(true);
        setDataSubmitted(false);
        setModalText("Approving Customer Receipt. Please Wait...");
        var result = await fetchData({
            requestingPage: "approveCusReceipt",
            method: "put",
            url : "po-edit/approve-vendor-payment/"+poInfo.viewVenPaymentId,
            headers: {token: isLogged.accessToken,module: "Vendor Payments"}
        });
        setDataSubmitted(true);
        if(result.msg==="success"){
            setModalText("Vendor Payment Approved");
        }else{
            setModalText(result.desc);
            setErrors([result.desc]);
        }
    };

    async function submitData(dataToServer){
        setIsOpen(true);
        setModalText("Editing Vendor Payment... Please Wait...")
         var result = await fetchData({
             requestingPage: "createPoItem",
             url: "po-edit/edit-vendor-payment",
             method: "put",
             headers: {token: isLogged.accessToken, module: "Vendor Payments"},
             data: dataToServer
         });
         if(result.msg==="success"){
             if(editApprove)
              approveReceipt();
                setDataSubmitted(true);
             setModalText("Vendor Payment Edited SuccessFully");
         }else{
             setDataSubmitted(true);
             setModalText(result.desc);
             console.log(result);
             setErrors([result.desc]);
         }
     };

     const [modalIsOpen, setIsOpen] = useState(false);
     const [dataSubmitted, setDataSubmitted] = useState(false);
     const [modalText, setModalText] = useState("Editing Vendor Payment. Please wait...");

     const submitOkClick = () => {
        setIsOpen(false);
        if (errors.length === 0) {
            setSection("vendorPaymentsList");
        }
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
            <div
                    style={{
                        width: "1100px",
                        margin: "0 auto 4rem",
                        padding: "3rem 3rem 1rem 3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}>
                    <div style={{ display: "flex" }}>
                        <div className="createPurchaseOrderGrid" style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
                            {renderFormElements({ elementList: Object.keys(vendorPaymentParams), param: vendorPaymentParams, section: "general" })}
                        </div>
                        <div style={{ width: "260px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{showVendorDetail ? <RenderVendorDetail  vendorDetail={venPaymentDetail}/> : null}</div>
                            </div>
                        </div>
                    </div>

                    <div className="purchaseOrderSubSectionArea">{renderSubSection()}</div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div style={{ marginTop: "10px" }}>Notes</div>
                            <textarea
                                className="multilineInput"
                                value={notes}
                                rows="6"
                                cols="30"
                                name="text"
                                placeholder="Enter your notes"
                                style={{
                                    padding: "10px",
                                    resize: "none",
                                    marginTop: "10px",
                                    height: "114px",
                                    fontFamily: "sans-serif",
                                }}
                                onChange={(e) => {
                                    setNotes(e.target.value);
                                }}
                            />
                        </div>
                        <div className="poSpacer">&nbsp;</div>
                        <div className="poNetTotal">
                             <div className="poTotalTitle">
                                <span>
                                    <b>Total Invoice Amount</b>
                                </span>
                                <span className="poNetTotalAmount">{netTotal}</span>
                             </div>
                             <div className="poTotalTitle">
                                <span>
                                    <b>Total Invoice Balance</b>
                                </span>
                                <span className="poNetTotalAmount">{isNaN(totalInvoiceBalance) ? 0 : totalInvoiceBalance}</span>
                             </div>
                             <div className="poTotalTitle">
                                <span>
                                    <b>Payment Amount Balance</b>
                                </span>
                                <span className="poNetTotalAmount">{isNaN(paymentAmount) ? 0 : paymentAmount}</span>
                             </div>
                             </div>
                        </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                    <button className="submitButton" onClick={() => {setErrors([]);checkErrors();}}>
                        {editApprove ? "Edit & Approve" : "Submit to Edit"}
                    </button>
            </div>
            </div>
        </React.Fragment>);

}

const mapStateToProps = (state) => {
    return {
      isLogged: state.isLogged,
      poInfo: state.poInfo};
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) =>dispatch(addToken(accessToken, employeeId)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditVendorPayment);