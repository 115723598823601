import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { useHistory } from "react-router-dom";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import update from "immutability-helper";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import FormHint from "../SmallComponents/FormHint";

const EditStockTransfer = ({ setSection, isLogged, storeInfo, addTokenToState, editApprove }) => {
    const lineItemCols=isLogged.showHideWeight ? ["itemName", "itemCode","from","to","netWeight","grossWeight", "uom", "quantity"] : ["itemName", "uom", "quantity"];
    const transferParamList = [
        ["fromPlant", "fromStorageId", "transferDate", "toPlant", "toStorageId", "batchNo"],
       lineItemCols
    ];
    const [transferParams, setTransferParams] = useState({
        fromPlant: {
            inputType: "options",
            value: "",
            hintText: "From Plant",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select a From plant",
            disabled: false,
        },
        fromStorageId: {
            inputType: "options",
            value: "",
            hintText: "From Storage",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select a From storage",
            disabled: false,
            resetOptions: true,
        },
        toPlant: {
            inputType: "options",
            value: "",
            hintText: "To Plant",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select a To plant",
            disabled: false,
        },
        toStorageId: {
            inputType: "options",
            value: "",
            hintText: "To Storage",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select a To storage",
            disabled: false,
            resetOptions: true,
        },
        transferDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Transfer Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please select a transfer date",
            disabled: false,
            // maxdate: true,
        },
        batchNo: {
            inputType: "text",
            value: "",
            hintText: "Batch No",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
            disabled: false,
        },

        itemId: {
            inputType: "options",
            value: "",
            hintText: "Item",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select item",
            disabled: false,
        },from: {
            inputType: "text",
            value: "",
            hintText: "Weight From(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        to: {
            inputType: "text",
            value: "",
            hintText: "Weight To(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        netWeight: {
            inputType: "text",
            value: "",
            hintText: "Net Weight(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        grossWeight: {
            inputType: "text",
            value: "",
            hintText: "Gross Weight(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        uomId: {
            inputType: "options",
            value: "",
            hintText: "Uom",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select Uom",
            disabled: false,
            resetOptions: true,
        },

        quantity: {
            inputType: "text",
            value: "",
            hintText: "Quantity",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter a valid quantity",
            disabled: false,
        },

        transferLineItemId: { value: null },
    });

    const [transferList, setTransferList] = useState([]);
    const [transferErrors, setTransferErrors] = useState([]);

    const [storageList, setStorageList] = useState({});
    const [itemUomList, setItemUomList] = useState({});

    const [transferDetail, setTransferDetail] = useState({});

    useEffect(() => {
        getInformation();
    }, []);

    const getInformation = async () => {
        setIsOpen(true);
        setModalText("Getting Stock Transfer Info. Please wait...");

        await checkToken();
        var [plantList, storageLocListFormatted] = await getPlants();
        setStorageList(storageLocListFormatted);

        var [itemList, itemUomListFormatted] = await getItemUom();
        setItemUomList(itemUomListFormatted);

        var transferDetailCopy = await getTransferDetail();


        var paramsCopy = Object.assign(transferParams);
        paramsCopy = update(paramsCopy, {
            fromPlant: { options: { $set: plantList }, value: { $set: transferDetailCopy.fromPlantId } },
            fromStorageId: {
                options: { $set: storageLocListFormatted[transferDetailCopy.fromPlantId] },
                value: { $set: transferDetailCopy.fromStorageId },
            },
            transferDate: { value: { $set: transferDetailCopy.transferDate } },
            batchNo: { value: { $set: transferDetailCopy.batchNo !== null ? transferDetailCopy.batchNo : "" } },
            toPlant: { options: { $set: plantList }, value: { $set: transferDetailCopy.toPlantId } },
            toStorageId: {
                options: { $set: storageLocListFormatted[transferDetailCopy.toPlantId] },
                value: { $set: transferDetailCopy.toStorageId },
            },
            itemId: { options: { $set: itemList } },
        });
        setTransferParams(paramsCopy);
        setTransferList(transferDetailCopy.lineItems);
        setTransferDetail(transferDetailCopy);
        if (transferDetailCopy.instruction !== null) setInstruction(transferDetailCopy.instruction);
        if (transferDetailCopy.transferDocument !== null) setTransferDocument({ name: transferDetailCopy.transferDocument });

        setIsOpen(false);
        setModalText("Updating Stock Transfer Information. Please wait..");
    };

    const history = useHistory();
    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getPlants() {
        var plantsData = await fetchData({
            requestingPage: "plantList",
            method: "get",
            url: "fetch/plants",
            headers: { token: isLogged.accessToken,module:"Stock Transfer" },
        });

        var plantList = [];
        var storageLocList = {};

        if (plantsData.msg === "success") {
            plantsData.asset.forEach((plant) => {
                var plantObj = {};
                plantObj.optionId = plant.plant.plantId;
                plantObj.optionName = plant.plant.plantName;
                plantObj.address =
                    plant.plant.plantName +
                    ", " +
                    plant.plant.city +
                    ", " +
                    plant.plant.state +
                    (plant.plant.zipCode !== null ? ` - ${plant.plant.zipCode}, ` : ", ") +
                    plant.plant.country;
                plantList.push(plantObj);

                storageLocList[plant.plant.plantId] = [];
                plant.storage.forEach((store) => {
                    var storeObj = {};
                    storeObj.optionId = store.storageId;
                    storeObj.optionName = store.storageName;
                    storeObj.address =
                        store.address +
                        ", " +
                        plant.plant.city +
                        ", " +
                        plant.plant.state +
                        (plant.plant.zipCode !== null ? ` - ${plant.plant.zipCode}, ` : ", ") +
                        plant.plant.country;
                    storeObj.gstNo = plant.plant.gstNo;
                    storageLocList[plant.plant.plantId].push(storeObj);
                });
            });

            return [plantList, storageLocList];
        }
    }

    async function getItemUom() {
        var itemData = await fetchData({ requestingPage: "itemList", method: "post", url: "fetch/items", 
                    headers: { token: isLogged.accessToken,module:"Stock Transfer" },data:{tab:"Active"} });

        var itemList = [];
        var itemUomList = {};
        if (itemData.msg === "success") {

            itemData.asset.forEach((item) => {
                var itemObj = {};
                itemObj.optionId = item.item.itemId;
                itemObj.optionName = item.item.itemName;
                itemObj.itemCode = item.item.itemCode;

                itemList.push(itemObj);

                itemUomList[item.item.itemId] = [];
                item.uom.forEach((uom) => {
                    var uomObj = {};
                    uomObj.optionId = uom.uomId;
                    uomObj.optionName = uom.uom;
                    uomObj.from = item.item.from;
                    uomObj.to= item.item.to;
                    uomObj.netWeight = item.item.netWeight;
                    uomObj.grossWeight = item.item.grossWeight;

                    itemUomList[item.item.itemId].push(uomObj);
                });
            });

            return [itemList, itemUomList];
        }
    }

    async function getTransferDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "post",
            url: "store-fetch/stock-transfer-detail/" + storeInfo.editTransferId,
            headers: { token: isLogged.accessToken,module:"Stock Transfer" },
        });
        if (data.msg === "success") {

            return data.asset[0];
            // setTransferDetail(data.asset[0]);
        } else {
            console.log(data);
        }
    }

    // -----------------------------------------------------------------------------------------
    // Update UOM List on item change
    // -----------------------------------------------------------------------------------------
    useEffect(() => {
        var paramsCopy = Object.assign(transferParams);

        if (paramsCopy.itemId.value !== "") {
            if (paramsCopy.uomId.resetOptions) {
                paramsCopy = update(paramsCopy, { uomId: { options: { $set: itemUomList[paramsCopy.itemId.value] }, value: { $set: "" } },
                                                 from: {value: {$set: itemUomList[paramsCopy.itemId.value][0]["from"] } },
                                                to: {value: {$set: itemUomList[paramsCopy.itemId.value][0]["to"] } },
                                                netWeight: {value: {$set: itemUomList[paramsCopy.itemId.value][0]["netWeight"] } }, 
                                                grossWeight: {value: {$set: itemUomList[paramsCopy.itemId.value][0]["grossWeight"] } } 
             });
            } else {
                paramsCopy = update(paramsCopy, { uomId: { options: { $set: itemUomList[paramsCopy.itemId.value] }, resetOptions: { $set: true } } });
            }
        } else {
            paramsCopy = update(paramsCopy, { uomId: { options: { $set: [] }, value: { $set: "" } } });
        }

        setTransferParams(paramsCopy);
    }, [transferParams.itemId.value]);

    // -----------------------------------------------------------------------------------------
    // Update From Storage List on From Plant change
    // -----------------------------------------------------------------------------------------
    useEffect(() => {
        var paramsCopy = Object.assign(transferParams);
        if (paramsCopy.fromPlant.value !== "") {
            if (paramsCopy.fromStorageId.resetOptions) {

                if (paramsCopy.toPlant.value === paramsCopy.fromPlant.value && paramsCopy.toStorageId.value !== "") {
                    var storageListSelected = storageList[paramsCopy.fromPlant.value];
                    var filteredList = storageListSelected.filter((item) => item.optionId !== paramsCopy.toStorageId.value);

                    paramsCopy = update(paramsCopy, { fromStorageId: { options: { $set: filteredList }, value: { $set: "" } } });
                } else {
                    paramsCopy = update(paramsCopy, {
                        fromStorageId: { options: { $set: storageList[paramsCopy.fromPlant.value] }, value: { $set: "" } },
                    });
                }
            } else {
                paramsCopy = update(paramsCopy, { fromStorageId: { resetOptions: { $set: true } } });
            }
        } else {
            paramsCopy = update(paramsCopy, { fromStorageId: { options: { $set: [] }, value: { $set: "" } } });
        }

        setTransferParams(paramsCopy);
    }, [transferParams.fromPlant.value]);

    // -----------------------------------------------------------------------------------------
    // Update To Storage List on To Plant change
    // -----------------------------------------------------------------------------------------
    useEffect(() => {
        var paramsCopy = Object.assign(transferParams);
        if (paramsCopy.toPlant.value !== "") {
            if (paramsCopy.toStorageId.resetOptions) {
                if (paramsCopy.toPlant.value === paramsCopy.fromPlant.value && paramsCopy.fromStorageId.value !== "") {
                    var storageListSelected = storageList[paramsCopy.toPlant.value];
                    var filteredList = storageListSelected.filter((item) => item.optionId !== paramsCopy.fromStorageId.value);

                    paramsCopy = update(paramsCopy, { toStorageId: { options: { $set: filteredList }, value: { $set: "" } } });
                } else {
                    paramsCopy = update(paramsCopy, {
                        toStorageId: { options: { $set: storageList[paramsCopy.toPlant.value] }, value: { $set: "" } },
                    });
                }
            } else {
                paramsCopy = update(paramsCopy, { toStorageId: { resetOptions: { $set: true } } });
            }
        } else {
            paramsCopy = update(paramsCopy, { toStorageId: { options: { $set: [] }, value: { $set: "" } } });
        }

        setTransferParams(paramsCopy);
    }, [transferParams.toPlant.value]);

    // -----------------------------------------------------------------------------------------
    // Update To Storage List on From Storage List change
    // -----------------------------------------------------------------------------------------
    useEffect(() => {
        var paramsCopy = Object.assign(transferParams);
        if (paramsCopy.fromPlant.value === paramsCopy.toPlant.value && paramsCopy.fromPlant.value !== "") {
            if (paramsCopy.fromStorageId.value !== "") {
                var storageListSelected = storageList[paramsCopy.toPlant.value];
                var filteredList = storageListSelected.filter((item) => item.optionId !== paramsCopy.fromStorageId.value);

                paramsCopy = update(paramsCopy, { toStorageId: { options: { $set: filteredList } } });
            } else {
                paramsCopy = update(paramsCopy, {
                    toStorageId: { options: { $set: storageList[paramsCopy.toPlant.value] } },
                    fromStorageId: { options: { $set: storageList[paramsCopy.toPlant.value] } },
                });
            }
        }

        setTransferParams(paramsCopy);
    }, [transferParams.fromStorageId.value]);

    // -----------------------------------------------------------------------------------------
    // Update To Storage List on From Storage List change
    // -----------------------------------------------------------------------------------------
    useEffect(() => {
        var paramsCopy = Object.assign(transferParams);
        if (paramsCopy.fromPlant.value === paramsCopy.toPlant.value && paramsCopy.fromPlant.value !== "") {
            if (paramsCopy.toStorageId.value !== "") {
                var storageListSelected = storageList[paramsCopy.toPlant.value];
                var filteredList = storageListSelected.filter((item) => item.optionId !== paramsCopy.toStorageId.value);

                paramsCopy = update(paramsCopy, { fromStorageId: { options: { $set: filteredList } } });
            } else {
                paramsCopy = update(paramsCopy, {
                    fromStorageId: { options: { $set: storageList[paramsCopy.toPlant.value] } },
                    fromStorageId: { options: { $set: storageList[paramsCopy.toPlant.value] } },
                });
            }
        }

        setTransferParams(paramsCopy);
    }, [transferParams.toStorageId.value]);

    const updateParamaters = ({ section, paramName, key, value }) => {
        var paramsCopy = Object.assign(transferParams);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });

        setTransferParams(paramsCopy);
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Updating Stock Transfer info. Please wait..");

    const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
            // console.log(element);
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value ? param[element].value : ""}
                    setInput={(value) => {
                        updateParamaters({ section, paramName: element, key: "value", value: value });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                    maxdate={param[element].maxdate}
                    removeBorder={param[element].removeBorder}
                />
            );
        });
    };

    const checkLineItemError = () => {
        var paramsCopy = Object.assign(transferParams);
        var errorList = [];

        transferParamList[1].forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (item === "quantity" || item === "from" || item === "to" || item==="netWeight" || item ==="grossWeight") {
                if (paramsCopy.quantity.value !== "") {
                    if (isNaN(paramsCopy.quantity.value) || parseInt(paramsCopy.quantity.value) < 0) {
                        errorList.push("Enter a valid number for quantity");
                        paramsCopy = update(paramsCopy, { [item]: { error: { $set: true } } });
                    } else {
                        paramsCopy = update(paramsCopy, { [item]: { error: { $set: false } } });
                    }
                }
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
        });

        if (errorList.length === 0) {
            var transferListCopy = transferList.slice();

            var itemObj = paramsCopy.itemId.options.filter((option) => option.optionId === paramsCopy.itemId.value)[0];
            transferListCopy.push({
                itemId: paramsCopy.itemId.value,
                itemName: itemObj.optionName,
                itemCode: itemObj.itemCode,
                from: paramsCopy.from.value,
                to:paramsCopy.to.value,
                netWeight: paramsCopy.netWeight.value,
                grossWeight: paramsCopy.grossWeight.value,
                uomId: paramsCopy.uomId.value,
                uom: paramsCopy.uomId.options.filter((option) => option.optionId === paramsCopy.uomId.value)[0].optionName,
                quantity: paramsCopy.quantity.value,
                transferLineItemId: paramsCopy.transferLineItemId.value,
            });
            setTransferList(transferListCopy);

            paramsCopy = update(paramsCopy, { itemId: { value: { $set: "" } }, uomId: { value: { $set: "" } },from:{value:{$set: ""}}, to: {value:{$set:""}},netWeight:{value: {$set: ""}},grossWeight:{value: {$set: ""}}, quantity: { value: { $set: "" } } });
        }

        setTransferErrors(errorList);
        setTransferParams(paramsCopy);
    };

    const checkErrors = () => {
        var errorList = [];
        var paramsCopy = Object.assign(transferParams);

        transferParamList[0].forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
        });

        transferParamList[1].forEach((element) => {
            if (paramsCopy[element].value !== "") {
                errorList.push("Unsaved item information found!");
            }
        });

        if (!transferList.length > 0) {
            errorList.push("No item information found. Add atleast one item");
        }

        if (errorList.length === 0) {

            var data2server = new FormData();
            data2server.append("transferId", transferDetail.transferId);
            data2server.append("fromStorageId", paramsCopy.fromStorageId.value);
            data2server.append("toStorageId", paramsCopy.toStorageId.value);
            data2server.append("transferDate", paramsCopy.transferDate.value);

            var transferListToServer = [];

            transferList.forEach((item) => {
                transferListToServer.push({
                    itemId: item.itemId,
                    uomId: item.uomId,
                    quantity: parseInt(item.quantity),
                    transferLineItemId: item.transferLineItemId,
                });
            });

            data2server.append("transferLineItems", JSON.stringify(transferListToServer));

            if (paramsCopy.batchNo.value !== "") {
                data2server.append("batchNo", paramsCopy.batchNo.value);
            }
            if (instruction !== "") {
                data2server.append("instruction", instruction);
            }
            if (transferDocument !== "" && transferDocument.size > 0) {
                data2server.append("transferDocument", transferDocument);
            }

          

            submitData(data2server);
        }
        setTransferErrors(errorList);
        setTransferParams(paramsCopy);
    };

    const submitData = async (data) => {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "createCustomer",
            method: "put",
            url: "store-edit/stock-transfer",
            headers: { token: isLogged.accessToken,module:"Stock Transfer" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Stock Transfer Edited Successfully!");

            if (editApprove) {
                approveTransfer();
            } else {
                setDataSubmitted(true);
            }
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setTransferErrors([result.desc]);
        }
        setDataSubmitted(true);
    };

    async function approveTransfer() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `store-edit/stock-transfer-approve/${storeInfo.editTransferId}`,
            headers: { token: isLogged.accessToken,module:"Stock Transfer" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Stock Transfer Approved!");
        } else {
            setModalText(data.desc);
            setTransferErrors(data.desc);
        }
    }

    const editSubSectionItem = (index, action) => {
        if (action === "edit") {
            var proceed = true;

            ["itemId","from","to","netWeight","grossWeight", "uomId", "quantity"].forEach((elem) => {
                if (transferParams[elem].value !== "") {
                    proceed = false;
                }
            });

            if (!proceed) {
                var yes = window.confirm("Unsaved information found. Overwrite?");
            }

            if (proceed || yes) {
                var transferListCopy = transferList.slice();
                var editObj = transferListCopy.splice(index, 1)[0];

                var paramsCopy = Object.assign(transferParams);
                paramsCopy = update(paramsCopy, {
                    itemId: { value: { $set: editObj.itemId } },
                    uomId: { value: { $set: editObj.uomId }, resetOptions: { $set: false } },
                    from : {value: {$set : editObj.from}},
                    to: {value: {$set: editObj.to}},
                    netWeight: {value: {$set: editObj.netWeight}},
                    grossWeight: {value: {$set: editObj.grossWeight}},
                    quantity: { value: { $set: editObj.quantity } },
                    transferLineItemId: { value: { $set: editObj.transferLineItemId } },
                });

                setTransferList(transferListCopy);
                setTransferParams(paramsCopy);
            }
        }

        if (action === "delete") {
            var transferListCopy = transferList.slice();
            transferListCopy.splice(index, 1);
            setTransferList(transferListCopy);
        }
    };

    const renderSubSection = () => {
        return (
            <div className="purchaseOrderIndividualItemsArea" style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
                {renderFormElements({ elementList: transferParamList[1], param: transferParams, section: "general" })}
                <FormElement
                    inputType="addButton"
                    value="+ Add"
                    colSpan={5}
                    setInput={() => {
                        checkLineItemError();
                    }}
                />
            </div>
        );
    };

    const RenderTable = () => {
        return (
            <table className="createVendorContactsTable">
                <thead>
                    <tr className="createVendorContactsTableHeader">
                        <td>Item</td>
                        <td>Item Code</td>
                        {isLogged.showHideWeight ?
                        <><td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td></> : ''}
                        <td>UOM</td>
                        <td>Quantity</td>
                        <td className="createVendorContactsAction">Actions</td>
                    </tr>
                </thead>
                {transferList.length > 0 ? (
                    <tbody>
                        {transferList.map((row, j) => {
                            return (
                                <tr className="createVendorContactsTableRows" key={j}>
                                    {lineItemCols.map((key, i) => {
                                        return <td key={i}>{key === "itemId" ? row.itemName : row[key] !== null ? row[key] : "-"}</td>;
                                    })}
                                    <td>
                                        <img
                                            alt="Edit Record"
                                            className="createVendorContactsAction"
                                            src={editIcon}
                                            onClick={() => {
                                                editSubSectionItem(j, "edit");
                                            }}
                                        />
                                        <img
                                            alt="Delete Record"
                                            className="createVendorContactsAction"
                                            src={deleteIcon}
                                            onClick={() => {
                                                var reset = window.confirm("Delete record?");
                                                if (reset) {
                                                    editSubSectionItem(j, "delete");
                                                }
                                            }}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="createVendorContactsTableRows">
                            {lineItemCols.map((item) => {
                                return <td key={item}>&nbsp;</td>;
                            })}
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                )}
            </table>
        );
    };

    const renderErrorMessage = () => {
        return transferErrors.length > 0 ? transferErrors[0] : null;
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (transferErrors.length === 0) {
            setSection("transferList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const [instruction, setInstruction] = useState("");
    const [transferDocument, setTransferDocument] = useState("");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "1000px",
                        margin: "0 auto 2rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div className="createSalesInvoiceGrid2">
                        {renderFormElements({ elementList: transferParamList[0], param: transferParams, section: "general" })}
                    </div>

                    <div className="purchaseOrderSubSectionArea" style={{width:"90%"}}>
                        {renderSubSection()}
                        <RenderTable />
                    </div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                No Items: <span>{transferList.length}</span>
                            </div>

                            <div style={{ marginTop: "10px" }}>Instruction</div>
                            <textarea
                                className="createInwardMultilineInput"
                                value={instruction}
                                rows="3"
                                cols="30"
                                name="text"
                                placeholder="Enter instructions"
                                style={{
                                    display: "flex",
                                    padding: "10px",
                                    resize: "none",
                                    marginTop: "10px",
                                    width:"300px",
                                    height: "114px",
                                    fontFamily: "sans-serif",
                                }}
                                onChange={(e) => {
                                    setInstruction(e.target.value);
                                }}
                            />

                            <div style={{ display: "flex" }}>
                                <div className="createSalesInvoiceGrid2">
                                    {transferDocument !== "" ? (
                                        <div className="formElement" style={{ gridColumn: `auto / span ${9}` }}>
                                            <FormHint hintText="Transfer Document" mandatory={false} />
                                            <div className="inputDone">
                                                <span
                                                    style={{
                                                        flex: 1,
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        // maxWidth: "168px",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    ✅ {transferDocument.name}
                                                </span>
                                                <span
                                                    style={{ paddingRight: "10px", cursor: "pointer", "&:hover": { backgroundColor: "gray" } }}
                                                    onClick={() => setTransferDocument("")}
                                                    title="Remove and Re-upload LR Copy"
                                                >
                                                    ❌
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <FormElement
                                            colSpan={9}
                                            inputType="upload"
                                            hintText="Transfer Document"
                                            setInput={(file) => {
                                                setTransferDocument(file);
                                            }}
                                        />
                                    )}
                                </div>

                                <div style={{ width: "300px" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="submitButton"
                        onClick={() => {
                            setTransferErrors([]);
                            checkErrors();
                        }}
                    >
                        {editApprove ? "Edit & Approve" : "Submit for Approval"}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStockTransfer);
